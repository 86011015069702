import { createContext } from 'react';

import { HookStore, HeaderHeightState, HeaderHeightHook } from '@core/type/context';

import { useFnContext, useStoreContext } from '../util';

export const headerHeightContext =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createContext<HookStore<any, HeaderHeightState, HeaderHeightHook>>(null);
export const useContextHeaderHeight = () => {
  return useFnContext(headerHeightContext);
};
export function useStoreHeaderHeight<Selector>(selector: keyof HeaderHeightState) {
  return useStoreContext<Selector, HeaderHeightState, HeaderHeightHook>(
    selector,
    headerHeightContext,
  );
}
