export const google = {
  GTM_URL: 'https://www.googletagmanager.com',
  GTM_URLS: 'https://*.googletagmanager.com',
  GTM_PREVIEW_URL: 'https://googletagmanager.com',
  TM_PREVIEW_URL: 'https://tagmanager.google.com',
  FONT_URL: 'https://fonts.googleapis.com',
  GSTATIC_SSL_URL: 'https://ssl.gstatic.com',
  GSTATIC_URL: 'https://www.gstatic.com',
  GA_URL: 'https://www.google-analytics.com',
  GA_URLS: 'https://*.google-analytics.com',
  AG_URLS: 'https://*.analytics.google.com',
  G_URLS: 'https://*.google.com',
  DOUBLE_CLICK_URLS: 'https://*.g.doubleclick.net',
  RECAPTCHA_URL: 'https://www.google.com',
  GOOGLE_PLACE_URL: 'https://maps.googleapis.com',
  GOOGLE_IMAGE_URL: 'maps.gstatic.com',
  YOUTUBE_URL: 'https://www.youtube.com',
  AD_SERVICES_URL: 'https://www.googleadservices.com',
  SYNDICATION_URL: 'https://*.googlesyndication.com',
};

export const externalAnalytics = {
  TABOOLA_URL: 'https://*.taboola.com',
  HOTJAR_URL: 'https://script.hotjar.com',
  HOTJAR_WSS: 'wss://ws.hotjar.com',
  HOTJAR_METRICS: 'https://metrics.hotjar.io',
  HOTJAR_VC: 'https://vc.hotjar.io',
  HOTJAR_IO: 'https://content.hotjar.io',
  HOTJAR_STATIC: 'https://static.hotjar.com',
  BING_URL: 'https://bat.bing.com',
  LINKEDIN_URL: 'https://*.ads.linkedin.com',
  LICDN_URL: 'https://snap.licdn.com',
  FACEBOOK_URL: 'https://*.facebook.com',
  FACEBOOK_NET_URL: 'https://*.facebook.net',
  PUSHER_URL: 'https://sockjs-us3.pusher.com',
  PUSHER_WSS: 'wss://ws-us3.pusher.com',
  CLARITY_URL: 'https://www.clarity.ms',
  CLARITY_ALL_URL: 'https://*.clarity.ms',
  GA_SSR: 'https://*.goldavenue.com',
};

export const googleDomains = [
  'https://*.google.ad',
  'https://*.google.ae',
  'https://*.google.com.af',
  'https://*.google.com.ag',
  'https://*.google.al',
  'https://*.google.am',
  'https://*.google.co.ao',
  'https://*.google.com.ar',
  'https://*.google.as',
  'https://*.google.at',
  'https://*.google.com.au',
  'https://*.google.az',
  'https://*.google.ba',
  'https://*.google.com.bd',
  'https://*.google.be',
  'https://*.google.bf',
  'https://*.google.bg',
  'https://*.google.com.bh',
  'https://*.google.bi',
  'https://*.google.bj',
  'https://*.google.com.bn',
  'https://*.google.com.bo',
  'https://*.google.com.br',
  'https://*.google.bs',
  'https://*.google.bt',
  'https://*.google.co.bw',
  'https://*.google.by',
  'https://*.google.com.bz',
  'https://*.google.ca',
  'https://*.google.cd',
  'https://*.google.cf',
  'https://*.google.cg',
  'https://*.google.ch',
  'https://*.google.ci',
  'https://*.google.co.ck',
  'https://*.google.cl',
  'https://*.google.cm',
  'https://*.google.cn',
  'https://*.google.com.co',
  'https://*.google.co.cr',
  'https://*.google.com.cu',
  'https://*.google.cv',
  'https://*.google.com.cy',
  'https://*.google.cz',
  'https://*.google.de',
  'https://*.google.dj',
  'https://*.google.dk',
  'https://*.google.dm',
  'https://*.google.com.do',
  'https://*.google.dz',
  'https://*.google.com.ec',
  'https://*.google.ee',
  'https://*.google.com.eg',
  'https://*.google.es',
  'https://*.google.com.et',
  'https://*.google.fi',
  'https://*.google.com.fj',
  'https://*.google.fm',
  'https://*.google.fr',
  'https://*.google.ga',
  'https://*.google.ge',
  'https://*.google.gg',
  'https://*.google.com.gh',
  'https://*.google.com.gi',
  'https://*.google.gl',
  'https://*.google.gm',
  'https://*.google.gr',
  'https://*.google.com.gt',
  'https://*.google.gy',
  'https://*.google.com.hk',
  'https://*.google.hn',
  'https://*.google.hr',
  'https://*.google.ht',
  'https://*.google.co.uk',
  'https://*.google.hu',
  'https://*.google.co.id',
  'https://*.google.ie',
  'https://*.google.co.il',
  'https://*.google.im',
  'https://*.google.co.in',
  'https://*.google.iq',
  'https://*.google.is',
  'https://*.google.it',
  'https://*.google.je',
  'https://*.google.com.jm',
  'https://*.google.jo',
  'https://*.google.co.jp',
  'https://*.google.co.ke',
  'https://*.google.com.kh',
  'https://*.google.ki',
  'https://*.google.kg',
  'https://*.google.co.kr',
  'https://*.google.com.kw',
  'https://*.google.kz',
  'https://*.google.la',
  'https://*.google.com.lb',
  'https://*.google.li',
  'https://*.google.lk',
  'https://*.google.co.ls',
  'https://*.google.lt',
  'https://*.google.lu',
  'https://*.google.lv',
  'https://*.google.com.ly',
  'https://*.google.co.ma',
  'https://*.google.md',
  'https://*.google.me',
  'https://*.google.mg',
  'https://*.google.mk',
  'https://*.google.ml',
  'https://*.google.com.mm',
  'https://*.google.mn',
  'https://*.google.com.mt',
  'https://*.google.mu',
  'https://*.google.mv',
  'https://*.google.mw',
  'https://*.google.com.mx',
  'https://*.google.com.my',
  'https://*.google.co.mz',
  'https://*.google.com.na',
  'https://*.google.com.ng',
  'https://*.google.com.ni',
  'https://*.google.ne',
  'https://*.google.nl',
  'https://*.google.no',
  'https://*.google.com.np',
  'https://*.google.nr',
  'https://*.google.nu',
  'https://*.google.co.nz',
  'https://*.google.com.om',
  'https://*.google.com.pa',
  'https://*.google.com.pe',
  'https://*.google.com.pg',
  'https://*.google.com.ph',
  'https://*.google.com.pk',
  'https://*.google.pl',
  'https://*.google.pn',
  'https://*.google.com.pr',
  'https://*.google.ps',
  'https://*.google.pt',
  'https://*.google.com.py',
  'https://*.google.com.qa',
  'https://*.google.ro',
  'https://*.google.ru',
  'https://*.google.rw',
  'https://*.google.com.sa',
  'https://*.google.com.sb',
  'https://*.google.sc',
  'https://*.google.se',
  'https://*.google.com.sg',
  'https://*.google.sh',
  'https://*.google.si',
  'https://*.google.sk',
  'https://*.google.com.sl',
  'https://*.google.sn',
  'https://*.google.so',
  'https://*.google.sm',
  'https://*.google.sr',
  'https://*.google.st',
  'https://*.google.com.sv',
  'https://*.google.td',
  'https://*.google.tg',
  'https://*.google.co.th',
  'https://*.google.com.tj',
  'https://*.google.tl',
  'https://*.google.tm',
  'https://*.google.tn',
  'https://*.google.to',
  'https://*.google.com.tr',
  'https://*.google.tt',
  'https://*.google.com.tw',
  'https://*.google.co.tz',
  'https://*.google.com.ua',
  'https://*.google.co.ug',
  'https://*.google.co.uk',
  'https://*.google.com.uy',
  'https://*.google.co.uz',
  'https://*.google.com.vc',
  'https://*.google.co.ve',
  'https://*.google.co.vi',
  'https://*.google.com.vn',
  'https://*.google.vu',
  'https://*.google.ws',
  'https://*.google.rs',
  'https://*.google.co.za',
  'https://*.google.co.zm',
  'https://*.google.co.zw',
  'https://*.google.cat',
];
