import { FC, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { useScrollDirection } from '@core/hook';
import { EColor, EPosition, ThemeProps } from '@core/type';

import { DropdownBase, DropdownCloseArea } from '../dropdown';
import { DropdownBaseProps } from '../dropdown/interface-dropdown';
import { Box } from '../layout';
import { InputExtendable } from './InputExtendable';
import { AutocompleteProps } from './interface-input';

const AutocompleteDropdown = ({
  children,
  onClose,
  isOpen,
  isCustom,
}: DropdownBaseProps & { isCustom: boolean }) => (
  <>
    <DropdownCloseArea onClose={onClose} isOpen={isOpen} />

    {isCustom ? (
      <DropdownBase
        onClose={onClose}
        isOpen={isOpen}
        width={'100%'}
        minWidth={'inherit'}
        verticalPosition={EPosition.BOTTOM}
        verticalPositionProps={{
          [EPosition.TOP]: null,
          [EPosition.BOTTOM]: {
            top: '2px',
            bottom: 'auto',
          },
        }}
        fixedMaxHeight={500}
        borderColor={EColor.INFO}
        borderWidth={'1px'}
      >
        {children}
      </DropdownBase>
    ) : (
      <DropdownBase
        onClose={onClose}
        isOpen={isOpen}
        width={'100%'}
        minWidth="inherit"
        overflowX="hidden"
        overflowY="auto"
        verticalPosition={EPosition.BOTTOM}
        verticalPositionProps={{
          [EPosition.TOP]: null,
          [EPosition.BOTTOM]: {
            top: '33px',
            bottom: 'auto',
          },
        }}
        fixedMaxHeight={260}
      >
        <Box
          maxHeight={'228px'}
          overflow={'auto'}
          height={'auto'}
          position="relative"
          whiteSpace="wrap"
        >
          <Box position="relative" overflow="auto" width="auto" height="auto">
            {children}
          </Box>
        </Box>
      </DropdownBase>
    )}
  </>
);

export const Autocomplete: FC<AutocompleteProps> = ({
  icon,
  placeholder,
  setSearchTerm,
  children,
  onSubmit,
  searchTerm,
  isOpenInput,
  setIsOpenInput,
  isCustom,
}) => {
  const [isOpenDropDown, setIsOpenDropdown] = useState<boolean>(false);
  const { isDown, isUp } = useScrollDirection(0);

  const {
    structure: {
      autocomplete: { autocompleteWidth },
    },
  } = useTheme() as ThemeProps;

  useEffect(() => {
    if (isDown || isUp) {
      setIsOpenDropdown(false);
      setIsOpenInput(false);
    }
  }, [isDown, isUp, setIsOpenDropdown, setIsOpenInput]);

  useEffect(() => {
    if (children) {
      setIsOpenDropdown(!!searchTerm);
    }
  }, [children, searchTerm, setIsOpenDropdown]);

  const handleChange = (inputValue: string) => {
    setSearchTerm(inputValue);
  };

  return (
    <Box
      position="relative"
      display="flex"
      padding={0}
      width={autocompleteWidth}
      justifyContent="flex-end"
    >
      {!isCustom && (
        <InputExtendable
          icon={icon}
          placeholder={placeholder}
          onChange={handleChange}
          value={searchTerm}
          onPressEnter={onSubmit}
          isOpenInput={isOpenInput}
          setIsOpenInput={setIsOpenInput}
        />
      )}
      <AutocompleteDropdown
        onClose={() => setIsOpenDropdown(false)}
        isOpen={isOpenDropDown && isOpenInput}
        isCustom={isCustom}
      >
        {children}
      </AutocompleteDropdown>
    </Box>
  );
};
