import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import {
  PageRatingRequest,
  PageRatingResponse,
  PatchPageRatingEntity,
  SpotPriceCaratsRequest,
  SpotPriceCaratsResponse,
  SpotPriceCurrenciesRequest,
  SpotPriceCurrenciesResponse,
  SpotPriceHistoryRequest,
  SpotPriceHistoryResponse,
  SpotPriceWeightsRequest,
  SpotPriceWeightsResponse,
} from '@core/type/api';

import { Api } from '../constants/api';
import { replaceIdUrl } from '../helpers/api';
import { configResponse, EConfigName } from '../helpers/config';
import { addParameters } from '../helpers/util';

const getSpotPriceHistory = async (
  axiosInstance: AxiosInstance,
  { language, ...params }: LanguageProps & SpotPriceHistoryRequest,
): Promise<AxiosResponse<SpotPriceHistoryResponse>> =>
  axiosInstance.get(
    addParameters(Api.widget.SPOT_PRICE_HISTORY, {
      metal_iso: params.metalIso,
      currency_iso: params.currencyIso,
    }),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const getSpotPriceCarats = async (
  axiosInstance: AxiosInstance,
  { language, ...params }: LanguageProps & SpotPriceCaratsRequest,
): Promise<AxiosResponse<SpotPriceCaratsResponse>> => {
  return axiosInstance.get(
    addParameters(Api.widget.DAILY_SPOT_PRICE_CARATS, {
      metal_iso: params.metalIso,
      currency_iso: params.currencyIso,
      weight_unit: params.weightUnit,
    }),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getSpotPriceCurrencies = async (
  axiosInstance: AxiosInstance,
  { language, ...params }: LanguageProps & SpotPriceCurrenciesRequest,
): Promise<AxiosResponse<SpotPriceCurrenciesResponse>> => {
  return axiosInstance.get(
    addParameters(Api.widget.DAILY_SPOT_PRICE_CURRENCIES, {
      metal_iso: params.metalIso,
      weight_unit: params.weightUnit,
    }),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getSpotPriceWeights = async (
  axiosInstance: AxiosInstance,
  { language, ...params }: LanguageProps & SpotPriceWeightsRequest,
): Promise<AxiosResponse<SpotPriceWeightsResponse>> => {
  return axiosInstance.get(
    addParameters(Api.widget.DAILY_SPOT_PRICE_WEIGHTS, {
      metal_iso: params.metalIso,
      currency_iso: params.currencyIso,
    }),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getPageRating = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & PageRatingRequest,
): Promise<AxiosResponse<PageRatingResponse>> => {
  return axiosInstance.get(
    replaceIdUrl(Api.widget.PAGE_RATING, data.pageId),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const patchPageRating = (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & PatchPageRatingEntity,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.patch(
    addParameters(replaceIdUrl(Api.widget.PAGE_RATING, data.pageId), {
      rating: data.rating,
      recaptcha_token: data.recaptchaToken,
    }),
    null,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class WidgetAPI {
  public static readonly getSpotPriceHistory = getSpotPriceHistory;
  public static readonly getSpotPriceCarats = getSpotPriceCarats;
  public static readonly getSpotPriceCurrencies = getSpotPriceCurrencies;
  public static readonly getSpotPriceWeights = getSpotPriceWeights;
  public static readonly getPageRating = getPageRating;
  public static readonly patchPageRating = patchPageRating;
}
