import { createContext } from 'react';

import type { CartPopupHook, CartPopupState, HookStore } from '@core/type/context';

import { useFnContext, useStoreContext } from '../util';

export const cartPopupContext = createContext<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  HookStore<any, CartPopupState, CartPopupHook>
>(null);

export const useContextCartPopup = () => {
  return useFnContext(cartPopupContext);
};

export function useStoreCartPopup<Selector>(selector: keyof CartPopupState) {
  return useStoreContext<Selector, CartPopupState, CartPopupHook>(selector, cartPopupContext);
}
