import Image from 'next/image';

import IconLBMALogoSvg from './svgs/icon-lbma-logo.svg';

export const IconLBMALogo = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconLBMALogoSvg}
    alt="LBMA logo"
    width={112}
    height={86}
  />
);
