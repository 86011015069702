import withTranslation from 'next-translate/withTranslation';

import { Translate } from '@core/constant';
import { EActionAttribute, EColor, WithTranslation } from '@core/type';

import { useContextCrisp } from '@crisp';

import { Link } from './Link';
import { LinkLiveChatProps } from './interface-link';

const LinkLiveChatContainer = ({
  text,
  i18n: { t },
  ...props
}: LinkLiveChatProps & WithTranslation): JSX.Element => {
  const { openChat } = useContextCrisp();

  return (
    <Link as={EActionAttribute.BUTTON} onClick={openChat} color={EColor.ACCENT} {...props}>
      {text ?? t('liveChat.title')}
    </Link>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const LinkLiveChat = withTranslation(LinkLiveChatContainer, Translate.common.BUTTON);
