import { useStoreUser } from '@core/context';
import { ECurrency, ELanguage, EUserStatus } from '@core/type';
import { PhoneEntity, EStorageFeeStatus } from '@core/type/api';
import { HookStoreFn, UserHook, UserState } from '@core/type/context';

export const useUser: HookStoreFn<UserState, UserHook> = (): UserHook => {
  const [firstName] = useStoreUser<string>('firstName');
  const [lastName] = useStoreUser<string>('lastName');
  const [phone] = useStoreUser<PhoneEntity>('phone');
  const [email, setEmail] = useStoreUser<string>('email');
  const [language] = useStoreUser<ELanguage>('language');
  const [currency] = useStoreUser<ECurrency>('currency');
  const [status, setStatus] = useStoreUser<EUserStatus>('status');
  const [isReady] = useStoreUser<boolean>('isReady');
  const [hasMfaSms] = useStoreUser<boolean>('hasMfaSms');
  const [hasMfaTotp] = useStoreUser<boolean>('hasMfaTotp');
  const [isLogged] = useStoreUser<boolean>('isLogged');
  const [isRedirectedToSignIn, setIsRedirectedToSignIn] =
    useStoreUser<boolean>('isRedirectedToSignIn');
  const [isDisconnecting, setIsDisconnecting] = useStoreUser<boolean>('isDisconnecting');
  const [referenceNumber] = useStoreUser<string>('referenceNumber');
  const [hasNotCompletedStep] = useStoreUser<boolean>('hasNotCompletedStep');
  const [storageFeesStatus] = useStoreUser<EStorageFeeStatus>('storageFeesStatus');

  const fullName = firstName && lastName ? `${firstName} ${lastName}` : firstName || lastName;

  return {
    fullName,
    firstName,
    lastName,
    phone,
    email,
    status,
    hasMfaSms,
    hasMfaTotp,
    language,
    currency,
    referenceNumber,
    hasNotCompletedStep,
    storageFeesStatus,
    isLogged,
    isReady,
    setStatus,
    setEmail,
    setIsDisconnecting,
    isDisconnecting,
    isRedirectedToSignIn,
    setIsRedirectedToSignIn,
  };
};
