import { ECurrency, ELanguageTags } from '@core/type';

const getStaticPhoneNumberPropsByCurrency = (formattedPhoneNumber: string) =>
  (Object.values(ECurrency) as ECurrency[]).reduce(
    (obj, cur) => {
      obj[cur] = formattedPhoneNumber;

      return obj;
    },
    {} as Record<ECurrency, string>,
  );

export const localizedCurrencyCustomerContacts: Record<ELanguageTags, Record<ECurrency, string>> = {
  [ELanguageTags.EN_US]: getStaticPhoneNumberPropsByCurrency('+41 22 518 92 11'),
  [ELanguageTags.FR_FR]: {
    ...getStaticPhoneNumberPropsByCurrency('+33 1 89 71 29 67'),
    [ECurrency.CHF]: '+41 22 518 92 12',
  },
  [ELanguageTags.IT_IT]: getStaticPhoneNumberPropsByCurrency('+41 22 518 92 16'),
  [ELanguageTags.DE_DE]: {
    ...getStaticPhoneNumberPropsByCurrency('+49 800 7244869'),
    [ECurrency.CHF]: '+41 22 518 92 13',
  },
  [ELanguageTags.DEFAULT]: getStaticPhoneNumberPropsByCurrency('+41 22 518 92 11'),
};
