/* eslint-disable @typescript-eslint/no-magic-numbers */
import { useContextMediaQuery } from '@core/context';

import { Grid } from '../grid';
import { SkeletonTypography } from './SkeletonTypography';
import { SkeletonWrapper } from './SkeletonWrapper';
import { FaqHeader } from './components';

export const SkeletonFaq = () => {
  const { isPhone, isMobile } = useContextMediaQuery();

  const spacing = isPhone ? 16 : 48;

  return (
    <>
      <FaqHeader />

      <SkeletonWrapper marginTop={'64px'}>
        <Grid spacing={spacing} isContainer>
          {[...Array(isMobile ? 4 : 6).keys()].map((index: number) => (
            <Grid key={index} xs={12} md={6} lg={4} spacing={spacing}>
              <SkeletonTypography lineHeight={'140px'} borderRadius={'8px'} />
            </Grid>
          ))}
        </Grid>
      </SkeletonWrapper>
    </>
  );
};
