import Router from 'next/router';

import { Logger } from '@core/logger';
import { ELanguageTags, ERouting, LinkNext } from '@core/type';
import {
  getDefaultLanguageSSR,
  getFullUrl as getFullUrlWithoutPathTranslation,
  getKey,
  getLanguageFromTags,
  getLinkNext,
  getRouteFromDefault as getRouteFromDefaultWithoutPathTranslation,
  getTranslatedRoute,
} from '@core/util';

import { defaultRoute, pathTranslations } from '../constants';

// Used only for Metatag
export const getFullUrl = (
  host: string,
  language: ELanguageTags,
  keyRoute: ERouting,
  query?: NodeJS.Dict<string | string[]>,
): string => {
  return getFullUrlWithoutPathTranslation(
    host,
    getDefaultLanguageSSR(language),
    keyRoute,
    pathTranslations,
    query,
  );
};

export const getRelativeUrl = (key: ERouting, language: ELanguageTags): string => {
  if (key === ERouting.HOME && language === ELanguageTags.EN_US) {
    return '/';
  }
  return `/${getLanguageFromTags(language)}${
    getRoute(key, language) !== '/' ? getRoute(key, language) : ''
  }`;
};

export const getKeyRoute = (route: string, language: ELanguageTags): ERouting => {
  return route ? getKey(pathTranslations[getDefaultLanguageSSR(language)], route) : null;
};

export const getRoute = (key: ERouting, language: ELanguageTags): string => {
  return getTranslatedRoute(key, getDefaultLanguageSSR(language), pathTranslations);
};

export const getLink = (
  key: ERouting,
  language: ELanguageTags,
  query?: NodeJS.Dict<string | string[]>,
): LinkNext => {
  return getLinkNext(key, getDefaultLanguageSSR(language), pathTranslations, defaultRoute, query);
};

export const redirect = async (
  key: ERouting,
  language?: ELanguageTags,
  query?: NodeJS.Dict<string | string[]>,
): Promise<void> => {
  const link = getLinkNext(key, language, pathTranslations, defaultRoute, query);
  if (link?.href) {
    Logger.logInfo(
      `Call redirect <{href:${JSON.stringify(link.href)},as:${JSON.stringify(
        link.as,
      )}, language:${language}}>`,
      { language, route: key, query: query },
    );

    await Router.push(link.href, link.as, {
      shallow: true,
      scroll: true,
      locale:
        language === ELanguageTags.DEFAULT && key === ERouting.HOME
          ? null
          : getLanguageFromTags(language),
    });
  }
};

export const getRouteFromDefaults = (route: string, language: ELanguageTags): string => {
  return getRouteFromDefaultWithoutPathTranslation(
    route,
    getDefaultLanguageSSR(language),
    pathTranslations,
    defaultRoute,
  );
};
