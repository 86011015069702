/* eslint-disable @typescript-eslint/no-magic-numbers */
import { useContextMediaQuery } from '@core/context';

import { Grid } from '../grid';
import { SkeletonTypography } from './SkeletonTypography';
import { SkeletonWrapper } from './SkeletonWrapper';

const SkeletonArticle = ({ isPhone }: { isPhone: boolean }) => (
  <SkeletonWrapper>
    <SkeletonTypography lineHeight={'208px'} />
    <SkeletonTypography width={'215px'} margin={'32px 0 18px'} />
    <SkeletonTypography lineHeight={'29px'} marginBottom={'4px'} />
    <SkeletonTypography
      lineHeight={'29px'}
      width={isPhone ? '200px' : '90%'}
      marginBottom={'10px'}
    />
    <SkeletonTypography lineHeight={'98px'} marginBottom={'18px'} />
    <SkeletonTypography lineHeight={'25px'} width={'100px'} />
  </SkeletonWrapper>
);

export const SkeletonBlogList = () => {
  const { isPhone } = useContextMediaQuery();

  return (
    <Grid isContainer rowSpacing={64} columnSpacing={30}>
      {[...Array(isPhone ? 2 : 6).keys()].map((index: number) => (
        <Grid key={index} xs={12} md={6} lg={4}>
          <SkeletonArticle isPhone={isPhone} />
        </Grid>
      ))}
    </Grid>
  );
};
