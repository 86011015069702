import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { WishlistResponseEntity, WishlistUpdateResponseEntity } from '@core/type/api';

import { WishListAPI } from '../api/wishlist';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
): Promise<R> => {
  return logService('WishlistService', methodName, dto, apiCall, true);
};

const getWishlist = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<WishlistResponseEntity> => {
  return log('getWishlist', { axiosInstance, language }, WishListAPI.getWishlist);
};

const addProductToWishlist = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  productId: number,
): Promise<WishlistUpdateResponseEntity> => {
  return log('addToWishlist', { axiosInstance, language, productId }, WishListAPI.addToWishlist);
};

const deleteProductFromWishList = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  productId: number,
): Promise<void> => {
  return log('deleteWishlist', { axiosInstance, language, productId }, WishListAPI.deleteWishlist);
};

export class WishlistService {
  public static readonly getWishlist = getWishlist;
  public static readonly addProductToWishlist = addProductToWishlist;
  public static readonly deleteProductFromWishList = deleteProductFromWishList;
}
