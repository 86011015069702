import { ActionType, EActionAttribute } from './action';
import { ColorType, EColor, EColorName } from './color';
import { ThemePalette } from './interface-theme-palette';
import { ETypographyVariant, TypographyVariantType } from './typography';

export enum ELink {
  WITHOUT_TEXT_ON_MOBILE = 'withoutTextOnMobile',
  SWITCH_TEXT_ON_MOBILE = 'switchTextOnMobile',
  LARGE_ICON_ON_MOBILE = 'largeIconOnMobile',
}

export enum ELinkAnimation {
  ARROW_RIGHT = 'arrowRight',
  ARROW_DOWN = 'arrowDown',
}

export type LinkAction = keyof ActionType<
  | EActionAttribute.LINK
  | EActionAttribute.H2
  | EActionAttribute.H3
  | EActionAttribute.H1
  | EActionAttribute.BUTTON
  | EActionAttribute.SPAN
  | EActionAttribute.INPUT
>;

export type LinkVariant = keyof TypographyVariantType<
  | ETypographyVariant.BODY1
  | ETypographyVariant.H1
  | ETypographyVariant.H2
  | ETypographyVariant.H3
  | ETypographyVariant.H4
  | ETypographyVariant.H5
  | ETypographyVariant.H6
  | ETypographyVariant.BODY2
  | ETypographyVariant.BODY3
  | ETypographyVariant.CAPTION1
  | ETypographyVariant.CAPTION2
  | ETypographyVariant.CAPTION3
>;

export type LinkColorType = keyof ColorType<
  | EColor.PRIMARY
  | EColor.SECONDARY
  | EColor.ACCENT
  | EColor.DESTRUCTIVE
  | EColor.GRAY
  | EColor.WHITE
  | EColor.SUCCESS
  | EColor.WARNING
  | EColor.INFO
  | EColor.NEUTRAL
  | EColor.DANGER
>;

export type LinkColorProps = {
  color: EColorName;
  hover: {
    color: EColorName;
  };
};

export type LinkColorHandler = (props: {
  color: LinkColorType;
  palette: ThemePalette;
  isDisabled?: boolean;
}) => LinkColorProps;
