import { util } from '@core/constant';

const setCookie = (name: string, value = '', expiresDays?: number) => {
  let expires = '';
  if (expiresDays) {
    const date = new Date();
    date.setTime(date.getTime() + expiresDays * util.EXPIRE_COOKIE_COEF);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = `${name}=${value}${expires}; path=/`;
};

const getCookie = (name: string) => {
  const cname = name + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let value of ca) {
    while (value.startsWith(' ')) {
      value = value.substring(1);
    }
    if (value.startsWith(cname)) {
      return value.substring(cname.length, value.length);
    }
  }
  return '';
};

const deleteCookie = (name: string) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export class CookieHelper {
  public static readonly setItem = setCookie;
  public static readonly getItem = getCookie;
  public static readonly removeItem = deleteCookie;
}
