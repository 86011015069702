import { BoxProps, TypographyProps } from '../interface';

export enum ELoaderAnimation {
  PULSE = 'pulse',
  WAVE = 'wave',
}

export type SkeletonBoxProps = BoxProps & {
  loaderAnimation?: ELoaderAnimation;
};

export type SkeletonTypographyProps = TypographyProps & {
  borderRadius?: string | number;
};
