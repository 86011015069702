import withTranslation from 'next-translate/withTranslation';
import type { FC } from 'react';

import { Translate } from '@core/constant';

import { useContextCrisp } from '@crisp';

import { Button } from './Button';
import { ButtonCustomProps, ButtonLiveChatProps } from './interface-button';

const ButtonLiveChatContainer: FC<Omit<ButtonCustomProps, 'onClick'>> = ({
  text,
  i18n: { t },
  ...props
}: Omit<ButtonCustomProps, 'onClick'> & ButtonLiveChatProps): JSX.Element => {
  const { openChat } = useContextCrisp();

  return (
    <Button onClick={openChat} {...props}>
      {text ?? t('liveChat.title')}
    </Button>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const ButtonLiveChat = withTranslation(ButtonLiveChatContainer, Translate.common.BUTTON);
