import { useContextMediaQuery } from '@core/context';

import { Grid } from '../grid';
import { SkeletonTypography } from './SkeletonTypography';
import { SkeletonWrapper } from './SkeletonWrapper';
import { FaqHeader } from './components';

export const SkeletonFaqShow = () => {
  const { isPhone, isMobile } = useContextMediaQuery();

  return (
    <>
      <FaqHeader />

      <SkeletonWrapper paddingTop={isPhone ? '56px' : '64px'}>
        <Grid isContainer>
          <Grid xs={12} md={5} lg={4}>
            <SkeletonTypography
              lineHeight={isPhone ? '75px' : '108px'}
              borderRadius={'8px'}
              marginRight={isPhone ? undefined : '24px'}
            />
          </Grid>

          <Grid xs={12} md={7} lg={8}>
            <SkeletonTypography
              lineHeight={'252px'}
              marginLeft={isMobile ? undefined : '80px'}
              marginTop={isPhone ? '46px' : undefined}
            />
          </Grid>
        </Grid>
      </SkeletonWrapper>
    </>
  );
};
