import { useStoreRedirectAfterSignInRoute } from '@core/context';
import { ERouting } from '@core/type';
import {
  HookStoreFn,
  RedirectAfterSignInRouteHook,
  RedirectAfterSignInRouteState,
} from '@core/type/context';

export const useRedirectAfterSignInRoute: HookStoreFn<
  RedirectAfterSignInRouteState,
  RedirectAfterSignInRouteHook
> = (): RedirectAfterSignInRouteHook => {
  const [route, setRoute] = useStoreRedirectAfterSignInRoute<ERouting>('route');
  const [query, setQuery] = useStoreRedirectAfterSignInRoute<Record<string, string>>('query');

  return {
    route,
    query,
    setRoute,
    setQuery,
  };
};
