import { LinkProps } from 'next/link';
import { forwardRef } from 'react';

import { useContextRouting, useContextUtil } from '@core/context';
import { useLanguage } from '@core/hook';
import { ELanguage, ERouting } from '@core/type';
import { getLanguageFromTags, getLanguageTags, getLinkNext, getTypeRoute } from '@core/util';

import { NextLinkStyledAsLink } from './StyledLinkNext';
import { LinkTranslatedProps } from './interface-link-translated';

export const LinkTranslatedStyledAsLink = forwardRef<HTMLAnchorElement, LinkTranslatedProps>(
  (
    {
      keyRoute,
      children,
      language,
      disabled,
      pointerEvents,
      disableScroll,
      disableShallow,
      enableAnimation,
      query = null,
      href: hrefProp,
      locale,
      rel: relProps,
      ...restProps
    }: LinkTranslatedProps,
    ref,
  ) => {
    const { pathTranslations, defaultRoute } = useContextRouting();
    const { isBotDetected } = useContextUtil();
    const { locale: currentLanguage } = useLanguage();

    const languageLink = language ? getLanguageFromTags(language) : currentLanguage;

    if (!keyRoute && !hrefProp) {
      return <>{children}</>;
    }

    const typeRoute = keyRoute ? getTypeRoute(keyRoute) : null;
    const rel = typeRoute?.noIndex ? 'nofollow' : relProps;

    let link: LinkProps = getLinkNext(
      keyRoute,
      getLanguageTags(languageLink),
      pathTranslations,
      defaultRoute,
      query,
    );

    if (isBotDetected) {
      link = {
        ...link,
        prefetch: false,
      };
    }

    const { as: forwardedAs, href, ...restLink } = link;

    const languageLinkByRoute =
      keyRoute === ERouting.HOME &&
      (languageLink === ELanguage.EN || languageLink === ELanguage.DEFAULT)
        ? ELanguage.DEFAULT
        : languageLink === ELanguage.DEFAULT
          ? ELanguage.EN
          : languageLink;

    return (
      <NextLinkStyledAsLink
        {...restLink}
        href={hrefProp || (href as string)}
        forwardedAs={forwardedAs}
        scroll={!disableScroll}
        shallow={!disableShallow}
        enableAnimation={enableAnimation}
        locale={locale ?? languageLinkByRoute}
        ref={ref}
        pointerEvents={disabled ? 'none' : pointerEvents}
        rel={rel}
        {...restProps}
      >
        {children}
      </NextLinkStyledAsLink>
    );
  },
);

LinkTranslatedStyledAsLink.displayName = 'LinkTranslatedStyledAsLink';
