import { EPosition } from '@core/type';
import { getTranslationWithOrientation } from '@core/util';

import { SVG } from './SVG';
import { DynamicIconProps, IconOrientationProps } from './interface-icon';

export const IconCircleArrow = ({
  orientation = EPosition.LEFT,
  yTranslation,
  ...restProps
}: IconOrientationProps) => {
  return (
    <IconCircleArrowSVG
      transform={getTranslationWithOrientation(orientation, yTranslation)}
      {...restProps}
    />
  );
};

const IconCircleArrowSVG = ({ fill, transform }: DynamicIconProps) => (
  <SVG height={16} width={16} viewBox="0 0 16 16" title="Circle arrow icon" transform={transform}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00391 1.20049C11.7594 1.20049 14.8039 4.24499 14.8039 8.00049C14.8039 11.756 11.7594 14.8005 8.00391 14.8005C4.24837 14.8005 1.20391 11.756 1.20391 8.00049C1.20391 4.24499 4.24837 1.20049 8.00391 1.20049ZM8.00391 0.000488281C12.4222 0.000488281 16.0039 3.58219 16.0039 8.00049C16.0039 12.4188 12.4222 16.0005 8.00391 16.0005C3.58563 16.0005 0.00390625 12.4188 0.00390625 8.00049C0.00390625 3.58219 3.58563 0.000488281 8.00391 0.000488281ZM4.8282 9.82514C4.59388 10.0595 4.21398 10.0595 3.97967 9.82514C3.74536 9.59083 3.74536 9.21093 3.97967 8.97662L7.57967 5.37659L8.00393 4.95239L8.4282 5.37659L12.0282 8.97662C12.2625 9.21093 12.2625 9.59083 12.0282 9.82515C11.7939 10.0595 11.414 10.0595 11.1797 9.82515L8.00393 6.64941L4.8282 9.82514Z"
      fill={fill}
    />
  </SVG>
);
