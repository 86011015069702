import type { ReactNode } from 'react';

import { useLanguage } from '@core/hook';
import { getLanguageFromTags } from '@core/util';

import { CrispStyle } from './CrispStyle';
import { crispContext } from './context-crisp';
import { useCrisp } from './use-crisp';

export const CrispProvider = ({
  children,
  crispWebsiteId,
  enableCrisp,
  zIndex,
}: {
  children: ReactNode;
  crispWebsiteId: string;
  enableCrisp: boolean;
  zIndex: number;
}) => {
  const { language } = useLanguage();

  return (
    <crispContext.Provider
      value={useCrisp(getLanguageFromTags(language), crispWebsiteId, zIndex, enableCrisp)}
    >
      {enableCrisp && <CrispStyle />}
      {children}
    </crispContext.Provider>
  );
};
