import { FC } from 'react';

import { Box } from '../layout';
import { SelectBaseContentProps } from './interface-select';

export const SelectBaseContent: FC<SelectBaseContentProps> = ({ children, ...restProps }) => (
  <Box
    height={'100%'}
    display={'flex'}
    alignItems={'center'}
    flexShrink={0}
    flexGrow={1}
    justifyContent={'space-between'}
    {...restProps}
  >
    {children}
  </Box>
);
