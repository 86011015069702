import type { AxiosInstance, AxiosResponse } from 'axios';

import { UserStorage } from '@core/storage';
import { ELanguageTags, EStorageType } from '@core/type';
import type {
  AddressPasswordReq,
  AutoSavingsProductEntity,
  BankAccountEntity,
  CertificateDatesEntity,
  CurrencyEntity,
  DateIntervalEntity,
  DeleteBankAccountReq,
  DeleteCreditCardEntity,
  DepositDetailsEntity,
  FraudCreditCardReq,
  InitializeCreditCardReq,
  ListBankAccountsEntity,
  NewCreditCardReq,
  NewsletterSubscriptionEntity,
  PasswordEntity,
  PhoneEntity,
  PostBankAccountReq,
  PostReferralProgramEntity,
  PostWithdrawalEntity,
  ReferralAdressEntity,
  ReferralProgramEntity,
  StorageFeesInvoicesEntity,
  StorageFeesRequestEntity,
  StorageFeesResponseEntity,
  TotpEntity,
  UpdateEmergencyPeopleEntity,
  UpdateLoginAlertEntity,
  UpdatePasswordEntity,
  UpdateUserInformationEntity,
  UserAutoSavingsProductsEntity,
  UserNewsletterSubscriptionEntity,
} from '@core/type/api';
import {
  PatchAutoSavingsProductEntity,
  PaymentTransactionReq,
  PurchasingLimitEntity,
  StorageFeesInvoicesReq,
  UserEntity,
} from '@core/type/api';

import { UserAPI } from '../api/user';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
): Promise<R> => {
  return logService('UserService', methodName, dto, apiCall, true);
};

const getProfile = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<UserEntity> => {
  return log('getProfile', { axiosInstance, language }, UserAPI.getProfile);
};

const getCertificateDates = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<CertificateDatesEntity> => {
  return log('getCertificateDates', { axiosInstance, language }, UserAPI.getCertificateDates);
};

const getBankAccounts = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<ListBankAccountsEntity> => {
  return log('getBankAccounts', { axiosInstance, language }, UserAPI.getBankAccounts);
};

const getDepositDetails = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<DepositDetailsEntity> => {
  return log('getDepositDetails', { axiosInstance, language }, UserAPI.getDepositDetails);
};

const getDepositDetailsPdf = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<Blob> => {
  return log('getDepositDetails', { axiosInstance, language }, UserAPI.getDepositDetailsPdf);
};

const deleteBankAccount = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: DeleteBankAccountReq,
): Promise<void> => {
  return log('deleteBankAccount', { axiosInstance, language, ...data }, UserAPI.deleteBankAccount);
};

const getNewsletterSubscriptions = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<NewsletterSubscriptionEntity> => {
  return log(
    'getNewsletterSubscriptions',
    { axiosInstance, language },
    UserAPI.getNewsletterSubscriptions,
  );
};

const getNewsletterSubscriptionsWithToken = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  token: string,
): Promise<UserNewsletterSubscriptionEntity> => {
  return log(
    'getNewsletterSubscriptionsWithToken',
    { axiosInstance, language, token },
    UserAPI.getNewsletterSubscriptionsWithToken,
  );
};

const putProfile = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: UpdateUserInformationEntity,
): Promise<void> => log('putProfile', { axiosInstance, language, ...data }, UserAPI.putProfile);

const putLoginAlert = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: UpdateLoginAlertEntity,
): Promise<void> =>
  log('putLoginAlert', { axiosInstance, language, ...data }, UserAPI.putLoginAlert);

const putPassword = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: UpdatePasswordEntity,
): Promise<void> => log('putProfile', { axiosInstance, language, ...data }, UserAPI.putPassword);

const putEmergencyPeople = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: UpdateEmergencyPeopleEntity,
): Promise<void> => {
  return log(
    'putEmergencyPeople',
    { axiosInstance, language, ...data },
    UserAPI.putEmergencyPeople,
  );
};

const deleteCreditCard = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: DeleteCreditCardEntity,
): Promise<void> => {
  return log('deleteCreditCard', { axiosInstance, language, ...data }, UserAPI.deleteCreditCard);
};

const deleteSms = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: PasswordEntity,
): Promise<void> => {
  return log('deleteSms', { axiosInstance, language, ...data }, UserAPI.deleteSms);
};

const deleteTotp = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: PasswordEntity,
): Promise<void> => {
  return log('deleteTotp', { axiosInstance, language, ...data }, UserAPI.deleteTotp);
};

const postEmergencyPeople = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: UpdateEmergencyPeopleEntity,
): Promise<void> => {
  return log(
    'postEmergencyPeople',
    { axiosInstance, language, ...data },
    UserAPI.postEmergencyPeople,
  );
};

const postAddresses = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: AddressPasswordReq,
): Promise<void> => {
  return log('postAddresses', { axiosInstance, language, ...data }, UserAPI.postAddresses);
};

const postSms = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: { phone: PhoneEntity },
): Promise<void> => {
  return log('postSMS', { axiosInstance, language, ...data }, UserAPI.postSMS);
};

const getSms = async (axiosInstance: AxiosInstance, language: ELanguageTags): Promise<void> => {
  return log('getSMS', { axiosInstance, language }, UserAPI.getSMS);
};

const putSms = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: { code: string },
): Promise<void> => {
  return log('putSMS', { axiosInstance, language, ...data }, UserAPI.putSMS);
};

const putTotp = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: { code: string },
): Promise<void> => {
  return log('putTotp', { axiosInstance, language, ...data }, UserAPI.putTotp);
};

const postTotp = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<TotpEntity> => {
  return log('postTotp', { axiosInstance, language }, UserAPI.postTotp);
};

const postCertificate = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: DateIntervalEntity,
): Promise<Blob> => {
  return log('postCertificate', { axiosInstance, language, ...data }, UserAPI.postCertificate);
};

const postBankAccount = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: PostBankAccountReq,
): Promise<{ bankAccount: BankAccountEntity }> => {
  return log('postBankAccount', { axiosInstance, language, ...data }, UserAPI.postBankAccount);
};

const postWithdrawal = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: PostWithdrawalEntity,
): Promise<void> => {
  return log('postWithdrawal', { axiosInstance, language, ...data }, UserAPI.postWithdrawal);
};

const putNewsletterSubscriptions = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: UserNewsletterSubscriptionEntity,
): Promise<void> => {
  return log(
    'putNewsletterSubscriptions',
    { axiosInstance, language, ...data },
    UserAPI.putNewsletterSubscriptions,
  );
};

const putNewsletterSubscriptionsWithToken = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: UserNewsletterSubscriptionEntity & { token: string },
): Promise<void> => {
  return log(
    'putNewsletterSubscriptionsWithToken',
    { axiosInstance, language, ...data },
    UserAPI.putNewsletterSubscriptionsWithToken,
  );
};

const getPurchasingLimit = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<PurchasingLimitEntity> =>
  log('getPurchasingLimit', { axiosInstance, language }, UserAPI.getPurchasingLimit);

const getAutoSavingsProducts = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<UserAutoSavingsProductsEntity> => {
  return log('getAutoSavingsProducts', { axiosInstance, language }, UserAPI.getAutoSavingsProducts);
};

const getAutoSavingsProductById = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  id: number,
): Promise<AutoSavingsProductEntity> => {
  return log(
    'getAutoSavingsProductById',
    { axiosInstance, id, language },
    UserAPI.getAutoSavingsProductById,
  );
};

const putAutoSavingsProductPause = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  id: number,
): Promise<AutoSavingsProductEntity> => {
  return log(
    'putAutoSavingsProductPause',
    { axiosInstance, language, id },
    UserAPI.putAutoSavingsProductPause,
  );
};

const putAutoSavingsProductUnpause = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  id: number,
): Promise<AutoSavingsProductEntity> => {
  return log(
    'putAutoSavingsProductUnpause',
    { axiosInstance, language, id },
    UserAPI.putAutoSavingsProductUnpause,
  );
};

const deleteAutoSavingsProductById = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  id: number,
  password: string,
): Promise<void> => {
  return log(
    'deleteAutoSavingsProductById',
    { axiosInstance, language, id, password },
    UserAPI.deleteAutoSavingsProductById,
  );
};

const getCreditCards = (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  { isStrong }: { isStrong?: boolean },
) => {
  return log('getCreditCards', { axiosInstance, language, isStrong }, UserAPI.getCreditCards);
};

const fraudCreditCard = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: FraudCreditCardReq,
): Promise<void> => {
  return log('fraudCreditCard', { axiosInstance, language, ...data }, UserAPI.fraudCreditCard);
};

const paymentTransactions = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: PaymentTransactionReq,
): Promise<void> => {
  return log(
    'paymentTransactions',
    { axiosInstance, language, ...data },
    UserAPI.paymentTransactions,
  );
};

// TODO[GARB-***]: need to check UserService.initializeCreditCard (we don't use it)
const initializeCreditCard = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: InitializeCreditCardReq,
): Promise<void> => {
  return log(
    'initializeCreditCard',
    { axiosInstance, language, ...data },
    UserAPI.initializeCreditCard,
  );
};

const newCreditCard = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: NewCreditCardReq,
): Promise<{ id: number }> => {
  return log('newCreditCard', { axiosInstance, language, ...data }, UserAPI.newCreditCard);
};

const deleteNewCreditCard = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  id: string,
): Promise<void> => {
  return log('deleteNewCreditCard', { axiosInstance, language, id }, UserAPI.deleteNewCreditCard);
};

const patchAutoSavingsProductById = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: PatchAutoSavingsProductEntity,
): Promise<AutoSavingsProductEntity> => {
  return log(
    'patchAutoSavingsProductById',
    { axiosInstance, language, ...data },
    UserAPI.patchAutoSavingsProductById,
  );
};

const setASBannerState = (axiosInstance: AxiosInstance, value: string) => {
  UserStorage.setASBannerStates(EStorageType.LOCAL_STORAGE, value);
};

const getASBannerState = (): string =>
  UserStorage.getASBannerStates(EStorageType.LOCAL_STORAGE) || null;

const removeASBannerState = () => {
  UserStorage.removeASBannerStates(EStorageType.LOCAL_STORAGE);
};

const getReferralProgram = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<ReferralProgramEntity> => {
  return log('referralProgram', { axiosInstance, language }, UserAPI.getReferralProgram);
};

const postReferralProgram = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data?: CurrencyEntity,
): Promise<PostReferralProgramEntity> => {
  return log(
    'postReferralProgram',
    { axiosInstance, language, ...data },
    UserAPI.postReferralProgram,
  );
};

const postReferralProgramAddress = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: ReferralAdressEntity,
): Promise<void> => {
  return log(
    'postAddresses',
    { axiosInstance, language, ...data },
    UserAPI.postReferralProgramAddress,
  );
};

const getStorageFees = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<StorageFeesResponseEntity> => {
  return log('getStorageFees', { axiosInstance, language }, UserAPI.getStorageFees);
};

const putStorageFees = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: StorageFeesRequestEntity,
): Promise<StorageFeesResponseEntity> => {
  return log('putStorageFees', { axiosInstance, language, ...data }, UserAPI.putStorageFees);
};

const getStorageFeesInvoices = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: StorageFeesInvoicesReq,
): Promise<StorageFeesInvoicesEntity> => {
  return log(
    'getStorageFeesInvoices',
    { axiosInstance, language, ...data },
    UserAPI.getStorageFeesInvoices,
  );
};

const setSFBannerState = (value: string) => {
  UserStorage.setSFBannerState(EStorageType.LOCAL_STORAGE, value);
};

const getSFBannerState = (): string =>
  UserStorage.getSFBannerState(EStorageType.LOCAL_STORAGE) || null;

const removeSFBannerState = () => {
  UserStorage.removeSFBannerState(EStorageType.LOCAL_STORAGE);
};

export class UserService {
  public static readonly getProfile = getProfile;
  public static readonly getCertificateDates = getCertificateDates;
  public static readonly postCertificate = postCertificate;
  public static readonly putProfile = putProfile;
  public static readonly putPassword = putPassword;
  public static readonly deleteTotp = deleteTotp;
  public static readonly deleteSms = deleteSms;
  public static readonly getSms = getSms;
  public static readonly postSms = postSms;
  public static readonly putSms = putSms;
  public static readonly postTotp = postTotp;
  public static readonly putTotp = putTotp;
  public static readonly getDepositDetails = getDepositDetails;
  public static readonly getDepositDetailsPdf = getDepositDetailsPdf;
  public static readonly getBankAccounts = getBankAccounts;
  public static readonly postBankAccount = postBankAccount;
  public static readonly deleteBankAccount = deleteBankAccount;
  public static readonly getPurchasingLimit = getPurchasingLimit;
  public static readonly putEmergencyPeople = putEmergencyPeople;
  public static readonly putLoginAlert = putLoginAlert;
  public static readonly postEmergencyPeople = postEmergencyPeople;
  public static readonly postAddresses = postAddresses;
  public static readonly getNewsletterSubscriptionsWithToken = getNewsletterSubscriptionsWithToken;
  public static readonly getNewsletterSubscriptions = getNewsletterSubscriptions;
  public static readonly putNewsletterSubscriptions = putNewsletterSubscriptions;
  public static readonly getAutoSavingsProducts = getAutoSavingsProducts;
  public static readonly getAutoSavingsProductById = getAutoSavingsProductById;
  public static readonly putAutoSavingsProductPause = putAutoSavingsProductPause;
  public static readonly putAutoSavingsProductUnpause = putAutoSavingsProductUnpause;
  public static readonly deleteAutoSavingsProductById = deleteAutoSavingsProductById;
  public static readonly patchAutoSavingsProductById = patchAutoSavingsProductById;
  public static readonly setASBannerState = setASBannerState;
  public static readonly getASBannerState = getASBannerState;
  public static readonly removeASBannerState = removeASBannerState;
  public static readonly putNewsletterSubscriptionsWithToken = putNewsletterSubscriptionsWithToken;
  public static readonly getCreditCards = getCreditCards;
  public static readonly initializeCreditCard = initializeCreditCard;
  public static readonly newCreditCard = newCreditCard;
  public static readonly fraudCreditCard = fraudCreditCard;
  public static readonly paymentTransactions = paymentTransactions;
  public static readonly deleteCreditCard = deleteCreditCard;
  public static readonly deleteNewCreditCard = deleteNewCreditCard;
  public static readonly postWithdrawal = postWithdrawal;
  public static readonly getReferralProgram = getReferralProgram;
  public static readonly postReferralProgram = postReferralProgram;
  public static readonly postReferralProgramAddress = postReferralProgramAddress;
  public static readonly getStorageFees = getStorageFees;
  public static readonly putStorageFees = putStorageFees;
  public static readonly getStorageFeesInvoices = getStorageFeesInvoices;
  public static readonly setSFBannerState = setSFBannerState;
  public static readonly getSFBannerState = getSFBannerState;
  public static readonly removeSFBannerState = removeSFBannerState;
}
