import { ActionType, EActionAttribute } from './action';
import { ColorProps, ColorType, EColor, EColorName } from './color';
import { ESize, SizeType } from './size';

export enum EButtonVariant {
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
  TEXT = 'text',
}

export type ButtonAction = keyof ActionType<
  | EActionAttribute.BUTTON
  | EActionAttribute.LINK
  | EActionAttribute.SPAN
  | EActionAttribute.INPUT
  | EActionAttribute.DIV
>;

export type ButtonSize = keyof SizeType<ESize.XS | ESize.SM | ESize.MD | ESize.LG>;

export type ButtonQuantitySize = keyof SizeType<ESize.SM | ESize.LG>;

export type ButtonColorType = keyof ColorType<
  | EColor.PRIMARY
  | EColor.SECONDARY
  | EColor.ACCENT
  | EColor.INFO
  | EColor.SUCCESS
  | EColor.WARNING
  | EColor.DANGER
  | EColor.WHITE
  | EColor.YELLOW
>;

export type ButtonColorBaseProps = {
  color: EColorName;
  borderColor: EColorName;
  backgroundColor: EColorName;
};

export type ButtonColorProps = ButtonColorBaseProps & {
  hover?: ButtonColorBaseProps;
};

export type ButtonColorVariantHandler<T = object> = (
  props: {
    color: ButtonColorType;
    getColor: (props: ColorProps) => EColorName;
    isDisabled?: boolean;
  } & T,
) => ButtonColorProps;
