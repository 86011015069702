import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconClockWithArrow = ({ fill }: DynamicIconProps) => (
  <SVG height={18} width={18} viewBox="0 0 18 18" title="Clock icon">
    <g clipPath="url(#clip0_11_12)">
      <path
        d="M12.9383 11.4052L12.9384 11.4053C13.165 11.5752 13.2108 11.8963 13.0409 12.1228C12.871 12.3493 12.55 12.3952 12.3234 12.2253L8.94915 9.6946C8.84123 9.60728 8.76875 9.45446 8.76875 9.28125V4.21875C8.76875 3.93558 8.99808 3.70625 9.28125 3.70625C9.56442 3.70625 9.79375 3.93558 9.79375 4.21875V9V9.02486L9.81357 9.03987L12.9383 11.4052Z"
        fill={fill}
        stroke="white"
        strokeWidth="0.1"
      />
      <path
        d="M1.27152 7.7256L1.20161 7.62165L0.987908 7.30391C0.829248 7.06887 0.510444 7.00688 0.275449 7.1655C0.0404066 7.32416 -0.0215855 7.64297 0.137042 7.87796L0.137203 7.8782L1.2622 9.5657L1.26237 9.56595C1.34622 9.69338 1.48251 9.77694 1.63392 9.79375L1.68729 9.79375M1.27152 7.7256L1.68729 9.79375M1.27152 7.7256L1.2924 7.60208C1.97458 3.56658 5.46966 0.6125 9.56248 0.6125C14.1949 0.6125 17.95 4.36758 17.95 9C17.95 13.6324 14.1949 17.3875 9.56248 17.3875L9.56166 17.3875C6.95174 17.4303 4.48548 16.1946 2.95717 14.0784C2.79305 13.8478 2.84711 13.5278 3.07773 13.3635C3.30832 13.1993 3.6284 13.2533 3.79268 13.484L3.79293 13.4844C5.1305 15.329 7.28494 16.4037 9.56292 16.3625C13.1735 16.3628 16.2516 13.7447 16.8307 10.1808C17.4828 6.16713 14.7576 2.38508 10.7442 1.73293C6.73074 1.08078 2.9484 3.80547 2.29625 7.81917L2.27123 7.97316L2.3811 7.8624L2.72971 7.51098C2.72972 7.51096 2.72974 7.51094 2.72976 7.51093M1.27152 7.7256L2.72976 7.51093M1.68729 9.79375L1.68748 9.79375M1.68729 9.79375C1.68735 9.79375 1.68741 9.79375 1.68748 9.79375M1.68748 9.79375C1.82323 9.79319 1.95304 9.73887 2.04854 9.6426L2.04868 9.64246L3.45493 8.23621C3.65506 8.03608 3.65506 7.71111 3.45493 7.51098C3.25482 7.31087 2.92989 7.31085 2.72976 7.51093M1.68748 9.79375L2.72976 7.51093"
        fill={fill}
        stroke="white"
        strokeWidth="0.1"
      />
    </g>
    <defs>
      <clipPath id="clip0_11_12">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </SVG>
);
