import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type { WishlistResponseEntity } from '@core/type/api';
import { WishlistUpdateResponseEntity } from '@core/type/api';

import { Api } from '../constants/api';
import { replaceIdUrl } from '../helpers/api';
import { configResponse, EConfigName } from '../helpers/config';

const getWishlist = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<WishlistResponseEntity>> => {
  return axiosInstance.get(
    Api.wishlist.WISHLIST,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const addToWishlist = async (
  axiosInstance: AxiosInstance,
  { language, productId }: LanguageProps & { productId: number },
): Promise<AxiosResponse<WishlistUpdateResponseEntity>> => {
  return axiosInstance.put(
    replaceIdUrl(Api.wishlist.ADD_TO_WISHLIST, productId, '[id]'),
    null,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const deleteWishlist = async (
  axiosInstance: AxiosInstance,
  { language, productId }: LanguageProps & { productId: number },
): Promise<AxiosResponse<void>> => {
  return axiosInstance.delete(
    replaceIdUrl(Api.wishlist.DELETE_FROM_WISHLIST, productId, ':productId'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class WishListAPI {
  public static readonly getWishlist = getWishlist;
  public static readonly addToWishlist = addToWishlist;
  public static readonly deleteWishlist = deleteWishlist;
}
