import { createContext } from 'react';

import type { HookStore, CartFormState, CartFormHook } from '@core/type/context';

import { useFnContext, useStoreContext } from '../util';

export const cartFormContext =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createContext<HookStore<any, CartFormState, CartFormHook>>(null);

export const useContextCartForm = () => {
  return useFnContext(cartFormContext);
};

export function useStoreCartForm<Selector>(selector: keyof CartFormState) {
  return useStoreContext<Selector, CartFormState, CartFormHook>(selector, cartFormContext);
}
