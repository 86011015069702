import { Translate } from 'next-translate';

import { Language } from '@core/constant';
import { ECountry, ELanguage, ELanguageTags, LanguageEntity } from '@core/type';

export const getLanguage = (language: ELanguage): Exclude<ELanguage, 'default'> => {
  return language === ELanguage.DEFAULT ? ELanguage.EN : language;
};

export const getLanguageInEnglish = (key: string): string => {
  switch (key) {
    case ELanguage.EN.toString():
      return 'English';
    case ELanguage.FR.toString():
      return 'French';
    case ELanguage.DE.toString():
      return 'German';
    case ELanguage.IT.toString():
      return 'Italian';
    default:
      return '';
  }
};

export const getIsoLanguage = (key: string): ELanguage => {
  switch (key) {
    case ELanguage.EN.toString():
      return ELanguage.EN;
    case ELanguage.FR.toString():
      return ELanguage.FR;
    case ELanguage.DE.toString():
      return ELanguage.DE;
    case ELanguage.IT.toString():
      return ELanguage.IT;
    default:
      return getLanguageFromTags(Language.I18N_DEFAULT_LANGUAGE);
  }
};

export const getIsoLanguageTags = (key: string): ELanguageTags => {
  switch (key) {
    case ELanguageTags.EN_US.toString():
      return ELanguageTags.EN_US;
    case ELanguageTags.FR_FR.toString():
      return ELanguageTags.FR_FR;
    case ELanguageTags.DE_DE.toString():
      return ELanguageTags.DE_DE;
    case ELanguageTags.IT_IT.toString():
      return ELanguageTags.IT_IT;
    default:
      return ELanguageTags.EN_US;
  }
};

export const getLanguageTags = (
  language: ELanguage,
): Exclude<ELanguageTags, ELanguageTags.DEFAULT> => {
  switch (language) {
    case ELanguage.EN:
      return ELanguageTags.EN_US;
    case ELanguage.FR:
      return ELanguageTags.FR_FR;
    case ELanguage.DE:
      return ELanguageTags.DE_DE;
    case ELanguage.IT:
      return ELanguageTags.IT_IT;
    default:
      return ELanguageTags.EN_US;
  }
};

export const getLanguageFromTags = (language: ELanguageTags): ELanguage => {
  switch (language) {
    case ELanguageTags.EN_US:
      return ELanguage.EN;
    case ELanguageTags.FR_FR:
      return ELanguage.FR;
    case ELanguageTags.DE_DE:
      return ELanguage.DE;
    case ELanguageTags.IT_IT:
      return ELanguage.IT;
    default:
      return getLanguageFromTags(Language.I18N_DEFAULT_LANGUAGE);
  }
};

export const getCountryFromLanguage = (iso: ELanguage) => {
  switch (iso.toLowerCase() as ELanguage) {
    case ELanguage.EN:
      return ECountry.UNITED_KINGDOM;
    case ELanguage.FR:
      return ECountry.FRANCE;
    case ELanguage.DE:
      return ECountry.GERMANY;
    case ELanguage.IT:
      return ECountry.ITALY;
    default:
      return null;
  }
};

export const getListLanguage = (t: Translate): LanguageEntity[] => {
  return Language.i18nSupportedLanguage.map((lng: ELanguageTags): LanguageEntity => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const tLang: { [key: string]: string }[] | string = t('lang', {}, { returnObjects: true });
    return {
      name:
        tLang && tLang !== 'lang'
          ? (tLang as { [key: string]: string }[])
              .filter((locale) => locale[getLanguageFromTags(lng)])
              .shift()[getLanguageFromTags(lng)]
          : '',
      key: lng.toString(),
      iso: lng,
    };
  });
};
