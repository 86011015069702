import { useEffect, useState } from 'react';

import { useContextUtil } from '@core/context';
import { useQueryApi } from '@core/hook';
import { EStorageKey, getItem, setItem } from '@core/storage';
import { EColor, EStorageType } from '@core/type';
import { BannerEntity, EQueryKey } from '@core/type/api';

import { ConnectedBannerHookProps, ConnectedBannerProps } from './interface-connected-banner';

export const useConnectedBanner = (): ConnectedBannerHookProps => {
  const [bannerData, setBannerData] = useState<ConnectedBannerProps>(null);
  const [storedToken, setStoredToken] = useState<string>(null);
  const [isOpen, setIsOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isBotDetected } = useContextUtil();

  useEffect(() => {
    const storedBannerToken = getItem(EStorageType.COOKIE, EStorageKey.BANNER_COOKIE_NAME);
    if (storedBannerToken) {
      setStoredToken(storedBannerToken);
    }
  }, []);

  const { isSuccess, data } = useQueryApi<BannerEntity>(EQueryKey.BANNER, null, {
    enabled: !isBotDetected,
  });

  useEffect(() => {
    if (isSuccess && data?.token && data?.token !== storedToken) {
      const { color, ...restData } = data;

      const bannerData: ConnectedBannerProps = {
        color: color === EColor.RED ? EColor.DANGER : EColor.ACCENT,
        ...restData,
      };

      setBannerData(bannerData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccess]);

  const handleBannerClose = () => {
    setItem(EStorageType.COOKIE, EStorageKey.BANNER_COOKIE_NAME, bannerData.token);
    setIsOpen(false);
  };

  const handleShowMore = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return {
    bannerData,
    isOpen,
    isModalOpen,
    handleBannerClose,
    handleShowMore,
    handleModalClose,
  };
};
