import { Box, ButtonLiveChat } from '@core/component';
import { EButtonVariant, EColor, ESize } from '@core/type';

export const FooterShort = () => {
  return (
    <Box padding={'16px'} textAlign={'center'}>
      <ButtonLiveChat color={EColor.YELLOW} size={ESize.SM} variant={EButtonVariant.OUTLINED} />
    </Box>
  );
};
