import type { ReactNode } from 'react';

import { useQueryApi } from '@core/hook';
import { CountriesEntity, EQueryKey } from '@core/type/api';

import { gtmContext } from './context-gtm';
import { useGTM } from './hooks';

export const GTMProvider = ({
  children,
  active,
  host,
}: {
  active: boolean;
  children: ReactNode;
  host: string;
}) => {
  const { data: countriesData, isFetched } = useQueryApi<CountriesEntity>(EQueryKey.COUNTRIES_EN);

  return (
    <gtmContext.Provider
      value={{
        enable: active,
        isReady: isFetched,
        countries: countriesData?.countries || [],
        baseDatalayerProps: useGTM(host),
      }}
    >
      {children}
    </gtmContext.Provider>
  );
};
