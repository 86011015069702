import { ComponentType, FC, ReactNode, Suspense } from 'react';

import { cartPopupContext, useContextCartPopup } from '@core/context';
import { ToastPosition } from '@core/type';
import {
  CartPopupHook,
  CartPopupProps,
  CartPopupState,
  ToastPositionProps,
} from '@core/type/context';

import { storeHook } from '../helpers';
import { useCartPopup } from '../hook';

function CartPopupChildren({
  children,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Component,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Wrapper,
  placement,
}: {
  children?: ReactNode;
  placement?: ToastPosition;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Wrapper: FC<any>;
  Component: ComponentType<Omit<CartPopupProps, 'i18n'>>;
}) {
  const { cartPopup } = useContextCartPopup();

  return (
    <>
      {children}
      {cartPopup && (
        <Suspense>
          <Wrapper placement={placement}>
            <Component {...cartPopup} />
          </Wrapper>
        </Suspense>
      )}
    </>
  );
}

export function ProviderCartPopup<WrapperProps extends ToastPositionProps>(props: {
  children?: ReactNode;
  placement?: ToastPosition;
  Wrapper: FC<WrapperProps>;
  Component: ComponentType<Omit<CartPopupProps, 'i18n'>>;
}) {
  const hooks = storeHook<CartPopupState, CartPopupHook>(
    {
      cartPopup: null,
    },
    useCartPopup,
  );

  return (
    <cartPopupContext.Provider value={hooks}>
      <CartPopupChildren {...props} />
    </cartPopupContext.Provider>
  );
}
