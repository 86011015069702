import { EStrokeWidth } from '@core/type';

import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconFilledHeart = ({
  fill,
  stroke,
  strokeWidth = EStrokeWidth.ICON_DEFAULT,
}: DynamicIconProps) => (
  <SVG height={24} width={24} viewBox="0 0 24 24" title="Heart icon">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7903 5.32466H16.793C19.1642 5.32466 21.068 7.19591 21.068 9.48265C21.068 12.6844 18.4332 15.7432 12.7362 18.6346L12.7359 18.6348C12.7148 18.6455 12.6915 18.6511 12.668 18.6511C12.6444 18.6511 12.6211 18.6455 12.6 18.6348L12.5998 18.6346C6.90272 15.7432 4.26797 12.6844 4.26797 9.48265C4.26797 7.19591 6.17177 5.32466 8.54297 5.32466L8.54562 5.32465C9.26375 5.32148 9.97126 5.49816 10.6036 5.83858C11.2359 6.17899 11.7729 6.67231 12.1656 7.27354L12.668 8.04252L13.1703 7.27354C13.563 6.67231 14.1 6.17899 14.7324 5.83858C15.3647 5.49816 16.0722 5.32148 16.7903 5.32466Z"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
  </SVG>
);
