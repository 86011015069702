import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconSmartphoneValid = ({ fill }: DynamicIconProps) => {
  return (
    <SVG height={32} width={32} viewBox="0 0 32 32" title="Smartphone valid icon">
      <path
        d="M17.2788 1.95898H5.59619C4.16235 1.95898 3 3.05395 3 4.40466V27.6383C3 28.989 4.16235 30.084 5.59619 30.084H17.2788C18.7126 30.084 19.875 28.989 19.875 27.6383V4.40466C19.875 3.05395 18.7126 1.95898 17.2788 1.95898Z"
        fill="#F6FEFD"
        stroke={fill}
        strokeWidth="1.2"
      ></path>
      <path
        d="M17.2788 1.95898H5.59619C4.16235 1.95898 3 3.05395 3 4.40466V27.6383C3 28.989 4.16235 30.084 5.59619 30.084H17.2788C18.7126 30.084 19.875 28.989 19.875 27.6383V4.40466C19.875 3.05395 18.7126 1.95898 17.2788 1.95898Z"
        fill="#F6FEFD"
        stroke={fill}
        strokeWidth="1.2"
      ></path>
      <mask id="path-3-inside-1_1119_19068" fill="white">
        <path d="M14.3724 3.79297H9.17536C8.9221 3.79297 8.7168 3.99827 8.7168 4.25153C8.7168 4.50478 8.9221 4.71009 9.17536 4.71009H14.3724C14.6256 4.71009 14.831 4.50478 14.831 4.25153C14.831 3.99827 14.6256 3.79297 14.3724 3.79297Z"></path>
      </mask>
      <path
        d="M14.3724 3.79297H9.17536C8.9221 3.79297 8.7168 3.99827 8.7168 4.25153C8.7168 4.50478 8.9221 4.71009 9.17536 4.71009H14.3724C14.6256 4.71009 14.831 4.50478 14.831 4.25153C14.831 3.99827 14.6256 3.79297 14.3724 3.79297Z"
        fill="#F6FEFD"
      ></path>
      <path
        d="M9.17536 4.79297H14.3724V2.79297H9.17536V4.79297ZM14.3724 4.79297C14.0734 4.79297 13.831 4.55056 13.831 4.25153H15.831C15.831 3.44599 15.1779 2.79297 14.3724 2.79297V4.79297ZM13.831 4.25153C13.831 3.9525 14.0734 3.71009 14.3724 3.71009V5.71009C15.1779 5.71009 15.831 5.05707 15.831 4.25153H13.831ZM14.3724 3.71009H9.17536V5.71009H14.3724V3.71009ZM9.17536 3.71009C9.47439 3.71009 9.7168 3.9525 9.7168 4.25153H7.7168C7.7168 5.05707 8.36982 5.71009 9.17536 5.71009V3.71009ZM9.7168 4.25153C9.7168 4.55056 9.47439 4.79297 9.17536 4.79297V2.79297C8.36982 2.79297 7.7168 3.44599 7.7168 4.25153H9.7168Z"
        fill={fill}
        mask="url(#path-3-inside-1_1119_19068)"
      ></path>
      <path
        d="M11.6181 26.8494C12.4653 26.8494 13.1522 26.1626 13.1522 25.3153C13.1522 24.4681 12.4653 23.7812 11.6181 23.7812C10.7708 23.7812 10.084 24.4681 10.084 25.3153C10.084 26.1626 10.7708 26.8494 11.6181 26.8494Z"
        fill="#F6FEFD"
        stroke={fill}
        strokeWidth="0.5"
      ></path>
      <circle
        cx="21.5625"
        cy="10.3965"
        r="7.8375"
        fill="#F6FEFD"
        stroke={fill}
        strokeWidth="1.2"
      ></circle>
      <path
        d="M24.6785 8.89429L20.8928 12.6799L19 10.7871"
        stroke={fill}
        strokeWidth="1.2"
        strokeLinecap="round"
      ></path>
    </SVG>
  );
};
