import { getEnvironmentPublic } from '@web-app/helpers';
import withTranslation from 'next-translate/withTranslation';

import { Box, Icon, LinkTranslatedStyledAsLink, Typography } from '@core/component';
import { Translate, metalPriceToSlug } from '@core/constant';
import { useContextCurrency, useContextMetalPrice } from '@core/context';
import { useLanguage } from '@core/hook';
import { ProviderMetalPrice } from '@core/provider';
import {
  EColor,
  EIcon,
  EMetal,
  ERouting,
  ESize,
  ETypographyVariant,
  MetalPrice,
  WithTranslation,
} from '@core/type';
import { formatCurrency } from '@core/util';

const PriceMetal = ({
  title,
  priceChange,
  deskbid,
  isPositive,
}: {
  title: string;
  priceChange: number;
  deskbid: number;
  isPositive: boolean;
}) => {
  const { language } = useLanguage();
  const { currency } = useContextCurrency();
  const color = isPositive ? EColor.SUCCESS : EColor.DANGER;

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      textAlign={'center'}
      gap={'5px'}
    >
      <Typography
        color={{ commonType: EColor.WHITE, intensity: EColor.R700 }}
        textTransform={'uppercase'}
        variant={ETypographyVariant.CAPTION1}
        letterSpacing="1.08px"
        lineHeight="14px"
        hoverColor={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
      >
        {`${title} ${formatCurrency(language, currency, deskbid)}`}
      </Typography>

      <Icon
        type={EIcon.TRIANGLE}
        transform={isPositive ? 'rotate(180deg)' : undefined}
        color={color}
        size={ESize.XXXS}
      />
      <Typography color={color} variant={ETypographyVariant.CAPTION1} lineHeight="14px">
        {`${isPositive ? '' : '('}${formatCurrency(language, currency, priceChange, {
          signDisplay: 'never',
        })}${isPositive ? '' : ')'}`}
      </Typography>
    </Box>
  );
};

const HeaderPriceContainer = ({ i18n: { t } }: WithTranslation) => {
  const { prices } = useContextMetalPrice();
  const { currency } = useContextCurrency();
  const { language } = useLanguage();

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      textAlign={'center'}
      padding={'6px 0'}
      height="26px"
    >
      {Object.entries(prices).map(([metal, metalPrice]: [string, MetalPrice]) => {
        const { deskbid = 0, isPositive, name, priceChange = 0 } = metalPrice;
        return (
          <LinkTranslatedStyledAsLink
            keyRoute={ERouting.CHARTS}
            query={{
              metalSlug: metalPriceToSlug[language][metal as EMetal],
              slug: [currency.toLowerCase()],
            }}
            variant={ETypographyVariant.CAPTION3}
            color={EColor.ACCENT}
            key={metal}
            language={language}
            isUnderlinedHover={false}
          >
            <PriceMetal
              title={t(name)}
              priceChange={priceChange}
              deskbid={deskbid}
              isPositive={isPositive}
            />
          </LinkTranslatedStyledAsLink>
        );
      })}
    </Box>
  );
};
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const HeaderPriceTranslated = withTranslation(HeaderPriceContainer, Translate.layout.HEADER);

export const HeaderPrice = () => {
  const metalPriceInitialize = {
    [EMetal.GOLD]: { isPositive: true, name: 'price.gold' },
    [EMetal.SILVER]: { isPositive: true, name: 'price.silver' },
    [EMetal.PLATINUM]: { isPositive: true, name: 'price.platinum' },
    [EMetal.PALLADIUM]: { isPositive: true, name: 'price.palladium' },
  };
  const { wsHost } = getEnvironmentPublic();

  return (
    <ProviderMetalPrice wsHost={wsHost} metalPriceInitialize={metalPriceInitialize}>
      <HeaderPriceTranslated />
    </ProviderMetalPrice>
  );
};
