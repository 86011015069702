import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconSettings = ({ fill }: DynamicIconProps) => (
  <SVG width="18" height="18" viewBox="0 0 18 18" title="storage fees icon" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.238 2.422a.964.964 0 0 1-1.268.734C1.868 2.061-.435 6.073 2.055 8.206a.967.967 0 0 1 0 1.47c-2.49 2.133-.187 6.145 2.915 5.05a.964.964 0 0 1 1.268.734c.602 3.23 5.227 3.23 5.829 0a.964.964 0 0 1 1.269-.734c3.101 1.095 5.405-2.917 2.915-5.05a.968.968 0 0 1 0-1.47c2.49-2.133.186-6.145-2.915-5.05a.964.964 0 0 1-1.269-.734c-.602-3.23-5.227-3.23-5.829 0m.983.184c.4-2.141 3.464-2.141 3.863 0a1.965 1.965 0 0 0 2.585 1.493c2.052-.724 3.584 1.932 1.931 3.348a1.968 1.968 0 0 0 0 2.988c1.653 1.416.12 4.073-1.931 3.348a1.965 1.965 0 0 0-2.585 1.494c-.4 2.14-3.463 2.14-3.863 0a1.964 1.964 0 0 0-2.584-1.494c-2.052.725-3.585-1.932-1.932-3.348a1.968 1.968 0 0 0 0-2.988C1.052 6.031 2.585 3.375 4.637 4.1A1.964 1.964 0 0 0 7.22 2.606M6.976 8.94a2.176 2.176 0 1 0 4.353 0 2.176 2.176 0 0 0-4.353 0m-1 0a3.176 3.176 0 1 0 6.353 0 3.176 3.176 0 0 0-6.353 0"
      fill={fill}
    />
  </SVG>
);
