import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { WalletEntity } from '@core/type/api';
import { PostWalletSalesEntity, PutWalletSalesEntity, WalletSalesEntity } from '@core/type/api';

import { WalletAPI } from '../api/wallet';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
): Promise<R> => {
  return logService('wallet', methodName, dto, apiCall);
};

const getWallet = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<WalletEntity> => {
  return log('getWallet', { axiosInstance, language }, WalletAPI.getWallet);
};

const getWalletSales = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<WalletSalesEntity> => {
  return log('getWalletSales', { axiosInstance, language }, WalletAPI.getWalletSales);
};

const postWalletSales = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: PostWalletSalesEntity,
): Promise<WalletSalesEntity> => {
  return log('postWalletSales', { axiosInstance, language, ...data }, WalletAPI.postWalletSales);
};

const putWalletSales = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: PutWalletSalesEntity,
): Promise<WalletSalesEntity> => {
  return log('putWalletSales', { axiosInstance, language, ...data }, WalletAPI.putWalletSales);
};

export class WalletService {
  public static readonly getWallet = getWallet;
  public static readonly getWalletSales = getWalletSales;
  public static readonly postWalletSales = postWalletSales;
  public static readonly putWalletSales = putWalletSales;
}
