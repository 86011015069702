import { createContext } from 'react';

import type { AlternateHook, AlternateState, HookStore } from '@core/type/context';

import { useFnContext, useStoreContext } from '../util';

export const alternateContext =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createContext<HookStore<any, AlternateState, AlternateHook>>(null);

export const useContextAlternate = () => {
  return useFnContext(alternateContext);
};

export function useStoreAlternate<Selector>(selector: keyof AlternateState) {
  return useStoreContext<Selector, AlternateState, AlternateHook>(selector, alternateContext);
}
