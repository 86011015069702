import { FC } from 'react';
import styled from 'styled-components';

import { EActionAttribute } from '@core/type';

import { EditorArticleContent } from './EditorArticleContent';
import { EditorDefaultContentProps } from './interface-editor';

export const EditorTutorialContent: FC<EditorDefaultContentProps> = styled(EditorArticleContent)(
  ({ isPhone }: EditorDefaultContentProps) => ({
    [EActionAttribute.FIGURE]: {
      overflow: 'hidden',
      borderRadius: 4,

      [EActionAttribute.IFRAME]: {
        width: isPhone ? 'initial' : '100% !important',
        height: isPhone ? 'initial' : '430px !important',
      },
    },
  }),
);
