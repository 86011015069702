import withTranslation from 'next-translate/withTranslation';
import { memo } from 'react';

import { Box, Button, SelectCurrency, SelectLanguage } from '@core/component';
import { Translate } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { EButtonVariant, EColor, EPosition, ESize, WithTranslation } from '@core/type';

const HeaderLandingContainer = ({ i18n: { t } }: WithTranslation) => {
  const { isPhone } = useContextMediaQuery();

  return (
    <Box display="flex" justifyContent="flex-end" alignItems={'center'} gap="16px">
      <SelectCurrency horizontalPosition={EPosition.RIGHT} />
      <SelectLanguage horizontalPosition={EPosition.RIGHT} />

      {!isPhone && (
        <Box marginLeft={16} display={'flex'}>
          <Button
            size={ESize.XS}
            color={EColor.WHITE}
            variant={EButtonVariant.OUTLINED}
            // TODO[RCT-283]: connect with by physical silver section
            onClick={() => {
              const buySilverBlock = document.getElementById('buySilverBlock');
              buySilverBlock.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }}
          >
            {t('menu.buyPhysicalSilver')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export const HeaderLanding = memo(withTranslation(HeaderLandingContainer, Translate.layout.HEADER));
