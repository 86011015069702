import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconUser = ({ fill }: DynamicIconProps) => {
  return (
    <SVG height={15} width={14} viewBox="0 0 14 15" title="User icon">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.287 3.946a2.546 2.546 0 1 1-5.09 0 2.546 2.546 0 0 1 5.09 0m-.57 3.416a3.946 3.946 0 1 0-3.95 0A6.745 6.745 0 0 0 0 13.81a.7.7 0 1 0 1.4 0 5.342 5.342 0 0 1 10.683 0 .7.7 0 1 0 1.4 0 6.745 6.745 0 0 0-4.766-6.448"
        fill={fill}
      />
    </SVG>
  );
};
