import { useCallback } from 'react';

import { useStoreThemeColor } from '@core/context';
import type { ThemeColorType, ThemePalette } from '@core/type';
import { EColor } from '@core/type';
import type { HookStoreFn, ThemeColorHook, ThemeColorState } from '@core/type/context';

export const useThemeColor: HookStoreFn<ThemeColorState, ThemeColorHook> = (): ThemeColorHook => {
  const [isDark, setIsDark] = useStoreThemeColor<boolean>('isDark');
  const [listThemeColor] = useStoreThemeColor<ThemeColorType>('listThemeColor');
  const [themePalette, setThemePalette] = useStoreThemeColor<ThemePalette>('listThemeColor');

  const darkEnable = useCallback(() => {
    setThemePalette(listThemeColor[EColor.DARK]);
    setIsDark(true);
  }, [listThemeColor, setIsDark, setThemePalette]);

  const darkDisable = useCallback(() => {
    setThemePalette(listThemeColor[EColor.MAIN]);
    setIsDark(false);
  }, [listThemeColor, setIsDark, setThemePalette]);

  return {
    themePalette,
    darkEnable,
    darkDisable,
    isDark,
  };
};
