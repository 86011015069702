import { useEventUserInteraction } from '@google-tag-manager';
import { useEffect } from 'react';

import { useContextProfile, useContextUser } from '@core/context';
import { Logger } from '@core/logger';
import { EGTMEventName, ELanguage } from '@core/type';

import type { CrispContextState } from './interface-crisp';
import { usePrevious } from './use-previous';

/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    $crisp: Array<any> | undefined;

    [key: string]: any;
  }
}

const logCrisp = (methodName: string) => {
  Logger.logInfo(`Call useCrisp::${methodName}`, { library: 'crisp' });
};

export const useCrisp = (
  locale: ELanguage,
  websiteId: string,
  zIndex: number,
  enable: boolean,
): CrispContextState => {
  const { isLogged, isReady, fullName, email } = useContextUser();
  const { pushEventUserInteraction } = useEventUserInteraction();
  const { crispSessionToken } = useContextProfile();

  const pushSendChatEvent = () => pushEventUserInteraction(EGTMEventName.SEND_CHAT);
  const pushSendOpenEvent = () => pushEventUserInteraction(EGTMEventName.OPEN_CHAT);

  useEffect(() => {
    if (isLogged) {
      window.CRISP_TOKEN_ID = crispSessionToken;
    } else {
      window.CRISP_TOKEN_ID = null;
    }

    logCrisp('resetChat');
    $crisp().push(['do', 'session:reset']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  useEffect(() => {
    if (enable) {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = websiteId;
      window.CRISP_TOKEN_ID = crispSessionToken;
      window.CRISP_RUNTIME_CONFIG = {
        locale,
      };

      window.$crisp.push([
        'on',
        'session:loaded',
        () => {
          $crisp().push(['safe', true]);
          $crisp().push(['config', 'sound:mute', [true]]);
          $crisp().push(['config', 'container:index', [zIndex]]);
        },
      ]);

      window.$crisp.push([
        'on',
        'message:sent',
        () => pushEventUserInteraction(EGTMEventName.SEND_CHAT),
      ]);

      window.$crisp.push([
        'on',
        'chat:opened',
        () => pushEventUserInteraction(EGTMEventName.OPEN_CHAT),
      ]);

      logCrisp('Crisp initialize');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const previousLocale = usePrevious(locale);

  useEffect(() => {
    if (enable && previousLocale && locale && previousLocale !== locale) {
      window.CRISP_RUNTIME_CONFIG = {
        locale,
      };

      resetChat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  useEffect(() => {
    if (enable && isReady) {
      if (isLogged) {
        setUser(fullName, email);
      }

      window.$crisp.push(['off', 'message:sent', pushSendChatEvent]);
      window.$crisp.push(['off', 'chat:opened', pushSendOpenEvent]);
      window.$crisp.push(['on', 'message:sent', pushSendChatEvent]);
      window.$crisp.push(['on', 'chat:opened', pushSendOpenEvent]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, isReady]);

  const $crisp = (): Array<any> => window?.$crisp ?? [];

  const setUser = (nickname: string, email: string) => {
    logCrisp('setUser');
    $crisp().push(['set', 'user:email', email]);
    $crisp().push(['set', 'user:nickname', nickname]);
  };

  const openChat = () => {
    logCrisp('openChat');
    $crisp().push(['do', 'chat:open']);
  };

  const closeChat = () => {
    logCrisp('closeChat');
    $crisp().push(['do', 'chat:close']);
  };

  const resetChat = () => {
    window.CRISP_TOKEN_ID = null;
    logCrisp('resetChat');
    $crisp().push(['do', 'session:reset']);
  };

  const toggleChat = () => {
    logCrisp('toggleChat');
    $crisp().push(['do', 'chat:toggle']);
  };

  const sendMessage = (message: string) => {
    logCrisp('sendMessage');
    $crisp().push(['do', 'message:send', ['text', message]]);
  };

  return {
    setUser,
    openChat,
    closeChat,
    resetChat,
    toggleChat,
    sendMessage,
  };
};
