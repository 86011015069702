import { AxiosInstance, isAxiosError } from 'axios';
import { Translate as NextTranslate } from 'next-translate';

import { AuthService, RoutingService } from '@core/api';
import { Logger } from '@core/logger';
import { ETagLogger } from '@core/logger/type';
import {
  EAccessRoute,
  EColor,
  EHttpStatusCode,
  ERouting,
  ETypeResponse,
  EUserStatus,
  ErrorHandled,
  ResponseHandled,
  ToastColorType,
  ELanguageTags,
} from '@core/type';
import { ToastObject } from '@core/type/context';

import { getTypeRoute } from './routing';

const NUMBER_RETRY = 3;

export const convertErrorAPI = (data: ResponseHandled): ResponseHandled => {
  if (data?.message || data?.errors) {
    const message = typeof data.message === 'string' ? data.message : data.message[0];
    return {
      message,
      isToast: isToast(data.status, message),
      userStatus: data.userStatus,
      status: convertStatus(data.status),
      errors: data.errors,
      data: data,
    };
  }
  return null;
};

export const convertStatus = (
  status?: string,
): EColor.INFO | EColor.SUCCESS | EColor.WARNING | EColor.DANGER => {
  switch (status as ETypeResponse) {
    case ETypeResponse.ERROR:
      return EColor.DANGER;
    case ETypeResponse.WARNING:
      return EColor.WARNING;
    case ETypeResponse.INFORMATION:
      return EColor.INFO;
    case ETypeResponse.SUCCESS:
      return EColor.SUCCESS;
    default:
      return EColor.WARNING;
  }
};

export const disableLog = (status?: EHttpStatusCode): boolean => {
  switch (status) {
    case EHttpStatusCode.BAD_REQUEST:
      return true;
    case EHttpStatusCode.UNAUTHORIZED:
      return true;
    case EHttpStatusCode.FORBIDDEN:
      return true;
    case EHttpStatusCode.UNPROCESSABLE_ENTITY:
      return true;
    case EHttpStatusCode.LOCKED:
      return true;
    default:
      return false;
  }
};

export const isToast = (status?: string, message?: string): boolean => {
  if (status && message) {
    return (
      (status as ETypeResponse) === ETypeResponse.ERROR ||
      (status as ETypeResponse) === ETypeResponse.WARNING ||
      (status as ETypeResponse) === ETypeResponse.INFORMATION ||
      (status as ETypeResponse) === ETypeResponse.SUCCESS
    );
  }

  return false;
};

export const handleError = (err: unknown, isProductionServer?: boolean): ErrorHandled => {
  if (err && isAxiosError(err)) {
    if (err.code === 'ERR_NETWORK') {
      return {
        code: EHttpStatusCode.INTERNAL_SERVER_ERROR,
        isToast: true,
        status: EColor.DANGER,
      };
    }

    if (err.response) {
      Logger.logInfo('Response Error', {
        code: err.response.status as EHttpStatusCode,
      });
      return {
        status: EColor.WARNING,
        ...convertErrorAPI(err.response.data as ResponseHandled),
        code: err.response.status as EHttpStatusCode,
      };
    }

    Logger.logInfo('Response Error not handled by API', {
      code: (err?.response?.status as EHttpStatusCode) || EHttpStatusCode.BAD_REQUEST,
    });
    if (!isProductionServer && !disableLog()) {
      Logger.setTags({
        [ETagLogger.STATUS_CODE]:
          (err?.response?.status as EHttpStatusCode) || EHttpStatusCode.BAD_REQUEST,
      });
      Logger.logError(err, { isException: true });
      Logger.setTags({ [ETagLogger.STATUS_CODE]: null });
    }
    return {
      code: EHttpStatusCode.BAD_REQUEST,
      isToast: true,
      status: EColor.DANGER,
    };
  }

  Logger.setTags({ [ETagLogger.STATUS_CODE]: EHttpStatusCode.INTERNAL_SERVER_ERROR });
  Logger.logError(err, { code: EHttpStatusCode.INTERNAL_SERVER_ERROR, isException: true });
  Logger.setTags({ [ETagLogger.STATUS_CODE]: null });
  return {
    code: EHttpStatusCode.INTERNAL_SERVER_ERROR,
    isToast: false,
  };
};

export function displayToast<T extends ToastObject>(
  err: ErrorHandled,
  addToast: (props: Omit<T, 'id'>) => string,
  title: string,
  tCommon?: NextTranslate,
) {
  addToast({
    title,
    description: err.message || tCommon('error.service'),
    status: err.status || EColor.WARNING,
  } as T);
}

export const delay = (ms: number) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return new Promise(() => setTimeout(() => {}, ms));
};

export const getTitleToast = (status?: ToastColorType, tCommon?: NextTranslate): string => {
  switch (status) {
    case EColor.INFO:
      return tCommon('toast.information');
    case EColor.SUCCESS:
      return tCommon('toast.success');
    case EColor.WARNING:
      return tCommon('toast.warning');
    case EColor.DANGER:
      return tCommon('toast.error');
    default:
      return tCommon('toast.warning');
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export let tokenRefreshPromise: undefined | Promise<any> = undefined;

export const getTokenRefreshPromise = () => {
  return tokenRefreshPromise;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setRefreshTokenPromise = (arg: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  tokenRefreshPromise = arg;
};

export async function manageError({
  language,
  error,
  keyRoute: keyRouteProps,
  addToast,
  setStatus,
  setRoute,
  tCommon,
  axiosInstance,
  setIsDisconnecting,
}: {
  language: ELanguageTags;
  error: ErrorHandled;
  keyRoute: ERouting;
  addToast: (props: Omit<ToastObject, 'id'>) => string;
  setStatus: (status: EUserStatus) => void;
  setRoute: (key: ERouting, queries?: NodeJS.Dict<string | string[]>) => void;
  setIsDisconnecting: (isDisconnecting?: boolean) => void;
  isDisconnecting?: boolean;
  tCommon?: NextTranslate;
  axiosInstance: AxiosInstance;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): Promise<ErrorHandled> {
  const keyRoute = (RoutingService.getKeyRoute() as ERouting) || keyRouteProps;

  // When the user is locked or blocked
  if (
    [EUserStatus.LOCKED, EUserStatus.BLOCKED].includes(error.userStatus) &&
    keyRoute !== ERouting.SIGN_IN
  ) {
    Logger.logInfo(`User ${error.userStatus} : back to sign-in page`, {
      route: keyRoute,
      userStatus: error.userStatus,
    });
    setStatus(error.userStatus);
    setRoute(ERouting.SIGN_IN);
    AuthService.removeToken();
    setIsDisconnecting(true);
    if (error?.isToast) {
      displayToast(error, addToast, getTitleToast(error?.status, tCommon), tCommon);
    } else {
      addToast({
        title: getTitleToast(EColor.DANGER, tCommon),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        description: tCommon('userBanned.description', { status: error.userStatus }),
        status: EColor.DANGER,
      } as ToastObject);
    }
    Logger.logInfo('User not allow to connect: back to sign-in page', {
      userStatus: error.userStatus,
      route: keyRoute,
    });
    return error;
  }

  // When API send 401, we must refresh the token
  const originalConfig = error.config;
  if (
    error.code === EHttpStatusCode.UNAUTHORIZED &&
    AuthService.getRefreshToken() &&
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    !(originalConfig as any)._retry &&
    originalConfig.url !== '/session'
  ) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      (originalConfig as any)._retry = true;
      if (tokenRefreshPromise === undefined) {
        tokenRefreshPromise = AuthService.refreshToken(axiosInstance, language);
      }
      await tokenRefreshPromise;
      tokenRefreshPromise = undefined;

      return axiosInstance.request(originalConfig);
    } catch (e) {
      setIsDisconnecting(true);
      AuthService.removeIsRetry();
      tokenRefreshPromise = undefined;
      Logger.logInfo('Refresh-token invalid: back to sign-in page', {
        code: EHttpStatusCode.UNAUTHORIZED,
        route: keyRoute,
      });
      return error;
    } finally {
      tokenRefreshPromise = undefined;
    }
  }

  if (
    error.code === EHttpStatusCode.UNAUTHORIZED &&
    !AuthService.getAccessToken() &&
    AuthService.getRefreshToken() &&
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    !(originalConfig as any)._retry
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    (originalConfig as any)._retry = true;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    (originalConfig as any)._refreshToken = AuthService.getRefreshToken();
    return axiosInstance.request(originalConfig);
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  if (error.code === EHttpStatusCode.UNAUTHORIZED && (originalConfig as any)._refreshToken) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/restrict-plus-operands
    (originalConfig as any)._numberRetry = (originalConfig as any)._numberRetry
      ? // eslint-disable-next-line @typescript-eslint/restrict-plus-operands, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        (originalConfig as any)._numberRetry + 1
      : 1;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    if ((originalConfig as any)._numberRetry > NUMBER_RETRY) {
      AuthService.removeIsRetry();
      return error;
    }

    return axiosInstance.request(originalConfig);
  }

  const hasToBeConnected = getTypeRoute(keyRoute)?.accessRoute === EAccessRoute.CONNECTED;

  if (error.code === EHttpStatusCode.FORBIDDEN && hasToBeConnected) {
    // If refresh token failed, we disconnect the user on Sign-in Page
    AuthService.removeToken();
    setIsDisconnecting(true);
    Logger.logInfo('You must be connect: back to sign-in page', {
      code: EHttpStatusCode.FORBIDDEN,
      route: keyRoute,
    });
    return error;
  }

  if (error?.isToast) {
    displayToast(error, addToast, getTitleToast(error?.status, tCommon), tCommon);
  }
  return error;
}
