import { ECurrency, EStorageType } from '@core/type';

import { EStorageKey } from '../constants';
import { getItem, logStorage, setItem } from '../helpers';

const setCurrencyStore = (storage: EStorageType, value: ECurrency) => {
  logStorage('setCurrencyStore');
  setItem(storage, EStorageKey.CURRENCY, value);
};

const getCurrencyStore = (storage: EStorageType): ECurrency => {
  return getItem(storage, EStorageKey.CURRENCY) as ECurrency;
};

export class CurrencyStorage {
  public static readonly set = setCurrencyStore;
  public static readonly get = getCurrencyStore;
}
