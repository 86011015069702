import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type {
  KycProfileEntity,
  StepLocationUploadFileEntity,
  KycStepResponse,
  KycQuestionsEntity,
  KycStepRequest,
} from '@core/type/api';

import { KycAPI } from '../api/kyc';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('KycService', methodName, dto, apiCall, withContent);
};

const getQuestionList = (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<KycQuestionsEntity> => {
  return log('getQuestionList', { axiosInstance, language }, KycAPI.getQuestionList);
};

const getUserSteps = (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<KycProfileEntity> => {
  return log('getUserSteps', { axiosInstance, language }, KycAPI.getUserSteps);
};

const putKycStep = <T extends KycStepRequest>(
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  dto: T,
): Promise<KycStepResponse> => {
  return log(`putKycStep:${dto.step}`, { axiosInstance, language, ...dto }, KycAPI.putKycStep);
};

const patchKycStep = <T extends KycStepRequest>(
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  dto: T,
): Promise<KycStepResponse> => {
  return log(`patchKycStep:${dto.step}`, { axiosInstance, language, ...dto }, KycAPI.patchKycStep);
};

const getUtilityBills = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<StepLocationUploadFileEntity> => {
  return log('getUtilityBills', { axiosInstance, language }, KycAPI.getUtilityBills);
};

const deleteUtilityBills = (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<void> => {
  return log('deleteUtilityBills', { axiosInstance, language }, KycAPI.deleteUtilityBills);
};

const postUtilityBills = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: FormData,
): Promise<StepLocationUploadFileEntity> => {
  return (await KycAPI.postUtilityBills(axiosInstance, language, data)).data;
};

export class KycService {
  public static readonly getQuestionList = getQuestionList;
  public static readonly getUserSteps = getUserSteps;
  public static readonly putKycStep = putKycStep;
  public static readonly patchKycStep = patchKycStep;
  public static readonly getUtilityBills = getUtilityBills;
  public static readonly postUtilityBills = postUtilityBills;
  public static readonly deleteUtilityBills = deleteUtilityBills;
}
