import { FC } from 'react';
import styled, { CSSObject } from 'styled-components';

import { WithThemeProps } from '@core/type';
import { getPaletteHandlers } from '@core/util';

import { TypographyProps } from './interface-typography';

export const Typography: FC<TypographyProps> = styled.p(
  (props: TypographyProps & WithThemeProps) => {
    const {
      theme: {
        typography: {
          defaultVariant,
          textDecoration,
          fontStyle,
          fontWeight: fontWeightTheme,
          ...restTypography
        },
        palette,
      },
      variant = defaultVariant,
      color,
      fontWeight: fontWeightVariant,
      children,
      hoverColor,
      ...restProps
    } = props;
    const { getTextColor } = getPaletteHandlers(palette);

    return {
      fontStyle,
      textDecoration,
      color: color ? getTextColor(color) : 'inherit',
      lineHeight: restTypography[variant].lineHeight,
      fontSize: restTypography[variant].fontSize,
      fontWeight: fontWeightTheme[fontWeightVariant || restTypography[variant].fontWeight],
      ...restProps,
      ':hover': {
        color: getTextColor(hoverColor),
      },
    } as CSSObject;
  },
);
