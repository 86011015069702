import { UtilNext } from '@sentry';
import type { LogLevelDesc } from 'loglevel';
import log from 'loglevel';
import { Logger as LoggerAxiom } from 'next-axiom';

import {
  ECountry,
  ECurrency,
  EHttpStatusCode,
  ELanguageTags,
  ERouting,
  EUserStatus,
  MediaQuerySize,
} from '@core/type';
import { OrderResponse, UserEntity } from '@core/type/api';

import { TagLogger } from './interface';

/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-redundant-type-constituents */
type LogElement = string | Event | any;
type RequestLog = {
  route: ERouting;
  time: number;
};

type LogParam =
  | {
      user?: UserEntity;
      code?: EHttpStatusCode;
      language?: ELanguageTags;
      route?: ERouting;
      isException?: boolean;
      endpoint?: string;
      library?: string;
      mediaQuery?: MediaQuerySize;
      query?: NodeJS.Dict<string | string[]>;
      userStatus?: EUserStatus;
      currency?: ECurrency;
      cart?: OrderResponse;
      country?: ECountry;
    }
  | RequestLog;

const logInfo = (element: LogElement, params?: LogParam) => {
  const logger = new LoggerAxiom();
  log.info(element);
  logger.info(JSON.stringify(element), params);
  void logger.flush();
};

const logWarn = (element: LogElement, params?: LogParam) => {
  const logger = new LoggerAxiom();
  log.warn(element);
  logger.warn(JSON.stringify(element), params);
  void logger.flush();
};

const logError = (element: LogElement, params?: LogParam) => {
  const logger = new LoggerAxiom();
  log.error(element);
  logger.error(JSON.stringify(element), params);
  void logger.flush();
};

const logDebug = (element: LogElement, params?: LogParam) => {
  const logger = new LoggerAxiom();
  log.debug(element);
  logger.debug(JSON.stringify(element), params);
  void logger.flush();
};

const initialize = (logLevel: LogLevelDesc) => {
  log.setLevel(logLevel);
};

const setUser = (user: UserEntity) => {
  UtilNext.setUser({ email: user?.email });
  logInfo(`User ${user?.userId || user?.firstName || user?.email} successfully logged`, { user });
};

const setTags = (tags: Partial<TagLogger>) => {
  UtilNext.setTags(tags);
};

export class Logger {
  public static readonly initialize = initialize;
  public static readonly logInfo = logInfo;
  public static readonly logWarn = logWarn;
  public static readonly logError = logError;
  public static readonly logDebug = logDebug;
  public static readonly setUser = setUser;
  public static readonly setTags = setTags;
}
