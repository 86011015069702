import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { CountriesEntity } from '@core/type/api';

import { CountriesAPI } from '../api/countries';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('CountriesService', methodName, dto, apiCall, withContent);
};

const getCountries = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<CountriesEntity> => {
  return log<{ language: ELanguageTags }, CountriesEntity>(
    'getCountries',
    { axiosInstance, language },
    CountriesAPI.getCountries,
  );
};

export class CountriesService {
  public static readonly getCountries = getCountries;
}
