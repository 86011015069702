import { SVG } from './SVG';

export const IconSuccess = () => (
  <SVG width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.15" cx="20.25" cy="21.75" r="17.25" fill="#09bb70" />
    <path
      d="M30.7279 19.2427L22.2426 27.728L18 23.4854"
      stroke="#09bb70"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <circle cx="24" cy="24" r="17.4" stroke="#09bb70" strokeWidth="1.2" />
  </SVG>
);
