import { useCallback } from 'react';

export const useBodyStyle = () => {
  const removePosition = useCallback(() => {
    document.body.style.position = '';
    document.body.style.width = '';
  }, []);

  const fixedPosition = useCallback(() => {
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
  }, []);

  return {
    removePosition,
    fixedPosition,
  };
};
