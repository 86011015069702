import { useContextMediaQuery } from '@core/context';
import { ETypographyVariant } from '@core/type';

import { Grid } from '../grid/Grid';
import { Box, Container } from '../layout';
import { Line } from '../line';
import { Paper } from '../paper';
import { SkeletonTypography } from './SkeletonTypography';
import { SkeletonWrapper } from './SkeletonWrapper';

export const SkeletonPayment = () => {
  const { isPhone, isMobile } = useContextMediaQuery();

  return (
    <Container>
      <SkeletonWrapper paddingTop={'44px'} overflow={'visible'}>
        <Box
          marginBottom={'32px'}
          display={'flex'}
          justifyContent={'space-between'}
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <Box>
            <SkeletonTypography
              variant={ETypographyVariant.H2}
              maxWidth={isPhone ? '260px' : '320px'}
              marginBottom={'16px'}
            />
            <SkeletonTypography maxWidth={'300px'} marginBottom={isMobile ? '16px' : undefined} />
          </Box>

          <SkeletonTypography maxWidth={'240px'} />
        </Box>

        {isMobile && <Line margin={'40px 0'} />}

        <SkeletonTypography maxWidth={'260px'} marginBottom={'24px'} />

        <Paper
          marginBottom={'48px'}
          padding={isMobile ? undefined : '40px'}
          hasElevation={!isMobile}
        >
          <Grid isContainer>
            <Grid md={6} sm={12}>
              <Box padding={isMobile ? '0' : '0 15px'}>
                <SkeletonTypography lineHeight={'60px'} width={'200px'} marginBottom={'40px'} />
                <SkeletonTypography lineHeight={isMobile ? '40px' : '20px'} />
              </Box>
            </Grid>

            {isMobile && <Line margin={'40px 0'} width={'100%'} />}

            <Grid md={6} sm={12}>
              <Box padding={isMobile ? '0' : '0 15px'}>
                <SkeletonTypography lineHeight={'60px'} width={'200px'} marginBottom={'40px'} />
                <SkeletonTypography lineHeight={'92px'} />
              </Box>
            </Grid>
          </Grid>
        </Paper>

        {!isMobile && (
          <>
            <SkeletonTypography width={'160px'} marginBottom={'24px'} />

            <Paper
              marginBottom={'48px'}
              padding={isMobile ? undefined : '40px'}
              hasElevation={!isMobile}
            >
              <Grid isContainer>
                <Grid md={6} sm={12}>
                  <Box padding={isMobile ? '0' : '0 15px'}>
                    <SkeletonTypography lineHeight={'76px'} maxWidth={'300px'} />
                  </Box>
                </Grid>

                <Grid md={6} sm={12}>
                  <Box
                    padding={isMobile ? '0' : '0 15px'}
                    display={'flex'}
                    gap={'16px'}
                    justifyContent={'flex-end'}
                  >
                    <SkeletonTypography lineHeight={'48px'} borderRadius={'8px'} width={'170px'} />
                    <SkeletonTypography lineHeight={'48px'} borderRadius={'8px'} width={'170px'} />
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </>
        )}
      </SkeletonWrapper>
    </Container>
  );
};
