import { AbTestTracking } from './abTest';
import { ECurrency } from './currency';
import { ELanguage } from './language';
import { EFullMetalName } from './metal';
import { EFullPaymentType } from './order-details';
import { EFullProductType } from './product-type';
import { Price } from './unit';

declare global {
  interface Window {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    dataLayer: Array<any> | undefined;

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    [key: string]: any;
  }
}

export enum EGTMLoginStatus {
  LOGGED_IN = 'Logged in',
  NOT_LOGGED_IN = 'Not Logged In',
}

export enum EGTMUserType {
  CUSTOMER = 'Customer',
  VISITOR = 'Visitor',
  NOT_LOGGED_IN = 'Not Logged In',
}

export enum EGTMEventName {
  PAGE_VIEW = 'page_view',
  VIEW_ITEM = 'view_item',
  ADD_TO_CART = 'add_to_cart',
  VIEW_CART = 'view_cart',
  BEGIN_CHECKOUT = 'begin_checkout',
  ADD_SHIPPING_INFO = 'add_shipping_info',
  ADD_PAYMENT_INFO = 'add_payment_info',
  VIEW_ORDER_REVIEW = 'view_order_review',
  PURCHASE = 'purchase',
  PROGRESS_CHECKOUT_FLOW = 'progress_checkout_flow',
  COMPLETE_KYC = 'complete_kyc',
  LOGIN = 'login',
  PROGRESS_REGISTRATION_FLOW = 'progress_registration_flow',
  OPEN_CHAT = 'open_chat',
  SEND_CHAT = 'send_chat',
  CLICK_PHONE_CALL = 'click_phone_call',
  REQUEST_CALL = 'request_call',
  CAPTURE_EMAIL = 'capture_email',
}

export enum EGTMStorageOrShipping {
  STORAGE = 'Storage',
  SHIPPING = 'Shipping',
}

export enum EGTMShippingMethod {
  UPS_POINT = 'UPS point',
  PERSONAL_ADDRESS = 'Personal address',
  STORAGE = 'Storage',
}

export enum EGTMCaptureLocation {
  SIGN_UP = 'Sign up',
  NEWSLETTER = 'Newsletter',
  LEAD_MAGNET = 'Lead magnet',
  CONTACT_FORM = 'Contact form',
}

export enum EGTMProductAvailability {
  IN_STOCK = 'In Stock',
  OUT_OF_STOCK = 'Out of stock',
}

export enum EGTMStepName {
  STORAGE_AND_SHIPPING = 'Storage & Shipping',
  BILLING_ADDRESS = 'Billing address',
  PAYMENT_METHOD = 'Payment method',
  ORDER_REVIEW = 'Order review',
  OPEN_YOUR_ACCOUNT = 'Open your account',
  DONE = 'Done',
}

export enum EGTMStepNumber {
  STORAGE_AND_SHIPPING = 1,
  BILLING_ADDRESS = 2,
  PAYMENT_METHOD = 3,
  ORDER_REVIEW = 4,
  OPEN_YOUR_ACCOUNT = 5,
  DONE = 6,
}

export type GTMProductProps = {
  itemId: number;
  itemName: string;
  itemCategory: EFullMetalName;
  itemCategory1: EFullProductType;
  itemWeight: number;
  itemAvailability: EGTMProductAvailability;
  price: Price;
  quantity: number;
  unitFees?: Price;
  totalFees?: Price;
  fees?: number;
};

type GTMECommerce = {
  currency: ECurrency;
  value: number;
  items: GTMProductProps[];
  orderFees?: Price;

  // optional order data
  cartSize?: number;
  transactionId?: string;
  storageOrShipping?: EGTMStorageOrShipping;
  shippingMethod?: EGTMShippingMethod;
  shippingAmount?: number;
  shippingCountry?: string;
  paymentMethod?: EFullPaymentType;
};

export enum EGTMNetworkName {
  FACEBOOK = 'Facebook',
  TWITTER = 'Twitter',
  LINKEDIN = 'LinkedIn',
}

export enum EGTMVideoProviderName {
  YOUTUBE = 'Youtube',
}

export enum EGTMKYCLimit {
  UNLIMITED = 'unlimited',
}

export type GTMDataLayer = {
  // global data
  event: EGTMEventName;
  language: Exclude<ELanguage, ELanguage.DEFAULT>;
  pageUrl: string;
  eventOrigin: string;
  userId: string;
  loginStatus: EGTMLoginStatus;
  userType: EGTMUserType;

  // global optional data
  searchTerm?: string;
  scrollPercentage?: string; //50%, 90%
  videoTitle?: string;
  videoProvider?: EGTMVideoProviderName;
  networkName?: EGTMNetworkName;
  networkUrl?: string;
  stepNumber?: EGTMStepNumber;
  stepName?: EGTMStepName;
  currency?: ECurrency;
  abTest?: AbTestTracking;

  ecommerce?: GTMECommerce;

  KYCPurchasingLimit?: EGTMKYCLimit;

  // For sending emails
  capturedLocation?: EGTMCaptureLocation;

  assistantBudget?: number;
  assistantMetal?: EFullMetalName;
};
