import withTranslation from 'next-translate/withTranslation';

import { Box } from '@core/component';
import { Translate } from '@core/constant';
import { useContextUser } from '@core/context';

import { SideNavDefaultProps } from '../../../../interface-header';
import { CurrencyDrawerLine } from './CurrencyDrawerLine';
import { HelloUser } from './HelloUser';
import { LanguageDrawerLine } from './LanguageDrawerLine';
import { Menus } from './Menu';
import { SideNavFooter } from './SideNavFooter';

const SideNavDefaultContainerNew = ({
  closeDrawer,
  drawerLevelProps,
  handleDrawerLevelProps,
  shouldShowCurrencyLink,
  menus,
}: SideNavDefaultProps) => {
  const { isLogged } = useContextUser();

  return (
    <>
      {isLogged && (
        <HelloUser
          closeDrawer={closeDrawer}
          handleDrawerLevelProps={handleDrawerLevelProps}
          drawerLevelProps={drawerLevelProps}
        />
      )}
      <Menus
        menus={menus}
        drawerLevelProps={drawerLevelProps}
        closeDrawer={closeDrawer}
        handleDrawerLevelProps={handleDrawerLevelProps}
        shouldShowCurrencyLink={shouldShowCurrencyLink}
      />
      <Box margin={'48px 0'}>
        <LanguageDrawerLine
          drawerLevelProps={drawerLevelProps}
          handleDrawerLevelProps={handleDrawerLevelProps}
          shouldShowCurrencyLink={shouldShowCurrencyLink}
        />
        <CurrencyDrawerLine
          drawerLevelProps={drawerLevelProps}
          handleDrawerLevelProps={handleDrawerLevelProps}
          shouldShowCurrencyLink={shouldShowCurrencyLink}
        />
      </Box>
      <SideNavFooter closeDrawer={closeDrawer} />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const SideNavDefaultNew = withTranslation(
  SideNavDefaultContainerNew,
  Translate.layout.HEADER,
);
