import Image from 'next/image';

import IconCaseSvg from './svgs/icon-case.svg';

export const IconCase = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconCaseSvg}
    alt="Case icon"
    height={24}
    width={24}
  />
);
