import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconClock = ({ fill, secondFill }: DynamicIconProps) => (
  <SVG height={40} width={40} viewBox="0 0 40 40" title="Clock icon">
    <circle opacity="0.1" cx="18.3335" cy="18.3335" r="15" fill={secondFill} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0002 35.4668C28.5422 35.4668 35.4668 28.5422 35.4668 20.0002C35.4668 11.4582 28.5422 4.5335 20.0002 4.5335C11.4582 4.5335 4.5335 11.4582 4.5335 20.0002C4.5335 28.5422 11.4582 35.4668 20.0002 35.4668ZM20.0002 36.6668C29.2049 36.6668 36.6668 29.2049 36.6668 20.0002C36.6668 10.7954 29.2049 3.3335 20.0002 3.3335C10.7954 3.3335 3.3335 10.7954 3.3335 20.0002C3.3335 29.2049 10.7954 36.6668 20.0002 36.6668Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2001 7.2665C20.2001 6.93513 19.9315 6.6665 19.6001 6.6665C19.2687 6.6665 19.0001 6.93513 19.0001 7.2665L19.0001 21.0665C19.0001 21.1079 19.0043 21.1484 19.0123 21.1874C19.0682 21.4608 19.3101 21.6665 19.6001 21.6665L28.4001 21.6665C28.7315 21.6665 29.0001 21.3979 29.0001 21.0665C29.0001 20.7351 28.7315 20.4665 28.4001 20.4665L20.2001 20.4665L20.2001 7.2665Z"
      fill={fill}
    />
  </SVG>
);
