import { useContextMediaQuery } from '@core/context';
import { ETypographyVariant } from '@core/type';

import { Grid } from '../grid';
import { Box } from '../layout';
import { SkeletonTypography } from './SkeletonTypography';
import { SkeletonWrapper } from './SkeletonWrapper';

export const SkeletonWishlist = () => {
  const { isMobile, isPhone } = useContextMediaQuery();

  return (
    <SkeletonWrapper>
      <SkeletonTypography variant={ETypographyVariant.H2} width={'260px'} marginBottom={'11px'} />
      <SkeletonTypography
        lineHeight={isPhone ? '88px' : isMobile ? '44px' : '48px'}
        maxWidth={'750px'}
        marginBottom={'36px'}
      />

      <Grid isContainer rowSpacing={24} columns={24} columnSpacing={2}>
        {/* eslint-disable-next-line @typescript-eslint/no-magic-numbers */}
        {[...Array(4).keys()].map((index: number) => (
          <Grid key={index} xs={12} lg={8} xl={6}>
            <Box padding={isPhone ? '44px 16px' : '60px 32px 64px'}>
              <SkeletonTypography lineHeight={isPhone ? '120px' : '180px'} marginBottom={'16px'} />
              <SkeletonTypography lineHeight={'40px'} marginBottom={'8px'} />
              <SkeletonTypography lineHeight={'28px'} width={'120px'} margin={'0 auto 4px'} />
              <SkeletonTypography lineHeight={'16px'} width={'140px'} margin={'0 auto'} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </SkeletonWrapper>
  );
};
