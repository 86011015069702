import { useContextRouting, useStoreAlternate } from '@core/context';
import { Alternates } from '@core/type';
import { AlternatesEntity } from '@core/type/api';
import { AlternateHook, AlternateState, HookStoreFn } from '@core/type/context';
import { buildAlternate } from '@core/util';

export const useAlternate: HookStoreFn<AlternateState, AlternateHook> = (): AlternateHook => {
  const [alternates, setAlternatesMeta] = useStoreAlternate<Alternates[]>('alternates');
  const { keyRoute, query } = useContextRouting();

  const setAlternates = (alternates?: AlternatesEntity) => {
    setAlternatesMeta(buildAlternate({ alternates, keyRoute, query }));
  };

  return {
    alternates,
    setAlternates,
  };
};
