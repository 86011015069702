import { Children, cloneElement, ReactElement, useMemo } from 'react';
import styled, { CSSObject } from 'styled-components';

import { useContextMediaQuery } from '@core/context';
import { ESize, MediaQuerySize, WithThemeProps } from '@core/type';

import { GridProps, StyledGridProps } from './interface-grid';

const mediaQueryList: MediaQuerySize[] = [ESize.XS, ESize.SM, ESize.MD, ESize.LG, ESize.XL];

export const Grid = ({
  children,
  isContainer,
  columns,
  spacing,
  rowSpacing,
  columnSpacing,
  ...restProps
}: GridProps) => {
  const { mediaQuery } = useContextMediaQuery();

  const childrenWithProps = useMemo(() => {
    if (isContainer) {
      return Children.map(
        children,
        (child) =>
          child &&
          cloneElement(child as ReactElement, {
            columns,
            spacing,
            rowSpacing,
            columnSpacing,
          }),
      );
    } else {
      return children;
    }
  }, [isContainer, children, columns, spacing, rowSpacing, columnSpacing]);

  return (
    <StyledGrid
      mediaQuery={mediaQuery}
      isContainer={isContainer}
      columns={columns}
      spacing={spacing}
      rowSpacing={rowSpacing}
      columnSpacing={columnSpacing}
      {...restProps}
    >
      {childrenWithProps}
    </StyledGrid>
  );
};

export const StyledGrid = styled.div<StyledGridProps>(
  ({
    theme: {
      structure: {
        grid: {
          item: { rowSpacing: rowSpacingTheme, columnSpacing: columnSpacingTheme },
          container: { columns: gridThemeColumns, ...containerThemeRules },
        },
      },
    },
    isContainer,
    spacing,
    rowSpacing: rowSpacingProps,
    columnSpacing: columnSpacingProps,
    mediaQuery: mediaQueryProps,
    columns: columnsProps = gridThemeColumns,
    children,
    isReversed,
    ...restProps
  }: StyledGridProps & WithThemeProps) => {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const rowSpacing = (rowSpacingProps ?? spacing ?? rowSpacingTheme) / 2;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const columnSpacing = (columnSpacingProps ?? spacing ?? columnSpacingTheme) / 2;

    if (isContainer) {
      return {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        width: `calc(100% + ${columnSpacing * 2}px)`,
        margin: `-${rowSpacing}px -${columnSpacing}px`,
        flexDirection: isReversed ? 'row-reverse' : 'row',
        ...containerThemeRules,
        ...restProps,
      } as CSSObject;
    } else {
      let gridColumns = columnsProps;

      for (const mediaQuery of mediaQueryList) {
        const currentColumns = restProps[mediaQuery];
        gridColumns = currentColumns ? currentColumns : gridColumns;

        if (mediaQuery === mediaQueryProps) {
          break;
        }
      }

      return {
        width: `calc(${gridColumns} / ${columnsProps} * 100%)`,
        padding: `${rowSpacing}px ${columnSpacing}px`,
      } as CSSObject;
    }
  },
);
