import { BreadcrumbObject, EAlternateLevels, ERouting, ERoutingDynamic } from '@core/type';
import { BreadcrumbEntity } from '@core/type/api';

import { getTypeRoute } from './routing';

export type BreadcrumbParams = {
  keyRoute: ERouting;
  breadcrumbs?: ERouting[];
  dynamicsBreadcrumbs?: BreadcrumbEntity[];
};

const getIndexByTitle = (searchTitle: string, breadcrumbs?: BreadcrumbEntity[]) =>
  breadcrumbs?.find(({ title }) => title === searchTitle)?.index;

const getSlugByIndex = (i: number, breadcrumbs?: BreadcrumbEntity[]) =>
  breadcrumbs?.find(({ index }) => index === i)?.slug;

export const getQueriesFromDynamicRoute = (
  keyRouteDynamic: ERoutingDynamic,
  dynamicsBreadcrumbs?: BreadcrumbEntity[],
  title?: string,
  slug?: string,
): BreadcrumbObject => {
  switch (keyRouteDynamic) {
    case ERoutingDynamic.CHARTS:
      return {
        title,
        keyRoute: ERouting.CHARTS,
        href: slug,
      };
    case ERoutingDynamic.PRODUCT_SHOW: {
      const metal = dynamicsBreadcrumbs?.find(
        ({ breadcrumbType }) => breadcrumbType === ERoutingDynamic.METAL,
      )?.slug;
      if (metal) {
        return {
          title,
          keyRoute: ERouting.PRODUCT_SHOW,
          query: {
            metal,
            slug,
          },
        };
      }
      return null;
    }
    case ERoutingDynamic.METAL: {
      return { title, keyRoute: ERouting.BUY, query: { metal: slug } };
    }
    case ERoutingDynamic.METAL_CATEGORY: {
      const metal = dynamicsBreadcrumbs?.find(
        ({ breadcrumbType }) => breadcrumbType === ERoutingDynamic.METAL,
      )?.slug;

      // breadcrumbs from API
      if (metal) {
        return {
          title,
          keyRoute: ERouting.SHOP_SUBCATEGORY,
          query: {
            metal,
            slug,
          },
        };
      }

      // breadcrumbs from Sanity (external link)
      if (slug?.includes('https')) {
        return {
          title,
          href: slug,
        };
      }

      // breadcrumbs from Sanity (internal link)
      return {
        title,
        keyRoute: ERouting.BUY,
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        query: { metal: slug.split('/').slice(3).join('/') },
      };
    }
    case ERoutingDynamic.TUTORIAL_VIDEO: {
      return { title, keyRoute: ERouting.TUTORIAL_VIDEO, query: { slug } };
    }
    case ERoutingDynamic.PRECIOUS_METAL_GUIDE:
    case ERoutingDynamic.PRECIOUS_METAL_GUIDE_LEVEL1:
    case ERoutingDynamic.PRECIOUS_METAL_GUIDE_LEVEL2:
    case ERoutingDynamic.PRECIOUS_METAL_GUIDE_LEVEL3: {
      const index = getIndexByTitle(title, dynamicsBreadcrumbs);
      const slug = getSlugByIndex(EAlternateLevels.LEVEL3, dynamicsBreadcrumbs);
      const subSlug = getSlugByIndex(EAlternateLevels.LEVEL4, dynamicsBreadcrumbs);

      switch (index as EAlternateLevels) {
        case EAlternateLevels.LEVEL2:
          return { title, keyRoute: ERouting.PRECIOUS_METAL_GUIDE };
        case EAlternateLevels.LEVEL3:
          return { title, keyRoute: ERouting.PRECIOUS_METAL_GUIDE_LEVEL1, query: { slug } };
        case EAlternateLevels.LEVEL4:
          return {
            title,
            keyRoute: ERouting.PRECIOUS_METAL_GUIDE_LEVEL2,
            query: { slug, subSlug },
          };
        default:
          return { title, keyRoute: ERouting.PRECIOUS_METAL_GUIDE };
      }
    }
    case ERoutingDynamic.BLOG_CATEGORY: {
      return { title, keyRoute: ERouting.BLOG_CATEGORY, query: { slug } };
    }
    case ERoutingDynamic.BLOG_TAG: {
      return { title, keyRoute: ERouting.BLOG_TAG, query: { slug } };
    }
    case ERoutingDynamic.BLOG_ARTICLE: {
      const category = dynamicsBreadcrumbs?.find(
        ({ breadcrumbType }) => breadcrumbType === ERoutingDynamic.BLOG_ARTICLE,
      )?.slug;
      if (category) {
        return {
          title,
          keyRoute: ERouting.BLOG_ARTICLE,
          query: {
            category,
            slug,
          },
        };
      }
      return null;
    }
    case ERoutingDynamic.FAQ_ARTICLE: {
      return { title, keyRoute: ERouting.FAQ_SHOW, query: { slug } };
    }
    case ERoutingDynamic.PAGES: {
      return {
        title,
        keyRoute: ERouting.PAGES,
        href: slug,
      };
    }
    default:
      return null;
  }
};

export const buildBreadcrumb = ({
  keyRoute,
  breadcrumbs,
  dynamicsBreadcrumbs,
}: BreadcrumbParams): BreadcrumbObject[] => {
  if (Array.isArray(breadcrumbs)) {
    const breadcrumbObjects: BreadcrumbObject[] = [];
    breadcrumbs?.forEach((keyRouteBreadcrumb) => {
      if (getTypeRoute(keyRouteBreadcrumb).keyRouteDynamic && dynamicsBreadcrumbs) {
        dynamicsBreadcrumbs.forEach(({ title, slug, breadcrumbType }) => {
          const dynamicBuild = getQueriesFromDynamicRoute(
            breadcrumbType,
            dynamicsBreadcrumbs,
            title,
            slug,
          );
          if (dynamicBuild) {
            breadcrumbObjects.push(dynamicBuild);
          }
        });
      } else {
        breadcrumbObjects.push({ keyRoute: keyRouteBreadcrumb });
      }
    });

    if (getTypeRoute(keyRoute).keyRouteDynamic && dynamicsBreadcrumbs) {
      dynamicsBreadcrumbs.forEach(({ title, slug, breadcrumbType }) => {
        const dynamicBuild = getQueriesFromDynamicRoute(
          breadcrumbType,
          dynamicsBreadcrumbs,
          title,
          slug,
        );
        if (dynamicBuild) {
          breadcrumbObjects.push(dynamicBuild);
        }
      });
    } else {
      breadcrumbObjects.push({ keyRoute });
    }

    return breadcrumbObjects;
  }
  return null;
};
