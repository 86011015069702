import Head from 'next/head';

import { ERouting, PageSSR } from '@core/type';

import { makeServerProps } from '../helpers/make-server-props/make-server-props';
import { useSSRPage } from '../hooks';
import { ViewMaintenance } from '../view';

export type PageMaintenanceProps = PageSSR<unknown, unknown, unknown>;

export const Maintenance = () => {
  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
      </Head>
      <ViewMaintenance />
    </>
  );
};

const PageMaintenance = (props: PageMaintenanceProps) =>
  useSSRPage<unknown, unknown, unknown>({
    ...props,
    ViewPage: Maintenance,
  });

const getServerSideProps = makeServerProps({ keyRoute: ERouting.MAINTENANCE });

export { getServerSideProps };

export default PageMaintenance;
