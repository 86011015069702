import { ReactNode } from 'react';

import { cartFormContext } from '@core/context';
import { ECountry } from '@core/type';
import { CartFormHook, CartFormState } from '@core/type/context';

import { storeHook } from '../helpers';
import { useCartForm } from '../hook';

export const ProviderCartForm = ({ children }: { children?: ReactNode }) => {
  const hooks = storeHook<CartFormState, CartFormHook>(
    {
      country: ECountry.SWITZERLAND,
      errors: {},
    },
    useCartForm,
  );
  return <cartFormContext.Provider value={hooks}>{children}</cartFormContext.Provider>;
};
