import Image from 'next/image';

import IconHelveticorSecondarySvg from './svgs/icon-helveticor-secondary.svg';

export const IconHelveticorSecondary = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconHelveticorSecondarySvg}
    alt="Helveticor icon"
    height={19}
    width={136}
  />
);
