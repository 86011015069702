import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type {
  CreateCustomerSurveyEntity,
  CreateCustomerSurveyResponse,
  CustomerSurveysResponse,
} from '@core/type/api';

import { CustomerSurveysAPI } from '../api/customer-surveys';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('CustomerSurveyService', methodName, dto, apiCall, withContent);
};

const getCustomerSurveys = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<CustomerSurveysResponse> => {
  return log(
    'getCustomerSurveys',
    { axiosInstance, language },
    CustomerSurveysAPI.getCustomerSurveys,
  );
};

const postCustomerSurveys = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: CreateCustomerSurveyEntity,
): Promise<CreateCustomerSurveyResponse> => {
  return log(
    'postCustomerSurveys',
    { axiosInstance, language, ...data },
    CustomerSurveysAPI.postCustomerSurveys,
  );
};

export class CustomerSurveysService {
  public static readonly getCustomerSurveys = getCustomerSurveys;
  public static readonly postCustomerSurveys = postCustomerSurveys;
}
