import { useContextMediaQuery } from '@core/context';

import { Grid } from '../grid';
import { Box } from '../layout';
import { SkeletonTypography } from './SkeletonTypography';
import { SkeletonWrapper } from './SkeletonWrapper';

const SKELETON_TYPOGRAPHY_IMAGES = 4;

export const SkeletonProductShow = () => {
  const { isPhone, isDesktop } = useContextMediaQuery();

  return (
    <SkeletonWrapper>
      <Grid isContainer columnSpacing={30} rowSpacing={24}>
        <Grid lg={6}>
          {!isDesktop && (
            <SkeletonTypography lineHeight={'30px'} marginBottom={isPhone ? '4px' : '40px'} />
          )}
          {isPhone && (
            <SkeletonTypography lineHeight={'28px'} width={'70%'} marginBottom={'40px'} />
          )}

          <SkeletonTypography
            lineHeight={isPhone ? '318px' : '456px'}
            maxWidth={isPhone ? '318px' : '456px'}
            margin={'0 auto 48px'}
          />

          <Box
            display={'flex'}
            justifyContent={'space-around'}
            maxWidth={'384px'}
            margin={'0 auto'}
          >
            {[...Array(SKELETON_TYPOGRAPHY_IMAGES).keys()].map((index: number) => (
              <SkeletonTypography
                key={index}
                lineHeight={isPhone ? '64px' : '68px'}
                width={isPhone ? '64px' : '68px'}
              />
            ))}
          </Box>
        </Grid>

        <Grid lg={6}>
          {isDesktop && (
            <>
              <SkeletonTypography lineHeight={'32px'} marginBottom={'8px'} />
              <SkeletonTypography lineHeight={'32px'} width={'65%'} marginBottom={'40px'} />
            </>
          )}

          <SkeletonTypography
            lineHeight={isPhone ? '32px' : '38px'}
            width={'160px'}
            marginBottom={'10px'}
          />
          <SkeletonTypography lineHeight={'20px'} width={'150px'} />

          {!isPhone && (
            <>
              <Box display={'flex'} flexDirection={'column'} gap={'4px'} margin={'40px 0'}>
                <SkeletonTypography width={'290px'} />
                <SkeletonTypography width={'320px'} />
                <SkeletonTypography width={'430px'} />
              </Box>

              <SkeletonTypography lineHeight={'48px'} width={'144px'} marginBottom={'24px'} />

              <SkeletonTypography lineHeight={'48px'} width={'210px'} borderRadius={'8px'} />

              <Box marginTop={'80px'} display={'flex'} gap={'16px'}>
                <SkeletonTypography lineHeight={'36px'} width={'140px'} borderRadius={'8px'} />
                <SkeletonTypography lineHeight={'36px'} width={'120px'} borderRadius={'8px'} />
                <SkeletonTypography lineHeight={'36px'} width={'130px'} borderRadius={'8px'} />
              </Box>
            </>
          )}
        </Grid>
      </Grid>

      {isPhone && (
        <Box position={'fixed'} bottom={0} left={0} right={0} padding={'20px 12px'}>
          <SkeletonTypography lineHeight={'48px'} borderRadius={'8px'} />
        </Box>
      )}
    </SkeletonWrapper>
  );
};
