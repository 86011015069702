import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type { GAPayEntity, StorageFeeClaimsEntity } from '@core/type/api';
import { PagedRequest } from '@core/type/api';

import { Api } from '../constants/api';
import { configResponse, EConfigName } from '../helpers/config';
import { getPagedQuery } from '../helpers/paged';

const getGAPayHistory = async (
  axiosInstance: AxiosInstance,
  { language, ...params }: LanguageProps & PagedRequest,
): Promise<AxiosResponse<GAPayEntity>> => {
  return axiosInstance.get(
    `${Api.accountTransactions.GA_PAY}${getPagedQuery(params)}`,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getStorageFeeClaimsHistory = async (
  axiosInstance: AxiosInstance,
  { language, ...params }: LanguageProps & PagedRequest,
): Promise<AxiosResponse<StorageFeeClaimsEntity>> => {
  return axiosInstance.get(
    `${Api.accountTransactions.STORAGE_FEE_CLAIMS}${getPagedQuery(params)}`,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class AccountTransactionAPI {
  public static readonly getGAPayHistory = getGAPayHistory;
  public static readonly getStorageFeeClaimsHistory = getStorageFeeClaimsHistory;
}
