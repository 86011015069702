import { useContextMediaQuery } from '@core/context';

import { SkeletonTypography } from './SkeletonTypography';
import { SkeletonWrapper } from './SkeletonWrapper';

export const SkeletonTutorialVideo = () => {
  const { isPhone } = useContextMediaQuery();

  return (
    <SkeletonWrapper maxWidth={'755px'} margin={'88px auto 0'}>
      <SkeletonTypography lineHeight={isPhone ? '64px' : '46px'} marginBottom={'40px'} />
      <SkeletonTypography
        lineHeight={isPhone ? '112px' : '84px'}
        marginBottom={isPhone ? '16px' : '40px'}
      />
      <SkeletonTypography lineHeight={'430px'} />
    </SkeletonWrapper>
  );
};
