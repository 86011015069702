import type { AxiosInstance, AxiosResponse } from 'axios';

import { Logger } from '@core/logger';
import { AuthStorage } from '@core/storage';
import { ELanguageTags, EStorageType } from '@core/type';
import type {
  AuthEntity,
  ChangePasswordEntity,
  ForgottenPasswordEntity,
  LoginEntity,
  ResendUnlockInstructionsEntity,
  TwoStepAuthReq,
  UnlockAccountEntity,
  UserSignUp,
} from '@core/type/api';

import { AuthAPI } from '../api/auth';
import { logService } from '../helpers/util';

const formatBearer = (token: string) => `Bearer ${token}`;

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
): Promise<R> => {
  return logService('AuthService', methodName, dto, apiCall, true);
};

const setToken = ({ token, refreshToken }: AuthEntity) => {
  AuthStorage.setTokenStore(EStorageType.COOKIE, token);
  AuthStorage.setRefreshTokenStore(EStorageType.COOKIE, refreshToken);
};

const getToken = (): AuthEntity => ({
  token: AuthStorage.getTokenStore(EStorageType.COOKIE) || '',
  refreshToken: AuthStorage.getRefreshTokenStore(EStorageType.COOKIE) || '',
});

const getAccessToken = () => AuthStorage.getTokenStore(EStorageType.COOKIE);
const getRefreshToken = () => AuthStorage.getRefreshTokenStore(EStorageType.COOKIE);
const setIsRetry = () => AuthStorage.setIsRetryStore(EStorageType.COOKIE, 'true');

const removeToken = () => {
  AuthStorage.removeTokenStore(EStorageType.COOKIE);
  AuthStorage.removeRefreshTokenStore(EStorageType.COOKIE);
};

const removeIsRetry = () => {
  AuthStorage.removeIsRetryStore(EStorageType.COOKIE);
};

const removeAccessToken = () => {
  AuthStorage.removeTokenStore(EStorageType.COOKIE);
};

const removeRefreshToken = () => {
  AuthStorage.removeRefreshTokenStore(EStorageType.COOKIE);
};

const logout = async (axiosInstance: AxiosInstance, language: ELanguageTags): Promise<void> => {
  try {
    await log('logout', { axiosInstance, language }, AuthAPI.logout);
  } finally {
    removeToken();
  }
};

const login = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: LoginEntity,
): Promise<AuthEntity> => {
  return await log('login', { axiosInstance, language, ...data }, AuthAPI.login);
};

const autoLogged = async (axiosInstance: AxiosInstance, language: ELanguageTags): Promise<void> => {
  const { token, refreshToken } = getToken();

  if (token && refreshToken) {
    const data = await log(
      'refreshToken',
      {
        axiosInstance,
        language,
        refreshToken,
      },
      AuthAPI.refreshToken,
    );
    setToken(data);
  } else {
    throw new Error('NO_TOKEN');
  }
};

const refreshToken = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<AuthEntity> => {
  Logger.logInfo('Call AuthService::refreshToken', { library: 'core-api' });
  const { refreshToken } = getToken();

  const data = await log(
    'refreshToken',
    {
      axiosInstance,
      language,
      refreshToken,
    },
    AuthAPI.refreshToken,
  );
  setToken(data);
  return data;
};

const refreshTokenSSR = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  refreshToken: string,
): Promise<AuthEntity> => {
  Logger.logInfo('Call AuthService::refreshTokenSSR', { library: 'core-api' });
  const data = await log(
    'refreshToken',
    {
      axiosInstance,
      language,
      refreshToken,
    },
    AuthAPI.refreshToken,
  );
  return data;
};

const changePassword = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: ChangePasswordEntity,
): Promise<void> => {
  const changePasswordAction = async () => {
    const token = await log(
      'changePassword',
      { axiosInstance, language, ...data },
      AuthAPI.changePassword,
    );
    setToken(token);
  };

  return changePasswordAction();
};

const unlockAccount = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: UnlockAccountEntity,
): Promise<void> => {
  return log('unlockAccount', { axiosInstance, language, ...data }, AuthAPI.unlockAccount);
};

const forgottenPassword = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: ForgottenPasswordEntity,
): Promise<void> => {
  return log('forgottenPassword', { axiosInstance, language, ...data }, AuthAPI.forgottenPassword);
};

const getSms = async (axiosInstance: AxiosInstance, language: ELanguageTags): Promise<void> => {
  return log('getSms', { axiosInstance, language }, AuthAPI.getSms);
};

const postSms = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: TwoStepAuthReq,
): Promise<AuthEntity> => {
  return log('postSms', { axiosInstance, language, ...data }, AuthAPI.postSms);
};

const postTotp = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: TwoStepAuthReq,
): Promise<AuthEntity> => {
  return log('postTotp', { axiosInstance, language, ...data }, AuthAPI.postTotp);
};

const resendUnlockInstructions = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: ResendUnlockInstructionsEntity,
): Promise<void> => {
  return log(
    'resendUnlockInstructions',
    { axiosInstance, language, ...data },
    AuthAPI.resendUnlockInstructions,
  );
};

const signUp = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: UserSignUp,
): Promise<void> => {
  const token = await log('signup', { axiosInstance, language, ...data }, AuthAPI.signUp);
  setToken(token);
};

export class AuthService {
  public static readonly logout = logout;
  public static readonly login = login;
  public static readonly signUp = signUp;
  public static readonly postSms = postSms;
  public static readonly postTotp = postTotp;
  public static readonly getSms = getSms;
  public static readonly formatBearer = formatBearer;
  public static readonly refreshToken = refreshToken;
  public static readonly refreshTokenSSR = refreshTokenSSR;
  public static readonly autoLogged = autoLogged;
  public static readonly changePassword = changePassword;
  public static readonly forgottenPassword = forgottenPassword;
  public static readonly resendUnlockInstructions = resendUnlockInstructions;
  public static readonly removeToken = removeToken;
  public static readonly removeAccessToken = removeAccessToken;
  public static readonly removeRefreshToken = removeRefreshToken;
  public static readonly getAccessToken = getAccessToken;
  public static readonly getRefreshToken = getRefreshToken;
  public static readonly setToken = setToken;
  public static readonly unlockAccount = unlockAccount;
  public static readonly removeIsRetry = removeIsRetry;
  public static readonly setIsRetry = setIsRetry;
}
