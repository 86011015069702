import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type { DeliveryCountriesEntity } from '@core/type/api';

import { Api } from '../constants/api';
import { configResponse, EConfigName } from '../helpers/config';

const getDeliveryCountries = (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<DeliveryCountriesEntity>> => {
  return axiosInstance.get(
    Api.deliveryCountries.DELIVERY_COUNTRIES,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class DeliveryCountriesAPI {
  public static readonly getDeliveryCountries = getDeliveryCountries;
}
