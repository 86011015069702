import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type { GiftEntity } from '@core/type/api';

import { Api } from '../constants/api';
import { replaceIdUrl } from '../helpers/api';
import { configResponse, EConfigName } from '../helpers/config';

const getGiftInfomation = (
  axiosInstance: AxiosInstance,
  { reference, language }: LanguageProps & { reference: string },
): Promise<AxiosResponse<GiftEntity>> => {
  return axiosInstance.get(
    replaceIdUrl(Api.gift.GIFT_INFORMATION, reference, ':slug'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class GiftAPI {
  public static readonly getGiftInfomation = getGiftInfomation;
}
