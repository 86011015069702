import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { CurrencyRatesResponse } from '@core/type/api';

import { CurrencyRatesAPI } from '../api/currency-rates';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('CurrencyRatesService', methodName, dto, apiCall, withContent);
};

const getCurrencyRates = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<CurrencyRatesResponse> => {
  return log('getCurrencyRates', { axiosInstance, language }, CurrencyRatesAPI.getCurrencyRates);
};

export class CurrencyRatesService {
  public static readonly getCurrencyRates = getCurrencyRates;
}
