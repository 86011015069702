import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconGraph = ({ fill }: DynamicIconProps) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    title="Graph icon"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path d="M17.5 19.167V10m-15 9.167v-.834m5.417.834v-7.084M13 19.167V13.75" stroke={fill} />
    <path
      d="M15.011 1.23a.6.6 0 1 0 0 1.2h1.086L12.7 7.187 7.912 5.302l-.403-.159-.278.333-5.192 6.223a.6.6 0 0 0 .921.768l4.915-5.89 4.821 1.898.436.171.272-.38 3.507-4.91v.974a.6.6 0 1 0 1.2 0v-2.5a.6.6 0 0 0-.6-.6z"
      fill={fill}
    />
  </SVG>
);
