import { Box } from '@core/component';
import { useContextMediaQuery } from '@core/context';
import {
  EHeaderMenu,
  ESize,
  HeaderMenuFullwidthDropdownProps,
  HeaderMenuLinkProps,
  HeaderMenuShortDropdownProps,
} from '@core/type';
import { SanityHeaderDefaultResponse } from '@core/type/api';

import { MenuFullwidthDropdown } from './MenuFullwidthDropdown';
import { MenuLink } from './MenuLink';
import { MenuShortDropdown } from './MenuShortDropdown';
import { HoveredMenuHandler } from './interface-header-menu';

export const MenuItemsBlock = ({
  menus,
  handleHover,
  isSearchOpen,
}: SanityHeaderDefaultResponse & {
  handleHover: HoveredMenuHandler;
  isSearchOpen: boolean;
}) => {
  const { isMobile, mediaQuery } = useContextMediaQuery();
  const isSmallDesktop = mediaQuery === ESize.LG;

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      gap={isMobile ? '24px' : isSmallDesktop ? '32px' : '48px'}
      justifyContent={'center'}
      opacity={isSearchOpen ? 0 : 1}
      transition={'all 0.3s'}
      pointerEvents={isSearchOpen ? 'none' : 'auto'}
      overflowX={isSearchOpen ? 'hidden' : undefined}
      width={isSearchOpen ? '0' : '100%'}
      paddingRight={isMobile ? '24px' : '0'}
      minWidth={isSearchOpen ? '0' : 'fit-content'}
    >
      {menus
        .filter(({ menuType }) => !(isMobile && menuType === EHeaderMenu.SHORT_DROPDOWN))
        .map(({ menuType, ...restProps }, index) => {
          switch (menuType) {
            case EHeaderMenu.FULLWIDTH_DROPDOWN:
              return (
                <MenuFullwidthDropdown
                  onHover={handleHover}
                  key={index}
                  index={index}
                  {...(restProps as HeaderMenuFullwidthDropdownProps)}
                />
              );
            case EHeaderMenu.SHORT_DROPDOWN:
              return (
                <MenuShortDropdown
                  key={index}
                  index={index}
                  onHover={handleHover}
                  {...(restProps as HeaderMenuShortDropdownProps)}
                />
              );
            case EHeaderMenu.LINK:
              return (
                <MenuLink
                  key={index}
                  index={index}
                  onHover={handleHover}
                  {...(restProps as HeaderMenuLinkProps)}
                />
              );
          }
        })}
    </Box>
  );
};
