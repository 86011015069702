import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type {
  GiftPatchPayload,
  GiftsConfirmResponseEntity,
  GiftsRelationshipsEntity,
  GiftsRequestEntity,
  UserGiftResponse,
  WaitingGiftsEntity,
} from '@core/type/api';
import { ToastResponse } from '@core/type/context';

import { Api } from '../constants/api';
import { replaceIdUrl } from '../helpers/api';
import { configResponse, EConfigName } from '../helpers/config';

const getGiftsRelationships = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<GiftsRelationshipsEntity>> => {
  return axiosInstance.get(
    Api.user.GIFTS_RELATIONSHIPS,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const postGifts = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & GiftsRequestEntity,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.post(
    Api.user.GIFTS,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getWaitingGifts = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<WaitingGiftsEntity>> => {
  return axiosInstance.get(Api.user.GIFTS, configResponse({ [EConfigName.LANGUAGE]: language }));
};

const patchGift = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & GiftPatchPayload,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.patch(
    Api.user.GIFTS,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const confirmGift = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<GiftsConfirmResponseEntity>> => {
  return axiosInstance.put(
    Api.user.GIFTS_CONFIRM,
    null,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const acceptGift = async (
  axiosInstance: AxiosInstance,
  {
    referenceNumber,
    language,
  }: LanguageProps & {
    referenceNumber: string;
  },
): Promise<AxiosResponse<ToastResponse>> => {
  return axiosInstance.put(
    replaceIdUrl(Api.user.GIFT_ACCEPT, referenceNumber, ':referenceNumber'),
    null,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const declineGift = async (
  axiosInstance: AxiosInstance,
  {
    referenceNumber,
    language,
  }: LanguageProps & {
    referenceNumber: string;
  },
): Promise<AxiosResponse<ToastResponse>> => {
  return axiosInstance.put(
    replaceIdUrl(Api.user.GIFT_DECLINE, referenceNumber, ':referenceNumber'),
    null,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getGiftUserInformation = async (
  axiosInstance: AxiosInstance,
  {
    referenceNumber,
    language,
  }: LanguageProps & {
    referenceNumber: string;
  },
): Promise<AxiosResponse<UserGiftResponse>> =>
  axiosInstance.get(
    replaceIdUrl(Api.user.GIFT, referenceNumber, ':referenceNumber'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

export class GiftsAPI {
  public static readonly postGifts = postGifts;
  public static readonly getGiftsRelationships = getGiftsRelationships;
  public static readonly patchGift = patchGift;
  public static readonly confirmGift = confirmGift;
  public static readonly getWaitingGifts = getWaitingGifts;
  public static readonly acceptGift = acceptGift;
  public static readonly declineGift = declineGift;
  public static readonly getGiftUserInformation = getGiftUserInformation;
}
