import { ONFIDO_SDK_URL, crisp, externalAnalytics, google, vercel } from '@core/constant';

import { EnvironmentPublicNext } from '../../helpers/environment';

export const getFontSrc = (publicEnvironment: Partial<EnvironmentPublicNext>): string[] => {
  const {
    cdnCsp,
    isDevelopmentServer,
    enableGoogleAnalytics,
    enableCrisp,
    enableVercelPreviewFeedback,
  } = publicEnvironment;

  const crispUrl = crisp.CRISP_URL;
  const fontGoogleUrl = google.FONT_URL;
  const vercelLiveUrl = vercel.LIVE_URL;
  const taboolaUrl = externalAnalytics.TABOOLA_URL;

  let fontSrc = [
    `'self'`,
    ONFIDO_SDK_URL,
    cdnCsp,
    enableVercelPreviewFeedback && vercelLiveUrl,
    isDevelopmentServer && 'data:',
    enableCrisp && crispUrl,
  ];
  if (enableGoogleAnalytics) {
    fontSrc = fontSrc.concat([fontGoogleUrl, taboolaUrl, 'data:']);
  }

  return fontSrc.filter((src) => src);
};
