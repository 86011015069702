import Script from 'next/script';

import { TWITTER_DOMAIN } from '@core/constant';

const TWITTER_SCRIPT_URL = `${TWITTER_DOMAIN}/widgets.js`;
export const TWITTER_EVENT = 'twitterPlaceLoaded';

export const TwitterNextScript = ({ nonce }: { nonce: string }): JSX.Element | null => {
  return nonce ? (
    <Script
      strategy="lazyOnload"
      src={TWITTER_SCRIPT_URL}
      nonce={nonce}
      onLoad={() => {
        window.dispatchEvent(new CustomEvent(TWITTER_EVENT));
      }}
    />
  ) : null;
};
