import { useContextUtil } from '@core/context';
import { getStaticPathFlagFromCountry } from '@core/media';

import { Image } from '../image';
import { IconCountryProps } from './interface-icon';

export const IconCountry = ({ country, ...restProps }: IconCountryProps) => {
  const { cdn } = useContextUtil();

  return (
    <Image
      alt={`${country} icon`}
      width={16}
      height={16}
      style={{ borderRadius: '50%', objectFit: 'cover' }}
      src={getStaticPathFlagFromCountry(country, cdn)}
      {...restProps}
    />
  );
};
