import { FC } from 'react';
import styled, { CSSObject } from 'styled-components';

import { EActionAttribute, ETypographyVariant, WithThemeProps } from '@core/type';

import { EditorDefaultContent } from './EditorDefaultContent';
import { EditorDefaultContentProps, EEditorClassName } from './interface-editor';

export const EditorArticleContent: FC<EditorDefaultContentProps> = styled(EditorDefaultContent)(({
  theme: {
    typography: { fontWeight: _fontWeight, ...restTypographyProps },
  },
}: WithThemeProps & EditorDefaultContentProps) => {
  return {
    [EActionAttribute.P]: {
      ...restTypographyProps[ETypographyVariant.BODY1],

      [`&:not(.${EEditorClassName.SMALL_TEXT}):not(.${EEditorClassName.SMALLER_TEXT})`]: {
        lineHeight: 1.55,
      },
    },
    [`${EActionAttribute.DIV}, ${EActionAttribute.H1}, ${EActionAttribute.H2}`]: {
      marginTop: 104,
      marginBottom: 40,
    },
    [EActionAttribute.FIGURE]: {
      marginTop: '40px !important',
      marginBottom: '40px !important',
    },
    [EActionAttribute.H3]: {
      marginTop: 72,
      marginBottom: 20,
    },
    [EActionAttribute.LI]: {
      ...restTypographyProps[ETypographyVariant.BODY1],
      lineHeight: 1.66,
    },
  } as CSSObject;
});
