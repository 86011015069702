import Image from 'next/image';

import IconVisaSvg from '../svgs/icon-visa.svg';

export const IconVisa = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconVisaSvg}
    alt="Visa icon"
    height={20}
    width={31}
  />
);
