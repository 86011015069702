import useTranslation from 'next-translate/useTranslation';

import { Box, Button, Line, Link, LinkTranslatedStyledAsLink } from '@core/component';
import { HEADER_CONTAINER_WIDTH, Translate } from '@core/constant';
import { useContextMediaQuery, useContextRouting } from '@core/context';
import {
  EActionAttribute,
  EButtonVariant,
  EColor,
  EGTMEventName,
  EIcon,
  EPosition,
  ERouting,
  ESize,
} from '@core/type';
import { SelectWithLngAndCur } from '@core/widget';

import { FullLogoLink } from '../FullLogoLink';
import { UserMenu } from '../UserMenu';
import { HeaderDefaultDesktopProps } from './interface-header-default';
import { Search } from './search';

export const HeaderDefaultDesktop = ({
  phone,
  hasProfileError,
  isLogged,
  fullName,
  pushEventUserInteraction,
  shouldShowCurrencyLink,
}: HeaderDefaultDesktopProps) => {
  const { setRoute } = useContextRouting();
  const { mediaQuery } = useContextMediaQuery();
  const { t } = useTranslation(Translate.layout.HEADER);

  const isSmallDesktop = mediaQuery === ESize.LG;

  return (
    <Box
      maxWidth={`${HEADER_CONTAINER_WIDTH}px`}
      display={'flex'}
      alignItems={'center'}
      padding={'16px 0'}
      margin={'0 auto'}
      justifyContent={'space-between'}
      backgroundColor={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
    >
      <Box display={'flex'} flex={'1'} alignItems={'center'}>
        <Box flexShrink={'0'} marginRight={isSmallDesktop ? '24px' : '48px'}>
          <FullLogoLink height={'30px'} iconType={EIcon.GA_LOGO_FULL_COLOR_FILLED} />
        </Box>

        <Search />

        <Box
          margin={'0 20px'}
          display={'flex'}
          alignItems={'center'}
          position={'relative'}
          top={'1px'}
          whiteSpace={'nowrap'}
        >
          <Link
            as={EActionAttribute.SPAN}
            onClick={() => {
              pushEventUserInteraction(EGTMEventName.CLICK_PHONE_CALL);
              window.location.href = `tel:${phone}`;
            }}
          >
            {phone}
          </Link>
          <Line isVertical color={EColor.PRIMARY} margin={'0 12px'} height={'18px'} />
          <LinkTranslatedStyledAsLink keyRoute={ERouting.CONTACT}>
            {t('needHelp')}
          </LinkTranslatedStyledAsLink>
        </Box>
      </Box>

      <Box
        display={'flex'}
        alignItems={'center'}
        gap={'24px'}
        position={'relative'}
        height={'24px'}
      >
        <SelectWithLngAndCur
          containerIsRelative={false}
          position={EPosition.TOP_RIGHT}
          shouldShowCurrencyLink={shouldShowCurrencyLink}
        />
        {isLogged ? (
          <UserMenu fullName={fullName} isError={hasProfileError} />
        ) : (
          <Button
            variant={EButtonVariant.CONTAINED}
            color={EColor.SECONDARY}
            lineHeight={'40px'}
            onClick={() => setRoute(ERouting.SIGN_IN)}
          >
            {t('menu.signIn')}
          </Button>
        )}
      </Box>
    </Box>
  );
};
