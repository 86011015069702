import { useCallback } from 'react';

import { useStoreMediaQuery } from '@core/context';
import { Logger } from '@core/logger';
import { ETagLogger } from '@core/logger/type';
import type { MediaQuerySize, ThemeMediaQuery } from '@core/type';
import { DeviceSizeProps, ESize } from '@core/type';
import { HookStoreFn, MediaQueryHook, MediaQueryState } from '@core/type/context';

export const useMediaQuery: HookStoreFn<MediaQueryState, MediaQueryHook> = (): MediaQueryHook => {
  const [mediaQuery, setMediaQuery] = useStoreMediaQuery<MediaQuerySize>('mediaQuery');
  const [isPhone, setIsPhone] = useStoreMediaQuery<boolean>('isPhone');
  const [isTablet, setIsTablet] = useStoreMediaQuery<boolean>('isTablet');
  const [isMobile, setIsMobile] = useStoreMediaQuery<boolean>('isMobile');
  const [isDesktop, setIsDesktop] = useStoreMediaQuery<boolean>('isDesktop');
  const [themeMediaQueries] = useStoreMediaQuery<ThemeMediaQuery>('themeMediaQueries');

  const setDeviceCheck = useCallback(
    ({ isPhone, isTablet, isMobile, isDesktop }: DeviceSizeProps) => {
      setIsPhone(isPhone);
      setIsTablet(isTablet);
      setIsMobile(isMobile);
      setIsDesktop(isDesktop);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const detectRange = useCallback(
    (width: number) => {
      // From desktop to mobile approach
      if (width >= themeMediaQueries[ESize.XL]) {
        Logger.setTags({ [ETagLogger.MEDIA_QUERY]: ESize.XL });
        setMediaQuery(ESize.XL);
        setDeviceCheck({ isPhone: false, isTablet: false, isMobile: false, isDesktop: true });
        return ESize.XL;
      } else if (width >= themeMediaQueries[ESize.LG]) {
        Logger.setTags({ [ETagLogger.MEDIA_QUERY]: ESize.LG });
        setMediaQuery(ESize.LG);
        setDeviceCheck({ isPhone: false, isTablet: false, isMobile: false, isDesktop: true });
        return ESize.LG;
      } else if (width >= themeMediaQueries[ESize.MD]) {
        Logger.setTags({ [ETagLogger.MEDIA_QUERY]: ESize.MD });
        setMediaQuery(ESize.MD);
        setDeviceCheck({ isPhone: false, isTablet: true, isMobile: true, isDesktop: false });
        return ESize.MD;
      } else if (width >= themeMediaQueries[ESize.SM]) {
        Logger.setTags({ [ETagLogger.MEDIA_QUERY]: ESize.SM });
        setMediaQuery(ESize.SM);
        setDeviceCheck({ isPhone: true, isTablet: false, isMobile: true, isDesktop: false });
        return ESize.SM;
      } else {
        Logger.setTags({ [ETagLogger.MEDIA_QUERY]: ESize.XS });
        setMediaQuery(ESize.XS);
        setDeviceCheck({ isPhone: true, isTablet: false, isMobile: true, isDesktop: false });
        return ESize.XS;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setDeviceCheck],
  );

  return {
    mediaQuery,
    detectRange,
    isPhone,
    isTablet,
    isMobile,
    isDesktop,
  };
};
