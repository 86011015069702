import withTranslation from 'next-translate/withTranslation';

import { Box } from '@core/component';
import { Translate } from '@core/constant';
import { useContextMetalPrice } from '@core/context';
import { EMetal, MetalPrice, WithTranslation } from '@core/type';

import { LiveChartsLink } from './LiveChartsLink';
import { LiveChartsMetalLink } from './LiveChartsMetalLink';
import { PriceMetal } from './PriceMetal';

const HeaderPriceContainer = ({ i18n: { t } }: WithTranslation) => {
  const { prices } = useContextMetalPrice();

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      textAlign={'center'}
      padding={'6px'}
    >
      <LiveChartsLink />
      {Object.entries(prices).map(([metal, metalPrice]: [string, MetalPrice]) => {
        const { deskbid = 0, name, priceChange = 0 } = metalPrice;
        return (
          <LiveChartsMetalLink metal={metal as EMetal} key={metal}>
            <PriceMetal title={t(name)} priceChange={priceChange} deskbid={deskbid} />
          </LiveChartsMetalLink>
        );
      })}
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const HeaderPrice = withTranslation(HeaderPriceContainer, Translate.layout.HEADER);
