import { Box, ErrorDot, LinkTranslatedStyledAsLink } from '@core/component';
import { SideNavDrawerLineProps } from '@core/component/interface';
import { EColor } from '@core/type';

export const DropdownLine = ({
  route,
  title,
  icon,
  hasError,
  closeDrawer,
}: SideNavDrawerLineProps) => (
  <Box padding={'10px 0px'}>
    <LinkTranslatedStyledAsLink
      startIcon={icon}
      keyRoute={route}
      iconColor={EColor.ACCENT}
      isIconFill={false}
      isUnderlinedHover
      verticalAlign="middle"
      onClick={closeDrawer}
      iconMargin={8}
    >
      {title}
    </LinkTranslatedStyledAsLink>
    {hasError && (
      <Box display={'inline-block'} marginLeft={'15px'}>
        <ErrorDot />
      </Box>
    )}
  </Box>
);
