import Image from 'next/image';

import IconAlertSvg from './svgs/icon-alert.svg';

export const IconAlert = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconAlertSvg}
    alt="Alert icon"
    height={24}
    width={24}
  />
);
