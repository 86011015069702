import { FC } from 'react';
import styled from 'styled-components';

import { EActionAttribute, ETypographyVariant, WithThemeProps } from '@core/type';

import { Box } from '../layout/Box';
import { EditorDefaultContentProps } from './interface-editor';

export const EditorInlineContent: FC<EditorDefaultContentProps> = styled(Box)(
  ({ theme: { typography } }: EditorDefaultContentProps & WithThemeProps) => ({
    [EActionAttribute.P]: {
      ...typography[ETypographyVariant.BODY2],
      display: 'inline',
    },
  }),
);
