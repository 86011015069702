import withTranslation from 'next-translate/withTranslation';

import { Box, Line } from '@core/component';
import { DrawerCloseProps } from '@core/component/interface';
import { Translate } from '@core/constant';
import { useContextProfile, useContextUser } from '@core/context';
import { EIcon, ERouting, WithTranslation } from '@core/type';

import { SignInButton, SignOutButton } from '../../SignInOrOutButton';
import { DropdownLine } from './DropdownLine';
import { MyWallet } from './MyWallet';
import { ReferFriends } from './ReferFriends';

const MobileDrawerBase = ({ i18n: { t }, closeDrawer }: WithTranslation & DrawerCloseProps) => {
  const { hasNotCompletedStep, hasStorageFeesUnpaid } = useContextProfile();
  const { isLogged } = useContextUser();

  return (
    <>
      <MyWallet closeDrawer={closeDrawer} />
      <Line margin={'22px 20px'} />

      <Box padding={'0 40px'}>
        <DropdownLine
          icon={EIcon.AUTO_SAVINGS}
          title={t('menu.autoSavings')}
          route={ERouting.WALLET_AUTO_SAVINGS}
          closeDrawer={closeDrawer}
        />
        <DropdownLine
          icon={EIcon.STORAGE_FEES}
          title={t('menu.storageFees')}
          route={ERouting.STORAGE_FEES}
          hasError={hasStorageFeesUnpaid}
          closeDrawer={closeDrawer}
        />
        <DropdownLine
          icon={EIcon.PAYMENT}
          title={t('menu.paymentMethods')}
          route={ERouting.PAYMENTS}
          closeDrawer={closeDrawer}
        />
        <DropdownLine
          icon={EIcon.CLOCK_WITH_ARROW}
          title={t('menu.orderHistory')}
          route={ERouting.WALLET_ORDERS}
          closeDrawer={closeDrawer}
        />
        <DropdownLine
          icon={EIcon.ALERT_FILLED}
          title={t('menu.alertsSettings')}
          route={ERouting.ALERT_SETTINGS}
          closeDrawer={closeDrawer}
        />
        <DropdownLine
          icon={EIcon.HEART_BIG}
          title={t('menu.wishList')}
          route={ERouting.WISHLIST}
          closeDrawer={closeDrawer}
        />
        <DropdownLine
          icon={EIcon.CART_PURCHASING_LIMIT}
          title={t('menu.purchasingLimit')}
          route={ERouting.PURCHASING_LIMIT}
          hasError={hasNotCompletedStep}
          closeDrawer={closeDrawer}
        />
        <DropdownLine
          icon={EIcon.SETTINGS}
          title={t('menu.profileSettings')}
          route={ERouting.PROFILE}
          closeDrawer={closeDrawer}
        />
        <Line margin={'22px -20px 48px'} />
        <ReferFriends closeDrawer={closeDrawer} />
        {isLogged ? (
          <SignOutButton closeDrawer={closeDrawer} />
        ) : (
          <SignInButton closeDrawer={closeDrawer} />
        )}
      </Box>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const MobileDrawer = withTranslation(MobileDrawerBase, Translate.layout.HEADER);
