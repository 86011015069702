// ISO 4217
export enum ECurrency {
  EUR = 'EUR',
  CHF = 'CHF',
  USD = 'USD',
  GBP = 'GBP',
}

export type Currency = {
  name: string;
  key: string;
  iso: ECurrency;
};

export enum ECurrencySlug {
  SWISS_FRANC = 'swiss_franc',
  EURO = 'euro',
  DOLLAR = 'dollar',
  POUND_STERLING = 'pound_sterling',
}
