import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type { EHomepageProductsCategory, HomepageProductsCategoryEntity } from '@core/type/api';

import { Api } from '../constants/api';
import { configResponse, EConfigName } from '../helpers/config';
import { addParameters } from '../helpers/util';

const getHomepageProductsCategory = async (
  axiosInstance: AxiosInstance,
  { language, metalIso }: LanguageProps & EHomepageProductsCategory,
): Promise<AxiosResponse<HomepageProductsCategoryEntity>> =>
  axiosInstance.get(
    addParameters(Api.product.PRODUCT_HOMEPAGE, {
      metal_iso: metalIso,
    }),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

export class HomepageProductsApi {
  public static readonly getHomepageProductsCategory = getHomepageProductsCategory;
}
