import { Box, Container, Line } from '@core/component';
import { HEADER_CONTAINER_WIDTH } from '@core/constant';
import { EColor, EIcon, EPosition } from '@core/type';
import { SelectWithLngAndCur } from '@core/widget';

import { FullLogoLink } from '../FullLogoLink';

export const HeaderShortNew = ({
  shouldShowCurrencyLink,
  hasLine,
}: {
  shouldShowCurrencyLink: boolean;
  hasLine?: boolean;
}) => {
  return (
    <Container gutter={0} maxWidth={'100%'}>
      <Container
        maxWidth={'100%'}
        backgroundColor={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
      >
        <Box
          maxWidth={`${HEADER_CONTAINER_WIDTH}px`}
          display={'flex'}
          alignItems={'center'}
          padding={'16px 0'}
          margin={'0 auto'}
          justifyContent={'space-between'}
          backgroundColor={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
          height="72px"
        >
          <FullLogoLink height={'30px'} iconType={EIcon.GA_LOGO_FULL_COLOR_FILLED} />
          <SelectWithLngAndCur
            withCurrency={false}
            position={EPosition.TOP_RIGHT}
            shouldShowCurrencyLink={shouldShowCurrencyLink}
          />
        </Box>
      </Container>
      {hasLine && <Line />}
    </Container>
  );
};
