import { EStrokeWidth } from '@core/type';

import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconCross = ({
  stroke,
  strokeWidth = EStrokeWidth.ICON_DEFAULT,
}: DynamicIconProps) => (
  <SVG height={17} width={17} viewBox="0 0 17 17" title="Cross icon">
    <g stroke="none" strokeWidth={strokeWidth} fill="none" fillRule="evenodd" strokeLinecap="round">
      <g transform="translate(-939.000000, -454.000000)" stroke={stroke} strokeWidth={strokeWidth}>
        <g transform="translate(940.000000, 455.000000)">
          <path d="M15,0 L0,15" id="Stroke-25" />
          <path
            d="M15,0 L0,15"
            id="Stroke-25"
            transform="translate(7.500000, 7.500000) scale(-1, 1) translate(-7.500000, -7.500000) "
          />
        </g>
      </g>
    </g>
  </SVG>
);
