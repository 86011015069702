import Image from 'next/image';

import IconLoomisSvg from './svgs/icon-loomis.svg';

export const IconLoomis = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconLoomisSvg}
    alt="Loomis icon"
    height={20}
    width={36}
  />
);
