import { SVG } from '../SVG';
import { DynamicIconProps } from '../interface-icon';

export const IconYoutube = ({ fill }: DynamicIconProps) => (
  <SVG height={24} width={25} viewBox="0 0 25 24" title="Youtube icon">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.502 7.914c.551.151.985.596 1.132 1.162.268 1.025.268 3.163.268 3.163s0 2.139-.268 3.164a1.63 1.63 0 0 1-1.132 1.162c-.998.274-5 .274-5 .274s-4.003 0-5.001-.274a1.63 1.63 0 0 1-1.132-1.162c-.267-1.025-.267-3.164-.267-3.164s0-2.138.267-3.163a1.63 1.63 0 0 1 1.132-1.162c.998-.275 5-.275 5-.275s4.003 0 5.001.275m-6.2 2.525v4l3.2-2z"
      fill={fill}
    />
  </SVG>
);
