import type { ReactNode } from 'react';
import { useMemo } from 'react';

import { metalPriceContext } from '@core/context';
import { MetalsPrice } from '@core/type';
import { MetalPriceHook, MetalPriceState } from '@core/type/context';

import { storeHook } from '../helpers';
import { makeUseMetalPrice } from '../hook';

export const ProviderMetalPrice = ({
  wsHost,
  children,
  metalPriceInitialize,
}: {
  wsHost: string;
  children?: ReactNode;
  metalPriceInitialize?: MetalsPrice;
}) => {
  const useMetalPrice = useMemo(() => makeUseMetalPrice(wsHost), [wsHost]);

  const hookMetalPrice = storeHook<MetalPriceState, MetalPriceHook>(
    {
      prices: metalPriceInitialize,
    },
    useMetalPrice,
  );

  return <metalPriceContext.Provider value={hookMetalPrice}>{children}</metalPriceContext.Provider>;
};
