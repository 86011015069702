/* eslint-disable @typescript-eslint/no-magic-numbers */
import { useContextMediaQuery } from '@core/context';
import { ETypographyVariant } from '@core/type';

import { Box } from '../layout';
import { Paper } from '../paper';
import { SkeletonShop } from './SkeletonShop';
import { SkeletonTypography } from './SkeletonTypography';
import { SkeletonWrapper } from './SkeletonWrapper';

export const SkeletonShopSubcategory = () => {
  const { isPhone, isDesktop } = useContextMediaQuery();

  const createDescription = (lines: number) =>
    [...Array(lines).keys()].map((index) => (
      <SkeletonTypography key={index} variant={ETypographyVariant.BODY3} marginBottom={'4px'} />
    ));

  return (
    <SkeletonWrapper overflow={'visible'}>
      <Paper padding={isPhone ? '30px 24px' : '30px 40px'} borderRadius={'10px'}>
        <Box display={'flex'} gap={'22px'}>
          {isDesktop && <SkeletonTypography minWidth={'108px'} lineHeight={'108px'} />}
          <Box width={'calc(100% - 108px)'}>
            <SkeletonTypography
              marginBottom={isPhone ? '8px' : '16px'}
              variant={ETypographyVariant.H2}
            />
            <Box>
              {createDescription(2)}
              <SkeletonTypography
                variant={ETypographyVariant.BODY3}
                width={'50%'}
                marginBottom={isPhone ? '8px' : '16px'}
              />

              {createDescription(4)}
              <SkeletonTypography width={'20%'} />
            </Box>
          </Box>
        </Box>
      </Paper>

      <SkeletonShop />
    </SkeletonWrapper>
  );
};
