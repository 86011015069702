import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type {
  BlogArticlesEntity,
  BlogCategoriesEntity,
  BlogSearchAutocompleteEntity,
  ContentEntity,
} from '@core/type/api';

import { ContentAPI } from '../api/content';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('ContentService', methodName, dto, apiCall, withContent);
};

const blogBySlug = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  slug: string,
): Promise<ContentEntity> => {
  return log('blogBySlug', { axiosInstance, language, slug }, ContentAPI.blogBySlug);
};

const blog = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  page?: number,
  perPage?: number,
): Promise<BlogArticlesEntity> => {
  return log('blog', { axiosInstance, page, perPage, language }, ContentAPI.blog);
};

const getCategoryArticles = (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  slug: string,
  page?: number,
  perPage?: number,
): Promise<BlogCategoriesEntity> => {
  return log(
    'getCategoryArticles',
    { axiosInstance, language, page, perPage, slug },
    ContentAPI.getCategoryArticles,
  );
};

const getTagArticles = (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  slug: string,
  page?: number,
  perPage?: number,
): Promise<BlogCategoriesEntity> => {
  return log(
    'getTagArticles',
    { axiosInstance, language, page, perPage, slug },
    ContentAPI.getTagArticles,
  );
};

const searchArticles = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  q: string,
  page?: number,
  perPage?: number,
): Promise<BlogArticlesEntity> => {
  return log(
    'searchArticles',
    { axiosInstance, language, page, perPage, q },
    ContentAPI.blogSearch,
  );
};

const searchArticlesAutocomplete = (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  q?: string,
): Promise<BlogSearchAutocompleteEntity> => {
  return log(
    'searchArticlesAutocomplete',
    { axiosInstance, q, language },
    ContentAPI.blogSearchAutocomplete,
  );
};

export class ContentService {
  public static readonly blogBySlug = blogBySlug;
  public static readonly blog = blog;
  public static readonly getCategoryArticles = getCategoryArticles;
  public static readonly getTagArticles = getTagArticles;
  public static readonly searchArticles = searchArticles;
  public static readonly searchArticlesAutocomplete = searchArticlesAutocomplete;
}
