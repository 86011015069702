import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconCartRepeatRounded = ({ fill }: DynamicIconProps) => {
  return (
    <SVG height={24} width={24} viewBox="0 0 24 24" title="Cart repeat rounded icon" fill="inherit">
      <g>
        <path
          fill={fill}
          d="M16.3,17.7c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1s-1.1,0.5-1.1,1.1S15.7,17.7,16.3,17.7z"
        />
        <path
          fill={fill}
          d="M12.5,0c-6.6,0-12,5.4-12,12s5.4,12,12,12s12-5.4,12-12S19.1,0,12.5,0z M13.1,5.8c0.6,0,1.2,0.2,1.7,0.5V5.7   c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v1.4v0.4h-0.4h-1.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4H14   c-0.3-0.1-0.6-0.2-0.9-0.2c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4   c0,1.8-1.5,3.3-3.3,3.3S9.8,10.8,9.8,9S11.3,5.8,13.1,5.8z M18.8,10.9l-0.7,2.8c0,0.2-0.2,0.3-0.4,0.3H8.9l0,0H8.6   c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1h0.2c0.2-0.8,1-1.3,1.8-1.3s1.6,0.6,1.8,1.3h2.2c0.2-0.8,1-1.3,1.8-1.3c1,0,1.9,0.9,1.9,1.9   s-0.8,1.9-1.9,1.9c-1,0-1.8-0.8-1.9-1.7h-2c-0.1,1-0.9,1.7-1.9,1.7s-1.8-0.8-1.9-1.7H8.6c-1,0-1.8-0.8-1.8-1.8   c0-0.9,0.7-1.7,1.6-1.8L6.5,6.3L5.6,5.7C5.4,5.6,5.4,5.3,5.5,5.1C5.6,4.9,5.9,4.9,6.1,5l1,0.7c0.1,0.1,0.1,0.1,0.2,0.2l2,7.3l0,0   l0,0h8.2l0.6-2.4c0.1-0.2,0.3-0.3,0.5-0.3C18.7,10.5,18.8,10.7,18.8,10.9z"
        />
        <path
          fill={fill}
          d="M10.6,17.7c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1S9.5,16,9.5,16.6S10,17.7,10.6,17.7z"
        />
      </g>
    </SVG>
  );
};
