import { createContext } from 'react';

import type { CurrencyHook, CurrencyState, HookStore } from '@core/type/context';

import { useFnContext, useStoreContext } from '../util';

export const currencyContext =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createContext<HookStore<any, CurrencyState, CurrencyHook>>(null);

export const useContextCurrency = () => {
  return useFnContext(currencyContext);
};

export function useStoreCurrency<Selector>(selector: keyof CurrencyState) {
  return useStoreContext<Selector, CurrencyState, CurrencyHook>(selector, currencyContext);
}
