import { EStorageType } from '@core/type';
import { OrderResponse } from '@core/type/api';

import { EStorageKey } from '../constants';
import { getItem, logStorage, removeItem, setItem } from '../helpers';

const getCart = (): OrderResponse => {
  const storedCard = getItem(EStorageType.LOCAL_STORAGE, EStorageKey.CART);

  if (!storedCard) {
    return null;
  }

  try {
    const parsedCart = JSON.parse(storedCard) as OrderResponse;
    if (parsedCart && typeof parsedCart === 'object' && 'id' in parsedCart) {
      return parsedCart;
    }
    return null;
  } catch {
    return null;
  }
};

const setCart = (cart: OrderResponse) => {
  logStorage('setCart');

  setItem(EStorageType.LOCAL_STORAGE, EStorageKey.CART, JSON.stringify(cart));
};

const removeCart = () => {
  logStorage('removeCart');

  removeItem(EStorageType.LOCAL_STORAGE, EStorageKey.CART);
};

export class CartProductsStorage {
  public static readonly getCart = getCart;
  public static readonly setCart = setCart;
  public static readonly removeCart = removeCart;
}
