import { ButtonIcon } from '@core/component';
import { EIcon } from '@core/type';

import { ButtonSearchProps } from './interface-search';

export const ButtonSearch = ({ isOpen, onOpen, onClose }: ButtonSearchProps) => {
  if (isOpen) {
    return (
      <ButtonIcon
        onClick={onClose}
        iconProps={{ type: EIcon.CROSS }}
        width={'28px'}
        height={'28px'}
      />
    );
  }

  return (
    <ButtonIcon
      onClick={onOpen}
      padding={'0'}
      iconProps={{ type: EIcon.SEARCH, width: '28px', height: '28px' }}
    />
  );
};
