import { createContext } from 'react';

import type { HookStore, SelectState, SelectHook } from '@core/type/context';

import { useFnContext, useStoreContext } from '../util';

export const selectContext =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createContext<HookStore<any, SelectState, SelectHook>>(null);

export const useContextSelect = () => {
  return useFnContext(selectContext);
};

export function useStoreSelect<Selector>(selector: keyof SelectState) {
  return useStoreContext<Selector, SelectState, SelectHook>(selector, selectContext);
}
