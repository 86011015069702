import { createContext } from 'react';

import type { BreadcrumbHook, BreadcrumbState, HookStore } from '@core/type/context';

import { useFnContext, useStoreContext } from '../util';

export const breadcrumbContext =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createContext<HookStore<any, BreadcrumbState, BreadcrumbHook>>(null);

export const useContextBreadcrumb = () => {
  return useFnContext(breadcrumbContext);
};

export function useStoreBreadcrumb<Selector>(selector: keyof BreadcrumbState) {
  return useStoreContext<Selector, BreadcrumbState, BreadcrumbHook>(selector, breadcrumbContext);
}
