import { createContext, useContext } from 'react';

import { GTMDataLayer } from '@core/type';
import { CountryEntity } from '@core/type/api';

export type GTMContextState = {
  enable: boolean;
  isReady: boolean;
  countries: CountryEntity[];
  baseDatalayerProps: Omit<GTMDataLayer, 'event'>;
};

export const gtmContext = createContext<GTMContextState>(null);

export const useContextGTM = () => {
  return useContext(gtmContext);
};
