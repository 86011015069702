import styled, { css, CSSObject } from 'styled-components';

import { zIndexes } from '@core/constant';
import { EActionAttribute, WithThemeProps } from '@core/type';

import { ButtonBaseProps } from './interface-button';

export const ButtonBase = styled.button<ButtonBaseProps>(
  (props: ButtonBaseProps & WithThemeProps) => {
    const {
      theme: {
        structure: {
          button: {
            base,
            disabled: { cursor },
          },
        },
      },
      as: component,
      disabled,
      autoFocus,
      text,
      href,
      target,
      type,
      name,
      form,
      role,
      tabindex,
      onClick,
      children,
      zIndex: zIndexProps,
      ...restProps
    } = props;

    const disabledRules = {
      cursor,
      pointerEvents: component === EActionAttribute.LINK ? 'none' : 'auto',
    } as CSSObject;

    const zIndex = zIndexProps && zIndexes[zIndexProps];

    return css`
      ${base as CSSObject};
      ${disabled && disabledRules};
      ${{ ...restProps, zIndex } as CSSObject};
    `;
  },
);
