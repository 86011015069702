import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconChat = ({ stroke }: DynamicIconProps) => (
  <SVG height={24} width={24} viewBox="0 0 24 24" title="Chat icon">
    <path
      d="M17.5516 5.00678L5.56856 6.10412C5.34374 6.12472 5.125 6.19148 4.92483 6.30058C4.72466 6.40968 4.54697 6.55899 4.40192 6.73999C4.25688 6.92099 4.14731 7.13012 4.07947 7.35545C4.01163 7.58078 3.98686 7.81789 4.00656 8.05325L4.58651 14.9971L4.60284 15.132C4.67292 15.5797 4.90203 15.9832 5.24427 16.2616C5.58652 16.54 6.01661 16.6727 6.44837 16.6332L9.34211 16.3678L12.0511 18.7847L12.1379 18.853C12.3148 18.9743 12.5283 19.023 12.7374 18.9898C12.9465 18.9566 13.1365 18.8439 13.2712 18.6731L15.5368 15.8003L18.4314 15.5358C18.6563 15.5152 18.875 15.4485 19.0752 15.3394C19.2753 15.2303 19.453 15.081 19.5981 14.9C19.7431 14.719 19.8527 14.5098 19.9205 14.2845C19.9884 14.0592 20.0131 13.8221 19.9934 13.5867L19.4126 6.6429C19.3931 6.40754 19.3294 6.17853 19.2253 5.96894C19.1212 5.75934 18.9786 5.57327 18.8058 5.42134C18.633 5.26941 18.4333 5.1546 18.2181 5.08348C18.0029 5.01235 17.7764 4.98629 17.5516 5.00678Z"
      fill={'none'}
      stroke={stroke}
      strokeWidth="1.2"
    />
  </SVG>
);
