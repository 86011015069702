import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type { PatchProductAlertEntity, ProductAlertResponse } from '@core/type/api';
import { PostProductAlertEntity, PostProductAlertResponse } from '@core/type/api';

import { Api } from '../constants/api';
import { replaceIdUrl } from '../helpers/api';
import { configResponse, EConfigName } from '../helpers/config';

const getAlerts = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<ProductAlertResponse>> => {
  return axiosInstance.get(
    Api.productAlert.PRODUCT_ALERTS,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const addAlert = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & PostProductAlertEntity,
): Promise<AxiosResponse<PostProductAlertResponse>> => {
  return axiosInstance.post(
    Api.productAlert.PRODUCT_ALERTS,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const updateAlert = async (
  axiosInstance: AxiosInstance,
  { language, alertId, ...data }: LanguageProps & PatchProductAlertEntity & { alertId: number },
): Promise<AxiosResponse<void>> => {
  return axiosInstance.patch(
    replaceIdUrl(Api.productAlert.UPDATE_PRODUCT_ALERT, alertId, ':alertId'),
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const deleteAlert = async (
  axiosInstance: AxiosInstance,
  { language, alertId }: LanguageProps & { alertId: number },
): Promise<AxiosResponse<void>> => {
  return axiosInstance.delete(
    replaceIdUrl(Api.productAlert.DELETE_PRODUCT_ALERT, alertId, ':alertId'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class ProductAlertsAPI {
  public static readonly getAlerts = getAlerts;
  public static readonly addAlert = addAlert;
  public static readonly updateAlert = updateAlert;
  public static readonly deleteAlert = deleteAlert;
}
