import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type { MostPurchasedProductsResponseEntity } from '@core/type/api';

import { Api } from '../constants/api';
import { configResponse, EConfigName } from '../helpers/config';

const getMostPurchasedProducts = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<MostPurchasedProductsResponseEntity>> => {
  return axiosInstance.get(
    Api.mostPurchasedProducts.MOST_PURCHASED_PRODUCTS,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getMostPurchasedProductsVisitor = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<MostPurchasedProductsResponseEntity>> => {
  return axiosInstance.get(
    Api.mostPurchasedProducts.MOST_PURCHASED_PRODUCTS_VISITOR,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class MostPurchasedProductsAPI {
  public static readonly getMostPurchasedProducts = getMostPurchasedProducts;
  public static readonly getMostPurchasedProductsVisitor = getMostPurchasedProductsVisitor;
}
