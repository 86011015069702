import Script from 'next/script';

export const GRNextScript = ({
  nonce,
  recaptchaKey,
}: {
  nonce: string;
  recaptchaKey: string;
}): JSX.Element => {
  return nonce ? (
    <Script
      strategy="afterInteractive"
      defer
      src={`https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`}
      nonce={nonce}
    />
  ) : null;
};
