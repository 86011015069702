import type { ReactNode } from 'react';

import { redirectAfterSignInRouteContext } from '@core/context';
import { RedirectAfterSignInRouteHook, RedirectAfterSignInRouteState } from '@core/type/context';

import { storeHook } from '../helpers/store';
import { useRedirectAfterSignInRoute } from '../hook';

export const ProviderRedirectAfterSignInRoute = ({ children }: { children?: ReactNode }) => {
  const hooks = storeHook<RedirectAfterSignInRouteState, RedirectAfterSignInRouteHook>(
    { route: null, query: null },
    useRedirectAfterSignInRoute,
  );
  return (
    <redirectAfterSignInRouteContext.Provider value={hooks}>
      {children}
    </redirectAfterSignInRouteContext.Provider>
  );
};
