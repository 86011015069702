/* eslint-disable @typescript-eslint/no-magic-numbers */
import { useContextMediaQuery } from '@core/context';
import { ETypographyVariant } from '@core/type';

import { Grid } from '../grid';
import { Box } from '../layout';
import { SkeletonTypography } from './SkeletonTypography';
import { SkeletonWrapper } from './SkeletonWrapper';

export const SkeletonTutorialVideos = () => {
  const { isMobile, isPhone } = useContextMediaQuery();

  return (
    <SkeletonWrapper paddingTop={'48px'}>
      <SkeletonTypography variant={ETypographyVariant.H2} width={'220px'} marginBottom={'16px'} />
      <SkeletonTypography lineHeight={'48px'} maxWidth={'750px'} marginBottom={'64px'} />

      <Grid isContainer columnSpacing={30} rowSpacing={isMobile ? 48 : 64}>
        {[...Array(isPhone ? 2 : isMobile ? 4 : 6).keys()].map((index: number) => (
          <Grid key={index} sm={6} lg={4}>
            <Box>
              <SkeletonTypography lineHeight={'208px'} marginBottom={'32px'} />
              <SkeletonTypography lineHeight={'64px'} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </SkeletonWrapper>
  );
};
