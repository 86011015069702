import { Box, IconCountry, Typography } from '@core/component';
import { EActionAttribute, EColor, EFontWeight, LanguageEntity } from '@core/type';
import { getCountryFromLanguage, getLanguageFromTags } from '@core/util';

export const LanguageLabel = ({ name, iso }: LanguageEntity) => (
  <Box display={'flex'} alignItems={'center'} whiteSpace={'nowrap'} color={EColor.PRIMARY}>
    <Box marginRight={'8px'} lineHeight={0} minWidth={20}>
      <IconCountry
        width={20}
        height={20}
        country={getCountryFromLanguage(getLanguageFromTags(iso))}
      />
    </Box>
    <Typography as={EActionAttribute.SPAN} fontWeight={EFontWeight.BOLD} marginRight={'4px'}>
      {getLanguageFromTags(iso).toUpperCase()}
    </Typography>
    – {name}
  </Box>
);
