import { default as NextImage, ImageProps as NextImageProps } from 'next/image';
import { FC } from 'react';

import { ImageProps } from './interface-image';

export const Image: FC<ImageProps> = (props) => {
  return (
    <NextImage
      placeholder={props.blurDataURL ? 'blur' : 'empty'}
      sizes={props.sizes || (props.width && `${props.width}px`) || '100vw'}
      fill={!props.width}
      style={{
        objectFit: 'contain',
        objectPosition: 'initial',
      }}
      {...(props as NextImageProps)}
    />
  );
};
