import { staticFilePath } from '@core/constant';
import { ECountry } from '@core/type';

import { getBlurData64 } from './blur-data64';
import { EFilenameBlur } from './filename-blur';

export const getStaticPathAsset = (filename?: string, cdn?: string): string => {
  return `${cdn || ''}/${staticFilePath.ASSETS}/${filename}`;
};

export const getStaticPathPng = (filename?: string, cdn?: string): string => {
  return `${cdn || ''}/${staticFilePath.PNG}/${filename}`;
};

export const getStaticPathFont = (filename?: string, cdn?: string): string => {
  return `${cdn || ''}/${staticFilePath.FONTS}/${filename}`;
};

export const getStaticPathScripts = (filename?: string, cdn?: string): string => {
  return `${cdn || ''}/${staticFilePath.SCRIPTS}/${filename}`;
};

export const getStaticPathSitemap = (filename?: string, cdn?: string): string => {
  return `${cdn || ''}/${staticFilePath.SITEMAP}/${filename}`;
};

export const getStaticPathIconFolder = (filename?: string, cdn?: string): string => {
  const iconFolder = cdn ? '' : `${staticFilePath.ICON_FOLDER}/`;
  return `${cdn || ''}/${iconFolder}${filename}`;
};

export const getStaticPathFlagFromCountry = (
  country: ECountry,
  cdn?: string,
  suffix = 'svg',
): string => {
  return getStaticPathIconFolder(`${staticFilePath.FLAG}/${country.toLowerCase()}.${suffix}`, cdn);
};

export const getStaticPathImage = (filename?: string, cdn?: string): string => {
  return `${cdn || ''}/${staticFilePath.IMAGE_FOLDER}/${filename}`;
};

export const getStaticPathImageBlurData = (filename?: EFilenameBlur): string => {
  return getBlurData64(filename);
};

export const getMediaFolder = (folderApp: string, folder?: string): string => {
  return folder ? `${folderApp}${folder}` : folderApp;
};

export const getMediaIconFolder = (folderApp: string, folder?: string): string => {
  const pathFolder = folder ? `/${folder}` : '';
  return getMediaFolder(folderApp, `${staticFilePath.ICON_FOLDER}${pathFolder}`);
};
