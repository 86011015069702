import type { AxiosInstance, RawAxiosRequestHeaders } from 'axios';
import axios from 'axios';
import { Translate as NextTranslate } from 'next-translate';

import { AuthStorage, CurrencyStorage } from '@core/storage';
import {
  ECurrency,
  EDevice,
  ELanguage,
  ELanguageTags,
  ERouting,
  EStorageType,
  EUserStatus,
  ErrorHandled,
  ResponseHandled,
} from '@core/type';
import { ToastObject } from '@core/type/context';

import { DEFAULT_CURRENCY, getIsoCurrencyFromCookie } from './currency';
import {
  convertErrorAPI,
  displayToast,
  getTitleToast,
  getTokenRefreshPromise,
  manageError,
} from './error';
import {
  getUrl,
  setRequestErrorConfig,
  setRequestSuccessConfig,
  setResponseErrorConfig,
  setResponseSuccessConfig,
} from './http-server';
import { getLanguageFromTags } from './language';

export const getHttpClientConfig = ({
  language,
  currency,
  csrfToken,
  apiHost,
  apiBase,
  apiVersion,
  deviceType,
  isProductionServer,
  ...rest
}: {
  currency?: ECurrency;
  apiHost?: string;
  apiBase?: string;
  apiVersion?: string;
  keyRoute: ERouting;
  deviceType?: EDevice;
  isProductionServer?: boolean;
  language: ELanguageTags;
  csrfToken: string;
  addToast: (props: Omit<ToastObject, 'id'>) => string;
  setStatus: (status: EUserStatus) => void;
  setRoute: (key: ERouting, queries?: NodeJS.Dict<string | string[]>) => void;
  setIsDisconnecting: (isDisconnecting?: boolean) => void;
  isDisconnecting?: boolean;
  tCommon?: NextTranslate;
}): AxiosInstance => {
  let headers: RawAxiosRequestHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json, multipart/form-data',
    'Accept-Language':
      language !== ELanguageTags.DEFAULT ? getLanguageFromTags(language) : ELanguage.EN,
    'Client-Type': deviceType || EDevice.WEB,
  };

  if (currency) {
    headers = {
      ...headers,
      IsoCurrency: currency,
    };
  }

  if (csrfToken) {
    headers = {
      ...headers,
      'X-CSRF-TOKEN': csrfToken,
    };
  }

  const axiosInstance = axios.create({
    baseURL: getUrl(apiHost, apiBase, apiVersion),
    maxRedirects: 0,
    headers,
  });

  return httpClient({ axiosInstance, language, isProductionServer, ...rest });
};

export const httpClient = ({
  axiosInstance,
  language,
  keyRoute,
  isProductionServer,
  addToast,
  setStatus,
  setRoute,
  setIsDisconnecting,
  isDisconnecting,
  tCommon,
}: {
  axiosInstance: AxiosInstance;
  language: ELanguageTags;
  keyRoute: ERouting;
  isProductionServer?: boolean;
  addToast: (props: Omit<ToastObject, 'id'>) => string;
  setStatus: (status: EUserStatus) => void;
  setRoute: (key: ERouting, queries?: NodeJS.Dict<string | string[]>) => void;
  setIsDisconnecting: (isDisconnecting?: boolean) => void;
  isDisconnecting?: boolean;
  tCommon?: NextTranslate;
}): AxiosInstance => {
  axiosInstance.interceptors.response.use(
    (response) => {
      const res = setResponseSuccessConfig(response);
      const result = convertErrorAPI(res.data as ResponseHandled);

      if (result?.isToast) {
        displayToast(
          result,
          addToast,
          (res.data as ResponseHandled).title || getTitleToast(result?.status, tCommon),
          tCommon,
        );
      }

      return res;
    },
    async (err: unknown) => {
      const error = setResponseErrorConfig(err, isProductionServer);

      const managedError = await manageError({
        setIsDisconnecting,
        isDisconnecting,
        error: error as unknown as ErrorHandled,
        keyRoute,
        addToast,
        setStatus,
        setRoute,
        tCommon,
        axiosInstance,
        language,
      });

      if (managedError === error) {
        return Promise.reject(error);
      } else {
        return Promise.resolve(managedError);
      }
    },
  );
  axiosInstance.interceptors.request.use(async (config) => {
    const tokenRefreshPromise = getTokenRefreshPromise();
    if (config.url !== '/session' && tokenRefreshPromise !== undefined) {
      await tokenRefreshPromise;
    }

    config.headers['IsoCurrency'] = getIsoCurrencyFromCookie(
      CurrencyStorage.get(EStorageType.COOKIE) || DEFAULT_CURRENCY,
    );
    if (
      AuthStorage.getTokenStore(EStorageType.COOKIE) ||
      AuthStorage.getRefreshTokenStore(EStorageType.COOKIE)
    ) {
      config.headers['Authorization'] = `Bearer ${AuthStorage.getTokenStore(EStorageType.COOKIE)}`;
    }
    return setRequestSuccessConfig(config);
  }, setRequestErrorConfig);

  return axiosInstance;
};
