import builder from 'content-security-policy-builder';

import { ONFIDO_ASSETS_URL, ONFIDO_SDK_URL } from '@core/constant';

import { EnvironmentPublicNext } from '../../helpers/environment';
import { getConnectSrc } from './connect-src';
import { getFontSrc } from './font-src';
import { getImgSrc } from './img-src';
import { getScriptSrc } from './script-src';
import { getStyleSrc } from './style-src';

// Header Content-Security-Policy
// It need .env
export const getContentSecurityPolicy = (
  nonce: string,
  publicEnvironment: Partial<EnvironmentPublicNext>,
): string => {
  const { host, env, cdnCsp, enableCdn, isDevelopmentServer } = publicEnvironment;

  let hostAllowed = `${host}`;
  if (env === 'preview') {
    hostAllowed = `https://${process.env.NEXT_PUBLIC_VERCEL_URL} https://${process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL}`;
  }

  const connectSrc = getConnectSrc(publicEnvironment);
  const imgSrc = getImgSrc(nonce, publicEnvironment);
  const fontSrc = getFontSrc(publicEnvironment);
  const styleSrc = getStyleSrc(publicEnvironment);
  const scriptSrc = getScriptSrc(publicEnvironment);

  const contentSecurityPolicy: Record<string, string[] | string | boolean> = {
    defaultSrc: [`'self'`, ONFIDO_ASSETS_URL, ONFIDO_SDK_URL],
    manifestSrc: [`'self'`, enableCdn && cdnCsp].filter((src) => src),
    connectSrc,
    frameSrc: `*`,
    imgSrc,
    styleSrc,
    fontSrc,
    workerSrc: `'self' blob:`,
    mediaSrc: ['blob:', ONFIDO_ASSETS_URL, ONFIDO_SDK_URL],
    frameAncestors: [`'self'`, hostAllowed],
    objectSrc: [`'self'`, 'blob: ', isDevelopmentServer && 'data:'].filter((src) => src),
    scriptSrc,
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
  return builder({ directives: contentSecurityPolicy });
};
