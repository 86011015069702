import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconMarketGraph = ({ fill }: DynamicIconProps) => (
  <SVG height="32" width="32" viewBox="0 0 32 32" title="Market graph icon">
    <path
      fill={fill}
      fillRule="evenodd"
      d="M23.673 6h-3.112a1 1 0 1 1 0-2h5a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0V8.04l-4.36 5.11a1 1 0 0 1-1.146.273l-5.315-2.215-6.528 7.928a1 1 0 0 1-1.544-1.272l7-8.5a1 1 0 0 1 1.157-.287l5.327 2.22L23.673 6zM8.44 27a1 1 0 0 1-2 0v-4a1 1 0 0 1 2 0v4zm6 .011a1 1 0 1 1-2-.022l.121-11a1 1 0 1 1 2 .022l-.121 11zm6-.011a1 1 0 0 1-2 0v-8.5a1 1 0 1 1 2 0V27zm6 0a1 1 0 0 1-2 0V14.5a1 1 0 1 1 2 0V27z"
    />
  </SVG>
);
