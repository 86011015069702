import { useContextSelect } from '@core/context';

import { DropdownBase } from '../dropdown';
import { SelectBaseDropdownProps } from './interface-select';

export const SelectBaseDropdown = ({ children, ...restProps }: SelectBaseDropdownProps) => {
  const { isOpen, setIsOpen } = useContextSelect();

  return (
    <DropdownBase
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      {...restProps}
      display={!isOpen ? 'none' : restProps?.display}
    >
      {children}
    </DropdownBase>
  );
};
