import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type { OrderReq, OrderResponse, UpdateOrderEntity } from '@core/type/api';

import { Api } from '../constants/api';
import { replaceIdUrl } from '../helpers/api';
import { configResponse, EConfigName } from '../helpers/config';

const getCart = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<OrderResponse>> => {
  return axiosInstance.get(Api.cart.CART, configResponse({ [EConfigName.LANGUAGE]: language }));
};

const getHistoryOrder = async (
  axiosInstance: AxiosInstance,
  { id, language }: LanguageProps & { id: string },
): Promise<AxiosResponse<OrderResponse>> => {
  return axiosInstance.get(
    `${Api.cart.HISTORY_ORDER}/${id}`,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const postOrder = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & OrderReq,
): Promise<AxiosResponse<OrderResponse>> => {
  return axiosInstance.post(
    Api.cart.ORDER,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const virtualPostCart = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & OrderReq,
): Promise<AxiosResponse<OrderResponse>> => {
  return axiosInstance.post(
    Api.cart.VIRTUAL_CART,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const patchOrder = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & OrderReq,
): Promise<AxiosResponse<OrderResponse>> => {
  return axiosInstance.patch(
    Api.cart.ORDER,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const putOrderReview = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<OrderResponse>> => {
  return axiosInstance.put(
    Api.cart.ORDER_REVIEW,
    null,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const putOrderConfirm = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & UpdateOrderEntity,
): Promise<AxiosResponse<{ id: string }>> => {
  return axiosInstance.put(
    Api.cart.ORDER_CONFIRM,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const setCurrentCartToAbandonedCart = async (
  axiosInstance: AxiosInstance,
  { language, id }: LanguageProps & { id: string },
): Promise<AxiosResponse<OrderResponse>> => {
  return axiosInstance.patch(
    replaceIdUrl(Api.cart.SET_CART_TO_ABANDONED_CART, id),
    null,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class OrderAPI {
  public static readonly getCart = getCart;
  public static readonly getHistoryOrder = getHistoryOrder;
  public static readonly postOrder = postOrder;
  public static readonly putOrderReview = putOrderReview;
  public static readonly putOrderConfirm = putOrderConfirm;
  public static readonly patchOrder = patchOrder;
  public static readonly virtualPostCart = virtualPostCart;
  public static readonly setCurrentCartToAbandonedCart = setCurrentCartToAbandonedCart;
}
