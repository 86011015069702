import Image from 'next/image';

import IconSmsValidSvg from './svgs/icon-sms-valid.svg';

export const IconSmsValid = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconSmsValidSvg}
    alt="SMS valid icon"
    height={32}
    width={32}
  />
);
