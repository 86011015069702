import type { ReactNode } from 'react';

import { EMixpanelEnv } from '@core/type';

import { mixpanelContext } from './context-mixpanel';
import { useMixpanel } from './hooks';

export const MixpanelProvider = ({
  children,
  active,
  token,
  env,
  host,
  environment,
}: {
  active: boolean;
  children: ReactNode;
  token: string;
  env: string;
  host: string;
  environment: EMixpanelEnv;
}) => (
  <mixpanelContext.Provider value={useMixpanel(active, token, env, host, environment)}>
    {children}
  </mixpanelContext.Provider>
);
