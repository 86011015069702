import type { ReactNode } from 'react';

import { breadcrumbContext } from '@core/context';
import { ERouting } from '@core/type';
import { BreadcrumbEntity } from '@core/type/api';
import { BreadcrumbHook, BreadcrumbState } from '@core/type/context';
import { buildBreadcrumb, getTypeRoute } from '@core/util';

import { storeHook } from '../helpers';
import { useBreadcrumb } from '../hook';

export const ProviderBreadcrumb = ({
  children,
  breadcrumbs,
  keyRoute,
}: {
  breadcrumbs?: BreadcrumbEntity[];
  children?: ReactNode;
  keyRoute?: ERouting;
}) => {
  const hooks = storeHook<BreadcrumbState, BreadcrumbHook>(
    {
      breadcrumbs: buildBreadcrumb({
        keyRoute,
        breadcrumbs: getTypeRoute(keyRoute)?.breadcrumb,
        dynamicsBreadcrumbs: breadcrumbs,
      }),
    },
    useBreadcrumb,
  );
  return <breadcrumbContext.Provider value={hooks}>{children}</breadcrumbContext.Provider>;
};
