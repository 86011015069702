import { lazy, Suspense, useState } from 'react';

import { Box, IconCountry } from '@core/component';
import { useContextCurrency } from '@core/context';
import { useLanguage } from '@core/hook';
import { EIcon, EPosition, StyleRuleAlignContent } from '@core/type';
import { getCountryFromLanguage, getLanguageFromTags } from '@core/util';

import { SelectButton } from './SelectButton';
import { useSelectHandlers, useSelectOptions, useSelectTabs } from './hooks';
import { ESelectDropdown, SelectLanguageCurrencyProps } from './interface-select';

const SelectDropdownLazy = lazy(() =>
  // eslint-disable-next-line @typescript-eslint/naming-convention
  import('./SelectDropdown').then(({ SelectDropdown }) => ({ default: SelectDropdown })),
);

const getPositionProps = (position: EPosition): Record<string, StyleRuleAlignContent> => {
  switch (position) {
    case EPosition.TOP_LEFT:
    case EPosition.BOTTOM_LEFT:
      return { justifyContent: 'flex-start' };
    case EPosition.TOP_RIGHT:
    case EPosition.BOTTOM_RIGHT:
      return { justifyContent: 'flex-end' };
    default:
      return { justifyContent: 'flex-start' };
  }
};

export const SelectWithLngAndCur = ({
  withCurrency = true,
  onCurrencyChange,
  onLanguageChange,
  position,
  containerIsRelative = true,
  isDarkBackground,
  shouldShowCurrencyLink,
}: SelectLanguageCurrencyProps) => {
  const { language } = useLanguage();
  const { currency } = useContextCurrency();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedDropdown, setSelectedDropdown] = useState<ESelectDropdown>(null);

  const tabs = useSelectTabs(withCurrency);
  const { languageOptions, currencyOptions } = useSelectOptions();
  const { handleCurrencyChange, handleLanguageChange } = useSelectHandlers({
    onCurrencyChange,
    onLanguageChange,
  });

  const handleDropdownOpen = (tab: ESelectDropdown) => {
    setIsDropdownOpen(true);
    setSelectedDropdown(tab);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
    setSelectedDropdown(null);
  };

  const isLanguageSelected = selectedDropdown === ESelectDropdown.LANGUAGE;
  const isCurrencySelected = selectedDropdown === ESelectDropdown.CURRENCY;

  let dropdownProps = null;

  if (isLanguageSelected) {
    dropdownProps = {
      options: languageOptions,
      currentValue: language,
      onChange: handleLanguageChange,
    };
  } else if (isCurrencySelected) {
    dropdownProps = {
      options: currencyOptions,
      currentValue: currency,
      onChange: handleCurrencyChange,
    };
  }

  const positionProps = getPositionProps(position);

  return (
    <Box position={containerIsRelative ? 'relative' : 'initial'}>
      <Box display={'flex'} alignItems={'center'} gap={'14px'} {...positionProps}>
        <SelectButton
          image={
            <IconCountry
              height={14}
              width={14}
              country={getCountryFromLanguage(getLanguageFromTags(language))}
            />
          }
          isActive={isLanguageSelected}
          onClick={() => handleDropdownOpen(ESelectDropdown.LANGUAGE)}
          isDarkBackground={isDarkBackground}
          name={'selectLanguage'}
        >
          {getLanguageFromTags(language).toUpperCase()}
        </SelectButton>
        {withCurrency && (
          <SelectButton
            iconType={currency.toLowerCase() as EIcon}
            onClick={() => handleDropdownOpen(ESelectDropdown.CURRENCY)}
            isActive={isCurrencySelected}
            isDarkBackground={isDarkBackground}
            name={'selectCurrency'}
          >
            {currency}
          </SelectButton>
        )}
      </Box>
      {isDropdownOpen && (
        <Suspense>
          <SelectDropdownLazy
            position={position}
            onClose={handleDropdownClose}
            name={selectedDropdown}
            tabs={tabs}
            onTabChange={(tab) => setSelectedDropdown(tab)}
            {...dropdownProps}
            shouldShowCurrencyLink={shouldShowCurrencyLink}
          />
        </Suspense>
      )}
    </Box>
  );
};
