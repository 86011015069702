import Image from 'next/image';

import IconLivePriceSvg from './svgs/icon-live-price.svg';

export const IconLivePrice = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconLivePriceSvg}
    alt="live charts"
    width={24}
    height={24}
  />
);
