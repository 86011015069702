import { Box, Icon, Typography } from '@core/component';
import { useContextCurrency } from '@core/context';
import { useLanguage } from '@core/hook';
import {
  EColor,
  ECurrency,
  EFontWeight,
  EIcon,
  ELanguageTags,
  ESize,
  ETypographyVariant,
} from '@core/type';
import { formatCurrency } from '@core/util';

const getFormattedPriceChange = (
  language: ELanguageTags,
  currency: ECurrency,
  priceChange: number,
) => {
  const isPositive = priceChange >= 0;
  const formattedPriceChange = formatCurrency(language, currency, priceChange, {
    signDisplay: 'never',
  });

  if (isPositive) {
    return formattedPriceChange;
  }

  return `(${formattedPriceChange})`;
};

export const PriceMetal = ({
  title,
  priceChange,
  deskbid,
}: {
  title: string;
  priceChange: number;
  deskbid: number;
}) => {
  const { language } = useLanguage();
  const { currency } = useContextCurrency();
  const isPositive = priceChange >= 0;

  const color = isPositive ? EColor.SUCCESS : EColor.DANGER;

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      textAlign={'center'}
      gap={'5px'}
      hoverStyles={{ opacity: 0.8 }}
      transition={'opacity 0.3s'}
    >
      <Typography
        color={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
        variant={ETypographyVariant.BODY3}
      >
        {title}
      </Typography>
      <Typography
        color={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
        fontWeight={EFontWeight.MEDIUM}
        variant={ETypographyVariant.BODY3}
      >
        {formatCurrency(language, currency, deskbid)}
      </Typography>

      <Icon
        type={EIcon.TRIANGLE}
        transform={isPositive ? 'rotate(180deg)' : undefined}
        color={color}
        size={ESize.XXXS}
      />
      <Typography color={color} variant={ETypographyVariant.CAPTION1} lineHeight="14px">
        {getFormattedPriceChange(language, currency, priceChange)}
      </Typography>
    </Box>
  );
};
