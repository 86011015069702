import { createContext } from 'react';

import type { HookStore, ThemeColorHook, ThemeColorState } from '@core/type/context';

import { useFnContext, useStoreContext } from '../util';

export const themeColorContext =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createContext<HookStore<any, ThemeColorState, ThemeColorHook>>(null);

export const useContextThemeColor = () => {
  return useFnContext(themeColorContext);
};

export function useStoreThemeColor<Selector>(selector: keyof ThemeColorState) {
  return useStoreContext<Selector, ThemeColorState, ThemeColorHook>(selector, themeColorContext);
}
