import { SVG } from '../SVG';
import { DynamicIconProps } from '../interface-icon';

export const IconEUR = ({ fill }: DynamicIconProps) => (
  <SVG height={16} width={15} viewBox="0 0 15 16" title="EUR icon">
    <path
      d="M13.49 12.385a5.685 5.685 0 0 1-8.364-1.253h4.766a.899.899 0 1 0 0-1.799H4.365q-.167-.662-.168-1.343h5.695a.9.9 0 1 0 0-1.798H4.496a5.702 5.702 0 0 1 7.986-3.28.9.9 0 1 0 .816-1.6 7.494 7.494 0 0 0-10.666 4.88H.899a.9.9 0 0 0 0 1.798h1.5q.002.677.125 1.343H.899a.9.9 0 1 0 0 1.799h2.2a7.483 7.483 0 0 0 11.59 2.638.916.916 0 1 0-1.2-1.385"
      fill={fill}
    />
  </SVG>
);
