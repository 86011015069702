import { useState } from 'react';

import { toastProperties } from '@core/constant';
import { useStoreCartPopup } from '@core/context';
import { CartPopupHook, CartPopupType } from '@core/type/context';

const DELAY = 2000;

export function useCartPopup(): CartPopupHook {
  const [cartPopup, setCartPopup] = useStoreCartPopup<CartPopupType>('cartPopup');

  const [isVisible, setIsVisible] = useState(true);

  const showPopup = (props: CartPopupType) => {
    setIsVisible(true);

    setCartPopup({ timeout: toastProperties.duration, ...props });
  };

  const cleanImmediately = () => {
    setIsVisible(false);
    setCartPopup(null);
  };

  const clearAll = () => {
    setTimeout(() => {
      setCartPopup(null);
    }, DELAY);

    setIsVisible(false);
  };

  return {
    cartPopup,
    showPopup,
    clearAll,
    isVisible,
    cleanImmediately,
  };
}
