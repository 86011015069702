import {
  CRITEO_NET,
  CRITEO_URL,
  ONFIDO_ASSETS_URL,
  ONFIDO_SARDINE_URL,
  SENTRY_URL,
  TRUSTPILOT_WIDGET_URL,
  TWITTER_DOMAIN,
  TWITTER_STATIC_URL,
  axeptioUrls,
  crisp,
  externalAnalytics,
  google,
  vercel,
} from '@core/constant';

import { EnvironmentPublicNext } from '../../helpers/environment';

export const getScriptSrc = (publicEnvironment: Partial<EnvironmentPublicNext>): string[] => {
  const {
    cdnCsp,
    enableCdn,
    enableGoogleAnalytics,
    enableCrisp,
    enableTrustpilot,
    enableVercelPreviewFeedback,
    enableWebVitals,
  } = publicEnvironment;

  const axeptioStaticUrl = axeptioUrls.STATIC_URL;

  const trustpilotUrl = TRUSTPILOT_WIDGET_URL;
  const twitterUrl = TWITTER_DOMAIN;
  const twitterStaticUrl = TWITTER_STATIC_URL;
  const crispUrl = crisp.CRISP_URL;
  const gtmUrl = google.GTM_URLS;
  const gtmPreviewUrl = google.GTM_PREVIEW_URL;
  const tmPreviewUrl = google.TM_PREVIEW_URL;
  const adServicesUrl = google.AD_SERVICES_URL;
  const gStaticUrl = google.GSTATIC_URL;
  const criteoUrl = CRITEO_URL;
  const criteoNet = CRITEO_NET;
  const doubleClickUrl = google.DOUBLE_CLICK_URLS;
  const gUrl = google.G_URLS;
  const googlePlaceUrl = google.GOOGLE_PLACE_URL;
  const vercelLiveUrl = vercel.LIVE_URL;
  const vercelUrl = vercel.VERCEL_URL;
  const vercelWebVitalsUrl = vercel.WEB_VITALS_URL;
  const taboolaUrl = externalAnalytics.TABOOLA_URL;
  const hotjarUrl = externalAnalytics.HOTJAR_URL;
  const bingUrl = externalAnalytics.BING_URL;
  const hotjarStatic = externalAnalytics.HOTJAR_STATIC;
  const licdnUrl = externalAnalytics.LICDN_URL;
  const facebookUrl = externalAnalytics.FACEBOOK_URL;
  const facebookNetUrl = externalAnalytics.FACEBOOK_NET_URL;
  const clarityUrl = externalAnalytics.CLARITY_URL;
  const clarityAllUrl = externalAnalytics.CLARITY_ALL_URL;
  const gaSsrUrl = externalAnalytics.GA_SSR;

  let scriptSrc = [
    `'self'`,
    `'unsafe-inline'`,
    ONFIDO_ASSETS_URL,
    ONFIDO_SARDINE_URL,
    SENTRY_URL,
    criteoUrl,
    criteoNet,
    googlePlaceUrl,
    gStaticUrl,
    axeptioStaticUrl,
    enableWebVitals && vercelWebVitalsUrl,
    twitterUrl,
    twitterStaticUrl,
    clarityUrl,
    clarityAllUrl,
    gaSsrUrl,
  ];
  if (enableVercelPreviewFeedback) {
    scriptSrc = scriptSrc.concat([vercelLiveUrl, vercelUrl]);
  }
  scriptSrc = scriptSrc.concat([`'unsafe-eval'`]);
  if (enableGoogleAnalytics) {
    scriptSrc = scriptSrc.concat([
      gtmUrl,
      hotjarStatic,
      facebookNetUrl,
      doubleClickUrl,
      facebookUrl,
      gtmPreviewUrl,
      tmPreviewUrl,
      taboolaUrl,
      hotjarUrl,
      bingUrl,
      licdnUrl,
      adServicesUrl,
      gUrl,
    ]);
  }
  scriptSrc = scriptSrc.concat([
    enableCdn && cdnCsp,
    enableTrustpilot && trustpilotUrl,
    enableCrisp && crispUrl,
  ]);

  return scriptSrc.filter((src) => src);
};
