import { forwardRef } from 'react';
import styled, { CSSObject } from 'styled-components';

import { WithThemeProps } from '@core/type';
import { getPaletteBackgroundColor, getPaletteHandlers } from '@core/util';

import { Icon } from '../icon/Icon';
import { BoxProps } from '../layout/interface-layout';
import { ButtonBase } from './ButtonBase';
import { ButtonBaseProps, ButtonIconProps } from './interface-button';

export const ButtonIcon = forwardRef<HTMLButtonElement, ButtonIconProps>(
  ({ iconProps, ...restProps }, ref) => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <StyledIconButton ref={ref} flexShrink={'0'} {...restProps}>
      <Icon {...iconProps} />
    </StyledIconButton>
  ),
);

ButtonIcon.displayName = 'ButtonIcon';

export const StyledIconButton = styled(ButtonBase)<
  ButtonBaseProps & ButtonIconProps & Omit<BoxProps, 'onClick' | 'as'>
>(({
  theme: {
    palette,
    structure: { buttonIcon },
  },
  backgroundColor,
  borderRadius,
  hoverFill,
  borderWidth,
  borderStyle,
  borderColor,
  padding,
  hoverStyles,
}: WithThemeProps & ButtonBaseProps & ButtonIconProps & Omit<BoxProps, 'onClick' | 'as'>) => {
  const { getColor, getTextColor, getBackgroundColor } = getPaletteHandlers(palette);

  const hoverColor =
    hoverStyles &&
    getPaletteBackgroundColor({
      backgroundColor: hoverStyles.backgroundColor,
      palette,
    });

  return {
    ...buttonIcon,
    ...(borderRadius && { borderRadius }),
    ...(borderWidth && { borderWidth }),
    ...(borderStyle && { borderStyle }),
    borderColor: getColor(borderColor),
    background: backgroundColor ? getBackgroundColor(backgroundColor) || 'none' : 'none',
    padding,
    outline: 'none',
    path: {
      transition: 'all 0.3s',
    },
    '&:hover': {
      path: {
        ...(hoverFill && {
          fill: getTextColor(hoverFill),
        }),
      },
      backgroundColor: hoverColor,
    },
  } as CSSObject;
});
