import { metalIsoToFullMetalName, productTypeToFullProductType } from '@core/constant';
import { useContextCurrency, useContextProfile, useContextUser } from '@core/context';
import { useLanguage } from '@core/hook';
import {
  EGTMLoginStatus,
  ELanguage,
  EMetal,
  EMixpanelEventName,
  MixpanelEventProps,
  Price,
} from '@core/type';
import {
  CollectionsBannersImageEntity,
  InvestmentChartsResponse,
  OrderResponse,
  ProductEntity,
} from '@core/type/api';

import { useContextMixpanel } from '../context-mixpanel';
import { trackEvent } from '../helpers';
import { MixpanelProductEntity } from '../interface-mixpanel';

export const useEventMixpanel = () => {
  const { locale } = useLanguage();
  const { currency } = useContextCurrency();
  const { enable, environment, isReady } = useContextMixpanel();
  const { isLogged } = useContextUser();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { userGaPayUsed } = useContextProfile();

  const loginStatus = isLogged ? EGTMLoginStatus.LOGGED_IN : EGTMLoginStatus.NOT_LOGGED_IN;

  const baseProps: Pick<MixpanelEventProps, 'language' | 'currency' | 'environment'> = {
    language: locale === ELanguage.DEFAULT ? ELanguage.EN : locale,
    currency,
    environment,
  };

  const getProductProps = (
    props: MixpanelProductEntity,
  ): Omit<MixpanelEventProps, 'language' | 'currency' | 'environment'> => ({
    productId: props.id.toString(),
    productCategory: metalIsoToFullMetalName[props.metalIso],
    productName: props.nameEn,
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    productPrice: props.price || Math.round((props.totalPrice / props.quantity) * 100) / 100,
    productType: productTypeToFullProductType[props.productType],
    productWeight: props.weight.toString(),
    productQuantity: props.quantity,
  });

  const trackProductAddedToCartEvent = (props: MixpanelProductEntity) => {
    if (enable && props?.nameEn) {
      trackEvent(EMixpanelEventName.PRODUCT_ADDED_TO_CART, {
        ...baseProps,
        ...getProductProps(props),
        loginStatus,
        packageSelected: props.packageSelected || null,
      });
    }
  };

  const trackProductRemovedFromCartEvent = (props: MixpanelProductEntity) => {
    if (enable && props?.nameEn) {
      trackEvent(EMixpanelEventName.PRODUCT_REMOVED_FROM_CART, {
        ...baseProps,
        ...getProductProps(props),
        loginStatus,
      });
    }
  };

  const trackPageViewedEvent = () => {
    if (enable) {
      trackEvent(EMixpanelEventName.PAGE_VIEWED, { ...baseProps });
    }
  };

  const trackCartViewedEvent = (cart: OrderResponse) => {
    if (enable) {
      trackEvent(EMixpanelEventName.CART_VIEWED, {
        ...baseProps,
        cartSize: cart?.products?.length,
        cartValue: cart?.totalPrice,
        productsCategories: cart?.products?.map(
          ({ metalIso }) => metalIsoToFullMetalName[metalIso],
        ),
        productsIds: cart?.products?.map(({ id }) => id),
        productsNames: cart?.products?.map(({ nameEn }) => nameEn),
        productsPrices: cart?.products?.map(({ totalPrice }) => totalPrice),
      });
    }
  };

  const trackCarouselProductsEvent = ({ id, nameEn }: ProductEntity, productGroup: string) => {
    if (enable) {
      trackEvent(EMixpanelEventName.CAROUSEL_PRODUCTS, {
        ...baseProps,
        loginStatus,
        productId: id.toString(),
        productName: nameEn,
        productGroup,
        userGaPayUsed,
      });
    }
  };

  const trackCarouselBannersEvent = ({ id }: Partial<CollectionsBannersImageEntity>) => {
    if (enable) {
      trackEvent(EMixpanelEventName.CAROUSEL_BANNER, {
        ...baseProps,
        loginStatus,
        imageId: id,
        userGaPayUsed,
      });
    }
  };

  const trackAccountCreationDonePageEvent = (
    name: MixpanelEventProps['accountCreationDoneCtasName'],
  ) => {
    if (enable) {
      trackEvent(EMixpanelEventName.ACCOUNT_CREATION_DONE_PAGE, {
        ...baseProps,
        loginStatus,
        accountCreationDoneCtasName: name,
      });
    }
  };

  const trackAbTestEvent = (abTestKey: string, isVariant: boolean) => {
    if (enable && isReady) {
      trackEvent(EMixpanelEventName.AB_TEST, {
        ...baseProps,
        loginStatus,
        abTestKey,
        isVariant,
      });
    }
  };

  const trackInvestmentChartsEvent = ({
    eventName,
    metalIso,
    initialInvestment,
    monthlyInvestment,
  }: { eventName: EMixpanelEventName } & Partial<InvestmentChartsResponse>) => {
    if (enable) {
      trackEvent(eventName, {
        ...baseProps,
        loginStatus,
        metal: metalIsoToFullMetalName[metalIso],
        initialInvestment,
        monthlyInvestment,
      });
    }
  };

  const trackSavingAssistantEvent = ({ metalIso, amount }: { metalIso: EMetal; amount: Price }) => {
    if (enable) {
      trackEvent(EMixpanelEventName.SAVINGS_ASSISTANT_USED, {
        ...baseProps,
        loginStatus,
        assistantMetal: metalIsoToFullMetalName[metalIso],
        assistantBudget: amount,
        userGaPayUsed,
      });
    }
  };

  return {
    trackProductAddedToCartEvent,
    trackProductRemovedFromCartEvent,
    trackPageViewedEvent,
    trackCartViewedEvent,
    trackCarouselProductsEvent,
    trackCarouselBannersEvent,
    trackAccountCreationDonePageEvent,
    trackAbTestEvent,
    trackInvestmentChartsEvent,
    trackSavingAssistantEvent,
  };
};
