import Script from 'next/script';
import { ReactNode } from 'react';

import { getStaticPathScripts } from '@core/media';

export const FacebookPixelNextScript = ({
  facebookPixelId,
  nonce,
  cdn,
  children,
}: {
  facebookPixelId: string;
  cdn: string;
  nonce: string;
  children?: ReactNode;
}): JSX.Element => {
  return facebookPixelId && nonce ? (
    <Script
      id="fb-pixel"
      src={getStaticPathScripts('pixel.js', cdn)}
      strategy="afterInteractive"
      nonce={nonce}
      data-pixel-id={facebookPixelId}
    >
      {children}
    </Script>
  ) : null;
};
