import { ESizeIcon, EStrokeWidth } from '@core/type';
import { getTranslationWithOrientation } from '@core/util';

import { SVG } from './SVG';
import { DynamicIconProps, IconOrientationProps } from './interface-icon';

export const IconAngleDouble = ({
  orientation,
  yTranslation,
  ...restProps
}: IconOrientationProps) => {
  return (
    <IconAngleDoubleSVG
      transform={getTranslationWithOrientation(orientation, yTranslation)}
      {...restProps}
    />
  );
};

const IconAngleDoubleSVG = ({
  stroke,
  transform,
  size = ESizeIcon.DEFAULT,
  strokeWidth = EStrokeWidth.ICON_DEFAULT,
}: DynamicIconProps) => (
  <SVG
    height={size}
    width={size}
    viewBox="0 0 24 24"
    title="Double angel icon"
    transform={transform}
  >
    <path
      strokeLinecap="round"
      d="M5.9453 6.6707L5.94531 6.67071L11.929 12.6544L11.5755 13.0079L11.929 12.6544C11.968 12.6934 12.0314 12.6934 12.0704 12.6544L18.054 6.67071L18.0541 6.67064C18.0931 6.63161 18.0931 6.56839 18.0541 6.52935L18.054 6.52928C18.015 6.49024 17.9517 6.49024 17.9126 6.52928L17.9126 6.52929L12.3532 12.0887C12.1579 12.2839 11.8414 12.2839 11.6461 12.0887L6.08672 6.52929C6.08672 6.52929 6.08672 6.52929 6.08672 6.52929C6.04767 6.49024 5.98436 6.49024 5.94531 6.52929C5.90625 6.56835 5.90626 6.63166 5.9453 6.6707ZM5.94529 12.6707L5.94531 12.6707L11.929 18.6544C11.968 18.6934 12.0314 18.6934 12.0704 18.6544L18.054 12.6707L18.0541 12.6706C18.0931 12.6316 18.0931 12.5684 18.0541 12.5294L18.054 12.5293C18.015 12.4902 17.9516 12.4902 17.9126 12.5293L12.3532 18.0887C12.2594 18.1824 12.1323 18.2351 11.9997 18.2351C11.867 18.2351 11.7399 18.1824 11.6461 18.0887L6.08673 12.5293C6.08673 12.5293 6.08672 12.5293 6.08672 12.5293C6.04767 12.4902 5.98435 12.4902 5.9453 12.5293L5.94529 12.5293C5.90626 12.5683 5.90626 12.6316 5.94529 12.6707Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
  </SVG>
);
