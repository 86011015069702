import withTranslation from 'next-translate/withTranslation';

import { DrawerLine, Typography } from '@core/component';
import { DrawerCloseProps, EDrawerLevel } from '@core/component/interface';
import { metalPriceToSlug, Translate } from '@core/constant';
import { useContextCurrency } from '@core/context';
import { useLanguage } from '@core/hook';
import { EHeaderMenu, EMetal, ERouting, WithTranslation } from '@core/type';
import { SanityHeaderDefaultResponse } from '@core/type/api';

import { SideNavDefaultProps } from '../../../../interface-header';
import { getHeaderLinkHref } from '../../HeaderMenu/utils';
import { RecursiveDrawerLineProps } from './interface-side-nav';

const RecursiveDrawerLine = ({
  link,
  links,
  menuType,
  closeDrawer,
  drawerLevelProps,
  handleDrawerLevelProps,
  previousDrawerLevel,
  subMenus,
  iconType,
}: RecursiveDrawerLineProps) => {
  if (menuType === EHeaderMenu.FULLWIDTH_DROPDOWN) {
    return (
      <DrawerLine
        closeDrawer={closeDrawer}
        drawerLevel={`${previousDrawerLevel}/${link.text}` as EDrawerLevel}
        title={link.text}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={handleDrawerLevelProps}
      >
        {subMenus.map((menu) => (
          <RecursiveDrawerLine
            key={menu._key}
            {...menu}
            drawerLevelProps={drawerLevelProps}
            previousDrawerLevel={`${previousDrawerLevel}/${link.text}`}
            handleDrawerLevelProps={handleDrawerLevelProps}
            closeDrawer={closeDrawer}
          />
        ))}
      </DrawerLine>
    );
  }

  if (links) {
    return (
      <DrawerLine
        closeDrawer={closeDrawer}
        drawerLevel={`${previousDrawerLevel}/${link.text}` as EDrawerLevel}
        href={link.href}
        title={link.text}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={handleDrawerLevelProps}
      >
        {links.map((link) => (
          <DrawerLine
            key={link.id}
            closeDrawer={closeDrawer}
            drawerLevel={`${previousDrawerLevel}/${link.text}` as EDrawerLevel}
            href={getHeaderLinkHref(link)}
            title={<Typography>{link.text}</Typography>}
            drawerLevelProps={drawerLevelProps}
            handleDrawerLevelProps={closeDrawer}
            iconType={iconType}
          />
        ))}
      </DrawerLine>
    );
  }

  return (
    <DrawerLine
      closeDrawer={closeDrawer}
      drawerLevel={`${previousDrawerLevel}/${link.text}` as EDrawerLevel}
      href={getHeaderLinkHref(link)}
      title={link.text}
      drawerLevelProps={drawerLevelProps}
      handleDrawerLevelProps={closeDrawer}
      iconType={iconType}
    />
  );
};

const LiveChartsMenuBase = ({
  i18n: { t },
  closeDrawer,
  drawerLevelProps,
  handleDrawerLevelProps,
}: WithTranslation & DrawerCloseProps) => {
  const { currency } = useContextCurrency();
  const { language } = useLanguage();

  return (
    <DrawerLine
      closeDrawer={closeDrawer}
      drawerLevel={EDrawerLevel.CHARTS}
      drawerLevelProps={drawerLevelProps}
      handleDrawerLevelProps={handleDrawerLevelProps}
      title={t('menu.liveCharts')}
    >
      <DrawerLine
        closeDrawer={closeDrawer}
        title={`${t('menu.gold')} ${t('menu.charts')}`}
        drawerLevelProps={drawerLevelProps}
        route={ERouting.CHARTS}
        query={{
          metalSlug: metalPriceToSlug[language][EMetal.GOLD],
          slug: [currency.toLowerCase()],
        }}
        handleDrawerLevelProps={closeDrawer}
      />
      <DrawerLine
        closeDrawer={closeDrawer}
        title={`${t('menu.silver')} ${t('menu.charts')}`}
        route={ERouting.CHARTS}
        query={{
          metalSlug: metalPriceToSlug[language][EMetal.SILVER],
          slug: [currency.toLowerCase()],
        }}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={closeDrawer}
      />
      <DrawerLine
        closeDrawer={closeDrawer}
        title={`${t('menu.platinium')} ${t('menu.charts')}`}
        drawerLevelProps={drawerLevelProps}
        route={ERouting.CHARTS}
        query={{
          metalSlug: metalPriceToSlug[language][EMetal.PLATINUM],
          slug: [currency.toLowerCase()],
        }}
        handleDrawerLevelProps={closeDrawer}
      />
      <DrawerLine
        closeDrawer={closeDrawer}
        title={`${t('menu.palladium')} ${t('menu.charts')}`}
        drawerLevelProps={drawerLevelProps}
        route={ERouting.CHARTS}
        query={{
          metalSlug: metalPriceToSlug[language][EMetal.PALLADIUM],
          slug: [currency.toLowerCase()],
        }}
        handleDrawerLevelProps={closeDrawer}
      />
    </DrawerLine>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const LiveChartsMenu = withTranslation(LiveChartsMenuBase, Translate.layout.HEADER);

const MenusBase = ({
  closeDrawer,
  drawerLevelProps,
  handleDrawerLevelProps,
  menus,
}: WithTranslation & SideNavDefaultProps & SanityHeaderDefaultResponse) => {
  return (
    <>
      {menus?.map((menu) => (
        <RecursiveDrawerLine
          key={menu._key}
          {...menu}
          previousDrawerLevel=""
          drawerLevelProps={drawerLevelProps}
          closeDrawer={closeDrawer}
          handleDrawerLevelProps={handleDrawerLevelProps}
        />
      ))}
      <LiveChartsMenu
        closeDrawer={closeDrawer}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={handleDrawerLevelProps}
      />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const Menus = withTranslation(MenusBase, Translate.layout.HEADER);
