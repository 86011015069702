import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type { CollectionsBannersResponse, CollectionsProductGroupsEntity } from '@core/type/api';

import { Api } from '../constants/api';
import { replaceIdUrl } from '../helpers/api';
import { configResponse, EConfigName } from '../helpers/config';

const getProductGroups = async (
  axiosInstance: AxiosInstance,
  { slug, language }: LanguageProps & { slug: string },
): Promise<AxiosResponse<CollectionsProductGroupsEntity>> =>
  axiosInstance.get(
    replaceIdUrl(Api.collections.PRODUCT_GROUPS, slug, ':slug'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const getBanners = async (
  axiosInstance: AxiosInstance,
  { slug, language }: LanguageProps & { slug: string },
): Promise<AxiosResponse<CollectionsBannersResponse>> =>
  axiosInstance.get(
    replaceIdUrl(Api.collections.BANNERS, slug, ':slug'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const getBlogArticles = async (
  axiosInstance: AxiosInstance,
  { slug, language }: LanguageProps & { slug: string },
): Promise<AxiosResponse<CollectionsBannersResponse>> =>
  axiosInstance.get(
    replaceIdUrl(Api.collections.ARTICLES, slug, ':slug'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

export class CollectionsAPI {
  public static readonly getProductGroups = getProductGroups;
  public static readonly getBanners = getBanners;
  public static readonly getBlogArticles = getBlogArticles;
}
