import { keyframes } from 'styled-components';

import { animation } from '@core/constant';

export const spinAnimation = keyframes`
 0% { transform: rotate(0deg); }
 100% { transform: rotate(360deg); }
`;

export const getSlideInAnimation = (shift: string, maxHeight?: string) => keyframes`
  0% {
    transform: translateX(${shift});
    max-height:${maxHeight && 0};
  }
  100% {
    transform: translateX(0);
    max-height: ${maxHeight};
  }
`;

export const getSlideOutAnimation = (shift: string, maxHeight?: string) => keyframes`
  0% {
    transform: translateX(0);
    max-height: ${maxHeight};
  }
  100% {
    transform: translateX(${shift});
    max-height: ${maxHeight && 0};
  }
`;

export const fadeInAnimation = keyframes`
   0% { opacity: 0;}
   100% { opacity: 1; }
`;

export const fadeOutAnimation = keyframes`
   0% { opacity: 1; }
   100% { opacity: 0; }
`;

export const getSlideUpAnimation = (shift: string) => keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-${shift});
  }
`;

export const getSlideDownAnimation = (shift: string) => keyframes`
  0% {
    transform: translateY(-${shift});
  }
  100% {
    transform: translateY(0);
  }
`;

export const getSlideAnimation = (from: string, to: string) => keyframes`
  0% {
    transform: translateY(-${from});
  }
  100% {
    transform: translateY(-${to});
  }
`;

export const getBarAnimation = ({
  totalSteps,
  currentStep,
  previousStep,
}: {
  totalSteps: number;
  currentStep: number;
  previousStep: number;
}) => {
  if (currentStep < previousStep) {
    return keyframes`

        from {
            width: ${`calc(${(animation.BAR_COEF * previousStep) / totalSteps}% - 8px)`};
        }
        to {
            width: ${`calc(${(animation.BAR_COEF * currentStep) / totalSteps}% + 8px)`};
        }
    `;
  }

  return keyframes`
    from {
        width: ${`calc(${(animation.BAR_COEF * previousStep) / totalSteps}% + 8px)`};
    }
    to {
        width: ${`calc(${(animation.BAR_COEF * currentStep) / totalSteps}% - 8px)`};
    }
    `;
};

export const getWholeBarAnimation = ({
  totalSteps,
  currentStep,
}: {
  totalSteps: number;
  currentStep: number;
}) => keyframes`
    from {
        width: 0;
    }
    to {
        width: ${`calc(${(animation.BAR_COEF * currentStep) / totalSteps}% - 8px)`};
    }
`;

export const pulsate = keyframes`
    0% {
        transform: scale(0.5);
        opacity: 0
    }

    25% {
        opacity: 1
    }

    50% {
        transform: scale(1.2);
        opacity: 0
    }

    to {
        transform: scale(1.2);
        opacity: 0
    }
`;

export const pulseShadow = ({ color }: { color: string }) => keyframes`
  0% {
    box-shadow: 0 0 0 0 ${color}66;
  }

  70% {
    box-shadow: 0 0 0 14px ${color}00;
  }

  100% {
    box-shadow: 0 0 0 0 ${color}00;
  }
`;

export const modalOpeningAnimation = () => keyframes`
  from {
    transform: translateY(-25%);
  }

  to {
    transform: translate(0);
  }
`;

export const modalClosingAnimation = () => keyframes`
  from {
    transform: translate(0);
  }

  to {
    transform: translateY(-25%);
  }
`;

export const copiedExpandingAnimation = keyframes`
    from {
      top: -10px;
      transform: translateX(-50%) translateY(-10px);
      left: 50%;
    }
    to {
      top: -35px;
    }
`;

export const copiedCollapsingAnimation = keyframes`
    from {
      top: -35px;
      height: initial;
    }
    to {
      top: -10px;
      transform: translateX(-50%) translateY(-10px);
      left: 50%;
      height: 0px;
    }
`;

export const spotlightBannerCollapsingAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;
