import useTranslation from 'next-translate/useTranslation';

import { Box, IconCurrency, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { EActionAttribute, ECurrency, EFontWeight } from '@core/type';

export const CurrencyLabel = ({ currency }: { currency: ECurrency }) => {
  const { t } = useTranslation(Translate.common.CURRENCY);

  return (
    <Box display={'flex'} alignItems={'center'} whiteSpace={'nowrap'}>
      <IconCurrency currency={currency} width={20} height={20} marginRight={'8px'} />
      <Typography as={EActionAttribute.SPAN} fontWeight={EFontWeight.BOLD} marginRight={'4px'}>
        {currency.toUpperCase()}
      </Typography>
      <Typography
        as={EActionAttribute.SPAN}
        textOverflow={'ellipsis'}
        width={'120px'}
        overflow={'hidden'}
      >
        – {t(currency)}
      </Typography>
    </Box>
  );
};
