import { EActionAttribute, EColor, ETypographyVariant } from '@core/type';

import { Box } from '../layout/Box';
import { Typography } from '../typography/Typography';
import { FieldErrorProps } from './interface-input';

export const FieldError = ({
  isError: defaultIsError,
  fieldState,
  isSubmitted,
  permanentWarning,
  message,
}: FieldErrorProps) => {
  const isError = fieldState
    ? !!fieldState.error && (fieldState.isTouched || isSubmitted)
    : defaultIsError;

  return (
    <>
      {fieldState && isError ? (
        <Box
          margin={'0px auto'}
          paddingBottom={0}
          paddingTop={4}
          width={'100%'}
          flexDirection={'column'}
          alignItems={'flex-start'}
          display={'flex'}
          role="alert"
        >
          <Typography
            as={EActionAttribute.SPAN}
            color={EColor.DANGER}
            variant={ETypographyVariant.CAPTION1}
            role="alert"
          >
            {message || fieldState.error.message}
          </Typography>
        </Box>
      ) : (
        <>
          {permanentWarning && (
            <Box
              margin={'0px auto'}
              paddingBottom={0}
              paddingTop={4}
              width={'100%'}
              flexDirection={'column'}
              alignItems={'flex-start'}
              display={'flex'}
              role="alert"
            >
              <Typography
                as={EActionAttribute.SPAN}
                color={EColor.PRIMARY}
                variant={ETypographyVariant.CAPTION1}
                role="alert"
              >
                {permanentWarning}
              </Typography>
            </Box>
          )}
        </>
      )}
    </>
  );
};

FieldError.displayName = 'FieldError';
