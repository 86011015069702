import {
  ONFIDO_ASSETS_URL,
  ONFIDO_SDK_URL,
  SANITY_CDN,
  TRUSTPILOT_WIDGET_URL,
  TWITTER_ANALYTICS_URL,
  TWITTER_CO_URL,
  axeptioUrls,
  crisp,
  externalAnalytics,
  google,
  googleDomains,
  vercel,
} from '@core/constant';

import { EnvironmentPublicNext } from '../../helpers/environment';

export const getImgSrc = (
  nonce: string,
  {
    cdnApi,
    cdnCsp,
    enableGoogleAnalytics,
    enableCrisp,
    enableTrustpilot,
    enableSanityCdn,
  }: Partial<EnvironmentPublicNext>,
): string[] => {
  const trustpilotUrl = TRUSTPILOT_WIDGET_URL;
  const twitterCoUrl = TWITTER_CO_URL;
  const twitterAnalyticsUrl = TWITTER_ANALYTICS_URL;
  const crispUrl = crisp.CRISP_URL;
  const crispStream = crisp.CRISP_STREAM;
  const crispImg = crisp.CRISP_IMG;
  const crispFiles = crisp.CRISP_FILES;
  const gtmUrl = google.GTM_URLS;
  const gaUrl = google.GA_URLS;
  const agUrl = google.AG_URLS;
  const adServicesUrl = google.AD_SERVICES_URL;
  const gtmPreviewUrl = google.GTM_PREVIEW_URL;
  const gStaticSslUrl = google.GSTATIC_SSL_URL;
  const gStaticUrl = google.GSTATIC_URL;
  const doubleClickUrl = google.DOUBLE_CLICK_URLS;
  const gUrl = google.G_URLS;
  const googleImageUrl = google.GOOGLE_IMAGE_URL;
  const taboolaUrl = externalAnalytics.TABOOLA_URL;
  const bingUrl = externalAnalytics.BING_URL;
  const linkedinUrl = externalAnalytics.LINKEDIN_URL;
  const facebookUrl = externalAnalytics.FACEBOOK_URL;
  const vercelUrl = vercel.VERCEL_URL;
  const faviconsAxeptioUrl = axeptioUrls.FAVICONS_URL;

  let imgSrc = [
    `'self'`,
    'data: blob:',
    enableSanityCdn && SANITY_CDN,
    cdnApi,
    twitterCoUrl,
    twitterAnalyticsUrl,
    axeptioUrls.IMAGE_URL,
    googleImageUrl,
    cdnCsp,
    vercelUrl,
  ];
  if (enableGoogleAnalytics) {
    imgSrc = imgSrc.concat([
      gaUrl,
      facebookUrl,
      gtmUrl,
      taboolaUrl,
      agUrl,
      gUrl,
      googleDomains.join(' '),
      doubleClickUrl,
      gtmPreviewUrl,
      gStaticSslUrl,
      gStaticUrl,
      linkedinUrl,
      bingUrl,
      adServicesUrl,
    ]);
  }
  if (enableCrisp) {
    imgSrc = imgSrc.concat([crispImg, crispStream, crispFiles, crispUrl]);
  }
  if (enableTrustpilot) {
    imgSrc = imgSrc.concat([trustpilotUrl]);
  }
  imgSrc = imgSrc.concat([
    ONFIDO_ASSETS_URL,
    ONFIDO_SDK_URL,
    `'nonce-${nonce}'`,
    faviconsAxeptioUrl,
  ]);

  return imgSrc.filter((src) => src);
};
