import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconInfo = ({ fill }: DynamicIconProps) => {
  return (
    // Do not set the title attr to the icon to avoid a default tooltip on hover.
    <SVG height={12} width={12} viewBox="0 0 12 12" aria-label={'Info icon'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 6C11.25 8.8995 8.8995 11.25 6 11.25C3.10051 11.25 0.75 8.8995 0.75 6C0.75 3.10051 3.10051 0.75 6 0.75C8.8995 0.75 11.25 3.10051 11.25 6ZM12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM5.25 3C5.25 2.58579 5.58579 2.25 6 2.25C6.41421 2.25 6.75 2.58579 6.75 3C6.75 3.41421 6.41421 3.75 6 3.75C5.58579 3.75 5.25 3.41421 5.25 3ZM5.25 5.25C5.25 4.83579 5.58579 4.5 6 4.5C6.41421 4.5 6.75 4.83579 6.75 5.25V9C6.75 9.41421 6.41421 9.75 6 9.75C5.58579 9.75 5.25 9.41421 5.25 9V5.25Z"
        fill={fill}
      />
    </SVG>
  );
};
