import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconCirclePlus = ({ fill }: DynamicIconProps) => {
  return (
    <SVG height={16} width={16} viewBox="0 0 12 12" title="Circle plus icon">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8 6C10.8 8.65097 8.65097 10.8 6 10.8C3.34903 10.8 1.2 8.65097 1.2 6C1.2 3.34903 3.34903 1.2 6 1.2C8.65097 1.2 10.8 3.34903 10.8 6ZM12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM5.39995 3.6C5.39995 3.26863 5.66858 3 5.99995 3C6.33132 3 6.59995 3.26863 6.59995 3.6V5.4H8.4C8.73137 5.4 9 5.66863 9 6C9 6.33137 8.73137 6.6 8.4 6.6H6.59995V8.4C6.59995 8.73137 6.33132 9 5.99995 9C5.66858 9 5.39995 8.73137 5.39995 8.4V6.6H3.6C3.26863 6.6 3 6.33137 3 6C3 5.66863 3.26863 5.4 3.6 5.4H5.39995V3.6Z"
        fill={fill}
      ></path>
    </SVG>
  );
};
