import { FC } from 'react';
import styled, { CSSObject } from 'styled-components';

import { WithRef, WithThemeProps } from '@core/type';

import { Box } from './Box';
import { ContainerProps } from './interface-layout';

export const Container: FC<ContainerProps & Partial<WithRef<HTMLDivElement>>> = styled(Box)(
  ({
    theme: {
      structure: {
        container: {
          width: themeWidth,
          gutter: themeGutter,
          margin: themeMargin,
          maxWidth: themeMaxWidth,
        },
      },
    },
    isFullWidth,
    width = themeWidth,
    margin = themeMargin,
    gutter = themeGutter,
    paddingLeft = isFullWidth ? null : gutter,
    paddingRight = isFullWidth ? null : gutter,
    maxWidth = isFullWidth ? '100%' : themeMaxWidth,
    animation,
    onClick,
    children,
    as,
    ...restProps
  }: ContainerProps & WithThemeProps) =>
    ({
      width,
      margin,
      maxWidth,
      paddingLeft,
      paddingRight,
      ...restProps,
    }) as CSSObject,
);
