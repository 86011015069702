/* eslint-disable @typescript-eslint/no-magic-numbers */
import { useContextMediaQuery } from '@core/context';
import { ESize, ETypographyVariant } from '@core/type';

import { Grid } from '../grid';
import { SkeletonTypography } from './SkeletonTypography';
import { SkeletonWrapper } from './SkeletonWrapper';

export const SkeletonPmg = () => {
  const { mediaQuery, isDesktop } = useContextMediaQuery();

  const isSmallDesktop: boolean = mediaQuery === ESize.LG;

  return (
    <SkeletonWrapper paddingTop={'24px'}>
      <Grid isContainer columns={13} columnSpacing={isSmallDesktop ? 80 : 120} rowSpacing={96}>
        <Grid sm={13} lg={4}>
          <SkeletonTypography
            variant={ETypographyVariant.BODY1}
            maxWidth={isDesktop ? '100%' : '280px'}
            marginBottom={'10px'}
          />
          <SkeletonTypography lineHeight={'60px'} marginBottom={'32px'} />
          <SkeletonTypography lineHeight={'44px'} borderRadius={'8px'} marginBottom={'4px'} />
          <SkeletonTypography lineHeight={'44px'} borderRadius={'8px'} marginBottom={'4px'} />
          <SkeletonTypography lineHeight={'44px'} borderRadius={'8px'} />
        </Grid>

        <Grid sm={13} lg={9}>
          <SkeletonTypography lineHeight={'42px'} maxWidth={'700px'} margin={'0 auto 8px'} />
          <SkeletonTypography lineHeight={'42px'} maxWidth={'350px'} margin={'0 auto 24px'} />
          <SkeletonTypography width={'120px'} margin={'0 auto 40px'} />
          <SkeletonTypography lineHeight={'372px'} marginBottom={'40px'} />
          <SkeletonTypography lineHeight={'120px'} />
        </Grid>
      </Grid>
    </SkeletonWrapper>
  );
};
