import { ImageSourceEntity } from '@core/type/api';

export const getImage = (image: ImageSourceEntity) =>
  image?.src?.baseUrl ||
  image?.src?.small2xUrl ||
  image?.src?.small3xUrl ||
  image?.webp?.baseUrl ||
  image?.webp?.small2xUrl ||
  image?.webp?.small3xUrl ||
  null;
