import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type {
  PatchProductAlertEntity,
  PostProductAlertEntity,
  PostProductAlertResponse,
  ProductAlertResponse,
} from '@core/type/api';

import { ProductAlertsAPI } from '../api/product-alerts';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('ProductAlertService', methodName, dto, apiCall, withContent);
};

const getAlerts = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<ProductAlertResponse> => {
  return log('getAlerts', { axiosInstance, language }, ProductAlertsAPI.getAlerts);
};

const addAlert = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: PostProductAlertEntity,
): Promise<PostProductAlertResponse> => {
  return log('addAlert', { axiosInstance, language, ...data }, ProductAlertsAPI.addAlert);
};

const patchAlert = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  alertId: number,
  data: PatchProductAlertEntity,
): Promise<void> => {
  return log(
    'patchAlert',
    { axiosInstance, language, alertId, ...data },
    ProductAlertsAPI.updateAlert,
  );
};

const deleteAlert = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  alertId: number,
): Promise<void> => {
  return log('deleteAlert', { axiosInstance, language, alertId }, ProductAlertsAPI.deleteAlert);
};

export class ProductAlertService {
  public static readonly getAlerts = getAlerts;
  public static readonly addAlert = addAlert;
  public static readonly deleteAlert = deleteAlert;
  public static readonly patchAlert = patchAlert;
}
