import { ECurrency, ELanguageTags, ERoundingMode, EUnit } from '@core/type';

export const formatNumber = (
  locale: ELanguageTags,
  number?: number,
  options?: Intl.NumberFormatOptions,
): string => new Intl.NumberFormat(locale, options).format(number);

export const formatCurrency = (
  locale: ELanguageTags,
  currency: ECurrency = ECurrency.USD,
  number?: number,
  options?: Exclude<Intl.NumberFormatOptions, 'currency'>,
): string =>
  new Intl.NumberFormat(locale, { style: 'currency', currency, ...options })
    .format(number)
    .replaceAll('$US', '$')
    .replaceAll('USD', '$')
    .replaceAll('£GB', '£');

export const formatCurrencyArray = (
  locale: ELanguageTags,
  currency: ECurrency = ECurrency.USD,
  number?: number,
  options?: Exclude<Intl.NumberFormatOptions, 'currency'>,
): Intl.NumberFormatPart[] =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    ...options,
  })
    .formatToParts(number)
    .map((props) => {
      if (props.type === 'currency') {
        return {
          ...props,
          value: props.value.replaceAll('$US', '$').replaceAll('USD', '$').replaceAll('£GB', '£'),
        };
      }

      return props;
    });

export const formatPriceRange = (
  from: number,
  to: number,
  language: ELanguageTags,
  currency: ECurrency,
) => {
  return from === 0
    ? `< ${formatCurrency(language, currency, to)}`
    : `${formatCurrency(language, currency, from)} - ${formatCurrency(language, currency, to)}`;
};

export const formatUnitShort = (
  locale: ELanguageTags,
  unit: EUnit,
  number?: number,
  minimumFractionDigits?: number,
  roundingMode?: ERoundingMode,
): string =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  new Intl.NumberFormat(locale, {
    style: 'unit',
    unitDisplay: 'short',
    unit,
    minimumFractionDigits,
    roundingMode,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any).format(number);

export function getDecimalSeparator(locale: string) {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  return Intl.NumberFormat(locale).format(1.1).charAt(1);
}
