import styled, { css } from 'styled-components';

import { Box } from '@core/component';

import { HeaderPrice } from './HeaderPrice';

const ScrollableBox = styled(Box)(
  () => css`
    scrollbar-width: none !important;

    &::-webkit-scrollbar {
      display: none;
    }
  `,
);

export const LiveChartsHeaderMobile = () => {
  return (
    <Box maxWidth={'100vw'}>
      <ScrollableBox overflowX={'scroll'}>
        <HeaderPrice />
      </ScrollableBox>
    </Box>
  );
};
