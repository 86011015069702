import useTranslation from 'next-translate/useTranslation';

import { Box, Container, LinkTranslatedStyledAsLink, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { EActionAttribute, EColor, EFontWeight, ERouting, ETypographyVariant } from '@core/type';

import { ErrorLinkProps, ErrorViewProps } from './interface-error';

const ErrorLink = ({ keyRoute, title, isDesktop }: ErrorLinkProps) => (
  <Box
    as={EActionAttribute.LI}
    padding={isDesktop ? '10px 40px' : '10px 20px'}
    listStyleType={'none'}
  >
    <LinkTranslatedStyledAsLink
      keyRoute={keyRoute}
      isUnderlinedHover={false}
      color={EColor.PRIMARY}
      textTransform={'uppercase'}
    >
      {title}
    </LinkTranslatedStyledAsLink>
  </Box>
);

export const ErrorDefaultView = ({ error, title, subtitle, withLinks = true }: ErrorViewProps) => {
  const { isDesktop, isPhone } = useContextMediaQuery();
  const { t } = useTranslation(Translate.common.BREADCRUMB);

  return (
    <Container>
      <Box
        paddingTop={isDesktop ? '100px' : '0'}
        paddingBottom={isPhone ? '60px' : '288px'}
        textAlign={'center'}
      >
        <Typography
          margin={'11px 0'}
          fontSize={'120px'}
          fontWeight={EFontWeight.LIGHT}
          lineHeight={'1.28'}
          as={EActionAttribute.H4}
        >
          {error}
        </Typography>

        <Box height={'30px'} />

        {title && (
          <Typography
            as={EActionAttribute.H3}
            variant={ETypographyVariant.H1}
            marginTop={'22px'}
            fontWeight={EFontWeight.MEDIUM}
          >
            {title}
          </Typography>
        )}

        {subtitle && (
          <Typography fontSize={'16px'} margin={'11px auto 0'} maxWidth={'785px'}>
            {subtitle}
          </Typography>
        )}

        {withLinks && (
          <Box
            marginTop={'50px'}
            display={'flex'}
            justifyContent={'center'}
            as={EActionAttribute.UL}
          >
            <ErrorLink keyRoute={ERouting.HOME_BUY} title={t('shop')} isDesktop={isDesktop} />
            <ErrorLink keyRoute={ERouting.WALLET} title={t('wallet')} isDesktop={isDesktop} />
            <ErrorLink keyRoute={ERouting.FAQ} title={t('help')} isDesktop={isDesktop} />
          </Box>
        )}
      </Box>
    </Container>
  );
};
