import { getTranslationWithOrientation } from '@core/util';

import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconArrowLong = ({ orientation, yTranslation, stroke }: DynamicIconProps) => (
  <SVG
    height={21}
    width={45}
    viewBox="0 0 45 21"
    title="Arrow"
    fill="none"
    transform={getTranslationWithOrientation(orientation, yTranslation)}
  >
    <path
      d="M34 1L44 10.02L34 19.919M43.6 10.2L1 10.46"
      stroke={stroke}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
);
