import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconCopy = ({ fill }: DynamicIconProps) => {
  return (
    <SVG height={16} width={16} viewBox="0 0 16 16" title="Copy icon">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.31342 1.83398C5.48499 1.83398 4.81342 2.50556 4.81342 3.33398C4.81342 3.61013 5.03728 3.83398 5.31342 3.83398C5.58956 3.83398 5.81342 3.61013 5.81342 3.33398C5.81342 3.05784 6.03728 2.83398 6.31342 2.83398H12.3736C12.6497 2.83398 12.8736 3.05784 12.8736 3.33398V10.4689C12.8736 10.745 12.6497 10.9689 12.3736 10.9689H11.9996C11.7235 10.9689 11.4996 11.1927 11.4996 11.4689C11.4996 11.745 11.7235 11.9689 11.9996 11.9689H12.3736C13.202 11.9689 13.8736 11.2973 13.8736 10.4689V3.33398C13.8736 2.50556 13.202 1.83398 12.3736 1.83398H6.31342ZM3.62646 3.98339C2.79804 3.98339 2.12646 4.65496 2.12646 5.48339V12.6183C2.12646 13.4467 2.79804 14.1183 3.62646 14.1183H9.68665C10.5151 14.1183 11.1866 13.4467 11.1866 12.6183V5.48339C11.1866 4.65496 10.5151 3.98339 9.68665 3.98339H3.62646ZM3.12646 5.48339C3.12646 5.20724 3.35032 4.98339 3.62646 4.98339H9.68665C9.96279 4.98339 10.1866 5.20724 10.1866 5.48339V12.6183C10.1866 12.8944 9.96279 13.1183 9.68665 13.1183H3.62646C3.35032 13.1183 3.12646 12.8944 3.12646 12.6183V5.48339Z"
        fill={fill}
      />
    </SVG>
  );
};
