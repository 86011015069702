import { FC, MouseEventHandler } from 'react';
import styled, { CSSObject } from 'styled-components';

import { EActionAttribute, EColor, WithThemeProps } from '@core/type';
import { getPaletteBackgroundColor } from '@core/util';

import { StyledSelectBaseOptionProps } from '../interface';
import { Box } from '../layout';
import { SelectBaseOptionProps } from './interface-select';

export const SelectBaseOption = ({
  value,
  onChange,
  isActive,
  label,
  content,
  children,
  onClick,
  onMouseLeave,
  onMouseEnter,
  ...restProps
}: SelectBaseOptionProps): JSX.Element => {
  const handleOption: MouseEventHandler = (e) => {
    onChange(value, content || label, e);
  };

  return (
    <StyledSelectOption
      {...restProps}
      role={'option'}
      isActive={isActive}
      as={EActionAttribute.LI}
      aria-selected={isActive}
      onClick={(event) => {
        event.preventDefault();
        onClick ? onClick(event) : handleOption(event);
      }}
    >
      {children || label}
    </StyledSelectOption>
  );
};

const StyledSelectOption: FC<StyledSelectBaseOptionProps> = styled(Box)(({
  theme: { palette },
  onClick: _onClick,
  onMouseEnter: _onMouseEnter,
  onMouseLeave: _onMouseLeave,
  animation: _animation,
  role: _role,
  isActive,
  as: _as,
  hoverStyles,
  ...restProps
}: StyledSelectBaseOptionProps & WithThemeProps) => {
  const hoverColor = hoverStyles
    ? getPaletteBackgroundColor({
        backgroundColor: hoverStyles.backgroundColor,
        palette,
      })
    : palette[EColor.GRAY][EColor.A50];
  return {
    cursor: 'pointer',
    width: '100%',
    padding: 0,
    listStyleType: 'none',
    whiteSpace: 'nowrap',
    backgroundColor: isActive ? hoverColor : 'none',
    '&:hover': {
      backgroundColor: hoverColor,
    },
    ...restProps,
  } as CSSObject;
});
