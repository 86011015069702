import withTranslation from 'next-translate/withTranslation';
import { useTheme } from 'styled-components';

import { Translate } from '@core/constant';
import { useContextRouting, useContextUser } from '@core/context';
import { EColor, ThemeProps, WithTranslation } from '@core/type';
import { getTypeRoute } from '@core/util';

import { BoxProps } from '../interface';
import { Box } from '../layout/Box';
import { Typography } from '../typography/Typography';
import { BreadcrumbLink } from './BreadcrumbLink';
import { BreadcrumbProps, BreadcrumbTitleProps } from './interface-breadcrumb';

const BreadcrumbTitleContainer = ({
  isFullWidth,
  backgroundColor,
  breadcrumbObjects,
  i18n: { t },
}: BreadcrumbTitleProps & WithTranslation): JSX.Element => {
  const {
    structure: {
      breadcrumb: { marginBottom },
    },
    typography: {
      breadcrumb: { fontSize },
    },
  } = useTheme() as ThemeProps;
  const fullWidthStyles = {
    marginTop: '-24px',
    marginLeft: '-24px',
    marginRight: '-24px',
    paddingTop: '24px',
    paddingLeft: '24px',
    paddingRight: '24px',
  };

  return breadcrumbObjects?.length > 0 ? (
    <Box
      {...(isFullWidth && (fullWidthStyles as BoxProps))}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      paddingBottom={marginBottom}
      backgroundColor={backgroundColor}
    >
      <nav>
        {breadcrumbObjects.map(
          (
            { keyRoute: keyRouteBreadcrumb, title, query: queryBreadcrumb, href },
            index,
            { length },
          ) => {
            // last one
            if (index + 1 === length) {
              return (
                <Typography
                  key={index}
                  display={'inline-block'}
                  color={EColor.SECONDARY}
                  variant={fontSize}
                >
                  {title?.toString() || t(keyRouteBreadcrumb)}
                </Typography>
              );
            }
            return (
              <BreadcrumbLink
                key={index}
                keyRoute={keyRouteBreadcrumb}
                title={title?.toString() || t(keyRouteBreadcrumb)}
                query={queryBreadcrumb}
                href={href}
              />
            );
          },
        )}
      </nav>
    </Box>
  ) : null;
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const BreadcrumbTitle = withTranslation(
  BreadcrumbTitleContainer,
  Translate.common.BREADCRUMB,
);

export const Breadcrumb = ({
  isFullWidth,
  backgroundColor,
  breadcrumbObjects,
  breadcrumbObjectWithLogin,
}: BreadcrumbProps) => {
  const { keyRoute } = useContextRouting();
  /*
   TODO: a component must not call useContextUser, and should not have the responsibility of
   figuring out whether the user is logged-in or not.
   */

  const { isLogged } = useContextUser();

  let breadcrumbs = breadcrumbObjects;

  /*
   TODO: a component should not be aware of the log-in state of the user.
   */
  if (breadcrumbObjectWithLogin && !isLogged) {
    breadcrumbs = breadcrumbObjects.filter(
      (el) => !breadcrumbObjectWithLogin.includes(el.keyRoute),
    );
  }

  return (
    getTypeRoute(keyRoute).breadcrumb && (
      <BreadcrumbTitle
        isFullWidth={isFullWidth}
        backgroundColor={backgroundColor}
        breadcrumbObjects={breadcrumbs}
      />
    )
  );
};
