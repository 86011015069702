import { EStorageType } from '@core/type';

import { CookieHelper } from './cookie';

export const setItem = (storage: EStorageType, key: string, value: string, expiresDay?: number) => {
  switch (storage) {
    case EStorageType.LOCAL_STORAGE:
      return localStorage.setItem(key, value);
    case EStorageType.COOKIE:
      return CookieHelper.setItem(key, value, expiresDay);
    default:
      return localStorage.setItem(key, value);
  }
};

export const getItem = (storage: EStorageType, key: string): string => {
  switch (storage) {
    case EStorageType.LOCAL_STORAGE:
      return localStorage.getItem(key);
    case EStorageType.COOKIE:
      return CookieHelper.getItem(key);
    default:
      return localStorage.getItem(key);
  }
};

export const removeItem = (storage: EStorageType, key: string) => {
  switch (storage) {
    case EStorageType.LOCAL_STORAGE:
      return localStorage.removeItem(key);
    case EStorageType.COOKIE:
      return CookieHelper.removeItem(key);
    default:
      return localStorage.removeItem(key);
  }
};
