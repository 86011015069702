import { ReactNode } from 'react';
import { Control } from 'react-hook-form';

import { ButtonProps, ButtonRadioProps, SelectTextProps } from '@core/component/interface';
import { ECurrency, EIcon, ELanguageTags, EPosition } from '@core/type';

export type SelectCurrencyBorderedProps = Omit<SelectTextProps, 'options'>;

export type ControlProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
};

export enum ESelectDropdown {
  LANGUAGE = 'language',
  CURRENCY = 'currency',
}

export type SelectLanguageCurrencyProps = {
  containerIsRelative?: boolean;
  withCurrency?: boolean;
  withLanguage?: boolean;
  onCurrencyChange?: (currency: ECurrency) => void;
  onLanguageChange?: (language: ELanguageTags) => void;
  position?:
    | EPosition.TOP_LEFT
    | EPosition.TOP_RIGHT
    | EPosition.BOTTOM_LEFT
    | EPosition.BOTTOM_RIGHT;
  isDarkBackground?: boolean;
  shouldShowCurrencyLink: boolean;
};

export type SelectTabProps = { value: ESelectDropdown; label: string };

export type SelectDropdownProps = Pick<ButtonRadioProps, 'onChange'> &
  Pick<SelectLanguageCurrencyProps, 'position'> & {
    onClose: () => void;
    name: ESelectDropdown;
    options: {
      value: string;
      label: ReactNode;
    }[];
    currentValue: ECurrency | ELanguageTags;
    tabs: SelectTabProps[];
    onTabChange: (value: ESelectDropdown) => void;
    shouldShowCurrencyLink: boolean;
  };

export type ButtonRadioDropdownProps = {
  value: string;
  name: string;
  currentValue: string;
  label: ReactNode;
  onChange?: (value: string) => void;
};

export type SelectButtonProps = Pick<ButtonProps, 'onClick'> & {
  image?: ReactNode;
  iconType?: EIcon;
  isActive: boolean;
  isDarkBackground?: boolean;
  children: ReactNode;
  name: string;
};
