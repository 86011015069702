export enum EProductType {
  BAR = 'bar',
  COIN = 'coin',
  MINTED_BAR = 'minted_bar',
}

export enum EFullProductType {
  CAST_BAR = 'Cast Bar',
  MINTED_BAR = 'Minted Bar',
  COIN = 'Coin',
}
