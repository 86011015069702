import Image from 'next/image';

import IconZeroPercentSvg from './svgs/icon-zero-percent.svg';

export const IconZeroPercent = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconZeroPercentSvg}
    alt="Zero percent icon"
    width={96}
    height={96}
  />
);
