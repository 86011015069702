export const ONFIDO_API_URL = 'https://api.onfido.com';
export const ONFIDO_WSS_URL = 'wss://sync.onfido.com';
export const ONFIDO_ASSETS_URL = 'https://assets.onfido.com';
export const ONFIDO_SDK_URL = 'https://sdk.onfido.com';
export const ONFIDO_SARDINE_URL = 'https://*.sardine.ai/';
export const ONFIDO_ALL_URL = '*.onfido.com';
export const ONFIDO_ALL_WSS_URL = 'wss://*.onfido.com';

// The onfido token is valid for 2 hours
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const ONFIDO_TOKEN_VALIDITY = 2 * 60 * 60 * 1000;
