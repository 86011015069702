import type { SVGAttributes } from 'react';
import { ReactNode, useId } from 'react';
import styled, { CSSObject } from 'styled-components';

import { ESVGRole } from '@core/type';

export type SVGProps = SVGAttributes<SVGElement> & {
  children: ReactNode;
  role?: ESVGRole;
  title?: string;
  description?: string;
  ariaLabel?: string;
  ariaHidden?: boolean;
  ariaLabelledby?: string;
  ariaDescribedby?: string;
  transform?: string;
  flex?: string | number;
};

export const SVGIcon = (props: SVGProps) => {
  // TODO: Create a custom hook with boolean paramter to return null or random number
  const titleId = useId();
  const descriptionId = useId();

  const {
    children,
    role = ESVGRole.IMG,
    title,
    description,
    ariaLabel,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ariaHidden = true,
    ariaLabelledby = title && titleId,
    ariaDescribedby = description && descriptionId,
    transform,
    ...svgProps
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      role={role}
      aria-label={ariaLabel}
      aria-hidden={ariaHidden}
      aria-labelledby={ariaLabelledby}
      aria-describedby={ariaDescribedby}
      {...svgProps}
    >
      {title && <title id={ariaLabelledby}>{title}</title>}
      {description && <desc id={ariaDescribedby}>{description}</desc>}
      {children}
    </svg>
  );
};

export const SVGStyled = styled(SVGIcon)<SVGProps>`
  ${(props: SVGProps) => {
    const { transform } = props;

    return {
      transform,
    } as CSSObject;
  }}
`;

export const SVG = (props: SVGProps) => {
  return <SVGStyled {...props} />;
};
