import useTranslation from 'next-translate/useTranslation';
import styled, { css } from 'styled-components';

import { Box, Icon, LinkTranslatedStyledAsLink, Typography } from '@core/component';
import { BoxProps } from '@core/component/interface';
import { Translate } from '@core/constant';
import { useContextCart, useContextProfile, useContextUser } from '@core/context';
import {
  EActionAttribute,
  EColor,
  EIcon,
  ERouting,
  ESize,
  EZIndexName,
  ThemeProps,
} from '@core/type';

import { HeaderCardProps } from './interface-header';

const BoxStyled = styled(Box)<BoxProps>(
  ({ theme: { palette } }: { theme: ThemeProps }) => css`
    position: relative;
    width: 56px;
    display: flex;
    height: 32px;
    justify-content: flex-end;
    cursor: pointer;
    align-items: center;

    path {
      transition: all 0.3s;
    }

    &:hover {
      path {
        fill: ${palette[EColor.WARNING][EColor.MAIN]};
      }
    }
  `,
);

const HeaderCartBase = ({ nbArticle }: HeaderCardProps) => {
  const { t: tAria } = useTranslation(Translate.common.ARIA);

  return (
    <LinkTranslatedStyledAsLink keyRoute={ERouting.CART} aria-label={tAria('cart')}>
      <BoxStyled as={EActionAttribute.SPAN}>
        <Icon
          type={EIcon.CART}
          size={ESize.MD}
          color={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
        />
        {nbArticle > 0 && (
          <Box
            backgroundColor={EColor.WARNING}
            color={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
            borderRadius="50%"
            height="16px"
            width="16px"
            position="absolute"
            lineHeight="16px"
            zIndex={EZIndexName.AUTO}
            top="-3px"
            right="-6px"
          >
            <Typography textAlign="center" lineHeight="16px" fontSize="small">
              {nbArticle}
            </Typography>
          </Box>
        )}
      </BoxStyled>
    </LinkTranslatedStyledAsLink>
  );
};

export const HeaderCart = () => {
  const { isLogged } = useContextUser();
  const { cartItemsCount } = useContextProfile();
  const { cart } = useContextCart();

  return <HeaderCartBase nbArticle={isLogged ? cartItemsCount : cart?.products?.length || 0} />;
};
