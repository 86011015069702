import type { AxiosInstance } from 'axios';

import { ECurrency, EDevice, ELanguage } from '@core/type';
import { getHttpServerConfig } from '@core/util';

import { getEnvironmentPublic } from './environment';

export const initializeHttpServer = ({
  contentType = 'application/json',
  ...params
}: {
  locale: ELanguage;
  currency?: ECurrency;
  csrfToken?: string;
  token?: string;
  tokenAuthorizationApi?: string;
  deviceType?: EDevice;
  contentType: 'application/json' | 'multipart/form-data';
}): AxiosInstance => {
  const { apiHost, apiBase, apiVersion, isProductionServer } = getEnvironmentPublic();
  return getHttpServerConfig({
    ...params,
    contentType,
    apiHost,
    apiBase,
    apiVersion,
    isProductionServer,
  });
};
