declare global {
  interface Window {
    grecaptcha: ReCaptchaInstance;
  }
}

interface ReCaptchaExecuteOptions {
  action: string;
}

interface ReCaptchaInstance {
  ready: (cb?: () => Promise<string>) => void;
  execute: (key: string, options: ReCaptchaExecuteOptions) => Promise<string>;
}

export const useRecaptcha = (recaptchaKey: string) => {
  const getRecaptcha = async (): Promise<string> => {
    return window.grecaptcha.execute(recaptchaKey, { action: 'submit' });
  };

  return { getRecaptcha };
};
