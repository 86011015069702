import { EFullMetalName, EFullProductType, EMetal, EProductType } from '@core/type';

export const metalIsoToFullMetalName = {
  [EMetal.GOLD]: EFullMetalName.GOLD,
  [EMetal.SILVER]: EFullMetalName.SILVER,
  [EMetal.PALLADIUM]: EFullMetalName.PALLADIUM,
  [EMetal.PLATINUM]: EFullMetalName.PLATINUM,
};

export const productTypeToFullProductType = {
  [EProductType.BAR]: EFullProductType.CAST_BAR,
  [EProductType.MINTED_BAR]: EFullProductType.MINTED_BAR,
  [EProductType.COIN]: EFullProductType.COIN,
};
