import withTranslation from 'next-translate/withTranslation';

import { Box } from '@core/component';
import { Translate } from '@core/constant';
import { EColor, WithTranslation } from '@core/type';

const LabelNewContainer = ({ i18n: { t } }: WithTranslation) => {
  return (
    <Box
      display={'inline-block'}
      padding={'0 4px'}
      marginLeft={'6px'}
      borderRadius={'8px'}
      color={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
      fontSize={'10px'}
      lineHeight={'15px'}
      textTransform={'uppercase'}
      backgroundColor={EColor.ACCENT}
    >
      {t('new')}
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const LabelNew = withTranslation(LabelNewContainer, Translate.layout.FOOTER);
