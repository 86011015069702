import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type { CountriesEntity } from '@core/type/api';

import { Api } from '../constants/api';
import { configResponse, EConfigName } from '../helpers/config';

const getCountries = (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<CountriesEntity>> => {
  return axiosInstance.get(
    Api.countries.COUNTRIES,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class CountriesAPI {
  public static readonly getCountries = getCountries;
}
