import { ELanguageTags, EMetal, EMetalSlug, MetalToSlugMap, SlugToMetalMap } from '@core/type';

export const metalToSlug: MetalToSlugMap = {
  [ELanguageTags.DEFAULT]: {
    [EMetal.GOLD]: EMetalSlug.GOLD_EN,
    [EMetal.SILVER]: EMetalSlug.SILVER_EN,
    [EMetal.PLATINUM]: EMetalSlug.PLATINUM_EN,
    [EMetal.PALLADIUM]: EMetalSlug.PALLADIUM_EN,
  },
  [ELanguageTags.EN_US]: {
    [EMetal.GOLD]: EMetalSlug.GOLD_EN,
    [EMetal.SILVER]: EMetalSlug.SILVER_EN,
    [EMetal.PLATINUM]: EMetalSlug.PLATINUM_EN,
    [EMetal.PALLADIUM]: EMetalSlug.PALLADIUM_EN,
  },
  [ELanguageTags.FR_FR]: {
    [EMetal.GOLD]: EMetalSlug.GOLD_FR,
    [EMetal.SILVER]: EMetalSlug.SILVER_FR,
    [EMetal.PLATINUM]: EMetalSlug.PLATINUM_FR,
    [EMetal.PALLADIUM]: EMetalSlug.PALLADIUM_FR,
  },
  [ELanguageTags.IT_IT]: {
    [EMetal.GOLD]: EMetalSlug.GOLD_IT,
    [EMetal.SILVER]: EMetalSlug.SILVER_IT,
    [EMetal.PLATINUM]: EMetalSlug.PLATINUM_IT,
    [EMetal.PALLADIUM]: EMetalSlug.PALLADIUM_IT,
  },
  [ELanguageTags.DE_DE]: {
    [EMetal.GOLD]: EMetalSlug.GOLD_DE,
    [EMetal.SILVER]: EMetalSlug.SILVER_DE,
    [EMetal.PLATINUM]: EMetalSlug.PLATINUM_DE,
    [EMetal.PALLADIUM]: EMetalSlug.PALLADIUM_DE,
  },
};

export const metalPriceToSlug: MetalToSlugMap = {
  [ELanguageTags.DEFAULT]: {
    [EMetal.GOLD]: EMetalSlug.GOLD_PRICE_EN,
    [EMetal.SILVER]: EMetalSlug.SILVER_PRICE_EN,
    [EMetal.PLATINUM]: EMetalSlug.PLATINUM_PRICE_EN,
    [EMetal.PALLADIUM]: EMetalSlug.PALLADIUM_PRICE_EN,
  },
  [ELanguageTags.EN_US]: {
    [EMetal.GOLD]: EMetalSlug.GOLD_PRICE_EN,
    [EMetal.SILVER]: EMetalSlug.SILVER_PRICE_EN,
    [EMetal.PLATINUM]: EMetalSlug.PLATINUM_PRICE_EN,
    [EMetal.PALLADIUM]: EMetalSlug.PALLADIUM_PRICE_EN,
  },
  [ELanguageTags.FR_FR]: {
    [EMetal.GOLD]: EMetalSlug.GOLD_PRICE_FR,
    [EMetal.SILVER]: EMetalSlug.SILVER_PRICE_FR,
    [EMetal.PLATINUM]: EMetalSlug.PLATINUM_PRICE_FR,
    [EMetal.PALLADIUM]: EMetalSlug.PALLADIUM_PRICE_FR,
  },
  [ELanguageTags.IT_IT]: {
    [EMetal.GOLD]: EMetalSlug.GOLD_PRICE_IT,
    [EMetal.SILVER]: EMetalSlug.SILVER_PRICE_IT,
    [EMetal.PLATINUM]: EMetalSlug.PLATINUM_PRICE_IT,
    [EMetal.PALLADIUM]: EMetalSlug.PALLADIUM_PRICE_IT,
  },
  [ELanguageTags.DE_DE]: {
    [EMetal.GOLD]: EMetalSlug.GOLD_PRICE_DE,
    [EMetal.SILVER]: EMetalSlug.SILVER_PRICE_DE,
    [EMetal.PLATINUM]: EMetalSlug.PLATINUM_PRICE_DE,
    [EMetal.PALLADIUM]: EMetalSlug.PALLADIUM_PRICE_DE,
  },
};

export const slugToMetal: SlugToMetalMap = {
  [ELanguageTags.DEFAULT]: {
    [EMetalSlug.GOLD_EN]: EMetal.GOLD,
    [EMetalSlug.SILVER_EN]: EMetal.SILVER,
    [EMetalSlug.PLATINUM_EN]: EMetal.PLATINUM,
    [EMetalSlug.PALLADIUM_EN]: EMetal.PALLADIUM,
  },
  [ELanguageTags.EN_US]: {
    [EMetalSlug.GOLD_EN]: EMetal.GOLD,
    [EMetalSlug.SILVER_EN]: EMetal.SILVER,
    [EMetalSlug.PLATINUM_EN]: EMetal.PLATINUM,
    [EMetalSlug.PALLADIUM_EN]: EMetal.PALLADIUM,
  },
  [ELanguageTags.FR_FR]: {
    [EMetalSlug.GOLD_FR]: EMetal.GOLD,
    [EMetalSlug.SILVER_FR]: EMetal.SILVER,
    [EMetalSlug.PLATINUM_FR]: EMetal.PLATINUM,
    [EMetalSlug.PALLADIUM_FR]: EMetal.PALLADIUM,
  },
  [ELanguageTags.IT_IT]: {
    [EMetalSlug.GOLD_IT]: EMetal.GOLD,
    [EMetalSlug.SILVER_IT]: EMetal.SILVER,
    [EMetalSlug.PLATINUM_IT]: EMetal.PLATINUM,
    [EMetalSlug.PALLADIUM_IT]: EMetal.PALLADIUM,
  },
  [ELanguageTags.DE_DE]: {
    [EMetalSlug.GOLD_DE]: EMetal.GOLD,
    [EMetalSlug.SILVER_DE]: EMetal.SILVER,
    [EMetalSlug.PLATINUM_DE]: EMetal.PLATINUM,
    [EMetalSlug.PALLADIUM_DE]: EMetal.PALLADIUM,
  },
};

export const slugToMetalPrice: SlugToMetalMap = {
  [ELanguageTags.DEFAULT]: {
    [EMetalSlug.GOLD_PRICE_EN]: EMetal.GOLD,
    [EMetalSlug.SILVER_PRICE_EN]: EMetal.SILVER,
    [EMetalSlug.PLATINUM_PRICE_EN]: EMetal.PLATINUM,
    [EMetalSlug.PALLADIUM_PRICE_EN]: EMetal.PALLADIUM,
  },
  [ELanguageTags.EN_US]: {
    [EMetalSlug.GOLD_PRICE_EN]: EMetal.GOLD,
    [EMetalSlug.SILVER_PRICE_EN]: EMetal.SILVER,
    [EMetalSlug.PLATINUM_PRICE_EN]: EMetal.PLATINUM,
    [EMetalSlug.PALLADIUM_PRICE_EN]: EMetal.PALLADIUM,
  },
  [ELanguageTags.FR_FR]: {
    [EMetalSlug.GOLD_PRICE_FR]: EMetal.GOLD,
    [EMetalSlug.SILVER_PRICE_FR]: EMetal.SILVER,
    [EMetalSlug.PLATINUM_PRICE_FR]: EMetal.PLATINUM,
    [EMetalSlug.PALLADIUM_PRICE_FR]: EMetal.PALLADIUM,
  },
  [ELanguageTags.IT_IT]: {
    [EMetalSlug.GOLD_PRICE_IT]: EMetal.GOLD,
    [EMetalSlug.SILVER_PRICE_IT]: EMetal.SILVER,
    [EMetalSlug.PLATINUM_PRICE_IT]: EMetal.PLATINUM,
    [EMetalSlug.PALLADIUM_PRICE_IT]: EMetal.PALLADIUM,
  },
  [ELanguageTags.DE_DE]: {
    [EMetalSlug.GOLD_PRICE_DE]: EMetal.GOLD,
    [EMetalSlug.SILVER_PRICE_DE]: EMetal.SILVER,
    [EMetalSlug.PLATINUM_PRICE_DE]: EMetal.PLATINUM,
    [EMetalSlug.PALLADIUM_PRICE_DE]: EMetal.PALLADIUM,
  },
};
