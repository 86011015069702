import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type {
  GiftPatchPayload,
  GiftsConfirmResponseEntity,
  GiftsRelationshipsEntity,
  GiftsRequestEntity,
  UserGiftResponse,
  WaitingGiftsEntity,
} from '@core/type/api';
import { ToastResponse } from '@core/type/context';

import { GiftsAPI } from '../api/gifts';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
): Promise<R> => {
  return logService('GiftsService', methodName, dto, apiCall);
};

const getGiftsRelationships = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<GiftsRelationshipsEntity> => {
  return log('getGiftsRelationships', { axiosInstance, language }, GiftsAPI.getGiftsRelationships);
};

const getGiftUserInformation = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  referenceNumber: string,
): Promise<UserGiftResponse> => {
  return log(
    'getGiftUserInformation',
    { axiosInstance, referenceNumber, language },
    GiftsAPI.getGiftUserInformation,
  );
};

const getWaitingGifts = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<WaitingGiftsEntity> => {
  return log('getWaitingGifts', { axiosInstance, language }, GiftsAPI.getWaitingGifts);
};

const patchGift = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  giftPatchPayload: GiftPatchPayload,
): Promise<void> => {
  return log('patchGift', { axiosInstance, language, ...giftPatchPayload }, GiftsAPI.patchGift);
};

const postGifts = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  giftRequest: GiftsRequestEntity,
): Promise<void> => {
  return log('postGifts', { axiosInstance, language, ...giftRequest }, GiftsAPI.postGifts);
};

const confirmGift = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<GiftsConfirmResponseEntity> => {
  return log('confirmGift', { axiosInstance, language }, GiftsAPI.confirmGift);
};

const acceptGift = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  referenceNumber: string,
): Promise<ToastResponse> => {
  return log(
    'acceptGift',
    {
      axiosInstance,
      language,
      referenceNumber,
    },
    GiftsAPI.acceptGift,
  );
};

const declineGift = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  referenceNumber: string,
): Promise<ToastResponse> => {
  return log('declineGift', { axiosInstance, language, referenceNumber }, GiftsAPI.declineGift);
};

export class GiftsService {
  public static readonly postGifts = postGifts;
  public static readonly getGiftsRelationships = getGiftsRelationships;
  public static readonly patchGift = patchGift;
  public static readonly confirmGift = confirmGift;
  public static readonly getWaitingGifts = getWaitingGifts;
  public static readonly acceptGift = acceptGift;
  public static readonly declineGift = declineGift;
  public static readonly getGiftUserInformation = getGiftUserInformation;
}
