import { useCallback } from 'react';

import { useStoreCurrency } from '@core/context';
import { Logger } from '@core/logger';
import { CurrencyStorage } from '@core/storage';
import { ECurrency, EStorageType } from '@core/type';
import type { CurrencyHook, CurrencyState, HookStoreFn } from '@core/type/context';

export const useCurrency: HookStoreFn<CurrencyState, CurrencyHook> = (): CurrencyHook => {
  const [currency, setCurrency] = useStoreCurrency<ECurrency>('currency');
  const [storage] = useStoreCurrency<EStorageType>('storage');

  const changeCurrency = useCallback(
    (currency: ECurrency) => {
      setCurrency(currency);
      CurrencyStorage.set(storage, currency);
      Logger.logInfo(`Call useCurrency::changeCurrency with<${currency}>`, {
        library: 'core-hook',
        currency,
      });
    },
    [setCurrency, storage],
  );

  return {
    currency,
    changeCurrency,
  };
};
