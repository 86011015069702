import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

import { EHttpStatusCode } from '@core/type';

export function useMutationApi<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>,
): UseMutationResult<TData, TError, TVariables, TContext> {
  return useMutation(mutationFn, {
    ...options,
    onError: (error, variable, context) => {
      const isStatusOk =
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        (error as any)?.status >= EHttpStatusCode.OK &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        (error as any)?.status <= EHttpStatusCode.ALREADY_REPORTED;

      if (isStatusOk) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        return options?.onSuccess?.((error as any)?.data, variable, context);
      } else {
        return options?.onError?.(error, variable, context);
      }
    },
  });
}
