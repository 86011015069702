import type { ReactNode } from 'react';

import { metaContext } from '@core/context';
import { Price } from '@core/type';
import { MetaEntity } from '@core/type/api';
import { MetaHook, MetaState } from '@core/type/context';

import { storeHook } from '../helpers';
import { useMeta } from '../hook';

export const ProviderMeta = ({
  children,
  meta,
  priceProduct,
}: {
  meta?: MetaEntity;
  priceProduct?: Price;
  children?: ReactNode;
}) => {
  const hooks = storeHook<MetaState, MetaHook>(
    {
      meta,
      priceProduct,
    },
    useMeta,
  );
  return <metaContext.Provider value={hooks}>{children}</metaContext.Provider>;
};
