import { useContextMediaQuery } from '@core/context';

import { Box } from '../layout';
import { Paper } from '../paper';
import { SkeletonTypography } from './SkeletonTypography';
import { SkeletonWrapper } from './SkeletonWrapper';

export const SkeletonSignIn = () => {
  const { isPhone } = useContextMediaQuery();

  return (
    <SkeletonWrapper
      overflow={'visible'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      height={'100%'}
      flexDirection={'column'}
      paddingTop={'72px'}
    >
      <Paper
        padding={isPhone ? '24px 0' : '40px'}
        hasElevation={!isPhone}
        maxWidth={'552px'}
        maxHeight={'516px'}
        flexShrink={'0'}
        width={'100%'}
        height={'100%'}
      >
        <Box maxWidth={'364px'} margin={'0 auto'}>
          <SkeletonTypography
            lineHeight={'32px'}
            borderRadius={'6px'}
            maxWidth={'170px'}
            margin={'0 auto 8px'}
          />

          <SkeletonTypography
            lineHeight={'20px'}
            borderRadius={'6px'}
            maxWidth={'290px'}
            margin={'0 auto 24px'}
          />

          <SkeletonTypography lineHeight={'48px'} borderRadius={'6px'} marginBottom={'16px'} />
          <SkeletonTypography lineHeight={'48px'} borderRadius={'6px'} marginBottom={'8px'} />
          <SkeletonTypography
            lineHeight={'16px'}
            borderRadius={'6px'}
            marginBottom={'24px'}
            maxWidth={'90px'}
          />
          <SkeletonTypography lineHeight={'48px'} borderRadius={'6px'} marginBottom={'40px'} />
          <SkeletonTypography
            lineHeight={'20px'}
            borderRadius={'6px'}
            maxWidth={'180px'}
            margin={'0 auto 20px'}
          />
          <SkeletonTypography lineHeight={'48px'} borderRadius={'6px'} />
        </Box>
      </Paper>
      <Box height={isPhone ? '192px' : '204px'} />
    </SkeletonWrapper>
  );
};
