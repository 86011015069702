import { SVG } from '../SVG';
import { DynamicIconProps } from '../interface-icon';

export const IconUSD = ({ fill, stroke }: DynamicIconProps) => (
  <SVG height={16} width={15} viewBox="0 0 11 16" title="USD icon">
    <path
      d="M6 7.431V3.2c1.492 0 2.692.97 2.692 2.108a.578.578 0 0 0 1.154 0C9.846 3.53 8.13 2.092 6 2.046v-.97a.577.577 0 1 0-1.154 0v.97C2.715 2.092 1 3.531 1 5.308S2.716 8.524 4.846 8.57v4.23c-1.492 0-2.692-.969-2.692-2.107a.577.577 0 1 0-1.154 0c0 1.776 1.716 3.215 3.846 3.26v.97a.577.577 0 1 0 1.154 0v-.97c2.13-.045 3.846-1.484 3.846-3.26C9.846 8.914 8.13 7.476 6 7.43ZM2.154 5.308c0-1.139 1.2-2.069 2.692-2.108v4.215c-1.492-.038-2.692-.953-2.692-2.107ZM6 12.8V8.585c1.492.038 2.692.97 2.692 2.108S7.492 12.762 6 12.8Z"
      fill={fill}
      stroke={stroke}
      strokeWidth="0.5"
    />
  </SVG>
);
