import { decamelizeKeys } from 'humps';
import mixpanel from 'mixpanel-browser';

import { Logger } from '@core/logger';
import { EMixpanelEventName, MixpanelEventProps } from '@core/type';

export const trackEvent = (event: EMixpanelEventName, rawData: MixpanelEventProps) => {
  const data = decamelizeKeys(rawData) as MixpanelEventProps;

  Logger.logInfo(`Mixpanel event "${event}": ${JSON.stringify(data)}`, {
    library: 'google-tag-manager',
  });

  mixpanel.track(event, data);
};
