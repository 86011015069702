import { createContext } from 'react';

import type { HookStore, ToastHook, ToastItem, ToastObject, ToastState } from '@core/type/context';

import { useFnContext, useStoreContext } from '../util';

export const toastContext =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createContext<HookStore<any, ToastState<ToastItem<any>>, ToastHook<ToastItem<any>>>>(null);

export const useContextToast = () => {
  return useFnContext(toastContext);
};

export function useStoreToast<Selector, T extends ToastItem<ToastObject>>(
  selector: keyof ToastState<T>,
) {
  return useStoreContext<Selector, ToastState<T>, ToastHook<T>>(selector, toastContext);
}
