import type { SanityLinkObjProps } from '../sanity';
import { EIcon } from './icon';

export enum EHeader {
  DEFAULT = 'headerDefault',
  SHORT = 'headerShort',
  BLOG = 'blog',
}

export enum EHeaderMenu {
  LINK = 'link',
  SHORT_DROPDOWN = 'shortDropdown',
  FULLWIDTH_DROPDOWN = 'fullwidthDropdown',
}

type HeaderMenuBaseProps = {
  link: SanityLinkObjProps;
  menuType: EHeaderMenu;
  _key?: string;
};

export type HeaderMenuLinkProps = HeaderMenuBaseProps & {
  iconType?: EIcon;
};

export type HeaderMenuShortDropdownProps = HeaderMenuBaseProps & {
  links: SanityLinkObjProps[];
};

export type HeaderMenuFullwidthDropdownProps = HeaderMenuBaseProps & {
  subMenus: {
    link: SanityLinkObjProps;
    links: SanityLinkObjProps[];
    _key?: string;
  }[];
};
