import mixpanel from 'mixpanel-browser';
import { useEffect, useState } from 'react';

import { useContextProfile, useContextUser } from '@core/context';
import { EMixpanelEnv } from '@core/type';

export const useMixpanel = (
  enable: boolean,
  token: string,
  env: string,
  host: string,
  environment: EMixpanelEnv,
) => {
  const { isReady: isUserContextReady, isLogged } = useContextUser();
  const { userId } = useContextProfile();
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    if (isUserContextReady && enable) {
      mixpanel.init(token, { debug: ['development', 'preview', 'local'].includes(env) });
      setIsReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserContextReady]);

  useEffect(() => {
    if (isUserContextReady && enable && isLogged && userId) {
      mixpanel.identify(userId.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserContextReady, isLogged, userId]);

  return { enable, isReady, host, environment };
};
