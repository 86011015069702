import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type { BlogCategoriesResponse } from '@core/type/api';

import { Api } from '../constants/api';
import { configResponse, EConfigName } from '../helpers/config';

const getBlogCategories = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<BlogCategoriesResponse>> => {
  return axiosInstance.get(
    Api.content.BLOG_CATEGORIES,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class BlogCategoriesAPI {
  public static readonly getBlogCategories = getBlogCategories;
}
