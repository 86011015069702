import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type {
  CreateStockAlertEntity,
  CreateStockAlertResponse,
  PatchStockAlertEntity,
  StockAlertResponse,
} from '@core/type/api';

import { Api } from '../constants/api';
import { replaceIdUrl } from '../helpers/api';
import { configResponse, EConfigName } from '../helpers/config';

const getAlerts = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<StockAlertResponse>> => {
  return axiosInstance.get(
    Api.stockAlert.ALERTS,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const deleteAlert = async (
  axiosInstance: AxiosInstance,
  { language, alertId }: LanguageProps & { alertId: number },
): Promise<AxiosResponse<void>> => {
  return axiosInstance.delete(
    replaceIdUrl(Api.stockAlert.DELETE_ALERT, alertId, ':alertId'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const patchAlert = async (
  axiosInstance: AxiosInstance,
  { language, alertId, ...data }: LanguageProps & PatchStockAlertEntity & { alertId: number },
): Promise<AxiosResponse<void>> => {
  return axiosInstance.patch(
    replaceIdUrl(Api.stockAlert.UPDATE_ALERT, alertId, ':alertId'),
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const createAlert = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & CreateStockAlertEntity,
): Promise<AxiosResponse<CreateStockAlertResponse>> => {
  return axiosInstance.post(
    Api.stockAlert.ALERTS,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class StockAlertAPI {
  public static readonly getAlerts = getAlerts;
  public static readonly patchAlert = patchAlert;
  public static readonly deleteAlert = deleteAlert;
  public static readonly createAlert = createAlert;
}
