import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type { CurrencyRatesResponse } from '@core/type/api';

import { Api } from '../constants/api';
import { configResponse, EConfigName } from '../helpers/config';

const getCurrencyRates = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<CurrencyRatesResponse>> => {
  return axiosInstance.get(
    Api.currencyRates.CURRENCY_RATES,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class CurrencyRatesAPI {
  public static readonly getCurrencyRates = getCurrencyRates;
}
