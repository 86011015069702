import { ELanguageTags } from './language';
import { EUnit, Price, UnitType } from './unit';

export type WeightUnitType = keyof UnitType<EUnit.GRAM | EUnit.OUNCE | EUnit.KILOGRAM>;

export type SizeUnitType = keyof UnitType<EUnit.MILLIMETER>;

export enum EMetal {
  GOLD = 'XAU',
  SILVER = 'XAG',
  PLATINUM = 'XPT',
  PALLADIUM = 'XPD',
}

export enum EFullMetalName {
  GOLD = 'Gold',
  SILVER = 'Silver',
  PLATINUM = 'Platinum',
  PALLADIUM = 'Palladium',
}

export type MetalPrice = {
  ask?: Price;
  bid?: Price;
  deskask?: Price;
  deskbid?: Price;
  priceChange?: Price;
  isPositive: boolean;
  name?: string;
};

export type MetalsPrice = {
  [EMetal.GOLD]: MetalPrice;
  [EMetal.SILVER]: MetalPrice;
  [EMetal.PLATINUM]: MetalPrice;
  [EMetal.PALLADIUM]: MetalPrice;
};

export enum EMetalSlug {
  GOLD_EN = 'gold',
  GOLD_FR = 'or',
  GOLD_IT = 'oro',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  GOLD_DE = 'gold',
  SILVER_EN = 'silver',
  SILVER_FR = 'argent',
  SILVER_IT = 'argento',
  SILVER_DE = 'silber',
  PLATINUM_EN = 'platinum',
  PLATINUM_FR = 'platine',
  PLATINUM_IT = 'platino',
  PLATINUM_DE = 'platin',
  PALLADIUM_EN = 'palladium',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  PALLADIUM_FR = 'palladium',
  PALLADIUM_IT = 'palladio',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  PALLADIUM_DE = 'palladium',
  GOLD_PRICE_EN = 'gold-price',
  GOLD_PRICE_FR = 'cours-or',
  GOLD_PRICE_IT = 'quotazione-oro',
  GOLD_PRICE_DE = 'goldpreis',
  SILVER_PRICE_EN = 'silver-price',
  SILVER_PRICE_FR = 'cours-argent',
  SILVER_PRICE_IT = 'quotazione-argento',
  SILVER_PRICE_DE = 'silberpreis',
  PLATINUM_PRICE_EN = 'platinum-price',
  PLATINUM_PRICE_FR = 'cours-platine',
  PLATINUM_PRICE_IT = 'quotazione-platino',
  PLATINUM_PRICE_DE = 'platinpreis',
  PALLADIUM_PRICE_EN = 'palladium-price',
  PALLADIUM_PRICE_FR = 'cours-palladium',
  PALLADIUM_PRICE_IT = 'quotazione-palladio',
  PALLADIUM_PRICE_DE = 'palladiumpreis',
}

export type SlugToMetalMap = {
  [key in ELanguageTags]: Partial<{
    [key in EMetalSlug]: EMetal;
  }>;
};

export type MetalToSlugMap = {
  [key in ELanguageTags]: Partial<{
    [key in EMetal]: EMetalSlug;
  }>;
};
