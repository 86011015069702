import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { ReactNode } from 'react';
import { useState } from 'react';

export const ProviderReactQuery = ({
  children,
  staleTimeClient,
}: {
  children?: ReactNode;
  staleTimeClient?: number;
}) => {
  const [queryClient] = useState<QueryClient>(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: (err) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
            if ((err as any)?.config?.data?.hasToRedirect) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
              window.location.replace((err as any)?.config?.data?.hasToRedirect);
            }
          },
        }),
        defaultOptions: {
          queries: {
            staleTime: staleTimeClient,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: true,
            retry: false,
          },
        },
      }),
  );

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
