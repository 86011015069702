import { ProfileStorage } from '@core/storage';
import { EStorageType } from '@core/type';

const setSessionId = (id: string) => {
  ProfileStorage.setSessionIdStore(EStorageType.LOCAL_STORAGE, id);
};

const getSessionId = (): string =>
  ProfileStorage.getSessionIdStore(EStorageType.LOCAL_STORAGE) || '';

const removeSessionId = () => {
  ProfileStorage.removeSessionIdStore(EStorageType.LOCAL_STORAGE);
};

export class ProfileService {
  public static readonly setSessionId = setSessionId;
  public static readonly getSessionId = getSessionId;
  public static readonly removeSessionId = removeSessionId;
}
