import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconDeliver = ({ fill }: DynamicIconProps) => (
  <SVG height="30" width="30" viewBox="0 0 30 30" title="Deliver icon">
    <g fill="none" fillRule="evenodd">
      <path
        fill={fill}
        d="M16 13.538V21h7a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h7v-7.462l-1.828 1.828a1 1 0 0 1-1.415-1.414l3.536-3.536a1 1 0 0 1 1.414 0l3.536 3.536a1 1 0 1 1-1.415 1.414L16 13.538zM11.024 6A1.003 1.003 0 0 1 11 5.78v-1a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v1c0 .076-.008.15-.024.22H23a3 3 0 0 1 3 3v11a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V9a3 3 0 0 1 3-3h4.024zM2.006 26.5a1 1 0 0 1 0-2H28a1 1 0 0 1 0 2H2.006z"
      ></path>
    </g>
  </SVG>
);
