import { useEffect, useState } from 'react';

export function useScrollDirection(threshold: number) {
  const [direction, setDirection] = useState<{ isDown: boolean; isUp: boolean }>({
    isDown: false,
    isUp: false,
  });

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDir = () => {
      const scrollY = window.scrollY;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        return;
      }
      setDirection({ isDown: scrollY > lastScrollY, isUp: scrollY < lastScrollY });
      lastScrollY = Math.max(scrollY, 0);
    };

    const onScroll = () => {
      window.requestAnimationFrame(updateScrollDir);
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [threshold]);

  return direction;
}
