import { ELanguageTags, TranslatedUrl } from '@core/type';

export const referralGaUrl: TranslatedUrl = {
  [ELanguageTags.EN_US]: 'https://go.goldavenue.com/referral-program-customer',
  [ELanguageTags.DE_DE]: 'https://go.goldavenue.com/referral-programm-kunde',
  [ELanguageTags.FR_FR]: 'https://go.goldavenue.com/programme-parrainage',
  [ELanguageTags.IT_IT]: 'https://go.goldavenue.com/programma-referral',
};

export const AFFILIATION_DAYS = 30;
