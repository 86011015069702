import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import {
  ProductBrandFilterEntity,
  ProductCollectionFilterEntity,
  ProductPriceFilterEntity,
  ProductSelectionFilterEntity,
  ProductShopEntities,
  ProductTypeFilterEntity,
  ProductWeightFilterEntity,
  ShopSubcategoriesProductsRequest,
  ShopSubcategoriesProductsResponse,
  ShopSubcategoriesResponse,
} from '@core/type/api';

import { ShopAPI } from '../api/shop';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('ShopService', methodName, dto, apiCall, withContent);
};

const getFilterPrice = (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<ProductPriceFilterEntity> => {
  return log('getFilterPrice', { axiosInstance, language }, ShopAPI.getFilterPrice);
};

const getFilterWeight = (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<ProductWeightFilterEntity> => {
  return log('getFilterWeight', { axiosInstance, language }, ShopAPI.getFilterWeight);
};

const getFilterProductType = (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<ProductTypeFilterEntity> => {
  return log('getFilterProductType', { axiosInstance, language }, ShopAPI.getFilterProductType);
};

const getFilterCollections = (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<ProductCollectionFilterEntity> => {
  return log('getFilterCollections', { axiosInstance, language }, ShopAPI.getFilterCollections);
};

const getFilterBrands = (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<ProductBrandFilterEntity> => {
  return log('getFilterBrands', { axiosInstance, language }, ShopAPI.getFilterBrands);
};

const getFilterSelection = (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<ProductSelectionFilterEntity> => {
  return log('getFilterSelection', { axiosInstance, language }, ShopAPI.getFilterSelection);
};

const getProducts = (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<ProductShopEntities> => {
  return log('getProducts', { axiosInstance, language }, ShopAPI.getProducts);
};

const getSubcategories = (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<ShopSubcategoriesResponse> => {
  return log('getSubcategories', { axiosInstance, language }, ShopAPI.getSubcategories);
};

const getSubcategoriesProducts = (
  axiosInstance: AxiosInstance,
  dto: ShopSubcategoriesProductsRequest,
  language: ELanguageTags,
): Promise<ShopSubcategoriesProductsResponse> => {
  return log(
    'getSubcategoriesProducts',
    { axiosInstance, language, ...dto },
    ShopAPI.getSubcategoriesProducts,
  );
};

export class ShopService {
  public static readonly getFilterPrice = getFilterPrice;
  public static readonly getFilterWeight = getFilterWeight;
  public static readonly getFilterProductType = getFilterProductType;
  public static readonly getFilterCollections = getFilterCollections;
  public static readonly getFilterBrands = getFilterBrands;
  public static readonly getFilterSelection = getFilterSelection;
  public static readonly getProducts = getProducts;
  public static readonly getSubcategories = getSubcategories;
  public static readonly getSubcategoriesProducts = getSubcategoriesProducts;
}
