import Image from 'next/image';

import IconUPSSvg from './svgs/icon-ups.svg';

export const IconUPS = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconUPSSvg}
    alt="UPS icon"
    height={20}
    width={19}
  />
);
