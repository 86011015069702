import { Icon, LinkTranslated, Typography } from '@core/component';
import { usePageLoading } from '@core/hook';
import { EActionAttribute, EColor, EFontWeight, HeaderMenuLinkProps } from '@core/type';

import { MenuItemContainer } from './MenuItemContainer';
import { HoveredMenuHandler } from './interface-header-menu';
import { getHeaderLinkHref } from './utils';

export const MenuLink = ({
  link,
  iconType,
  onHover,
  index,
}: HeaderMenuLinkProps & {
  onHover: HoveredMenuHandler;
  index: number;
}) => {
  const { isLoading, startLoading } = usePageLoading();
  const href = getHeaderLinkHref(link);

  return (
    <MenuItemContainer onHover={onHover} shouldShowBorder={false} index={index}>
      <LinkTranslated
        as={EActionAttribute.LINK}
        display={'flex'}
        alignItems={'center'}
        href={href}
        isUnderlinedHover={false}
        lineHeight={'24px'}
        color={isLoading ? EColor.ACCENT : undefined}
        onClick={startLoading}
      >
        {iconType && <Icon type={iconType} flexShrink={0} marginRight={'8px'} />}
        <Typography fontWeight={EFontWeight.BOLD} whiteSpace={'nowrap'}>
          {link.text}
        </Typography>
      </LinkTranslated>
    </MenuItemContainer>
  );
};
