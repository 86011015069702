import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconSearch = ({ stroke }: DynamicIconProps) => (
  <SVG height={24} width={24} viewBox="0 0 24 24" title="Search icon">
    <path
      d="M14.5909 15.3409C17.3246 12.6072 17.3246 8.17504 14.5909 5.44137C11.8573 2.7077 7.42511 2.7077 4.69144 5.44137C1.95777 8.17504 1.95777 12.6072 4.69144 15.3409C7.42511 18.0745 11.8573 18.0745 14.5909 15.3409ZM14.5909 15.3409L21.3085 22.0584"
      stroke={stroke}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    ></path>
  </SVG>
);
