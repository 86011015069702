import { themeContainerMaxWidth } from '@core/constant';
import {
  EFontWeight,
  ESize,
  ETypographyVariant,
  ThemeDevice,
  ThemeDeviceReturn,
  ThemeStructure,
  ThemeTypography,
} from '@core/type';

import { themeSM } from './theme-sm';

const themeStructureXS = (baseTheme: ThemeDevice): ThemeStructure => ({
  ...baseTheme.structure,
  container: {
    ...baseTheme.structure.container,
    maxWidth: themeContainerMaxWidth[ESize.XS],
  },
  cartPopup: {
    wrapper: {
      maxWidth: 'calc(100% - 60px)',
      margin: '0 auto',
    },
  },
  paper: {
    ...baseTheme.structure.paper,
    form: {
      ...baseTheme.structure.paper.form,
      maxWidth: '410px',
      padding: '24px 0',
    },
  },
  footer: {
    ...baseTheme.structure.footer,
    subscribe: {
      ...baseTheme.structure.footer.subscribe,
      padding: '16px 0px',
      flexDirection: 'column',
      height: 'inherit',
    },
  },
  content: {
    title: {
      fontSize: '24px',
    },
  },
  autocomplete: {
    inputWidth: '100%',
    autocompleteWidth: '100%',
  },
  header: {
    ...baseTheme.structure.header,
    padding: '20px 0 10px 0',
    logoHeight: '30px',
    metals: { padding: '4px 8px' },
    minWidthSearchButton: '30px',
  },
  productCard: {
    ...baseTheme.structure.productCard,
    statusLabel: {
      ...baseTheme.structure.productCard.statusLabel,
      container: {
        ...baseTheme.structure.productCard.statusLabel.container,
      },
      sizes: {
        [ESize.MD]: {
          ...baseTheme.structure.productCard.statusLabel.sizes[ESize.MD],
          fontSize: '10px',
          padding: '8px',
        },
        [ESize.LG]: {
          ...baseTheme.structure.productCard.statusLabel.sizes[ESize.LG],
          fontSize: '10px',
        },
      },
    },
  },
});

const themeTypographyXS = (baseTheme: ThemeDevice): ThemeTypography => ({
  ...baseTheme.typography,
  [ETypographyVariant.H1]: {
    fontSize: '24px',
    lineHeight: '1.34',
    fontWeight: EFontWeight.BOLD,
  },
  header: {
    ...baseTheme.typography.header,
    metals: { fontSize: '14px', lineHeight: '16px' },
  },
});

export const themeXS: ThemeDeviceReturn = (baseTheme: ThemeDevice = themeSM()): ThemeDevice => ({
  typography: themeTypographyXS(baseTheme),
  structure: themeStructureXS(baseTheme),
});
