import { SVG } from '../SVG';

export const IconLinkedInSquare = () => (
  <SVG height={24} width={24} viewBox="0 0 24 24" title="LinkedIn icon">
    <path
      fill="#0077B5"
      d="M6 0h12a6 6 0 016 6v12a6 6 0 01-6 6H6a6 6 0 01-6-6V6a6 6 0 016-6zm2.571 18V9.643H6V18h2.571zM6 7.286c0 .705.543 1.277 1.213 1.277a1.2 1.2 0 001.171-.593c.25-.418.25-.95 0-1.369a1.2 1.2 0 00-1.171-.593C6.543 6.008 6 6.58 6 7.286zM18 18v-4.804c0-2.57-1.357-3.766-3.228-3.766a2.813 2.813 0 00-2.536 1.392V9.644H9.429v8.347h2.807v-4.637a1.84 1.84 0 01.093-.677 1.543 1.543 0 011.441-1.011c1.02 0 1.423.732 1.423 1.854V18H18z"
    />
  </SVG>
);
