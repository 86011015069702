import { createGlobalStyle, css } from 'styled-components';

import { EColor, ThemeProps } from '@core/type';
import { getPaletteHandlers } from '@core/util';

export const GlobalStyle = createGlobalStyle(({ theme }: { theme: ThemeProps }) => {
  const {
    palette,
    typography: {
      font,
      fontWeight: fontWeightVariant,
      body2: { fontSize, lineHeight, fontWeight },
    },
  } = theme;
  const { getColor, getTextColor, getBackgroundColor } = getPaletteHandlers(palette);

  const thumbBorderColor = getColor({
    commonType: EColor.WHITE,
    intensity: EColor.R900,
  });
  const thumbBackgroundColor = getColor({
    commonType: EColor.GRAY,
    intensity: EColor.A200,
  });

  const secondaryColor = getColor({
    commonType: EColor.BLUE,
    intensity: EColor.A300,
  });

  return css`
    * {
      font: inherit;
    }

    *:not(html, body, .default-scroll-bar) {
      /* Works on Firefox */
      scrollbar-width: thin;
      scrollbar-color: ${thumbBackgroundColor} ${thumbBorderColor};

      /* Works on Chrome, Edge, and Safari */

      /* stylelint-disable-next-line property-no-vendor-prefix */
      &::-webkit-scrollbar {
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -webkit-appearance: none;
      }

      /* stylelint-disable-next-line property-no-vendor-prefix */
      &::-webkit-scrollbar:vertical {
        width: 8px;
      }

      /* stylelint-disable-next-line property-no-vendor-prefix */
      &::-webkit-scrollbar:horizontal {
        height: 8px;
      }

      /* stylelint-disable-next-line property-no-vendor-prefix */
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid ${thumbBorderColor};
        background-color: ${thumbBackgroundColor};
      }

      /* stylelint-disable-next-line property-no-vendor-prefix */
      &::-webkit-scrollbar-track {
        border-radius: 8px;
        background-color: ${thumbBorderColor};
      }
    }

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    main,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      padding: 0;
      margin: 0;
      border: 0;
      vertical-align: baseline;
      box-sizing: border-box;
    }

    body {
      background: ${getBackgroundColor({
        semanticType: EColor.NEUTRAL,
        variant: EColor.LIGHT,
      })};
      color: ${getTextColor(EColor.PRIMARY)};
      font-family: '${font}';
      font-style: normal;
      font-size: ${fontSize};
      line-height: ${lineHeight};
      font-weight: ${fontWeightVariant[fontWeight]};
      -webkit-tap-highlight-color: transparent;
    }

    #__next {
      min-height: 100vh;
      margin: 0;
      display: grid;
      grid-template-rows: auto 1fr auto;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }

    blockquote,
    q {
      quotes: none;
    }

    blockquote:before,
    blockquote:after,
    q:before,
    q:after {
      content: '';
      content: none;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    img {
      border-style: none;
    }

    .grecaptcha-badge {
      visibility: hidden;
    }

    progress {
      appearance: none;
      background-color: rgba(0, 0, 0, 0);
      color: ${secondaryColor};
    }
    progress::-webkit-progress-bar {
      background-color: rgba(0, 0, 0, 0);
    }
    progress::-webkit-progress-value {
      background-color: ${secondaryColor};
    }
    progress::-moz-progress-bar {
      background-color: ${secondaryColor};
    }
  `;
});
