import { useQuery } from '@tanstack/react-query';

import { useContextRouting, useContextUtil } from '@core/context';
import { useLanguage } from '@core/hook';
import { formatSanityField, getSanityQuery, getVariation, sanityFetch } from '@core/sanity/utils';
import { EQueryKey } from '@core/type/api';
import { ESanityField, SanityPageResponse } from '@core/type/sanity';

export const useSanityData = () => {
  const { clientIp } = useContextUtil();
  const { query, keyRoute } = useContextRouting();
  const { language } = useLanguage();

  const { data } = useQuery<unknown, unknown, SanityPageResponse>({
    queryKey: [EQueryKey.CMS, keyRoute, query, language],
    queryFn: () =>
      sanityFetch({
        query: getSanityQuery(keyRoute, query, language),
      }),
  });

  const abTestKey = formatSanityField(data?.abTest?.key);
  const { data: shouldUseVariation } = useQuery<unknown, unknown, boolean>({
    queryKey: [EQueryKey.LAUNCHDARKLY, abTestKey, keyRoute, clientIp],
    queryFn: () => getVariation(abTestKey, clientIp),
    enabled: !!abTestKey && query[ESanityField.AB_TEST] === undefined,
  });

  return {
    cmsResult: data,
    shouldUseVariation,
  };
};
