import Image from 'next/image';

import IconThumbsUpSvg from './svgs/thumbs-up.svg';

export const IconThumbUp = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconThumbsUpSvg}
    alt="Thumb up icon"
    width={24}
    height={24}
  />
);
