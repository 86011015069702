import { FC, memo } from 'react';
import { useTheme } from 'styled-components';

import { useContextSelect } from '@core/context';
import { SelectSize, ThemeProps } from '@core/type';

import { Box } from '../layout/Box';
import { BoxProps } from '../layout/interface-layout';
import { SelectBaseWrapperProps } from './interface-select';

export const StyledSelectBaseWrapper = ({
  children,
  size,
  disabled,
  ...restProps
}: BoxProps & {
  size: SelectSize;
  disabled?: boolean;
}) => {
  const {
    structure: {
      select: {
        wrapper: { size: sizeVariant },
      },
    },
    typography: {
      select: { size: sizeTypography },
    },
  } = useTheme() as ThemeProps;

  return (
    <Box
      position={'relative'}
      display={'flex'}
      fontSize={sizeTypography[size]?.fontSize}
      width={'100%'}
      cursor={disabled ? 'default' : 'pointer'}
      alignItems={'center'}
      justifyContent={'space-between'}
      role={'button'}
      height={sizeVariant[size]?.height}
      {...restProps}
    >
      {children}
    </Box>
  );
};

export const SelectBaseWrapper: FC<SelectBaseWrapperProps> = memo(
  ({ children, padding, size, gap, backgroundColor, disabled, borderRadius }) => {
    const { setIsOpen, isOpen } = useContextSelect();

    return (
      <StyledSelectBaseWrapper
        onClick={() => !disabled && (isOpen ? setIsOpen(false) : setIsOpen(true))}
        size={size}
        padding={padding}
        gap={gap}
        backgroundColor={backgroundColor}
        disabled={disabled}
        borderRadius={borderRadius}
      >
        {children}
      </StyledSelectBaseWrapper>
    );
  },
);

SelectBaseWrapper.displayName = 'SelectBaseWrapper';
