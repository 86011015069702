import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags, LanguageProps } from '@core/type';
import type {
  KycProfileEntity,
  StepLocationUploadFileEntity,
  KycQuestionsEntity,
  KycStepResponse,
} from '@core/type/api';
import { KycStepRequest } from '@core/type/api';

import { Api } from '../constants/api';
import { replaceIdUrl } from '../helpers/api';
import { configResponse, EConfigName } from '../helpers/config';

const getQuestionList = (
  axiosInstance: AxiosInstance,
  { language }: { language: ELanguageTags },
): Promise<AxiosResponse<KycQuestionsEntity>> => {
  return axiosInstance.get(Api.kyc.KYC, configResponse({ 'Accept-Language': language }));
};

const getUserSteps = (
  axiosInstance: AxiosInstance,
  { language }: { language: ELanguageTags },
): Promise<AxiosResponse<KycProfileEntity>> => {
  return axiosInstance.get(Api.kyc.USER_KYC, configResponse({ 'Accept-Language': language }));
};

const putKycStep = (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & KycStepRequest,
): Promise<AxiosResponse<KycStepResponse>> => {
  return axiosInstance.put(
    replaceIdUrl(Api.kyc.STEP, data.step, ':slug'),
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const patchKycStep = (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & KycStepRequest,
): Promise<AxiosResponse<KycStepResponse>> => {
  return axiosInstance.patch(
    replaceIdUrl(Api.kyc.STEP, data.step, ':slug'),
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getUtilityBills = (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<StepLocationUploadFileEntity>> => {
  return axiosInstance.get(
    Api.kyc.UTILITY_BILLS,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const deleteUtilityBills = (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.delete(
    Api.kyc.UTILITY_BILLS,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const postUtilityBills = (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: FormData,
): Promise<AxiosResponse<StepLocationUploadFileEntity>> => {
  return axiosInstance.post(Api.kyc.UTILITY_BILLS, data, {
    ...configResponse({
      [EConfigName.CONTENT_TYPE]: 'multipart/form-data',
      [EConfigName.LANGUAGE]: language,
    }),
    transformRequest: () => data,
  });
};

export class KycAPI {
  public static readonly getQuestionList = getQuestionList;
  public static readonly getUserSteps = getUserSteps;
  public static readonly putKycStep = putKycStep;
  public static readonly patchKycStep = patchKycStep;
  public static readonly getUtilityBills = getUtilityBills;
  public static readonly postUtilityBills = postUtilityBills;
  public static readonly deleteUtilityBills = deleteUtilityBills;
}
