import { getTranslationWithOrientation } from '@core/util';

import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconDrawer = ({ fill, orientation, yTranslation }: DynamicIconProps) => (
  <SVG
    height={24}
    width={24}
    viewBox="0 0 24 24"
    title="Drawer icon"
    transform={getTranslationWithOrientation(orientation, yTranslation)}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 3.79997C1 3.4686 1.26863 3.19997 1.6 3.19997H17.4C17.7314 3.19997 18 3.4686 18 3.79997C18 4.13134 17.7314 4.39997 17.4 4.39997H1.6C1.26863 4.39997 1 4.13134 1 3.79997ZM1 19.8C1 19.4686 1.26863 19.2 1.6 19.2H17.4C17.7314 19.2 18 19.4686 18 19.8C18 20.1313 17.7314 20.4 17.4 20.4H1.6C1.26863 20.4 1 20.1313 1 19.8ZM22.4 12.6C22.7314 12.6 23 12.3314 23 12C23 11.6687 22.7314 11.4 22.4 11.4L1.6 11.4C1.26863 11.4 1 11.6687 1 12C1 12.3314 1.26863 12.6 1.6 12.6L22.4 12.6Z"
      fill={fill}
    />
  </SVG>
);
