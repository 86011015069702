import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type {
  BlogArticlesEntity,
  EFAQCategory,
  FAQArticleEntity,
  FAQCategoriesResponse,
  FAQCategoryEntity,
  FAQSearchAutocompleteEntity,
  PagedSearchRequest,
} from '@core/type/api';

import { Api } from '../constants/api';
import { replaceIdUrl } from '../helpers/api';
import { configResponse, EConfigName } from '../helpers/config';
import { getPagedSearchQuery, getSearchQuery } from '../helpers/paged';

const getFAQArticle = async (
  axiosInstance: AxiosInstance,
  { slug, language }: LanguageProps & { slug: string },
): Promise<AxiosResponse<FAQArticleEntity>> =>
  axiosInstance.get(
    replaceIdUrl(Api.faq.ARTICLE, slug, ':slug'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const getFAQCategory = async (
  axiosInstance: AxiosInstance,
  { slug, language }: LanguageProps & { slug: EFAQCategory },
): Promise<AxiosResponse<FAQCategoryEntity>> =>
  axiosInstance.get(
    replaceIdUrl(Api.faq.CATEGORY, slug as string, ':slug'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const getFAQCategories = (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<FAQCategoriesResponse>> => {
  return axiosInstance.get(
    Api.faq.CATEGORIES,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const faqSearchAutocomplete = (
  axiosInstance: AxiosInstance,
  { q, language }: LanguageProps & { q?: string },
): Promise<AxiosResponse<FAQSearchAutocompleteEntity>> => {
  return axiosInstance.get(
    `${Api.faq.SEARCH_AUTOCOMPLETE}${getSearchQuery(q)}`,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const faqSearch = (
  axiosInstance: AxiosInstance,
  { language, ...pagedRequest }: LanguageProps & PagedSearchRequest,
): Promise<AxiosResponse<BlogArticlesEntity>> => {
  return axiosInstance.get(
    `${Api.faq.SEARCH}${getPagedSearchQuery(pagedRequest)}`,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class FAQApi {
  public static readonly getFAQArticle = getFAQArticle;
  public static readonly getFAQCategory = getFAQCategory;
  public static readonly getFAQCategories = getFAQCategories;
  public static readonly faqSearch = faqSearch;
  public static readonly faqSearchAutocomplete = faqSearchAutocomplete;
}
