import type { AxiosInstance, AxiosResponse } from 'axios';

import { Logger } from '@core/logger';

export const logService = async <T, R>(
  serviceName: string,
  methodName: string,
  { axiosInstance, ...dto }: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  try {
    withContent &&
      Logger.logInfo(`Call ${serviceName}::${methodName} with <${JSON.stringify(dto)}>`, {
        library: 'core-api',
      });
    const { data } = await apiCall(axiosInstance, dto as T);
    Logger.logDebug(`${serviceName}::${methodName} successfully`, { library: 'core-api' });
    return data;
  } catch (e) {
    Logger.logDebug(`${serviceName}::${methodName} error`, { library: 'core-api' });
    Logger.logInfo(e, { library: 'core-api', isException: true });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    let error = e;
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      error = await e;
    } catch (_) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      error = e;
    }
    throw error;
  }
};

export const addParameters = (url: string, parameters: Record<string, string>) => {
  if (Object.keys(parameters).length !== 0) {
    const searchParams = new URLSearchParams(parameters);
    return searchParams ? `${url}?${searchParams.toString()}` : url;
  }
  return url;
};
