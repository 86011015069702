import Image from 'next/image';

import IconReceiveSvg from './svgs/icon-receive.svg';

export const IconReceive = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconReceiveSvg}
    alt="Receive icon"
    height={64}
    width={64}
  />
);
