import styled, { css } from 'styled-components';

import type { WithThemeProps } from '@core/type';
import { EColor } from '@core/type';
import { getPaletteHandlers } from '@core/util';

import { Box } from '../layout/Box';
import { BoxProps } from '../layout/interface-layout';
import { InputBackground } from './InputBackground';
import { InputBase } from './InputBase';
import { InputBorder } from './InputBorder';
import { InputLabel } from './InputLabel';

export const InputWrapper = styled(Box)<BoxProps>(({
  theme: {
    palette,
    structure: {
      input: {
        label: { active: labelActive, required: labelRequired },
      },
    },
  },
}: WithThemeProps) => {
  const { getColor, getTextColor, getBackgroundColor } = getPaletteHandlers(palette);

  return css`
    ${InputBase}:disabled ~ ${InputBackground},
      ${InputBase}:read-only ~ ${InputBackground} {
      background-color: ${getBackgroundColor(EColor.SECONDARY)};
    }

    ${InputBase}:focus:not(:read-only) ~ ${InputBorder} {
      border-color: ${getColor(EColor.SECONDARY)};
    }

    ${InputBase}:focus:not(:read-only) ~ ${InputLabel} {
      ${labelActive};
    }

    ${InputBase}:required ~ ${InputLabel}:after {
      ${labelRequired};
      color: ${getTextColor(EColor.DANGER)};
    }
  `;
});
