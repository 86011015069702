import { createContext } from 'react';

import type { HookStore, UserHook, UserState } from '@core/type/context';

import { useFnContext, useStoreContext } from '../util';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const userContext = createContext<HookStore<any, UserState, UserHook>>(null);

export const useContextUser = () => {
  return useFnContext(userContext);
};

export function useStoreUser<Selector>(selector: keyof UserState) {
  return useStoreContext<Selector, UserState, UserHook>(selector, userContext);
}
