import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconIncrease = ({ fill }: DynamicIconProps) => (
  <SVG height="24" width="24" viewBox="0 0 24 24" title="Increase icon">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8601 12.1316V7.37039L8.53956 15.691C8.30525 15.9253 7.92535 15.9253 7.69104 15.691C7.45672 15.4567 7.45672 15.0768 7.69104 14.8424L16.0566 6.4769L11.2055 6.4769C10.8741 6.4769 10.6055 6.20827 10.6055 5.8769C10.6055 5.54553 10.8741 5.2769 11.2055 5.2769H17.4601H18.0601V5.8769L18.0601 12.1316C18.0601 12.463 17.7915 12.7316 17.4601 12.7316C17.1288 12.7316 16.8601 12.463 16.8601 12.1316ZM4.6 18.55C4.26863 18.55 4 18.8187 4 19.15C4 19.4814 4.26863 19.75 4.6 19.75H19.4C19.7314 19.75 20 19.4814 20 19.15C20 18.8187 19.7314 18.55 19.4 18.55H4.6Z"
      fill={fill}
    ></path>
  </SVG>
);
