import { createContext } from 'react';

import type { HookStore, MediaQueryHook, MediaQueryState } from '@core/type/context';

import { useFnContext, useStoreContext } from '../util';

export const mediaQueryContext =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createContext<HookStore<any, MediaQueryState, MediaQueryHook>>(null);

export const useContextMediaQuery = () => {
  return useFnContext(mediaQueryContext);
};

export function useStoreMediaQuery<Selector>(selector: keyof MediaQueryState) {
  return useStoreContext<Selector, MediaQueryState, MediaQueryHook>(selector, mediaQueryContext);
}
