import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconDownload = ({ fill }: DynamicIconProps) => (
  <SVG height={24} width={24} viewBox="0 0 24 24" title="Download icon">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4543 3.72476V15.5556L8.02387 12.1252C7.78956 11.8909 7.40966 11.8909 7.17535 12.1252C6.94103 12.3595 6.94103 12.7394 7.17535 12.9737L11.5981 17.3965L12.0223 17.8207L12.4466 17.3965L16.8693 12.9737C17.1037 12.7394 17.1037 12.3595 16.8693 12.1252C16.635 11.8909 16.2551 11.8909 16.0208 12.1252L12.6543 15.4917V3.72476C12.6543 3.39338 12.3857 3.12476 12.0543 3.12476C11.7229 3.12476 11.4543 3.39338 11.4543 3.72476ZM4.6 20.8C4.26863 20.8 4 21.0686 4 21.4C4 21.7314 4.26863 22 4.6 22H19.4C19.7314 22 20 21.7314 20 21.4C20 21.0686 19.7314 20.8 19.4 20.8H4.6Z"
      fill={fill}
    />
  </SVG>
);
