import { FC, useMemo } from 'react';
import styled, { css, useTheme } from 'styled-components';

import { EColor, EIcon, ESize, ThemeProps } from '@core/type';
import { getPaletteHandlers } from '@core/util';

import { spinAnimation } from '../animation';
import { iconSize } from '../constants';
import { Box } from '../layout/Box';
import { BoxProps } from '../layout/interface-layout';
import { Icon2StepAuth1 } from './Icon2StepAuth1';
import { Icon2StepAuth2 } from './Icon2StepAuth2';
import { Icon2StepAuth3 } from './Icon2StepAuth3';
import { Icon60YearsLogo } from './Icon60YearsLogo';
import { IconAddress } from './IconAddress';
import { IconAlert } from './IconAlert';
import { IconAlertCircle } from './IconAlertCircle';
import { IconAlertFilled } from './IconAlertFilled';
import { IconAlertRounded } from './IconAlertRounded';
import { IconAngle } from './IconAngle';
import { IconAngleDouble } from './IconAngleDouble';
import { IconAppStore } from './IconAppStore';
import { IconArrowBig } from './IconArrowBig';
import { IconArrowDown } from './IconArrowDown';
import { IconArrowLong } from './IconArrowLong';
import { IconArrowRight } from './IconArrowRight';
import { IconArrowTurned } from './IconArrowTurned';
import { IconAutoSavings } from './IconAutoSavings';
import { IconBestValue } from './IconBestValue';
import { IconBin } from './IconBin';
import { IconCalendar } from './IconCalendar';
import { IconCart } from './IconCart';
import { IconCartPurchasingLimit } from './IconCartPurchasingLimit';
import { IconCartRepeat } from './IconCartRepeat';
import { IconCartRepeatRounded } from './IconCartRepeatRounded';
import { IconCase } from './IconCase';
import { IconChat } from './IconChat';
import { IconChatWithText } from './IconChatWithText';
import { IconCheck } from './IconCheck';
import { IconCircleArrow } from './IconCircleArrow';
import { IconCircleCross } from './IconCircleCross';
import { IconCirclePlus } from './IconCirclePlus';
import { IconCircularArrow } from './IconCircularArrow';
import { IconClock } from './IconClock';
import { IconClockWithArrow } from './IconClockWithArrow';
import { IconCoinFocus } from './IconCoinFocus';
import { IconContact } from './IconContact';
import { IconCopy } from './IconCopy';
import { IconCross } from './IconCross';
import { IconDashed } from './IconDashed';
import { IconDeals } from './IconDeals';
import { IconDecrease } from './IconDecrease';
import { IconDeliver } from './IconDeliver';
import { IconDeliveryVehicle } from './IconDeliveryVehicle';
import { IconDiscount } from './IconDiscount';
import { IconDocument } from './IconDocument';
import { IconDownload } from './IconDownload';
import { IconDrawer } from './IconDrawer';
import { IconEdit } from './IconEdit';
import { IconEye } from './IconEye';
import { IconEyeClosed } from './IconEyeClosed';
import { IconFilledHeart } from './IconFilledHeart';
import { IconFlexibleChoice } from './IconFlexibleChoice';
import { IconGALogoWithBackground } from './IconGALogoWithBackground';
import { IconGaLogo } from './IconGaLogo';
import { IconGaLogoAlert } from './IconGaLogoAlert';
import { IconGaLogoFull } from './IconGaLogoFull';
import { IconGaLogoFullColorFilled } from './IconGaLogoFullColorFilled';
import { IconGaLogoWithPAMP } from './IconGaLogoWithPAMP';
import { IconGift } from './IconGift';
import { IconGooglePlay } from './IconGooglePlay';
import { IconGraph } from './IconGraph';
import { IconHeaps } from './IconHeaps';
import { IconHeart } from './IconHeart';
import { IconHeartBig } from './IconHeartBig';
import { IconHome } from './IconHome';
import { IconIncluded } from './IconIncluded';
import { IconIncrease } from './IconIncrease';
import { IconInfo } from './IconInfo';
import { IconIpCheck } from './IconIpCheck';
import { IconLBMALogo } from './IconLBMALogo';
import { IconLivePrice } from './IconLivePrice';
import { IconLock } from './IconLock';
import { IconMarketGraph } from './IconMarketGraph';
import { IconMazarsLogo } from './IconMazarsLogo';
import { IconMinus } from './IconMinus';
import { IconPDF } from './IconPDF';
import { IconPaperPlane } from './IconPaperPlane';
import { IconPause } from './IconPause';
import { IconPayment } from './IconPayment';
import { IconPhone } from './IconPhone';
import { IconPhone2 } from './IconPhone2';
import { IconPlay } from './IconPlay';
import { IconPlayCircled } from './IconPlayCircled';
import { IconPlus } from './IconPlus';
import { IconPlusRounded } from './IconPlusRounded';
import { IconReceive } from './IconReceive';
import { IconRedeemProducts } from './IconRedeemProducts';
import { IconSMS } from './IconSMS';
import { IconSearch } from './IconSearch';
import { IconSecureLogo } from './IconSecureLogo';
import { IconSell } from './IconSell';
import { IconSettings } from './IconSettings';
import { IconShield } from './IconShield';
import { IconSmartphone } from './IconSmartphone';
import { IconSmartphoneText } from './IconSmartphoneText';
import { IconSmartphoneValid } from './IconSmartphoneValid';
import { IconSmsValid } from './IconSmsValid';
import { IconSolid } from './IconSolid';
import { IconSpinner } from './IconSpinner';
import { IconStar } from './IconStar';
import { IconStorageFees } from './IconStorageFees';
import { IconSuccess } from './IconSuccess';
import { IconSuccessFull } from './IconSuccessFull';
import { IconSwissLogo } from './IconSwissLogo';
import { IconSwissServers } from './IconSwissServers';
import { IconTag } from './IconTag';
import { IconTagCross } from './IconTagCross';
import { IconThumbUp } from './IconThumbUp';
import { IconTriangle } from './IconTriangle';
import { IconTrustpilotLogo } from './IconTrustpilotLogo';
import { IconTwoSteps } from './IconTwoSteps';
import { IconUnion } from './IconUnion';
import { IconUser } from './IconUser';
import { IconZeroPercent } from './IconZeroPercent';
import { IconCHF, IconEUR, IconGBP, IconUSD } from './currency';
import { IconHelveticor } from './delivery/IconHelveticor';
import { IconHelveticorSecondary } from './delivery/IconHelveticorSecondary';
import { IconLoomis } from './delivery/IconLoomis';
import { IconSwissPost } from './delivery/IconSwissPost';
import { IconUPS } from './delivery/IconUPS';
import { DynamicIconProps, IconProps, ThemeIcon } from './interface-icon';
import { IconBankTransfer } from './payment/IconBankTransfer';
import { IconMastercard } from './payment/IconMastercard';
import { IconVisa } from './payment/IconVisa';
import { IconEmail } from './social/IconEmail';
import { IconFacebook } from './social/IconFacebook';
import { IconInstagram } from './social/IconInstagram';
import { IconLinkedIn } from './social/IconLinkedIn';
import { IconLinkedInSquare } from './social/IconLinkedInSquare';
import { IconTwitter } from './social/IconTwitter';
import { IconWhatsApp } from './social/IconWhatsApp';
import { IconYoutube } from './social/IconYoutube';

const iconTypes: ThemeIcon = {
  [EIcon.COPY]: IconCopy,
  [EIcon.CROSS]: IconCross,
  [EIcon.BIN]: IconBin,
  [EIcon.GA_LOGO]: IconGaLogo,
  [EIcon.GA_LOGO_ALERT]: IconGaLogoAlert,
  [EIcon.GA_LOGO_FULL]: IconGaLogoFull,
  [EIcon.GA_LOGO_FULL_COLOR_FILLED]: IconGaLogoFullColorFilled,
  [EIcon.GA_LOGO_WITH_PAMP]: IconGaLogoWithPAMP,
  [EIcon.GA_LOGO_WITH_BACKGROUND]: IconGALogoWithBackground,
  [EIcon.CART]: IconCart,
  [EIcon.CART_REPEAT]: IconCartRepeat,
  [EIcon.DEALS]: IconDeals,
  [EIcon.ALERT]: IconAlert,
  [EIcon.ALERT_FILLED]: IconAlertFilled,
  [EIcon.ARROW_RIGHT]: IconArrowRight,
  [EIcon.ARROW_DOWN]: IconArrowDown,
  [EIcon.ARROW_BIG]: IconArrowBig,
  [EIcon.LOCK]: IconLock,
  [EIcon.LOGO_60_YEARS]: Icon60YearsLogo,
  [EIcon.LOGO_SECURE]: IconSecureLogo,
  [EIcon.LOGO_SWISS]: IconSwissLogo,
  [EIcon.LOGO_LBMA]: IconLBMALogo,
  [EIcon.LOGO_MAZARS]: IconMazarsLogo,
  [EIcon.ANGLE]: IconAngle,
  [EIcon.ANGLE_DOUBLE]: IconAngleDouble,
  [EIcon.MARKET_GRAPH]: IconMarketGraph,
  [EIcon.EYE]: IconEye,
  [EIcon.EYE_CLOSED]: IconEyeClosed,
  [EIcon.SEARCH]: IconSearch,
  [EIcon.DRAWER]: IconDrawer,
  [EIcon.TRIANGLE]: IconTriangle,
  [EIcon.PAPER_PLANE]: IconPaperPlane,
  [EIcon.PLUS]: IconPlus,
  [EIcon.MINUS]: IconMinus,
  [EIcon.CHECK]: IconCheck,
  [EIcon.DISCOUNT]: IconDiscount,
  [EIcon.PHONE]: IconPhone,
  [EIcon.PHONE2]: IconPhone2,
  [EIcon.CALENDAR]: IconCalendar,
  [EIcon.CLOCK]: IconClock,
  [EIcon.CLOCK_WITH_ARROW]: IconClockWithArrow,
  [EIcon.USER]: IconUser,
  [EIcon.BANK_TRANSFER]: IconBankTransfer,
  [EIcon.MASTERCARD]: IconMastercard,
  [EIcon.VISA]: IconVisa,
  [EIcon.UPS]: IconUPS,
  [EIcon.HELVETICOR]: IconHelveticor,
  [EIcon.HELVETICOR_SECONDARY]: IconHelveticorSecondary,
  [EIcon.LOOMIS]: IconLoomis,
  [EIcon.SWISS_POST]: IconSwissPost,
  [EIcon.FACEBOOK]: IconFacebook,
  [EIcon.INSTAGRAM]: IconInstagram,
  [EIcon.LINKED_IN]: IconLinkedIn,
  [EIcon.LINKED_IN_SQUARE]: IconLinkedInSquare,
  [EIcon.TWITTER]: IconTwitter,
  [EIcon.YOUTUBE]: IconYoutube,
  [EIcon.UNION]: IconUnion,
  [EIcon.USD]: IconUSD,
  [EIcon.EUR]: IconEUR,
  [EIcon.CHF]: IconCHF,
  [EIcon.GBP]: IconGBP,
  [EIcon.HEART]: IconHeart,
  [EIcon.HEART_BIG]: IconHeartBig,
  [EIcon.FILLED_HEART]: IconFilledHeart,
  [EIcon.PAUSE]: IconPause,
  [EIcon.PLAY]: IconPlay,
  [EIcon.PLAY_CIRCLED]: IconPlayCircled,
  [EIcon.INFO]: IconInfo,
  [EIcon.TRUSTPILOT]: IconTrustpilotLogo,
  [EIcon.CASE]: IconCase,
  [EIcon.SMARTPHONE_TEXT]: IconSmartphoneText,
  [EIcon.CIRCLE_PLUS]: IconCirclePlus,
  [EIcon.CHAT]: IconChat,
  [EIcon.CHAT_WITH_TEXT]: IconChatWithText,
  [EIcon.INCLUDED]: IconIncluded,
  [EIcon.SELL]: IconSell,
  [EIcon.RECEIVE]: IconReceive,
  [EIcon.ARROW_LONG]: IconArrowLong,
  [EIcon.ADDRESS]: IconAddress,
  [EIcon.DOWNLOAD]: IconDownload,
  [EIcon.SUCCESS]: IconSuccess,
  [EIcon.SUCCESS_FULL]: IconSuccessFull,
  [EIcon.PDF]: IconPDF,
  [EIcon.HEAPS]: IconHeaps,
  [EIcon.ZERO_PERCENT]: IconZeroPercent,
  [EIcon.THUMB_UP]: IconThumbUp,
  [EIcon.REDEEM_PRODUCTS]: IconRedeemProducts,
  [EIcon.SWISS_SERVERS]: IconSwissServers,
  [EIcon.TWO_STEPS]: IconTwoSteps,
  [EIcon.IP_CHECK]: IconIpCheck,
  [EIcon.LIVE_PRICE]: IconLivePrice,
  [EIcon.TAG]: IconTag,
  [EIcon.CIRCLE_CROSS]: IconCircleCross,
  [EIcon.CIRCLE_ARROW]: IconCircleArrow,
  [EIcon.CIRCULAR_ARROW]: IconCircularArrow,
  [EIcon.SHIELD]: IconShield,
  [EIcon.AUTH_2STEP_1]: Icon2StepAuth1,
  [EIcon.AUTH_2STEP_2]: Icon2StepAuth2,
  [EIcon.AUTH_2STEP_3]: Icon2StepAuth3,
  [EIcon.SMARTPHONE]: IconSmartphone,
  [EIcon.SMS]: IconSMS,
  [EIcon.SMS_VALID]: IconSmsValid,
  [EIcon.EDIT]: IconEdit,
  [EIcon.HOME]: IconHome,
  [EIcon.GIFT]: IconGift,
  [EIcon.SMARTPHONE_VALID]: IconSmartphoneValid,
  [EIcon.TAG_CROSS]: IconTagCross,
  [EIcon.DELIVER]: IconDeliver,
  [EIcon.CONTACT]: IconContact,
  [EIcon.EMAIL]: IconEmail,
  [EIcon.INCREASE]: IconIncrease,
  [EIcon.DECREASE]: IconDecrease,
  [EIcon.DOCUMENT]: IconDocument,
  [EIcon.FLEXIBLE_CHOICE]: IconFlexibleChoice,
  [EIcon.BEST_VALUE]: IconBestValue,
  [EIcon.COIN_FOCUS]: IconCoinFocus,
  [EIcon.STAR]: IconStar,
  [EIcon.GRAPH]: IconGraph,
  [EIcon.WHATS_APP]: IconWhatsApp,
  [EIcon.SPINNER]: IconSpinner,
  [EIcon.ALERT_CIRCLE]: IconAlertCircle,
  [EIcon.ARROW_TURNED]: IconArrowTurned,
  [EIcon.DELIVERY_VEHICLE]: IconDeliveryVehicle,
  [EIcon.PLUS_ROUNDED]: IconPlusRounded,
  [EIcon.ALERT_ROUNDED]: IconAlertRounded,
  [EIcon.CART_REPEAT_ROUNDED]: IconCartRepeatRounded,
  [EIcon.APP_STORE]: IconAppStore,
  [EIcon.GOOGLE_PLAY]: IconGooglePlay,
  [EIcon.LINE_SOLID]: IconSolid,
  [EIcon.LINE_DASHED]: IconDashed,
  [EIcon.AUTO_SAVINGS]: IconAutoSavings,
  [EIcon.PAYMENT]: IconPayment,
  [EIcon.STORAGE_FEES]: IconStorageFees,
  [EIcon.SETTINGS]: IconSettings,
  [EIcon.CART_PURCHASING_LIMIT]: IconCartPurchasingLimit,
};

export const Icon = ({
  type,
  color: colorProps = EColor.PRIMARY,
  orientation,
  secondColor: secondColorProps,
  size: sizeProps = ESize.SM,
  height,
  width,
  hidden,
  fillRule,
  fillOpacity,
  stroke,
  strokeDasharray,
  strokeDashoffset,
  strokeLinecap,
  strokeLinejoin,
  strokeMiterlimit,
  strokeOpacity,
  strokeWidth,
  ...wrapperProps
}: IconProps) => {
  const { palette } = useTheme() as ThemeProps;
  const { getTextColor } = useMemo(() => getPaletteHandlers(palette), [palette]);

  if (!hidden) {
    const DynamicIcon: FC<DynamicIconProps> = iconTypes[type];
    const size = iconSize[sizeProps];
    const dynamicIconProps = {
      fill: getTextColor(colorProps),
      secondFill: secondColorProps ? getTextColor(secondColorProps) : undefined,
      fillOpacity,
      fillRule,
      stroke: stroke || getTextColor(colorProps),
      strokeDasharray,
      strokeDashoffset,
      strokeLinecap,
      strokeLinejoin,
      strokeMiterlimit,
      strokeOpacity,
      strokeWidth,
      ...(orientation && { orientation }),
    };

    return (
      DynamicIcon && (
        <IconWrapper
          height={height ?? size}
          width={width ?? size}
          flexShrink={'0'}
          {...wrapperProps}
        >
          <DynamicIcon {...dynamicIconProps} />
        </IconWrapper>
      )
    );
  }

  return null;
};

export const IconWrapper = styled(Box)<
  BoxProps & {
    isLoading?: boolean;
  }
>(() => {
  return css`
    svg,
    img {
      display: block;
      height: 100%;
      width: 100%;
    }

    animation: ${({ isLoading }: { isLoading?: boolean }) =>
      isLoading
        ? css`
            ${spinAnimation} 2s linear infinite
          `
        : ''};
  `;
});
