import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconStorageFees = ({ fill }: DynamicIconProps) => (
  <SVG width="18" height="18" viewBox="0 0 18 18" title="storage fees icon" fill="none">
    <g clip-path="url(#a)">
      <path
        d="M2.388 11.756h4.914l1.638 5.119H.75zm8.648-6.624H6.122L4.725 10.1h7.678zm4.098 6.624H10.22l-.437 1.367 1.359 3.752h5.63z"
        fill="#fff"
      />
      <path
        d="M2.388 11.756h4.914l1.638 5.119H.75z"
        stroke={fill}
        stroke-width=".8"
        stroke-linejoin="round"
      />
      <path
        d="m12.485 9.66-1.448-4.528H6.123L4.673 9.66m6.469 7.215h5.63l-1.638-5.119H10.22l-.437 1.367"
        stroke={fill}
        stroke-width=".8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        stroke={fill}
        stroke-width=".8"
        stroke-linecap="round"
        d="M8.84 2.022V.775m5.771 2.633-.882.882m-9.922.001-.882-.883"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </SVG>
);
