import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { EHomepageProductsCategory, HomepageProductsCategoryEntity } from '@core/type/api';

import { HomepageProductsApi } from '../api/homepage-products';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('HomepageProductsService', methodName, dto, apiCall, withContent);
};

const getHomepageProducts = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: EHomepageProductsCategory,
): Promise<HomepageProductsCategoryEntity> => {
  return log(
    'getHomepageProducts',
    { axiosInstance, language, ...data },
    HomepageProductsApi.getHomepageProductsCategory,
  );
};

export class HomepageProductsService {
  public static readonly getHomepageProducts = getHomepageProducts;
}
