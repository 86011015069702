import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import { TeamMembersResponse } from '@core/type/api';

import { TeamMembersAPI } from '../api/team-members';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('TeamMembersService', methodName, dto, apiCall, withContent);
};

const getTeamMembers = (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<TeamMembersResponse> => {
  return log('getTeamMembers', { axiosInstance, language }, TeamMembersAPI.getTeamMembers);
};

export class TeamMembersService {
  public static readonly getTeamMembers = getTeamMembers;
}
