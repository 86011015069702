import { SVG } from '../SVG';
import { DynamicIconProps } from '../interface-icon';

export const IconFacebook = ({ fill }: DynamicIconProps) => (
  <SVG height={24} width={25} viewBox="0 0 25 24" title="Facebook icon">
    <path
      d="M13.723 19.2v-6.569h2.188l.185-2.56h-2.373V8.438c0-.741.204-1.245 1.26-1.245h1.113c0 .051 0-2.649.006-2.316a20 20 0 0 0-1.735-.076c-1.94 0-3.27 1.193-3.27 3.384v1.888H8.902v2.56h2.196V19.2z"
      fill={fill}
    />
  </SVG>
);
