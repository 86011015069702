import useTranslation from 'next-translate/useTranslation';
import { useEffect, useRef, useState } from 'react';

import { Box, LinkTranslatedStyledAsLink, Paper, Typography } from '@core/component';
import { Translate, metalPriceToSlug, metalToSlug } from '@core/constant';
import { useContextCurrency } from '@core/context';
import { useLanguage } from '@core/hook';
import { EColor, EFontWeight, EMetal, ERouting, EZIndexName } from '@core/type';
import { chunkArray } from '@core/util';

import { HeaderMetalCategoriesProps } from './interface-header';

const DEFAULT_LEFT_POSITION = -65;
const PAGE_PADDING = 30;
const SUBCATEGORIES_COUNT_IN_COLUMN = 5;

export const HeaderMetalCategories = ({
  data,
  metal,
  metalName,
  containerRef,
  isVisible,
}: HeaderMetalCategoriesProps) => {
  const { currency } = useContextCurrency();
  const { language } = useLanguage();

  const ref = useRef<HTMLDivElement>(null);
  const maxWidthRef = useRef<number>(0);

  const { t: tHeader } = useTranslation(Translate.layout.HEADER);

  const [leftPosition, setLeftPosition] = useState<number>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    maxWidthRef.current = window.innerWidth - PAGE_PADDING * 2;

    if (ref.current && isLoaded) {
      const componentWidth = ref.current.offsetWidth;
      const { left: containerLeft } = containerRef.current.getBoundingClientRect();

      const rightReminder =
        window.innerWidth - containerLeft - componentWidth - DEFAULT_LEFT_POSITION - PAGE_PADDING;

      if (rightReminder < 0) {
        const componentLeftPosition = DEFAULT_LEFT_POSITION + rightReminder;
        const containerLeftPosition = -containerLeft + PAGE_PADDING;

        if (Math.abs(componentLeftPosition) > Math.abs(containerLeftPosition)) {
          setLeftPosition(containerLeftPosition);
        } else {
          setLeftPosition(componentLeftPosition);
        }
      } else {
        setLeftPosition(DEFAULT_LEFT_POSITION);
      }
    }
  }, [isLoaded, isVisible]);

  return (
    <Box
      position={'absolute'}
      top={'30px'}
      left={leftPosition}
      paddingTop={'16px'}
      maxWidth={maxWidthRef.current}
      pointerEvents={isVisible ? 'auto' : 'none'}
      zIndex={EZIndexName.DROPDOWN}
    >
      <Paper
        ref={ref}
        isOutlined
        boxShadow={EColor.ELEVATION_3}
        padding={'30px 32px 30px 40px'}
        opacity={isVisible && isLoaded && leftPosition ? 1 : 0}
        borderColor={{ semanticType: EColor.NEUTRAL, variant: EColor.MAIN }}
      >
        <Box
          overflowY={'auto'}
          display={'flex'}
          gap={'22px'}
          paddingBottom={data?.length ? '15px' : '0px'}
        >
          {data?.map(({ menuTitle, slug, children }) => {
            const chunkedCategories = chunkArray(children, SUBCATEGORIES_COUNT_IN_COLUMN);

            return (
              <Box key={slug} paddingRight={'8px'}>
                <Typography whiteSpace={'nowrap'}>
                  <LinkTranslatedStyledAsLink
                    keyRoute={ERouting.SHOP_SUBCATEGORY}
                    query={{
                      metal: metalToSlug[language][metal],
                      slug,
                    }}
                    color={EColor.ACCENT}
                    fontWeight={EFontWeight.BOLD}
                  >
                    {menuTitle}
                  </LinkTranslatedStyledAsLink>
                </Typography>
                <Box display={'flex'} width={'max-content'} columnGap={'24px'} rowGap={'8px'}>
                  {chunkedCategories.map((categories, index) => (
                    <Box key={index}>
                      {categories.map(({ menuTitle, slug }) => (
                        <Box key={slug}>
                          <Typography whiteSpace={'nowrap'} marginTop={'16px'}>
                            <LinkTranslatedStyledAsLink
                              keyRoute={ERouting.SHOP_SUBCATEGORY}
                              query={{
                                metal: metalToSlug[language][metal],
                                slug,
                              }}
                              key={menuTitle}
                            >
                              {menuTitle}
                            </LinkTranslatedStyledAsLink>
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  ))}
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box paddingRight={'8px'}>
          <Typography whiteSpace={'nowrap'}>
            <LinkTranslatedStyledAsLink
              keyRoute={ERouting.CHARTS}
              query={{
                metalSlug: metalPriceToSlug[language][metal as EMetal],
                slug: [currency.toLowerCase()],
              }}
              fontWeight={EFontWeight.BOLD}
              color={EColor.ACCENT}
              language={language}
            >
              {tHeader('metalPrice', { metal: metalName })}
            </LinkTranslatedStyledAsLink>
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};
