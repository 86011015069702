export enum EActionAttribute {
  BUTTON = 'button',
  SPAN = 'span',
  STRONG = 'strong',
  INPUT = 'input',
  HEADER = 'header',
  SECTION = 'section',
  FOOTER = 'footer',
  MAIN = 'main',
  ARTICLE = 'article',
  LINK = 'a',
  LABEL = 'label',
  DIV = 'div',
  HR = 'hr',
  EM = 'em',
  BLOCKQUOTE = 'blockquote',
  SELECT = 'select',
  VIDEO = 'video',
  IFRAME = 'iframe',
  IMG = 'img',
  FIGURE = 'figure',
  FIGCAPTION = 'figcaption',
  UL = 'ul',
  OL = 'ol',
  LI = 'li',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  P = 'p',
  DEFAULT = 'default',
  HOVER = 'hover',
  INFO = 'info',
  TOAST = 'toast',
  DROPDOWN = 'dropdown',
  TOOLTIP = 'tooltip',
}

export type ActionType<T extends Partial<EActionAttribute>> = {
  [key in T]: T;
};
