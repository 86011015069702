import { Box } from '../layout/Box';
import { BoxProps } from '../layout/interface-layout';

export type ModalFooterProps = {
  children: React.ReactNode;
} & BoxProps;

export const ModalFooter = ({ children, ...props }: ModalFooterProps) => (
  <Box marginTop="32px" {...props}>
    {children}
  </Box>
);
