import Script from 'next/script';

export const CrispNextScript = ({
  nonce,
  url,
}: {
  nonce: string;
  url: string;
}): JSX.Element | null => {
  return nonce ? <Script strategy="lazyOnload" src={`${url}/l.js`} defer nonce={nonce} /> : null;
};
