import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { BlogArticleEntity } from '@core/type/api';

import { HighlightedArticleAPI } from '../api/highlighted-article';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('HighlightedArticleService', methodName, dto, apiCall, withContent);
};

const getHighlightedArticle = (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<BlogArticleEntity> => {
  return log(
    'getHighlightedArticle',
    { axiosInstance, language },
    HighlightedArticleAPI.getHighlightedArticle,
  );
};

export class HighlightedArticleService {
  public static readonly getHighlightedArticle = getHighlightedArticle;
}
