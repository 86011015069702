import { MOBILE_APP_PROMOTION_BANNER_LIFETIME, PAGE_RATING_LIFETIME } from '@core/constant';
import { EStorageType } from '@core/type';

import { EStorageKey } from '../constants';
import { getItem, logStorage, setItem } from '../helpers';

const setRatedPageState = (storage: EStorageType, pageId: string) => {
  logStorage('setRatedPageState');
  setItem(storage, `${EStorageKey.WIDGET_RATING_STARS}:${pageId}`, 'rated', PAGE_RATING_LIFETIME);
};

const getRatedPageState = (storage: EStorageType, pageId: string) => {
  logStorage('getRatedPageState');
  return getItem(storage, `${EStorageKey.WIDGET_RATING_STARS}:${pageId}`);
};

const setMobileAppPromotionBannerState = (storage: EStorageType) => {
  logStorage('setMobileAppPromotionBannerState');
  setItem(
    storage,
    EStorageKey.WIDGET_MOBILE_APP_PROMOTION_BANNER,
    'closed',
    MOBILE_APP_PROMOTION_BANNER_LIFETIME,
  );
};

const getMobileAppPromotionBannerState = (storage: EStorageType) => {
  logStorage('getMobileAppPromotionBannerState');
  return getItem(storage, EStorageKey.WIDGET_MOBILE_APP_PROMOTION_BANNER);
};

export class WidgetStorage {
  public static readonly setRatedPageState = setRatedPageState;
  public static readonly getRatedPageState = getRatedPageState;
  public static readonly setMobileAppPromotionBannerState = setMobileAppPromotionBannerState;
  public static readonly getMobileAppPromotionBannerState = getMobileAppPromotionBannerState;
}
