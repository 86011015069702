import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconDecrease = ({ fill }: DynamicIconProps) => (
  <SVG height="24" width="24" viewBox="0 0 24 24" title="Decrease icon">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.96034 5.29639L14.3302 13.6662L9.4761 13.6662C9.14473 13.6662 8.8761 13.9349 8.8761 14.2662C8.8761 14.5976 9.14473 14.8662 9.4761 14.8662H15.7334H16.3334V14.2662V8.00891C16.3334 7.67754 16.0648 7.40892 15.7334 7.40891C15.4021 7.40891 15.1334 7.67754 15.1334 8.00891L15.1334 12.7724L6.80887 4.44786C6.57456 4.21355 6.19466 4.21355 5.96034 4.44786C5.72603 4.68218 5.72603 5.06207 5.96034 5.29639ZM4.6 18.55C4.26863 18.55 4 18.8187 4 19.15C4 19.4814 4.26863 19.75 4.6 19.75H19.4C19.7314 19.75 20 19.4814 20 19.15C20 18.8187 19.7314 18.55 19.4 18.55H4.6Z"
      fill={fill}
    ></path>
  </SVG>
);
