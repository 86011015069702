import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type {
  CreateMarketAlertEntity,
  CreateMarketAlertResponse,
  MarketAlertResponse,
} from '@core/type/api';

import { MarketAlertAPI } from '../api/market-alerts';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('MarketAlertService', methodName, dto, apiCall, withContent);
};

const getAlerts = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<MarketAlertResponse> => {
  return log('getAlerts', { axiosInstance, language }, MarketAlertAPI.getAlerts);
};

const createAlert = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: CreateMarketAlertEntity,
): Promise<CreateMarketAlertResponse> => {
  return log('createAlert', { axiosInstance, language, ...data }, MarketAlertAPI.createAlert);
};

const deleteAlert = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  alertId: string,
): Promise<void> => {
  return log('deleteAlert', { axiosInstance, language, alertId }, MarketAlertAPI.deleteAlert);
};

export class MarketAlertService {
  public static readonly getAlerts = getAlerts;
  public static readonly createAlert = createAlert;
  public static readonly deleteAlert = deleteAlert;
}
