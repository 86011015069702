import { forwardRef } from 'react';

import { EColor, EFontWeight } from '@core/type';

import { ButtonRadio } from './ButtonRadio';
import { ButtonRadioProps } from './interface-button';

export const ButtonRadioBlueBordered = forwardRef<HTMLInputElement, ButtonRadioProps>(
  (
    { currentValue, value, name, wrapperProps, inputBorderProps, labelWrapperProps, ...restProps },
    ref,
  ) => {
    const isActive = currentValue === value;

    return (
      <ButtonRadio
        wrapperProps={{
          borderColor: EColor.NEUTRAL,
          flexShrink: '0',
          flexGrow: '1',
          borderRadius: '6px',
          padding: '6px 16px',
          minHeight: '48px',
          ...wrapperProps,
        }}
        labelWrapperProps={{
          fontSize: '16px',
          fontWeight: isActive ? EFontWeight.BOLD : EFontWeight.REGULAR,
          color: isActive ? EColor.ACCENT : EColor.PRIMARY,
          ...labelWrapperProps,
        }}
        inputBorderProps={{
          borderColor: isActive ? EColor.SECONDARY : EColor.PRIMARY,
          backgroundColor: isActive
            ? EColor.INFO
            : {
                semanticType: EColor.INFO,
                variant: EColor.LIGHT,
              },
          ...inputBorderProps,
        }}
        {...restProps}
        value={value}
        currentValue={currentValue}
        ref={ref}
        withBorder
        name={name}
        aria-checked={isActive}
        aria-label={`${name}.${value}`}
      />
    );
  },
);

ButtonRadioBlueBordered.displayName = 'ButtonRadioBlueBordered';
