import { SVG } from './SVG';

export const IconSuccessFull = () => (
  <SVG width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.5" cy="12" r="10" fill="#09BB70" stroke="#09BB70" />
    <path
      d="M6.99854 10.7844L11.8572 15.6431L20.5962 6.90431"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
  </SVG>
);
