import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconPDF = ({ fill }: DynamicIconProps) => (
  <SVG height={24} width={24} viewBox="0 0 24 24" title="PDF icon">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.2 3a.6.6 0 0 1 .6-.6h9L20.4 8v12a1.6 1.6 0 0 1-1.6 1.6h-12A1.6 1.6 0 0 1 5.2 20v-1h-.4a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h.4zm1.2 9h9.4a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H6.4v1a.4.4 0 0 0 .4.4h12a.4.4 0 0 0 .4-.4V8h-3.4a1 1 0 0 1-1-1V3.6H6.4zm-.277 5.666v-1.564h.636q.348 0 .63-.1.28-.1.477-.275.2-.18.305-.422.108-.244.108-.53 0-.308-.108-.56a1.2 1.2 0 0 0-.305-.43 1.4 1.4 0 0 0-.477-.282 1.8 1.8 0 0 0-.63-.103h-1.46v4.266zm.636-2.232h-.636v-1.366h.636q.176 0 .304.056a.6.6 0 0 1 .343.378q.044.13.044.278a.7.7 0 0 1-.044.25.6.6 0 0 1-.343.352.8.8 0 0 1-.304.052M8.833 13.4v4.266h1.146q.407 0 .744-.143.337-.144.577-.402.24-.261.372-.618.135-.36.135-.791v-.352q0-.43-.135-.79a1.8 1.8 0 0 0-.378-.619 1.7 1.7 0 0 0-.589-.404 1.9 1.9 0 0 0-.768-.147zm.83 3.604v-2.936h.274a1 1 0 0 1 .443.094.9.9 0 0 1 .322.264q.132.17.196.407.068.234.068.521v.358q0 .305-.068.545a1.2 1.2 0 0 1-.196.404.8.8 0 0 1-.31.255.94.94 0 0 1-.413.088zm5.498-1.105h-1.828v1.767h-.826V13.4h2.883v.671h-2.057v1.16h1.828z"
      fill={fill}
    />
  </SVG>
);
