import { AxeptioScript } from '@axeptio';
import { FacebookPixelNextScript } from '@facebook-pixel';
import { GRNextScript, GRProvider } from '@google-recaptcha';
import { GTMNextScript, GTMProvider } from '@google-tag-manager';
import { MixpanelProvider } from '@mixpanel';
import useTranslation from 'next-translate/useTranslation';
import { ReactNode, useEffect } from 'react';

import { RoutingService } from '@core/api';
import { Translate, crisp, zIndexes } from '@core/constant';
import {
  useContextAuth,
  useContextMediaQuery,
  useContextProfile,
  useContextRouting,
  useContextToast,
  useContextUser,
  useContextUtil,
} from '@core/context';
import { useUserWebSocket } from '@core/hook';
import { EColor, EHeader, EMixpanelEnv, EUserStatus, EZIndexName } from '@core/type';
import {
  BlogCategory,
  SanityHeaderDefaultResponse,
  ShopSubcategoriesResponse,
} from '@core/type/api';
import { getTypeRoute } from '@core/util';
import { BannerMobileAppPromotion } from '@core/widget';

import { CrispNextScript, CrispProvider } from '@crisp';

import { getEnvironmentPublic } from '../helpers';
import { useNextRouter } from '../hooks';
import { Layout } from '../layout';
import { MobileLiveChartsWithProvider } from './MobileLiveChartsWithProvider';

export type ProviderMainProps = {
  children?: ReactNode;
  nonce: string;
  recaptchaKey: string;
  isBotDetected: boolean;
  shouldSignIn?: boolean;
  blogCategories: BlogCategory[];
  userStatus?: EUserStatus;
  subcategories?: ShopSubcategoriesResponse;
  headerMenus?: SanityHeaderDefaultResponse['menus'];
  isDraft?: boolean;
};

export const ProviderMain = ({
  children,
  nonce,
  recaptchaKey,
  isBotDetected,
  blogCategories,
  shouldSignIn,
  userStatus,
  subcategories,
  headerMenus,
  isDraft,
}: ProviderMainProps) => {
  const {
    host,
    env,
    googleAnalyticsId,
    enableGoogleAnalytics,
    enableCrisp,
    enableRecaptcha,
    enableMixpanel,
    mixpanelId,
    crispWebsiteId,
    axeptioClientId,
    facebookPixelId,
    cdn,
    enableMobileAppPromotionBanner,
  } = getEnvironmentPublic();
  const { t } = useTranslation(Translate.common.COMMON);
  const { keyRoute, query } = useContextRouting();
  const { addToast } = useContextToast();
  const { isPhone, isMobile, isTablet, isDesktop } = useContextMediaQuery();
  const { setUserSessionId } = useContextProfile();
  const { isLogged, isReady } = useContextUser();
  const { setIsScriptGALoaded } = useContextUtil();
  const { autoLogged } = useContextAuth();

  const { wsHost } = getEnvironmentPublic();

  useNextRouter({
    addToast,
  });
  useUserWebSocket(wsHost);

  useEffect(() => {
    if (isLogged && isReady) {
      setUserSessionId();
    } else if (!isReady) {
      void autoLogged();
    }

    if (shouldSignIn) {
      addToast({
        title: t(`toast.information`),
        description: t('userMustLogged.description'),
        status: EColor.WARNING,
      });
    } else if (userStatus) {
      addToast({
        title: t(`toast.danger`),
        description: t('userBanned.description', { status: userStatus }),
        status: EColor.DANGER,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    RoutingService.setKeyRoute(keyRoute);
  }, []);

  const typeRoute = getTypeRoute(keyRoute);

  return (
    <>
      <AxeptioScript axeptioClientId={axeptioClientId} />
      <MixpanelProvider
        token={mixpanelId}
        env={env}
        active={enableMixpanel}
        host={host}
        environment={EMixpanelEnv.WEB_APP}
      >
        <GTMProvider active={enableGoogleAnalytics && !isBotDetected} host={host}>
          <GRProvider recaptchaKey={recaptchaKey}>
            <CrispProvider
              crispWebsiteId={crispWebsiteId}
              enableCrisp={!isBotDetected && enableCrisp}
              zIndex={zIndexes[EZIndexName.FIXED]}
            >
              <Layout
                typeRoute={typeRoute}
                keyRoute={keyRoute}
                query={query}
                isPhone={isPhone}
                isMobile={isMobile}
                isTablet={isTablet}
                isDesktop={isDesktop}
                blogCategories={blogCategories}
                subcategories={subcategories}
                headerMenus={headerMenus}
                isDraft={isDraft}
              >
                {isMobile && typeRoute.header.type === EHeader.DEFAULT && (
                  <MobileLiveChartsWithProvider />
                )}
                {children}
              </Layout>

              {enableMobileAppPromotionBanner && <BannerMobileAppPromotion />}

              {enableCrisp && !isBotDetected && (
                <CrispNextScript url={crisp.CRISP_URL} nonce={nonce} />
              )}
              {enableRecaptcha && !isBotDetected && (
                <GRNextScript nonce={nonce} recaptchaKey={recaptchaKey} />
              )}
              {enableGoogleAnalytics && !isBotDetected && (
                <>
                  <FacebookPixelNextScript
                    nonce={nonce}
                    facebookPixelId={facebookPixelId}
                    cdn={cdn}
                  />
                  <GTMNextScript
                    nonce={nonce}
                    gaTrackingId={googleAnalyticsId}
                    setIsScriptGALoaded={setIsScriptGALoaded}
                  />
                </>
              )}
            </CrispProvider>
          </GRProvider>
        </GTMProvider>
      </MixpanelProvider>
    </>
  );
};
