class Page {
  public static readonly ALERT_SETTINGS = 'alerts-settings';
  public static readonly SIGN_UP = 'sign-up';
  public static readonly ABOUT_US = 'about-us';
  public static readonly AUTO_SAVINGS = 'auto-savings';
  public static readonly PURCHASING_LIMIT = 'purchasing-limit';
  public static readonly NOT_FOUND = 'not-found';
  public static readonly BAD_REQUEST = 'bad-request';
  public static readonly INTERNAL_ERROR = 'internal-error';
  public static readonly PROFILE = 'profile';
  public static readonly ACCOUNT_TRANSACTIONS = 'account-transactions';
  public static readonly PRICING = 'pricing';
  public static readonly SIGN_IN = 'sign-in';
  public static readonly CHANGE_PASSWORD = 'change-password';
  public static readonly FORGOTTEN_PASSWORD = 'forgotten-password';
  public static readonly RESEND_INSTRUCTIONS = 'resend-instructions';
  public static readonly BLOG_HOME = 'blog-home';
  public static readonly HOME = 'home';
  public static readonly GENERAL_CONDITIONS = 'general-conditions';
  public static readonly IMPRESSUM = 'impressum';
  public static readonly KYC = 'kyc';
  public static readonly PRIVACY = 'privacy';
  public static readonly REFERRALS_TERMS = 'referral-terms';
  public static readonly REFERRALS = 'referrals';
  public static readonly SEARCH = 'search';
  public static readonly SHOP = 'shop';
  public static readonly WISHLIST = 'wishlist';
  public static readonly TUTORIAL_VIDEOS = 'tutorial-videos';
  public static readonly TUTORIAL_VIDEO = 'tutorial-video';
  public static readonly SILVER_LANDING = 'silver-landing';
  public static readonly CHARTS = 'charts';
  public static readonly KYC_DATE_OF_BIRTH = 'kyc-date-of-birth';
  public static readonly KYC_PHONE_NUMBER = 'kyc-phone-number';
  public static readonly KYC_EXPOSED_PERSON = 'kyc-exposed-person';
  public static readonly KYC_OCCUPATION = 'kyc-occupation';
  public static readonly KYC_BUSINESS_SECTOR = 'kyc-business-sector';
  public static readonly KYC_ANNUAL_INCOME = 'kyc-annual-income';
  public static readonly KYC_FORECASTED_ANNUAL_INVESTMENTS = 'kyc-forecasted-annual-investments';
  public static readonly KYC_SOURCES_OF_FUNDS = 'kyc-sources-of-funds';
  public static readonly KYC_LOCATION = 'kyc-location';
  public static readonly STORAGE_SOLUTION = 'storage-solution';
  public static readonly PRECIOUS_METAL_GUIDE = 'precious-metal';
  public static readonly KYC_PURCHASE_LIMIT = 'kyc-purchasing-limit';
  public static readonly CHECKOUT = 'checkout';
  public static readonly PRODUCT_SHOW = 'product-show';
  public static readonly BILLING_ADDRESS = 'billing-address';
  public static readonly SHIPPING_ADDRESS = 'shipping-address';
  public static readonly EMERGENCY_PEOPLE = 'emergency-people';
  public static readonly PASSWORD_SETTINGS = 'password-settings';
  public static readonly SHOP_SUBCATEGORY = 'shop-subcategory';
  public static readonly CLAIMS = 'claims';
  public static readonly GIFTS = 'gifts';
  public static readonly SAVING_ASSISTANT = 'saving-assistant';
  public static readonly WALLET = 'wallet';
  public static readonly WALLET_PAGE = 'wallet-page';
  public static readonly WALLET_AUTO_SAVINGS = 'wallet-auto-savings';
  public static readonly WALLET_ORDERS = 'wallet-orders';
  public static readonly WALLET_ORDER_SHOW = 'wallet-order-show';
  public static readonly WALLET_GIFTS = 'wallet-gifts';
  public static readonly WALLET_GIFT_SHOW = 'wallet-gift-show';
  public static readonly WALLET_CLAIMS = 'wallet-claims';
  public static readonly WALLET_CLAIM_SHOW = 'wallet-claim-show';
  public static readonly WALLET_SELL = 'wallet-sell';
  public static readonly WALLET_SELL_REVIEW = 'wallet-sell-review';
  public static readonly WALLET_SELL_THANKS = 'wallet-sell-thanks';
  public static readonly TWO_STEPS_AUTH = 'two-steps-auth';
  public static readonly CART = 'cart';
  public static readonly EMAIL_UNSUBSCRIBE = 'email-unsubscribe';
  public static readonly PAYMENTS = 'payments';
  public static readonly WITHDRAWAL = 'withdrawal';
  public static readonly FAQ = 'faq';
  public static readonly DEPOSIT = 'deposit';
  public static readonly CONTACT = 'contact';
  public static readonly CONTACT_FORM = 'contact-form';
  public static readonly STORAGE_FEES = 'storage-fees';
  public static readonly MAINTENANCE = 'maintenance';
  public static readonly HOME_SHOP = 'home-shop';
  public static readonly WALLET_AUTO_SAVINGS_SHOW = 'wallet-auto-savings-show';
  public static readonly PHOTO_IDENTIFICATION = 'photo-identification';
  public static readonly DONE_KYC = 'done-kyc';
  public static readonly NATIONALITY = 'nationality';
  public static readonly RESIDENCE = 'residence';
  public static readonly CHECKOUT_SHIPPING = 'checkout-shipping';
  public static readonly CHECKOUT_BILLING = 'checkout-billing';
  public static readonly CHECKOUT_PAYMENT = 'checkout-payment';
  public static readonly CHECKOUT_REVIEW = 'checkout-review';
  public static readonly CHECKOUT_THANK_YOU = 'checkout-thank-you';
  public static readonly VERIFY_AUTH = 'verify-auth';
  public static readonly TWO_STEPS_AUTH_SMS = 'two-steps-auth-sms';
  public static readonly TWO_STEPS_AUTH_TOTP = 'two-steps-auth-totp';
  public static readonly DELIVERY_CLAIMS_BREAKDOWN = 'delivery-claims-breakdown';
  public static readonly DELIVERY_CLAIMS_DELIVERY = 'delivery-claims-delivery';
  public static readonly DELIVERY_CLAIMS_BILLING_ADDRESS = 'delivery-claims-billing-address';
  public static readonly DELIVERY_CLAIMS_PAYMENT_METHOD = 'delivery-claims-payment-method';
  public static readonly DELIVERY_CLAIMS_ORDER_REVIEW = 'delivery-claims-order-review';
  public static readonly DELIVERY_CLAIMS_THANK_YOU = 'delivery-claims-thank-you';
  public static readonly GIFT_RECIPIENT = 'gift-recipient';
  public static readonly GIFT_SUBMIT = 'gift-submit';
  public static readonly GIFT_CONFIRMATION = 'gift-confirmation';
  public static readonly GIFT_PENDING = 'gift-pending';
  public static readonly GIFT_DETAIL = 'gift-detail';
  public static readonly GIFT_LIST = 'gift-list';
  public static readonly ABANDONED_CART = 'abandoned-cart';
  public static readonly BLOG_CATEGORY = 'blog-category';
  public static readonly BLOG_ARTICLE = 'blog-article';
  public static readonly BLOG_TAG = 'blog-tag';
  public static readonly BLOG_SEARCH = 'blog-search';
  public static readonly BLOG = 'blog';
  public static readonly UNLOCK_ACCOUNT = 'unlock-account';
  public static readonly FAQ_SHOW = 'faq-category';
  public static readonly FAQ_LIST = 'faq-list';
  public static readonly FAQ_SEARCH = 'faq-search';
  public static readonly PAGE_PROFILE = 'page-profile';
  public static readonly EXAMPLE = 'example';
  public static readonly ONFIDO = 'onfido';
  public static readonly PAGES = 'pages';
}

class Common {
  public static readonly ARIA = 'aria';
  public static readonly COMMON = 'common';
  public static readonly BUTTON = 'button';
  public static readonly BREADCRUMB = 'breadcrumb';
  public static readonly LINK = 'link';
  public static readonly LANGUAGE = 'language';
  public static readonly CURRENCY = 'currency';
  public static readonly FORM = 'form';
  public static readonly PRODUCT = 'product';
  public static readonly USER = 'user';
  public static readonly TOOLTIP = 'tooltip';
  public static readonly REQUEST_A_CALL = 'request-a-call';
  public static readonly ONFIDO = 'onfido';
  public static readonly MODAL = 'modal';
  public static readonly DELIVERY_CLAIMS = 'delivery-claims';
  public static readonly TABLE = 'table';
  public static readonly WIDGET = 'widget';
}

class Layout {
  public static readonly FOOTER = 'footer';
  public static readonly HEADER = 'header';
}

export class Translate {
  public static readonly layout = Layout;
  public static readonly common = Common;
  public static readonly page = Page;
}

export enum ETranslationFile {
  ABANDONED_CART = 'abondoned-cart',
  ABOUT_US = 'about-us',
  ACCOUNT_TRANSACTIONS = 'account-transactions',
  ALERTS_SETTINGS = 'alerts-settings',
  ARIA = 'aria',
  AUTO_SAVINGS = 'auto-savings',
  BAD_REQUEST = 'bad-request',
  BILLING_ADDRESS = 'billing-address',
  BLOG_ARTICLE = 'blog-article',
  BLOG_CATEGORY = 'blog-category',
  BLOG_HOME = 'blog-home',
  BLOG_SEARCH = 'blog-search',
  BLOG_TAG = 'blog-tag',
  BLOG = 'blog',
  BREADCRUMB = 'breadcrumb',
  BUTTON = 'button',
  CART = 'cart',
  CHANGE_PASSWORD = 'change-password',
  CHARTS = 'charts',
  CHECKOUT_BILLING = 'checkout-billing',
  CHECKOUT_PAYMENT = 'checkout-payment',
  CHECKOUT_REVIEW = 'checkout-review',
  CHECKOUT_SHIPPING = 'checkout-shipping',
  CHECKOUT_THANK_YOU = 'checkout-thank-you',
  CHECKOUT = 'checkout',
  CHOICE_PURCHASING_LIMIT = 'choice-purchasing-limit',
  CLAIMS = 'claims',
  COMMON = 'common',
  CONTACT = 'contact',
  CONTACT_FORM = 'contact-form',
  COOKIE_CONSENT = 'cookie-consent',
  CURRENCY = 'currency',
  DELIVERY_CLAIMS_BILLING_ADDRESS = 'delivery-claims-billing-address',
  DELIVERY_CLAIMS_BREAKDOWN = 'delivery-claims-breakdown',
  DELIVERY_CLAIMS_DELIVERY = 'delivery-claims-delivery',
  DELIVERY_CLAIMS_ORDER_REVIEW = 'delivery-claims-order-review',
  DELIVERY_CLAIMS_PAYMENT_METHOD = 'delivery-claims-payment-method',
  DELIVERY_CLAIMS_THANK_YOU = 'delivery-claims-thank-you',
  DELIVERY_CLAIMS = 'delivery-claims',
  DEPOSIT = 'deposit',
  DONE_KYC = 'done-kyc',
  EMAIL_UNSUBSCRIBE = 'email-unsubscribe',
  EMERGENCY_PEOPLE = 'emergency-people',
  EXAMPLE = 'example',
  FAQ_CATEGORY = 'faq-category',
  FAQ_LIST = 'faq-list',
  FAQ_SEARCH = 'faq-search',
  FAQ = 'faq',
  FOOTER = 'footer',
  FORGOTTEN_PASSWORD = 'forgotten-password',
  FORM = 'form',
  GENERAL_CONDITIONS = 'general-conditions',
  GIFT_CONFIRMATION = 'gift-confirmation',
  GIFT_DETAIL = 'gift-detail',
  GIFT_LIST = 'gift-list',
  GIFT_PENDING = 'gift-pending',
  GIFT_RECIPIENT = 'gift-recipient',
  GIFT_SUBMIT = 'gift-submit',
  GIFTS = 'gifts',
  HEADER = 'header',
  HOME_SHOP = 'home-shop',
  HOME = 'home',
  IMPRESSUM = 'impressum',
  INTERNAL_ERROR = 'internal-error',
  KYC_PURCHASE_LIMIT = 'kyc-purchasing-limit',
  KYC = 'kyc',
  LANGUAGE = 'language',
  LINK = 'link',
  MAINTENANCE = 'maintenance',
  MODAL = 'modal',
  NATIONALITY = 'nationality',
  NO_FILENAME = 'no_filename',
  NOT_FOUND = 'not-found',
  OCCUPATION = 'occupation',
  ONFIDO = 'onfido',
  ORIGIN_OF_FUNDS = 'origin-of-funds',
  PAGE_PROFILE = 'page-profile',
  PAYMENTS = 'payments',
  PASSWORD_SETTINGS = 'password-settings',
  PHOTO_IDENTIFICATION = 'photo-identification',
  PRECIOUS_METAL = 'precious-metal',
  PRIVACY = 'privacy',
  PRODUCT_SHOW = 'product-show',
  PRODUCT = 'product',
  PROFILE = 'profile',
  PROOF_OF_ADDRESS = 'proof-of-address',
  PURCHASING_LIMIT = 'purchasing-limit',
  REFERRAL_TERMS = 'referral-terms',
  REFERRALS = 'referrals',
  REQUEST_A_CALL = 'request-a-call',
  RESEND_INSTRUCTION = 'resend-instruction',
  RESEND_INSTRUCTIONS = 'resend-instructions',
  RESIDENCE = 'residence',
  ROUTE = 'route',
  SAVING_ASSISTANT = 'saving-assistant',
  SEARCH = 'search',
  SHIPPING_ADDRESS = 'shipping-address',
  SHOP_SUBCATEGORY = 'shop-subcategory',
  SHOP = 'shop',
  SIGN_IN = 'sign-in',
  SIGN_UP = 'sign-up',
  SILVER_LANDING = 'silver-landing',
  STORAGE_FEES = 'storage-fees',
  STORAGE_SOLUTION = 'storage-solution',
  TABLE = 'table',
  TOOLTIP = 'tooltip',
  TUTORIAL_VIDEO = 'tutorial-video',
  TUTORIAL_VIDEOS = 'tutorial-videos',
  TWO_STEPS_AUTH_SMS = 'two-steps-auth-sms',
  TWO_STEPS_AUTH_TOTP = 'two-steps-auth-totp',
  TWO_STEPS_AUTH = 'two-steps-auth',
  UNLOCK_ACCOUNT = 'unlock-account',
  USER = 'user',
  VALIDATOR = 'validator',
  VERIFY_AUTH = 'verify-auth',
  WALLET_AUTO_SAVINGS_SHOW = 'wallet-auto-savings-show',
  WALLET_AUTO_SAVINGS = 'wallet-auto-savings',
  WALLET_CLAIM_SHOW = 'wallet-claim-show',
  WALLET_CLAIMS = 'wallet-claims',
  WALLET_GIFT_SHOW = 'wallet-gift-show',
  WALLET_GIFTS = 'wallet-gifts',
  WALLET_ORDER_SHOW = 'wallet-order-show',
  WALLET_ORDERS = 'wallet-orders',
  WALLET_PAGE = 'wallet-page',
  WALLET_SELL_REVIEW = 'wallet-sell-review',
  WALLET_SELL_THANKS = 'wallet-sell-thanks',
  WALLET_SELL = 'wallet-sell',
  WALLET = 'wallet',
  WIDGET = 'widget',
  WISHLIST = 'wishlist',
  WITHDRAWAL = 'withdrawal',
  YOUR_PROJECT = 'your-project',
}
