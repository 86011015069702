import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type { BlogArticleEntity } from '@core/type/api';

import { Api } from '../constants/api';
import { configResponse, EConfigName } from '../helpers/config';

const getHighlightedArticle = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<BlogArticleEntity>> => {
  return axiosInstance.get(
    Api.content.HIGHLIGHTED_ARTICLE,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class HighlightedArticleAPI {
  public static readonly getHighlightedArticle = getHighlightedArticle;
}
