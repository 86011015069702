/* eslint-disable @typescript-eslint/no-magic-numbers */
import { useContextMediaQuery } from '@core/context';
import { ETypographyVariant } from '@core/type';

import { Grid } from '../grid';
import { Box } from '../layout';
import { Line } from '../line';
import { Paper } from '../paper';
import { SkeletonTypography } from './SkeletonTypography';
import { SkeletonWrapper } from './SkeletonWrapper';

export const SkeletonReferrals = () => {
  const { isPhone, isMobile } = useContextMediaQuery();

  return (
    <SkeletonWrapper paddingTop={isMobile ? undefined : '24px'} overflow={'visible'}>
      <Box display={'flex'} justifyContent={'space-between'} marginBottom={'16px'}>
        <SkeletonTypography variant={ETypographyVariant.H2} width={isPhone ? '200px' : '260px'} />
        {!isPhone && <SkeletonTypography lineHeight={'22px'} width={'260px'} />}
      </Box>

      <SkeletonTypography
        lineHeight={isPhone ? '44px' : isMobile ? '22px' : '24px'}
        maxWidth={'600px'}
        marginBottom={isPhone ? '16px' : '48px'}
      />

      {isPhone && (
        <>
          <SkeletonTypography lineHeight={'22px'} width={'260px'} />
          <Line margin={'48px 0'} isFullWidth />
        </>
      )}

      <Box
        display={'flex'}
        justifyContent={'space-between'}
        flexDirection={isPhone ? 'column' : 'row'}
        marginBottom={isPhone ? '48px' : '24px'}
        gap={'8px'}
      >
        <SkeletonTypography width={'120px'} />
        <SkeletonTypography variant={ETypographyVariant.BODY3} width={'220px'} />
      </Box>

      <Grid isContainer columnSpacing={30} rowSpacing={30}>
        {[...Array(isPhone ? 2 : 4).keys()].map((index: number) => (
          <Grid key={index} md={6} lg={4} xl={3}>
            <Paper borderRadius={'8px'} hasElevation padding={'24px'} height={'164px'}>
              <SkeletonTypography width={'160px'} marginBottom={'8px'} />
              <SkeletonTypography variant={ETypographyVariant.H4} width={'120px'} />
            </Paper>
          </Grid>
        ))}
      </Grid>

      {!isPhone && (
        <>
          <SkeletonTypography
            width={'120px'}
            marginTop={'48px'}
            marginBottom={isMobile ? '48px' : '24px'}
          />

          <Paper borderRadius={'8px'} hasElevation padding={isMobile ? '24px' : '40px'}>
            <SkeletonTypography maxWidth={'450px'} marginBottom={'24px'} />
            <SkeletonTypography
              lineHeight={'94px'}
              maxWidth={'750px'}
              borderRadius={'8px'}
              marginBottom={'32px'}
            />
            <SkeletonTypography width={'140px'} />
          </Paper>
        </>
      )}
    </SkeletonWrapper>
  );
};
