import { EColor } from './color';

export type ResponseErrors = {
  status?: ETypeResponse & EUserStatus;
  errors?: string;
};

export type ResponseErrorAPI = {
  status?: ETypeResponse & EUserStatus;
  message?: string;
  title?: string;
};

export enum EUserStatus {
  LOCKED = 'locked',
  BLOCKED = 'blocked',
  DEFAULT = 'default',
}

export enum ETypeResponse {
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
  INFORMATION = 'information',
}

export type ResponseUnprocessableEntity = {
  attribute?: string;
  message?: string;
};

export type ResponseHandled = {
  status?: EColor.INFO | EColor.SUCCESS | EColor.WARNING | EColor.DANGER;
  message?: string;
  errors?: ResponseUnprocessableEntity[];
  userStatus?: EUserStatus;
  isToast: boolean;
  data?: Record<string, unknown>;
  title?: string;
};
