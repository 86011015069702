import { getSanityPageRoute } from '@core/sanity';
import { SanityLinkObjProps } from '@core/type/sanity';

export const getHeaderLinkHref = ({
  keyRoute,
  language,
  slug,
  metalIso,
  href,
}: SanityLinkObjProps): string =>
  keyRoute ? getSanityPageRoute(keyRoute, language, slug, metalIso) : href;
