import Image from 'next/image';

import IconMastercardSvg from '../svgs/icon-mastercard.svg';

export const IconMastercard = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconMastercardSvg}
    alt="Mastercard icon"
    width={31}
    height={20}
  />
);
