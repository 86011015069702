import { IconSourceEntity } from './image';
import { DynamicSEOEntity } from './meta';
import { PagedEntity } from './paged';

export type FAQArticleEntity = FAQArticleBaseEntity &
  DynamicSEOEntity & {
    content: string;
    position: number;
    faqCategories: Omit<FAQCategoriesEntity, 'position'>[];
  };

export type FAQCategoryQuestionEntity = {
  name: string;
  content: string;
};

export type FAQCategoriesEntity = {
  name: string;
  subtitle: string;
  icon: IconSourceEntity;
  faqArticles: FAQArticleBaseEntity[];
  position: number;
};

export type FAQCategoryEntity = {
  faqQuestions: FAQCategoryQuestionEntity[];
};

export type FAQCategoriesResponse = {
  faqCategories: FAQCategoriesEntity[];
};

export type FAQArticleBaseEntity = {
  name: string;
  slug: string;
};

export type FAQSearchEntity = PagedEntity<FAQArticleBaseEntity, 'faqArticles'>;

export type FAQSearchAutocompleteEntity = {
  faqArticles: FAQArticleBaseEntity[];
};

export enum EFAQCategory {
  SILVER_LP = 'silver_landing_page',
  STORAGE_SOLUTION = 'storage_solution',
  HOME_PAGE = 'home_page',
  REPEAT_BUY = 'repeat_buy',
  CONTACT = 'contact',
}
