const {
  i18nSupportedLanguage,
  I18N_DEFAULT,
  ELanguage,
  getLanguageTags,
} = require('./src/constants/language');

module.exports = {
  locales: i18nSupportedLanguage,
  defaultLocale: I18N_DEFAULT,
  localeDetection: false,
  defaultNS: ELanguage.EN,
  pages: {
    '*': [
      'common',
      'button',
      'footer',
      'header',
      'route',
      'language',
      'currency',
      'maintenance',
      'internal-error',
      'not-found',
      'validator',
      'form',
      'link',
      'breadcrumb',
      'product',
      'user',
      'request-a-call',
      'delivery-claims',
      'pricing',
      'modal',
      'table',
      'tooltip',
      'aria',
      'widget',
    ],
    ['/example/PageComponent']: ['example'],
    ['/content/PageAboutUs']: ['about-us'],
    ['/content/PageAutoSavings']: ['auto-savings'],
    ['/content/PageGeneralConditions']: ['general-conditions'],
    ['/content/PageImpressum']: ['impressum'],
    ['/content/PagePurchasingLimit']: ['purchasing-limit'],
    ['/content/PagePrivacyPolicy']: ['privacy'],
    ['/content/PageReferralsTermsConditions']: ['referral-terms'],
    ['/content/PageReferrals']: ['referrals'],
    ['/content/PageSilverLanding']: ['silver-landing'],
    ['/content/PageStorageSolution']: ['storage-solution'],
    ['/saving-assistant/PageSavingAssistant']: ['saving-assistant'],
    ['/precious-metal-guide/PagePreciousMetalGuide']: ['precious-metal'],
    ['/precious-metal-guide/[slug]']: ['precious-metal'],
    ['/precious-metal-guide/[slug]/[subSlug]']: ['precious-metal'],
    ['/precious-metal-guide/[slug]/[subSlug]/[subSubSlug]']: ['precious-metal'],
    ['/blog/PageBlogHome']: ['blog', 'blog-home'],
    ['/blog/PageBlogSearch']: ['blog', 'blog-home'],
    ['/blog/categories/[slug]']: ['blog', 'blog-home'],
    ['/blog/tags/[slug]']: ['blog', 'blog-home'],
    ['/blog/[category]/[slug]']: ['blog', 'blog-article'],
    ['/product/shop/PageHomeShop']: ['home-shop', 'shop'],
    ['/product/shop/[metal]']: ['shop'],
    ['/product/PageSearch']: ['search'],
    ['/profile/email-unsubscribe/[token]/edit']: ['email-unsubscribe'],
    ['/product/PageProductShow/[metal]/product/[slug]']: ['product-show'],
    ['/product/PageShopSubcategory/[metal]/[slug]']: ['shop-subcategory', 'shop'],
    ['/profile/PageEmergencyPeople']: ['emergency-people', 'profile'],
    ['/profile/PagePasswordSettings']: ['password-settings', 'profile'],
    ['/profile/PageProfile']: ['page-profile', 'profile'],
    ['/profile/PageBillingAddress']: ['billing-address', 'profile'],
    ['/profile/PageShippingAddress']: ['shipping-address', 'profile'],
    ['/profile/PageAccountTransactions']: ['account-transactions'],
    ['/profile/PageTwoStepsAuthSms']: ['two-steps-auth', 'two-steps-auth-sms'],
    ['/profile/PageTwoStepsAuthTotp']: ['two-steps-auth', 'two-steps-auth-totp'],
    ['/profile/PageVerifyAuth']: ['two-steps-auth', 'verify-auth'],
    ['/profile/PagePayments']: ['payments'],
    ['/profile/PageDeposit']: ['deposit'],
    ['/profile/PageWithdrawal']: ['withdrawal'],
    ['/auth/PageSignUp']: ['sign-up'],
    ['/auth/PageKYC']: [
      'kyc',
      'sign-up',
      'onfido',
      'kyc-date-of-birth',
      'kyc-phone-number',
      'kyc-exposed-person',
      'kyc-occupation',
      'kyc-business-sector',
      'kyc-annual-income',
      'kyc-purchasing-limit',
      'kyc-forecasted-annual-investments',
      'kyc-sources-of-funds',
      'kyc-location',
      'proof-of-address',
      'photo-identification',
      'done-kyc',
      'choice-purchasing-limit',
      'nationality',
      'residence',
    ],
    ['/auth/PageSignIn']: ['sign-in'],
    ['/auth/PageChangePassword']: ['change-password'],
    ['/checkout/PageCheckout']: [
      'checkout',
      'checkout-shipping',
      'checkout-billing',
      'checkout-payment',
      'checkout-review',
    ],
    ['/auth/PageForgottenPassword']: ['forgotten-password'],
    ['/auth/PageResendInstructions']: ['resend-instructions'],
    ['/auth/PageUnlockAccount']: ['unlock-account'],
    ['/dashboard/PageAlertSettings']: ['alerts-settings'],
    ['/dashboard/PageClaimsBreakdown']: ['claims', 'delivery-claims-breakdown'],
    ['/dashboard/PageClaimsDelivery']: [
      'claims',
      'checkout',
      'delivery-claims-delivery',
      'profile',
    ],
    ['/dashboard/PageClaimsBillingAddress']: ['claims', 'delivery-claims-billing-address'],
    ['/dashboard/PageClaimsPaymentMethod']: [
      'claims',
      'delivery-claims-payment-method',
      'checkout',
      'checkout-shipping',
      'checkout-billing',
      'checkout-payment',
      'checkout-review',
    ],
    ['/dashboard/PageClaimsOrderReview']: [
      'claims',
      'delivery-claims-order-review',
      'checkout',
      'checkout-shipping',
      'checkout-billing',
      'checkout-payment',
      'checkout-review',
    ],
    ['/dashboard/thankYou/[claimId]']: ['claims', 'delivery-claims-thank-you', 'checkout'],
    ['/checkout/[orderId]']: ['checkout', 'checkout-thank-you'],
    ['/dashboard/PageGifts']: ['gifts', 'gift-list'],
    ['/dashboard/PageRecipient']: ['gifts', 'gift-recipient'],
    ['/dashboard/PageGiftSubmit']: ['gifts', 'gift-submit'],
    ['/dashboard/PageGiftConfirmation']: ['gifts', 'gift-confirmation'],
    ['/dashboard/PageStorageFees']: ['storage-fees', 'checkout'],
    ['/gifts/[reference]']: ['gifts', 'gift-pending'],
    ['/gifts/details/[reference]']: ['gifts', 'gift-detail', 'payment'],
    ['/dashboard/PageWishList']: ['wishlist'],
    ['/tutorial-videos/PageTutorialVideos']: ['tutorial-videos'],
    ['/tutorial-videos/[slug]']: ['tutorial-video'],
    ['/wallet/PageWallet']: ['wallet', 'wallet-page'],
    ['/wallet/PageWalletOrders']: ['wallet', 'wallet-orders'],
    ['/wallet/PageWalletOrderShow/[id]']: ['wallet', 'wallet-order-show', 'checkout'],
    ['/wallet/PageWalletGifts']: ['wallet', 'wallet-gifts'],
    ['/wallet/PageWalletGiftShow/[reference_number]']: ['wallet', 'wallet-gift-show'],
    ['/wallet/PageWalletClaims']: ['wallet', 'wallet-claims'],
    ['/wallet/PageWalletClaimShow/[id]']: ['wallet', 'wallet-claim-show', 'checkout'],
    ['/wallet/PageWalletAutoSavings']: ['wallet-auto-savings', 'wallet'],
    ['/wallet/PageWalletAutoSavingsShow/[id]']: [
      'wallet-auto-savings',
      'wallet-auto-savings-show',
      'wallet',
      'checkout',
    ],
    ['/wallet/PageWalletSell']: ['wallet-sell', 'wallet'],
    ['/wallet/PageWalletSellReview']: ['wallet-sell', 'wallet-sell-review', 'wallet'],
    ['/wallet/PageWalletSellThanks']: ['wallet-sell-thanks', 'wallet'],
    ['/cart/PageCart']: [
      'cart',
      'checkout',
      'checkout-shipping',
      'checkout-billing',
      'checkout-payment',
      'checkout-review',
      'payments',
    ],
    ['/cart/PageAbandonedCart']: ['abandonedCart'],
    ['/faq/PageFaq']: ['faq', 'faq-list'],
    ['/faq/PageFaqShow/[slug]']: ['faq', 'faq-category'],
    ['/faq/PageFaqSearch']: ['faq', 'faq-search'],
    ['/contact/PageContact']: ['contact'],
    ['/contact/PageContactForm']: ['contact-form'],
    ['/r/[slug]']: ['home'],
    ['/maintenance']: ['maintenance'],
    ['/redirection/PageSaferpayRedirection']: ['checkout'],
    ['/redirection/PageSaferpayTransaction']: ['checkout'],
    ['/404']: ['not-found'],
    ['/500']: ['internal-error'],
    ['/400']: ['bad-request'],
  },
  interpolation: {
    prefix: '${',
    suffix: '}',
  },
  loadLocaleFrom: (locale, namespace) =>
    import(`./public/locales/${getLanguageTags(locale)}/${namespace}`).then((m) => m.default),
};
