import { useContextGTM } from '../context-gtm';
import { getEventOriginUrl, pushGTMEvent } from '../helpers';
import { GTMEventUserInteraction } from '../interface-google-tag-manager';

export const useEventUserInteraction = () => {
  const { enable, baseDatalayerProps } = useContextGTM();

  const pushEventUserInteraction = (event: GTMEventUserInteraction) => {
    if (enable) {
      pushGTMEvent({
        ...baseDatalayerProps,
        event,
        eventOrigin: getEventOriginUrl(baseDatalayerProps),
      });
    }
  };

  return { pushEventUserInteraction };
};
