import styled, { CSSObject } from 'styled-components';

import { WithThemeProps } from '@core/type';

import { Typography } from '../typography';
import { SkeletonTypographyProps } from './interface-skeleton';

export const SkeletonTypography = styled(Typography)<SkeletonTypographyProps>((
  props: SkeletonTypographyProps & WithThemeProps,
) => {
  const {
    theme: {
      typography: { defaultVariant, ...restTypography },
    },
    variant,
    width,
    lineHeight,
    ...restProps
  } = props;
  return {
    height: lineHeight || restTypography[variant || defaultVariant].lineHeight,
    ...restProps,
  } as CSSObject;
});
