import { MouseEventHandler } from 'react';

import { Box } from '@core/component';

import { MenuItemProps } from './interface-header-menu';

export const MenuItemContainer = ({
  children,
  onHover,
  shouldShowBorder,
  index,
}: MenuItemProps) => {
  const handleHover: MouseEventHandler<HTMLDivElement> = ({ currentTarget }) => {
    if (!onHover) {
      return;
    }
    const { offsetLeft, offsetWidth } = currentTarget as HTMLDivElement;

    if (shouldShowBorder) {
      onHover({
        styleProps: { width: offsetWidth, left: offsetLeft },
        index,
      });
    } else {
      onHover({
        styleProps: { width: offsetWidth, left: offsetLeft },
        index,
      });
    }
  };

  return (
    <Box onMouseEnter={handleHover} height={'24px'}>
      {children}
    </Box>
  );
};
