import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconGift = ({ fill }: DynamicIconProps) => (
  <SVG height="30" width="30" viewBox="0 0 30 30" title="Gift icon">
    <rect id="backgroundrect" width="100%" height="100%" x="0" y="0" fill="none" stroke="none" />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M14 25V14H6v10a1 1 0 0 0 1 1zm2 0h7a1 1 0 0 0 1-1V14h-8zm-2-13v-2H5a1 1 0 1 0 0 2zm2 0h9a1 1 0 0 0 0-2h-9zM8.464 8l-.05-.05a3 3 0 0 1 4.243-4.243l2.5 2.5 2.5-2.5a3 3 0 0 1 4.242 4.243l-.05.05H25a3 3 0 0 1 1 5.83V24a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V13.83A3.001 3.001 0 0 1 5 8zm2.827 0h2.177a1 1 0 0 1 .214-.318l.06-.06-2.5-2.5a1 1 0 0 0-1.414 1.414l1.43 1.43q.018.016.033.034m4.94 0h2.79l1.464-1.464a1 1 0 1 0-1.414-1.415l-2.827 2.827z"
    />
  </SVG>
);
