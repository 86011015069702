export const content = {
  SILVER_LANDING_PAGE_PRICE: 15000,
  PRIORITY_PAYMENT_FEE: 3,
};
export const ARTICLES_TO_SHOW = 9;
export const MIN_SEARCH_SYMBOLS = 3;
export const PRODUCT_BLOCK_SPACING = 56;
export const PRODUCT_BLOCK_SPACING_MOBILE = 48;
export const FAQ_SEARCH_PER_PAGE = 16;
export const ACCOUNT_TRANSACTION_PER_PAGE = 25;
export const SEARCH_PRODUCT_PER_PAGE = 16;
export const SUBCATEGORY_PRODUCTS_PER_PAGE = 24;
export const INVOICES_PER_PAGE_STEP = 6;
export const HISTORY_CLAIM_PER_PAGE = 10;
export const HISTORY_ORDER_PER_PAGE = 10;
export const HISTORY_GIFT_PER_PAGE = 10;
