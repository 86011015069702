import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type { TrustpilotTotalUsersEntity } from '@core/type/api';

import { Api } from '../constants/api';
import { configResponse, EConfigName } from '../helpers/config';

const getTotalUsers = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<TrustpilotTotalUsersEntity>> =>
  axiosInstance.get(
    Api.trustpilot.TOTAL_USERS,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

export class TrustpilotAPI {
  public static readonly getTotalUsers = getTotalUsers;
}
