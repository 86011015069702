import { Box } from '@core/component';
import { useContextMediaQuery } from '@core/context';

import { ButtonSearch, Search } from '../search';

export const SearchBlock = ({
  isSearchOpen,
  handleSearchClose,
  handleSearchOpen,
}: {
  isSearchOpen: boolean;
  handleSearchClose: () => void;
  handleSearchOpen: () => void;
}) => {
  const { isMobile } = useContextMediaQuery();

  if (isMobile) {
    return (
      <Box display={'flex'} alignItems={'center'}>
        <ButtonSearch isOpen={isSearchOpen} onClose={handleSearchClose} onOpen={handleSearchOpen} />

        <Box
          opacity={isSearchOpen ? 1 : 0}
          marginLeft={isSearchOpen ? '16px' : '0'}
          width={isSearchOpen ? '300px' : '0'}
          transition={'all 0.3s'}
        >
          <Search isLight />
        </Box>
      </Box>
    );
  }

  return <Box />;
};
