import { FC, useState, lazy, Suspense } from 'react';

import { Box } from '@core/component';

import { Label } from './Label';
import { UserMenuProps } from './interface-user-menu';

const DropdownLazy = lazy(() =>
  // eslint-disable-next-line @typescript-eslint/naming-convention
  import('./Dropdown').then(({ Dropdown }) => ({ default: Dropdown })),
);

export const UserMenu: FC<UserMenuProps> = ({ fullName, isError }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Box position={'relative'}>
      <Label isError={isError} fullName={fullName} onOpen={handleOpen} />
      {isOpen && (
        <Suspense>
          <DropdownLazy onClose={handleClose} />
        </Suspense>
      )}
    </Box>
  );
};
