import Image from 'next/image';

import Icon2StepAuth2Svg from './svgs/ga-logo-with-background.svg';

export const IconGALogoWithBackground = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={Icon2StepAuth2Svg}
    alt="GA logo with background"
    width={65}
    height={65}
  />
);
