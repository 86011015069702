import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconPhone = ({ stroke }: DynamicIconProps) => (
  <SVG height={24} width={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.7116 7.21142C10.0526 7.55383 10.2442 8.01821 10.2442 8.50241C10.2442 8.98661 10.0526 9.45099 9.7116 9.7934L9.16764 10.3395L9.18665 10.3694C9.6248 11.035 10.3068 11.8326 11.2153 12.7447C12.1578 13.6909 12.9927 14.4071 13.6871 14.8611L14.2212 14.3216C14.3901 14.152 14.5906 14.0174 14.8113 13.9256C15.032 13.8338 15.2686 13.7865 15.5075 13.7865C15.7464 13.7865 15.983 13.8338 16.2037 13.9256C16.4244 14.0174 16.6249 14.152 16.7938 14.3216L17.6371 15.1682C17.8406 15.3726 17.9669 15.6418 17.9943 15.9295C18.0218 16.2172 17.9487 16.5056 17.7875 16.7451L17.6594 16.8903C17.566 16.9949 17.4651 17.1003 17.3585 17.2074L17.4097 17.1551L17.3353 17.2314C15.839 18.6988 13.0696 18.1411 9.56279 14.8993L9.34207 14.6926C5.71125 11.25 5.31444 8.1725 6.8372 6.64456C6.94302 6.53667 7.04883 6.43707 7.15217 6.34246L7.29767 6.21299C7.53633 6.0513 7.8236 5.97802 8.11019 6.00574C8.39678 6.03345 8.66484 6.16042 8.86838 6.36487L9.7116 7.21142V7.21142Z"
      strokeWidth={1.2}
      fill={'none'}
      stroke={stroke}
    />
  </SVG>
);
