import Router, { useRouter } from 'next/router';

import { useStoreRouting } from '@core/context';
import { useLanguage } from '@core/hook';
import { DefaultRoute, ELanguage, ERouting, LinkNext, PathTranslationsType } from '@core/type';
import { HookStoreFn, RoutingHook, RoutingState } from '@core/type/context';
import { getDefaultKey, getKey, redirect as redirectFn } from '@core/util';

export const redirectNext = (
  link: LinkNext,
  keyRoute: ERouting,
  language?: ELanguage,
): Promise<boolean> => {
  return Router.push(link.href, undefined, {
    shallow: true,
    scroll: true,
    locale:
      (language === ELanguage.EN || language === ELanguage.DEFAULT) && keyRoute === ERouting.HOME
        ? ELanguage.DEFAULT
        : language === ELanguage.DEFAULT
          ? ELanguage.EN
          : language,
  });
};

export const useRouting: HookStoreFn<RoutingState, RoutingHook> = (): RoutingHook => {
  const { locale, language } = useLanguage();
  const [isLoading, setIsLoading] = useStoreRouting<boolean>('isLoading');
  const [pathTranslations] = useStoreRouting<PathTranslationsType>('pathTranslations');
  const [defaultRoute] = useStoreRouting<DefaultRoute>('defaultRoute');
  const { query: queryRouter, pathname, asPath } = useRouter();

  const setRoute = (
    key: ERouting,
    queries?: NodeJS.Dict<string | string[]>,
    localeParams?: ELanguage,
  ) => {
    void redirect(key, queries || {}, localeParams);
  };

  const setQuery = (queries?: NodeJS.Dict<string | string[]>, localeParams?: ELanguage) => {
    void redirect(getDefaultKey(pathname, defaultRoute), queries || {}, localeParams);
  };

  const redirect = async (
    keyRoute: ERouting,
    query?: NodeJS.Dict<string | string[]>,
    localeParams?: ELanguage,
  ): Promise<void> => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        await redirectFn(
          keyRoute,
          pathTranslations,
          defaultRoute,
          redirectNext,
          localeParams || locale,
          query,
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getKeyRoute = (route: string) => {
    return route ? getKey(pathTranslations[language], route) : null;
  };

  return {
    keyRoute: getKeyRoute(asPath) || getDefaultKey(pathname, defaultRoute),
    query: queryRouter,
    setRoute,
    pathTranslations,
    defaultRoute,
    setQuery,
    isLoading,
    setIsLoading,
    redirect,
  };
};
