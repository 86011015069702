import { themeContainerMaxWidth } from '@core/constant';
import type { ThemeDevice, ThemeDeviceReturn, ThemeStructure } from '@core/type';
import { ESize } from '@core/type';

import { themeXL } from './theme-xl';

const themeStructureLG = (baseTheme: ThemeDevice): ThemeStructure => ({
  ...baseTheme.structure,
  charts: {
    ...baseTheme.structure.charts,
    livePriceDisplay: 'block',
    chartPaper: { ...baseTheme.structure.charts.chartPaper, maxWidth: '747px' },
  },
  container: {
    ...baseTheme.structure.container,
    maxWidth: themeContainerMaxWidth[ESize.LG],
  },
  header: {
    ...baseTheme.structure.header,
    maxWidth: '980px',
    option: { ...baseTheme.structure.header.option, flexDirection: 'column' },
    short: { ...baseTheme.structure.header.short, animation: '96px' },
    height: '222px',
    animation: '95px',
  },
  footer: {
    ...baseTheme.structure.footer,
    subscribe: { ...baseTheme.structure.footer.subscribe },
  },
});

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const themeLG: ThemeDeviceReturn = (baseTheme: ThemeDevice = themeXL()): ThemeDevice => ({
  typography: baseTheme.typography,
  structure: themeStructureLG(baseTheme),
});
