import { useContextMediaQuery } from '@core/context';
import { EActionAttribute, ETypographyVariant } from '@core/type';

import { Box } from '../../layout';
import { SkeletonTypography } from '../SkeletonTypography';
import { SkeletonWrapper } from '../SkeletonWrapper';

export const FaqHeader = () => {
  const { isPhone, isDesktop } = useContextMediaQuery();

  return (
    <SkeletonWrapper
      marginTop={isPhone ? undefined : '40px'}
      padding={isPhone ? '44px 20px' : '50px 95px'}
      position={'relative'}
      overflow={'visible'}
    >
      <SkeletonTypography
        variant={ETypographyVariant.H2}
        maxWidth={isPhone ? '250px' : '380px'}
        marginBottom={isPhone ? '24px' : '30px'}
      />
      <SkeletonTypography lineHeight={'48px'} width={isPhone ? '100%' : '530px'} />

      <Box
        as={EActionAttribute.SPAN}
        position={'absolute'}
        top={isDesktop ? '20px' : '30px'}
        right={isDesktop ? '-50px' : '-6px'}
        height={isDesktop ? '170px' : '60px'}
        width={isDesktop ? '150px' : '46px'}
      />
    </SkeletonWrapper>
  );
};
