import useTranslation from 'next-translate/useTranslation';
import { ChangeEvent, FC, KeyboardEvent, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { Translate } from '@core/constant';
import { useScrollDirection } from '@core/hook';
import { EColor, ESize, ThemeProps } from '@core/type';
import { getPaletteHandlers } from '@core/util';

import { ButtonBase } from '../button/ButtonBase';
import { ButtonBaseProps } from '../button/interface-button';
import { Icon } from '../icon';
import { InputExtendableProps } from './interface-input';

export const InputExtendable: FC<InputExtendableProps> = ({
  icon,
  placeholder,
  onChange,
  onPressEnter,
  isOpenInput,
  value,
  setIsOpenInput,
}) => {
  const input = useRef<HTMLInputElement>();
  const { isDown, isUp } = useScrollDirection(0);
  const { t: tAria } = useTranslation(Translate.common.ARIA);

  useEffect(() => {
    if (isDown || isUp) {
      setIsOpenInput(false);
    }
  }, [setIsOpenInput, isDown, isUp]);

  const handleClick = () => {
    if (!isOpenInput) {
      setIsOpenInput(true);
      if (input?.current) {
        input.current.focus();
      }
    } else {
      onPressEnter();
    }
  };

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    onChange(value);
  };

  const handleKeyDown = ({ key }: KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') {
      onPressEnter();
    }
  };

  return (
    <>
      <StyledInput
        expanded={isOpenInput}
        placeholder={placeholder}
        ref={input}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={value}
      />

      <ButtonStyled onClick={handleClick} expanded={isOpenInput} aria-label={tAria('searchInput')}>
        <Icon
          type={icon}
          color={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
          size={ESize.MD}
        />
      </ButtonStyled>
    </>
  );
};

const StyledInput = styled.input<{
  expanded: boolean;
  theme: ThemeProps;
}>(
  ({
    theme: {
      palette,
      structure: {
        autocomplete: { inputWidth },
      },
      typography: { autocomplete },
    },
    expanded,
  }: {
    expanded: boolean;
    theme: ThemeProps;
  }) => {
    const { getTextColor, getBackgroundColor } = getPaletteHandlers(palette);
    const backgroundLight = getBackgroundColor({
      semanticType: EColor.NEUTRAL,
      variant: EColor.LIGHT,
    });

    return `
  transition: width 0.9s cubic-bezier(0.79, 0.14, 0.15, 0.86),
  opacity 0.5s cubic-bezier(0.79, 0.14, 0.15, 0.86);
  padding: 0;
  line-height: 20px;
  height: 32px;
  color: ${getTextColor(EColor.SECONDARY)};
  border: none;
  box-shadow: none;
  font-size:${autocomplete.fontSize};
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  width: 0px;
  opacity: 0;
  outline: none;
  ::placeholder {
    color: ${backgroundLight};
  }
  :focus {
    background: transparent;
    ${expanded && `background: ${backgroundLight};`}
    ${expanded && `color: ${getTextColor(EColor.PRIMARY)};`}
    ::placeholder {
      color: transparent;
      ${expanded && `color: ${getTextColor(EColor.SECONDARY)};`}
    }
  }
  div {
    width: inherit;
  }
  ${
    expanded
      ? `
    background: ${getBackgroundColor({ commonType: EColor.BLUE, intensity: EColor.A700 })};
    color: ${getTextColor({ commonType: EColor.WHITE, intensity: EColor.R900 })};
    opacity: 1;
    padding: 0 12px;
    width: ${inputWidth};
    max-width: 450px
  `
      : ''
  }
`;
  },
);

const ButtonStyled = styled(ButtonBase)<ButtonBaseProps & { expanded: boolean }>(
  ({
    expanded,
    theme: {
      palette,
      structure: {
        header: { minWidthSearchButton },
      },
    },
  }: {
    expanded: boolean;
    theme: ThemeProps;
  }) => css`
    height: 32px;
    background-color: transparent;
    border: 0;
    min-width: ${expanded ? '56px' : minWidthSearchButton};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    padding: 0;
    border-radius: 0 6px 6px 0;
    transition: background 0.5s cubic-bezier(0.79, 0.14, 0.15, 0.86);
    transition: min-width 0.5s cubic-bezier(0.79, 0.14, 0.15, 0.86);
    ${expanded &&
    `
      background: ${palette[EColor.BLUE][EColor.A700]};
    `}
  `,
);
