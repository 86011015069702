import { Typography } from '@core/component';
import { EActionAttribute, EFontWeight } from '@core/type';

export const formatSuggestion = (suggestion: string, searchTerm: string) => {
  if (!searchTerm) {
    return <>{suggestion}</>;
  }

  const regex = new RegExp(`(${searchTerm})`, 'gi'); // Global and case insensitive match
  const parts = suggestion.split(regex); // Split the suggestion into an array of alternating non-matching and matching segments

  return (
    <>
      {parts.map(
        (part, index) =>
          part.toLowerCase() === searchTerm.toLowerCase() ? (
            <Typography
              as={EActionAttribute.STRONG}
              fontWeight={EFontWeight.BOLD}
              key={`${part}${index}`}
            >
              {part}
            </Typography>
          ) : (
            part
          ), // Return non-matching text as is
      )}
    </>
  );
};
