import type { QueryParams } from '@sanity/client';
import { SanityClient, createClient } from 'next-sanity';

import { formatSanityData } from './format';

export const sanityClient: SanityClient = createClient({
  projectId: process.env.SANITY_PROJECT_ID,
  dataset: process.env.SANITY_DATASET,
  apiVersion: process.env.SANITY_API_VERSION,
  useCdn: process.env.SANITY_ENABLE_CDN === 'true',
  perspective: 'published',
  encodeSourceMap: true,
  studioUrl: '/studio',
});

const DEFAULT_PARAMS = {} as QueryParams;

// Utility for fetching data on the server, that can toggle between published and preview drafts
export async function sanityFetch<QueryResponse>({
  previewDrafts,
  query,
  params = DEFAULT_PARAMS,
}: {
  previewDrafts?: boolean;
  query: string;
  params?: QueryParams;
}): Promise<QueryResponse> {
  const sanityToken = process.env.SANITY_TOKEN;

  if (previewDrafts && !sanityToken) {
    throw new Error('The `SANITY_TOKEN` environment variable is required.');
  }

  return sanityClient
    .fetch<QueryResponse>(
      query,
      params,
      previewDrafts
        ? {
            token: sanityToken,
            perspective: 'previewDrafts',
          }
        : {},
    )
    .then((response) => formatSanityData(response));
}
