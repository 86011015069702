import styled from 'styled-components';

import { ButtonBase, Icon, Link } from '@core/component';
import {
  ColorProps,
  EActionAttribute,
  EColor,
  ETypographyVariant,
  TextColorType,
} from '@core/type';

import { SelectButtonProps } from './interface-select';

export const SelectButton = ({
  image,
  iconType,
  isDarkBackground,
  isActive,
  children,
  name,
  onClick,
}: SelectButtonProps) => {
  const linkColor = isDarkBackground ? EColor.WHITE : isActive ? EColor.ACCENT : EColor.PRIMARY;

  return (
    <StyledButtonBase onClick={onClick} name={name}>
      <Link
        as={EActionAttribute.SPAN}
        variant={ETypographyVariant.BODY3}
        color={linkColor}
        isUnderlined={isActive && isDarkBackground}
        iconMargin={8}
        customStartIcon={
          image ?? (
            <Icon
              display={'inline-block'}
              type={iconType}
              height={'15px'}
              width={'15px'}
              flexShrink={'0'}
              color={linkColor as ColorProps<TextColorType>}
            />
          )
        }
      >
        {children}
      </Link>
    </StyledButtonBase>
  );
};

const StyledButtonBase = styled(ButtonBase)`
  all: unset;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;
