import { usePathname, useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { AFFILIATION_DAYS } from '@core/constant';
import { EStorageKey, setItem } from '@core/storage';
import { EStorageType } from '@core/type';

export function useSetCookiesFromRelevantQueryParams(): void {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const affiliateKey = searchParams.get(EStorageKey.AFFILIATE_SLUG);

  useEffect(() => {
    if (affiliateKey) {
      setItem(EStorageType.COOKIE, EStorageKey.AFFILIATE_SLUG, affiliateKey, AFFILIATION_DAYS);

      const nextSearchParams = new URLSearchParams(searchParams.toString());
      nextSearchParams.delete(EStorageKey.AFFILIATE_SLUG);

      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      void router.replace(`${pathname}?${nextSearchParams}`);
    }
  }, []);
}
