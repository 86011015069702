import { useEffect, useState } from 'react';

import { useContextRouting } from '@core/context';

export const usePageLoading = () => {
  const { keyRoute, query } = useContextRouting();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(false);
  }, [keyRoute, query]);

  const startLoading = () => {
    setIsLoading(true);
  };

  return { isLoading, startLoading };
};
