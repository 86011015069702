import styled from 'styled-components';

import { EPosition, WithThemeProps } from '@core/type';

export const PopupWrapper = styled.div(
  ({
    theme: {
      structure: {
        toast: {
          wrapper: { placement: placementVariants, ...restProps },
        },
        cartPopup: {
          wrapper: { ...cartPopupRestProps },
        },
      },
    },
  }: WithThemeProps) => ({
    ...placementVariants[EPosition.TOP_RIGHT],
    ...restProps,
    ...cartPopupRestProps,
    width: '100%',
  }),
);
