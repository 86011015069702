// z-index types
export enum EZIndexName {
  BACKGROUND = 'background',
  DROPDOWN = 'dropdown',
  FIXED = 'fixed',
  HEADER = 'header',
  DRAWER = 'drawer',
  MODAL_BACKGROUND = 'modalBackground',
  MODAL = 'modal',
  POPOVER = 'popover',
  TOOLTIP = 'tooltip',
  DEFAULT = 'default',
  OVER_DEFAULT = 'overDefault',
  AUTO = 'auto',
  HOVER_EFFECT = 'hoverEffect',
  ABOVE_CRISP = 'aboveCrisp',
}
