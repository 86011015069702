import { Box } from '@core/component';
import { HEADER_CONTAINER_WIDTH } from '@core/constant';
import { BackgroundColorType, ColorProps, EColor } from '@core/type';

import { HeaderPrice } from './HeaderPrice';

export const LiveChartsHeader = () => {
  const headerBackgroundColor: ColorProps<BackgroundColorType> = {
    semanticType: EColor.PRIMARY,
    variant: EColor.DARK,
  };

  return (
    <Box backgroundColor={headerBackgroundColor} display="flex" justifyContent="center">
      <Box width={HEADER_CONTAINER_WIDTH}>
        <HeaderPrice />
      </Box>
    </Box>
  );
};
