import { useStoreCartForm } from '@core/context';
import { ECountry } from '@core/type';
import { CartFormErrorsProps, CartFormHook, CartFormState, HookStoreFn } from '@core/type/context';

export const useCartForm: HookStoreFn<CartFormState, CartFormHook> = () => {
  const [country, setCountry] = useStoreCartForm<ECountry>('country');
  const [errors, setErrors] = useStoreCartForm<CartFormErrorsProps>('errors');

  return {
    errors,
    setErrors,
    country,
    setCountry,
  };
};
