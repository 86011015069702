import { FC } from 'react';
import styled, { CSSObject } from 'styled-components';

import {
  EActionAttribute,
  EButtonVariant,
  EColor,
  EFontWeight,
  ESize,
  ETypographyVariant,
  WithThemeProps,
} from '@core/type';
import { getButtonColorProps, getLinkColorProps, getPaletteHandlers } from '@core/util';

import { EditorDefaultContentProps, EEditorClassName } from './interface-editor';

export const EditorDefaultContent: FC<EditorDefaultContentProps> = styled.div(
  ({
    theme: {
      palette,
      structure: {
        button: { base: buttonBaseProps, size: buttonSizeProps },
        link: { base: linkBaseProps, underlineFirstPath, transition: linkTransition },
        line: { horizontal: horizontalLineProps },
      },
      typography: {
        fontWeight,
        button: { size: buttonTypography },
        ...typographyProps
      },
    },
    isPhone,
    isMobile,
  }: WithThemeProps & EditorDefaultContentProps) => {
    const { getColor, getBackgroundColor } = getPaletteHandlers(palette);
    const { hover: buttonHoverColorProps, ...restButtonColorProps } = getButtonColorProps({
      color: EColor.PRIMARY,
      palette,
      variant: EButtonVariant.CONTAINED,
    });
    const highlightedProps: CSSObject = {
      padding: isPhone ? '0 0 0 24px' : '0 0 0 48px',
      fontWeight: fontWeight[EFontWeight.REGULAR],
      fontStyle: 'italic',
      borderLeft: `solid 1.2px ${getColor(EColor.SECONDARY)}`,
    };
    const { hover: linkHoverColorProps, ...restLinkColorProps } = getLinkColorProps({
      color: EColor.ACCENT,
      palette,
    });

    return {
      [`${EActionAttribute.IMG},
      ${EActionAttribute.VIDEO},
      ${EActionAttribute.IFRAME}`]: {
        maxWidth: '100%',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      [EActionAttribute.HR]: {
        ...horizontalLineProps,
        backgroundColor: getColor({ commonType: EColor.GRAY, intensity: EColor.A200 }),
        margin: isPhone ? '16px auto' : '22px auto',
      },
      [EActionAttribute.FIGURE]: {
        maxWidth: '100% !important',

        [EActionAttribute.IMG]: {
          height: 'auto !important',
        },
      },
      [`${EActionAttribute.H1},
      ${EEditorClassName.H1}`]: {
        ...typographyProps[ETypographyVariant.H1],
      },
      [`${EActionAttribute.H2},
      .${EEditorClassName.H2}`]: {
        ...typographyProps[ETypographyVariant.H2],
      },
      [`${EActionAttribute.H3},
      .${EEditorClassName.H3}`]: {
        ...typographyProps[ETypographyVariant.H3],
      },
      [`${EActionAttribute.H4},
      .${EEditorClassName.H4}`]: {
        ...typographyProps[ETypographyVariant.H4],
      },
      [`${EActionAttribute.H5},
      .${EEditorClassName.H5}`]: {
        ...typographyProps[ETypographyVariant.H5],
      },
      [`${EActionAttribute.H6},
      .${EEditorClassName.H6}`]: {
        ...typographyProps[ETypographyVariant.H6],
      },
      [`${EActionAttribute.H1},
      ${EActionAttribute.H2},
      ${EActionAttribute.H3},
      ${EActionAttribute.H4},
      ${EActionAttribute.H5},
      ${EActionAttribute.H6}`]: {
        marginTop: 22,
        marginBottom: 11,
      },
      [`.${EEditorClassName.SMALL_TEXT}`]: {
        ...typographyProps[ETypographyVariant.BODY3],
      },
      [`.${EEditorClassName.SMALLER_TEXT}`]: {
        ...typographyProps[ETypographyVariant.CAPTION1],
      },
      [`.${EEditorClassName.FW_300}`]: {
        fontWeight: fontWeight[EFontWeight.LIGHT],
      },
      [`.${EEditorClassName.FW_400}`]: {
        fontWeight: fontWeight[EFontWeight.REGULAR],
      },
      [`.${EEditorClassName.FW_500}`]: {
        fontWeight: fontWeight[EFontWeight.MEDIUM],
      },
      [`${EActionAttribute.UL},
      ${EActionAttribute.OL}`]: {
        margin: '20px 0',
        paddingLeft: isMobile ? '28px' : '78px',

        [EActionAttribute.LI]: {
          paddingLeft: isMobile ? '13px' : '10px',
          marginBottom: '20px',

          '&:last-of-type': {
            marginBottom: 0,
          },
        },
      },
      [`${EActionAttribute.UL} ${EActionAttribute.LI}::marker`]: {
        fontSize: '22px',
      },
      [`${EActionAttribute.OL} ${EActionAttribute.LI}::marker`]: {
        fontSize: '18px',
        fontWeight: fontWeight[EFontWeight.BOLD],
      },
      [EActionAttribute.P]: {
        ...typographyProps[ETypographyVariant.BODY2],
        marginBottom: isPhone ? '11px' : '16px',
      },
      [EActionAttribute.LINK]: {
        ...linkBaseProps,
        ...underlineFirstPath,
        ...restLinkColorProps,
        transition: linkTransition,

        '&:hover': {
          ...linkHoverColorProps,
        },
      },
      [EActionAttribute.STRONG]: {
        fontWeight: fontWeight[EFontWeight.BOLD],
      },
      [EActionAttribute.BLOCKQUOTE]: {
        ...highlightedProps,
        margin: isPhone ? '72px 0' : '72px 64px',
        fontSize: isPhone ? '20px' : '24px',
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        lineHeight: isPhone ? 1.4 : 1.33,
      },
      [`.${EEditorClassName.HIGHLIGHTED_BLUE_LINE}`]: {
        ...highlightedProps,
        [`${EActionAttribute.P},
        ${EActionAttribute.EM}`]: {
          fontSize: '18px',
          lineHeight: 1.55,
        },
        [`${EActionAttribute.P}:last-of-type`]: {
          marginBottom: 0,
        },
      },
      [`.${EEditorClassName.HIGHLIGHTED_BLUE_BG}`]: {
        margin: '72px auto',
        maxWidth: '594px',
        padding: '48px',
        borderRadius: '8px',
        backgroundColor: getBackgroundColor(EColor.INFO),

        [`&, ${EActionAttribute.P}`]: {
          ...typographyProps[ETypographyVariant.BODY2],
          fontWeight: fontWeight[EFontWeight.REGULAR],
        },
      },
      [`.${EEditorClassName.BTN}`]: {
        display: 'inline-block',
        ...buttonBaseProps,
        ...buttonSizeProps[ESize.LG],
        ...buttonTypography[ESize.MD],
        ...restButtonColorProps,

        '&:hover': {
          ...buttonHoverColorProps,
        },
      },
      [`.${EEditorClassName.LS_MINUS_03}`]: {
        letterSpacing: '-0.3px',
      },
      [`.${EEditorClassName.LS_03}`]: {
        letterSpacing: '0.3px',
      },
      [`.${EEditorClassName.LS_05}`]: {
        letterSpacing: '0.5px',
      },
      [`.${EEditorClassName.LS_1}`]: {
        letterSpacing: '1px',
      },
      [`.${EEditorClassName.LS_2}`]: {
        letterSpacing: '2px',
      },
      '*:first-child': {
        marginTop: 0,
      },
      '*:last-child': {
        marginBottom: 0,
      },
    } as CSSObject;
  },
);
