import Image from 'next/image';

import IconChatWithTextSvg from './svgs/icon-chat-with-text.svg';

export const IconChatWithText = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconChatWithTextSvg}
    alt="Chat icon"
    width={35}
    height={35}
  />
);
