import { EStorageType } from '@core/type';

import { EStorageKey } from '../constants';
import { getItem, logStorage, removeItem, setItem } from '../helpers';

const getASBannerStates = (storage: EStorageType) => {
  logStorage('getASBannerStates');
  return getItem(storage, EStorageKey.AS_BANNER_STATES);
};

const setASBannerStates = (storage: EStorageType, value: string) => {
  logStorage('setASBannerStates');
  setItem(storage, EStorageKey.AS_BANNER_STATES, value);
};

const removeASBannerStates = (storage: EStorageType) => {
  logStorage('removeASBannerStates');
  removeItem(storage, EStorageKey.AS_BANNER_STATES);
};

const getSFBannerState = (storage: EStorageType): string => {
  logStorage('getSFBannerState');
  return getItem(storage, EStorageKey.STORAGE_FEES_BANNER_STATE);
};

const setSFBannerState = (storage: EStorageType, value: string) => {
  logStorage('setSFBannerState');
  setItem(storage, EStorageKey.STORAGE_FEES_BANNER_STATE, value);
};

const removeSFBannerState = (storage: EStorageType) => {
  logStorage('removeSFBannerState');
  removeItem(storage, EStorageKey.STORAGE_FEES_BANNER_STATE);
};

export class UserStorage {
  public static readonly getASBannerStates = getASBannerStates;
  public static readonly setASBannerStates = setASBannerStates;
  public static readonly removeASBannerStates = removeASBannerStates;
  public static readonly getSFBannerState = getSFBannerState;
  public static readonly setSFBannerState = setSFBannerState;
  public static readonly removeSFBannerState = removeSFBannerState;
}
