import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconEye = ({ fill }: DynamicIconProps) => (
  <SVG height={24} width={24} viewBox="0 0 24 24" title="Eye icon">
    <path
      fill={fill}
      fillOpacity=".5"
      fillRule="evenodd"
      d="M12 16c2.95 0 5.576-1.589 6.833-4C17.576 9.589 14.95 8 12 8s-5.576 1.589-6.833 4C6.424 14.411 9.05 16 12 16zm-8.82-4.41C4.663 8.224 8.122 6 12 6s7.337 2.224 8.82 5.59L21 12l-.18.41C19.337 15.776 15.878 18 12 18s-7.337-2.224-8.82-5.59L3 12l.18-.41zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
    />
  </SVG>
);
