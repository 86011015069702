import { createContext } from 'react';

import type { CartHook, CartState, HookStore } from '@core/type/context';

import { useFnContext, useStoreContext } from '../util';

export const cartContext =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createContext<HookStore<any, CartState, CartHook>>(null);

export const useContextCart = () => {
  return useFnContext(cartContext);
};

export function useStoreCart<Selector>(selector: keyof CartState) {
  return useStoreContext<Selector, CartState, CartHook>(selector, cartContext);
}
