/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, MouseEventHandler } from 'react';

import { EColor, ERouting } from '@core/type';

import { ErrorDot } from '../error-dot';
import { Box } from '../layout';
import { Link } from '../link';
import { LinkTranslatedStyledAsLink } from '../link-translated';
import { Typography } from '../typography';

const DropdownLink = ({ title, isInvalid }: { title: string; isInvalid?: boolean }) => (
  <Box padding={'8px 24px'} display={'flex'} alignItems={'center'} height={'40px'}>
    <Typography color={EColor.PRIMARY} textAlign="left" whiteSpace="nowrap">
      {title}
    </Typography>
    {isInvalid && (
      <Box marginLeft="32px">
        <ErrorDot />
      </Box>
    )}
  </Box>
);

const DropdownLineContainer = ({
  route,
  title,
  onClick,
  isInvalid,
}: {
  route?: ERouting;
  title: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  isInvalid?: boolean;
}) => {
  return route ? (
    <Box hoverStyles={{ backgroundColor: EColor.SECONDARY }}>
      <LinkTranslatedStyledAsLink keyRoute={route} color={EColor.ACCENT}>
        <DropdownLink title={title} isInvalid={isInvalid} />
      </LinkTranslatedStyledAsLink>
    </Box>
  ) : (
    <Box hoverStyles={{ backgroundColor: EColor.SECONDARY }}>
      <Link color={EColor.ACCENT} onClick={onClick}>
        <DropdownLink title={title} isInvalid={isInvalid} />
      </Link>
    </Box>
  );
};

export const DropdownLine = memo(DropdownLineContainer);
