import { getTranslationWithOrientation } from '@core/util';

import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconArrowDown = ({ stroke, orientation, yTranslation }: DynamicIconProps) => {
  return (
    <SVG
      height={16}
      width={12}
      viewBox="0 0 12 16"
      title="Arrow down"
      transform={getTranslationWithOrientation(orientation, yTranslation)}
    >
      <path
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinecap="round"
        d="M0.683112 9.94483L5.74073 15.0024M5.74073 15.0024L10.7983 9.94483M5.74073 15.0024V0.997553"
      />
    </SVG>
  );
};
