import Image from 'next/image';

import Icon2StepAuth3Svg from './svgs/icon-2-step-auth3.svg';

export const Icon2StepAuth3 = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={Icon2StepAuth3Svg}
    alt="2 step authentification 3rd step icon"
    width={57}
    height={56}
  />
);
