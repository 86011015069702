import type { ReactNode } from 'react';

import { grContext } from './context-gr';
import { useRecaptcha } from './use-recaptcha';

export const GRProvider = ({
  children,
  recaptchaKey,
}: {
  recaptchaKey?: string;
  children?: ReactNode;
}) => {
  return <grContext.Provider value={useRecaptcha(recaptchaKey)}>{children}</grContext.Provider>;
};
