export enum ESize {
  XXXS = 'xxxs',
  XXS = 'xxs',
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = 'xxl',
}

export type SizeType<Size extends Partial<ESize>> = {
  [key in Size]: Size;
};

export enum ESizeIcon {
  DEFAULT = 19,
}
