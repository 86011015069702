import { Translate as NextTranslate } from 'next-translate';
import {
  cloneElement,
  ComponentType,
  FC,
  LazyExoticComponent,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from 'react';

import {
  DefaultRoute,
  DeviceSize,
  ECurrency,
  EDevice,
  ELanguageTags,
  EPosition,
  ERouting,
  EStorageType,
  PathTranslationsType,
} from '@core/type';
import { DynamicSEOEntity, UserEntity } from '@core/type/api';
import { CartPopupProps, ToastItem, ToastObject, ToastPositionProps } from '@core/type/context';

import { ProviderAlternate } from './ProviderAlternate';
import { ProviderAuth } from './ProviderAuth';
import { ProviderBreadcrumb } from './ProviderBreadcrumb';
import { ProviderCart } from './ProviderCart';
import { ProviderCartForm } from './ProviderCartForm';
import { ProviderCartPopup } from './ProviderCartPopup';
import { ProviderCurrency } from './ProviderCurrency';
import { ProviderHeaderHeight } from './ProviderHeaderHeight';
import { ProviderMeta } from './ProviderMeta';
import { ProviderModal } from './ProviderModal';
import { ProviderProfile } from './ProviderProfile';
import { ProviderReactQuery } from './ProviderReactQuery';
import { ProviderRedirectAfterSignInRoute } from './ProviderRedirectAfterSignInRoute';
import { ProviderRouter } from './ProviderRouter';
import { ProviderTheme, ThemeProps } from './ProviderTheme';
import { ProviderToast } from './ProviderToast';
import { ProviderUser } from './ProviderUser';
import { ProviderUtil } from './ProviderUtil';

const nest = (children: ReactNode, component: ReactElement) =>
  cloneElement(component, {}, children);

export type ProviderMultiProps = PropsWithChildren<{
  providers: ReactElement[];
}>;

const ProviderMulti: FC<ProviderMultiProps> = ({ children, providers }) => (
  <>{providers.reduceRight(nest, children)}</>
);

export function ProviderApp<
  WrapperProps extends ToastPositionProps,
  ComponentProps extends ToastItem<ToastObject>,
>({
  children,
  keyNonce,
  csrfToken,
  cdn,
  themeMediaQueries,
  themeXL,
  themeLG,
  themeMD,
  themeSM,
  themeXS,
  themeColorList,
  deviceDetected,
  currencyInitialize,
  keyRouteInitialize,
  queryInitialize,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ToastComponent,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ToastComponentWrapper,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PopupComponentWrapper, // eslint-disable-next-line @typescript-eslint/naming-convention
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ToastComponentCart,
  pathTranslationsInitialize,
  defaultRouteInitialize,
  staleTimeClient,
  isBotDetected,
  recaptchaKey,
  apiHost,
  apiBase,
  apiVersion,
  isProductionServer,
  language,
  currency,
  tCommon,
  alternates,
  breadcrumbs,
  metas,
  isReady,
  isLogged,
  user,
  deviceType,
  clientIp,
}: {
  children: ReactNode;
  keyNonce: string;
  csrfToken: string;
  cdn: string;
  currencyInitialize: ECurrency;
  keyRouteInitialize: ERouting;
  deviceDetected?: DeviceSize;
  queryInitialize: NodeJS.Dict<string | string[]>;
  PopupComponentWrapper: FC;
  ToastComponentWrapper: FC<WrapperProps>;
  ToastComponent: LazyExoticComponent<FC<ComponentProps>>;
  ToastComponentCart: ComponentType<Omit<CartPopupProps, 'i18n'>>;
  pathTranslationsInitialize?: PathTranslationsType;
  defaultRouteInitialize?: DefaultRoute;
  recaptchaKey?: string;
  staleTimeClient?: number;
  isBotDetected?: boolean;
  apiHost?: string;
  apiBase?: string;
  apiVersion?: string;
  isProductionServer?: boolean;
  language: ELanguageTags;
  currency: ECurrency;
  tCommon?: NextTranslate;
  isLogged?: boolean;
  isReady?: boolean;
  user?: UserEntity;
  deviceType?: EDevice;
  clientIp: string;
} & ThemeProps &
  DynamicSEOEntity) {
  return (
    <ProviderMulti
      providers={[
        <ProviderReactQuery key={1} staleTimeClient={staleTimeClient} />,
        <ProviderRouter
          keyRouteInitialize={keyRouteInitialize}
          queryInitialize={queryInitialize}
          pathTranslationsInitialize={pathTranslationsInitialize}
          defaultRouteInitialize={defaultRouteInitialize}
          key={3}
        />,
        <ProviderTheme
          themeMediaQueries={themeMediaQueries}
          themeXL={themeXL}
          themeLG={themeLG}
          themeMD={themeMD}
          themeSM={themeSM}
          themeXS={themeXS}
          themeColorList={themeColorList}
          deviceDetected={deviceDetected}
          key={4}
        />,
        <ProviderToast
          Component={ToastComponent}
          Wrapper={ToastComponentWrapper}
          placement={EPosition.TOP_RIGHT}
          key={5}
        />,
        <ProviderUser
          key={6}
          isBotDetected={isBotDetected}
          isReady={isReady}
          isLogged={isLogged}
          user={user}
        />,
        <ProviderUtil
          key={8}
          recaptchaKey={recaptchaKey}
          nonce={keyNonce}
          csrfToken={csrfToken}
          isBotDetected={isBotDetected}
          cdn={cdn}
          apiHost={apiHost}
          apiBase={apiBase}
          apiVersion={apiVersion}
          isProductionServer={isProductionServer}
          language={language}
          currency={currency}
          tCommon={tCommon}
          staleTimeClient={staleTimeClient}
          deviceType={deviceType}
          clientIp={clientIp}
        />,
        <ProviderAuth key={9} />,
        <ProviderCurrency
          currencyInitialize={currencyInitialize}
          storageType={EStorageType.COOKIE}
          key={11}
        />,
        <ProviderCartPopup
          Component={ToastComponentCart}
          Wrapper={PopupComponentWrapper}
          placement={EPosition.TOP_RIGHT}
          key={12}
        />,
        <ProviderModal key={13} />,
        <ProviderProfile key={16} user={user} />,
        <ProviderCart key={17} />,
        <ProviderHeaderHeight key={19} />,
        <ProviderCartForm key={22} />,
        <ProviderRedirectAfterSignInRoute key={23} />,
        <ProviderAlternate
          key={24}
          alternates={alternates}
          keyRoute={keyRouteInitialize}
          query={queryInitialize}
        />,
        <ProviderMeta key={25} meta={metas} />,
        <ProviderBreadcrumb key={26} breadcrumbs={breadcrumbs} keyRoute={keyRouteInitialize} />,
      ]}
    >
      {children}
    </ProviderMulti>
  );
}
