import { FC, memo, MouseEventHandler, useEffect, useRef, useState } from 'react';
import styled, { CSSObject } from 'styled-components';

import { EActionAttribute, EColor, EPosition, EZIndexName, WithRef } from '@core/type';
import { getPaletteHandlers } from '@core/util';

import { Box } from '../layout/Box';
import {
  DropdownBaseProps,
  DropdownVerticalPosition,
  StyledDropdownBaseProps,
} from './interface-dropdown';

const MAX_HEIGHT_DEFAULT = 250;
const PADDING_TO_THE_BOTTOM = 12;

export const DropdownBase = memo(
  ({
    children,
    horizontalPosition = EPosition.LEFT,
    verticalPosition: verticalPositionProps,
    onClose,
    isOpen,
    maxHeight: maxHeightProps,
    fixedMaxHeight,
    ...restProps
  }: DropdownBaseProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [verticalPosition, setVerticalPosition] = useState<DropdownVerticalPosition>(
      verticalPositionProps || EPosition.BOTTOM,
    );
    const [maxHeight, setMaxHeight] = useState<number>(fixedMaxHeight || MAX_HEIGHT_DEFAULT);

    const isFixedBottom = Boolean(verticalPositionProps === EPosition.BOTTOM);

    const handleCloseSelect: MouseEventHandler = (e) => {
      e.stopPropagation();

      onClose();
    };

    useEffect(() => {
      if (isOpen) {
        const { current } = ref;
        const { height } = current.getBoundingClientRect();
        const { bottom } = current.parentElement.getBoundingClientRect();
        const spaceUnderInput = window.innerHeight - bottom;
        const isTop = spaceUnderInput < height;

        if (isFixedBottom && isTop) {
          setMaxHeight(spaceUnderInput - PADDING_TO_THE_BOTTOM);
        } else if (!verticalPositionProps) {
          setVerticalPosition(isTop ? EPosition.TOP : EPosition.BOTTOM);
        }
      } else {
        if (isFixedBottom) {
          setMaxHeight(fixedMaxHeight || MAX_HEIGHT_DEFAULT);
        }
      }
    }, [isOpen]);

    if (!isOpen) {
      return null;
    }

    return (
      <StyledDropdownBase
        {...restProps}
        as={EActionAttribute.UL}
        ref={ref}
        maxHeight={maxHeightProps || maxHeight}
        isTop={verticalPosition === EPosition.TOP}
        isLeft={horizontalPosition === EPosition.LEFT}
        zIndex={EZIndexName.DROPDOWN}
        onClick={handleCloseSelect}
      >
        {children}
      </StyledDropdownBase>
    );
  },
);

const StyledDropdownBase: FC<
  Omit<StyledDropdownBaseProps, 'onClose' | 'theme'> & WithRef<HTMLDivElement>
> = styled(Box)(({
  theme: { palette },
  isTop,
  isLeft,
  onClick: _onClick,
  onMouseEnter: _onMouseEnter,
  onMouseLeave: _onMouseLeave,
  animation: _animation,
  verticalPositionProps: verticalProps,
  horizontalPositionProps: horizontalProps,
  ...restProps
}: Omit<StyledDropdownBaseProps, 'onClose'>) => {
  const { getBoxShadow, getBackgroundColor } = getPaletteHandlers(palette);

  return {
    position: 'absolute',
    top: isTop ? 'auto' : '27px',
    bottom: isTop ? '24px' : 'auto',
    left: isLeft ? 0 : 'auto',
    right: isLeft ? 'auto' : 0,
    width: 'fit-content',
    minHeight: '16px',
    height: 'fit-content',
    minWidth: '100%',
    maxWidth: 'calc(100vw - 40px)',
    padding: '16px 0',
    borderRadius: '6px',
    overflowY: 'auto',
    backgroundColor: getBackgroundColor({
      semanticType: EColor.NEUTRAL,
      variant: EColor.LIGHT,
    }),
    boxShadow: getBoxShadow(EColor.ELEVATION_1),
    ...restProps,
    ...(isTop ? verticalProps?.[EPosition.TOP] : verticalProps?.[EPosition.BOTTOM]),
    ...(isLeft ? horizontalProps?.[EPosition.LEFT] : horizontalProps?.[EPosition.RIGHT]),
  } as CSSObject;
});

DropdownBase.displayName = 'DropdownBase';
