import type { AxiosInstance, AxiosResponse } from 'axios';

import { metalToSlug } from '@core/constant';
import { ELanguageTags, EMetal, EMetalSlug } from '@core/type';
import type { CategoryEntity, CategoryResponse } from '@core/type/api';

import { CategoriesAPI } from '../api/categories';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('CategoriesService', methodName, dto, apiCall, withContent);
};

const getCategories = (language: ELanguageTags): CategoryResponse => {
  return {
    categories: Object.values(EMetal).map(
      (metal, index) =>
        ({
          id: index,
          position: index,
          title:
            metalToSlug[language][metal].charAt(0).toUpperCase() +
            metalToSlug[language][metal].slice(1),
          slug: metalToSlug[language][metal],
          metalIso: metal,
          description: null,
          metas: null,
        }) as CategoryEntity,
    ),
  };
};

const getCategory = (
  axiosInstance: AxiosInstance,
  slug: EMetalSlug,
  language: ELanguageTags,
): Promise<CategoryEntity> => {
  return log('getCategory', { axiosInstance, slug, language }, CategoriesAPI.getCategory);
};

export class CategoriesService {
  public static readonly getCategories = getCategories;
  public static readonly getCategory = getCategory;
}
