import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type { SearchSuggestionsEntity } from '@core/type/api';

import { Api } from '../constants/api';
import { configResponse, EConfigName } from '../helpers/config';
import { addParameters } from '../helpers/util';

const searchAll = async (
  axiosInstance: AxiosInstance,
  { searchTerm, language }: LanguageProps & { searchTerm: string },
): Promise<AxiosResponse<SearchSuggestionsEntity>> =>
  axiosInstance.get(
    addParameters(Api.search.AUTOCOMPLETE, { q: searchTerm }),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

export class SearchAPI {
  public static readonly searchAll = searchAll;
}
