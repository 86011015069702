import type { ReactNode } from 'react';

import { cartContext } from '@core/context';
import { CartHook, CartState } from '@core/type/context';

import { storeHook } from '../helpers/store';
import { useCart } from '../hook';

export const ProviderCart = ({ children }: { children?: ReactNode }) => {
  const hooks = storeHook<CartState, CartHook>(
    { id: null, isReady: false, cart: null, abandonedCartId: null },
    useCart,
  );

  return <cartContext.Provider value={hooks}>{children}</cartContext.Provider>;
};
