import Image from 'next/image';

import IconCoinFocusSvg from './svgs/icon-coin-focus.svg';

export const IconCoinFocus = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconCoinFocusSvg}
    alt="Coin focus icon"
    width={80}
    height={80}
  />
);
