import { TwitterScript } from '@twitter';
import { useMemo } from 'react';

import { useContextMediaQuery, useContextUtil } from '@core/context';
import { EEditor } from '@core/type';

import { EditorArticleContent } from './EditorArticleContent';
import { EditorDefaultContent } from './EditorDefaultContent';
import { EditorInlineContent } from './EditorInlineContent';
import { EditorTutorialContent } from './EditorTutorialContent';
import { EditorContentProps } from './interface-editor';

export const EditorContent = ({
  type = EEditor.DEFAULT,
  content,
  ...restProps
}: EditorContentProps) => {
  const { isPhone, isMobile } = useContextMediaQuery();
  const { nonce } = useContextUtil();

  const EditorContentContainer = useMemo(() => {
    switch (type) {
      case EEditor.DEFAULT:
        return EditorDefaultContent;
      case EEditor.ARTICLE:
        return EditorArticleContent;
      case EEditor.INLINE:
        return EditorInlineContent;
      case EEditor.TUTORIAL:
        return EditorTutorialContent;
      default:
        return EditorDefaultContent;
    }
  }, [type]);

  return (
    <>
      <EditorContentContainer
        isPhone={isPhone}
        isMobile={isMobile}
        dangerouslySetInnerHTML={{ __html: content.replace(`'`, '&#39;') }}
        {...restProps}
      />
      {[EEditor.ARTICLE, EEditor.TUTORIAL].includes(type) && <TwitterScript nonce={nonce} />}
    </>
  );
};
