import { decamelizeKeys } from 'humps';

import { metalIsoToFullMetalName, productTypeToFullProductType } from '@core/constant';
import { Logger } from '@core/logger';
import {
  EGTMProductAvailability,
  EGTMUserType,
  EUserType,
  GTMDataLayer,
  GTMProductProps,
} from '@core/type';
import { roundPrice } from '@core/util';

import { GTMProductEntity } from './interface-google-tag-manager';

export const getGTMUserType = (userType: EUserType): EGTMUserType => {
  switch (userType) {
    case EUserType.CUSTOMER:
      return EGTMUserType.CUSTOMER;
    case EUserType.VISITOR:
      return EGTMUserType.VISITOR;
    default:
      return EGTMUserType.NOT_LOGGED_IN;
  }
};

export const getEcommerceItemProps = (product: GTMProductEntity): GTMProductProps => {
  // totalPrice - it's a total product price without vat which comes from GET|POST user/order|user/cart
  const price = product.totalPrice / product.quantity;
  const fees =
    product.metalPrice >= 0 &&
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    Math.round((product.totalPrice / product.quantity - product.metalPrice) * 100) / 100;

  return {
    itemId: product.id,
    itemName: product.nameEn,
    itemCategory: metalIsoToFullMetalName[product.metalIso],
    itemCategory1: productTypeToFullProductType[product.productType],
    itemWeight: product.weight,
    itemAvailability: product.isOutOfStock
      ? EGTMProductAvailability.OUT_OF_STOCK
      : EGTMProductAvailability.IN_STOCK,
    price: roundPrice(price),
    quantity: product.quantity,
    fees: roundPrice(fees) ?? 0,
    ...(product.unitFees ? { unitFees: product.unitFees } : {}),
    ...(product.totalFees ? { totalFees: product.totalFees } : {}),
  };
};

export const getTransactionId = (id: number) => `GA_${id?.toString()}`;

export const pushGTMEvent = (rawData: GTMDataLayer) => {
  const data = decamelizeKeys(rawData);

  Logger.logInfo(`GTM event "${rawData.event}": ${JSON.stringify(data)}`, {
    library: 'google-tag-manager',
  });

  window.dataLayer?.push(data);
};

export const getEventOriginUrl = (baseDatalayerProps: Omit<GTMDataLayer, 'event'>): string => {
  return baseDatalayerProps.eventOrigin || document.referrer || null;
};
