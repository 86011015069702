import Image from 'next/image';

import IconHeapsSvg from './svgs/icon-heaps.svg';

export const IconHeaps = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconHeapsSvg}
    alt="Heaps icon"
    width={96}
    height={96}
  />
);
