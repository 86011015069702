import withTranslation from 'next-translate/withTranslation';
import { Dispatch, SetStateAction, memo, useEffect, useState } from 'react';

import { Autocomplete, SelectOption, Typography } from '@core/component';
import { Translate, util } from '@core/constant';
import { useContextRouting } from '@core/context';
import { useDebounce, useQueryApi } from '@core/hook';
import {
  EActionAttribute,
  EColor,
  EFontWeight,
  EIcon,
  ERouting,
  WithTranslation,
} from '@core/type';
import { EQueryKey, SearchSuggestionsEntity } from '@core/type/api';

const HeaderSearchContainer = ({
  i18n: { t },
  isOpen,
  setIsOpen,
}: WithTranslation & { isOpen: boolean; setIsOpen: Dispatch<SetStateAction<boolean>> }) => {
  const { setRoute } = useContextRouting();
  const [searchTerm, setSearchTerm] = useState<string>();
  const [suggestions, setSuggestions] = useState<string[]>([]);

  const searchDebounced = useDebounce<string>(searchTerm, util.DEBOUNCE);

  const { data } = useQueryApi<SearchSuggestionsEntity, { q?: string }>(
    EQueryKey.SEARCH_ALL,
    { q: searchDebounced },
    {
      staleTime: Infinity,
      enabled: !!searchTerm && searchTerm.length > 1,
    },
  );

  useEffect(() => {
    if (data?.suggestions) {
      if (!data.suggestions.length) {
        setSuggestions([searchTerm]);
      } else {
        setSuggestions(data?.suggestions);
      }
    }
  }, [data, searchTerm]);

  const redirect = (sT: string) => {
    if (sT) {
      setRoute(ERouting.SEARCH, { q: sT });
      setIsOpen(false);
    }
  };

  return (
    <Autocomplete
      icon={EIcon.SEARCH}
      placeholder={t('search.placeholder')}
      setSearchTerm={setSearchTerm}
      onSubmit={() => redirect(searchTerm)}
      isOpenInput={isOpen}
      searchTerm={searchTerm}
      setIsOpenInput={setIsOpen}
    >
      {suggestions?.map(
        (suggestion) =>
          suggestion && (
            <SelectOption
              value={suggestion}
              key={suggestion}
              onClick={() => redirect(suggestion)}
              label={formatSuggestion(suggestion, searchTerm)}
              padding={'8px 24px'}
              hoverStyles={{
                backgroundColor: { semanticType: EColor.SECONDARY, variant: EColor.MAIN },
              }}
            />
          ),
      )}
    </Autocomplete>
  );
};

export const HeaderSearch = memo(withTranslation(HeaderSearchContainer, Translate.layout.HEADER));

const formatSuggestion = (suggestion: string, searchTerm: string) => {
  let res = <></>;
  let lastIndex = 0;
  let end = '';
  if (searchTerm) {
    const lowerSearchTerm = searchTerm.toLowerCase();
    let index = suggestion.toLowerCase().indexOf(lowerSearchTerm, 0);
    const searchTermLength = searchTerm.length;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    while (index > -1) {
      res = (
        <>
          {res}
          {suggestion.slice(lastIndex, index)}
          <Typography as={EActionAttribute.STRONG} fontWeight={EFontWeight.BOLD}>
            {suggestion.slice(index, index + searchTermLength)}
          </Typography>
        </>
      );
      lastIndex = index + searchTermLength;
      index = suggestion.toLowerCase().indexOf(lowerSearchTerm, index + searchTermLength);
    }
    end = lastIndex !== suggestion.length ? suggestion.slice(lastIndex) : '';
  }
  return (
    <>
      {res}
      {end}
    </>
  );
};
