import useTranslation from 'next-translate/useTranslation';

import { Translate } from '@core/constant';
import { useContextCurrency } from '@core/context';
import { useLanguage } from '@core/hook';
import { ECurrency, ELanguageTags } from '@core/type';
import { getListLanguage } from '@core/util';

import { CurrencyLabel } from './CurrencyLabel';
import { LanguageLabel } from './LanguageLabel';
import {
  ESelectDropdown,
  SelectDropdownProps,
  SelectLanguageCurrencyProps,
} from './interface-select';

export const useSelectTabs = (withCurrency: boolean): SelectDropdownProps['tabs'] => {
  const { t } = useTranslation(Translate.common.WIDGET);

  if (withCurrency) {
    return [
      { value: ESelectDropdown.LANGUAGE, label: t('selectWithLngAndCur.language') },
      { value: ESelectDropdown.CURRENCY, label: t('selectWithLngAndCur.currency') },
    ];
  } else {
    return [{ value: ESelectDropdown.LANGUAGE, label: t('selectWithLngAndCur.language') }];
  }
};

export const useSelectOptions = (): {
  currencyOptions: SelectDropdownProps['options'];
  languageOptions: SelectDropdownProps['options'];
} => {
  const { t: tLanguage } = useTranslation(Translate.common.LANGUAGE);

  const languageList = getListLanguage(tLanguage);

  const currencyOptions = Object.values(ECurrency).map((currency) => ({
    value: currency,
    label: <CurrencyLabel key={currency} currency={currency} />,
  }));

  const languageOptions = languageList.map(({ iso, name }) => ({
    value: iso,
    label: <LanguageLabel key={iso} iso={iso} name={name} />,
  }));

  return { currencyOptions, languageOptions };
};

export const useSelectHandlers = ({
  onCurrencyChange,
  onLanguageChange,
}: Pick<SelectLanguageCurrencyProps, 'onCurrencyChange' | 'onLanguageChange'>) => {
  const { changeLanguage } = useLanguage();
  const { changeCurrency } = useContextCurrency();

  const handleCurrencyChange = (currency: ECurrency) => {
    if (onCurrencyChange) {
      onCurrencyChange(currency);
    } else {
      changeCurrency(currency);
    }
  };

  const handleLanguageChange = (language: ELanguageTags) => {
    if (onLanguageChange) {
      onLanguageChange(language);
    } else {
      changeLanguage(language);
    }
  };

  return { handleCurrencyChange, handleLanguageChange };
};
