/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { forwardRef } from 'react';
import styled, { css, CSSObject, useTheme } from 'styled-components';

import {
  EActionAttribute,
  EButtonVariant,
  EColor,
  EFontWeight,
  ESize,
  StyleRuleDisplay,
  ThemeProps,
  WithThemeProps,
} from '@core/type';
import { getButtonColorProps, getPaletteHandlers } from '@core/util';

import { Icon } from '../icon/Icon';
import { Typography } from '../typography';
import { ButtonBase } from './ButtonBase';
import { ButtonProps, StyledButtonProps } from './interface-button';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    structure: {
      button: {
        icon: { indent, size },
      },
    },
  } = useTheme() as ThemeProps;

  const {
    text,
    isLoading,
    hiddenIcon,
    colorIcon,
    endIcon,
    startIcon,
    iconSize = size,
    zIndex,
    children,
    iconOrientation,
    ...restProps
  } = props;

  const hasIcon = !!startIcon || !!endIcon;

  return (
    <StyledButton {...restProps} zIndex={zIndex} hasIcon={hasIcon} ref={ref} colorIcon={colorIcon}>
      {startIcon && (
        <Icon
          type={startIcon}
          size={iconSize}
          marginRight={indent}
          isLoading={isLoading}
          color={colorIcon}
          hidden={hiddenIcon}
          orientation={iconOrientation}
        />
      )}
      <Typography as={EActionAttribute.SPAN}>{children}</Typography>
      {endIcon && (
        <Icon
          type={endIcon}
          size={iconSize}
          marginLeft={indent}
          isLoading={isLoading}
          color={colorIcon}
          hidden={hiddenIcon}
          orientation={iconOrientation}
        />
      )}
    </StyledButton>
  );
});

Button.displayName = 'Button';

export const StyledButton = styled(ButtonBase)<StyledButtonProps>`
  ${(props: StyledButtonProps & WithThemeProps) => {
    const {
      theme: {
        palette,
        typography: {
          fontWeight: fontWeightStructure,
          button: { size: typographySize },
        },
        structure: {
          button: {
            size: structureSize,
            icon: { indent, size, ...restIconRules },
          },
        },
      },
      disabled,
      hasIcon,
      colorIcon: colorIconProps,
      isFullWidth = false,
      isTransparent = true,
      variant = EButtonVariant.CONTAINED,
      color: colorProps = EColor.PRIMARY,
      size: sizeProps = ESize.MD,
      padding,
      width: widthProps,
      isIconFilled = true,
      isIconStroke = false,
      lineHeight: lineHeightProps,
      fontWeight: fontWeightProps,
      canOverrideTypography,
    } = props;
    const { getColor, getTextColor } = getPaletteHandlers(palette);

    const width = widthProps || isFullWidth ? '100%' : 'auto';
    const display: StyleRuleDisplay = hasIcon ? 'inline-flex' : 'inline-block';

    const { color, hover, ...restPaletteRules } = getButtonColorProps({
      variant,
      color: colorProps,
      isDisabled: disabled,
      isTransparent,
      palette,
    });

    const colorIcon = getTextColor(colorIconProps);

    const {
      fontWeight: fontWeightTypography,
      lineHeight: lineHeightTheme,
      fontSize,
      ...restTypographyRules
    } = typographySize[sizeProps];
    const fontWeight =
      fontWeightStructure[
        fontWeightProps || (sizeProps === ESize.SM && variant === EButtonVariant.TEXT)
          ? EFontWeight.REGULAR
          : fontWeightTypography
      ];
    const lineHeight = lineHeightProps ? lineHeightProps : lineHeightTheme;

    const sizeRules = structureSize[sizeProps];

    let rules = {
      display,
      width,
      color,
      ...sizeRules,
      ...restPaletteRules,
    } as CSSObject;

    if (padding) {
      rules = { ...rules, padding };
    }

    let typographyStyle: string = null;
    if (!canOverrideTypography) {
      typographyStyle = `
        ${Typography.toString()} {
          font-size: ${fontSize};
          font-weight: ${fontWeight};
          line-height: ${lineHeight};
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      `;
    }

    const hoverStyles = css`
      @media (hover: hover) {
        &:hover {
          color: ${hover?.color};
          border-color: ${hover?.borderColor};
          background: ${hover?.backgroundColor};

          path {
            fill: ${isIconFilled ? hover?.color : null};
            stroke: ${isIconStroke ? hover?.color : null};
          }
        }
      }
    `;

    return css`
      ${rules};
      ${typographyStyle};

      text-align: center;
      align-items: center;
      justify-content: center;

      ${Typography.toString()} {
        ${restTypographyRules};
      }

      ${hasIcon &&
      ` path {
          fill: ${isIconFilled ? colorIcon ?? color : getColor(EColor.TRANSPARENT)};
          stroke: ${isIconStroke ? colorIcon ?? color : getColor(EColor.TRANSPARENT)};
          ${/* eslint-disable @typescript-eslint/no-base-to-string */ restIconRules as CSSObject};
        }`}

      ${hoverStyles};
    `;
  }}
`;
