import Image from 'next/image';

import SwissLogo from './svgs/swiss-logo.svg';

export const IconSwissLogo = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={SwissLogo}
    alt="Swiss logo"
    width={78}
    height={26}
  />
);
