import { EStrokeWidth } from '@core/type';

import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconArrowRight = ({
  stroke,
  strokeWidth = EStrokeWidth.ICON_DEFAULT,
}: DynamicIconProps) => {
  return (
    <SVG height={24} width={24} viewBox="0 0 24 24" title="Arrow right">
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M7.88857 6L18 6M18 6V16.1114M18 6L5 19"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </SVG>
  );
};
