import Image from 'next/image';

import IconGaLogoFullColorFilledSvg from './svgs/icon-ga-logo-full-color-filled.svg';

export const IconGaLogoFullColorFilled = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconGaLogoFullColorFilledSvg}
    alt="Loader Gold Avenue icon"
    width={184}
    height={32}
  />
);
