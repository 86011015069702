import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type {
  ClaimDetailsEntity,
  GiftDetailsEntity,
  OrderDetailsEntity,
  PagedRequest,
  WalletClaimEntity,
  WalletGiftEntity,
  WalletOrderEntity,
} from '@core/type/api';

import { HistoryAPI } from '../api/history';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
): Promise<R> => {
  return logService('HistoryService', methodName, dto, apiCall);
};

const getHistoryOrders = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  pagedRequest: PagedRequest,
): Promise<WalletOrderEntity> => {
  return log(
    'getHistoryOrders',
    { axiosInstance, language, ...pagedRequest },
    HistoryAPI.getHistoryOrders,
  );
};

const getHistoryGifts = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  pagedRequest: PagedRequest,
): Promise<WalletGiftEntity> => {
  return log(
    'getHistoryGifts',
    { axiosInstance, language, ...pagedRequest },
    HistoryAPI.getHistoryGifts,
  );
};

const getHistoryClaims = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  pagedRequest: PagedRequest,
): Promise<WalletClaimEntity> => {
  return log(
    'getHistoryClaims',
    { axiosInstance, language, ...pagedRequest },
    HistoryAPI.getHistoryClaims,
  );
};

const getOrderById = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  id: number,
): Promise<OrderDetailsEntity> => {
  return log(
    'getOrderById',
    { axiosInstance, id: id.toString(), language },
    HistoryAPI.getOrderById,
  );
};

const getGiftByReferenceNumber = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  referenceNumber: string,
): Promise<GiftDetailsEntity> => {
  return log(
    'getGiftByReferenceNumber',
    { axiosInstance, referenceNumber, language },
    HistoryAPI.getGiftByReferenceNumber,
  );
};

const getClaimById = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  id: number,
): Promise<ClaimDetailsEntity> => {
  return log(
    'getClaimById',
    { axiosInstance, language, id: id.toString() },
    HistoryAPI.getClaimById,
  );
};

export class HistoryService {
  public static readonly getHistoryOrders = getHistoryOrders;
  public static readonly getHistoryGifts = getHistoryGifts;
  public static readonly getHistoryClaims = getHistoryClaims;
  public static readonly getOrderById = getOrderById;
  public static readonly getGiftByReferenceNumber = getGiftByReferenceNumber;
  public static readonly getClaimById = getClaimById;
}
