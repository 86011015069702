import Image from 'next/image';

import IconSpinnerSvg from './svgs/icon-spinner.svg';

export const IconSpinner = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconSpinnerSvg}
    alt="Spinner icon"
    width={20}
    height={20}
  />
);
