import {
  BackgroundColorType,
  BoxShadowElevationType,
  ColorProps,
  CommonColorProps,
  DesignElementType,
  EColor,
  EColorName,
  ThemePalette,
  SemanticColorProps,
  SemanticColorType,
  TextColorType,
  VariantColorType,
} from '@core/type';

type DesignVariantProps = undefined | { [key in VariantColorType]: EColorName };

export const getPaletteColor = <T extends BackgroundColorType | TextColorType>({
  color,
  palette,
  designElementType,
}: {
  color: ColorProps<T>;
  palette: ThemePalette;
  designElementType: DesignElementType;
}): EColorName => {
  const getColorName = (
    colorType: TextColorType | BackgroundColorType,
    variant: VariantColorType,
  ): EColorName => {
    // @ts-expect-error: designElementType can be one type of EColor.TEXT or EColor.BACKGROUND
    const designVariantProps = palette[designElementType]?.[colorType] as DesignVariantProps;
    const semanticVariantProps = palette[colorType as SemanticColorType];

    return (designVariantProps ?? semanticVariantProps)[variant ?? EColor.MAIN];
  };

  if (!color) {
    return null;
  }

  if (color === EColor.TRANSPARENT) {
    return EColorName.TRANSPARENT;
  }

  if (typeof color === 'string') {
    // returns EColor.MAIN by default
    return getColorName(color as TextColorType | BackgroundColorType, EColor.MAIN);
  }

  if (typeof color === 'object') {
    // Can be passed only one type of the object: SemanticColorProps<T> or CommonColorProps
    const { semanticType, variant } = color as SemanticColorProps<T>;

    if (semanticType) {
      return getColorName(semanticType as TextColorType | BackgroundColorType, variant);
    }

    const { commonType, intensity } = color as CommonColorProps;

    if (commonType) {
      return palette[commonType][intensity];
    }

    return null;
  }

  return null;
};

export const getPaletteTextColor = ({
  color,
  palette,
}: {
  color: ColorProps<TextColorType>;
  palette: ThemePalette;
}) => getPaletteColor({ color, palette, designElementType: EColor.TEXT });

export const getPaletteBackgroundColor = ({
  backgroundColor,
  palette,
}: {
  backgroundColor: ColorProps<BackgroundColorType>;
  palette: ThemePalette;
}): EColorName =>
  getPaletteColor({
    color: backgroundColor,
    palette,
    designElementType: EColor.BACKGROUND,
  });

export const getBoxShadowElevation = ({
  boxShadow,
  palette,
}: {
  boxShadow: BoxShadowElevationType;
  palette: ThemePalette;
}): string => palette[EColor.BOX_SHADOW][boxShadow] ?? null;

export const getPaletteHandlers = (palette: ThemePalette) => ({
  getColor: (color: ColorProps) => getPaletteColor({ palette, color, designElementType: null }),
  getTextColor: (color: ColorProps<TextColorType>) => getPaletteTextColor({ palette, color }),
  getBoxShadow: (boxShadow: BoxShadowElevationType) =>
    getBoxShadowElevation({
      boxShadow,
      palette,
    }),
  getBackgroundColor: (backgroundColor: ColorProps<BackgroundColorType>) =>
    getPaletteBackgroundColor({
      palette,
      backgroundColor,
    }),
});
