import type { AxiosInstance } from 'axios';
import useTranslation from 'next-translate/useTranslation';

import { AuthService, ProfileService, UserService } from '@core/api';
import { Translate } from '@core/constant';
import {
  useContextRouting,
  useContextToast,
  useStoreProfile,
  useStoreUser,
  useStoreUtil,
} from '@core/context';
import { Logger } from '@core/logger';
import { generateUUID } from '@core/security';
import {
  Datetime,
  EColor,
  ECurrency,
  EGender,
  ELanguage,
  ERouting,
  EUserStatus,
  EUserType,
} from '@core/type';
import {
  AddressEntity,
  EStorageFeeStatus,
  PersonEntity,
  PhoneEntity,
  UserEntity,
} from '@core/type/api';
import { HookStoreFn, ProfileHook, ProfileState } from '@core/type/context';
import { getLanguageTags } from '@core/util';

import { logHook } from '../helpers';

const log = async <R>(methodName: string, apiCall: () => Promise<R>): Promise<R> => {
  return logHook('useProfile', methodName, apiCall);
};

export const useProfile: HookStoreFn<ProfileState, ProfileHook> = (): ProfileHook => {
  const { redirect } = useContextRouting();
  const [axiosInstance] = useStoreUtil<AxiosInstance>('axiosInstance');
  const { t } = useTranslation(Translate.common.USER);
  const { addToast } = useContextToast();
  const [, setIsLogged] = useStoreUser<boolean>('isLogged');
  const [, setFirstName] = useStoreUser<string>('firstName');
  const [, setLastName] = useStoreUser<string>('lastName');
  const [phone, setPhone] = useStoreUser<PhoneEntity>('phone');
  const [, setEmail] = useStoreUser<string>('email');
  const [language, setLanguage] = useStoreUser<ELanguage>('language');
  const [, setCurrency] = useStoreUser<ECurrency>('currency');
  const [, setStatus] = useStoreUser<EUserStatus>('status');
  const [hasMfaSms, setHasMfaSms] = useStoreUser<boolean>('hasMfaSms');
  const [hasMfaTotp, setHasMfaTotp] = useStoreUser<boolean>('hasMfaTotp');
  const [hasNotCompletedStep, setHasNotCompletedStep] =
    useStoreUser<boolean>('hasNotCompletedStep');
  const [gaPayBalance, setGaPayBalance] = useStoreProfile<number>('gaPayBalance');
  const [referenceNumber, setReferenceNumber] = useStoreProfile<string>('referenceNumber');
  const [hasAuthy, setHasAuthy] = useStoreProfile<boolean>('hasAuthy');
  const [birthday, setBirthday] = useStoreProfile<Datetime>('birthday');
  const [gender, setGender] = useStoreProfile<EGender>('gender');
  const [hasNewsletterSubscription, setHasNewsletterSubscription] = useStoreProfile<boolean>(
    'hasNewsletterSubscription',
  );
  const [newsletterSubscriptionIds, setNewsletterSubscriptionIds] = useStoreProfile<number[]>(
    'newsletterSubscriptionIds',
  );
  const [hasStorageFeesUnpaid, setHasStorageFeesUnpaid] =
    useStoreProfile<boolean>('hasStorageFeesUnpaid');
  const [cartItemsCount, setCartItemsCount] = useStoreProfile<number>('cartItemsCount');
  const [customerSurveys, setCustomerSurveys] =
    useStoreProfile<{ id: number }[]>('customerSurveys');
  const [hasLoginAlert, setHasLoginAlert] = useStoreProfile<boolean>('hasLoginAlert');
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [userGaPayUsed, setUserGaPayUsed] = useStoreProfile<boolean>('userGaPayUsed');
  const [emergencyPeople, setEmergencyPeople] = useStoreProfile<PersonEntity>('emergencyPeople');
  const [shippingAddress, setShippingAddress] = useStoreProfile<AddressEntity>('shippingAddress');
  const [billingAddress, setBillingAddress] = useStoreProfile<AddressEntity>('billingAddress');
  const [sessionId, setSessionId] = useStoreProfile<string>('sessionId');
  const [userId, setUserId] = useStoreProfile<number>('userId');
  const [userType, setUserType] = useStoreProfile<EUserType>('userType');
  const [hasWireTransferInProgress, setHasWireTransferInProgress] = useStoreProfile<boolean>(
    'hasWireTransferInProgress',
  );
  const [storageFeesStatus, setStorageFeesStatus] =
    useStoreProfile<EStorageFeeStatus>('storageFeesStatus');
  const [crispSessionToken, setCrispSessionToken] = useStoreProfile<string>('crispSessionToken');
  const languageTag = getLanguageTags(language);

  const setUserSessionId = () => {
    const storeId = ProfileService.getSessionId();

    if (storeId) {
      setSessionId(JSON.parse(storeId) as string);
    } else {
      const id = generateUUID();

      setSessionId(id);
      ProfileService.setSessionId(JSON.stringify(id));
    }
  };

  const resetUserSessionId = () => {
    setSessionId(null);
    ProfileService.removeSessionId();
  };

  const setUser = (user: UserEntity): void => {
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setPhone(user.phone);
    setUserId(user.userId);
    setEmail(user.email);
    setLanguage(user.language);
    setCurrency(user.currencyIso);
    setStatus(user.status);
    setGaPayBalance(user.gaPayBalance);
    setReferenceNumber(user.referenceNumber);
    setHasAuthy(user.hasAuthy);
    setHasMfaSms(user.hasMfaSms);
    setHasMfaTotp(user.hasMfaTotp);
    setBirthday(user.birthday);
    setGender(user.gender);
    setHasNewsletterSubscription(user.hasNewsletterSubscription);
    setNewsletterSubscriptionIds(user.newsletterSubscriptionIds);
    setHasLoginAlert(user.hasLoginAlert);
    setEmergencyPeople(user.emergencyPeople);
    setShippingAddress(user.shippingAddress);
    setBillingAddress(user.billingAddress);
    setHasNotCompletedStep(user.hasNotCompletedStep);
    setHasStorageFeesUnpaid(user.hasStorageFeesUnpaid);
    setCartItemsCount(user.cartItemsCount);
    setCustomerSurveys(user.customerSurveys);
    setUserId(user.userId);
    setUserType(user.userType);
    setHasWireTransferInProgress(user.hasWireTransferInProgress);
    setStorageFeesStatus(user.storageFeesStatus);
    setUserGaPayUsed(user.userGaPayUsed);
    setCrispSessionToken(user.crispSessionToken);
  };

  const initializeProfile = async (): Promise<void> => {
    const getProfileAction = async () => {
      try {
        const user = await UserService.getProfile(axiosInstance, languageTag);

        // Throw an error when the user has not been sent (backend can send an empty user), so that we can't log-in a user without profile
        if (user.email === undefined) {
          throw new Error();
        }

        setUser(user);
        setIsLogged(true);
        setUserSessionId();
        Logger.setUser({ email: user.email } as UserEntity);
      } catch (e) {
        setIsLogged(false);
        resetUserSessionId();
        await redirect(ERouting.SIGN_IN);
        addToast({
          title: t('profile.noData.title'),
          description: t('profile.noData.description'),
          status: EColor.DANGER,
        });
        AuthService.removeToken();
        throw new Error();
      }
    };
    await log('profile', getProfileAction);
  };

  const resetProfile = (): void => {
    setFirstName(null);
    setLastName(null);
    setPhone(null);
    setEmail('');
    setUserId(null);
    setUserType(null);
    setLanguage(null);
    setCurrency(null);
    setHasNotCompletedStep(null);
    setStatus(EUserStatus.DEFAULT);
    setSessionId(null);
    resetUserSessionId();
    setCrispSessionToken(null);
  };

  return {
    phone,
    sessionId,
    userId,
    gaPayBalance,
    referenceNumber,
    hasAuthy,
    birthday,
    hasMfaSms,
    hasMfaTotp,
    gender,
    hasNewsletterSubscription,
    newsletterSubscriptionIds,
    hasLoginAlert,
    emergencyPeople,
    shippingAddress,
    billingAddress,
    cartItemsCount,
    userType,
    userGaPayUsed,
    hasWireTransferInProgress,
    setHasWireTransferInProgress,
    storageFeesStatus,
    initializeProfile,
    resetProfile,
    setHasLoginAlert,
    setHasMfaTotp,
    setHasMfaSms,
    setCurrency,
    setGaPayBalance,
    setBillingAddress,
    setShippingAddress,
    setHasStorageFeesUnpaid,
    setCartItemsCount,
    setEmergencyPeople,
    setCustomerSurveys,
    setHasNewsletterSubscription,
    setHasNotCompletedStep,
    hasNotCompletedStep,
    hasStorageFeesUnpaid,
    customerSurveys,
    setUserSessionId,
    setPhone,
    setEmail,
    setGender,
    setBirthday,
    setLanguage,
    crispSessionToken,
  };
};
