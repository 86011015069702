import {
  CRITEO_NET,
  CRITEO_URL,
  LAUNCHDARKLY_EVENT_URL,
  LAUNCHDARKLY_URL,
  MIXPANEL_API_URL,
  ONFIDO_ALL_URL,
  ONFIDO_ALL_WSS_URL,
  SANITY_API,
  SANITY_API_CDN,
  SENTRY_URL,
  TRUSTPILOT_API_URL,
  axeptioUrls,
  crisp,
  externalAnalytics,
  google,
  googleDomains,
  vercel,
} from '@core/constant';

import { EnvironmentPublicNext } from '../../helpers/environment';

export const getConnectSrc = ({
  host,
  env,
  apiHost,
  enableSentry,
  enableGoogleAnalytics,
  enableCrisp,
  enableTrustpilot,
  enableVercelPreviewFeedback,
  enableWebVitals,
  sentryEnvelope,
  sentrySecurity,
  wsHost,
  enableWebSocket,
  saferpayUrl,
  enableMixpanel,
}: Partial<EnvironmentPublicNext>): string[] => {
  const axeptioApiUrl = axeptioUrls.API_URL;
  const axeptioClientUrl = axeptioUrls.CLIENT_URL;
  const axeptioStaticUrl = axeptioUrls.STATIC_URL;

  const trustpilotApiUrl = TRUSTPILOT_API_URL;
  const crispUrl = crisp.CRISP_URL;
  const crispWSS = crisp.CRISP_WSS;
  const crispStream = crisp.CRISP_STREAM;
  const crispFiles = crisp.CRISP_FILES;
  const gtmUrl = google.GTM_URLS;
  const gaUrl = google.GA_URLS;
  const agUrl = google.AG_URLS;
  const criteoUrl = CRITEO_URL;
  const criteoNet = CRITEO_NET;
  const syndicationUrl = google.SYNDICATION_URL;
  const doubleClickUrl = google.DOUBLE_CLICK_URLS;
  const gUrl = google.G_URLS;
  const googlePlaceUrl = google.GOOGLE_PLACE_URL;
  const pusherUrl = externalAnalytics.PUSHER_URL;
  const pusherWss = externalAnalytics.PUSHER_WSS;
  const vercelLiveUrl = vercel.LIVE_URL;
  const vercelUrl = vercel.VERCEL_URL;
  const vercelSocketJSUrl = vercel.SOCKETJS_URL;
  const vercelWSSUrl = vercel.WSS_URL;
  const vercelWebVitalsUrl = vercel.WEB_VITALS_URL;
  const taboolaUrl = externalAnalytics.TABOOLA_URL;
  const bingUrl = externalAnalytics.BING_URL;
  const linkedinUrl = externalAnalytics.LINKEDIN_URL;
  const hotjarWss = externalAnalytics.HOTJAR_WSS;
  const hotjarIo = externalAnalytics.HOTJAR_IO;
  const hotjarVc = externalAnalytics.HOTJAR_VC;
  const hotjarMetrics = externalAnalytics.HOTJAR_METRICS;
  const facebookUrl = externalAnalytics.FACEBOOK_URL;
  const launchDarklyUrl = LAUNCHDARKLY_URL;
  const launchDarklyEventUrl = LAUNCHDARKLY_EVENT_URL;
  const clarityAllUrl = externalAnalytics.CLARITY_ALL_URL;
  const gaSsrUrl = externalAnalytics.GA_SSR;

  let hostAllowed = `${host}`;
  if (env === 'preview') {
    hostAllowed = `https://${process.env.NEXT_PUBLIC_VERCEL_URL} https://${process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL}`;
  }

  let connectSrc = [
    `'self'`,
    `data: blob: ${ONFIDO_ALL_URL} ${ONFIDO_ALL_WSS_URL}`,
    enableTrustpilot && trustpilotApiUrl,
    criteoUrl,
    criteoNet,
    SANITY_API,
    SANITY_API_CDN,
    hostAllowed,
    axeptioStaticUrl,
    axeptioClientUrl,
    axeptioApiUrl,
    saferpayUrl,
    enableWebVitals && vercelWebVitalsUrl,
    enableWebSocket && wsHost,
    googlePlaceUrl,
    apiHost,
    enableSentry && `${sentryEnvelope} ${sentrySecurity}`,
    SENTRY_URL,
    launchDarklyUrl,
    launchDarklyEventUrl,
    clarityAllUrl,
    gaSsrUrl,
  ];

  if (enableGoogleAnalytics) {
    connectSrc = connectSrc.concat([
      gaUrl,
      hotjarVc,
      pusherUrl,
      pusherWss,
      syndicationUrl,
      bingUrl,
      facebookUrl,
      gtmUrl,
      agUrl,
      gUrl,
      googleDomains.join(' '),
      doubleClickUrl,
      linkedinUrl,
      hotjarMetrics,
      hotjarWss,
      hotjarIo,
      taboolaUrl,
    ]);
  }
  if (enableCrisp) {
    connectSrc = connectSrc.concat([crispWSS, crispStream, crispFiles, crispUrl]);
  }
  if (enableVercelPreviewFeedback) {
    connectSrc = connectSrc.concat([vercelLiveUrl, vercelUrl, vercelSocketJSUrl, vercelWSSUrl]);
  }
  if (enableMixpanel) {
    connectSrc = connectSrc.concat([MIXPANEL_API_URL]);
  }

  return connectSrc.filter((src) => src);
};
