import { Box } from '../layout/Box';
import { SelectBaseOption } from './SelectBaseOption';
import { SelectBaseOptionProps } from './interface-select';

export const SelectOption = ({ label, padding, ...restProps }: SelectBaseOptionProps) => (
  <SelectBaseOption {...restProps}>
    <Box
      width={'100%'}
      padding={padding ? padding : '10px 16px'}
      overflow="hidden"
      textOverflow="ellipsis"
    >
      {label}
    </Box>
  </SelectBaseOption>
);
