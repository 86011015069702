import Image from 'next/image';

import IconSecureLogoSvg from './svgs/secure-logo.svg';

export const IconSecureLogo = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconSecureLogoSvg}
    alt="Secure logo"
    width={142}
    height={36}
  />
);
