import { ESizeIcon, IconPosition } from '@core/type';
import { getTranslationWithOrientation } from '@core/util';

import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconTriangle = ({
  fill,
  orientation,
  yTranslation,
  size,
}: {
  fill?: string;
  orientation?: IconPosition;
  yTranslation?: string;
  size?: number;
}) => {
  return (
    <IconTriangleSVG
      fill={fill}
      size={size}
      transform={getTranslationWithOrientation(orientation, yTranslation)}
    />
  );
};

const IconTriangleSVG = ({ fill, transform, size = ESizeIcon.DEFAULT }: DynamicIconProps) => (
  <SVG height={size} width={size} viewBox="0 0 10 8" title="Triangle icon" transform={transform}>
    <path
      d="M4.65421 6.93093L0.942958 1.99365C0.850601 1.90179 0.787811 1.78495 0.762502 1.65786C0.737193 1.53077 0.750496 1.39911 0.800735 1.27949C0.850975 1.15986 0.935902 1.05761 1.04482 0.985631C1.15373 0.91365 1.28175 0.875156 1.41275 0.875H8.83659C8.96773 0.875028 9.09591 0.913475 9.20497 0.985488C9.31402 1.0575 9.39905 1.15985 9.44932 1.27961C9.49958 1.39937 9.51283 1.53118 9.48739 1.65838C9.46195 1.78558 9.39897 1.90248 9.30638 1.99431L5.59579 6.93159C5.53413 6.99288 5.46082 7.04153 5.38009 7.07472C5.29935 7.10791 5.21277 7.125 5.12533 7.125C5.0379 7.125 4.95132 7.10791 4.87058 7.07472C4.78984 7.04153 4.71587 6.99223 4.65421 6.93093Z"
      fill={fill}
    />
  </SVG>
);
