import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { ForecastedDeliveryEntity, ForecastedDeliveryRequestEntity } from '@core/type/api';

import { ForecastedDeliveryAPI } from '../api/forecasted-delivery';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('ForecastedDeliveryService', methodName, dto, apiCall, withContent);
};

const getForecastedDelivery = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: ForecastedDeliveryRequestEntity,
): Promise<ForecastedDeliveryEntity> => {
  return log(
    'getForecastedDelivery',
    { axiosInstance, language, ...data },
    ForecastedDeliveryAPI.getForecastedDelivery,
  );
};

export class ForecastedDeliveryService {
  public static readonly getForecastedDelivery = getForecastedDelivery;
}
