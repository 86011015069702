import Image from 'next/image';

import IconDiscountSvg from './svgs/icon-discount.svg';

export const IconDiscount = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconDiscountSvg}
    alt="Discount icon"
    height={24}
    width={24}
  />
);
