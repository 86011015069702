import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { DeliveryCountriesEntity } from '@core/type/api';

import { DeliveryCountriesAPI } from '../api/delivery-countries';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('CountriesService', methodName, dto, apiCall, withContent);
};

const getDeliveryCountries = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<DeliveryCountriesEntity> => {
  return log(
    'getDeliveryCountries',
    { axiosInstance, language },
    DeliveryCountriesAPI.getDeliveryCountries,
  );
};

export class DeliveryCountriesService {
  public static readonly getDeliveryCountries = getDeliveryCountries;
}
