import { EStrokeWidth } from '@core/type';

import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconCheck = ({
  stroke,
  flex,
  strokeWidth = EStrokeWidth.ICON_DEFAULT,
}: DynamicIconProps & { flex?: string | number }) => {
  return (
    <SVG height={16} width={16} viewBox="0 0 16 16" title="Check icon" flex={flex}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M12.7106 5.81115L7.7609 10.7609L5 8"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </SVG>
  );
};
