export enum ELanguage {
  EN = 'en',
  FR = 'fr',
  IT = 'it',
  DE = 'de',
  DEFAULT = 'default',
}

// BCP 47 Language Tags
export enum ELanguageTags {
  EN_US = 'en-US',
  FR_FR = 'fr-FR',
  IT_IT = 'it-IT',
  DE_DE = 'de-DE',
  DEFAULT = 'default',
}

export type LanguageEntity = {
  name: string;
  key: string;
  iso: ELanguageTags;
};

export type TranslatedUrl = Omit<
  {
    [key in ELanguageTags]: string;
  },
  'default'
>;

export type LanguageProps = { language: ELanguageTags };
