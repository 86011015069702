import { Context, useContext } from 'react';

import { HookStore } from '@core/type/context';

// Must be used everytime inside core-context
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useFnContext<State, Hook>(contextHook: Context<HookStore<any, State, Hook>>): Hook {
  const { useFn } = useContext(contextHook);
  return useFn();
}

// Must be used inside hook to manage storage only inside hook
export function useStoreContext<Selector, State, Hook>(
  selector: keyof State,
  contextHook: Context<HookStore<Selector, State, Hook>>,
): [Selector, (value: Selector) => void] {
  const { useStore } = useContext(contextHook);
  return useStore(selector);
}
