import { useQueryClient } from '@tanstack/react-query';
import type { AxiosInstance } from 'axios';
import useTranslation from 'next-translate/useTranslation';

import { OrderService } from '@core/api';
import { Translate as TranslatePage } from '@core/constant';
import {
  useContextCartPopup,
  useContextToast,
  useStoreCart,
  useStoreCurrency,
  useStoreUser,
  useStoreUtil,
} from '@core/context';
import { useLanguage, useMutationApi, useParamsQueriesKeys, useQueryApi } from '@core/hook';
import { CartProductsStorage } from '@core/storage';
import { AutoSavingsFrequency, EColor, ECurrency, EFrequency, EIcon } from '@core/type';
import {
  CartProductEntity,
  EDeliveryType,
  EOrderSteps,
  EQueryKey,
  ErrorCartResponse,
  OrderReq,
  OrderResponse,
  ShippingMethodEntity,
} from '@core/type/api';
import { CartHook, CartPopupType, CartState, HookStoreFn } from '@core/type/context';

const getCartError = (error: ErrorCartResponse) => {
  if (error?.data?.cart) {
    return error?.data?.cart;
  }
  throw error;
};

const productHasAutoSaving = (product: CartProductEntity) => {
  return product?.repeatBuyFrequency && product.repeatBuyFrequency !== EFrequency.NEVER;
};

const getWarningTextFromShippingMethod = (
  product: CartProductEntity,
  previousCart: OrderResponse,
  shippingMethod?: ShippingMethodEntity,
) => {
  const existingProduct = previousCart?.products?.find(
    ({ id: previousCartProductId }) => previousCartProductId === product.id,
  );

  if (
    ((existingProduct && productHasAutoSaving(existingProduct)) ||
      previousCart?.products.some((previousCartProduct) =>
        productHasAutoSaving(previousCartProduct),
      )) &&
    shippingMethod?.shippingType === EDeliveryType.SHIPPING
  ) {
    return {
      warningText: `${TranslatePage.common.PRODUCT}:cart.setShippingAutosavingsOptionDeactivated`,
      iconType: EIcon.CIRCULAR_ARROW,
    };
  }

  if (
    productHasAutoSaving(product) &&
    previousCart?.products.some(
      (previousCartProduct) => !productHasAutoSaving(previousCartProduct),
    ) &&
    previousCart?.shippingMethod?.shippingType === EDeliveryType.SHIPPING
  ) {
    return {
      warningText: `${TranslatePage.common.PRODUCT}:cart.setStorageAutosavingsOptionActivated`,
      iconType: EIcon.CIRCULAR_ARROW,
    };
  }

  if (
    previousCart?.deliveryType === EDeliveryType.STORAGE &&
    shippingMethod?.shippingType === EDeliveryType.SHIPPING
  ) {
    return {
      warningText: `${TranslatePage.common.PRODUCT}:cart.setToDelivery`,
      iconType: EIcon.CIRCULAR_ARROW,
    };
  }

  if (
    previousCart?.deliveryType === EDeliveryType.SHIPPING &&
    shippingMethod?.shippingType === EDeliveryType.STORAGE
  ) {
    return {
      warningText: `${TranslatePage.common.PRODUCT}:cart.setToStorage`,
      iconType: EIcon.CIRCULAR_ARROW,
    };
  }

  if (existingProduct) {
    if (productHasAutoSaving(existingProduct) && !productHasAutoSaving(product)) {
      return {
        warningText: `${TranslatePage.common.PRODUCT}:cart.autosavingsOptionDeactivated`,
        iconType: EIcon.CIRCULAR_ARROW,
      };
    }
  }

  if (productHasAutoSaving(product)) {
    return {
      warningText: `${TranslatePage.common.PRODUCT}:cartItems.added.repeatBuyInstructionsHtml`,
      iconType: EIcon.INFO,
    };
  }

  return {
    warningText: null,
    iconType: null,
  };
};

const updateProductAutoSavingsFromShippingMethodAndAddedProduct = (
  products: CartProductEntity[],
  shippingMethod: ShippingMethodEntity,
) => {
  if (shippingMethod?.shippingType === EDeliveryType.SHIPPING) {
    return products.map((product) => ({
      ...product,
      repeatBuyFrequency: null,
    }));
  }

  return products;
};

export const useCart: HookStoreFn<CartState, CartHook> = (): CartHook => {
  const { showPopup, cleanImmediately } = useContextCartPopup();
  const { addToast } = useContextToast();
  const queryClient = useQueryClient();
  const { language } = useLanguage();

  const { t: tCommon } = useTranslation(TranslatePage.common.COMMON);
  const { t: tProduct } = useTranslation(TranslatePage.common.PRODUCT);

  const [id, setId] = useStoreCart<number>('id');
  const [axiosInstance] = useStoreUtil<AxiosInstance>('axiosInstance');
  const [currency] = useStoreCurrency<ECurrency>('currency');
  const [isLogged] = useStoreUser<boolean>('isLogged');
  const [isContextUserReady] = useStoreUser<boolean>('isReady');
  const [userCurrency] = useStoreUser<ECurrency>('currency');

  const [abandonedCartId, setAbandonedCartId] = useStoreCart<number>('abandonedCartId');
  const [isReady, setIsReady] = useStoreCart<boolean>('isReady');
  const queries = useParamsQueriesKeys(EQueryKey.CART);

  const { data: cart } = useQueryApi<OrderResponse>(EQueryKey.CART, null, { enabled: isLogged });

  const getUserCart = () => {
    void OrderService.getCart(axiosInstance, language)
      .then((response) => {
        const { id } = response;

        setId(id);
        queryClient.setQueryData(queries, response);
      })
      .finally(() => {
        setIsReady(true);
      });
  };

  const getStoredCart = (): OrderResponse => CartProductsStorage.getCart();

  const getUpdatedCart = (
    product: CartProductEntity,
    shippingMethod: ShippingMethodEntity,
  ): OrderResponse =>
    ({
      ...(cart || {}),
      products: updateProductAutoSavingsFromShippingMethodAndAddedProduct(
        [...(cart?.products || []), product],
        shippingMethod,
      ),
    }) as OrderResponse;

  const initializeCart = () => {
    if (isContextUserReady && isLogged) {
      const data = getStoredCart();
      const storedCart = data || ({} as OrderResponse);

      if (storedCart?.products?.length > 0) {
        // stored cart should be in priority
        void OrderService.postOrder(axiosInstance, language, {
          ...storedCart,
          step: EOrderSteps.CART_RECONCILIATION,
        })
          .then((response: OrderResponse) => {
            const { id } = response;

            setId(id);
            queryClient.setQueryData(queries, response);
          })
          .finally(() => {
            setIsReady(true);
          });
      } else {
        getUserCart();
      }

      CartProductsStorage.removeCart();
    } else if (isContextUserReady) {
      const storedCart = getStoredCart() || ({} as OrderResponse);
      const isProductsPresent = storedCart?.products?.length > 0;

      if (isProductsPresent) {
        const cartPayload = {
          ...storedCart,
        };

        if (storedCart.deliveryType) {
          cartPayload.shippingMethod = {
            shippingType: storedCart.deliveryType,
            countryIso: storedCart.shippingMethod?.countryIso,
          };
        }

        void OrderService.postVirtualCart(axiosInstance, language, cartPayload)
          .catch(getCartError)
          .then((response) => {
            queryClient.setQueryData(queries, response);
          })
          .finally(() => setIsReady(true));
      }

      if (!isProductsPresent) {
        setId(null);
        queryClient.setQueryData(queries, null);
        setIsReady(true);
      }
    }
  };

  const resetCart = () => {
    setId(undefined);
    queryClient.setQueryData(queries, { products: [] } as OrderResponse);
  };

  const changePriceWithCurrency = () => {
    if (cart?.products?.length > 0 && currency !== cart?.currencyIso) {
      if (isLogged && !userCurrency) {
        getUserCart();
      } else if (!isLogged) {
        void OrderService.postVirtualCart(axiosInstance, language, cart)
          .catch(getCartError)
          .then((response) => {
            return queryClient.setQueryData(queries, response);
          });
      }
    }
  };

  const { mutateAsync: updateCart } = useMutationApi(
    async (data: OrderReq) => {
      setIsReady(false);

      if (isLogged) {
        return OrderService.patchOrder(axiosInstance, language, data);
      } else {
        return OrderService.postVirtualCart(axiosInstance, language, data).catch(getCartError);
      }
    },
    {
      onSuccess: (response: OrderResponse) => {
        if (isLogged) {
          queryClient.setQueryData(queries, response);

          return response;
        } else {
          queryClient.setQueryData(queries, response);

          CartProductsStorage.setCart(response);
          return response;
        }
      },
      onSettled: () => {
        setIsReady(true);
      },
    },
  );

  const deleteFromCart = (productId: number) => {
    const { products }: Pick<OrderResponse, 'products'> = {
      products: cart.products.filter(({ id }) => id !== productId),
    } as OrderResponse;

    if (isLogged) {
      void OrderService.patchOrder(axiosInstance, language, {
        products,
        step: EOrderSteps.CART_FORM,
      }).then((response) => {
        queryClient.setQueryData(queries, response);
      });
    } else {
      if (products.length > 0) {
        void OrderService.postVirtualCart(axiosInstance, language, { products })
          .catch(getCartError)
          .then((response) => {
            queryClient.setQueryData(queries, response);

            CartProductsStorage.setCart(response);
          });
      } else {
        queryClient.setQueryData(queries, null);
        CartProductsStorage.removeCart();
      }
    }
  };

  const setAutoSavingsFrequency = (productId: number, repeatBuyFrequency: AutoSavingsFrequency) => {
    const updatedCart = { products: [...cart.products], step: EOrderSteps.CART_FORM };
    const productToUpdate = updatedCart.products.find(({ id }) => productId === id);

    productToUpdate.repeatBuyFrequency = repeatBuyFrequency;

    if (isLogged) {
      return OrderService.patchOrder(axiosInstance, language, updatedCart).then((response) => {
        queryClient.setQueryData(queries, response);
      });
    } else {
      return OrderService.postVirtualCart(axiosInstance, language, updatedCart)
        .catch(getCartError)
        .then((response) => {
          CartProductsStorage.setCart(response);
          queryClient.setQueryData(queries, response);
        });
    }
  };

  const setQuantity = (
    productId: number,
    newQuantity: number,
    repeatBuyFrequency?: AutoSavingsFrequency,
    shippingMethod?: ShippingMethodEntity,
  ) => {
    const updatedCart = {
      products: [...cart.products],
      step: EOrderSteps.CART_FORM,
      shippingMethod,
    };

    setIsReady(false);

    const affectedProduct = updatedCart.products.find(({ id }) => id === productId);
    if (affectedProduct) {
      affectedProduct.quantity = newQuantity;

      if (repeatBuyFrequency !== undefined) {
        affectedProduct.repeatBuyFrequency = repeatBuyFrequency;
      }
    }

    if (isLogged) {
      return OrderService.patchOrder(axiosInstance, language, updatedCart)
        .then((response) => {
          queryClient.setQueryData(queries, response);

          return response;
        })
        .finally(() => {
          setIsReady(true);
        });
    } else {
      return OrderService.postVirtualCart(axiosInstance, language, updatedCart)
        .catch(getCartError)
        .then((response) => {
          CartProductsStorage.setCart(response);

          queryClient.setQueryData(queries, response);

          return response;
        })
        .finally(() => {
          setIsReady(true);
        });
    }
  };

  const addProductsToCart = (newProducts: CartProductEntity[]): Promise<OrderResponse> => {
    if (isLogged) {
      const updatedCart = {
        step: EOrderSteps.CART_FORM,
        products: cart?.products || [],
      };

      newProducts?.forEach((newProduct) => {
        let isAdded = false;

        cart?.products?.forEach((cartProduct, index) => {
          if (newProduct.id === cartProduct.id) {
            updatedCart.products[index].quantity += newProduct.quantity;
            isAdded = true;
          }
        });

        if (!isAdded) {
          updatedCart.products.push(newProduct);
        }
      });

      if (!id) {
        return OrderService.postOrder(axiosInstance, language, updatedCart).then((response) => {
          queryClient.setQueryData(queries, response);

          return response;
        });
      } else {
        return OrderService.patchOrder(axiosInstance, language, updatedCart).then((response) => {
          queryClient.setQueryData(queries, response);

          return response;
        });
      }
    } else {
      const updatedCart = {
        ...(cart || {}),
        products: [...(cart?.products || []), ...newProducts],
      } as OrderResponse;

      return OrderService.postVirtualCart(axiosInstance, language, updatedCart)
        .catch(getCartError)
        .then((response) => {
          CartProductsStorage.setCart(response);
          queryClient.setQueryData(queries, response);

          return response;
        });
    }
  };

  const setCurrentCartToAbandonedCart = (abandonedCartId: number) => {
    return OrderService.setCurrentCartToAbandonedCart(
      axiosInstance,
      language,
      abandonedCartId.toString(),
    ).then((response) => {
      queryClient.setQueryData(queries, response);

      return response;
    });
  };

  const showAddToCartInfo = ({
    isToast,
    ...restProps
  }: CartPopupType & {
    isToast: boolean;
  }) => {
    if (isToast) {
      addToast({
        title: tCommon('toast.success'),
        description: tProduct('cartItems.added.itemAdded'),
        status: EColor.SUCCESS,
      });
    } else {
      showPopup(restProps);
    }
  };

  const addToCart = (
    product: CartProductEntity,
    isToast = false,
    shippingMethod?: ShippingMethodEntity,
  ): Promise<OrderResponse> => {
    cleanImmediately();

    const { warningText, iconType: warningIconType } = getWarningTextFromShippingMethod(
      product,
      cart,
      shippingMethod,
    );

    // If the product is already in the cart, you just have to change the quantity
    const foundProduct = cart?.products?.find(({ id }) => id === product.id);
    if (foundProduct) {
      const { currentStock, preorder } = foundProduct;
      const newQuantity = foundProduct.quantity + product.quantity;

      if (
        newQuantity <= currentStock ||
        (preorder?.isPreorder && newQuantity <= preorder?.preorderStock)
      ) {
        return setQuantity(
          product.id,
          newQuantity,
          product.repeatBuyFrequency,
          shippingMethod,
        ).then((response) => {
          const { totalPrice, currencyIso, productCounts, products: responseProducts } = response;
          const addedProduct = responseProducts.find(({ id }) => id === product.id);

          showAddToCartInfo({
            totalPrice,
            product: addedProduct,
            currencyIso,
            productCounts,
            isToast,
            warningText,
            warningIconType,
          });

          return response;
        });
      }

      addToast({
        title: tCommon('toast.error'),
        description: tProduct('order.errors.reachedStockLimit'),
        status: EColor.WARNING,
      });

      return null;
    }

    setIsReady(false);

    if (isLogged) {
      const products = [...(cart?.products || []), product];

      const updatedCart = {
        step: EOrderSteps.CART_FORM,
        products: updateProductAutoSavingsFromShippingMethodAndAddedProduct(
          products,
          shippingMethod,
        ),
        shippingMethod,
      };

      if (!id) {
        return OrderService.postOrder(axiosInstance, language, updatedCart)
          .then((response) => {
            const { totalPrice, currencyIso, productCounts, products: responseProducts } = response;

            queryClient.setQueryData(queries, response);

            const addedProduct = responseProducts.find(({ id }) => id === product.id);

            showAddToCartInfo({
              product: addedProduct,
              totalPrice,
              currencyIso,
              productCounts,
              isToast,
              warningText,
              warningIconType,
            });

            return response;
          })
          .finally(() => {
            setIsReady(true);
          });
      } else {
        return OrderService.patchOrder(axiosInstance, language, updatedCart)
          .then((response) => {
            const { totalPrice, currencyIso, productCounts, products: responseProducts } = response;

            const addedProduct = responseProducts.find(({ id }) => id === product.id);

            queryClient.setQueryData(queries, response);

            showAddToCartInfo({
              product: addedProduct,
              totalPrice,
              currencyIso,
              productCounts,
              isToast,
              warningText,
              warningIconType,
            });

            return response;
          })
          .finally(() => {
            setIsReady(true);
          });
      }
    } else {
      const updatedCart = {
        ...getUpdatedCart(product, shippingMethod),
        shippingMethod,
      };

      return OrderService.postVirtualCart(axiosInstance, language, updatedCart)
        .catch(getCartError)
        .then((response) => {
          const { totalPrice, currencyIso, products: responseProducts } = response;
          CartProductsStorage.setCart(response);

          queryClient.setQueryData(queries, response);

          const addedProduct = responseProducts.find(({ id }) => id === product.id);

          showAddToCartInfo({
            product: addedProduct,
            totalPrice,
            currencyIso,
            productCounts: updatedCart.products.reduce((acc, { quantity }) => acc + quantity, 0),
            isToast,
            warningText,
            warningIconType,
          });

          return response;
        })
        .finally(() => {
          setIsReady(true);
        });
    }
  };

  return {
    id,
    isReady,
    addToCart,
    deleteFromCart,
    updateCart,
    setCurrentCartToAbandonedCart,
    abandonedCartId,
    setAbandonedCartId,
    setQuantity,
    setAutoSavingsFrequency,
    cart: cart || ({ products: [] } as OrderResponse),
    addProductsToCart,
    initializeCart,
    changePriceWithCurrency,
    resetCart,
  };
};
