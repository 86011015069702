import { RoutingStorage } from '@core/storage';
import { EStorageType } from '@core/type';

const setKeyRoute = (value: string) => {
  RoutingStorage.setKeyRouteStore(EStorageType.LOCAL_STORAGE, value);
};

const getKeyRoute = (): string =>
  RoutingStorage.getKeyRouteStore(EStorageType.LOCAL_STORAGE) || null;

export class RoutingService {
  public static readonly setKeyRoute = setKeyRoute;
  public static readonly getKeyRoute = getKeyRoute;
}
