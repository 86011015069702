import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type {
  AuthEntity,
  ChangePasswordEntity,
  ForgottenPasswordEntity,
  LoginEntity,
  ResendUnlockInstructionsEntity,
  TwoStepAuthReq,
  UnlockAccountEntity,
  UserSignUp,
} from '@core/type/api';

import { Api } from '../constants/api';
import { configResponse, EConfigName } from '../helpers/config';

const logout = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<void>> =>
  axiosInstance.delete(Api.auth.LOGOUT, configResponse({ [EConfigName.LANGUAGE]: language }));

const login = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & LoginEntity,
): Promise<AxiosResponse<AuthEntity>> => {
  return axiosInstance.post(
    Api.auth.LOGIN,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const refreshToken = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & Omit<AuthEntity, 'token'>,
): Promise<AxiosResponse<AuthEntity>> => {
  return axiosInstance.put(
    Api.auth.REFRESH_TOKEN,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const changePassword = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & ChangePasswordEntity,
): Promise<AxiosResponse<AuthEntity>> => {
  return axiosInstance.put(
    Api.auth.CHANGE_PASSWORD,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const unlockAccount = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & UnlockAccountEntity,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.put(
    Api.auth.UNLOCK_ACCOUNT,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const forgottenPassword = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & ForgottenPasswordEntity,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.post(
    Api.auth.FORGOTTEN_PASSWORD,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const signUp = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & UserSignUp,
): Promise<AxiosResponse<AuthEntity>> => {
  return axiosInstance.post(
    Api.auth.SIGNUP,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getSms = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.get(Api.auth.SMS, configResponse({ [EConfigName.LANGUAGE]: language }));
};

const postSms = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & TwoStepAuthReq,
): Promise<AxiosResponse<AuthEntity>> => {
  return axiosInstance.post(
    Api.auth.SMS,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const postTotp = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & TwoStepAuthReq,
): Promise<AxiosResponse<AuthEntity>> => {
  return axiosInstance.post(
    Api.auth.TOTP,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const resendUnlockInstructions = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & ResendUnlockInstructionsEntity,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.post(
    Api.auth.UNLOCK_ACCOUNT,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class AuthAPI {
  public static readonly login = login;
  public static readonly logout = logout;
  public static readonly signUp = signUp;
  public static readonly getSms = getSms;
  public static readonly postSms = postSms;
  public static readonly postTotp = postTotp;
  public static readonly unlockAccount = unlockAccount;
  public static readonly refreshToken = refreshToken;
  public static readonly changePassword = changePassword;
  public static readonly forgottenPassword = forgottenPassword;
  public static readonly resendUnlockInstructions = resendUnlockInstructions;
}
