import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type { PostWalletSalesEntity, WalletEntity, WalletSalesEntity } from '@core/type/api';
import { PutWalletSalesEntity } from '@core/type/api';

import { Api } from '../constants/api';
import { configResponse, EConfigName } from '../helpers/config';

const getWallet = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<WalletEntity>> => {
  return axiosInstance.get(Api.user.WALLET, configResponse({ [EConfigName.LANGUAGE]: language }));
};

const getWalletSales = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<WalletSalesEntity>> => {
  return axiosInstance.get(
    Api.user.WALLET_SALES,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const postWalletSales = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & PostWalletSalesEntity,
): Promise<AxiosResponse<WalletSalesEntity>> => {
  return axiosInstance.post(
    Api.user.WALLET_SALES,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const putWalletSales = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & PutWalletSalesEntity,
): Promise<AxiosResponse<WalletSalesEntity>> => {
  return axiosInstance.put(
    Api.user.WALLET_SALES_CONFIRM,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class WalletAPI {
  public static readonly getWallet = getWallet;
  public static readonly getWalletSales = getWalletSales;
  public static readonly postWalletSales = postWalletSales;
  public static readonly putWalletSales = putWalletSales;
}
