import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type { TutorialVideoBySlugEntity, TutorialVideosEntity } from '@core/type/api';

import { Api } from '../constants/api';
import { replaceIdUrl } from '../helpers/api';
import { configResponse, EConfigName } from '../helpers/config';

const getTutorialVideoBySlug = async (
  axiosInstance: AxiosInstance,
  { slug, language }: LanguageProps & { slug: string },
): Promise<AxiosResponse<TutorialVideoBySlugEntity>> =>
  axiosInstance.get(
    replaceIdUrl(Api.tutorialVideos.TUTORIAL_VIDEO_BY_SLUG, slug, ':slug'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const getTutorialVideos = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<TutorialVideosEntity>> => {
  return axiosInstance.get(
    Api.tutorialVideos.TUTORIAL_VIDEOS,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class TutorialVideosAPI {
  public static readonly getTutorialVideos = getTutorialVideos;
  public static readonly getTutorialVideoBySlug = getTutorialVideoBySlug;
}
