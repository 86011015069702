import { ELanguageTags } from '@core/type';

export const GENERAL_CONDITIONS_MAP_SLUG_TO_URL = {
  [ELanguageTags.IT_IT]: 'condizioni-generali',
  [ELanguageTags.FR_FR]: 'conditions-generales',
  [ELanguageTags.DE_DE]: 'allgemeine-geschaftsbedingungen',
  [ELanguageTags.EN_US]: 'general-conditions',
};

export const GENERAL_CONDITIONS_SLUGS = Object.values(GENERAL_CONDITIONS_MAP_SLUG_TO_URL);

export const PRIVACY_POLICY_MAP_SLUG_TO_URL = {
  [ELanguageTags.IT_IT]: 'informativa-sulla-privacy',
  [ELanguageTags.FR_FR]: 'politique-de-confidentialite',
  [ELanguageTags.DE_DE]: 'datenschutzerklarung',
  [ELanguageTags.EN_US]: 'privacy-policy',
};

export const PRIVACY_POLICY_SLUGS = Object.values(PRIVACY_POLICY_MAP_SLUG_TO_URL);
