import { EZIndexName, ThemeZIndex } from '@core/type';

export const zIndexes: ThemeZIndex = {
  [EZIndexName.BACKGROUND]: -1,
  [EZIndexName.DEFAULT]: 0,
  [EZIndexName.OVER_DEFAULT]: 1,
  [EZIndexName.HOVER_EFFECT]: 10,
  [EZIndexName.POPOVER]: 1010,
  [EZIndexName.DROPDOWN]: 1020,
  [EZIndexName.TOOLTIP]: 1030,
  [EZIndexName.FIXED]: 1040,
  [EZIndexName.HEADER]: 1050,
  [EZIndexName.DRAWER]: 1060,
  [EZIndexName.MODAL_BACKGROUND]: 1080,
  [EZIndexName.MODAL]: 1090,
  [EZIndexName.ABOVE_CRISP]: 1100,
  [EZIndexName.AUTO]: 'auto',
};

/* Just add this class to your component if you'd like it to have the
   system default scroll bar when it overflows.
*/
export const DEFAULT_SCROLL_BAR_CLASS = 'default-scroll-bar';

export const CONTAINER_GUTTERS = 40;
export const MIN_SCREEN_WIDTH = 374;
