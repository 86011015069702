import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconAlertRounded = ({ fill }: DynamicIconProps) => {
  return (
    <SVG height={24} width={25} viewBox="0 0 25 24" title="Alert rounded icon" fill="inherit">
      <g>
        <path
          fill={fill}
          d="M12.3,17.3c0.1,0.3,0.4,0.4,0.7,0.4c0.3,0,0.6-0.2,0.7-0.4l-0.7,0L12.3,17.3z"
        />
        <path
          fill={fill}
          d="M17.7,15.5c-0.3-0.2-0.5-0.5-0.7-0.7c-0.2-0.3-0.3-0.6-0.4-1c-0.2-0.7-0.3-1.6-0.3-2.4   c-0.1-2.1-1.5-3.9-3.3-4.1c-1.8,0.2-3.2,2-3.3,4.1c0,0.9-0.1,1.7-0.3,2.4c-0.1,0.4-0.2,0.7-0.4,1c-0.2,0.3-0.4,0.6-0.7,0.7   c0,0-0.1,0-0.1,0.1c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.2,0.1,0.2c0,0,0,0,0.1,0h0l4.6-0.2c0,0,0,0,0,0c0,0,0,0,0,0l4.6,0.2h0   c0,0,0,0,0.1,0c0,0,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.3C17.8,15.6,17.7,15.5,17.7,15.5z"
        />
        <path
          fill={fill}
          d="M12.8,0c-6.6,0-12,5.4-12,12s5.4,12,12,12c6.6,0,12-5.4,12-12S19.5,0,12.8,0z M19,16.2   c-0.1,0.5-0.5,1.2-1.4,1.2l-2.7-0.1c-0.2,0.9-1,1.6-1.9,1.6c-0.9,0-1.7-0.7-1.9-1.6l-2.7,0.1c-0.8,0-1.3-0.7-1.4-1.2   c-0.1-0.5,0-1.3,0.7-1.7c0.1,0,0.1-0.1,0.2-0.3C8,14,8.1,13.8,8.2,13.5c0.2-0.6,0.2-1.4,0.3-2.2c0.1-2.1,1.3-4.2,3.2-4.9l0-0.2   C11.8,5.5,12.3,5,13,5c0.7,0,1.2,0.5,1.3,1.2l0,0.2c1.9,0.8,3.1,2.8,3.2,4.9c0,0.8,0.1,1.6,0.3,2.2c0.1,0.3,0.2,0.5,0.3,0.7   c0.1,0.2,0.2,0.3,0.2,0.3C19,14.9,19.1,15.6,19,16.2z"
        />
      </g>
    </SVG>
  );
};
