import Image from 'next/image';

import IconContactSvg from './svgs/icon-contact.svg';

export const IconContact = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconContactSvg}
    alt="Contact icon"
    height={47}
    width={47}
  />
);
