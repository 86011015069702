import { camelizeKeys } from 'humps';

import {
  EWebSocketCommand,
  EWebSocketIdentifier,
  WebSocketChannel,
  WebSocketCommand,
  WebSocketIdentifier,
} from '@core/type';

import { Api } from '../constants/api';
import { addParameters } from '../helpers/util';

const open = (
  wsHost: string,
  identifier: EWebSocketIdentifier,
  parameters: Record<string, string>,
): WebSocket => {
  const ws = new WebSocket(addParameters(`${wsHost}${Api.webSocket.CABLE}`, parameters));

  ws.onopen = () => {
    ws.send(
      JSON.stringify({
        command: EWebSocketCommand.SUBSCRIBE,
        identifier,
      } as WebSocketCommand),
    );
  };
  return ws;
};

const close = (ws: WebSocket): void => {
  if (ws.readyState === WebSocket.OPEN) {
    ws.close();
  }
};

function message<T>(
  ws: WebSocket,
  identifier: EWebSocketIdentifier,
  setter: (state: T) => void,
): void {
  if (ws.readyState === WebSocket.OPEN) {
    ws.send(
      JSON.stringify({ command: EWebSocketCommand.SUBSCRIBE, identifier } as WebSocketCommand),
    );
  }
  ws.onmessage = (event: MessageEvent<string>) => {
    const obj: WebSocketIdentifier<T> = JSON.parse(
      camelizeKeys(event.data),
    ) as WebSocketIdentifier<T>;

    if (obj?.identifier) {
      const wsIdentifier: WebSocketChannel = JSON.parse(obj.identifier) as WebSocketChannel;
      const clientIdentifier: WebSocketChannel = JSON.parse(identifier) as WebSocketChannel;

      if (wsIdentifier.channel === clientIdentifier.channel && obj.message) {
        setter(camelizeKeys(obj.message) as unknown as T);
      }
    }
  };
}

export class WebSocketService {
  public static readonly open = open;
  public static readonly close = close;
  public static readonly message = message;
}
