import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconEdit = ({ fill }: DynamicIconProps) => {
  return (
    <SVG height={16} width={16} viewBox="0 0 16 16" title="Edit icon">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7852 6.16991L9.82992 4.21466L2.73687 11.3077C2.63756 11.407 2.57705 11.5386 2.56627 11.6786L2.42003 13.5798L4.3212 13.4336C4.46124 13.4228 4.59281 13.3623 4.69213 13.263L11.7852 6.16991ZM13.1994 6.16991L12.8458 6.52347L12.4923 6.87702L5.39923 13.9701C5.13112 14.2382 4.77595 14.4015 4.3979 14.4306L2.34242 14.5887L1.3335 14.6663L1.41111 13.6574L1.56922 11.6019C1.5983 11.2239 1.76166 10.8687 2.02977 10.6006L9.12282 3.50756L9.47637 3.154L9.82992 2.80045L10.5995 2.03085C11.53 1.10039 13.0385 1.10039 13.969 2.03085C14.8994 2.9613 14.8994 4.46986 13.969 5.40031L13.1994 6.16991ZM10.537 3.50756L11.3066 2.73795C11.8466 2.19803 12.722 2.19803 13.2619 2.73795C13.8018 3.27788 13.8018 4.15328 13.2619 4.69321L12.4923 5.46281L10.537 3.50756Z"
        fill={fill}
      />
    </SVG>
  );
};
