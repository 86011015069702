export enum EWebSocketType {
  PING = 'ping',
}

export enum EWebSocketIdentifier {
  METAL_PRICES = '{"channel":"MetalPrices"}',
  LIVE_CHART = '{"channel":"LiveChartChannel"}',
  USER_CHANNEL = '{"channel":"UserChannel"}',
}

export enum EWebSocketChannel {
  METAL_PRICES = 'MetalPrices',
  LIVE_CHART = 'LiveChart',
}

export enum EWebSocketCommand {
  SUBSCRIBE = 'subscribe',
}

export type WebSocketMessage = {
  type: EWebSocketType;
  message: number;
};

export type WebSocketIdentifier<T> = {
  identifier: EWebSocketIdentifier;
  message: T;
};

export type WebSocketChannel = {
  channel: EWebSocketChannel;
};

export type WebSocketCommand = {
  command: EWebSocketCommand;
  identifier: EWebSocketIdentifier;
};

export type WebSocketSetterCommand<T> = WebSocketCommand & {
  setter: (state: T) => void;
};
