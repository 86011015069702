import { createContext, useContext } from 'react';

import type { EMixpanelEnv } from '@core/type';

export type MixpanelContextState = {
  enable: boolean;
  isReady: boolean;
  host: string;
  environment: EMixpanelEnv;
};

export const mixpanelContext = createContext<MixpanelContextState>(null);

export const useContextMixpanel = () => {
  return useContext(mixpanelContext);
};
