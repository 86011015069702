import { createContext } from 'react';

import type { HookStore, RoutingHook, RoutingState } from '@core/type/context';

import { useFnContext, useStoreContext } from '../util';

export const routingContext =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createContext<HookStore<any, RoutingState, RoutingHook>>(null);

export const useContextRouting = () => {
  return useFnContext(routingContext);
};

export function useStoreRouting<Selector>(selector: keyof RoutingState) {
  return useStoreContext<Selector, RoutingState, RoutingHook>(selector, routingContext);
}
