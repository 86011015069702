import type { ReactNode } from 'react';

import { alternateContext } from '@core/context';
import { ERouting } from '@core/type';
import { AlternatesEntity } from '@core/type/api';
import { AlternateHook, AlternateState } from '@core/type/context';
import { buildAlternate } from '@core/util';

import { storeHook } from '../helpers';
import { useAlternate } from '../hook';

export const ProviderAlternate = ({
  children,
  alternates,
  keyRoute,
  query,
}: {
  alternates?: AlternatesEntity;
  children?: ReactNode;
  keyRoute?: ERouting;
  query?: NodeJS.Dict<string | string[]>;
}) => {
  const hooks = storeHook<AlternateState, AlternateHook>(
    {
      alternates: buildAlternate({ alternates, keyRoute, query }),
    },
    useAlternate,
  );
  return <alternateContext.Provider value={hooks}>{children}</alternateContext.Provider>;
};
