import Image from 'next/image';

import IconDeliveryVehicleSvg from './svgs/icon-delivery-vehicle.svg';

export const IconDeliveryVehicle = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconDeliveryVehicleSvg}
    alt="Delivery vehicle icon"
    width={24}
    height={24}
  />
);
