import withTranslation from 'next-translate/withTranslation';

import { Box, Icon, LinkTranslatedStyledAsLink, Typography } from '@core/component';
import { DrawerCloseProps } from '@core/component/interface';
import { Translate } from '@core/constant';
import {
  EActionAttribute,
  EColor,
  EIcon,
  EPosition,
  ERouting,
  ESize,
  ETypographyVariant,
  WithTranslation,
} from '@core/type';

const ReferFriendsBase = ({ closeDrawer, i18n: { t } }: WithTranslation & DrawerCloseProps) => (
  <Box as={EActionAttribute.LI} marginTop={'40px'} marginBottom={'64px'}>
    <LinkTranslatedStyledAsLink keyRoute={ERouting.REFERRALS} onClick={closeDrawer}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Box maxWidth={'210px'} marginRight={'16px'}>
          <Typography variant={ETypographyVariant.H6} marginBottom={'8px'}>
            {t('menu.referFriend')}
          </Typography>
          <Typography variant={ETypographyVariant.BODY3}>
            {t('menu.referFriendAndEarnBonus')}
          </Typography>
        </Box>

        <Icon
          type={EIcon.ARROW_DOWN}
          orientation={EPosition.RIGHT}
          size={ESize.SM}
          flexShrink={'0'}
          color={EColor.ACCENT}
        />
      </Box>
    </LinkTranslatedStyledAsLink>
  </Box>
);

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const ReferFriends = withTranslation(ReferFriendsBase, Translate.layout.HEADER);
