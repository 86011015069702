import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconArrowTurned = ({ fill }: DynamicIconProps) => {
  return (
    <SVG height={24} width={24} viewBox="0 0 24 24" title="Arrow right">
      <g clipPath="url(#clip0_4415_43153)">
        <path
          d="M16.05 12.02C16.05 12.02 15.98 11.96 15.94 11.93C14.68 11.07 13.41 10.08 11.93 8.82L11.88 8.78C11.79 8.7 11.69 8.6 11.53 8.62C11.39 8.62 11.3 8.71 11.19 8.8L11.16 8.83C10.98 9 10.85 9.11 10.84 9.31C10.84 9.5 10.96 9.63 11.13 9.81C11.68 10.37 12.24 10.95 12.81 11.53L12.87 11.59C12.06 11.59 11.08 11.55 10.1 11.36C8.93 11.13 7.85 10.76 6.9 10.26C5.91 9.74 5.01 9.06 4.23 8.24C2.98 6.93 2.12 5.33 1.67 3.49C1.44 2.56 1.34 1.58 1.35 0.51C1.35 0.37 1.35 0.23 1.24 0.11C1.13 0 0.99 0 0.84 0H0.71C0.63 0 0.56 0 0.48 0C0.34 0 0.23 0.03 0.14 0.11C0 0.24 0 0.41 0 0.47C0 1.46 0.06 2.34 0.21 3.16C0.53 4.89 1.17 6.46 2.13 7.84C2.92 8.97 3.87 9.94 4.96 10.71C6.03 11.47 7.27 12.05 8.63 12.45C9.71 12.76 10.83 12.94 11.96 12.98C12.26 12.99 12.57 12.99 12.89 13L12.68 13.21C12.12 13.79 11.54 14.38 10.96 14.96C10.92 15 10.81 15.12 10.82 15.29C10.82 15.41 10.89 15.51 11 15.59C11.07 15.64 11.14 15.7 11.2 15.77C11.3 15.89 11.42 15.95 11.55 15.95C11.66 15.95 11.77 15.9 11.88 15.81L12.22 15.53C12.7 15.12 13.21 14.7 13.71 14.31C14.2 13.93 14.71 13.56 15.21 13.2L15.27 13.16C15.5 13 15.73 12.83 15.96 12.66L16.4 12.32L16.06 12.04L16.05 12.02Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4415_43153">
          <rect width="16.39" height="15.94" fill="white" />
        </clipPath>
      </defs>
    </SVG>
  );
};
