import {
  setContext as setContextSentry,
  setExtras as setExtrasSentry,
  setTags as setTagsSentry,
  setUser as setUserSentry,
} from '@sentry/nextjs';
import { Primitive } from '@sentry/types';

const setUser = (user: { email?: string; username?: string; id?: string }) => {
  setUserSentry(user);
};

const setExtras = (extras: Record<string, Primitive>) => {
  setExtrasSentry(extras);
};

const setTags = (tags: Record<string, Primitive>) => {
  setTagsSentry(tags);
};

const setContext = (
  name: string,
  context: {
    [key: string]: Primitive;
  } | null,
) => {
  setContextSentry(name, context);
};

export class UtilNext {
  public static readonly setUser = setUser;
  public static readonly setExtras = setExtras;
  public static readonly setContext = setContext;
  public static readonly setTags = setTags;
}
