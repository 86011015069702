/* eslint-disable jsx-a11y/anchor-is-valid */
import useTranslation from 'next-translate/useTranslation';

import { Icon, LinkTranslatedStyledAsLink } from '@core/component';
import { Translate } from '@core/constant';
import { EIcon, ERouting } from '@core/type';

export const FullLogoLink = ({ height, iconType }: { height: string; iconType: EIcon }) => {
  const { t } = useTranslation(Translate.common.ARIA);

  return (
    <LinkTranslatedStyledAsLink
      keyRoute={ERouting.HOME}
      aria-label={t('goldavenue')}
      isUnderlinedHover={false}
    >
      <Icon width={'184px'} height={height || '32px'} type={iconType} />
    </LinkTranslatedStyledAsLink>
  );
};
