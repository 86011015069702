import withTranslation from 'next-translate/withTranslation';

import { Box, Icon, LinkTranslatedStyledAsLink, Typography } from '@core/component';
import { Translate } from '@core/constant';
import {
  EColor,
  EIcon,
  EPosition,
  ERouting,
  ESize,
  ETypographyVariant,
  WithTranslation,
} from '@core/type';

import { CloseDrawerFnTyp } from '../../../interface-header';

const NeedHelpBase = ({ closeDrawer, i18n: { t } }: CloseDrawerFnTyp & WithTranslation) => {
  return (
    <Box margin={'48px 0'} padding={'0 40px'}>
      <LinkTranslatedStyledAsLink keyRoute={ERouting.CONTACT} onClick={closeDrawer}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Box marginRight={'40px'}>
            <Typography variant={ETypographyVariant.H6} marginBottom={'8px'}>
              {t('needHelp')}
            </Typography>
            <Typography>{t('findYourAnswer')}</Typography>
          </Box>

          <Icon
            type={EIcon.ARROW_DOWN}
            orientation={EPosition.RIGHT}
            size={ESize.SM}
            flexShrink={'0'}
            color={EColor.ACCENT}
          />
        </Box>
      </LinkTranslatedStyledAsLink>
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const NeedHelp = withTranslation(NeedHelpBase, Translate.layout.HEADER);
