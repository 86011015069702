import Image from 'next/image';

import IconCircularArrowSvg from './svgs/icon-circular-arrow.svg';

export const IconCircularArrow = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconCircularArrowSvg}
    alt="Circular arrow icon"
    width={16}
    height={16}
  />
);
