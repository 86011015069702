import { useContextSelect } from '@core/context';
import { EIcon, EPosition, ESize } from '@core/type';

import { Icon } from '../icon';
import { IconProps } from '../interface';

export const StyledSelectBaseIcon = ({
  isOpen,
  ...restProps
}: Partial<IconProps> & {
  isOpen?: boolean;
}) => (
  <Icon
    size={ESize.SM}
    display={'flex'}
    alignItems={'center'}
    flexShrink={0}
    {...restProps}
    type={EIcon.ANGLE}
    orientation={isOpen ? EPosition.TOP : EPosition.BOTTOM}
  />
);

export const SelectBaseIcon = (props: Partial<IconProps>) => {
  const { isOpen } = useContextSelect();

  return <StyledSelectBaseIcon {...props} isOpen={isOpen} />;
};
