import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { ClaimDetailsEntity, ClaimsRequestEntity, ConfirmClaimPayload } from '@core/type/api';

import { ClaimAPI } from '../api/claim';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
): Promise<R> => {
  return logService('GiftsService', methodName, dto, apiCall);
};

const postClaim = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: Partial<ClaimsRequestEntity>,
) => {
  return log('postClaim', { axiosInstance, language, ...data }, ClaimAPI.postClaim);
};

const patchClaim = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: Partial<ClaimsRequestEntity>,
) => {
  return log('patchClaim', { axiosInstance, language, ...data }, ClaimAPI.patchClaim);
};

const getClaim = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<ClaimDetailsEntity> => {
  return log('getClaim', { axiosInstance, language }, ClaimAPI.getClaim);
};

const reviewClaim = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<ClaimDetailsEntity> => {
  return log('reviewClaim', { axiosInstance, language }, ClaimAPI.reviewClaim);
};

const getClaimHistory = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  claimId: number,
): Promise<ClaimDetailsEntity> => {
  return log('getClaimHistory', { axiosInstance, claimId, language }, ClaimAPI.getClaimHistory);
};

const confirmClaim = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: ConfirmClaimPayload,
): Promise<ClaimDetailsEntity> => {
  return log('confirmClaim', { axiosInstance, language, ...data }, ClaimAPI.confirmClaim);
};

export class ClaimsService {
  public static readonly getClaim = getClaim;
  public static readonly getClaimHistory = getClaimHistory;
  public static readonly patchClaim = patchClaim;
  public static readonly postClaim = postClaim;
  public static readonly confirmClaim = confirmClaim;
  public static readonly reviewClaim = reviewClaim;
}
