import styled from 'styled-components';

import type { WithThemeProps } from '@core/type';
import { EColor } from '@core/type';

import { Box } from '../layout/Box';
import { InputBackgroundProps } from './interface-input';

export const InputBackground = styled(Box)<InputBackgroundProps>(
  ({
    theme: {
      palette,
      structure: {
        input: { background: backgroundProps },
      },
    },
    isDisabled,
    isReadonly,
  }: InputBackgroundProps & WithThemeProps) => ({
    ...backgroundProps,
    ...((isDisabled || isReadonly) && {
      backgroundColor: palette[EColor.BACKGROUND][EColor.SECONDARY][EColor.MAIN],
    }),
  }),
);
