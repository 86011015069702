import { getEnvironmentPublic } from '@web-app/helpers';

import { ProviderMetalPrice } from '@core/provider';
import { EMetal } from '@core/type';
import { LiveChartsHeader } from '@core/widget';

export const LiveChartsWithProvider = () => {
  const metalPriceInitialize = {
    [EMetal.GOLD]: { isPositive: true, name: 'price.gold' },
    [EMetal.SILVER]: { isPositive: true, name: 'price.silver' },
    [EMetal.PLATINUM]: { isPositive: true, name: 'price.platinum' },
    [EMetal.PALLADIUM]: { isPositive: true, name: 'price.palladium' },
  };
  const { wsHost } = getEnvironmentPublic();

  return (
    <ProviderMetalPrice wsHost={wsHost} metalPriceInitialize={metalPriceInitialize}>
      <LiveChartsHeader />
    </ProviderMetalPrice>
  );
};
