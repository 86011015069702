import { useContextMediaQuery } from '@core/context';
import { ETypographyVariant } from '@core/type';

import { Box } from '../layout';
import { Line } from '../line';
import { Paper } from '../paper';
import { SkeletonTypography } from './SkeletonTypography';
import { SkeletonWrapper } from './SkeletonWrapper';

export const SkeletonAutoSavings = () => {
  const { isPhone, isMobile } = useContextMediaQuery();

  return (
    <SkeletonWrapper paddingTop={'40px'} overflow={'visible'}>
      <Box
        display={'flex'}
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent={'space-between'}
        marginBottom={'48px'}
        gap={'32px 16px'}
      >
        <Box maxWidth={isMobile ? undefined : '695px'} width={'100%'}>
          <SkeletonTypography
            variant={ETypographyVariant.H2}
            width={'180px'}
            marginBottom={'16px'}
          />

          <SkeletonTypography lineHeight={isPhone ? '88px' : '48px'} marginBottom={'16px'} />

          {!isPhone && <SkeletonTypography width={'200px'} />}
        </Box>

        <SkeletonTypography lineHeight={'48px'} width={'120px'} borderRadius={'8px'} />
      </Box>

      {isPhone && <Line marginBottom={'32px'} />}

      <Paper padding={isPhone ? '0' : isMobile ? '56px 24px' : '40px'} hasElevation={!isPhone}>
        {isMobile ? (
          <>
            <SkeletonTypography lineHeight={'54px'} maxWidth={'360px'} marginBottom={'24px'} />
            <SkeletonTypography lineHeight={'28px'} width={'180px'} marginBottom={'16px'} />
            <SkeletonTypography lineHeight={'28px'} width={'220px'} marginBottom={'16px'} />
            <SkeletonTypography lineHeight={'28px'} width={'160px'} />
          </>
        ) : (
          <>
            <SkeletonTypography lineHeight={'21px'} />
            <Line margin={'12px 0 24px'} />
            <SkeletonTypography lineHeight={'48px'} />
            <Line margin={'24px 0'} />
            <SkeletonTypography lineHeight={'48px'} />
            <Line margin={'24px 0'} />
            <SkeletonTypography lineHeight={'48px'} />
          </>
        )}
      </Paper>
    </SkeletonWrapper>
  );
};
