import { useEffect, useRef } from 'react';

import { AuthService, WebSocketService } from '@core/api';
import { useContextCart, useContextProfile, useContextUser, useContextUtil } from '@core/context';
import { EWebSocketIdentifier } from '@core/type';
import { UserEntity } from '@core/type/api';

export const useUserWebSocket = (wsHost: string) => {
  const {
    setCartItemsCount,
    setCurrency,
    setGaPayBalance,
    setBillingAddress,
    setBirthday,
    setPhone,
    setLanguage,
    setEmail,
    setGender,
    setHasNotCompletedStep,
  } = useContextProfile();
  const { isLogged } = useContextUser();
  const { isBotDetected } = useContextUtil();
  const { isReady: isCartReady, cart } = useContextCart();

  const websocket = useRef<WebSocket>(null);

  useEffect(() => {
    if (isLogged && !isBotDetected) {
      websocket.current = WebSocketService.open(wsHost, EWebSocketIdentifier.USER_CHANNEL, {
        Authorization: `Bearer ${AuthService.getAccessToken()}`,
      });

      WebSocketService.message(
        websocket.current,
        EWebSocketIdentifier.USER_CHANNEL,
        ({
          profile: {
            currencyIso,
            gaPayBalance,
            billingAddress,
            phone,
            cartItemsCount,
            birthday,
            language,
            email,
            gender,
            hasNotCompletedStep,
          },
        }: {
          profile: UserEntity;
        }) => {
          setCartItemsCount(cartItemsCount);
          setBillingAddress(billingAddress);
          setGaPayBalance(gaPayBalance);
          setCurrency(currencyIso);
          setPhone(phone);
          setBirthday(birthday);
          setLanguage(language);
          setEmail(email);
          setGender(gender);
          setHasNotCompletedStep(hasNotCompletedStep);
        },
      );
    }

    return () => {
      if (websocket.current) {
        websocket.current.close();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  useEffect(() => {
    if (websocket.current) {
      WebSocketService.message(
        websocket.current,
        EWebSocketIdentifier.USER_CHANNEL,
        ({
          profile: {
            currencyIso,
            gaPayBalance,
            billingAddress,
            phone,
            cartItemsCount,
            birthday,
            language,
            email,
            gender,
            hasNotCompletedStep,
          },
        }: {
          profile: UserEntity;
        }) => {
          setCartItemsCount(cartItemsCount);
          setBillingAddress(billingAddress);
          setGaPayBalance(gaPayBalance);
          setCurrency(currencyIso);
          setPhone(phone);
          setBirthday(birthday);
          setLanguage(language);
          setEmail(email);
          setGender(gender);
          setHasNotCompletedStep(hasNotCompletedStep);
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCartReady, isLogged, cart?.products?.length, cart?.id]);
};
