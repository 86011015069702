import { ActionType, EActionAttribute } from './action';

export enum ETypographyVariant {
  DISPLAY = 'display',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  BODY1 = 'body1',
  BODY2 = 'body2',
  BODY3 = 'body3',
  CAPTION1 = 'caption1',
  CAPTION2 = 'caption2',
  CAPTION3 = 'caption3',
}

export type TypographyAction = keyof ActionType<
  | EActionAttribute.H1
  | EActionAttribute.H2
  | EActionAttribute.H3
  | EActionAttribute.H4
  | EActionAttribute.H5
  | EActionAttribute.H6
  | EActionAttribute.SPAN
  | EActionAttribute.P
  | EActionAttribute.LINK
  | EActionAttribute.STRONG
  | EActionAttribute.ARTICLE
  | EActionAttribute.LI
  | EActionAttribute.LABEL
  | EActionAttribute.FIGCAPTION
  | EActionAttribute.DIV
>;

export type TypographyVariantType<Variant extends Partial<ETypographyVariant>> = {
  [key in Variant]: Variant;
};

export type TypographyVariant = keyof TypographyVariantType<
  | ETypographyVariant.DISPLAY
  | ETypographyVariant.H1
  | ETypographyVariant.H2
  | ETypographyVariant.H3
  | ETypographyVariant.H4
  | ETypographyVariant.H5
  | ETypographyVariant.H6
  | ETypographyVariant.BODY1
  | ETypographyVariant.BODY2
  | ETypographyVariant.BODY3
  | ETypographyVariant.CAPTION1
  | ETypographyVariant.CAPTION2
  | ETypographyVariant.CAPTION3
>;
