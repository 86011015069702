import { ELanguageTags, ERouting } from '@core/type';

export enum EOgImage {
  // value should be the same as an image file name in web-app/public/assets/*
  DEFAULT_IMAGE = 'default-image.png',
  TUTORIAL_VIDEOS = 'tutorial-videos.png',
  SILVER_LANDING_PAGE_DE = 'silver-landing-page-de.png',
  SILVER_LANDING_PAGE_EN = 'silver-landing-page-en.png',
  SILVER_LANDING_PAGE_FR = 'silver-landing-page-fr.png',
  SILVER_LANDING_PAGE_IT = 'silver-landing-page-it.png',
  AUTO_SAVINGS = 'auto-savaings.png',
}

export type OgImagesRouterType<T extends Partial<ERouting>> = {
  [key in T]: EOgImage;
};

export type OgImageRouterType = OgImagesRouterType<
  ERouting.HOME | ERouting.TUTORIAL_VIDEOS | ERouting.SILVER_LANDING | ERouting.AUTO_SAVINGS
>;

export type OgImages = { [key in ELanguageTags]: OgImageRouterType };
