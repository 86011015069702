import type { ReactNode } from 'react';

import { currencyContext } from '@core/context';
import { ECurrency, EStorageType } from '@core/type';
import { CurrencyHook, CurrencyState } from '@core/type/context';
import { getIsoCurrencyFromCookie } from '@core/util';

import { storeHook } from '../helpers';
import { useCurrency } from '../hook';

export const ProviderCurrency = ({
  children,
  currencyInitialize,
  storageType,
}: {
  children?: ReactNode;
  currencyInitialize?: ECurrency;
  storageType?: EStorageType;
}) => {
  const hookCurrency = storeHook<CurrencyState, CurrencyHook>(
    {
      currency: getIsoCurrencyFromCookie(currencyInitialize),
      storage: storageType,
    },
    useCurrency,
  );

  return <currencyContext.Provider value={hookCurrency}>{children}</currencyContext.Provider>;
};
