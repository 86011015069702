import { getCookie } from 'cookies-next';
import { ReactElement, ReactNode } from 'react';

import {
  useContextAuth,
  useContextMediaQuery,
  useContextRouting,
  useContextUser,
} from '@core/context';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { EStorageKey } from '@core/storage';
import { EAccessRoute, EColor, ERouting } from '@core/type';

import { Breadcrumb } from '../breadcrumb/Breadcrumb';
import { Box } from './Box';
import { Container } from './Container';
import { MainContainer } from './MainContainer';
import { LayoutProps } from './interface-layout';

const Children = ({
  accessRoute,
  renderLoading,
  renderNoAccess,
  children,
  isLoading,
}: {
  renderNoAccess: ReactElement;
  renderLoading: ReactElement;
  children?: ReactNode;
  accessRoute: EAccessRoute;
  isLoading?: boolean;
}) => {
  const { isLogged, isReady } = useContextUser();
  const { isLoadingAuth } = useContextAuth();

  if (!isReady || isLoading || isLoadingAuth) {
    return renderLoading;
  }

  switch (accessRoute) {
    case EAccessRoute.CONNECTED: {
      if (!isLogged) {
        return renderLoading;
      }

      return children;
    }
    case EAccessRoute.NOT_CONNECTED:
      return isLogged && getCookie(EStorageKey.TOKEN) ? renderNoAccess : (children as JSX.Element);
    default:
      return children as JSX.Element;
  }
};

export const Layout = ({
  children,
  accessRoute,
  noPaddingMargin,
  breadcrumbObjects,
  isFullHeightPage,
  isDarkBackground,
  breadcrumbObjectWithLogin,
  isFullWidthPage,
  renderLoading,
  renderNoAccess,
  isLoading,
}: LayoutProps) => {
  const { isLogged, isReady } = useContextUser();
  const { keyRoute } = useContextRouting();
  const { isPhone } = useContextMediaQuery();

  return (
    <MainContainer
      isFullHeightPage={isFullHeightPage}
      isDarkBackground={isDarkBackground}
      noPaddingMargin={noPaddingMargin}
    >
      {!isLoading &&
        breadcrumbObjects &&
        ((isLogged && accessRoute === EAccessRoute.CONNECTED && isReady) ||
          (!isLogged && accessRoute === EAccessRoute.NOT_CONNECTED) ||
          accessRoute === EAccessRoute.DEFAULT) && (
          <Container>
            <Breadcrumb
              isFullWidth={keyRoute === ERouting.CHARTS && isPhone}
              backgroundColor={keyRoute === ERouting.CHARTS && isPhone ? EColor.INFO : undefined}
              breadcrumbObjects={breadcrumbObjects}
              breadcrumbObjectWithLogin={breadcrumbObjectWithLogin}
            />
          </Container>
        )}
      <Box flexGrow={'1'}>
        <Container height={'100%'} isFullWidth={noPaddingMargin || isFullWidthPage}>
          <Children
            accessRoute={accessRoute}
            renderLoading={renderLoading}
            renderNoAccess={renderNoAccess}
            isLoading={isLoading}
          >
            {children}
          </Children>
        </Container>
      </Box>
    </MainContainer>
  );
};
