import { useEffect } from 'react';

import { useContextCurrency, useContextUtil } from '@core/context';
import { EGTMEventName } from '@core/type';
import { SanityPageResponse } from '@core/type/sanity';

import { useContextGTM } from '../context-gtm';
import { getEventOriginUrl, pushGTMEvent } from '../helpers';

export const useEventPageView = (cmsResult?: SanityPageResponse, shouldUseVariation?: boolean) => {
  const { enable, baseDatalayerProps } = useContextGTM();
  const { currency } = useContextCurrency();
  const { isScriptGALoaded } = useContextUtil();

  useEffect(() => {
    if (enable && isScriptGALoaded) {
      pushGTMEvent({
        ...baseDatalayerProps,
        currency,
        event: EGTMEventName.PAGE_VIEW,
        eventOrigin: getEventOriginUrl(baseDatalayerProps),
        abTest: { key: cmsResult?.abTest?.key, isVariant: shouldUseVariation },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseDatalayerProps?.pageUrl, isScriptGALoaded, currency]);
};
