import { useEffect, useState } from 'react';

import { Box, SelectCurrency, SelectLanguage } from '@core/component';
import { useContextRouting } from '@core/context';
import { ColorProps, EColor, EPosition, TextColorType } from '@core/type';
import { getTypeRoute } from '@core/util';

export const HeaderShort = ({ isLight }: { isLight?: boolean }) => {
  const { keyRoute } = useContextRouting();

  const [hasCurrency, setHasCurrency] = useState(false);
  const [hasLanguage, setHasLanguage] = useState(false);

  useEffect(() => {
    if (keyRoute) {
      setHasCurrency(getTypeRoute(keyRoute)?.header?.hasSelectCurrency);
      setHasLanguage(getTypeRoute(keyRoute)?.header?.hasSelectLanguage);
    }
  }, []);

  const color: ColorProps<TextColorType> = isLight
    ? EColor.PRIMARY
    : {
        commonType: EColor.WHITE,
        intensity: EColor.R900,
      };

  return (
    <Box display="flex" justifyContent="flex-end" gap="20px">
      {hasCurrency && (
        <SelectCurrency color={color} iconColor={color} horizontalPosition={EPosition.RIGHT} />
      )}
      {hasLanguage && (
        <SelectLanguage color={color} iconColor={color} horizontalPosition={EPosition.RIGHT} />
      )}
    </Box>
  );
};
