import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type {
  BlogArticlesEntity,
  BlogCategoriesEntity,
  BlogSearchAutocompleteEntity,
  ContentEntity,
  PagedRequest,
  PagedSearchRequest,
} from '@core/type/api';
import { PagedSlugRequest } from '@core/type/api';

import { Api } from '../constants/api';
import { replaceIdUrl } from '../helpers/api';
import { configResponse, EConfigName } from '../helpers/config';
import { getPagedQuery, getPagedSearchQuery, getSearchQuery } from '../helpers/paged';

const blogBySlug = async (
  axiosInstance: AxiosInstance,
  { language, slug }: LanguageProps & { slug: string },
): Promise<AxiosResponse<ContentEntity>> =>
  axiosInstance.get(
    replaceIdUrl(Api.content.BLOG_BY_SLUG, slug, ':slug'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const blog = async (
  axiosInstance: AxiosInstance,
  { language, ...pagedRequest }: LanguageProps & PagedRequest,
): Promise<AxiosResponse<BlogArticlesEntity>> => {
  return axiosInstance.get(
    `${Api.content.BLOG}${getPagedQuery(pagedRequest)}`,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getCategoryArticles = (
  axiosInstance: AxiosInstance,
  { language, slug, ...pagedRequest }: LanguageProps & PagedSlugRequest,
): Promise<AxiosResponse<BlogCategoriesEntity>> => {
  return axiosInstance.get(
    `${replaceIdUrl(Api.content.CATEGORY_BY_SLUG, slug, ':slug')}${getPagedQuery(pagedRequest)}`,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getTagArticles = (
  axiosInstance: AxiosInstance,
  { language, slug, ...pagedRequest }: LanguageProps & PagedSlugRequest,
): Promise<AxiosResponse<BlogCategoriesEntity>> => {
  return axiosInstance.get(
    `${replaceIdUrl(Api.content.TAG_BY_SLUG, slug, ':slug')}${getPagedQuery(pagedRequest)}`,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const blogSearch = (
  axiosInstance: AxiosInstance,
  { language, ...pagedRequest }: LanguageProps & PagedSearchRequest,
): Promise<AxiosResponse<BlogArticlesEntity>> => {
  return axiosInstance.get(
    `${Api.content.SEARCH}${getPagedSearchQuery(pagedRequest)}`,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const blogSearchAutocomplete = (
  axiosInstance: AxiosInstance,
  { q, language }: LanguageProps & { q?: string },
): Promise<AxiosResponse<BlogSearchAutocompleteEntity>> => {
  return axiosInstance.get(
    `${Api.content.SEARCH_AUTOCOMPLETE}${getSearchQuery(q)}`,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class ContentAPI {
  public static readonly blogBySlug = blogBySlug;
  public static readonly blog = blog;
  public static readonly getCategoryArticles = getCategoryArticles;
  public static readonly getTagArticles = getTagArticles;
  public static readonly blogSearch = blogSearch;
  public static readonly blogSearchAutocomplete = blogSearchAutocomplete;
}
