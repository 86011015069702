import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type {
  InvestmentChartsResponse,
  LiveChartsRequest,
  LiveChartsResponse,
} from '@core/type/api';
import { InvestmentChartsRequest } from '@core/type/api';

import { Api } from '../constants/api';
import { configResponse, EConfigName } from '../helpers/config';
import { addParameters } from '../helpers/util';

const getLiveCharts = (
  axiosInstance: AxiosInstance,
  { language, period, metal, currency, weightUnit }: LanguageProps & LiveChartsRequest,
): Promise<AxiosResponse<LiveChartsResponse>> => {
  return axiosInstance.get(
    addParameters(Api.liveCharts.LIVE_CHARTS, {
      metal_iso: metal,
      currency_iso: currency,
      ...(period ? { period } : {}),
      ...(weightUnit ? { weight_unit: weightUnit } : {}),
    }),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getInvestmentCharts = (
  axiosInstance: AxiosInstance,
  {
    language,
    metalIso,
    currencyIso,
    initialInvestment,
    monthlyInvestment,
    pastDataYearCount,
    forecastYearCount,
  }: LanguageProps & InvestmentChartsRequest,
): Promise<AxiosResponse<InvestmentChartsResponse>> => {
  return axiosInstance.get(
    addParameters(Api.liveCharts.INVESTMENT_CHARTS, {
      metal_iso: metalIso,
      currency_iso: currencyIso,
      initial_investment: String(initialInvestment),
      monthly_investment: String(monthlyInvestment),
      past_data_year_count: String(pastDataYearCount),
      forecast_year_count: String(forecastYearCount),
    }),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class LiveChartsAPI {
  public static readonly getLiveCharts = getLiveCharts;
  public static readonly getInvestmentCharts = getInvestmentCharts;
}
