import { forwardRef } from 'react';
import styled, { css, CSSObject, useTheme } from 'styled-components';

import {
  EActionAttribute,
  EColor,
  ELink,
  ELinkAnimation,
  EPosition,
  ThemeProps,
  WithThemeProps,
} from '@core/type';
import { getLinkColorProps, getPaletteHandlers } from '@core/util';

import { iconSize } from '../constants';
import { Icon, IconWrapper } from '../icon/Icon';
import { Box } from '../layout';
import { Typography } from '../typography';
import { IconContainerProps, IconElement, LinkProps } from './interface-link';

/**
 * is deprecated is favour of StyledLinkNext and LinkTranslatedStyledAsLink
 */
export const Link = forwardRef<HTMLAnchorElement, LinkProps & IconElement>((props, ref) => {
  const {
    structure: {
      link: {
        icon: { base: iconStyles },
        transition,
        indent,
      },
    },
  } = useTheme() as ThemeProps;

  const {
    as: component,
    startIcon,
    endIcon,
    customStartIcon,
    iconSize: iconSizeProps,
    iconColor,
    color = EColor.PRIMARY,
    children,
    fontSize,
    fontWeight,
    animation,
    hiddenTextOnMobile,
    isIconFill,
    isIconStroke,
    onClick,
    href,
    isUnderlinedHover = true,
    isChangingColorHover = true,
    textTransform,
    lineHeight,
    target,
    top,
    bottom,
    left,
    right,
    variant,
    iconStrokeWidth,
    iconPosition,
    display,
    iconOrientation,
    isUnderlined,
    letterSpacing,
    enableAnimation,
    iconMargin = indent,
    hoverColor,
    alignItems,
    iconTop = iconStyles.top,
    buttonType,
    textAlign,
    wordBreak,
    isDraggable = true,
    pointerEvents,
    disabled,
    hasEllipsisText,
  } = props;

  const hasIcon = !!startIcon || !!endIcon || !!customStartIcon;
  const size = iconSize[iconSizeProps];

  return (
    <StyledLink
      color={color}
      hasIcon={hasIcon}
      as={component}
      type={buttonType}
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment */
      onClick={onClick as any}
      animation={animation}
      variant={variant}
      fontSize={fontSize}
      fontWeight={fontWeight}
      hiddenTextOnMobile={hiddenTextOnMobile}
      isIconFill={isIconFill}
      isIconStroke={isIconStroke}
      ref={ref}
      href={href}
      textTransform={textTransform}
      lineHeight={lineHeight}
      target={target}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      isUnderlinedHover={isUnderlinedHover}
      isUnderlined={isUnderlined}
      display={display}
      isChangingColorHover={isChangingColorHover}
      enableAnimation={enableAnimation}
      alignItems={alignItems}
      hoverColor={hoverColor}
      letterSpacing={letterSpacing}
      textAlign={textAlign}
      wordBreak={wordBreak}
      draggable={isDraggable ? undefined : false}
      pointerEvents={disabled ? 'none' : pointerEvents}
    >
      {customStartIcon && (
        <Box {...iconStyles} marginRight={iconMargin}>
          {customStartIcon}
        </Box>
      )}
      {startIcon && (
        <Icon
          {...iconStyles}
          strokeWidth={iconStrokeWidth}
          transition={transition}
          type={startIcon}
          size={iconSizeProps}
          marginRight={iconMargin}
          color={iconColor}
          orientation={iconOrientation}
          top={iconTop}
        />
      )}
      {hasIcon ? (
        <Typography
          as={EActionAttribute.SPAN}
          variant={variant}
          fontSize={fontSize}
          fontWeight={fontWeight}
          {...(hasEllipsisText && {
            overflow: 'hidden',
            display: 'inline-block',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            verticalAlign: 'middle',
            maxWidth: `calc(100% - ${size} - ${iconMargin}px)`,
          })}
        >
          {children}
        </Typography>
      ) : (
        children
      )}
      {endIcon && (
        <IconContainer
          variant={variant}
          lineHeight={lineHeight}
          position={iconPosition}
          size={iconSizeProps}
        >
          <Icon
            {...iconStyles}
            transition={transition}
            type={endIcon}
            size={iconSizeProps}
            marginLeft={iconMargin}
            color={iconColor}
            orientation={iconOrientation}
            top={iconTop}
          />
        </IconContainer>
      )}
    </StyledLink>
  );
});

Link.displayName = 'Link';

type StyledLinkProps = LinkProps &
  IconElement & {
    hasIcon: boolean;
    enableAnimation: boolean;
  };

export const StyledLink = styled.a<StyledLinkProps>(
  ({
    theme: {
      palette,
      typography: { fontWeight: fontWeightVariant, defaultVariant, ...restTypography },
      structure: {
        link: {
          base,
          transition,
          svg: svgBase,
          icon: {
            [ELinkAnimation.ARROW_RIGHT]: arrowRightStyles,
            [ELinkAnimation.ARROW_DOWN]: arrowDownStyles,
          },
          underlineFirstPath,
          underlineSecondPath,
          text: textStyles,
          variant,
        },
      },
    },
    ...restProps
  }: StyledLinkProps & WithThemeProps) => {
    const {
      isUnderlined,
      isUnderlinedHover,
      isChangingColorHover,
      hiddenTextOnMobile,
      isIconFill = true,
      isIconStroke,
      color,
      animation,
      hasIcon,
      textTransform,
      top,
      hoverColor: hoverColorProps,
      bottom,
      left,
      right,
      letterSpacing,
      variant: typographyVariant = defaultVariant,
      fontSize = restTypography[typographyVariant].fontSize,
      fontWeight = restTypography[typographyVariant].fontWeight,
      lineHeight = restTypography[typographyVariant].lineHeight,
      display,
      enableAnimation,
      alignItems,
      textAlign,
      wordBreak,
      pointerEvents,
    } = restProps;
    const { getColor } = getPaletteHandlers(palette);
    const {
      color: textColor,
      hover: { color: hoverTextColor },
    } = getLinkColorProps({ color, palette });
    const hoverColor = getColor(hoverColorProps);

    const variantTextRules = hiddenTextOnMobile && variant[ELink.WITHOUT_TEXT_ON_MOBILE];

    const iconPresentStyles = css`
      ${Typography.toString()} {
        ${{ transition } as CSSObject};
        ${textStyles as CSSObject};
        ${underlineFirstPath as CSSObject};
        ${isUnderlined && (underlineSecondPath as CSSObject)};
        ${variantTextRules as CSSObject};
      }

      svg,
      svg * {
        ${{ transition } as CSSObject};
      }

      svg * {
        ${isIconFill && `fill: ${textColor}`};
        ${isIconStroke && `stroke: ${textColor}`};
      }

      ${IconWrapper} {
        ${enableAnimation
          ? animation === ELinkAnimation.ARROW_RIGHT && arrowRightStyles
          : animation === ELinkAnimation.ARROW_DOWN && arrowDownStyles};
      }

      &:hover {
        ${Typography.toString()} {
          ${underlineSecondPath as CSSObject};
        }

        ${IconWrapper} {
          ${animation === ELinkAnimation.ARROW_RIGHT && arrowRightStyles};
          ${animation === ELinkAnimation.ARROW_DOWN && arrowDownStyles};
        }

        svg,
        svg * {
          ${svgBase as CSSObject};
        }

        ${isChangingColorHover &&
        `svg * {
          ${isIconFill && `fill: ${hoverColor ?? hoverTextColor}`};
          ${isIconStroke && `stroke: ${hoverColor ?? hoverTextColor}`};
        }`}
      }
    `;

    return css`
      ${base as CSSObject};
      font-weight: ${fontWeightVariant[fontWeight]};
      font-size: ${fontSize};
      line-height: ${lineHeight};
      ${textTransform && ({ textTransform } as CSSObject)};
      color: ${textColor};
      top: ${top};
      bottom: ${bottom};
      left: ${left};
      right: ${right};
      display: ${display};
      align-items: ${alignItems};
      letter-spacing: ${letterSpacing};
      text-align: ${textAlign};
      word-break: ${wordBreak};
      pointer-events: ${pointerEvents};

      ${!hasIcon && (underlineFirstPath as CSSObject)};
      ${!hasIcon && ({ transition } as CSSObject)};
      ${isUnderlined && (underlineSecondPath as CSSObject)};
      ${hasIcon && iconPresentStyles};

      &:focus {
        color: ${textColor};
      }

      &:hover {
        ${isUnderlinedHover && (underlineSecondPath as CSSObject)};
        ${isChangingColorHover && `color: ${hoverColor ?? hoverTextColor}`};
      }
    `;
  },
);

const IconContainer = styled.div<IconContainerProps>(
  ({
    theme: {
      typography: { defaultVariant, ...restTypography },
    },
    variant,
    position,
    lineHeight: lineHeightProps,
  }: IconContainerProps & WithThemeProps) => {
    const lineHeight = lineHeightProps
      ? lineHeightProps
      : restTypography[variant ? variant : defaultVariant].lineHeight;

    if (position === EPosition.TOP_RIGHT) {
      return css`
        position: relative;
        display: inline;
        top: calc(-${lineHeight} / 2 + 4px);
        right: 0;
      `;
    }
    return css`
      position: relative;
      display: inline;
    `;
  },
);
