import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type {
  ClaimDetailsEntity,
  GiftDetailsEntity,
  OrderDetailsEntity,
  PagedRequest,
  WalletClaimEntity,
  WalletGiftEntity,
  WalletOrderEntity,
} from '@core/type/api';

import { Api } from '../constants/api';
import { replaceIdUrl } from '../helpers/api';
import { configResponse, EConfigName } from '../helpers/config';
import { getPagedQuery } from '../helpers/paged';

const getHistoryOrders = async (
  axiosInstance: AxiosInstance,
  { language, ...pagedRequest }: LanguageProps & PagedRequest,
): Promise<AxiosResponse<WalletOrderEntity>> => {
  return axiosInstance.get(
    `${Api.user.HISTORY_ORDERS}${getPagedQuery(pagedRequest)}`,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getHistoryGifts = async (
  axiosInstance: AxiosInstance,
  { language, ...pagedRequest }: LanguageProps & PagedRequest,
): Promise<AxiosResponse<WalletGiftEntity>> => {
  return axiosInstance.get(
    `${Api.user.HISTORY_GIFTS}${getPagedQuery(pagedRequest)}`,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getHistoryClaims = async (
  axiosInstance: AxiosInstance,
  { language, ...pagedRequest }: LanguageProps & PagedRequest,
): Promise<AxiosResponse<WalletClaimEntity>> => {
  return axiosInstance.get(
    `${Api.user.HISTORY_CLAIMS}${getPagedQuery(pagedRequest)}`,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getOrderById = async (
  axiosInstance: AxiosInstance,
  { id, language }: LanguageProps & { id: string },
): Promise<AxiosResponse<OrderDetailsEntity>> =>
  axiosInstance.get(
    replaceIdUrl(Api.user.HISTORY_ORDER_SHOW, id, ':id'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const getGiftByReferenceNumber = async (
  axiosInstance: AxiosInstance,
  { referenceNumber, language }: LanguageProps & { referenceNumber: string },
): Promise<AxiosResponse<GiftDetailsEntity>> =>
  axiosInstance.get(
    replaceIdUrl(Api.user.HISTORY_GIFT_SHOW, referenceNumber, ':reference_number'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const getClaimById = async (
  axiosInstance: AxiosInstance,
  { id, language }: LanguageProps & { id: string },
): Promise<AxiosResponse<ClaimDetailsEntity>> =>
  axiosInstance.get(
    replaceIdUrl(Api.user.HISTORY_CLAIM_SHOW, id, ':id'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

export class HistoryAPI {
  public static readonly getHistoryOrders = getHistoryOrders;
  public static readonly getHistoryGifts = getHistoryGifts;
  public static readonly getHistoryClaims = getHistoryClaims;
  public static readonly getOrderById = getOrderById;
  public static readonly getGiftByReferenceNumber = getGiftByReferenceNumber;
  public static readonly getClaimById = getClaimById;
}
