import Image from 'next/image';

import Icon60YearsLogoSvg from './svgs/icon-60-years-logo.svg';

export const Icon60YearsLogo = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={Icon60YearsLogoSvg}
    alt="60 years logo"
    width={93}
    height={34}
  />
);
