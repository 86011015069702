import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconAutoSavings = ({ fill }: DynamicIconProps) => {
  return (
    <SVG height={18} width={18} viewBox="0 0 18 18" title="Bin icon">
      <g clip-path="url(#a)" fill-rule="evenodd" clip-rule="evenodd" fill={fill}>
        <path d="M.092.207a.433.433 0 0 1 .6-.116l1.31.885a.43.43 0 0 1 .175.246l2.53 9.41h10.671l.791-3.209a.433.433 0 1 1 .84.207l-.872 3.539a.43.43 0 0 1-.42.329H3.963a1.337 1.337 0 1 0 0 2.673h.21a2.444 2.444 0 0 1 2.34-1.779c1.109 0 2.045.751 2.34 1.779h2.735a2.444 2.444 0 0 1 2.34-1.779c1.346 0 2.437 1.107 2.437 2.472s-1.09 2.471-2.437 2.471c-1.29 0-2.344-1.014-2.432-2.299H8.945c-.088 1.285-1.143 2.3-2.432 2.3-1.29 0-2.345-1.015-2.432-2.3h-.118a2.202 2.202 0 0 1-.15-4.399l-2.43-9.035L.208.808a.433.433 0 0 1-.116-.6M13.928 16.47c.857 0 1.572-.708 1.572-1.606s-.715-1.607-1.572-1.607-1.572.708-1.572 1.607c0 .898.715 1.606 1.572 1.606m-5.843-1.606c0 .898-.715 1.606-1.572 1.606s-1.572-.708-1.572-1.606.715-1.607 1.572-1.607 1.572.708 1.572 1.607" />
        <path d="M10.703 2.326a.433.433 0 1 0 0 .865h2.238V.953a.433.433 0 0 0-.865 0v.821a4.183 4.183 0 1 0 1.856 3.476.433.433 0 1 0-.866 0 3.317 3.317 0 1 1-1.748-2.924z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </SVG>
  );
};
