import Image from 'next/image';

import IconShieldSvg from './svgs/icon-shield.svg';

export const IconShield = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconShieldSvg}
    alt="Shield icon"
    width={24}
    height={24}
  />
);
