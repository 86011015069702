import withTranslation from 'next-translate/withTranslation';

import { Box, DrawerLine, Line } from '@core/component';
import { Translate } from '@core/constant';
import { useContextUser } from '@core/context';
import { useQueryApi } from '@core/hook';
import { EPosition, ERouting, WithTranslation } from '@core/type';
import { BlogCategoriesResponse, EQueryKey } from '@core/type/api';

import { LanguageDrawerLine } from './components/HeaderDefault/HeaderDefaultMobile/SideNavDefaultNew';
import { HelloUser } from './components/HeaderDefault/HeaderDefaultMobile/SideNavDefaultNew/HelloUser';
import { SignInButton } from './components/HeaderDefault/SignInOrOutButton';
import { SideNavBlogProps } from './interface-header';

const SideNavBlogContainer = ({
  i18n: { t },
  shouldShowCurrencyLink,
  position = EPosition.LEFT,
  closeDrawer,
  drawerLevelProps,
  handleDrawerLevelProps,
  isBlogPage,
}: WithTranslation & SideNavBlogProps) => {
  const { data: blogCategories } = useQueryApi<BlogCategoriesResponse>(EQueryKey.BLOG_CATEGORIES);
  const { isLogged } = useContextUser();

  return (
    <>
      {isLogged && (
        <HelloUser
          closeDrawer={closeDrawer}
          handleDrawerLevelProps={handleDrawerLevelProps}
          drawerLevelProps={drawerLevelProps}
          isBlogPage={isBlogPage}
        />
      )}
      <DrawerLine
        title={t('blog.whatIsGa')}
        route={ERouting.HOME}
        closeDrawer={closeDrawer}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={closeDrawer}
        isBlogPage={isBlogPage}
      />
      <Line margin="24px 20px" />
      <DrawerLine
        title={t('blog.allArticles')}
        route={ERouting.BLOG_HOME}
        closeDrawer={closeDrawer}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={closeDrawer}
        isBlogPage={isBlogPage}
      />
      {blogCategories?.blogCategories
        ?.sort((a, b) => a.position - b.position)
        .map(({ slug, title }) => (
          <DrawerLine
            title={title}
            route={ERouting.BLOG_CATEGORY}
            query={{ slug }}
            closeDrawer={closeDrawer}
            drawerLevelProps={drawerLevelProps}
            handleDrawerLevelProps={closeDrawer}
            key={slug}
            isBlogPage={isBlogPage}
          />
        ))}

      <Box marginTop={'64px'}>
        <LanguageDrawerLine
          drawerLevelProps={drawerLevelProps}
          handleDrawerLevelProps={handleDrawerLevelProps}
          shouldShowCurrencyLink={shouldShowCurrencyLink}
          position={position}
          isBlogPage={isBlogPage}
        />
      </Box>

      {!isLogged && (
        <Box padding={'106px 40px 20px'}>
          <SignInButton closeDrawer={closeDrawer} />
        </Box>
      )}
    </>
  );
};

SideNavBlogContainer.displayName = 'SideNavBlog';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const SideNavBlog = withTranslation(SideNavBlogContainer, Translate.layout.HEADER);
