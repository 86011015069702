import Image from 'next/image';

import IconGaLogoFullSvg from './svgs/icon-ga-logo-full.svg';

export const IconGaLogoFull = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconGaLogoFullSvg}
    alt="Loader Gold Avenue icon"
    width={184}
    height={32}
  />
);
