import useTranslation from 'next-translate/useTranslation';

import { LinkTranslatedStyledAsButton } from '@core/component';
import { Translate, metalPriceToSlug } from '@core/constant';
import { useContextCurrency } from '@core/context';
import { useLanguage } from '@core/hook';
import {
  EActionAttribute,
  EButtonVariant,
  EColor,
  EIcon,
  EMetal,
  ERouting,
  ESize,
} from '@core/type';

export const LiveChartsLink = () => {
  const { t } = useTranslation(Translate.layout.HEADER);

  const { currency } = useContextCurrency();
  const { language } = useLanguage();

  return (
    <LinkTranslatedStyledAsButton
      keyRoute={ERouting.CHARTS}
      query={{
        metalSlug: metalPriceToSlug[language][EMetal.GOLD],
        slug: [currency.toLowerCase()],
      }}
      size={ESize.XS}
      color={EColor.WHITE}
      variant={EButtonVariant.OUTLINED}
      as={EActionAttribute.LINK}
      padding="5px"
      lineHeight="10px"
      startIcon={EIcon.GRAPH}
      iconSize={ESize.XXS}
      isIconStroke
    >
      {t('menu.liveCharts')}
    </LinkTranslatedStyledAsButton>
  );
};
