import type {
  QueryClient,
  QueryFunction,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getParamsQueriesKeys } from '@core/api';
import {
  useContextCurrency,
  useContextProfile,
  useContextUser,
  useContextUtil,
} from '@core/context';
import { EHttpStatusCode } from '@core/type';
import { EQueryKey } from '@core/type/api';

import { useLanguage } from './use-language';

const onError = (key: string[], queryClient: QueryClient, err: unknown) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  if ((err as any)?.status === EHttpStatusCode.OK && (err as any)?.data) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    queryClient.setQueryData(key, (err as any)?.data);
  }
};

function useApi<TQueryFnData, TError, TData = TQueryFnData>({
  queries,
  options,
  queryFn,
}: {
  queries: string[];
  queryFn: QueryFunction<TQueryFnData, string[]>;
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, string[]>,
    'queryKey' | 'queryFn' | 'initialData'
  >;
}): UseQueryResult<TData, TError> {
  return useQuery(queries, queryFn, options);
}

export function useQueryApi<
  TQueryFnData = unknown,
  RequestDto = TQueryFnData,
  TError = unknown,
  TData = TQueryFnData,
>(
  queryKey: EQueryKey,
  //fn: QueryFunction<TQueryFnData, string[]>,
  params?: RequestDto,
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, string[]>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
): UseQueryResult<TData, TError> {
  const queryClient = useQueryClient();
  const { language } = useLanguage();
  const { currency } = useContextCurrency();
  const { isLogged } = useContextUser();
  const { axiosInstance } = useContextUtil();
  const { userId } = useContextProfile();

  let queriesCustom: string[] = [];
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  let queryFnCustom: QueryFunction<TQueryFnData, string[]> = () => {
    return null as TQueryFnData;
  };
  let optionsCustom: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, string[]>,
    'queryKey' | 'queryFn' | 'initialData'
  > = { enabled: false };

  if (queryKey) {
    const { queries, shouldLogged, queryFn } = getParamsQueriesKeys<RequestDto, TQueryFnData>({
      queryKey,
      axiosInstance,
      language,
      currency,
      params,
      isLogged,
    });

    const enable = options?.enabled === false ? false : isLogged;

    queriesCustom = shouldLogged ? [...queries, userId ? userId.toString() : null] : queries;
    queryFnCustom = queryFn;
    optionsCustom = shouldLogged
      ? { ...options, enabled: enable, onError: (err) => onError(queriesCustom, queryClient, err) }
      : { ...options, onError: (err) => onError(queriesCustom, queryClient, err) };
  }

  return useApi({
    queries: queriesCustom,
    queryFn: queryFnCustom,
    options: optionsCustom,
  });
}

export function useParamsQueriesKeys<RequestDto>(
  queryKey: EQueryKey,
  params?: RequestDto,
): string[] {
  const { language } = useLanguage();
  const { currency } = useContextCurrency();
  const { axiosInstance } = useContextUtil();
  const { userId } = useContextProfile();

  if (queryKey) {
    const { queries, shouldLogged } = getParamsQueriesKeys({
      queryKey,
      axiosInstance,
      language,
      currency,
      params,
    });

    return shouldLogged ? [...queries, userId ? userId.toString() : null] : queries;
  }
  return [];
}
