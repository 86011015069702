import { createContext } from 'react';

import type { AuthHook, AuthState, HookStore } from '@core/type/context';

import { useFnContext, useStoreContext } from '../util';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const authContext = createContext<HookStore<any, AuthState, AuthHook>>(null);

export const useContextAuth = () => {
  return useFnContext(authContext);
};

export function useStoreAuth<Selector>(selector: keyof AuthState) {
  return useStoreContext<Selector, AuthState, AuthHook>(selector, authContext);
}
