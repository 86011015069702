import Image from 'next/image';

import IconEyeClosedSvg from './svgs/icon-eye-closed.svg';

export const IconEyeClosed = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconEyeClosedSvg}
    alt="Eye closed icon"
    width={24}
    height={24}
  />
);
