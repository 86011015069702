/* eslint-disable jsx-a11y/anchor-is-valid */
import withTranslation from 'next-translate/withTranslation';
import { memo, useEffect } from 'react';
import { useTheme } from 'styled-components';

import { Translate } from '@core/constant';
import { useContextAuth, useContextProfile, useContextSelect, useContextUser } from '@core/context';
import { useScrollDirection } from '@core/hook';
import { EColor, EFontWeight, EPosition, ERouting, ThemeProps, WithTranslation } from '@core/type';

import { DropdownLine } from '../dropdown/DropdownLine';
import { ErrorDot } from '../error-dot';
import { Box } from '../layout/Box';
import { Line } from '../line/Line';
import { Link } from '../link/Link';
import { SelectBase } from './SelectBase';
import { SelectBaseContent } from './SelectBaseContent';
import { SelectBaseDropdown } from './SelectBaseDropdown';
import { SelectBaseContentProps, SelectBaseDropdownProps } from './interface-select';

const SelectUserMenuContent = () => {
  const { isOpen, setIsOpen } = useContextSelect();
  const { isDown, isUp } = useScrollDirection(0);
  const { fullName } = useContextUser();

  const {
    typography: {
      header: {
        option: { fontSize },
      },
    },
  } = useTheme() as ThemeProps;

  useEffect(() => {
    if (isDown || isUp) {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDown, isUp]);

  return (
    <SelectBaseContent>
      <Box
        display="flex"
        alignItems="center"
        paddingRight={'5px'}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Link color={EColor.WHITE} fontWeight={EFontWeight.BOLD} fontSize={fontSize}>
          {fullName}
        </Link>
      </Box>
    </SelectBaseContent>
  );
};

const SelectUserMenuDropdown = ({ children, ...restProps }: SelectBaseDropdownProps) => (
  <SelectBaseDropdown maxHeight={'max-content'} {...restProps}>
    {children}
  </SelectBaseDropdown>
);

const Content = (props: SelectBaseContentProps) => <SelectUserMenuContent {...props} />;

const SelectUserMenuContainer = ({ i18n: { t } }: WithTranslation) => {
  const { logout } = useContextAuth();
  const { hasNotCompletedStep, hasStorageFeesUnpaid } = useContextProfile();

  return (
    <>
      {(hasNotCompletedStep || hasStorageFeesUnpaid) && (
        <Box>
          <ErrorDot />
        </Box>
      )}
      <SelectBase
        Content={Content}
        Dropdown={SelectUserMenuDropdown}
        isNavSelect
        verticalPosition={EPosition.BOTTOM}
        horizontalPosition={EPosition.RIGHT}
        iconProps={{
          color: { commonType: EColor.WHITE, intensity: EColor.R900 },
          marginBottom: 2,
        }}
        verticalPositionProps={{
          [EPosition.TOP]: {
            top: 'unset',
            bottom: '24px',
          },
          [EPosition.BOTTOM]: {
            top: '22px',
            bottom: 'unset',
          },
        }}
        options={[
          { label: <DropdownLine title={t('menu.wallet')} route={ERouting.WALLET} /> },
          {
            label: (
              <DropdownLine title={t('menu.autoSavings')} route={ERouting.WALLET_AUTO_SAVINGS} />
            ),
          },
          {
            label: (
              <DropdownLine
                title={t('menu.storageFees')}
                route={ERouting.STORAGE_FEES}
                isInvalid={hasStorageFeesUnpaid}
              />
            ),
          },
          { label: <DropdownLine title={t('menu.paymentMethods')} route={ERouting.PAYMENTS} /> },
          { label: <DropdownLine title={t('menu.orderHistory')} route={ERouting.WALLET_ORDERS} /> },
          {
            label: (
              <DropdownLine
                title={t('menu.transactionHistory')}
                route={ERouting.ACCOUNT_TRANSACTIONS}
              />
            ),
          },
          {
            label: (
              <DropdownLine title={t('menu.alertsSettings')} route={ERouting.ALERT_SETTINGS} />
            ),
          },
          { label: <DropdownLine title={t('menu.wishList')} route={ERouting.WISHLIST} /> },
          {
            label: (
              <DropdownLine
                title={t('menu.purchasingLimit')}
                route={ERouting.PURCHASING_LIMIT}
                isInvalid={hasNotCompletedStep}
              />
            ),
          },
          { label: <DropdownLine title={t('menu.profileSettings')} route={ERouting.PROFILE} /> },
          {
            label: (
              <Line
                color={{ commonType: EColor.GRAY, intensity: EColor.A200 }}
                width="80%"
                margin="8px auto"
              />
            ),
          },
          { label: <DropdownLine title={t('menu.referFriend')} route={ERouting.REFERRALS} /> },
          {
            label: (
              <Line
                color={{ commonType: EColor.GRAY, intensity: EColor.A200 }}
                width="80%"
                margin="8px auto"
              />
            ),
          },
          { label: <DropdownLine title={t('menu.signOut')} onClick={() => void logout()} /> },
        ]}
      />
    </>
  );
};

export const SelectUserMenu = memo(
  withTranslation(SelectUserMenuContainer, Translate.layout.HEADER),
);
