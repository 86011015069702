import { LinkProps } from 'next/link';

import { useContextRouting, useContextUtil } from '@core/context';
import { useLanguage } from '@core/hook';
import { ELanguage, ERouting } from '@core/type';
import { getLanguageFromTags, getLanguageTags, getLinkNext } from '@core/util';

import { NextLinkStyledAsButton } from './NextLinkStyledAsButton';
import { LinkTranslatedStyledAsButtonProps } from './interface-link-translated';

export const LinkTranslatedStyledAsButton = ({
  keyRoute,
  children,
  language,
  disableScroll,
  disableShallow,
  query = null,
  href: hrefProp,
  locale,
  ...restProps
}: LinkTranslatedStyledAsButtonProps) => {
  const { pathTranslations, defaultRoute } = useContextRouting();
  const { isBotDetected } = useContextUtil();
  const { locale: currentLanguage } = useLanguage();

  if (!keyRoute && !hrefProp) {
    return <>{children}</>;
  }

  const languageLink = language ? getLanguageFromTags(language) : currentLanguage;

  let link: LinkProps = getLinkNext(
    keyRoute,
    getLanguageTags(languageLink),
    pathTranslations,
    defaultRoute,
    query,
  );

  if (isBotDetected) {
    link = {
      ...link,
      prefetch: false,
    };
  }

  const { as: forwardedAs, href, ...restLink } = link;

  const languageLinkByRoute =
    keyRoute === ERouting.HOME &&
    (languageLink === ELanguage.EN || languageLink === ELanguage.DEFAULT)
      ? ELanguage.DEFAULT
      : languageLink === ELanguage.DEFAULT
        ? ELanguage.EN
        : languageLink;

  return (
    <NextLinkStyledAsButton
      {...restLink}
      href={hrefProp || (href as string)}
      forwardedAs={forwardedAs}
      scroll={!disableScroll}
      shallow={!disableShallow}
      locale={locale ?? languageLinkByRoute}
      {...restProps}
    >
      {children}
    </NextLinkStyledAsButton>
  );
};
