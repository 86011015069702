import { DefaultRoute, PathTranslationsType } from '@core/type';

import DefaultR from './default-route';
import PathT from './rewrite';

export const pathTranslations: PathTranslationsType = PathT as PathTranslationsType;
export const defaultRoute: DefaultRoute = DefaultR as DefaultRoute;

export * from './api';
export * from './content-security-policy';
export * from './email';
export * from './meta';
export * from './theme';
export * from './translation-file';
