import { SVG } from '../SVG';
import { DynamicIconProps } from '../interface-icon';

export const IconTwitter = ({ fill }: DynamicIconProps) => (
  <SVG height={25} width={25} viewBox="0 0 25 25" title="Twitter icon" fill="none">
    <path
      d="M7.032 6.007c-.006.008.42.587.941 1.284l2.466 3.292 1.517 2.024-.097.11-2.477 2.672A286 286 0 0 0 7 17.976c-.005.014.223.024.551.021l.559-.002.183-.202A3085 3085 0 0 0 12.4 13.36l.066-.076.524.702 1.766 2.36L15.998 18H17.9c1.582 0 1.9-.005 1.889-.034-.008-.021-.49-.67-1.073-1.448l-1.48-1.975c-.233-.31-.918-1.228-1.526-2.038a74 74 0 0 1-1.107-1.498c0-.018 3.216-3.504 4.31-4.67l.312-.332h-.575l-.571.002-.67.723a583 583 0 0 1-2.001 2.16l-1.33 1.436-1.613-2.153-1.616-2.155-1.902-.013a60 60 0 0 0-1.915.002m4.507 2.32c.621.834 1.581 2.114 2.132 2.85l2.718 3.628 1.74 2.326c.015.023-.171.03-.85.03h-.869l-1.72-2.299a2832 2832 0 0 1-6-8.014c-.016-.023.162-.031.847-.031h.868z"
      fill={fill}
    />
  </SVG>
);
