import { Dispatch, FC, memo, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { Keyframes, useTheme } from 'styled-components';

import { Box, Container, getSlideDownAnimation, getSlideUpAnimation, Icon } from '@core/component';
import { animation as animationConstant, util } from '@core/constant';
import {
  useContextCart,
  useContextCurrency,
  useContextHeaderHeight,
  useContextMediaQuery,
  useContextRouting,
  useContextUser,
} from '@core/context';
import { useLanguage, useScrollDirection } from '@core/hook';
import {
  BackgroundColorType,
  ColorProps,
  EActionAttribute,
  EColor,
  EIcon,
  ELayout,
  EPosition,
  ERouting,
  ESize,
  EZIndexName,
  ThemeProps,
} from '@core/type';
import { BlogCategory, ShopSubcategoriesResponse } from '@core/type/api';

import { FullLogoLink } from './FullLogoLink/FullLogoLink';
import { HeaderBlog } from './HeaderBlog';
import { HeaderCart } from './HeaderCart';
import { HeaderDefault } from './HeaderDefault';
import { HeaderLanding } from './HeaderLanding';
import { HeaderMenuBlog } from './HeaderMenuBlog';
import { HeaderOption } from './HeaderOption';
import { HeaderPrice } from './HeaderPrice';
import { HeaderShort } from './HeaderShort';
import { SideNav } from './SideNav';

export const HeaderContainer: FC<{
  type: ELayout;
  isLight?: boolean;
  isTransform?: boolean;
  setIsMounted?: Dispatch<SetStateAction<boolean>>;
  shouldShowBanner?: boolean;
  shouldShowCurrencyLink?: boolean;
  blogCategories: BlogCategory[];
  subcategories?: ShopSubcategoriesResponse;
}> = ({
  type,
  isLight,
  isTransform,
  setIsMounted,
  shouldShowCurrencyLink,
  blogCategories,
  subcategories,
}) => {
  const headerRef = useRef(null);
  const [animation, setAnimation] = useState<Keyframes>();
  const { height, setHeight } = useContextHeaderHeight();
  const [heightAnimation, setHeightAnimation] = useState<string>(height);
  const { mediaQuery, isMobile } = useContextMediaQuery();
  const { isDown, isUp } = useScrollDirection(util.DEBOUNCE);
  const { isLogged, isReady } = useContextUser();
  const { initializeCart } = useContextCart();
  const { keyRoute } = useContextRouting();
  const { language } = useLanguage();
  const { currency } = useContextCurrency();

  const {
    structure: {
      header: {
        blog: { height: heightBlog, animation: animationBlog, padding: blogPadding },
        short: { height: heightShort, padding: paddingShort, animation: animationShort },
        signUp: { height: heightSignUp, padding: paddingSignUp },
        maxWidth,
        height: heightDefault,
        animation: animationMedia,
        padding: paddingDefault,
        logoHeight,
      },
    },
  } = useTheme() as ThemeProps;

  const isLandingLayout = type === ELayout.LANDING;
  const isBlogLayout = type === ELayout.BLOG;
  // TODO[GARB-4422]: Review this condition after sign-in header redesign
  const isOnboardingLayout = [
    ERouting.SIGN_UP,
    ERouting.DONE_SIGN_UP,
    ERouting.KYC_DATE_OF_BIRTH,
    ERouting.KYC_PHONE_NUMBER,
    ERouting.KYC_NATIONALITIES,
    ERouting.KYC_COUNTRIES_OF_RESIDENCE,
    ERouting.KYC_EXPOSED_PERSON,
    ERouting.KYC_OCCUPATION,
    ERouting.KYC_BUSINESS_SECTOR,
    ERouting.KYC_ANNUAL_INCOME,
    ERouting.KYC_FORECASTED_ANNUAL_INVESTMENTS,
    ERouting.KYC_SOURCES_OF_FUNDS,
    ERouting.KYC_LOCATION,
    ERouting.KYC_PHOTO_IDENTIFICATION,
    ERouting.KYC_DONE,
  ].includes(keyRoute);

  const isShortLayout = type === ELayout.SHORT || isLandingLayout;
  const animationType = isShortLayout ? animationShort : animationMedia;

  const heightAnimationTransform = isBlogLayout ? animationBlog : animationType;
  const headerPadding = isOnboardingLayout
    ? paddingSignUp
    : isShortLayout
      ? paddingShort
      : isBlogLayout
        ? blogPadding
        : paddingDefault;

  const isShort =
    isShortLayout ||
    (type === ELayout.DEFAULT &&
      height === `${parseInt(heightDefault) - parseInt(animationMedia)}px`) ||
    (isBlogLayout && height === `${parseInt(heightBlog) - parseInt(animationBlog)}px`);

  const headers = useMemo(
    () => ({
      [ELayout.BLOG]: <HeaderBlog />,
      [ELayout.DEFAULT]: <HeaderDefault subcategories={subcategories} />,
      [ELayout.SHORT]: <HeaderShort isLight={isLight} />,
      [ELayout.LANDING]: <HeaderLanding />,
    }),
    [isLight, subcategories],
  );

  const headerBackgroundColor: ColorProps<BackgroundColorType> = isLight
    ? { semanticType: EColor.NEUTRAL, variant: EColor.LIGHT }
    : { semanticType: EColor.PRIMARY, variant: EColor.DARK };

  const triggerSlideAnimation = (heightEnd: string, animation: Keyframes) => {
    setAnimation(animation);
    setHeight(heightEnd);
    setHeightAnimation(heightEnd);
    if (isShortLayout) {
      setTimeout(() => {
        setAnimation(null);
      }, animationConstant.TIMEOUT_SLIDE_ANIMATION_HEADER);
    }
  };

  const unmountElement = () => {
    if (setIsMounted) {
      setIsMounted(true);
    }

    if (isOnboardingLayout) {
      setHeightAnimation(heightSignUp);
      setHeight(heightSignUp);
      return;
    }

    switch (type) {
      case ELayout.BLOG:
        setHeightAnimation(heightBlog);
        setHeight(heightBlog);
        break;
      case ELayout.SHORT:
      case ELayout.LANDING:
        setHeight(heightShort);
        setHeightAnimation(heightShort);
        break;
      case ELayout.DEFAULT:
        setHeightAnimation(heightDefault);
        setHeight(heightDefault);
        break;
    }
  };

  const triggerShortAnimation = (canTransform: boolean) => {
    if (isOnboardingLayout) {
      triggerSlideAnimation(heightSignUp, getSlideUpAnimation(animationShort));
      return;
    }

    if (canTransform) {
      switch (type) {
        case ELayout.BLOG:
          triggerSlideAnimation(heightBlog, getSlideDownAnimation(animationMedia));
          break;
        case ELayout.SHORT:
        case ELayout.LANDING:
          triggerSlideAnimation(heightShort, getSlideUpAnimation(animationShort));
          break;
        case ELayout.DEFAULT:
          triggerSlideAnimation(heightDefault, getSlideDownAnimation(animationShort));
          break;
      }
    } else {
      unmountElement();
    }
  };

  useEffect(() => {
    triggerShortAnimation(isTransform);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, isOnboardingLayout, isBlogLayout, mediaQuery]);

  const isLogoCentered = type === ELayout.DEFAULT && ESize.XL !== mediaQuery;

  useEffect(() => {
    if (isDown) {
      switch (type) {
        case ELayout.BLOG:
          setAnimation(getSlideUpAnimation(animationBlog));
          setHeight(`${parseInt(heightBlog) - parseInt(animationBlog)}px`);
          break;
        case ELayout.DEFAULT:
          setAnimation(getSlideUpAnimation(animationMedia));
          setHeight(`${parseInt(heightDefault) - parseInt(animationMedia)}px`);
          break;
      }
    } else if (isUp) {
      switch (type) {
        case ELayout.BLOG:
          setAnimation(getSlideDownAnimation(animationBlog));
          setHeight(heightBlog);
          break;
        case ELayout.DEFAULT:
          setAnimation(getSlideDownAnimation(animationMedia));
          setHeight(heightDefault);
          break;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDown, isUp]);

  useEffect(() => {
    initializeCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, isReady, language, currency]);

  return (
    <Box as={EActionAttribute.HEADER} display={'block'} height={heightAnimation} ref={headerRef}>
      <Box
        position={isLandingLayout || (isShortLayout && isMobile) ? 'static' : 'fixed'}
        top={'0'}
        width={'100%'}
        height={height}
        zIndex={EZIndexName.HEADER}
      >
        <Box
          animation={animation}
          animationDuration={'0.7s'}
          transition={'background-color 700ms linear'}
          transform={
            (isTransform || isShortLayout) && !isMobile
              ? `translateY(-${heightAnimationTransform})`
              : ''
          }
          position={'relative'}
          zIndex={EZIndexName.OVER_DEFAULT}
        >
          <Box
            backgroundColor={
              isLight
                ? { semanticType: EColor.NEUTRAL, variant: EColor.LIGHT }
                : { commonType: EColor.BLUE, intensity: EColor.R900 }
            }
            width={'100%'}
          >
            <Container maxWidth={maxWidth}>
              {!isMobile && type !== ELayout.BLOG && <HeaderPrice />}
            </Container>
          </Box>
          <Box
            backgroundColor={headerBackgroundColor}
            width={'100%'}
            position={'relative'}
            zIndex={EZIndexName.HEADER}
          >
            <Container maxWidth={maxWidth}>
              {!isMobile && type !== ELayout.BLOG && (
                <HeaderOption
                  isVisible={!isShort}
                  shouldShowCurrencyLink={shouldShowCurrencyLink}
                />
              )}
            </Container>
          </Box>
          <Box
            backgroundColor={headerBackgroundColor}
            padding={headerPadding}
            width={'100%'}
            position={'relative'}
          >
            <Container maxWidth={maxWidth}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                flexDirection={isLogoCentered ? 'column' : 'row'}
                gap={'10px'}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={isShortLayout && isMobile ? 'flex-start' : 'space-between'}
                  width={isMobile ? '100%' : undefined}
                >
                  {isMobile && type === ELayout.DEFAULT && (
                    <SideNav
                      shouldShowCurrencyLink={shouldShowCurrencyLink}
                      subcategories={subcategories}
                    />
                  )}

                  {isLandingLayout ? (
                    <Icon
                      width={'184px'}
                      height={logoHeight}
                      color={EColor.WARNING}
                      type={EIcon.GA_LOGO_FULL}
                    />
                  ) : (
                    <FullLogoLink
                      height={logoHeight}
                      iconType={isLight ? EIcon.GA_LOGO_FULL_COLOR_FILLED : EIcon.GA_LOGO_FULL}
                    />
                  )}

                  {isMobile && type === ELayout.DEFAULT && <HeaderCart />}
                </Box>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  gap="15px"
                  justifyContent={isShortLayout && isMobile ? 'flex-end' : 'space-between'}
                  width={isMobile ? '100%' : undefined}
                >
                  {headers[type]}
                </Box>

                {isMobile && isBlogLayout && (
                  <SideNav
                    shouldShowCurrencyLink={shouldShowCurrencyLink}
                    position={EPosition.RIGHT}
                    iconOrientation={EPosition.TOP}
                    iconWidth={'min-content'}
                    iconColor={EColor.PRIMARY}
                    isBlogPage
                  />
                )}
              </Box>
            </Container>
          </Box>
        </Box>
        <Box
          backgroundColor={headerBackgroundColor}
          width={'100%'}
          animation={animation}
          transform={isTransform && !isMobile ? `translateY(-${heightAnimationTransform})` : null}
          animationDuration={'0.7s'}
          transition={'background-color 700ms linear'}
          position={'relative'}
          borderColor={
            isBlogLayout ? { commonType: EColor.GRAY, intensity: EColor.A200 } : undefined
          }
          borderWidth={isBlogLayout ? (isMobile ? '0 0 1px 0' : '1px 0 1px 0') : undefined}
          borderStyle={'solid'}
        >
          <Container maxWidth={maxWidth}>
            {isBlogLayout && <HeaderMenuBlog blogCategories={blogCategories} />}
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export const Header = memo(HeaderContainer);
