import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconLock = ({ fill }: DynamicIconProps) => (
  <SVG height={24} width={24} viewBox="0 0 24 24" title="Lock icon">
    <path
      d="M17.267 10.455H16.2V8.302c0-1.141-.45-2.236-1.25-3.043A4.25 4.25 0 0 0 11.933 4a4.25 4.25 0 0 0-3.017 1.26 4.32 4.32 0 0 0-1.25 3.043v2.151H6.6c-.424.001-.83.171-1.13.474S5 11.64 5 12.068v6.455c0 .428.17.838.47 1.14.3.303.706.473 1.13.473h10.667c.424 0 .83-.17 1.13-.473s.47-.712.47-1.14v-6.455c0-.428-.17-.838-.47-1.14a1.6 1.6 0 0 0-1.13-.474M8.733 8.302c0-.856.337-1.677.938-2.282a3.2 3.2 0 0 1 2.262-.945c.849 0 1.663.34 2.263.945s.937 1.426.937 2.282v2.151h-6.4zm9.067 10.22a.54.54 0 0 1-.156.38.54.54 0 0 1-.377.158H6.6a.53.53 0 0 1-.533-.538v-6.455a.54.54 0 0 1 .156-.38c.1-.1.236-.158.377-.158h10.667a.54.54 0 0 1 .533.538zm-5.333-4.303v2.151a.54.54 0 0 1-.157.38.531.531 0 0 1-.91-.38v-2.15a.531.531 0 1 1 1.067 0"
      fill={fill}
    />
  </SVG>
);
