import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconBin = ({ fill }: DynamicIconProps) => {
  return (
    <SVG height={16} width={16} viewBox="0 0 16 16" title="Bin icon">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.722 1.911h3.011a.6.6 0 1 1 0 1.2H3.267a.6.6 0 0 1 0-1.2h3.011a.89.89 0 0 1 .833-.577H8.89a.89.89 0 0 1 .833.577m-5.866 2.32a.6.6 0 0 0-1.193.132l.856 7.702a2.6 2.6 0 0 0 2.584 2.313h3.794a2.6 2.6 0 0 0 2.584-2.313l.856-7.702a.6.6 0 1 0-1.193-.133l-.855 7.703a1.4 1.4 0 0 1-1.392 1.245H6.103a1.4 1.4 0 0 1-1.392-1.245zm6.313.06a.6.6 0 0 1 .563.634l-.355 6a.6.6 0 0 1-1.198-.071l.355-6a.6.6 0 0 1 .635-.564m-1.57.598a.6.6 0 0 0-1.2 0v6a.6.6 0 1 0 1.2 0zM5.831 4.29a.6.6 0 0 0-.563.635l.355 6a.6.6 0 0 0 1.198-.071l-.355-6a.6.6 0 0 0-.635-.564"
        fill={fill}
      />
    </SVG>
  );
};
