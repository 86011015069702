import { createContext } from 'react';

import type {
  HookStore,
  RedirectAfterSignInRouteHook,
  RedirectAfterSignInRouteState,
} from '@core/type/context';

import { useFnContext, useStoreContext } from '../util';

export const redirectAfterSignInRouteContext =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createContext<HookStore<any, RedirectAfterSignInRouteState, RedirectAfterSignInRouteHook>>(null);

export const useContextRedirectAfterSignInRoute = () => {
  return useFnContext(redirectAfterSignInRouteContext);
};

export function useStoreRedirectAfterSignInRoute<Selector>(
  selector: keyof RedirectAfterSignInRouteState,
) {
  return useStoreContext<Selector, RedirectAfterSignInRouteState, RedirectAfterSignInRouteHook>(
    selector,
    redirectAfterSignInRouteContext,
  );
}
