import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import { TeamMembersResponse } from '@core/type/api';

import { Api } from '../constants/api';
import { configResponse, EConfigName } from '../helpers/config';

const getTeamMembers = (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<TeamMembersResponse>> => {
  return axiosInstance.get(
    Api.teamMembers.TEAM_MEMBERS,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class TeamMembersAPI {
  public static readonly getTeamMembers = getTeamMembers;
}
