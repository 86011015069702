import Image from 'next/image';

import IconFlexibleChoiceSvg from './svgs/icon-flexible-choice.svg';

export const IconFlexibleChoice = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconFlexibleChoiceSvg}
    alt="flexible choice icon"
    width={80}
    height={80}
  />
);
