import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type {
  FAQArticleEntity,
  FAQCategoriesResponse,
  FAQCategoryEntity,
  FAQSearchEntity,
} from '@core/type/api';
import { EFAQCategory, FAQSearchAutocompleteEntity } from '@core/type/api';

import { FAQApi } from '../api/faq';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
): Promise<R> => {
  return logService('getFAQCategory', methodName, dto, apiCall);
};

const getFAQArticle = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  slug: string,
): Promise<FAQArticleEntity> => {
  return log('getFAQArticle', { axiosInstance, language, slug }, FAQApi.getFAQArticle);
};

const getFAQCategory = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  slug: EFAQCategory,
): Promise<FAQCategoryEntity> => {
  return log('getFAQCategory', { axiosInstance, language, slug }, FAQApi.getFAQCategory);
};

const getFAQCategories = (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<FAQCategoriesResponse> => {
  return log('getFAQCategories', { axiosInstance, language }, FAQApi.getFAQCategories);
};

const searchFAQAutocomplete = (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  q?: string,
): Promise<FAQSearchAutocompleteEntity> => {
  return log('searchFAQAutocomplete', { axiosInstance, q, language }, FAQApi.faqSearchAutocomplete);
};

const searchFAQArticles = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  q: string,
  page?: number,
  perPage?: number,
): Promise<FAQSearchEntity> => {
  return log('searchFAQArticles', { axiosInstance, language, page, perPage, q }, FAQApi.faqSearch);
};

export class FAQService {
  public static readonly getFAQArticle = getFAQArticle;
  public static readonly getFAQCategory = getFAQCategory;
  public static readonly getFAQCategories = getFAQCategories;
  public static readonly searchFAQArticles = searchFAQArticles;
  public static readonly searchFAQAutocomplete = searchFAQAutocomplete;
}
