// This file is created cause next.config.js work only with JS file
// It is the same than { ELanguage } of @core/type
const ELanguage = Object.freeze({
  EN: 'en',
  FR: 'fr',
  DE: 'de',
  IT: 'it',
  DEFAULT: 'default',
});

const i18nSupportedLanguage = [
  ELanguage.EN,
  ELanguage.FR,
  ELanguage.DE,
  ELanguage.IT,
  ELanguage.DEFAULT,
];

const ELanguageTags = Object.freeze({
  EN_US: 'en-US',
  FR_FR: 'fr-FR',
  DE_DE: 'de-DE',
  IT_IT: 'it-IT',
  DEFAULT: 'default',
});

const i18nSupportedLanguageTags = [
  ELanguageTags.EN_US,
  ELanguageTags.FR_FR,
  ELanguageTags.DE_DE,
  ELanguageTags.IT_IT,
  ELanguageTags.DEFAULT,
];

const I18N_DEFAULT = ELanguage.DEFAULT;

const getLanguageTags = (language) => {
  switch (language) {
    case ELanguage.EN:
      return ELanguageTags.EN_US;
    case ELanguage.FR:
      return ELanguageTags.FR_FR;
    case ELanguage.DE:
      return ELanguageTags.DE_DE;
    case ELanguage.IT:
      return ELanguageTags.IT_IT;
    default:
      return ELanguageTags.DEFAULT;
  }
};

module.exports = Object.freeze({
  i18nSupportedLanguage: i18nSupportedLanguage,
  i18nSupportedLanguageTags: i18nSupportedLanguageTags,
  I18N_DEFAULT: I18N_DEFAULT,
  ELanguage: ELanguage,
  ELanguageTags: ELanguageTags,
  getLanguageTags: getLanguageTags,
});
