import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';

import { Box, ButtonIcon, Typography } from '@core/component';
import { HEADER_CONTAINER_WIDTH, Translate } from '@core/constant';
import { useMediaQuery } from '@core/provider';
import { EColor, EFontWeight, EIcon, ESize, ETypographyVariant } from '@core/type';

import { ConnectedBannerProps } from './interface-connected-banner';

export const ConnectedBanner: FC<ConnectedBannerProps> = ({
  color,
  subtitle,
  onShowMore,
  onClose,
}) => {
  const { isPhone, isMobile } = useMediaQuery();

  const { t } = useTranslation(Translate.common.WIDGET);

  return (
    <Box
      role={'alert'}
      height={'32px'}
      backgroundColor={color}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      padding={'0 20px'}
    >
      <Box
        maxWidth={isMobile ? 'calc(100vw - 40px)' : `${HEADER_CONTAINER_WIDTH}px`}
        position={'relative'}
      >
        <Box
          role={'button'}
          onClick={onShowMore}
          cursor={'pointer'}
          color={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
          display={'flex'}
          justifyContent={'center'}
          fontWeight={EFontWeight.BOLD}
          padding={isPhone ? '0 28px 0 0' : isMobile ? '0 40px' : '0 90px'}
          maxWidth={'calc(100vw - 40px)'}
        >
          <Typography
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            variant={ETypographyVariant.BODY3}
          >
            {subtitle}
          </Typography>
          <Typography
            textDecoration={'underline'}
            flexShrink={'0'}
            variant={ETypographyVariant.BODY3}
          >
            {t('connectedBanner.seeMore')}
          </Typography>
        </Box>
        <ButtonIcon
          onClick={onClose}
          position={'absolute'}
          right={'-4px'}
          top={'4px'}
          iconProps={{
            type: EIcon.CROSS,
            flexShrink: 0,
            size: ESize.XS,
            color: { commonType: EColor.WHITE, intensity: EColor.R900 },
          }}
        />
      </Box>
    </Box>
  );
};
