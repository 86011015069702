import Image from 'next/image';

import IconBankTransferSvg from '../svgs/icon-bank-transfer.svg';

export const IconBankTransfer = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconBankTransferSvg}
    alt="Bank Transfer icon"
    height={20}
    width={113}
  />
);
