import { ESizeIcon, EStrokeWidth } from '@core/type';
import { getTranslationWithOrientation } from '@core/util';

import { SVG } from './SVG';
import { DynamicIconProps, IconOrientationProps } from './interface-icon';

export const IconAngle = ({ orientation, yTranslation, ...restProps }: IconOrientationProps) => {
  return (
    <IconAngleSVG
      transform={getTranslationWithOrientation(orientation, yTranslation)}
      {...restProps}
    />
  );
};

const IconAngleSVG = ({
  stroke,
  transform,
  size = ESizeIcon.DEFAULT,
  strokeWidth = EStrokeWidth.ICON_DEFAULT,
}: DynamicIconProps) => (
  <SVG height={size} width={size} viewBox="0 0 24 24" title="Angle icon" transform={transform}>
    <path
      strokeLinejoin="round"
      strokeLinecap="round"
      d="M17.9671 8.98366L11.9834 14.9673L5.99974 8.98366"
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
  </SVG>
);
