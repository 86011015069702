import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconCartPurchasingLimit = ({ fill }: DynamicIconProps) => (
  <SVG width="18" height="18" viewBox="0 0 18 18" title="Purchasing limit icon" fill="none">
    <g clipPath="url(#clip0_1132_19414)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0913515 0.207963C0.225174 0.0100079 0.494132 -0.0419823 0.692088 0.0918398L2.00065 0.976454C2.08682 1.03471 2.14912 1.12206 2.17614 1.22251L4.70701 10.6326H4.8115C4.81774 10.6326 4.82395 10.6327 4.83013 10.633H15.3774L16.1686 7.42362C16.2258 7.19162 16.4602 7.04992 16.6922 7.10711C16.9242 7.16431 17.0659 7.39875 17.0087 7.63075L16.1364 11.1692C16.0887 11.3625 15.9153 11.4983 15.7163 11.4983H4.37546C4.36925 11.4983 4.36307 11.4982 4.35692 11.4979H3.96361C3.22544 11.4979 2.62703 12.0963 2.62703 12.8345C2.62703 13.5726 3.22544 14.1711 3.96362 14.1711H4.17239C4.46789 13.1433 5.40385 12.3924 6.51276 12.3924C7.62173 12.3924 8.55772 13.1434 8.85318 14.1712H11.5874C11.8828 13.1434 12.8188 12.3924 13.9278 12.3924C15.274 12.3924 16.3653 13.4991 16.3653 14.8642C16.3653 16.2293 15.274 17.3359 13.9278 17.3359C12.6387 17.3359 11.5833 16.3212 11.4961 15.0365H8.94445C8.85721 16.3212 7.80184 17.3359 6.51276 17.3359C5.22364 17.3359 4.16825 16.3211 4.08107 15.0364H3.96362C2.74755 15.0364 1.76174 14.0505 1.76174 12.8345C1.76174 11.6693 2.66684 10.7154 3.81234 10.6377L1.38243 1.60299L0.207475 0.808699C0.00951959 0.674877 -0.0424706 0.405918 0.0913515 0.207963ZM13.9278 16.4706C14.7848 16.4706 15.5 15.7628 15.5 14.8642C15.5 13.9656 14.7848 13.2577 13.9278 13.2577C13.0708 13.2577 12.3556 13.9656 12.3556 14.8642C12.3556 15.7628 13.0708 16.4706 13.9278 16.4706ZM8.08499 14.8642C8.08499 15.7628 7.36975 16.4706 6.51276 16.4706C5.65578 16.4706 4.94055 15.7628 4.94055 14.8642C4.94055 13.9656 5.65578 13.2577 6.51276 13.2577C7.36975 13.2577 8.08499 13.9656 8.08499 14.8642Z"
        fill={fill}
      />
      <path
        d="M7.68472 4.7998H11.6446L12.9646 8.99971H6.36475L7.68472 4.7998Z"
        stroke={fill}
        strokeWidth="0.87"
        strokeLinejoin="round"
      />
      <line
        x1="9.52936"
        y1="1.3648"
        x2="9.52936"
        y2="0.434845"
        stroke={fill}
        strokeWidth="0.87"
        strokeLinecap="round"
      />
      <line
        x1="13.3369"
        y1="2.30659"
        x2="12.7521"
        y2="2.89138"
        stroke={fill}
        strokeWidth="0.87"
        strokeLinecap="round"
      />
      <line
        x1="6.58501"
        y1="2.8916"
        x2="6.00022"
        y2="2.30681"
        stroke={fill}
        strokeWidth="0.87"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1132_19414">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </SVG>
);
