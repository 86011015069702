import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconTag = ({ fill }: DynamicIconProps) => (
  <SVG height={24} width={24} viewBox="0 0 24 24" title="Search icon">
    <path
      d="M14.575 4.194a3 3 0 0 0-2.094.879l-5.906 5.906a3 3 0 0 0 0 4.243l2.662 2.661a3 3 0 0 0 4.242 0l5.906-5.906a3 3 0 0 0 .879-2.094l.05-5.42a.315.315 0 0 0-.318-.318zm-.79 6.294a1.583 1.583 0 1 0 2.238-2.24 1.583 1.583 0 0 0-2.239 2.24"
      fill="#F5FAFF"
    />
    <mask id="a" fill="#fff">
      <path d="M20.844 10.322a3 3 0 0 1-.878 2.195l-6.673 6.672a3 3 0 0 1-4.243 0l-4.714-4.714a3 3 0 0 1 0-4.243L11.01 3.56a3 3 0 0 1 2.194-.878l5.573.136a1.98 1.98 0 0 1 1.932 1.931zm-6.687-.954a1.055 1.055 0 1 1 1.493-1.493 1.055 1.055 0 0 1-1.493 1.493" />
    </mask>
    <path
      d="m20.844 10.322 1-.024zm-7.64-7.64.024-1zm5.572.136.025-1zm1.932 1.931-1 .024zm-1.45 7.06a2 2 0 0 0 .586-1.463l2-.048a4 4 0 0 1-1.171 2.926zm-6.672 6.673 6.673-6.673 1.414 1.415L14 19.896zm-2.829 0a2 2 0 0 0 2.829 0L14 19.896a4 4 0 0 1-5.657 0zm-4.714-4.714 4.714 4.714-1.414 1.414-4.714-4.714zm0-2.828a2 2 0 0 0 0 2.828L3.63 15.182a4 4 0 0 1 0-5.657zm6.673-6.673L5.043 10.94 3.63 9.525l6.673-6.672zm1.463-.585a2 2 0 0 0-1.463.585l-1.414-1.414a4 4 0 0 1 2.926-1.17zm5.573.135-5.573-.135.049-2 5.573.136zm.67.287a.98.98 0 0 0-.67-.287l.049-1.999a2.98 2.98 0 0 1 2.034.872zm.286.67a.98.98 0 0 0-.287-.67l1.414-1.414a2.98 2.98 0 0 1 .872 2.035zm.136 5.572-.136-5.573 2-.048.135 5.573zm-4.98-1.764a.056.056 0 0 0 0 .079l-1.414 1.414a2.055 2.055 0 0 1 0-2.907zm.079 0a.056.056 0 0 0-.079 0L13.45 7.168a2.056 2.056 0 0 1 2.907 0zm0 .079a.056.056 0 0 0 0-.079l1.414-1.414a2.056 2.056 0 0 1 0 2.907zm-.079 0a.056.056 0 0 0 .079 0l1.414 1.414a2.055 2.055 0 0 1-2.907 0z"
      fill={fill}
      mask="url(#a)"
    />
  </SVG>
);
