import type { LogLevelDesc } from 'loglevel';

import { EHttpStatusCode, ELanguageTags, MediaQuerySize } from '@core/type';

export type ContextLogger = {
  enableCapture: boolean;
  captureLevel: LogLevelDesc;
};

export enum ETagLogger {
  LOCALE = 'locale',
  STATUS_CODE = 'statusCode',
  LEVEL = 'level',
  USER_ID = 'userId',
  API_TYPE = 'apiType',
  API_INTERCEPTOR = 'apiInterceptor',
  DEPENDENCY_LIBRARY = 'dependencyLibrary',
  MEDIA_QUERY = 'mediaQuery',
}

export type TagLogger = {
  [ETagLogger.LOCALE]: ELanguageTags;
  [ETagLogger.STATUS_CODE]: EHttpStatusCode;
  [ETagLogger.LEVEL]: LogLevelDesc;
  [ETagLogger.USER_ID]: number;
  [ETagLogger.API_TYPE]: 'error' | 'success';
  [ETagLogger.API_INTERCEPTOR]: 'request' | 'response';
  [ETagLogger.DEPENDENCY_LIBRARY]:
    | 'core-api'
    | 'core-hook'
    | 'core-provider'
    | 'core-context'
    | 'core-storage'
    | 'core-security'
    | 'crisp'
    | 'trustpilot'
    | 'twitter'
    | 'google-tag-manager'
    | 'google-recaptcha'
    | 'mixpanel';

  [ETagLogger.MEDIA_QUERY]: MediaQuerySize;
};
