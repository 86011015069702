import withTranslation from 'next-translate/withTranslation';
import { MouseEventHandler } from 'react';
import { useTheme } from 'styled-components';

import { Translate } from '@core/constant';
import { EColor, EFontWeight, ERouting, ThemeProps, WithTranslation } from '@core/type';

import { LinkTranslatedStyledAsLink } from '../link-translated';
import { LinkProps } from './interface-link';

const LinkLoginContainer = ({ i18n: { t }, onClick }: WithTranslation & LinkProps): JSX.Element => {
  const {
    typography: {
      header: {
        option: { fontSize },
      },
    },
  } = useTheme() as ThemeProps;

  return (
    <LinkTranslatedStyledAsLink
      keyRoute={ERouting.SIGN_IN}
      color={EColor.WHITE}
      fontWeight={EFontWeight.BOLD}
      fontSize={fontSize}
      lineHeight={null}
      onClick={onClick as MouseEventHandler<HTMLAnchorElement>}
    >
      {t('signIn.title')}
    </LinkTranslatedStyledAsLink>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const LinkLogin = withTranslation(LinkLoginContainer, Translate.common.LINK);
