import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { LiveChartsRequest, LiveChartsResponse } from '@core/type/api';
import { InvestmentChartsRequest, InvestmentChartsResponse } from '@core/type/api';

import { LiveChartsAPI } from '../api/live-charts';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('LiveCharts', methodName, dto, apiCall, withContent);
};

const getLiveCharts = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: LiveChartsRequest,
): Promise<LiveChartsResponse> => {
  return log('getLiveCharts', { axiosInstance, language, ...data }, LiveChartsAPI.getLiveCharts);
};

const getInvestmentCharts = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: InvestmentChartsRequest,
): Promise<InvestmentChartsResponse> => {
  return log(
    'getInvestmentCharts',
    { axiosInstance, language, ...data },
    LiveChartsAPI.getInvestmentCharts,
  );
};

export class LiveChartsService {
  public static readonly getLiveCharts = getLiveCharts;
  public static readonly getInvestmentCharts = getInvestmentCharts;
}
