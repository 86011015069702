import {
  Datetime,
  ECountry,
  ECurrency,
  EPaymentType,
  EUnit,
  OrderStatusType,
  Price,
} from '../interface';
import { AddressEntity } from './address';
import { PhoneEntity } from './phone';
import { CartProductEntity } from './product';
import { UnitEntity } from './unit';

export enum EDeliveryType {
  STORAGE = 'storage',
  SHIPPING = 'shipping',
}

export enum EDeliveryPartner {
  UPS = 'ups',
  SWISSPOSTE = 'swissposte',
}

export enum ETransactionType {
  BUY = 'buy',
  SELL = 'sell',
  PREORDER = 'preorder',
}

export enum ECreditCardMethod {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
}

export type ShippingMethodEntity = {
  shippingType: EDeliveryType;
  countryIso?: ECountry;
  insurance?: UnitEntity<EUnit>;
};

export type StorageEntity = {
  isStorage: boolean;
  annualFees: UnitEntity<EUnit.PERCENT>;
};

export type OrderDetailsEntityGeneric<StatusType> = {
  id: number;
  orderableId: number;
  submittedAt: Datetime;
  deliveryType: EDeliveryType;
  deliveryPartner: EDeliveryPartner;
  trackingNumber: string;
  shippingMethod: ShippingMethodEntity;
  transactionType: ETransactionType;
  orderFees: Price;
  preorder: {
    isPreorder: boolean;
    estimatedArrival: {
      arrivalFrom: Datetime;
      arrivalTo: Datetime;
    };
  };
  storage: StorageEntity;
  status: StatusType;
  invoiceUrl: string;
  shippingAddress: ShippingAddressEntity | null;
  billingAddress: BillingAddressEntity | null;
  paymentMethods: PaymentMethodsEntity[];
  productCounts: number;
  products: CartProductEntity[];
  currencyIso: ECurrency;
  productsPrice: Price;
  shippingPrice: Price;
  shippingInsurancePrice: Price;
  totalShippingInsurancePrice: Price;
  priorityPaymentPrice: Price;
  totalPrice: Price;
  totalVat: Price;
  discountCode: DiscountCodeEntity;
  vats: VatEntity;
  updatedAt?: Datetime;
  storageFeesStatus?: EStorageFeeStatus;
  monthlyFeeAmount?: number;
  storageFeeRate?: number;
  totalShippingPrice: Price;
  totalPriorityPaymentPrice: Price;
};

export enum EStorageFeeStatus {
  FREE = 'free',
  FROM_10_TO_100K = 'from_10k_to_100k',
  OVER_100K = 'over_100k',
  OVER_10KG = 'over_10kg',
  EMPTY_WALLET = 'empty_wallet',
  FREE_PERIOD = 'free_period',
  LESS_10K = 'less_10k',
  LESS_10KG = 'less_10kg',
  STANDARD = 'standard',
  EARLY_ADOPTER = 'early_adopter',
  MONTHLY_CUSTOM = 'monthly_custom',
  FREE_CUSTOM = 'free_custom',
}

export type OrderDetailsEntity = OrderDetailsEntityGeneric<OrderStatusType>;

export type ShippingAddressEntity = AddressEntity & {
  firstName: string;
  lastName: string;
};

export type BillingAddressEntity = ShippingAddressEntity & { phone: PhoneEntity };

export type DiscountCodeEntity = {
  code: string;
  description: string;
  amount: Price;
};

export type VatEntity = {
  productVat: Price;
  shippingVat: Price;
  shippingInsuranceVat: Price;
  priorityPaymentPriceVat: Price;
};

export type PaymentMethodsEntity = {
  amount: Price;
  paymentType: EPaymentType;
  creditCardMethod?: ECreditCardMethod;
  maskedNumber?: string;
  creditCardId?: number;
  creditCardAliasId: string;
  isDeleted: boolean;
};

export enum EOrderSteps {
  CART_FORM = 'cart_form',
  CART_SUBMIT = 'cart_submit',
  STORAGE_AND_SHIPPING = 'storage_and_shipping',
  BILLING_ADDRESS = 'billing_address',
  PAYMENT_METHOD = 'payment_method',
  ORDER_REVIEW = 'order_review',
  CART_RECONCILIATION = 'cart_reconciliation',
}
