import { FC, ReactElement, useEffect } from 'react';

import { getPageParamsQueriesKeys } from '@core/api';
import { routeSkeleton, SkeletonHomepage } from '@core/component';
import {
  useContextAlternate,
  useContextBreadcrumb,
  useContextCurrency,
  useContextMeta,
  useContextRouting,
} from '@core/context';
import { useLanguage, useQueryApi } from '@core/hook';
import { ECurrency, ELanguageTags, ERouting, PageSSR } from '@core/type';
import { AlternatesEntity, BreadcrumbEntity, DynamicSEOEntity } from '@core/type/api';
import { getDefaultLanguageSSR, getTypeRoute } from '@core/util';

import { ViewNotFound } from '../view/error/ViewNotFound';
import { useSetCookiesFromRelevantQueryParams } from './use-set-cookies-from-relevant-query-params';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ViewSSR<P = any, Q = NodeJS.Dict<string | string[]>, E = any>({
  keyRoute,
  isLoading,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ViewLoader,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ViewError,
  withEmptyData,
  isError,
  data,
  currency,
  query,
  propsPage,
  language,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ViewPage,
}: {
  keyRoute: ERouting;
  isLoading?: boolean;
  ViewLoader?: ReactElement;
  ViewError?: ReactElement;
  withEmptyData?: boolean;
  isError?: boolean;
  data?: P;
  currency: ECurrency;
  query?: Q;
  propsPage?: E;
  language: ELanguageTags;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ViewPage: FC<PageSSR<any, Q, E>>;
}) {
  if (getPageParamsQueriesKeys(keyRoute)) {
    if (isLoading) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return ViewLoader;
    }

    if (!withEmptyData && (isError || !data)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return ViewError;
    }
  }

  return (
    <ViewPage currency={currency} {...data} {...propsPage} query={query} language={language} />
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useSSRPage<P = any, Q = NodeJS.Dict<string | string[]>, E = any>({
  withEmptyData,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ViewError,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ViewPage,
  propsPage,
  params,
  haveParamsMapper,
  setDynamicsBreadcrumbs,
  setDynamicsAlternates,
}: {
  withEmptyData?: boolean;
  ViewPage: FC<PageSSR<P, Q, E>>;
  ViewError?: ReactElement;
  params?: P;
  propsPage?: E;
  haveParamsMapper?: boolean;
  setDynamicsBreadcrumbs?: (
    props: {
      locale?: Exclude<ELanguageTags, ELanguageTags.DEFAULT>;
      breadcrumbs?: BreadcrumbEntity[];
    } & P,
  ) => BreadcrumbEntity[];
  setDynamicsAlternates?: (
    props: {
      locale?: Exclude<ELanguageTags, ELanguageTags.DEFAULT>;
      alternates?: AlternatesEntity;
    } & P,
  ) => AlternatesEntity;
}): ReactElement {
  const { keyRoute, query } = useContextRouting();
  const { currency } = useContextCurrency();
  const { language } = useLanguage();
  const { setAlternates } = useContextAlternate();
  const { setMeta } = useContextMeta();
  const { setBreadcrumbs } = useContextBreadcrumb();

  const { data, isError, isLoading, isSuccess } = useQueryApi(
    getPageParamsQueriesKeys(keyRoute),
    haveParamsMapper ? params : query,
  );

  const refreshPageHead = () => {
    setAlternates(
      setDynamicsAlternates
        ? setDynamicsAlternates({ locale: getDefaultLanguageSSR(language), ...(query as P) })
        : (data as DynamicSEOEntity)?.alternates,
    );
    setMeta((data as DynamicSEOEntity)?.metas);
    setBreadcrumbs(
      setDynamicsBreadcrumbs
        ? setDynamicsBreadcrumbs({ locale: getDefaultLanguageSSR(language), ...(query as P) })
        : (data as DynamicSEOEntity)?.breadcrumbs,
    );
  };

  useEffect(() => {
    if (!isLoading) {
      refreshPageHead();
    }
    if (!isSuccess && !getTypeRoute(keyRoute).keyRouteDynamic) {
      refreshPageHead();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyRoute, language, query, isSuccess, isLoading]);

  useSetCookiesFromRelevantQueryParams();

  return (
    <ViewSSR
      keyRoute={keyRoute}
      isLoading={isLoading}
      ViewLoader={routeSkeleton[keyRoute] || <SkeletonHomepage />}
      ViewError={ViewError || <ViewNotFound />}
      withEmptyData={withEmptyData}
      isError={isError}
      data={data}
      propsPage={propsPage}
      ViewPage={ViewPage}
      currency={currency}
      query={query as Q}
      language={language}
    />
  );
}
