import { useContextMediaQuery } from '@core/context';
import { ETypographyVariant } from '@core/type';

import { Grid } from '../grid';
import { Box } from '../layout';
import { Line } from '../line';
import { Paper } from '../paper';
import { SkeletonTypography } from './SkeletonTypography';
import { SkeletonWrapper } from './SkeletonWrapper';

export const SkeletonCart = () => {
  const { isPhone, isMobile } = useContextMediaQuery();

  return (
    <SkeletonWrapper overflow={'visible'}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <SkeletonTypography
          variant={ETypographyVariant.H3}
          width={'310px'}
          marginBottom={isPhone ? '30px' : '38px'}
        />

        {!isPhone && (
          <SkeletonTypography lineHeight={'48px'} width={'160px'} borderRadius={'8px'} />
        )}
      </Box>

      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        gap={isPhone ? '0' : '8px'}
        flexDirection={'row'}
      >
        <Paper
          width={'100%'}
          padding={isPhone ? '0' : isMobile ? '24px' : '40px'}
          hasElevation={!isPhone}
        >
          <SkeletonTypography width={'120px'} marginBottom={'32px'} />
          <SkeletonTypography
            lineHeight={isPhone ? '84px' : '144px'}
            marginBottom={isPhone ? '24px' : '48px'}
          />
          {isPhone && (
            <SkeletonTypography lineHeight={isPhone ? '92px' : '144px'} marginBottom={'48px'} />
          )}

          <Grid isContainer columns={12} columnSpacing={30}>
            <Grid xs={12} lg={6}>
              <SkeletonTypography width={'140px'} marginBottom={'32px'} />
              <SkeletonTypography marginBottom={'24px'} width={'240px'} />
              <SkeletonTypography lineHeight={'72px'} width={'240px'} marginBottom={'24px'} />
              <SkeletonTypography lineHeight={'52px'} width={'240px'} />
            </Grid>

            {!isMobile && (
              <Grid xs={12} lg={6}>
                <SkeletonTypography width={'140px'} marginBottom={'32px'} />
                <SkeletonTypography lineHeight={'72px'} width={'240px'} marginBottom={'24px'} />
                <SkeletonTypography lineHeight={'52px'} width={'240px'} />
              </Grid>
            )}
          </Grid>
        </Paper>

        {!isMobile && (
          <Paper minWidth={'330px'} hasElevation padding={'40px'}>
            <SkeletonTypography lineHeight={'80px'} />
            <Line margin={'16px 0'} />
            <SkeletonTypography lineHeight={'80px'} />
            <Line margin={'16px 0'} />
            <SkeletonTypography lineHeight={'80px'} marginBottom={'24px'} />
            <SkeletonTypography lineHeight={'36px'} marginBottom={'24px'} />
            <SkeletonTypography lineHeight={'48px'} maxWidth={'250px'} margin={'0 auto'} />
          </Paper>
        )}
      </Box>
    </SkeletonWrapper>
  );
};
