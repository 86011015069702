import Trans from 'next-translate/Trans';
import withTranslation from 'next-translate/withTranslation';

import { LinkLiveChat } from '@core/component';
import { Translate } from '@core/constant';
import { WithTranslation } from '@core/type';

import { ErrorDefaultView } from './ErrorDefaultView';

const ERROR_CODE = 404;

const ViewNotFoundContainer = ({ i18n: { t } }: WithTranslation) => (
  <ErrorDefaultView
    error={ERROR_CODE}
    title={t('title')}
    subtitle={
      <Trans
        i18nKey={`${Translate.page.NOT_FOUND}:subtitle`}
        components={{ link: <LinkLiveChat text={t('openLiveChat')} /> }}
      />
    }
  />
);

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const ViewNotFound = withTranslation(ViewNotFoundContainer, Translate.page.NOT_FOUND);
