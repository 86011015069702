import { FC } from 'react';
import styled, { css, CSSObject } from 'styled-components';

import { zIndexes } from '@core/constant';
import type { WithRef, WithThemeProps } from '@core/type';
import { getPaletteHandlers } from '@core/util';

import { BoxProps } from './interface-layout';

export const Box: FC<BoxProps & Partial<WithRef<HTMLDivElement>>> = styled.div(
  (props: BoxProps & WithThemeProps) => {
    const {
      theme: { palette, typography },
      boxShadow: boxShadowProps,
      borderColor: borderColorProps,
      color,
      backgroundColor,
      fontWeight: fontWeightProps,
      zIndex: zIndexProps,
      children,
      tabIndex,
      role,
      as,
      'aria-labelledby': ariaLabelledby,
      animation: animationProps,
      animationDuration,
      animationIterationCount = 1,
      animationTimingFunction = 'ease',
      hoverStyles,
      onClick,
      onMouseEnter,
      onMouseLeave,
      ...boxProps
    } = props;
    const { getColor, getTextColor, getBoxShadow, getBackgroundColor } =
      getPaletteHandlers(palette);

    const boxShadow = getBoxShadow(boxShadowProps);
    const borderColor = getColor(borderColorProps);
    const zIndex = zIndexes[zIndexProps];
    const fontWeight = typography?.fontWeight[fontWeightProps];

    const rules = {
      color: getTextColor(color),
      boxShadow,
      borderColor,
      fontWeight,
      backgroundColor: getBackgroundColor(backgroundColor),
      zIndex,
      ...(borderColor && { borderStyle: 'solid' }),
      ...boxProps,
    } as CSSObject;

    const hoverRules = {
      ...hoverStyles,
      color: getTextColor(hoverStyles?.color),
      boxShadow: getBoxShadow(hoverStyles?.boxShadow),
      borderColor: getColor(hoverStyles?.borderColor),
      fontWeight,
      backgroundColor: getBackgroundColor(hoverStyles?.backgroundColor),
      zIndex: zIndexes[hoverStyles?.zIndex],
    } as CSSObject;

    return css`
      ${rules}
      ${animationProps &&
      css`
        animation: ${animationProps} ${animationDuration} forwards ${animationIterationCount};
        animation-timing-function: ${animationTimingFunction};
      `};

      &:hover {
        ${hoverRules}
      }
    `;
  },
);
