import { PagedRequest, PagedSearchRequest } from '@core/type/api';

const PER_PAGE = 'per_page';
const PAGE = 'page';
const QUERY = 'q';

export const getPagedQuery = (pagedRequest?: PagedRequest): string => {
  if (!pagedRequest) {
    return '';
  }

  const params = new URLSearchParams();
  if (pagedRequest.page) {
    params.set(PAGE, pagedRequest.page.toString());
  }
  if (pagedRequest.perPage) {
    params.set(PER_PAGE, pagedRequest.perPage.toString());
  }
  return `?${params.toString()}`;
};

export const getPagedSearchQuery = (pagedRequest: PagedSearchRequest): string => {
  if (pagedRequest) {
    const params = new URLSearchParams();

    if (pagedRequest.page) {
      params.set(PAGE, pagedRequest.page.toString());
    }
    if (pagedRequest.perPage) {
      params.set(PER_PAGE, pagedRequest.perPage.toString());
    }
    if (pagedRequest.q) {
      params.set(QUERY, pagedRequest.q.toString());
    }

    return `?${params.toString()}`;
  }
  return '';
};

export const getSearchQuery = (q?: string): string => {
  if (q) {
    const params = new URLSearchParams();
    params.set(QUERY, q.toString());
    return `?${params.toString()}`;
  }
  return '';
};
