import { EStorageType } from '@core/type';

import { EStorageKey } from '../constants';
import { getItem, logStorage, removeItem, setItem } from '../helpers';

const getTokenStore = (storage: EStorageType): string => {
  return getItem(storage, EStorageKey.TOKEN);
};

const getRefreshTokenStore = (storage: EStorageType): string => {
  return getItem(storage, EStorageKey.REFRESH_TOKEN);
};

const getIsRetryStore = (storage: EStorageType): string => {
  return getItem(storage, EStorageKey.IS_RETRY);
};

const setTokenStore = (storage: EStorageType, value: string) => {
  logStorage('setTokenStore');
  setItem(storage, EStorageKey.TOKEN, value);
};

const setRefreshTokenStore = (storage: EStorageType, value: string) => {
  logStorage('setRefreshTokenStore');
  setItem(storage, EStorageKey.REFRESH_TOKEN, value);
};

const setIsRetryStore = (storage: EStorageType, value: string) => {
  logStorage('setIsRetryStore');
  setItem(storage, EStorageKey.IS_RETRY, value);
};

const removeTokenStore = (storage: EStorageType) => {
  logStorage('removeTokenStore');
  removeItem(storage, EStorageKey.TOKEN);
};

const removeRefreshTokenStore = (storage: EStorageType) => {
  logStorage('removeRefreshTokenStore');
  removeItem(storage, EStorageKey.REFRESH_TOKEN);
};

const removeIsRetryStore = (storage: EStorageType) => {
  logStorage('removeRefreshTokenStore');
  removeItem(storage, EStorageKey.IS_RETRY);
};

export class AuthStorage {
  public static readonly getTokenStore = getTokenStore;
  public static readonly getIsRetryStore = getIsRetryStore;
  public static readonly getRefreshTokenStore = getRefreshTokenStore;
  public static readonly setTokenStore = setTokenStore;
  public static readonly setIsRetryStore = setIsRetryStore;
  public static readonly setRefreshTokenStore = setRefreshTokenStore;
  public static readonly removeTokenStore = removeTokenStore;
  public static readonly removeIsRetryStore = removeIsRetryStore;
  public static readonly removeRefreshTokenStore = removeRefreshTokenStore;
}
