import { EStorageType } from '@core/type';

import { EStorageKey } from '../constants';
import { getItem, logStorage, removeItem, setItem } from '../helpers';

const getSessionIdStore = (storage: EStorageType): string => {
  logStorage('getSessionIdStore');
  return getItem(storage, EStorageKey.SESSION_ID);
};

const setSessionIdStore = (storage: EStorageType, id: string) => {
  logStorage('setSessionIdStore');
  setItem(storage, EStorageKey.SESSION_ID, id);
};

const removeSessionIdStore = (storage: EStorageType) => {
  logStorage('removeSessionIdStore');
  removeItem(storage, EStorageKey.SESSION_ID);
};

export class ProfileStorage {
  public static readonly getSessionIdStore = getSessionIdStore;
  public static readonly setSessionIdStore = setSessionIdStore;
  public static readonly removeSessionIdStore = removeSessionIdStore;
}
