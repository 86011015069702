import { FC } from 'react';
import styled, { CSSObject } from 'styled-components';

import { EColor, WithRef, WithThemeProps } from '@core/type';
import { getPaletteHandlers } from '@core/util';

import { Box } from '../layout/Box';
import { PaperProps } from './interface-paper';

export const Paper: FC<PaperProps & Partial<WithRef<HTMLDivElement>>> = styled(Box)<PaperProps>(({
  theme: {
    palette,
    structure: {
      paper: {
        border: { borderRadius, ...borderProps },
        form: _form,
        ...paperProps
      },
    },
  },
  isSquare,
  isOutlined,
  hasElevation,
  borderRadius: borderRadiusProps,
  backgroundColor = { semanticType: EColor.NEUTRAL, variant: EColor.LIGHT },
  animation: _animation,
  onClick,
  ...restProps
}: PaperProps & WithThemeProps) => {
  const { getColor, getBoxShadow, getBackgroundColor } = getPaletteHandlers(palette);

  return {
    ...(borderRadiusProps
      ? { borderRadius: borderRadiusProps }
      : isSquare
        ? { borderRadius: 0 }
        : { borderRadius }),
    borderColor: getColor({ commonType: EColor.GRAY, intensity: EColor.A200 }),
    backgroundColor: getBackgroundColor(backgroundColor),
    ...paperProps,
    ...(isOutlined && borderProps),
    ...(hasElevation && { boxShadow: getBoxShadow(EColor.ELEVATION_4) }),
    ...restProps,
  } as CSSObject;
});
