import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type { OnfidoSDKResponse, OnfidoUploadDocumentsResponse } from '@core/type/api';

import { Api } from '../constants/api';
import { configResponse, EConfigName } from '../helpers/config';

const getOnfidoSDK = (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<OnfidoSDKResponse>> => {
  return axiosInstance.get(
    Api.onfido.ONFIDO_SDK,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const uploadDocuments = (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<OnfidoUploadDocumentsResponse>> => {
  return axiosInstance.post(
    Api.onfido.UPLOAD_DOCUMENTS,
    {
      consents: [
        {
          name: 'privacy_notices_read',
          granted: true,
        },
        {
          name: 'ssn_verification',
          granted: true,
        },
        {
          name: 'phone_number_verification',
          granted: true,
        },
      ],
    },
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class OnfidoAPI {
  public static readonly getOnfidoSDK = getOnfidoSDK;
  public static readonly uploadDocuments = uploadDocuments;
}
