import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconStar = ({ fill, secondFill }: DynamicIconProps) => (
  <SVG height={24} width={24} viewBox="0 0 24 24" title="Star icon">
    <path
      d="M10.5289 3.43652C10.6865 2.99323 11.3135 2.99324 11.4711 3.43652L13.111 8.04799C13.2472 8.43083 13.6014 8.69322 14.0073 8.71187L18.717 8.92826C19.1723 8.94918 19.3645 9.51877 19.0148 9.81125L15.2674 12.9457C14.9699 13.1945 14.8431 13.5928 14.942 13.9678L16.2157 18.7975C16.3343 19.2473 15.8284 19.6011 15.4466 19.3354L11.5712 16.6385C11.2279 16.3996 10.7721 16.3996 10.4288 16.6385L6.55336 19.3354C6.1716 19.6011 5.66569 19.2473 5.78429 18.7975L7.05798 13.9678C7.15686 13.5928 7.03006 13.1945 6.73262 12.9457L2.9852 9.81125C2.63552 9.51877 2.82765 8.94918 3.28304 8.92826L7.99266 8.71187C8.39855 8.69322 8.75281 8.43083 8.88896 8.04799L10.5289 3.43652Z"
      fill={secondFill}
    />
    <path
      d="M2.27424 10.9364L2.58551 10.5451L2.27424 10.9364L6.77759 14.5187C6.9346 14.6436 7.00175 14.8503 6.94814 15.0436L5.41052 20.5889C5.16338 21.4802 6.15476 22.2004 6.92607 21.69L11.7247 18.514C11.892 18.4033 12.1093 18.4033 12.2766 18.514L17.0753 21.69L17.3512 21.273L17.0753 21.69C17.8466 22.2004 18.838 21.4802 18.5908 20.5889L17.0532 15.0436C16.9996 14.8503 17.0668 14.6436 17.2238 14.5187L21.7271 10.9364C22.451 10.3606 22.0723 9.19511 21.1482 9.15473L15.3993 8.90353C15.1988 8.89477 15.023 8.76705 14.9528 8.57912L12.9373 3.18917L12.469 3.36429L12.9373 3.18917C12.6134 2.32282 11.388 2.32282 11.064 3.18917L9.04859 8.57912C8.97832 8.76705 8.80253 8.89477 8.60209 8.90353L2.85313 9.15473C1.92908 9.19511 1.5504 10.3606 2.27424 10.9364Z"
      stroke={fill}
    />
  </SVG>
);
