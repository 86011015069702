import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconHeartBig = ({ stroke }: DynamicIconProps) => (
  <SVG height={18} width={18} viewBox="0 0 18 19" title="Heart icon">
    <path
      d="M13.1232 2.40005H13.125C15.6018 2.40005 17.6 4.35605 17.6 6.75804C17.6 10.0833 14.865 13.1922 9.15872 16.0884L9.15855 16.0885C9.10938 16.1134 9.05505 16.1264 9 16.1264C8.94495 16.1264 8.89062 16.1134 8.84145 16.0885L8.84128 16.0884C3.135 13.1922 0.4 10.0833 0.4 6.75804C0.4 4.35605 2.3982 2.40005 4.875 2.40005L4.87677 2.40004C5.62829 2.39672 6.3687 2.58162 7.03042 2.93787C7.69214 3.29411 8.25411 3.81037 8.66512 4.43955L9 4.9522L9.33488 4.43955C9.74589 3.81037 10.3079 3.29411 10.9696 2.93787C11.6313 2.58162 12.3717 2.39672 13.1232 2.40005Z"
      stroke={stroke}
      strokeWidth="0.8"
    />
  </SVG>
);
