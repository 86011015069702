import { FC } from 'react';
import styled from 'styled-components';

import { WithThemeProps } from '@core/type';

import { ToastWrapperProps } from './interface-toast';

export const ToastWrapper: FC<ToastWrapperProps> = styled.div(
  ({
    theme: {
      structure: {
        toast: {
          wrapper: { placement: placementVariants, ...restProps },
        },
      },
    },
    placement,
  }: ToastWrapperProps & WithThemeProps) => ({
    ...placementVariants[placement],
    ...restProps,
    width: '100%',
  }),
);
