import { FC } from 'react';

import { Box, DropdownBase } from '@core/component';
import { EPosition } from '@core/type';

import { DropdownProps } from './interface-search';

export const Dropdown: FC<DropdownProps> = ({ children, onClose, isOpen }) => (
  <DropdownBase
    onClose={onClose}
    isOpen={isOpen}
    width={'100%'}
    minWidth="inherit"
    overflowX="hidden"
    overflowY="auto"
    verticalPosition={EPosition.BOTTOM}
    verticalPositionProps={{
      [EPosition.TOP]: null,
      [EPosition.BOTTOM]: {
        top: '33px',
        bottom: 'auto',
      },
    }}
    fixedMaxHeight={260}
  >
    <Box
      maxHeight={'228px'}
      overflow={'auto'}
      height={'auto'}
      position="relative"
      whiteSpace="wrap"
    >
      <Box position="relative" overflow="auto" width="auto" height="auto">
        {children}
      </Box>
    </Box>
  </DropdownBase>
);
