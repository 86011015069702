import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { CollectionsBannersResponse, CollectionsProductGroupsEntity } from '@core/type/api';

import { CollectionsAPI } from '../api/collections';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & {
    axiosInstance: AxiosInstance;
  },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('AccountTransactionsService', methodName, dto, apiCall, withContent);
};

const getProductGroup = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  slug: string,
): Promise<CollectionsProductGroupsEntity> => {
  return log(
    'getProductGroups',
    { axiosInstance, slug, language },
    CollectionsAPI.getProductGroups,
  );
};

const getProductGroups = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  slugs: string[],
): Promise<CollectionsProductGroupsEntity[]> => {
  const response = await Promise.allSettled([
    ...slugs.map((slug) => CollectionsService.getProductGroup(axiosInstance, language, slug)),
  ]);

  return response.flatMap((result) => (result.status === 'fulfilled' ? [result.value] : []));
};

const getBanners = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  slug: string,
): Promise<CollectionsBannersResponse> => {
  return log('getBanners', { axiosInstance, slug, language }, CollectionsAPI.getBanners);
};

const getBlogArticles = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  slug: string,
): Promise<CollectionsBannersResponse> => {
  return log('getBlogArticles', { axiosInstance, slug, language }, CollectionsAPI.getBlogArticles);
};

export class CollectionsService {
  public static readonly getProductGroups = getProductGroups;
  public static readonly getProductGroup = getProductGroup;
  public static readonly getBanners = getBanners;
  public static readonly getBlogArticles = getBlogArticles;
}
