import { FC, useMemo } from 'react';
import styled from 'styled-components';

import { useContextMediaQuery } from '@core/context';
import type { BackgroundColorType, ColorProps, WithThemeProps } from '@core/type';
import { EActionAttribute, EColor } from '@core/type';
import { getPaletteBackgroundColor } from '@core/util';

import { Box } from './Box';
import { MainContainerProps, StyledMainContainerProps } from './interface-layout';

export const MainContainer: FC<MainContainerProps> = ({
  children,
  isDarkBackground,
  isFullHeightPage,
  noPaddingMargin,
  ...restProps
}) => {
  const { isPhone, isMobile, isTablet, isDesktop } = useContextMediaQuery();

  const isBackgroundDark = useMemo(() => {
    if (isPhone) {
      return isDarkBackground?.isPhone;
    }

    if (isMobile) {
      return isDarkBackground?.isMobile;
    }

    if (isTablet) {
      return isDarkBackground?.isTablet;
    }

    return isDarkBackground?.isDesktop && isDesktop;
  }, [isDarkBackground, isPhone, isMobile, isTablet, isDesktop]);

  const colorTheme: ColorProps<BackgroundColorType> = isBackgroundDark
    ? { commonType: EColor.GRAY, intensity: EColor.A900 }
    : { semanticType: EColor.NEUTRAL, variant: EColor.LIGHT };

  return (
    <StyledMainContainer
      {...restProps}
      maxWidth={isPhone ? '100vw' : undefined}
      display={'flex'}
      flexDirection={'column'}
      colorTheme={colorTheme}
      isFullHeightPage={isFullHeightPage}
      noPadding={noPaddingMargin}
      as={EActionAttribute.MAIN}
    >
      {children}
    </StyledMainContainer>
  );
};

const StyledMainContainer: FC<StyledMainContainerProps> = styled(Box)(
  ({
    theme: {
      palette,
      structure: {
        mainContainer: { padding, fullHeightPagePadding, ...restMainContainerProps },
      },
    },
    colorTheme,
    isFullHeightPage,
    noPadding,
  }: StyledMainContainerProps & WithThemeProps) => ({
    ...restMainContainerProps,
    padding: noPadding ? null : isFullHeightPage ? fullHeightPagePadding : padding,
    backgroundColor: getPaletteBackgroundColor({ backgroundColor: colorTheme, palette }),
  }),
);
