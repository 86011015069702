import { FC, ForwardedRef, forwardRef, memo, Ref } from 'react';

import { EColor } from '@core/type';

import { SelectBase } from './SelectBase';
import { SelectBaseContent } from './SelectBaseContent';
import { SelectBaseOption } from './SelectBaseOption';
import { SelectCrossPageContentProps, SelectCrossPageProps } from './interface-select';

export const SelectCrossPageContent = memo(
  ({
    textColor,
    textHoverColor,
    color: _color,
    children,
    ...restProps
  }: SelectCrossPageContentProps) => (
    <SelectBaseContent color={textColor} hoverStyles={{ color: textHoverColor }} {...restProps}>
      {children}
    </SelectBaseContent>
  ),
);

SelectCrossPageContent.displayName = 'SelectCrossPageContent';

const SelectCrossPageContainer = (
  {
    textColor = { commonType: EColor.WHITE, intensity: EColor.R700 },
    textHoverColor = { commonType: EColor.WHITE, intensity: EColor.R900 },
    iconColor = { commonType: EColor.WHITE, intensity: EColor.R900 },
    iconProps,
    ...restProps
  }: SelectCrossPageProps,
  ref: Ref<HTMLInputElement>,
) => {
  return (
    <SelectBase
      ref={ref}
      width={'fit-content'}
      Option={SelectBaseOption}
      Content={SelectCrossPageContent}
      iconProps={{ ...iconProps, color: iconColor }}
      {...restProps}
      textColor={textColor}
      textHoverColor={textHoverColor}
    />
  );
};

export const SelectCrossPage = forwardRef(SelectCrossPageContainer) as (
  props: SelectCrossPageProps & { ref?: ForwardedRef<HTMLInputElement> },
) => ReturnType<FC<SelectCrossPageProps>>;
