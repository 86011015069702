import useTranslation from 'next-translate/useTranslation';
import { FC, ForwardedRef, Fragment, Ref, forwardRef, memo } from 'react';

import { Translate } from '@core/constant';
import { useContextSelect } from '@core/context';
import { ProviderSelect } from '@core/provider';
import { EFontWeight, EZIndexName } from '@core/type';

import { InputHidden, InputWrapper } from '../input';
import { FieldError } from '../input/FieldError';
import { SelectOnChangeHandler } from '../interface';
import { Box } from '../layout';
import { Line } from '../line';
import { Typography } from '../typography';
import { SelectBaseContent } from './SelectBaseContent';
import { SelectBaseDropdown } from './SelectBaseDropdown';
import { SelectBaseIcon } from './SelectBaseIcon';
import { SelectBaseOption } from './SelectBaseOption';
import { SelectBaseWrapper } from './SelectBaseWrapper';
import { SelectBaseProps } from './interface-select';

const CloseArea = () => {
  const { isOpen, setIsOpen } = useContextSelect();

  if (!isOpen) {
    return null;
  }

  return (
    <Box
      position={'fixed'}
      top={0}
      left={0}
      width={'100vw'}
      height={'100vh'}
      zIndex={EZIndexName.DROPDOWN}
      onClick={() => setIsOpen(false)}
    />
  );
};

const OptionLine = () => (
  <Box padding={'16px 20px'} cursor={'default'}>
    <Line />
  </Box>
);

const SelectBaseContainer = (
  {
    disabled,
    readOnly,
    field,
    value: passedValue,
    onChange,
    options,
    size,
    placeholder = '',
    name,
    horizontalPosition,
    horizontalPositionProps,
    verticalPosition,
    verticalPositionProps,
    iconProps,
    width,
    maxWidth,
    isNavSelect,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Wrapper = SelectBaseWrapper,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Icon = SelectBaseIcon,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Dropdown = SelectBaseDropdown,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Option = SelectBaseOption,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Content = SelectBaseContent,
    textColor,
    textHoverColor,
    prefix,
    linePosition,
    id,
    ...restProps
  }: SelectBaseProps,
  ref: Ref<HTMLInputElement>,
) => {
  const selectedValue = field?.value || passedValue;
  const selectedOption = !isNavSelect && options.find(({ value }) => value === selectedValue);
  const { t: tAria } = useTranslation(Translate.common.ARIA);

  const handleSelectOnChange: SelectOnChangeHandler = (value, _content, e) => {
    if (selectedOption?.value !== value && !isNavSelect && onChange) {
      onChange({ ...e, target: { ...e.target, name, value } });
    }
  };

  const content = selectedOption?.content ?? selectedOption?.label ?? placeholder;

  return (
    <ProviderSelect>
      <InputWrapper width={width} maxWidth={maxWidth}>
        <CloseArea />
        <Wrapper
          {...restProps}
          id={id}
          value={passedValue}
          field={field}
          size={size}
          disabled={disabled}
        >
          <InputHidden
            id={id}
            ref={ref}
            name={name}
            disabled
            readOnly={readOnly}
            value={selectedValue?.toString()}
            onChange={onChange}
            aria-label={tAria('selectHiddenInput')}
          />
          <Content textColor={textColor} textHoverColor={textHoverColor}>
            {prefix ? (
              <>
                {prefix}{' '}
                <Typography fontWeight={EFontWeight.BOLD} display={'inline-block'}>
                  {content}
                </Typography>
              </>
            ) : (
              content
            )}
          </Content>
          <Icon {...iconProps} />
          <Dropdown
            verticalPosition={verticalPosition}
            verticalPositionProps={verticalPositionProps}
            horizontalPosition={horizontalPosition}
            horizontalPositionProps={horizontalPositionProps}
          >
            {options.map(({ value, content, label }, index) => (
              <Fragment key={`${value}${index}`}>
                {linePosition === index && <OptionLine />}
                <Option
                  label={label}
                  value={value}
                  content={content || label}
                  isActive={selectedValue && value === selectedValue}
                  onChange={handleSelectOnChange}
                />
              </Fragment>
            ))}
          </Dropdown>
        </Wrapper>
        <FieldError {...restProps} />
      </InputWrapper>
    </ProviderSelect>
  );
};

export const SelectBase = memo(forwardRef(SelectBaseContainer)) as (
  props: SelectBaseProps & { ref?: ForwardedRef<HTMLInputElement> },
) => ReturnType<FC<SelectBaseProps>>;
