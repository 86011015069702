import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconPlusRounded = ({ fill }: DynamicIconProps) => {
  return (
    <SVG height={24} width={24} viewBox="0 0 24 24" title="Plus rounded icon" fill="inherit">
      <path
        fill={fill}
        d="M12.3,0c-6.6,0-12,5.4-12,12s5.4,12,12,12c6.6,0,12-5.4,12-12S19,0,12.3,0z M17.5,12.8h-4.2V17c0,0.6-0.4,1-1,1  s-1-0.4-1-1v-4.2H7c-0.6,0-1-0.4-1-1s0.4-1,1-1h4.3V6.5c0-0.6,0.4-1,1-1s1,0.4,1,1v4.3h4.2c0.6,0,1,0.4,1,1S18.1,12.8,17.5,12.8z"
      />
    </SVG>
  );
};
