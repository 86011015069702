import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { StaticPageEntity } from '@core/type/api';

import { StaticPageAPI } from '../api/static-page';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('StaticPageService', methodName, dto, apiCall, withContent);
};

const staticPageBySlug = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  slug: string,
): Promise<StaticPageEntity> => {
  return log('staticPageBySlug', { axiosInstance, slug, language }, StaticPageAPI.staticPageBySlug);
};

const staticPageBySlugWithAuth = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  slug: string,
): Promise<StaticPageEntity> => {
  return log(
    'staticPageBySlugWithAuth',
    { axiosInstance, slug, language },
    StaticPageAPI.staticPageBySlugWithAuth,
  );
};

export class StaticPageService {
  public static readonly staticPageBySlugWithAuth = staticPageBySlugWithAuth;
  public static readonly staticPageBySlug = staticPageBySlug;
}
