import { EStrokeWidth } from '@core/type';

import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconIncluded = ({
  stroke,
  secondFill,
  flex,
  strokeWidth = EStrokeWidth.ICON_DEFAULT,
}: DynamicIconProps & { flex?: string | number }) => (
  <SVG height={16} width={16} viewBox="0 0 32 32" title="Check included" flex={flex}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.931 26.7818C23.1118 27.5657 21.1066 27.9999 19 27.9999C10.7157 27.9999 4 21.2842 4 12.9999C4 10.8933 4.43429 8.88801 5.21823 7.06885C3.20827 9.4926 2 12.6051 2 15.9999C2 23.7319 8.26801 29.9999 16 29.9999C19.3947 29.9999 22.5072 28.7917 24.931 26.7818Z"
      fill={secondFill}
    />
    <path
      d="M22.3722 3.53093C20.4609 2.55218 18.2949 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30C23.732 30 30 23.732 30 16C30 14.9703 29.8888 13.9665 29.6778 13"
      stroke={stroke}
      strokeLinecap="round"
    />
    <path
      d="M8.66455 14.3789L15.1428 20.8571L29.1456 6.85466"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </SVG>
);
