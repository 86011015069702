export const util = {
  STEP: 0.1,
  STALETIME_COEF: 60000,
  TIMEOUT_TIMER_COEF: 60000,
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  EXPIRE_COOKIE_COEF: 24 * 60 * 60 * 1000,
  DEBOUNCE: 300,
  SECONDS_IN_MINUTE: 60,
  MINUTES_IN_HOUR: 60,
  HOURS_IN_DAY: 24,
  MILLISECONDS_IN_SECOND: 1000,
};
