import { useEventUserInteraction } from '@google-tag-manager';

import { Box, Container, Line } from '@core/component';
import { localizedCurrencyCustomerContacts } from '@core/constant';
import {
  useContextCurrency,
  useContextMediaQuery,
  useContextProfile,
  useContextUser,
} from '@core/context';
import { useLanguage } from '@core/hook';
import { EColor } from '@core/type';

import { BlogSearchBar } from '../../../../view/blog/components/BlogSearchBar';
import { HeaderDefaultDesktop } from './HeaderDefaultDesktop';
import { HeaderDefaultMobile } from './HeaderDefaultMobile/HeaderDefaultMobile';
import { HeaderMenu } from './HeaderMenu';
import { LiveChartsWithProvider } from './LiveChartsWithProvider';
import { HeaderDefaultNewProps } from './interface-header-default';

export const HeaderDefaultNew = ({
  subcategories,
  shouldShowCurrencyLink,
  menus,
  isBlogPage,
}: HeaderDefaultNewProps) => {
  const { isMobile } = useContextMediaQuery();
  const { language } = useLanguage();
  const { currency } = useContextCurrency();
  const { isLogged, fullName } = useContextUser();
  const { hasNotCompletedStep, hasStorageFeesUnpaid } = useContextProfile();

  const { pushEventUserInteraction } = useEventUserInteraction();

  const phone = localizedCurrencyCustomerContacts[language][currency];

  return (
    <Container gutter={0} maxWidth={'100%'}>
      {!isMobile && <LiveChartsWithProvider />}
      <Container
        maxWidth={'100%'}
        backgroundColor={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
      >
        {isMobile ? (
          <HeaderDefaultMobile
            subcategories={subcategories}
            menus={menus}
            shouldShowCurrencyLink={shouldShowCurrencyLink}
            isBlogPage={isBlogPage}
          />
        ) : (
          <HeaderDefaultDesktop
            phone={phone}
            hasProfileError={hasNotCompletedStep || hasStorageFeesUnpaid}
            isLogged={isLogged}
            fullName={fullName}
            pushEventUserInteraction={pushEventUserInteraction}
            shouldShowCurrencyLink={shouldShowCurrencyLink}
            menus={menus}
          />
        )}
        {isBlogPage && (
          <Box>
            <Box width="100%" display="flex" justifyContent="center">
              <BlogSearchBar />
            </Box>
            <Line margin={'24px -20px 0'} />
          </Box>
        )}
      </Container>
      {menus?.length > 0 && <HeaderMenu menus={menus} />}
    </Container>
  );
};
