import { useCallback } from 'react';

import { toastProperties } from '@core/constant';
import { useStoreToast } from '@core/context';
import { generateUUID } from '@core/security';
import { ToastHook, ToastItem, ToastObject } from '@core/type/context';

export function useToast<T extends ToastItem<ToastObject>>(): ToastHook<T> {
  const [toasts, setToasts] = useStoreToast<ToastItem<T>[], ToastItem<T>>('toasts');

  const addToast = (props: T) => {
    const id = generateUUID();
    setToasts([...(toasts || []), { id, timeout: toastProperties.duration, ...props }]);
    return id;
  };

  const clearId = useCallback(
    (toastId: string) => {
      setToasts(toasts?.filter(({ id }) => id !== toastId));
    },
    [setToasts, toasts],
  );

  const clearAll = () => {
    setToasts(null);
  };

  return {
    toasts,
    addToast,
    clearId,
    clearAll,
  };
}
