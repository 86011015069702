import withTranslation from 'next-translate/withTranslation';
import { useCallback, useMemo, useState } from 'react';

import { Box, Drawer, Line, LinkTranslatedStyledAsLink, Typography } from '@core/component';
import { EDrawerLevel } from '@core/component/interface';
import { Translate } from '@core/constant';
import { useContextUser } from '@core/context';
import { useBodyStyle } from '@core/hook';
import {
  ColorProps,
  EActionAttribute,
  EColor,
  EFontWeight,
  EPosition,
  ERouting,
  TextColorType,
  WithTranslation,
} from '@core/type';

import { SideNavBlog } from './SideNavBlog';
import { SideNavDefault } from './SideNavDefault';
import { SideNavProps } from './interface-header';

const DEFAULT_DRAWER_STATE: Record<EDrawerLevel, boolean> = {
  [EDrawerLevel.RESOURCES]: false,
  [EDrawerLevel.DRAWER]: false,
  [EDrawerLevel.CURRENCY]: false,
  [EDrawerLevel.LANGUAGE]: false,
  [EDrawerLevel.CHARTS]: false,
  [EDrawerLevel.SHOP]: false,
  [EDrawerLevel.USER]: false,
};

const SideNavContainer = ({
  i18n: { t },
  shouldShowCurrencyLink,
  position = EPosition.LEFT,
  iconOrientation,
  iconWidth,
  iconColor,
  isBlogPage,
  subcategories,
}: WithTranslation & SideNavProps) => {
  const { isLogged, fullName, isReady } = useContextUser();
  const [drawerLevelProps, setDrawerLevelProps] =
    useState<Partial<Record<EDrawerLevel | `${EDrawerLevel}/${string}`, boolean>>>(
      DEFAULT_DRAWER_STATE,
    );
  const { removePosition } = useBodyStyle();

  const closeDrawer = useCallback(() => {
    removePosition();
    setDrawerLevelProps(DEFAULT_DRAWER_STATE);
  }, [removePosition]);

  const handleDrawerLevelProps = (props: Partial<Record<EDrawerLevel, boolean>>) => {
    setDrawerLevelProps((prevState) => ({ ...prevState, ...props }));
  };

  const textColor: ColorProps<TextColorType> = {
    commonType: EColor.WHITE,
    intensity: EColor.R900,
  };

  const memoUserHeader = useMemo(() => {
    if (isReady) {
      return isLogged ? (
        <Typography color={textColor} fontWeight={EFontWeight.BOLD}>
          {fullName}
        </Typography>
      ) : (
        <Box display="inline-flex">
          <LinkTranslatedStyledAsLink
            keyRoute={ERouting.SIGN_UP}
            onClick={closeDrawer}
            color={EColor.WHITE}
            fontWeight={EFontWeight.BOLD}
            lineHeight="24px"
          >
            {t('menu.openAccount')}
          </LinkTranslatedStyledAsLink>
          <Line
            color={{ commonType: EColor.WHITE, intensity: EColor.R300 }}
            width="1px"
            isVertical
          />
          <LinkTranslatedStyledAsLink
            keyRoute={ERouting.SIGN_IN}
            onClick={closeDrawer}
            color={EColor.WHITE}
            fontWeight={EFontWeight.BOLD}
            lineHeight="24px"
          >
            {t('menu.signIn')}
          </LinkTranslatedStyledAsLink>
        </Box>
      );
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, isLogged, fullName, t, closeDrawer]);

  return (
    <Drawer
      position={position}
      iconOrientation={iconOrientation}
      iconWidth={iconWidth}
      iconColor={iconColor}
      drawerLevel={EDrawerLevel.DRAWER}
      drawerLevelProps={drawerLevelProps}
      handleDrawerLevelProps={(props) =>
        setDrawerLevelProps((prevState) => ({ ...prevState, ...props }))
      }
    >
      <Box
        marginBottom={'24px'}
        padding="32px 20px 32px 40px"
        backgroundColor={EColor.PRIMARY}
        lineHeight="24px"
        height="88px"
      >
        {memoUserHeader}
      </Box>

      <Box as={EActionAttribute.UL} position="relative" width="100%" listStyleType="none">
        {isBlogPage ? (
          <SideNavBlog
            position={position}
            closeDrawer={closeDrawer}
            shouldShowCurrencyLink={shouldShowCurrencyLink}
            drawerLevelProps={drawerLevelProps}
            handleDrawerLevelProps={handleDrawerLevelProps}
            isBlogPage={isBlogPage}
          />
        ) : (
          <SideNavDefault
            subcategories={subcategories}
            drawerLevelProps={drawerLevelProps}
            handleDrawerLevelProps={handleDrawerLevelProps}
            shouldShowCurrencyLink={shouldShowCurrencyLink}
            closeDrawer={closeDrawer}
          />
        )}
      </Box>
    </Drawer>
  );
};

SideNavContainer.displayName = 'SideNav';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const SideNav = withTranslation(SideNavContainer, Translate.layout.HEADER);
