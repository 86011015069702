import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { TutorialVideoBySlugEntity, TutorialVideosEntity } from '@core/type/api';

import { TutorialVideosAPI } from '../api/tutorial-videos';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('TutorialVideosService', methodName, dto, apiCall, withContent);
};

const getTutorialVideos = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<TutorialVideosEntity> => {
  return log('getTutorialVideos', { axiosInstance, language }, TutorialVideosAPI.getTutorialVideos);
};

const getTutorialVideoBySlug = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  slug: string,
): Promise<TutorialVideoBySlugEntity> => {
  return log(
    'getTutorialVideoBySlug',
    { axiosInstance, slug, language },
    TutorialVideosAPI.getTutorialVideoBySlug,
  );
};

export class TutorialVideosService {
  public static readonly getTutorialVideos = getTutorialVideos;
  public static readonly getTutorialVideoBySlug = getTutorialVideoBySlug;
}
