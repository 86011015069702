import Image from 'next/image';

import IconHelveticorSvg from './svgs/icon-helveticor.svg';

export const IconHelveticor = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconHelveticorSvg}
    alt="Helveticor icon"
    height={20}
    width={73}
  />
);
