import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type {
  PreciousMetalArticlesBySlugEntity,
  PreciousMetalArticlesEntity,
} from '@core/type/api';

import { PreciousMetalArticlesAPI } from '../api/preciousMetalArticles';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('PreciousMetalArticlesService', methodName, dto, apiCall, withContent);
};

const getPreciousMetalArticles = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<PreciousMetalArticlesEntity> => {
  return log(
    'getPreciousMetalArticles',
    { axiosInstance, language },
    PreciousMetalArticlesAPI.getPreciousMetalArticles,
  );
};

const getPreciousMetalMainArticle = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<PreciousMetalArticlesBySlugEntity> => {
  const slug = (
    await PreciousMetalArticlesService.getPreciousMetalArticles(axiosInstance, language)
  ).mainPreciousMetalArticle.slug;
  return PreciousMetalArticlesService.getPreciousMetalArticlesBySlug(axiosInstance, language, slug);
};

const getPreciousMetalArticlesBySlug = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  slug: string,
): Promise<PreciousMetalArticlesBySlugEntity> => {
  return log(
    'getPreciousMetalArticlesBySlug',
    { axiosInstance, language, slug },
    PreciousMetalArticlesAPI.getPreciousMetalArticlesBySlug,
  );
};

export class PreciousMetalArticlesService {
  public static readonly getPreciousMetalMainArticle = getPreciousMetalMainArticle;
  public static readonly getPreciousMetalArticles = getPreciousMetalArticles;
  public static readonly getPreciousMetalArticlesBySlug = getPreciousMetalArticlesBySlug;
}
