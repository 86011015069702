export enum EFrequency {
  ALWAYS = 'always',
  HOURLY = 'hourly',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  SEMIANNUALLY = 'semiannually',
  ANNUALLY = 'annually',
  NEVER = 'never',
}

export type FrequencyType<Frequency extends Partial<EFrequency>> = {
  [key in Frequency]: Frequency;
};

export type AutoSavingsFrequency = keyof FrequencyType<
  | EFrequency.WEEKLY
  | EFrequency.MONTHLY
  | EFrequency.QUARTERLY
  | EFrequency.SEMIANNUALLY
  | EFrequency.ANNUALLY
  | EFrequency.NEVER
>;
