import Image from 'next/image';

import IconGaLogoAlertSvg from './svgs/icon-ga-logo-alert.svg';

export const IconGaLogoAlert = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconGaLogoAlertSvg}
    alt="GA logo alert icon"
    width={32}
    height={32}
  />
);
