import { useEventUserInteraction } from '@google-tag-manager';
import withTranslation from 'next-translate/withTranslation';
import { memo, useMemo } from 'react';
import { useTheme } from 'styled-components';

import {
  Box,
  Icon,
  Line,
  Link,
  LinkLogin,
  LinkTranslatedStyledAsButton,
  LinkTranslatedStyledAsLink,
  SelectCurrency,
  SelectLanguage,
  SelectUserMenu,
} from '@core/component';
import { Translate, localizedCurrencyCustomerContacts } from '@core/constant';
import {
  useContextCurrency,
  useContextMediaQuery,
  useContextRedirectAfterSignInRoute,
  useContextRouting,
  useContextUser,
} from '@core/context';
import { useLanguage } from '@core/hook';
import {
  EActionAttribute,
  EButtonVariant,
  EColor,
  EGTMEventName,
  EIcon,
  ERouting,
  ESize,
  ThemeProps,
  WithTranslation,
} from '@core/type';

import { getEnvironmentPublic } from '../../helpers';
import { HeaderOptionProps } from './interface-header';

const HeaderOptionContainer = ({
  i18n: { t },
  isVisible,
  shouldShowCurrencyLink,
}: WithTranslation & HeaderOptionProps) => {
  const { isPhone } = useContextMediaQuery();
  const { isLogged, isReady } = useContextUser();
  const { currency } = useContextCurrency();
  const { language } = useLanguage();
  const { pushEventUserInteraction } = useEventUserInteraction();
  const { host } = getEnvironmentPublic();
  const { keyRoute } = useContextRouting();
  const { setRoute } = useContextRedirectAfterSignInRoute();

  const phone = localizedCurrencyCustomerContacts[language][currency];

  const {
    typography: {
      header: {
        option: { fontSize },
      },
    },
    structure: {
      header: {
        option: { flexDirection },
      },
    },
  } = useTheme() as ThemeProps;

  const memoUserHeader = useMemo(() => {
    if (isReady) {
      return isLogged ? (
        <SelectUserMenu />
      ) : (
        <>
          <LinkLogin
            onClick={() => {
              if (keyRoute === ERouting.CART) {
                setRoute(keyRoute);
              }
            }}
          />
          <LinkTranslatedStyledAsButton
            keyRoute={ERouting.SIGN_UP}
            size={ESize.XS}
            color={EColor.WHITE}
            variant={EButtonVariant.OUTLINED}
            as={EActionAttribute.LINK}
          >
            {t('menu.openAccount')}
          </LinkTranslatedStyledAsButton>
        </>
      );
    }
    return null;
  }, [isReady, isLogged, t]);

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      textAlign={'center'}
      paddingTop={'10px'}
      flexDirection={flexDirection}
      gap={'15px'}
      opacity={isVisible ? 1 : 0}
      transition="opacity .2s .1s"
      marginBottom={'-12px'}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        textAlign={'center'}
        gap={'20px'}
      >
        <SelectCurrency shouldShowCurrencyLink={shouldShowCurrencyLink} host={host} />
        <SelectLanguage />
        <Box display={'inline-flex'} alignItems={'center'} gap={'8px'}>
          <Icon
            type={EIcon.PHONE}
            color={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
            size={ESize.MD}
          />
          <Link
            color={EColor.WHITE}
            fontSize={fontSize}
            lineHeight={null}
            href={`tel:${phone}`}
            top={'1px'}
            onClick={() => pushEventUserInteraction(EGTMEventName.CLICK_PHONE_CALL)}
          >
            {phone}
          </Link>
        </Box>
      </Box>
      {!isPhone && (
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          textAlign={'center'}
          gap={'16px'}
        >
          <LinkTranslatedStyledAsLink
            keyRoute={ERouting.ABOUT_US}
            color={EColor.WHITE}
            fontSize={fontSize}
            lineHeight={null}
          >
            {t('menu.aboutUs')}
          </LinkTranslatedStyledAsLink>
          <LinkTranslatedStyledAsLink
            keyRoute={ERouting.STORAGE_SOLUTION}
            color={EColor.WHITE}
            fontSize={fontSize}
            lineHeight={null}
          >
            {t('menu.storageSolution')}
          </LinkTranslatedStyledAsLink>
          <LinkTranslatedStyledAsLink
            keyRoute={ERouting.PRICING}
            color={EColor.WHITE}
            fontSize={fontSize}
            lineHeight={null}
          >
            {t('menu.pricing')}
          </LinkTranslatedStyledAsLink>
          <Line
            color={{ commonType: EColor.GRAY, intensity: EColor.R800 }}
            isVertical
            height="14px"
            margin="0 10px"
          />
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            textAlign={'center'}
            height={32}
            gap={isLogged ? '4px' : '24px'}
            minWidth={'100px'}
          >
            {memoUserHeader}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export const HeaderOption = memo(withTranslation(HeaderOptionContainer, Translate.layout.HEADER));
