import { Logger } from '@core/logger';

export const logHook = async <R>(
  hookName: string,
  methodName: string,
  hookCall: () => Promise<R>,
): Promise<R> => {
  try {
    Logger.logInfo(`Call ${hookName}::${methodName}`, { library: 'core-provider' });
    const result = await hookCall();
    Logger.logDebug(`${hookName}::${methodName} successfully`, { library: 'core-provider' });
    return result;
  } catch (e) {
    Logger.logDebug(`${hookName}::${methodName} error`, { library: 'core-provider' });
    Logger.logInfo(e, { isException: true, library: 'core-provider' });
    throw e;
  }
};
