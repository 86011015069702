import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { DiscountEntity } from '@core/type/api';

import { DiscountCodeAPI } from '../api/discount';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('DiscountService', methodName, dto, apiCall, withContent);
};

const getDiscount = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<DiscountEntity> => {
  return log('getDiscount', { axiosInstance, language }, DiscountCodeAPI.getDiscountCode);
};

export class DiscountService {
  public static readonly getDiscount = getDiscount;
}
