import type { AxiosInstance } from 'axios';

import { useStoreUtil } from '@core/context';
import { HookStoreFn, UtilHook, UtilState } from '@core/type/context';

export const useUtil: HookStoreFn<UtilState, UtilHook> = (): UtilHook => {
  const [nonce] = useStoreUtil<string>('nonce');
  const [csrfToken] = useStoreUtil<string>('csrfToken');
  const [recaptchaKey] = useStoreUtil<string>('recaptchaKey');
  const [isScriptGALoaded, setIsScriptGALoaded] = useStoreUtil<boolean>('isScriptGALoaded');
  const [axiosInstance] = useStoreUtil<AxiosInstance>('axiosInstance');
  const [cdn] = useStoreUtil<string>('cdn');
  const [isBotDetected] = useStoreUtil<boolean>('isBotDetected');
  const [staleTimeClient] = useStoreUtil<number>('staleTimeClient');
  const [clientIp] = useStoreUtil<string>('clientIp');

  return {
    nonce,
    csrfToken,
    axiosInstance,
    cdn,
    staleTimeClient,
    isBotDetected,
    recaptchaKey,
    isScriptGALoaded,
    setIsScriptGALoaded,
    clientIp,
  };
};
