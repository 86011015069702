import { EColor, ECurrency, ETypographyVariant } from '@core/type';

import { currencySymbol } from '../constants';
import { BoxProps } from '../interface';
import { Box } from '../layout';
import { Typography } from '../typography';

export const IconCurrency = ({ currency, ...restProps }: BoxProps & { currency: ECurrency }) => {
  return (
    <Box
      position={'relative'}
      width={'16px'}
      height={'16px'}
      backgroundColor={EColor.ACCENT}
      borderRadius={'50%'}
      {...restProps}
    >
      <Box
        position={'absolute'}
        width={'100%'}
        height={'100%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Typography
          color={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
          variant={ETypographyVariant.BODY3}
        >
          {currencySymbol[currency]}
        </Typography>
      </Box>
    </Box>
  );
};
