import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { MostPurchasedProductsResponseEntity } from '@core/type/api';

import { MostPurchasedProductsAPI } from '../api/most-purchased-products';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('MostPurchasedProductsService', methodName, dto, apiCall, withContent);
};

const getMostPurchasedProducts = async (
  axiosInstance: AxiosInstance,
  { language }: { language: ELanguageTags },
): Promise<MostPurchasedProductsResponseEntity> => {
  return log(
    'getMostPurchasedProducts',
    { axiosInstance, language },
    MostPurchasedProductsAPI.getMostPurchasedProducts,
  );
};

const getMostPurchasedProductsVisitor = async (
  axiosInstance: AxiosInstance,
  { language }: { language: ELanguageTags },
): Promise<MostPurchasedProductsResponseEntity> => {
  return log(
    'getMostPurchasedProductsVisitor',
    { axiosInstance, language },
    MostPurchasedProductsAPI.getMostPurchasedProductsVisitor,
  );
};

export class MostPurchasedProductsService {
  public static readonly getMostPurchasedProducts = getMostPurchasedProducts;
  public static readonly getMostPurchasedProductsVisitor = getMostPurchasedProductsVisitor;
}
