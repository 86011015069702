import type { ReactNode } from 'react';

import { authContext } from '@core/context';
import { AuthHook, AuthState } from '@core/type/context';

import { storeHook } from '../helpers';
import { useAuth } from '../hook';

export const ProviderAuth = ({ children }: { children?: ReactNode }) => {
  const hooks = storeHook<AuthState, AuthHook>({}, useAuth);
  return <authContext.Provider value={hooks}>{children}</authContext.Provider>;
};
