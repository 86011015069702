import { ReactElement, ReactNode, useEffect } from 'react';

import { Layout, routeSkeleton, SkeletonHomepage, SkeletonSignIn } from '@core/component';
import {
  useContextAlternate,
  useContextBreadcrumb,
  useContextCart,
  useContextCurrency,
  useContextMeta,
  useContextRouting,
  useContextUser,
} from '@core/context';
import { useLanguage } from '@core/hook';
import {
  DeviceSizeProps,
  EAccessRoute,
  ERouting,
  NewsArticleLdProps,
  Price,
  ProductLdProps,
} from '@core/type';
import { BreadcrumbEntity } from '@core/type/api';
import { getTypeRoute } from '@core/util';

import { MetaTag } from './MetaTag';

export const ContainerFirstLoad = ({
  children,
  price,
  renderNoAccess,
  isLoading,
  article,
  product,
  cmsPageId,
  breadcrumbs: breadcrumbsProp,
}: {
  renderNoAccess: ReactElement;
  children: ReactNode;
  price?: Price;
  isLoading?: boolean;
  article?: NewsArticleLdProps;
  product: ProductLdProps;
  cmsPageId: string;
  breadcrumbs?: BreadcrumbEntity[];
}) => {
  const { keyRoute } = useContextRouting();
  const { language } = useLanguage();
  const { alternates } = useContextAlternate();
  const { currency } = useContextCurrency();
  const { meta } = useContextMeta();
  const { setBreadcrumbs, breadcrumbs } = useContextBreadcrumb();
  const { cart, isReady } = useContextCart();
  const { isLogged } = useContextUser();

  const accessRoute = getTypeRoute(keyRoute)?.accessRoute;
  const renderLoading =
    accessRoute === EAccessRoute.CONNECTED && !isLogged ? (
      <SkeletonSignIn />
    ) : (
      routeSkeleton[keyRoute] || <SkeletonHomepage />
    );

  const isDarkBackground: Partial<DeviceSizeProps> =
    cart?.productCounts > 0 && isReady
      ? { isTablet: true, isDesktop: true, isMobile: true }
      : isReady
        ? null
        : getTypeRoute(keyRoute)?.isDarkBackground;

  useEffect(() => {
    if (breadcrumbsProp && breadcrumbsProp?.length !== 0) {
      setBreadcrumbs(breadcrumbsProp);
    }
  }, [cmsPageId, breadcrumbsProp]);

  return (
    <>
      <MetaTag
        language={language}
        keyRoute={keyRoute}
        metas={meta}
        breadcrumbObjects={breadcrumbs}
        alternates={alternates}
        currency={currency}
        price={price}
        article={article}
        product={product}
        cmsPageId={cmsPageId}
      />
      <Layout
        accessRoute={accessRoute}
        noPaddingMargin={getTypeRoute(keyRoute)?.noPaddingMargin}
        isDarkBackground={
          keyRoute === ERouting.CART ? isDarkBackground : getTypeRoute(keyRoute)?.isDarkBackground
        }
        isFullHeightPage={getTypeRoute(keyRoute)?.isFullHeightPage}
        breadcrumbObjects={breadcrumbs}
        breadcrumbObjectWithLogin={getTypeRoute(keyRoute)?.breadcrumbWithLogin}
        isFullWidthPage={getTypeRoute(keyRoute)?.isFullWidthPage}
        renderNoAccess={renderNoAccess}
        renderLoading={renderLoading}
        isLoading={isLoading}
      >
        {children}
      </Layout>
    </>
  );
};
