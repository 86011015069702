import Image from 'next/image';

import Icon2StepsSvg from './svgs/icon-2-steps.svg';

export const IconTwoSteps = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={Icon2StepsSvg}
    alt="2 steps icon"
    height={28}
    width={126}
  />
);
