import withTranslation from 'next-translate/withTranslation';
import { useMemo, useState } from 'react';

import { Translate } from '@core/constant';
import { useContextAlternate } from '@core/context';
import { useLanguage } from '@core/hook';
import {
  ColorProps,
  EActionAttribute,
  EColor,
  ELanguageTags,
  ERouting,
  LanguageEntity,
  SelectOption,
  TextColorType,
  WithTranslation,
} from '@core/type';
import { getCountryFromLanguage, getLanguageFromTags, getListLanguage } from '@core/util';

import { IconCountry } from '../icon';
import { Box } from '../layout';
import { LinkTranslatedStyledAsLink } from '../link-translated';
import { Typography } from '../typography';
import { SelectCrossPage } from './SelectCrossPage';
import { SelectCrossPageProps } from './interface-select';

const OptionLabel = ({
  iso,
  name,
  query,
  keyRoute,
}: {
  iso: ELanguageTags;
  name: string;
  query?: NodeJS.Dict<string | string[]>;
  keyRoute: ERouting;
}) => {
  return (
    <LinkTranslatedStyledAsLink language={iso} keyRoute={keyRoute} query={query} disableShallow>
      <Box
        display={'flex'}
        alignItems={'center'}
        whiteSpace={'nowrap'}
        textDecoration={'none'}
        color={EColor.PRIMARY}
        padding={'8px 24px'}
      >
        <Box marginRight={16} lineHeight={0} minWidth={20}>
          <IconCountry
            width={20}
            height={20}
            country={getCountryFromLanguage(getLanguageFromTags(iso))}
          />
        </Box>
        {name} - {getLanguageFromTags(iso).toUpperCase()}
      </Box>
    </LinkTranslatedStyledAsLink>
  );
};

const SelectLanguageOptionContent = ({
  iso,
  color,
}: {
  iso: ELanguageTags;
  color: ColorProps<TextColorType>;
}) => (
  <Box display={'flex'} alignItems={'center'} marginRight={'6px'}>
    <Box lineHeight={0} marginRight={'4px'}>
      <IconCountry country={getCountryFromLanguage(getLanguageFromTags(iso))} />
    </Box>
    <Box>
      <Typography
        fontSize={'13px'}
        lineHeight={'20px'}
        transition={'0.3s'}
        as={EActionAttribute.SPAN}
        color={color}
      >
        {getLanguageFromTags(iso).toUpperCase()}
      </Typography>
    </Box>
  </Box>
);

const SelectLanguageContainer = ({
  color,
  i18n: { t },
  ...restProps
}: WithTranslation & Partial<SelectCrossPageProps>) => {
  const { language } = useLanguage();
  const [languageList, setLanguageList] = useState<LanguageEntity[]>([]);
  const { alternates } = useContextAlternate();

  useMemo(() => {
    setLanguageList(getListLanguage(t));
  }, [t]);

  const options: SelectOption[] = useMemo(
    () =>
      languageList.map(({ iso, name }) => ({
        value: iso,
        label: (
          <OptionLabel
            iso={iso}
            name={name}
            {...alternates?.find(({ language }) => language === iso)}
          />
        ),
        content: <SelectLanguageOptionContent iso={iso} color={color} />,
      })),
    [alternates, color, languageList],
  );

  return (
    <SelectCrossPage {...restProps} name={'selectLanguage'} value={language} options={options} />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const SelectLanguage = withTranslation(SelectLanguageContainer, Translate.common.LANGUAGE);
