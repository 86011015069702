import { useRef } from 'react';

import { useContextProfile, useContextRouting, useContextUser } from '@core/context';
import { useLanguage } from '@core/hook';
import { EGTMLoginStatus, GTMDataLayer } from '@core/type';
import { getFullUrl, getLanguageFromTags } from '@core/util';

import { getGTMUserType } from '../helpers';

export const useGTM = (host: string) => {
  const { isReady, isLogged } = useContextUser();
  const { userId, userType } = useContextProfile();
  const { language } = useLanguage();
  const { keyRoute, pathTranslations, query } = useContextRouting();

  const currentUrlRef = useRef<string>(null);
  const prevUrlRef = useRef<string>(null);

  prevUrlRef.current = currentUrlRef.current || null;
  currentUrlRef.current = getFullUrl(host, language, keyRoute, pathTranslations, query);

  return {
    userType: getGTMUserType(userType),
    userId: userId?.toString() || null,
    pageUrl: currentUrlRef.current,
    eventOrigin: prevUrlRef.current,
    loginStatus: isReady && isLogged ? EGTMLoginStatus.LOGGED_IN : EGTMLoginStatus.NOT_LOGGED_IN,
    language: getLanguageFromTags(language),
  } as Omit<GTMDataLayer, 'event'>;
};
