export enum EConfigName {
  CONTENT_TYPE = 'Content-Type',
  LANGUAGE = 'Accept-Language',
}

export const configResponse = (props: Record<string, string>) => ({
  headers: {
    'Content-Type': 'application/json',
    ...props,
  },
});
