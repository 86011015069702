import Image from 'next/image';

import IconMazarsLogoSvg from './svgs/mazars-logo.svg';

export const IconMazarsLogo = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconMazarsLogoSvg}
    alt="Mazars logo"
    width={103}
    height={32}
  />
);
