import { EFontWeight, ESize, ETypographyVariant, ThemeTypography } from '@core/type';

export const themeDefaultTypography: ThemeTypography = {
  font: 'Inter',
  fontStyle: 'normal',
  textDecoration: 'none',
  defaultVariant: ETypographyVariant.BODY2,
  fontWeight: {
    [EFontWeight.LIGHT]: 300,
    [EFontWeight.REGULAR]: 400,
    [EFontWeight.MEDIUM]: 500,
    [EFontWeight.BOLD]: 700,
    [EFontWeight.HEAVY]: 900,
  },
  [ETypographyVariant.DISPLAY]: {
    fontWeight: EFontWeight.BOLD,
    fontSize: '44px',
    lineHeight: '54px',
  },
  [ETypographyVariant.H1]: {
    fontWeight: EFontWeight.BOLD,
    fontSize: '36px',
    lineHeight: '46px',
  },
  [ETypographyVariant.H2]: {
    fontWeight: EFontWeight.BOLD,
    fontSize: '32px',
    lineHeight: '40px',
  },
  [ETypographyVariant.H3]: {
    fontWeight: EFontWeight.BOLD,
    fontSize: '28px',
    lineHeight: '36px',
  },
  [ETypographyVariant.H4]: {
    fontWeight: EFontWeight.BOLD,
    fontSize: '24px',
    lineHeight: '32px',
  },
  [ETypographyVariant.H5]: {
    fontWeight: EFontWeight.BOLD,
    fontSize: '18px',
    lineHeight: '28px',
  },
  [ETypographyVariant.H6]: {
    fontWeight: EFontWeight.BOLD,
    fontSize: '16px',
    lineHeight: '24px',
  },
  [ETypographyVariant.BODY1]: {
    fontWeight: EFontWeight.REGULAR,
    fontSize: '18px',
    lineHeight: '28px',
  },
  [ETypographyVariant.BODY2]: {
    fontWeight: EFontWeight.REGULAR,
    fontSize: '16px',
    lineHeight: '24px',
  },
  [ETypographyVariant.BODY3]: {
    fontWeight: EFontWeight.REGULAR,
    fontSize: '14px',
    lineHeight: '20px',
  },
  [ETypographyVariant.CAPTION1]: {
    fontWeight: EFontWeight.REGULAR,
    fontSize: '12px',
    lineHeight: '16px',
  },
  [ETypographyVariant.CAPTION2]: {
    fontWeight: EFontWeight.REGULAR,
    fontSize: '13px',
    lineHeight: '16px',
  },
  [ETypographyVariant.CAPTION3]: {
    fontWeight: EFontWeight.REGULAR,
    fontSize: '11px',
    lineHeight: '16px',
  },
  form: {
    label: {
      lineHeight: '16px',
    },
    placeholder: {
      lineHeight: 'initial',
      fontSize: 'initial',
    },
  },
  breadcrumb: {
    fontSize: ETypographyVariant.CAPTION2,
  },
  button: {
    size: {
      [ESize.XS]: {
        fontWeight: EFontWeight.MEDIUM,
        fontSize: '14px',
        lineHeight: '30px',
        letterSpacing: '0.5px',
      },
      [ESize.SM]: {
        fontWeight: EFontWeight.MEDIUM,
        fontSize: '15px',
        lineHeight: '34px',
        letterSpacing: '0.5px',
      },
      [ESize.MD]: {
        fontWeight: EFontWeight.MEDIUM,
        fontSize: '15px',
        lineHeight: '46px',
        letterSpacing: '0.6px',
      },
      [ESize.LG]: {
        fontWeight: EFontWeight.MEDIUM,
        fontSize: '15px',
        lineHeight: '54px',
        letterSpacing: '0.6px',
      },
    },
  },
  table: {
    heading: {
      fontSize: '14px',
      fontWeight: EFontWeight.BOLD,
      lineHeight: '21px',
    },
  },
  autocomplete: {
    fontSize: '14px',
  },
  checkbox: {
    fontSize: '14px',
    lineHeight: '1.43',
    fontWeight: '14px',
  },
  header: {
    option: {
      fontSize: '15px',
    },
    metals: {
      fontSize: '15px',
      lineHeight: '22px',
    },
  },
  select: {
    size: {
      [ESize.XS]: {
        fontSize: '14px',
      },
      [ESize.SM]: {
        fontSize: '14px',
      },
      [ESize.MD]: {
        fontSize: '16px',
      },
      [ESize.LG]: {
        fontSize: '16px',
      },
      [ESize.XL]: {
        fontSize: '16px',
      },
    },
  },
};
