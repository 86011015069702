import { createContext } from 'react';

import type { HookStore, ProfileHook, ProfileState } from '@core/type/context';

import { useFnContext, useStoreContext } from '../util';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const profileContext = createContext<HookStore<any, ProfileState, ProfileHook>>(null);

export const useContextProfile = () => {
  return useFnContext(profileContext);
};

export function useStoreProfile<Selector>(selector: keyof ProfileState) {
  return useStoreContext<Selector, ProfileState, ProfileHook>(selector, profileContext);
}
