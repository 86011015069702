import { SVG } from '../SVG';
import { DynamicIconProps } from '../interface-icon';

export const IconWhatsApp = ({ fill }: DynamicIconProps) => (
  <SVG height={20} width={20} viewBox="0 0 20 20" title="WhatsApp icon" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.33 19.556c5.34 0 9.67-4.378 9.67-9.778S15.67 0 10.33 0C4.989 0 .66 4.378.66 9.778c0 1.799.48 3.484 1.318 4.932L0 20l5.714-1.628a9.54 9.54 0 0 0 4.616 1.184M6.92 5.238h.51c.192 0 .365.116.439.293l.918 2.203a.52.52 0 0 1-.162.596c-.314.255-.737.665-.64.956.476 1.428 3.333 3.333 3.81 3.333.327 0 .88-.676 1.194-1.098a.5.5 0 0 1 .603-.163l1.917.872a.475.475 0 0 1 .273.511l-.14.838a.73.73 0 0 1-.203.396c-.923.923-2.286 1.265-3.494.772-1.293-.527-2.886-1.292-3.722-2.128-1.19-1.19-2.143-2.143-2.857-4.048-.163-.435-.178-1.278 0-1.904.123-.433.444-.83.7-1.096.222-.229.536-.333.854-.333"
      fill={fill}
    />
  </SVG>
);
