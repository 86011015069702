import Image from 'next/image';

import IconDealsSvg from './svgs/icon-deals.svg';

export const IconDeals = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconDealsSvg}
    alt="Deals icon"
    width={17}
    height={19}
  />
);
