import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconUnion = ({ fill }: DynamicIconProps) => (
  <SVG height={16} width={15} viewBox="0 0 15 16" title="Union icon">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.904 7.961c0 .798.079 1.576.228 2.313-.7.267-1.368.615-1.995 1.034A6.27 6.27 0 0 1 1.2 8c0-.873.177-1.704.498-2.46.714.418 1.48.762 2.288 1.019q-.082.687-.082 1.402m-1.04 4.304a9 9 0 0 1 1.574-.823c.33 1.017.806 1.919 1.396 2.635a6.3 6.3 0 0 1-2.97-1.812M7.5 10.85q-.987.002-1.92.228c.433 1.321 1.124 2.357 1.923 2.952.801-.594 1.495-1.63 1.927-2.95a8.2 8.2 0 0 0-1.93-.23m2.226-.933a9.4 9.4 0 0 0-4.443-.002 10.8 10.8 0 0 1-.124-3.051q1.134.234 2.34.236c.802 0 1.586-.082 2.341-.236a10.7 10.7 0 0 1-.114 3.053m.845 1.529c-.33 1.014-.804 1.913-1.394 2.628a6.3 6.3 0 0 0 2.96-1.809 9 9 0 0 0-1.566-.82m.306-1.17a11.8 11.8 0 0 0 .135-3.719c.808-.256 1.575-.6 2.289-1.019A6.3 6.3 0 0 1 13.8 8a6.27 6.27 0 0 1-.937 3.307 10 10 0 0 0-1.986-1.03m-.079-4.91q1.024-.346 1.93-.883a6.3 6.3 0 0 0-3.55-2.557c.743.9 1.302 2.093 1.62 3.44M7.451 1.931c-.938.725-1.72 2.06-2.1 3.746a10.45 10.45 0 0 0 4.29 0c-.398-1.713-1.218-3.052-2.19-3.746M4.193 5.364a10 10 0 0 1-1.922-.879 6.3 6.3 0 0 1 3.483-2.54c-.716.906-1.254 2.089-1.56 3.419M7.5 15.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15"
      fill={fill}
    />
  </SVG>
);
