import useTranslation from 'next-translate/useTranslation';
import { memo, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { DEFAULT_SCROLL_BAR_CLASS, Translate, zIndexes } from '@core/constant';
import { useBodyStyle } from '@core/hook';
import {
  ColorProps,
  EColor,
  EIcon,
  EPosition,
  ESize,
  EZIndexName,
  TextColorType,
} from '@core/type';

import { ButtonIcon } from '../button';
import { Icon } from '../icon';
import { Box } from '../layout';
import {
  DrawerContentProps,
  DrawerPositionProps,
  DrawerProps,
  EDrawerLevel,
} from './interface-drawer';

export const Drawer = memo(
  ({
    children,
    position,
    backgroundColor,
    size,
    drawerLevel,
    drawerLevelProps,
    handleDrawerLevelProps,
    iconOrientation = EPosition.BOTTOM,
    iconWidth = '56px',
    iconColor: iconColorProps,
  }: DrawerProps) => {
    const { t: tAria } = useTranslation(Translate.common.ARIA);
    const { removePosition, fixedPosition } = useBodyStyle();
    const [height, setHeight] = useState<number>(null);
    const iconColor: ColorProps<TextColorType> = {
      commonType: EColor.WHITE,
      intensity: EColor.R900,
    };

    const toggleOpen = () => {
      if (drawerLevelProps[drawerLevel]) {
        removePosition();
        handleDrawerLevelProps({ [drawerLevel]: false });
      } else {
        fixedPosition();
        handleDrawerLevelProps({ [drawerLevel]: true });
      }
    };

    useEffect(() => {
      setHeight(window.innerHeight);
    }, []);

    return (
      <>
        <ButtonIcon
          padding="0"
          width={iconWidth}
          onClick={toggleOpen}
          iconProps={{
            type: EIcon.DRAWER,
            color: iconColorProps || iconColor,
            size: ESize.MD,
            orientation: iconOrientation,
          }}
          aria-label={tAria('mobileMenu')}
        />
        <DrawerOverlay
          isOpen={drawerLevelProps[drawerLevel]}
          onClick={toggleOpen}
          onSubmit={toggleOpen}
        >
          <Box
            left={position === EPosition.LEFT ? '328px' : undefined}
            right={position === EPosition.RIGHT ? '328px' : undefined}
            top={'36px'}
            position={'absolute'}
            onClick={() => {
              toggleOpen();
            }}
          >
            <Icon type={EIcon.CROSS} color={iconColor} strokeWidth={'2'} />
          </Box>
        </DrawerOverlay>
        <DrawerContent
          className={DEFAULT_SCROLL_BAR_CLASS}
          isOpen={drawerLevelProps[drawerLevel]}
          size={size}
          position={position}
          backgroundColor={backgroundColor}
          isSubDrawerOpen={Object.values(EDrawerLevel)
            .filter((key) => key !== drawerLevel)
            .some((key) => drawerLevelProps[key as EDrawerLevel])}
          height={height}
        >
          {children}
        </DrawerContent>
      </>
    );
  },
);
Drawer.displayName = 'Drawer';

export const transforms = {
  top: 'translateY(-100%)',
  right: 'translateX(100%)',
  bottom: 'translateY(100%)',
  left: 'translateX(-100%)',
};

export const placements: DrawerPositionProps = {
  [EPosition.TOP]: {
    top: 0,
    right: 0,
    left: 0,
  },
  [EPosition.RIGHT]: {
    top: 0,
    right: 0,
    bottom: 0,
  },
  [EPosition.BOTTOM]: {
    right: 0,
    bottom: 0,
    left: 0,
  },
  [EPosition.LEFT]: {
    top: 0,
    bottom: 0,
    left: 0,
  },
};

// Covers entire view and is used for dismissal
const DrawerOverlay = styled.div<{ isOpen: boolean }>(({ isOpen }: { isOpen: boolean }) => {
  return css`
    position: absolute;
    top: ${isOpen ? '0px' : null};
    right: ${isOpen ? '0px' : null};
    bottom: ${isOpen ? '0px' : null};
    left: ${isOpen ? '0px' : null};
    z-index: ${isOpen ? zIndexes[EZIndexName.DRAWER] : zIndexes[EZIndexName.DEFAULT]};
    background-color: ${isOpen ? 'rgba(21, 51, 88, 0.3)' : 'rgba(21, 51, 88, 0)'};
    transition: background-color 0.3s;
    height: 100vh;
    overflow: auto;
  `;
});

const DrawerContent = styled.nav<DrawerContentProps>(
  ({ isOpen, position, size, backgroundColor, isSubDrawerOpen, height }: DrawerContentProps) => {
    return css`
      display: block;
      box-sizing: border-box;
      position: fixed;
      ${placements[position]};
      z-index: ${zIndexes[EZIndexName.DRAWER]};
      width: ${position !== EPosition.TOP && position !== EPosition.BOTTOM && size
        ? size
        : '300px'};
      transform: ${!isOpen ? transforms[position] : 'translateX(0)'};
      transition: transform 0.3s;
      overflow-y: ${isSubDrawerOpen ? '' : 'auto'};
      overflow-x: ${isSubDrawerOpen ? '' : 'hidden'};
      background-color: ${backgroundColor || '#ffffff'};
      ${position === EPosition.LEFT && 'box-shadow: -10px 0px 10px rgba(0, 0, 0, 0.19);'}
      flex-direction: column;
      backface-visibility: hidden;
      margin: 0;
      padding-left: 0;
      padding-bottom: 64px;
      list-style-type: none;
      height: ${height ? `${height.toString()}px` : '100vh'};
    `;
  },
);
