export const staticFilePath = {
  ASSETS: 'assets',
  PNG: 'png',
  FONTS: 'fonts',
  SCRIPTS: 'scripts',
  SAFERPAY: 'saferpay',
  SITEMAP: 'sitemaps',
  LOCALES: 'locales',
  MEDIA: 'media',
  PUBLIC_FOLDER: 'public',
  ICON_FOLDER: 'icons',
  IMAGE_FOLDER: 'images',
  STATIC_FOLDER: '_next',
  FLAG: 'flags',
};
