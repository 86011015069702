import styled, { css, CSSObject } from 'styled-components';

import { EColor } from '@core/type';
import { getPaletteHandlers } from '@core/util';

import { InputLabelProps } from './interface-input';

export const InputLabel = styled.label<InputLabelProps>(
  ({
    theme: {
      palette,
      structure: {
        input: {
          defaultSize,
          label: {
            required: requiredRules,
            active: activeLabelRules,
            size: sizeVariant,
            ...structureRules
          },
        },
      },
    },
    isRequired,
    withValue,
    size = defaultSize,
  }: InputLabelProps) => {
    const { getTextColor } = getPaletteHandlers(palette);
    const rules = {
      color: getTextColor(EColor.SECONDARY),
      ...sizeVariant[size],
      ...structureRules,
      ...(withValue && activeLabelRules),
    } as CSSObject;

    return css`
      ${rules}
      &:after {
        color: ${getTextColor(EColor.DANGER)};
        ${isRequired && requiredRules};
      }
    `;
  },
);
