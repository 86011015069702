import Image from 'next/image';

import IconIpCheckSvg from './svgs/icon-ip.check.svg';

export const IconIpCheck = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconIpCheckSvg}
    alt="IP check icon"
    height={28}
    width={105}
  />
);
