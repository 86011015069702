import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { GAPayEntity, StorageFeeClaimsEntity } from '@core/type/api';
import { PagedRequest } from '@core/type/api';

import { AccountTransactionAPI } from '../api/account-transactions';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('AccountTransactionsService', methodName, dto, apiCall, withContent);
};

const getGAPayHistory = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  params: PagedRequest,
): Promise<GAPayEntity> => {
  return log(
    'getGAPayTransactionsHistory',
    { axiosInstance, language, ...params },
    AccountTransactionAPI.getGAPayHistory,
  );
};

const getStorageFeeClaimsHistory = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  params: PagedRequest,
): Promise<StorageFeeClaimsEntity> => {
  return log(
    'getStorageFeeClaimsHistory',
    { axiosInstance, language, ...params },
    AccountTransactionAPI.getStorageFeeClaimsHistory,
  );
};

export class AccountTransactionsService {
  public static readonly getGAPayHistory = getGAPayHistory;
  public static readonly getStorageFeeClaimsHistory = getStorageFeeClaimsHistory;
}
