/* eslint-disable @typescript-eslint/no-magic-numbers */
import { useContextMediaQuery } from '@core/context';
import { ETypographyVariant } from '@core/type';

import { Grid } from '../grid';
import { Box } from '../layout';
import { Line } from '../line';
import { SkeletonTypography } from './SkeletonTypography';
import { SkeletonWrapper } from './SkeletonWrapper';

export const SkeletonShop = () => {
  const { isPhone, isTablet, isDesktop } = useContextMediaQuery();

  return (
    <SkeletonWrapper paddingTop={isPhone ? '36px' : '24px'}>
      <Grid isContainer columns={24}>
        <Grid md={10} lg={5}>
          {!isPhone && (
            <Box
              maxHeight={isTablet ? 'calc(100vh - 256px)' : 'calc(100vh - 156px)'}
              overflowY={'auto'}
              paddingRight={'24px'}
            >
              <SkeletonTypography variant={ETypographyVariant.BODY1} />

              {[...Array(4).keys()].map((index: number) => (
                <Box key={index} marginTop={'32px'}>
                  <SkeletonTypography variant={ETypographyVariant.BODY3} width={'65%'} />

                  <Box display={'flex'} flexDirection={'column'} gap={'8px'} marginTop={'32px'}>
                    <SkeletonTypography width={'65%'} />
                    <SkeletonTypography width={'65%'} />
                    <SkeletonTypography width={'65%'} />
                    <SkeletonTypography width={'65%'} />
                  </Box>

                  <Line marginTop={'24px'} />
                </Box>
              ))}
            </Box>
          )}
        </Grid>

        <Grid sm={24} md={14} lg={19}>
          <Box paddingLeft={isPhone ? undefined : '30px'}>
            <SkeletonTypography lineHeight={isDesktop ? '28px' : '56px'} marginBottom={'32px'} />

            <SkeletonTypography
              lineHeight={'36px'}
              width={'80px'}
              marginBottom={isPhone ? '32px' : '48px'}
              borderRadius={'8px'}
            />

            <Grid isContainer columns={24}>
              {[...Array(isDesktop ? 6 : 4).keys()].map((index: number) => (
                <Grid key={index} xs={12} lg={8}>
                  <Box
                    alignItems={'center'}
                    padding={isPhone ? '60px 16px' : '60px 32px'}
                    display={'flex'}
                    flexDirection={'column'}
                  >
                    <SkeletonTypography lineHeight={isPhone ? '120px' : '180px'} width={'100%'} />

                    <SkeletonTypography lineHeight={'40px'} width={'100%'} marginTop={'16px'} />

                    <SkeletonTypography
                      variant={ETypographyVariant.BODY1}
                      marginTop={'8px'}
                      width={'50%'}
                    />
                    <SkeletonTypography
                      variant={ETypographyVariant.CAPTION1}
                      marginTop={'4px'}
                      width={'70%'}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </SkeletonWrapper>
  );
};
