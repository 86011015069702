import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconCircleCross = ({ fill }: DynamicIconProps) => (
  <SVG height={16} width={16} viewBox="0 0 16 16" title="Circle cross icon">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14.8C4.24446 14.8 1.2 11.7555 1.2 8C1.2 4.24446 4.24446 1.2 8 1.2C11.7555 1.2 14.8 4.24446 14.8 8C14.8 11.7555 11.7555 14.8 8 14.8ZM8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16Z"
      fill={fill}
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2776 4.89944C10.5119 4.66512 10.8918 4.66512 11.1262 4.89944C11.3605 5.13375 11.3605 5.51365 11.1262 5.74797L8.84646 8.02767L11.0985 10.2797C11.3328 10.514 11.3328 10.8939 11.0985 11.1282C10.8642 11.3625 10.4843 11.3625 10.25 11.1282L7.99793 8.8762L5.74936 11.1248C5.51504 11.3591 5.13515 11.3591 4.90083 11.1248C4.66652 10.8905 4.66652 10.5106 4.90083 10.2762L7.1494 8.02767L4.87316 5.75143C4.63885 5.51712 4.63885 5.13722 4.87316 4.9029C5.10748 4.66859 5.48738 4.66859 5.72169 4.9029L7.99793 7.17914L10.2776 4.89944Z"
      fill={fill}
    ></path>
  </SVG>
);
