import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { OnfidoSDKResponse, OnfidoUploadDocumentsResponse } from '@core/type/api';

import { OnfidoAPI } from '../api/onfido';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('OnfidoSDKService', methodName, dto, apiCall, withContent);
};

const uploadOnfidoDocuments = (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<OnfidoUploadDocumentsResponse> => {
  return log('uploadOnfidoDocuments', { axiosInstance, language }, OnfidoAPI.uploadDocuments);
};

const getOnfidoSDK = (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<OnfidoSDKResponse> => {
  return log('getOnfidoSDK', { axiosInstance, language }, OnfidoAPI.getOnfidoSDK);
};

export class OnfidoSDKService {
  public static readonly getOnfidoSDK = getOnfidoSDK;
  public static readonly uploadOnfidoDocuments = uploadOnfidoDocuments;
}
