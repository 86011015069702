import { themeContainerMaxWidth } from '@core/constant';
import type { ThemeDevice, ThemeDeviceReturn, ThemeTypography } from '@core/type';
import { EAccordion, ELink, ESize, ETypographyVariant, ThemeStructure } from '@core/type';

import { themeLG } from './theme-lg';

const themeTypographyMD = (baseTheme: ThemeDevice): ThemeTypography => ({
  ...baseTheme.typography,
  [ETypographyVariant.DISPLAY]: {
    ...baseTheme.typography[ETypographyVariant.DISPLAY],
    fontSize: '36px',
    lineHeight: '42px',
  },
  [ETypographyVariant.H1]: {
    ...baseTheme.typography[ETypographyVariant.H1],
    fontSize: '28px',
    lineHeight: '36px',
  },
  [ETypographyVariant.H2]: {
    ...baseTheme.typography[ETypographyVariant.H2],
    fontSize: '24px',
    lineHeight: '32px',
  },
  [ETypographyVariant.H3]: {
    ...baseTheme.typography[ETypographyVariant.H3],
    fontSize: '22px',
    lineHeight: '30px',
  },
  [ETypographyVariant.H4]: {
    ...baseTheme.typography[ETypographyVariant.H4],
    fontSize: '20px',
    lineHeight: '28px',
  },
  [ETypographyVariant.H6]: {
    ...baseTheme.typography[ETypographyVariant.H6],
    fontSize: '15px',
    lineHeight: '22px',
  },
  [ETypographyVariant.BODY2]: {
    ...baseTheme.typography[ETypographyVariant.BODY2],
    fontSize: '15px',
    lineHeight: '22px',
  },
});

const themeStructureMD = (baseTheme: ThemeDevice): ThemeStructure => ({
  ...baseTheme.structure,
  charts: {
    ...baseTheme.structure.charts,
    headerBox: {
      ...baseTheme.structure.charts.headerBox,
      padding: '30px 30px 64px',
      margin: '0 auto -40px',
    },
    livePriceDisplay: 'inline-block',
    chartPaper: { ...baseTheme.structure.charts.chartPaper, maxWidth: '674px' },
  },
  button: {
    ...baseTheme.structure.button,
    size: {
      ...baseTheme.structure.button.size,
      [ESize.SM]: {
        ...baseTheme.structure.button.size[ESize.SM],
        padding: '0 16px',
      },
      [ESize.MD]: {
        ...baseTheme.structure.button.size[ESize.MD],
        padding: '0 16px',
      },
    },
  },
  modalContainer: {
    padding: '48px 24px',
    size: {
      [ESize.MD]: {
        maxWidth: '500px',
      },
      [ESize.LG]: {
        maxWidth: '500px',
      },
      [ESize.XL]: {
        maxWidth: '500px',
      },
      [ESize.XXL]: {
        maxWidth: '500px',
      },
    },
  },
  videoModalContainer: {
    ...baseTheme.structure.videoModalContainer,
    height: '100%',
    iconBox: {
      top: '11px',
      right: '0px',
    },
  },
  mainContainer: {
    ...baseTheme.structure.mainContainer,
    padding: '24px 0 104px',
    minHeight: '789px',
  },
  container: {
    ...baseTheme.structure.container,
    maxWidth: themeContainerMaxWidth[ESize.MD],
  },
  link: {
    ...baseTheme.structure.link,
    variant: {
      [ELink.WITHOUT_TEXT_ON_MOBILE]: {
        display: 'none',
      },
    },
  },
  autocomplete: {
    inputWidth: '100%',
    autocompleteWidth: '100%',
  },
  paper: {
    ...baseTheme.structure.paper,
    form: {
      ...baseTheme.structure.paper.form,
      padding: '24px',
    },
  },
  header: {
    ...baseTheme.structure.header,
    short: { ...baseTheme.structure.header.short, padding: '24px 0', animation: '0px' },
    blog: { ...baseTheme.structure.header.blog, height: '123px', animation: '123px' },
    height: '122px',
    animation: '122px',
    padding: '20px 0 10px 0',
    maxWidth: 'none',
    logoHeight: '30px',
    minWidthSearchButton: '30px',
    metals: { padding: '4px 8px' },
  },
  footer: {
    ...baseTheme.structure.footer,
    subscribe: { ...baseTheme.structure.footer.subscribe, padding: '0 16px' },
    menu: {
      ...baseTheme.structure.footer.menu,
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
      padding: '40px 0 0',
      optionsContainer: {
        ...baseTheme.structure.footer.menu.optionsContainer,
        padding: '0',
      },
      column: {
        ...baseTheme.structure.footer.menu.column,
        marginBottom: '0',
      },
    },
  },
  accordion: {
    ...baseTheme.structure.accordion,
    base: {
      ...baseTheme.structure.accordion.base,
      [EAccordion.FAQ]: {
        ...baseTheme.structure.accordion.base[EAccordion.FAQ],
        padding: '24px 20px',
      },
    },
  },
  discountBanner: {
    padding: '16px 0px',
  },
});

export const themeMD: ThemeDeviceReturn = (baseTheme: ThemeDevice = themeLG()): ThemeDevice => ({
  typography: themeTypographyMD(baseTheme),
  structure: themeStructureMD(baseTheme),
});
