import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconPaperPlane = ({ fill }: DynamicIconProps) => (
  <SVG height={24} width={24} viewBox="0 0 24 24" title="Paper plane icon">
    <path
      fill={fill}
      fillRule="evenodd"
      d="M20.406 5.062a.298.298 0 0 0-.299-.039L2.16 12.613a.252.252 0 0 0-.16.226.25.25 0 0 0 .155.228l5.079 2.269a.3.3 0 0 0 .287-.025l4.938-3.359-3.876 3.63a.239.239 0 0 0-.078.194l.386 4.575a.257.257 0 0 0 .19.22.289.289 0 0 0 .296-.075l2.695-2.843 3.333 1.45a.302.302 0 0 0 .23.003.262.262 0 0 0 .153-.155l4.7-13.624a.237.237 0 0 0-.082-.265z"
    ></path>
  </SVG>
);
