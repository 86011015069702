import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type {
  CreateCustomerSurveyEntity,
  CreateCustomerSurveyResponse,
  CustomerSurveysResponse,
} from '@core/type/api';

import { Api } from '../constants/api';
import { configResponse, EConfigName } from '../helpers/config';

const getCustomerSurveys = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<CustomerSurveysResponse>> => {
  return axiosInstance.get(
    Api.customerSurveys.CUSTOMER_SURVEYS,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const postCustomerSurveys = async (
  axiosInstance: AxiosInstance,
  { language, ...restData }: LanguageProps & CreateCustomerSurveyEntity,
): Promise<AxiosResponse<CreateCustomerSurveyResponse>> => {
  return axiosInstance.post(
    Api.customerSurveys.CUSTOMER_SURVEYS,
    restData,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class CustomerSurveysAPI {
  public static readonly getCustomerSurveys = getCustomerSurveys;
  public static readonly postCustomerSurveys = postCustomerSurveys;
}
