import Image from 'next/image';

import IconSwissPostSvg from './svgs/icon-swiss-post.svg';

export const IconSwissPost = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconSwissPostSvg}
    alt="Swiss Post icon"
    height={20}
    width={60}
  />
);
