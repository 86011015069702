import { forwardRef, Ref } from 'react';
import styled from 'styled-components';

import { InputTextProps } from '../interface';
import { InputBase } from './InputBase';

const InputHiddenBase = <T = Omit<InputTextProps, 'size'>, P = HTMLInputElement>(
  props: Omit<T, 'size'> & { ref?: Ref<P> },
  ref: Ref<P>,
) => <StyledHiddenInput aria-hidden ref={ref as Ref<HTMLInputElement>} {...props} />;

const StyledHiddenInput = styled(InputBase)`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
`;

export const InputHidden = forwardRef(InputHiddenBase) as typeof InputHiddenBase;
