import { SVG } from '../SVG';
import { DynamicIconProps } from '../interface-icon';

export const IconGBP = ({ fill }: DynamicIconProps) => (
  <SVG height={16} width={15} viewBox="0 0 10 16" title="GBP icon">
    <path d="M7.5 8H.938a.938.938 0 0 1 0-1.875H7.5A.938.938 0 0 1 7.5 8" fill={fill} />
    <path
      d="M8.438 15.5H1.875a.938.938 0 0 1 0-1.875.94.94 0 0 0 .938-.937V4.44c0-2.014 1.483-3.739 3.376-3.924 1.054-.1 2.11.247 2.884.962a.937.937 0 0 1-1.271 1.38 1.82 1.82 0 0 0-1.43-.475c-.944.092-1.684.996-1.684 2.057v8.247c0 .328-.057.644-.16.937h3.91a.938.938 0 0 1 0 1.875"
      fill={fill}
    />
  </SVG>
);
