import type {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
  RawAxiosRequestHeaders,
} from 'axios';
import axios, { isAxiosError } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

import { Logger } from '@core/logger';
import { ECurrency, EDevice, EHttpStatusCode, ELanguage, ErrorHandled } from '@core/type';

import { handleError } from './error';

export const getUrl = (
  apiHost: string,
  apiBase: string,
  apiVersion: string,
  url?: string,
): string => {
  return `${apiHost}/${apiBase}/${apiVersion}${url || ''}`;
};

// Interceptor used to log and mapping object in camelCase or snake_case
// Work in server side and browser side
export const setResponseSuccessConfig = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: AxiosResponse<any, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): AxiosResponse<any, any> => {
  if (
    response?.config.baseURL !== '/api/saferpay' &&
    response.data &&
    typeof response?.headers['content-type'] === 'string' &&
    response.headers['content-type'].includes('application/json')
  ) {
    response.data = camelizeKeys(response.data);
  }

  if (
    response?.config.baseURL !== '/api/saferpay' &&
    response.data &&
    (response.status as EHttpStatusCode) >= EHttpStatusCode.MULTIPLE_CHOICES &&
    (response.status as EHttpStatusCode) <= EHttpStatusCode.PERMANENT_REDIRECT &&
    typeof response?.headers['content-type'] === 'string' &&
    response.headers['content-type'].includes('text/html') &&
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    response?.request?.responseURL
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    window.location.replace(response?.request?.responseURL);
  }

  return response;
};

// Interceptor used to log and mapping object in camelCase or snake_case
// Work in server side and browser side
export const setResponseErrorConfig = (err: unknown, isProductionServer?: boolean) => {
  if (err && isAxiosError(err) && err?.response?.data && err?.config?.baseURL !== '/api/saferpay') {
    err.response.data = camelizeKeys(err.response.data);
  }

  if (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    (err as any)?.response?.status >= EHttpStatusCode.MULTIPLE_CHOICES &&
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    (err as any)?.response?.status <= EHttpStatusCode.PERMANENT_REDIRECT &&
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    (err as any)?.response?.headers?.location
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    (err as any).response.config.data = {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      hasToRedirect: (err as any)?.response?.headers?.location,
    };
  }

  return logInterceptorError(err, isProductionServer);
};

// Interceptor used to log and mapping object in camelCase or snake_case
// Work in server side and browser side
export const setRequestSuccessConfig = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: InternalAxiosRequestConfig<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): InternalAxiosRequestConfig<any> | Promise<InternalAxiosRequestConfig<any>> => {
  if (
    typeof config?.headers['content-type'] === 'string' &&
    config?.headers['content-type'].includes('multipart/form-data')
  ) {
    return config;
  }

  if (config.params && config?.baseURL !== '/api/saferpay') {
    config.params = decamelizeKeys(config.params);
  }

  if (config.data && config?.baseURL !== '/api/saferpay') {
    config.data = decamelizeKeys(config.data);
  }

  return config;
};

// Interceptor used to log and mapping object in camelCase or snake_case
// Work in server side and browser side
export const setRequestErrorConfig = (err: unknown, isProductionServer?: boolean) => {
  return logInterceptorError(err, isProductionServer);
};

export const getHttpServerConfig = ({
  locale,
  currency,
  csrfToken,
  contentType = 'application/json',
  apiHost,
  apiBase,
  apiVersion,
  isProductionServer,
  token,
  deviceType,
  tokenAuthorizationApi,
}: {
  locale: ELanguage;
  currency?: ECurrency;
  deviceType?: EDevice;
  csrfToken?: string;
  contentType: 'application/json' | 'multipart/form-data';
  apiHost?: string;
  apiBase?: string;
  apiVersion?: string;
  tokenAuthorizationApi?: string;
  token?: string;
  isProductionServer?: boolean;
}): AxiosInstance => {
  let headers: RawAxiosRequestHeaders = {
    'Content-Type': contentType,
    Accept: 'application/json, multipart/form-data',
    'Accept-Language': locale !== ELanguage.DEFAULT ? locale : ELanguage.EN,
    'Client-Type': deviceType || EDevice.WEB,
  };

  if (currency) {
    headers = {
      ...headers,
      IsoCurrency: currency,
    };
  }

  if (csrfToken) {
    headers = {
      ...headers,
      'X-CSRF-TOKEN': csrfToken,
    };
  }

  if (tokenAuthorizationApi) {
    headers = {
      ...headers,
      UNBOUD_THROTTLE_AUTHORIZATION: tokenAuthorizationApi,
    };
  }

  if (token) {
    headers = {
      ...headers,
      Authorization: `Bearer ${token}`,
    };
  }

  const axiosInstance = axios.create({
    baseURL: getUrl(apiHost, apiBase, apiVersion),
    maxRedirects: 0,
    headers,
  });

  return httpServer(axiosInstance, isProductionServer);
};

export const httpServer = (
  axiosInstance: AxiosInstance,
  isProductionServer?: boolean,
): AxiosInstance => {
  axiosInstance.interceptors.response.use(setResponseSuccessConfig, async (err: unknown) =>
    Promise.reject(setResponseErrorConfig(err, isProductionServer)),
  );
  axiosInstance.interceptors.request.use(
    setRequestSuccessConfig,
    (err: unknown, isProductionServer?: boolean) =>
      Promise.reject(setRequestErrorConfig(err, isProductionServer)),
  );
  return axiosInstance;
};

const logInterceptorError = (err: unknown, isProductionServer?: boolean): ErrorHandled => {
  Logger.logDebug(err, { isException: true });
  const error = handleError(err, isProductionServer);
  if (error?.errors) {
    Logger.logInfo(error?.errors?.join(','));
  }
  if (error?.message) {
    Logger.logInfo(error?.message);
  }
  return { ...error, config: (err as AxiosError)?.config };
};
