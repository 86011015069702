import { SVG } from '../SVG';
import { DynamicIconProps } from '../interface-icon';

export const IconCHF = ({ fill }: DynamicIconProps) => (
  <SVG height={16} width={15} viewBox="0 0 11 16" title="CHF icon">
    <path
      d="M3.544.5v15H1.556V.5zm6.284 6.748v1.628H3.111V7.248zM10.848.5v1.628H3.111V.5zM6.562 11.214v1.566H0v-1.566z"
      fill={fill}
    />
  </SVG>
);
