import type { AxiosInstance } from 'axios';
import useTranslation from 'next-translate/useTranslation';
import { useEffect } from 'react';

import { AuthService, GiftsService } from '@core/api';
import { SESSION_KEY, Translate } from '@core/constant';
import {
  useContextProfile,
  useContextRedirectAfterSignInRoute,
  useContextRouting,
  useContextToast,
  useStoreAuth,
  useStoreUser,
  useStoreUtil,
} from '@core/context';
import { useLanguage } from '@core/hook';
import { EAccessRoute, EColor, ELanguageTags, ERouting, ForgottenPasswordForm } from '@core/type';
import type { AuthEntity, ChangePasswordEntity, LoginEntity, UserSignUp } from '@core/type/api';
import { AuthHook, AuthState, HookStoreFn } from '@core/type/context';
import { getTypeRoute } from '@core/util';

import { logHook } from '../helpers';

const log = async <R>(methodName: string, apiCall: () => Promise<R>): Promise<R> => {
  return logHook('useAuth', methodName, apiCall);
};

export const useAuth: HookStoreFn<AuthState, AuthHook> = (): AuthHook => {
  const { setRoute, redirect } = useContextRouting();
  const { addToast } = useContextToast();
  const [axiosInstance] = useStoreUtil<AxiosInstance>('axiosInstance');
  const { language } = useLanguage();
  const { initializeProfile, resetProfile } = useContextProfile();
  const [isLoadingAuth, setIsLoadingAuth] = useStoreAuth<boolean>('isLoadingAuth');
  const { keyRoute } = useContextRouting();
  const [isLogged, setIsLogged] = useStoreUser<boolean>('isLogged');
  const [isDisconnecting, setIsDisconnecting] = useStoreUser<boolean>('isDisconnecting');
  const [isReady, setIsReady] = useStoreUser<boolean>('isReady');
  const [, setHasMfaSms] = useStoreUser<boolean>('hasMfaSms');
  const [, setHasMfaTotp] = useStoreUser<boolean>('hasMfaTotp');
  const [, setIsRedirectedToSignIn] = useStoreUser<boolean>('isRedirectedToSignIn');
  const { t } = useTranslation(Translate.common.COMMON);
  const { t: tPass } = useTranslation(Translate.page.CHANGE_PASSWORD);
  const {
    route: redirectAfterSignInRoute,
    query: redirectAfterSignInQuery,
    setRoute: setRedirectAfterSignInRoute,
    setQuery: setRedirectAfterSignInQuery,
  } = useContextRedirectAfterSignInRoute();

  const logIn = async (data: LoginEntity, callback?: () => void) => {
    const logInAction = async () => {
      const auth = await AuthService.login(axiosInstance, language, data);
      if (auth?.mfaToken) {
        sessionStorage.setItem(SESSION_KEY, JSON.stringify(auth));
        AuthService.setToken({ token: auth.mfaToken });
        setHasMfaSms(auth.hasMfaSms);
        setHasMfaTotp(auth.hasMfaTotp);
        setRoute(ERouting.VERIFY_AUTH);
      } else {
        await startSession(auth).then(callback);
      }
    };

    await log('logIn', logInAction);
  };

  const startSession = async (auth: AuthEntity): Promise<void> => {
    AuthService.setToken(auth);
    try {
      setIsLoadingAuth(true);
      const [, waitingGiftsData] = await Promise.all([
        initializeProfile(),
        GiftsService.getWaitingGifts(axiosInstance, ELanguageTags.EN_US),
      ]);

      if (waitingGiftsData?.waitingGifts?.length > 0) {
        const waitingGift = waitingGiftsData.waitingGifts[0];
        await redirect(ERouting.DETAILS_GIFT, { reference: waitingGift.referenceNumber });
      } else if (redirectAfterSignInRoute) {
        await redirect(redirectAfterSignInRoute, redirectAfterSignInQuery);
        setRedirectAfterSignInRoute(null);
        setRedirectAfterSignInQuery(null);
      } else {
        await redirect(ERouting.HOME);
      }
    } catch (e) {
      if (isLogged && redirectAfterSignInRoute) {
        await redirect(redirectAfterSignInRoute);
        setRedirectAfterSignInRoute(null);
        setRedirectAfterSignInQuery(null);
      } else if (isLogged) {
        await redirect(ERouting.HOME);
      }
    } finally {
      setIsLoadingAuth(false);
    }
  };

  const signUp = async (data: UserSignUp) => {
    const signUpAction = async () => {
      await AuthService.signUp(axiosInstance, language, data);
      setRoute(ERouting.DONE_SIGN_UP);
      setIsLoadingAuth(true);
      try {
        await initializeProfile();
      } finally {
        setIsLoadingAuth(false);
      }
    };

    await log('signUp', signUpAction);
  };

  const changePassword = async (data: ChangePasswordEntity) => {
    const changePasswordAction = async () => {
      await AuthService.changePassword(axiosInstance, language, data).then(() => {
        addToast({
          title: t('toast.success'),
          description: tPass('password.change'),
          status: EColor.SUCCESS,
        });
      });
      setRoute(ERouting.SIGN_IN);
    };

    await log('changePassword', changePasswordAction);
  };

  const logout = async () => {
    setIsLoadingAuth(true);

    const logoutAction = async () => {
      await AuthService.logout(axiosInstance, language);
    };

    try {
      await log('logout', logoutAction);
    } finally {
      void disconnect(async () => {
        if (getTypeRoute(keyRoute).accessRoute === EAccessRoute.CONNECTED) {
          await redirect(ERouting.SIGN_IN);
        }

        setIsLoadingAuth(false);
      });
    }
  };

  useEffect(() => {
    if (isDisconnecting) {
      void disconnect();
      if (getTypeRoute(keyRoute).accessRoute === EAccessRoute.DEFAULT) {
        addToast({
          title: t(`toast.information`),
          description: 'You have been disconnected',
          status: EColor.WARNING,
        });
      }
    }
  }, [isDisconnecting]);

  const disconnect = async (fn?: () => Promise<void>) => {
    setIsLoadingAuth(true);
    resetProfile();
    setIsLogged(false);
    setIsDisconnecting(false);
    if (fn) {
      try {
        await fn();
      } finally {
        setIsLoadingAuth(false);
      }
    } else {
      setIsLoadingAuth(false);
    }
  };

  const autoLogged = async (): Promise<void> => {
    const autoLoggedAction = async () => {
      try {
        if (isReady) {
          await initializeProfile();
          return;
        } else if (!isLogged && !sessionStorage.getItem(SESSION_KEY)) {
          await AuthService.autoLogged(axiosInstance, language);
          await initializeProfile();
          setIsLogged(true);
        }
      } catch (e) {
        AuthService.removeToken();
        resetProfile();
      } finally {
        setIsReady(true);
      }
    };

    await log('autoLogged', autoLoggedAction);
  };

  const resetPassword = async (data: ForgottenPasswordForm): Promise<void> => {
    const resetPasswordAction = async () => {
      await AuthService.forgottenPassword(axiosInstance, language, data);
      setRoute(ERouting.SIGN_IN);
    };

    await log('resetPassword', resetPasswordAction);
  };

  const checkSession = (callback: () => void) => {
    if (isLogged) {
      callback();
    } else {
      setIsRedirectedToSignIn(true);
    }
  };

  return {
    isLoadingAuth,
    checkSession,
    logIn,
    logout,
    signUp,
    changePassword,
    resetPassword,
    autoLogged,
    disconnect,
    startSession,
  };
};
