import useTranslation from 'next-translate/useTranslation';
import styled, { css } from 'styled-components';

import { Box, Icon, Typography } from '@core/component';
import { BoxProps } from '@core/component/interface';
import { Translate } from '@core/constant';
import {
  useContextCart,
  useContextMediaQuery,
  useContextProfile,
  useContextRouting,
  useContextUser,
} from '@core/context';
import {
  EActionAttribute,
  EColor,
  EIcon,
  ERouting,
  ESize,
  EZIndexName,
  ThemeProps,
} from '@core/type';

const BoxStyled = styled(Box)<BoxProps>(
  ({ theme: { palette } }: { theme: ThemeProps }) => css`
    position: relative;
    display: flex;
    height: 32px;
    cursor: pointer;
    align-items: center;

    path {
      transition: all 0.3s;
    }

    &:hover {
      path {
        fill: ${palette[EColor.PRIMARY][EColor.DARK]};
      }
    }
  `,
);

export const ButtonCart = () => {
  const { isMobile } = useContextMediaQuery();
  const { isLogged } = useContextUser();
  const { cartItemsCount } = useContextProfile();
  const { cart } = useContextCart();
  const { setRoute } = useContextRouting();

  const { t: tAria } = useTranslation(Translate.common.ARIA);

  const productsCount = isLogged ? cartItemsCount : cart?.products?.length || 0;

  return (
    <Box role={'button'} aria-label={tAria('cart')} onClick={() => setRoute(ERouting.CART)}>
      <BoxStyled
        as={EActionAttribute.SPAN}
        width={isMobile ? '28px' : '38px'}
        height={isMobile ? '32px' : '36px'}
      >
        <Icon type={EIcon.CART} size={isMobile ? ESize.MD : ESize.LG} />
        <Box
          backgroundColor={EColor.PRIMARY}
          color={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
          borderRadius="50%"
          width={isMobile ? '12px' : '14px'}
          height={isMobile ? '12px' : '14px'}
          position="absolute"
          lineHeight={isMobile ? '12px' : '14px'}
          zIndex={EZIndexName.AUTO}
          top="2px"
          right={isMobile ? '0' : '2px'}
        >
          <Typography
            textAlign="center"
            lineHeight={isMobile ? '12px' : '14px'}
            fontSize={isMobile ? '6px' : '8px'}
          >
            {productsCount}
          </Typography>
        </Box>
      </BoxStyled>
    </Box>
  );
};
