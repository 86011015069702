import { useContextMediaQuery } from '@core/context';
import { ETypographyVariant } from '@core/type';

import { Grid } from '../grid';
import { Box } from '../layout';
import { Paper } from '../paper';
import { SkeletonTypography } from './SkeletonTypography';
import { SkeletonWrapper } from './SkeletonWrapper';

const PaperBlock = ({ isMobile, isPhone }: { isMobile: boolean; isPhone: boolean }) => {
  return (
    <Paper hasElevation padding={isMobile ? '24px' : '40px'}>
      <SkeletonTypography
        lineHeight={isMobile ? '30px' : '32px'}
        width={'130px'}
        marginBottom={'30px'}
      />
      <SkeletonTypography lineHeight={'20px'} width={'140px'} marginBottom={'4px'} />
      <SkeletonTypography
        lineHeight={isMobile ? '28px' : '36px'}
        width={'160px'}
        marginBottom={'18px'}
      />
      <SkeletonTypography lineHeight={'20px'} width={'280px'} />

      {isPhone && <SkeletonTypography marginTop={'40px'} />}
    </Paper>
  );
};

export const SkeletonStorageFees = () => {
  const { isPhone, isMobile } = useContextMediaQuery();

  return (
    <SkeletonWrapper paddingTop={'40px'} overflow={'visible'}>
      <Box
        marginBottom={'16px'}
        display={'flex'}
        justifyContent={'space-between'}
        flexDirection={isPhone ? 'column' : 'row'}
      >
        <SkeletonTypography
          variant={ETypographyVariant.H2}
          maxWidth={isPhone ? '160px' : '300px'}
          width={'100%'}
          marginBottom={isPhone ? '16px' : undefined}
        />

        <SkeletonTypography
          lineHeight={isPhone ? '44px' : '24px'}
          maxWidth={isPhone ? '100%' : '280px'}
          width={'100%'}
        />
      </Box>

      <SkeletonTypography maxWidth={isPhone ? '250px' : '640px'} marginBottom={'32px'} />

      <Grid isContainer spacing={30}>
        <Grid lg={6}>
          <PaperBlock isMobile={isMobile} isPhone={isPhone} />
        </Grid>

        <Grid lg={6}>
          <PaperBlock isMobile={isMobile} isPhone={isPhone} />
        </Grid>
      </Grid>

      {!isMobile && (
        <>
          <SkeletonTypography width={'120px'} margin={'48px 0 24px'} />

          <Paper hasElevation padding={isMobile ? '24px' : '40px'}>
            <SkeletonTypography lineHeight={'48px'} />
          </Paper>
        </>
      )}
    </SkeletonWrapper>
  );
};
