import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type { SpotPriceEntity } from '@core/type/api';

import { Api } from '../constants/api';
import { configResponse, EConfigName } from '../helpers/config';

const getSpotPrices = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<{ spotPrices: SpotPriceEntity[] }>> => {
  return axiosInstance.get(
    Api.price.SPOT_PRICES,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class PriceAPI {
  public static readonly getSpotPrices = getSpotPrices;
}
