import withTranslation from 'next-translate/withTranslation';
import { Children, ReactNode, memo } from 'react';
import { useTheme } from 'styled-components';

import { CategoriesService } from '@core/api';
import { Box, Icon, Line, Link, LinkTranslatedStyledAsLink, Typography } from '@core/component';
import {
  PAMP_HIRING_URL,
  Translate,
  metalPriceToSlug,
  metalToSlug,
  referralGaUrl,
} from '@core/constant';
import { useContextCurrency, useContextMediaQuery, useContextUser } from '@core/context';
import { useLanguage } from '@core/hook';
import {
  EColor,
  EFontWeight,
  EIcon,
  EMetal,
  EPosition,
  ERouting,
  ETypographyVariant,
  ThemeProps,
  WithTranslation,
} from '@core/type';
import { SelectWithLngAndCur } from '@core/widget';

import { FooterOption } from '../FooterOption';
import { FooterColumnAsAccordion } from './FooterColumnAsAccordion';
import { LabelNew } from './LabelNew';

const LinkCharts = ({ title }: { title: string }) => {
  const { language } = useLanguage();
  const { currency } = useContextCurrency();

  return (
    <Box>
      <LinkTranslatedStyledAsLink
        query={{
          metalSlug: metalPriceToSlug[language][EMetal.GOLD],
          slug: [currency.toLowerCase()],
        }}
        keyRoute={ERouting.CHARTS}
        color={EColor.WHITE}
        variant={ETypographyVariant.BODY3}
        language={language}
      >
        {title}
      </LinkTranslatedStyledAsLink>
    </Box>
  );
};

const LinkHiring = ({ title }: { title: string }) => (
  <Box>
    <Link
      variant={ETypographyVariant.BODY3}
      color={EColor.WHITE}
      href={PAMP_HIRING_URL}
      target={'_blank'}
    >
      {title}
    </Link>
  </Box>
);

const LinkReferrals = ({ title }: { title: string }) => {
  const { isLogged } = useContextUser();
  const { language } = useLanguage();

  if (isLogged) {
    return <LinkFooter keyRoute={ERouting.REFERRALS} title={title} />;
  }

  return (
    <Box>
      <Link
        variant={ETypographyVariant.BODY3}
        color={EColor.WHITE}
        href={referralGaUrl[language]}
        target={'_blank'}
      >
        {title}
      </Link>
    </Box>
  );
};

const LinkFooter = ({
  title,
  keyRoute,
  query,
  isNew,
}: {
  title: string;
  keyRoute: ERouting;
  query?: NodeJS.Dict<string | string[]>;
  isNew?: boolean;
}) => (
  <Box>
    <LinkTranslatedStyledAsLink
      keyRoute={keyRoute}
      query={query}
      variant={ETypographyVariant.BODY3}
      color={EColor.WHITE}
    >
      {title}
    </LinkTranslatedStyledAsLink>
    {isNew && <LabelNew />}
  </Box>
);

const LinkAccount = ({ keyRoute, title }: { title: string; keyRoute: ERouting }) => (
  <Box>
    <LinkTranslatedStyledAsLink
      keyRoute={keyRoute}
      variant={ETypographyVariant.BODY3}
      color={EColor.WHITE}
    >
      {title}
    </LinkTranslatedStyledAsLink>
  </Box>
);

const FooterColumnContainer = ({
  children,
  path,
  isMobile,
  i18n: { t },
}: {
  children: ReactNode;
  path: string;
  isMobile: boolean;
} & WithTranslation) => {
  const {
    structure: {
      footer: {
        menu: {
          column: { marginBottom },
        },
      },
    },
  } = useTheme() as ThemeProps;

  return (
    <>
      {isMobile ? (
        <Box
          display={'flex'}
          alignItems={'flex-start'}
          justifyContent={'center'}
          textAlign={'center'}
          flexDirection={'column'}
          width={'100%'}
        >
          <FooterColumnAsAccordion title={t(path)}>{children}</FooterColumnAsAccordion>
        </Box>
      ) : (
        <Box
          display={'flex'}
          alignItems={'flex-start'}
          justifyContent={'center'}
          textAlign={'center'}
          flexDirection={'column'}
          gap={'12px'}
        >
          <Typography
            color={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
            fontWeight={EFontWeight.MEDIUM}
            variant={ETypographyVariant.CAPTION3}
            marginBottom={marginBottom}
            textTransform={'uppercase'}
          >
            {t(path)}
          </Typography>
          {children}
        </Box>
      )}
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const FooterColumn = withTranslation(FooterColumnContainer, Translate.layout.FOOTER);

const ColumnSection = ({
  children,
  isMobile,
}: {
  children: ReactNode;
  isMobile: boolean;
}): JSX.Element => {
  return (
    <>
      {
        Children.toArray(children).reduce((prev, curr, index) => [
          prev,
          isMobile && (
            <Line
              key={index}
              width={'100%'}
              color={{ commonType: EColor.WHITE, intensity: EColor.R100 }}
            />
          ),
          curr,
        ]) as JSX.Element
      }
    </>
  );
};

const FooterMenuContainer = ({
  shouldShowCurrencyLink,
  i18n: { t },
}: WithTranslation & {
  shouldShowCurrencyLink: boolean;
}) => {
  const {
    structure: {
      footer: {
        menu: { padding },
      },
    },
  } = useTheme() as ThemeProps;

  const { language } = useLanguage();
  const { isMobile } = useContextMediaQuery();

  return (
    <>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        flexDirection={isMobile ? 'column' : 'row'}
        padding={isMobile ? '40px 0 0 8px' : '48px 0 10px'}
        position="relative"
      >
        <Icon width={'233px'} height={'auto'} type={EIcon.GA_LOGO_WITH_PAMP} />
        <Box
          display={'flex'}
          alignItems={'center'}
          textAlign={'center'}
          padding={isMobile ? '40px 0 0 2px' : '0'}
          gap={'24px'}
        >
          <SelectWithLngAndCur
            containerIsRelative
            position={isMobile ? EPosition.TOP_LEFT : EPosition.TOP_RIGHT}
            isDarkBackground
            shouldShowCurrencyLink={shouldShowCurrencyLink}
          />
        </Box>
      </Box>
      <Box
        display={'flex'}
        alignItems={'flex-start'}
        justifyContent={'space-between'}
        textAlign={'center'}
        flexDirection={isMobile ? 'column' : 'row'}
        padding={padding}
      >
        {isMobile && (
          <Line width={'100%'} color={{ commonType: EColor.WHITE, intensity: EColor.R100 }} />
        )}
        <ColumnSection isMobile={isMobile}>
          <FooterColumn path={'menu.shop.title'} isMobile={isMobile}>
            {CategoriesService.getCategories(language).categories?.map(
              (value) =>
                value?.title && (
                  <LinkFooter
                    key={value.title}
                    keyRoute={ERouting.BUY}
                    query={{ metal: metalToSlug[language][value.metalIso] }}
                    title={value.title}
                  />
                ),
            )}
          </FooterColumn>
          <FooterColumn path={'menu.buying.title'} isMobile={isMobile}>
            <LinkCharts title={t('menu.buying.liveCharts')} />
            <LinkFooter
              keyRoute={ERouting.AUTO_SAVINGS}
              title={t('menu.buying.autoSavings')}
              isNew
            />
            <LinkFooter
              keyRoute={ERouting.SAVING_ASSISTANT}
              title={t('menu.buying.savingsAssistant')}
            />
            <LinkFooter
              keyRoute={ERouting.STORAGE_SOLUTION}
              title={t('menu.buying.storageSolution')}
            />
            <LinkFooter keyRoute={ERouting.PRICING} title={t('menu.buying.pricing')} />
          </FooterColumn>
          <FooterColumn path={'menu.knowledge.title'} isMobile={isMobile}>
            <LinkFooter keyRoute={ERouting.BLOG_HOME} title={t('menu.knowledge.blog')} />
            <LinkFooter
              keyRoute={ERouting.PRECIOUS_METAL_GUIDE}
              title={t('menu.knowledge.preciousMetal')}
            />
            <LinkFooter keyRoute={ERouting.FAQ} title={t('menu.whoWeAre.faq')} />
            <LinkFooter keyRoute={ERouting.TUTORIAL_VIDEOS} title={t('menu.knowledge.tutorial')} />
          </FooterColumn>
          <FooterColumn path={'menu.whoWeAre.title'} isMobile={isMobile}>
            <LinkFooter keyRoute={ERouting.ABOUT_US} title={t('menu.whoWeAre.aboutUs')} />
            <LinkHiring title={t('menu.whoWeAre.hiring')} />
            <LinkFooter keyRoute={ERouting.CONTACT} title={t('menu.whoWeAre.contactUs')} />
            <LinkReferrals title={t('menu.whoWeAre.referFriend')} />
          </FooterColumn>
          <FooterColumn path={'menu.account.title'} isMobile={isMobile}>
            <LinkAccount keyRoute={ERouting.WALLET} title={t('menu.account.wallet')} />
            <LinkAccount keyRoute={ERouting.WALLET_ORDERS} title={t('menu.account.orderHistory')} />
            <LinkAccount keyRoute={ERouting.STORAGE_FEES} title={t('menu.account.storageFees')} />
            <LinkAccount
              keyRoute={ERouting.PURCHASING_LIMIT}
              title={t('menu.account.purchasingLimit')}
            />
            <LinkAccount keyRoute={ERouting.PROFILE} title={t('menu.account.profile')} />
          </FooterColumn>
        </ColumnSection>
      </Box>
      <Line color={{ commonType: EColor.WHITE, intensity: EColor.R100 }} />
      <FooterOption />
    </>
  );
};

export const FooterMenu = memo(withTranslation(FooterMenuContainer, Translate.layout.FOOTER));
