import withTranslation from 'next-translate/withTranslation';

import { Button } from '@core/component';
import { Translate } from '@core/constant';
import { useContextRouting } from '@core/context';
import {
  EButtonVariant,
  EColor,
  EIcon,
  EPosition,
  ERouting,
  ESize,
  WithTranslation,
} from '@core/type';

import { CloseDrawerFnTyp } from '../../../interface-header';

const SignInButtonBase = ({ i18n: { t }, closeDrawer }: WithTranslation & CloseDrawerFnTyp) => {
  const { setRoute } = useContextRouting();

  return (
    <Button
      onClick={() => {
        closeDrawer();
        setRoute(ERouting.SIGN_IN);
      }}
      variant={EButtonVariant.CONTAINED}
      color={EColor.SECONDARY}
      lineHeight={'40px'}
      endIcon={EIcon.ARROW_DOWN}
      iconOrientation={EPosition.RIGHT}
      iconSize={ESize.SM}
      isIconStroke
      isIconFilled
    >
      {t('menu.signIn')}
    </Button>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const SignInButton = withTranslation(SignInButtonBase, Translate.layout.HEADER);
