import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags, LanguageProps } from '@core/type';
import type {
  AddressPasswordReq,
  AutoSavingsProductEntity,
  BankAccountEntity,
  CertificateDatesEntity,
  CreditCardsResponse,
  CurrencyEntity,
  DateIntervalEntity,
  DeleteAutoSavingsProductEntity,
  DeleteBankAccountReq,
  DeleteCreditCardEntity,
  DepositDetailsEntity,
  FraudCreditCardReq,
  InitializeCreditCardReq,
  ListBankAccountsEntity,
  NewCreditCardReq,
  NewsletterSubscriptionEntity,
  PasswordEntity,
  PatchAutoSavingsProductEntity,
  PhoneEntity,
  PostBankAccountReq,
  PostReferralProgramEntity,
  PostWithdrawalEntity,
  PurchasingLimitEntity,
  ReferralAdressEntity,
  ReferralProgramEntity,
  StorageFeesInvoicesEntity,
  StorageFeesRequestEntity,
  StorageFeesResponseEntity,
  TotpEntity,
  UpdateEmergencyPeopleEntity,
  UpdateLoginAlertEntity,
  UpdatePasswordEntity,
  UpdateUserInformationEntity,
  UserAutoSavingsProductsEntity,
  UserEntity,
  UserNewsletterSubscriptionEntity,
} from '@core/type/api';
import { PaymentTransactionReq, StorageFeesInvoicesReq } from '@core/type/api';

import { Api } from '../constants/api';
import { replaceIdUrl } from '../helpers/api';
import { configResponse, EConfigName } from '../helpers/config';
import { addParameters } from '../helpers/util';

const getProfile = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<UserEntity>> =>
  axiosInstance.get(Api.user.PROFILE, configResponse({ [EConfigName.LANGUAGE]: language }));

const getCertificateDates = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<CertificateDatesEntity>> =>
  axiosInstance.get(
    Api.user.CERTIFICATE_DATES,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const getBankAccounts = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<ListBankAccountsEntity>> =>
  axiosInstance.get(Api.user.BANK_ACCOUNTS, configResponse({ [EConfigName.LANGUAGE]: language }));

const getDepositDetails = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<DepositDetailsEntity>> =>
  axiosInstance.get(Api.user.DEPOSIT_DETAILS, configResponse({ [EConfigName.LANGUAGE]: language }));

const getDepositDetailsPdf = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<Blob>> =>
  axiosInstance.get(`${Api.user.DEPOSIT_DETAILS}.pdf`, {
    headers: {
      [EConfigName.CONTENT_TYPE]: 'multipart/form-data',
      [EConfigName.LANGUAGE]: language,
    },
    responseType: 'blob',
  });

const deleteBankAccount = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & DeleteBankAccountReq,
): Promise<AxiosResponse<void>> =>
  axiosInstance.delete(`${Api.user.BANK_ACCOUNTS}/${data.id}`, {
    ...configResponse({ [EConfigName.LANGUAGE]: language }),
    data: { password: data.password },
  });

const getNewsletterSubscriptions = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<NewsletterSubscriptionEntity>> =>
  axiosInstance.get(
    Api.user.NEWSLETTER_SUBSCRIPTIONS,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const getNewsletterSubscriptionsWithToken = async (
  axiosInstance: AxiosInstance,
  { token, language }: LanguageProps & { token: string },
): Promise<AxiosResponse<UserNewsletterSubscriptionEntity>> =>
  axiosInstance.get(
    `${Api.user.NEWSLETTER_SUBSCRIPTIONS}/${token}`,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const putNewsletterSubscriptions = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & UserNewsletterSubscriptionEntity,
): Promise<AxiosResponse<void>> =>
  axiosInstance.put(
    Api.user.NEWSLETTER_SUBSCRIPTIONS_PROFILE,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const putNewsletterSubscriptionsWithToken = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & UserNewsletterSubscriptionEntity & { token: string },
): Promise<AxiosResponse<void>> =>
  axiosInstance.put(
    `${Api.user.NEWSLETTER_SUBSCRIPTIONS}/${data.token}`,
    { newsletterSubscriptionIds: data.newsletterSubscriptionIds },
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const putProfile = async (
  axiosInstance: AxiosInstance,
  { language, locale, ...data }: LanguageProps & UpdateUserInformationEntity,
): Promise<AxiosResponse<void>> =>
  axiosInstance.put(
    Api.user.PROFILE,
    { language: locale as ELanguageTags, ...data },
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const putPassword = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & UpdatePasswordEntity,
): Promise<AxiosResponse<void>> =>
  axiosInstance.put(
    Api.user.PASSWORD_SETTINGS,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const getPurchasingLimit = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<PurchasingLimitEntity>> =>
  axiosInstance.get(
    Api.user.PURCHASING_LIMIT,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const putEmergencyPeople = (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & UpdateEmergencyPeopleEntity,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.put(
    Api.user.EMERGENCY_PEOPLE,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const putLoginAlert = (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & UpdateLoginAlertEntity,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.put(
    Api.user.LOGIN_ALERT,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const postEmergencyPeople = (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & UpdateEmergencyPeopleEntity,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.post(
    Api.user.EMERGENCY_PEOPLE,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const postAddresses = (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & AddressPasswordReq,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.post(
    Api.user.ADDRESSES,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const postSMS = (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & { phone: PhoneEntity },
): Promise<AxiosResponse<void>> => {
  return axiosInstance.post(
    Api.user.SMS,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const putSMS = (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & { code: string },
): Promise<AxiosResponse<void>> => {
  return axiosInstance.put(
    Api.user.SMS,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getSMS = (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.get(Api.user.SMS, configResponse({ [EConfigName.LANGUAGE]: language }));
};

const postTotp = (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<TotpEntity>> => {
  return axiosInstance.post(
    Api.user.TOTP,
    null,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const putTotp = (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & { code: string },
): Promise<AxiosResponse<void>> => {
  return axiosInstance.put(
    Api.user.TOTP,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const postCertificate = (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & DateIntervalEntity,
): Promise<AxiosResponse<Blob>> => {
  return axiosInstance.post(Api.user.CERTIFICATE, data, {
    ...configResponse({
      [EConfigName.CONTENT_TYPE]: 'multipart/form-data',
      [EConfigName.LANGUAGE]: language,
    }),
    responseType: 'blob',
  });
};

const postBankAccount = (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & PostBankAccountReq,
): Promise<AxiosResponse<{ bankAccount: BankAccountEntity }>> => {
  return axiosInstance.post(
    Api.user.BANK_ACCOUNTS,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const postWithdrawal = (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & PostWithdrawalEntity,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.post(
    Api.user.WITHDRAWALS,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const deleteSms = (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & PasswordEntity,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.delete(Api.user.SMS, {
    ...configResponse({ [EConfigName.LANGUAGE]: language }),
    data: { password: data.password },
  });
};
const deleteTotp = (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & PasswordEntity,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.delete(Api.user.TOTP, {
    ...configResponse({ [EConfigName.LANGUAGE]: language }),
    data: { password: data.password },
  });
};

const getCreditCards = (
  axiosInstance: AxiosInstance,
  { language, isStrong }: LanguageProps & { isStrong?: boolean },
): Promise<AxiosResponse<CreditCardsResponse>> => {
  return axiosInstance.get(
    isStrong
      ? addParameters(Api.creditCard.CREDIT_CARDS, { strong: 'true' })
      : Api.creditCard.CREDIT_CARDS,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const deleteCreditCard = (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & DeleteCreditCardEntity,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.delete(`${Api.creditCard.CREDIT_CARDS}/${data.id}`, {
    ...configResponse({ [EConfigName.LANGUAGE]: language }),
    data: { password: data.password },
  });
};

const initializeCreditCard = (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & InitializeCreditCardReq,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.post(
    Api.creditCard.INITIALIZE,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};
const newCreditCard = (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & NewCreditCardReq,
): Promise<AxiosResponse<{ id: number }>> => {
  return axiosInstance.post(
    Api.creditCard.NEW,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const deleteNewCreditCard = (
  axiosInstance: AxiosInstance,
  { language, id }: LanguageProps & { id: string },
): Promise<AxiosResponse<void>> => {
  return axiosInstance.delete(
    `${Api.creditCard.NEW}/${id}`,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const fraudCreditCard = (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & FraudCreditCardReq,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.post(
    Api.creditCard.FRAUD,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const paymentTransactions = (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & PaymentTransactionReq,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.post(
    Api.creditCard.PAYMENT_TRANSACTIONS,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getAutoSavingsProducts = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<UserAutoSavingsProductsEntity>> =>
  axiosInstance.get(
    Api.user.AUTO_SAVINGS_PRODUCTS,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const getAutoSavingsProductById = async (
  axiosInstance: AxiosInstance,
  { id, language }: LanguageProps & { id: number },
): Promise<AxiosResponse<AutoSavingsProductEntity>> =>
  axiosInstance.get(
    replaceIdUrl(Api.user.AUTO_SAVINGS_PRODUCT_BY_ID, id, ':id'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const putAutoSavingsProductPause = async (
  axiosInstance: AxiosInstance,
  { id, language }: LanguageProps & { id: number },
): Promise<AxiosResponse<AutoSavingsProductEntity>> =>
  axiosInstance.put(
    replaceIdUrl(Api.user.AUTO_SAVINGS_PAUSE, id, ':id'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const putAutoSavingsProductUnpause = async (
  axiosInstance: AxiosInstance,
  { id, language }: LanguageProps & { id: number },
): Promise<AxiosResponse<AutoSavingsProductEntity>> =>
  axiosInstance.put(
    replaceIdUrl(Api.user.AUTO_SAVINGS_UNPAUSE, id, ':id'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const deleteAutoSavingsProductById = async (
  axiosInstance: AxiosInstance,
  { id, language, ...data }: LanguageProps & DeleteAutoSavingsProductEntity,
): Promise<AxiosResponse<void>> =>
  axiosInstance.delete(replaceIdUrl(Api.user.AUTO_SAVINGS_PRODUCT_BY_ID, id, ':id'), {
    ...configResponse({ [EConfigName.LANGUAGE]: language }),
    data,
  });

const patchAutoSavingsProductById = async (
  axiosInstance: AxiosInstance,
  { id, language, ...data }: LanguageProps & PatchAutoSavingsProductEntity,
): Promise<AxiosResponse<AutoSavingsProductEntity>> =>
  axiosInstance.patch(
    replaceIdUrl(Api.user.AUTO_SAVINGS_PRODUCT_BY_ID, id, ':id'),
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const getReferralProgram = (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<ReferralProgramEntity>> => {
  return axiosInstance.get(
    Api.user.REFERRAL_PROGRAM,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const postReferralProgram = (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & CurrencyEntity,
): Promise<AxiosResponse<PostReferralProgramEntity>> => {
  return axiosInstance.post(
    Api.user.REFERRAL_PROGRAM,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const postReferralProgramAddress = (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & ReferralAdressEntity,
): Promise<AxiosResponse<void>> => {
  return axiosInstance.post(
    Api.user.REFERRAL_PROGRAM_ADDRESS,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getStorageFees = (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<StorageFeesResponseEntity>> => {
  return axiosInstance.get(
    Api.user.STORAGE_FEES,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const putStorageFees = (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & StorageFeesRequestEntity,
): Promise<AxiosResponse<StorageFeesResponseEntity>> => {
  return axiosInstance.put(
    Api.user.STORAGE_FEES,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getStorageFeesInvoices = (
  axiosInstance: AxiosInstance,
  { language, page, perPage }: StorageFeesInvoicesReq & LanguageProps,
): Promise<AxiosResponse<StorageFeesInvoicesEntity>> =>
  axiosInstance.get(
    addParameters(Api.user.STORAGE_FEES_INVOICES, {
      page: page?.toString(),
      per_page: perPage?.toString(),
    }),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

export class UserAPI {
  public static readonly getProfile = getProfile;
  public static readonly getCertificateDates = getCertificateDates;
  public static readonly postCertificate = postCertificate;
  public static readonly putProfile = putProfile;
  public static readonly putPassword = putPassword;
  public static readonly getPurchasingLimit = getPurchasingLimit;
  public static readonly putEmergencyPeople = putEmergencyPeople;
  public static readonly putLoginAlert = putLoginAlert;
  public static readonly postEmergencyPeople = postEmergencyPeople;
  public static readonly postAddresses = postAddresses;
  public static readonly getDepositDetails = getDepositDetails;
  public static readonly getDepositDetailsPdf = getDepositDetailsPdf;
  public static readonly getSMS = getSMS;
  public static readonly postSMS = postSMS;
  public static readonly putSMS = putSMS;
  public static readonly postTotp = postTotp;
  public static readonly putTotp = putTotp;
  public static readonly deleteSms = deleteSms;
  public static readonly deleteTotp = deleteTotp;
  public static readonly getBankAccounts = getBankAccounts;
  public static readonly postBankAccount = postBankAccount;
  public static readonly deleteBankAccount = deleteBankAccount;
  public static readonly postWithdrawal = postWithdrawal;
  public static readonly getNewsletterSubscriptions = getNewsletterSubscriptions;
  public static readonly getNewsletterSubscriptionsWithToken = getNewsletterSubscriptionsWithToken;
  public static readonly putNewsletterSubscriptions = putNewsletterSubscriptions;
  public static readonly getAutoSavingsProducts = getAutoSavingsProducts;
  public static readonly getAutoSavingsProductById = getAutoSavingsProductById;
  public static readonly putAutoSavingsProductPause = putAutoSavingsProductPause;
  public static readonly putAutoSavingsProductUnpause = putAutoSavingsProductUnpause;
  public static readonly deleteAutoSavingsProductById = deleteAutoSavingsProductById;
  public static readonly patchAutoSavingsProductById = patchAutoSavingsProductById;
  public static readonly putNewsletterSubscriptionsWithToken = putNewsletterSubscriptionsWithToken;
  public static readonly getCreditCards = getCreditCards;
  public static readonly initializeCreditCard = initializeCreditCard;
  public static readonly fraudCreditCard = fraudCreditCard;
  public static readonly paymentTransactions = paymentTransactions;
  public static readonly newCreditCard = newCreditCard;
  public static readonly deleteNewCreditCard = deleteNewCreditCard;
  public static readonly deleteCreditCard = deleteCreditCard;
  public static readonly getReferralProgram = getReferralProgram;
  public static readonly postReferralProgram = postReferralProgram;
  public static readonly postReferralProgramAddress = postReferralProgramAddress;
  public static readonly getStorageFees = getStorageFees;
  public static readonly putStorageFees = putStorageFees;
  public static readonly getStorageFeesInvoices = getStorageFeesInvoices;
}
