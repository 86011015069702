import { SVG } from '../SVG';
import { DynamicIconProps } from '../interface-icon';

export const IconInstagram = ({ fill }: DynamicIconProps) => (
  <SVG height={24} width={25} viewBox="0 0 25 24" title="Instagram icon">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.24 6c.975.004 1.206.012 1.733.036.64.029 1.075.13 1.457.28.395.152.73.357 1.063.69.334.334.539.67.693 1.064.147.382.25.817.278 1.457.024.527.032.758.036 1.734v1.478a33 33 0 0 1-.036 1.734c-.029.64-.13 1.075-.278 1.457-.151.4-.387.763-.693 1.063a2.9 2.9 0 0 1-1.063.693c-.382.147-.817.25-1.457.278-.527.024-.758.032-1.734.036h-1.478a31 31 0 0 1-1.734-.036c-.64-.029-1.075-.13-1.457-.278a2.9 2.9 0 0 1-1.063-.693 2.9 2.9 0 0 1-.691-1.063c-.15-.382-.251-.817-.28-1.457A33 33 0 0 1 6.5 12.74v-1.478c.004-.976.012-1.207.036-1.734.029-.64.13-1.075.28-1.457.15-.4.386-.763.69-1.063.3-.305.664-.541 1.064-.691.382-.15.817-.251 1.457-.28.527-.024.758-.032 1.734-.036zm-.051 1.334H11.81c-.907.002-1.116.01-1.611.033-.555.024-.856.117-1.056.195a1.8 1.8 0 0 0-.656.426 1.8 1.8 0 0 0-.426.654c-.078.202-.17.503-.195 1.058a29 29 0 0 0-.033 1.611v1.378c.002.907.01 1.116.033 1.611.024.555.117.856.195 1.056.104.267.227.456.426.656.2.199.39.322.654.426.202.078.503.17 1.058.195.494.023.703.031 1.611.033h1.378c.908-.002 1.117-.01 1.611-.033.555-.024.856-.117 1.056-.195.248-.092.472-.237.656-.426.189-.184.334-.407.426-.654.078-.202.17-.503.195-1.058.023-.494.031-.704.033-1.611V11.31a28 28 0 0 0-.033-1.611c-.024-.555-.117-.856-.195-1.056a1.8 1.8 0 0 0-.426-.656 1.8 1.8 0 0 0-.654-.426c-.202-.078-.503-.17-1.058-.195a29 29 0 0 0-1.611-.033M12.5 8.666a3.334 3.334 0 1 1 0 6.668 3.334 3.334 0 0 1 0-6.668m0 1.334a1.999 1.999 0 0 0-1.415 3.415A2 2 0 1 0 12.5 10m3.044-1.704a.667.667 0 1 1 .08 1.331.667.667 0 0 1-.08-1.331"
      fill={fill}
    />
  </SVG>
);
