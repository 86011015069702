import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { GiftEntity } from '@core/type/api';

import { GiftAPI } from '../api/gift';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('GiftService', methodName, dto, apiCall, withContent);
};

const getGiftInformation = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  reference: string,
): Promise<GiftEntity> => {
  return log(
    'getGiftInfomation',
    { axiosInstance, language, reference },
    GiftAPI.getGiftInfomation,
  );
};

export class GiftService {
  public static readonly getGiftInformation = getGiftInformation;
}
