import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconMinus = ({ fill }: DynamicIconProps) => {
  return (
    <SVG height={14} width={14} viewBox="-1 -1 16 16" title="Minus icon">
      <path fillRule="evenodd" d="M12 6a1 1 0 0 1 0 2H2a1 1 0 1 1 0-2h10z" fill={fill} />
    </SVG>
  );
};
