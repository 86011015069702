import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconHome = ({ fill }: DynamicIconProps) => {
  return (
    <SVG height={16} width={16} viewBox="0 0 16 16" title="Home icon">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.28533 2.78546C8.2102 2.70934 8.10767 2.6665 8.00073 2.6665C7.89373 2.6665 7.79127 2.70934 7.71607 2.78546L2.89759 7.6654C2.74238 7.8226 2.74399 8.07586 2.90118 8.23106C3.05838 8.38633 3.31164 8.38473 3.46685 8.22753L4.34763 7.33553V11.4865C4.34763 11.7074 4.52671 11.8865 4.74763 11.8865H6.95607C7.177 11.8865 7.35607 11.7074 7.35607 11.4865V9.11466H8.7646V11.4865C8.7646 11.7074 8.94367 11.8865 9.1646 11.8865H11.3731C11.5939 11.8865 11.7731 11.7074 11.7731 11.4865V7.45626L12.5345 8.22753C12.6898 8.38473 12.9431 8.38633 13.1003 8.23106C13.2575 8.07586 13.2591 7.8226 13.1038 7.6654L8.28533 2.78546ZM10.9731 6.64606L8.00073 3.6358L5.14119 6.53181C5.14542 6.55512 5.14763 6.57913 5.14763 6.60365V11.0865H6.5561V8.71466C6.5561 8.49373 6.7352 8.31466 6.95607 8.31466H9.1646C9.38547 8.31466 9.5646 8.49373 9.5646 8.71466V11.0865H10.9731V6.64606Z"
        fill={fill}
      ></path>
    </SVG>
  );
};
