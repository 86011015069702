import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type {
  CreateMarketAlertEntity,
  CreateMarketAlertResponse,
  MarketAlertResponse,
} from '@core/type/api';

import { Api } from '../constants/api';
import { replaceIdUrl } from '../helpers/api';
import { configResponse, EConfigName } from '../helpers/config';

const getAlerts = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<MarketAlertResponse>> => {
  return axiosInstance.get(
    Api.marketAlert.ALERTS,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const createAlert = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & CreateMarketAlertEntity,
): Promise<AxiosResponse<CreateMarketAlertResponse>> => {
  return axiosInstance.post(
    Api.marketAlert.ALERTS,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const deleteAlert = async (
  axiosInstance: AxiosInstance,
  { language, alertId }: LanguageProps & { alertId: string },
): Promise<AxiosResponse<void>> => {
  return axiosInstance.delete(
    replaceIdUrl(Api.marketAlert.DELETE_ALERT, alertId, ':alertId'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class MarketAlertAPI {
  public static readonly getAlerts = getAlerts;
  public static readonly createAlert = createAlert;
  public static readonly deleteAlert = deleteAlert;
}
