import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { HomepageArticlesResponse } from '@core/type/api';

import { HomepageArticlesAPI } from '../api/homepage-articles';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('HomepageArticlesService', methodName, dto, apiCall, withContent);
};

const getHomepageArticles = (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<HomepageArticlesResponse> => {
  return log(
    'getHomepageArticles',
    { axiosInstance, language },
    HomepageArticlesAPI.getHomepageArticles,
  );
};

export class HomepageArticlesService {
  public static readonly getHomepageArticles = getHomepageArticles;
}
