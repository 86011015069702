import { useEffect } from 'react';

import { useContextUtil } from '@core/context';
import { useLanguage } from '@core/hook';
import { ELanguageTags } from '@core/type';

import { AxeptioScriptProps } from './interface-axeptio-script';

const LANGUAGE_2_COOKIES_VERSION = {
  [ELanguageTags.EN_US]: 'react_en',
  [ELanguageTags.FR_FR]: 'react_fr',
  [ELanguageTags.DE_DE]: 'react_de',
  [ELanguageTags.IT_IT]: 'react_it',
  [ELanguageTags.DEFAULT]: 'react_en',
};

export const AxeptioScript = ({ axeptioClientId }: AxeptioScriptProps): null => {
  const { language } = useLanguage();
  const { nonce } = useContextUtil();

  useEffect(() => {
    window.axeptioSettings = {
      clientId: axeptioClientId,
      cookiesVersion: LANGUAGE_2_COOKIES_VERSION[language],
    };

    (function (d, s) {
      const t = d.getElementsByTagName(s)[0];
      const e = d.createElement(s);

      // @ts-expect-error coment
      e.async = true;
      e.nonce = nonce;
      // @ts-expect-error coment
      e.src = 'https://static.axept.io/sdk.js';
      t.parentNode.insertBefore(e, t);
    })(document, 'script');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window._axcb) {
      /* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access */
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window._axcb.push((sdk) => {
        // here you'll be able to access the methods and properties
        // of the Axeptio SDK instance.
        sdk.setCookiesVersion(LANGUAGE_2_COOKIES_VERSION[language]);
      });
      /* eslint-enable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access */
    }
  }, [language]);

  return null;
};
