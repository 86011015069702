import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type { ForecastedDeliveryEntity, ForecastedDeliveryRequestEntity } from '@core/type/api';

import { Api } from '../constants/api';
import { configResponse, EConfigName } from '../helpers/config';

const getForecastedDelivery = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & ForecastedDeliveryRequestEntity,
): Promise<AxiosResponse<ForecastedDeliveryEntity>> => {
  return axiosInstance.post(
    Api.forecastedDelivery.FORECASTED_DELIVERY,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class ForecastedDeliveryAPI {
  public static readonly getForecastedDelivery = getForecastedDelivery;
}
