import { EColor, LinkColorHandler } from '@core/type';

import { getPaletteHandlers } from './palette';

export const getLinkColorProps: LinkColorHandler = ({ color, palette, isDisabled }) => {
  const { getTextColor } = getPaletteHandlers(palette);

  if (isDisabled) {
    const neutral = getTextColor(EColor.NEUTRAL);

    return {
      color: neutral,
      hover: {
        color: neutral,
      },
    };
  }

  if (color === EColor.SECONDARY) {
    return {
      color: getTextColor(EColor.SECONDARY),
      hover: {
        color: getTextColor({ semanticType: EColor.SECONDARY, variant: EColor.DARK }),
      },
    };
  }

  if (color === EColor.INFO) {
    return {
      color: getTextColor(EColor.INFO),
      hover: {
        color: getTextColor({ commonType: EColor.BLUE, intensity: EColor.R600 }),
      },
    };
  }

  if (color === EColor.ACCENT) {
    return {
      color: getTextColor(EColor.ACCENT),
      hover: {
        color: getTextColor({ semanticType: EColor.ACCENT, variant: EColor.DARK }),
      },
    };
  }

  if (color === EColor.NEUTRAL) {
    return {
      color: getTextColor(EColor.NEUTRAL),
      hover: {
        color: getTextColor(EColor.NEUTRAL),
      },
    };
  }

  if (color === EColor.DESTRUCTIVE) {
    return {
      color: getTextColor(EColor.DESTRUCTIVE),
      hover: {
        color: getTextColor({ semanticType: EColor.DESTRUCTIVE, variant: EColor.DARK }),
      },
    };
  }

  if (color === EColor.SUCCESS) {
    const success = getTextColor(EColor.SUCCESS);

    return {
      color: success,
      hover: {
        color: success,
      },
    };
  }

  if (color === EColor.WARNING) {
    const warning = getTextColor(EColor.WARNING);

    return {
      color: warning,
      hover: {
        color: warning,
      },
    };
  }

  if (color === EColor.DANGER) {
    const danger = getTextColor(EColor.DANGER);

    return {
      color: danger,
      hover: {
        color: danger,
      },
    };
  }

  if (color === EColor.WHITE) {
    const whiteR900 = getTextColor({ commonType: EColor.WHITE, intensity: EColor.R900 });

    return {
      color: whiteR900,
      hover: {
        color: whiteR900,
      },
    };
  }

  if (color === EColor.GRAY) {
    const whiteR600 = getTextColor({ commonType: EColor.WHITE, intensity: EColor.R600 });

    return {
      color: whiteR600,
      hover: {
        color: whiteR600,
      },
    };
  }

  return {
    color: getTextColor(EColor.PRIMARY),
    hover: {
      color: getTextColor({ semanticType: EColor.PRIMARY, variant: EColor.DARK }),
    },
  };
};
