import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconCalendar = ({ fill }: DynamicIconProps) => {
  return (
    <SVG width="24" height="24" viewBox="0 0 24 24" title="Calendar icon">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.34 7C6.06386 7 5.84 6.77614 5.84 6.5V5.50843C5.0868 5.5883 4.5 6.22562 4.5 7L4.5 10L19.5 10V7C19.5 6.22905 18.9184 5.59395 18.17 5.50953V6.5C18.17 6.77614 17.9461 7 17.67 7C17.3939 7 17.17 6.77614 17.17 6.5V5.5H12.5557V6.5C12.5557 6.77614 12.3318 7 12.0557 7C11.7795 7 11.5557 6.77614 11.5557 6.5V5.5H6.84V6.5C6.84 6.77614 6.61614 7 6.34 7ZM19.5 11L4.5 11V18C4.5 18.8284 5.17157 19.5 6 19.5H18C18.8284 19.5 19.5 18.8284 19.5 18V11ZM20.5 7C20.5 5.67642 19.4714 4.59308 18.17 4.50569V3C18.17 2.72386 17.9461 2.5 17.67 2.5C17.3939 2.5 17.17 2.72386 17.17 3V4.5H12.5557V3C12.5557 2.72386 12.3318 2.5 12.0557 2.5C11.7795 2.5 11.5557 2.72386 11.5557 3V4.5H6.84V3C6.84 2.72386 6.61614 2.5 6.34 2.5C6.06386 2.5 5.84 2.72386 5.84 3V4.50504C4.53384 4.58754 3.5 5.67303 3.5 7V18C3.5 19.3807 4.61929 20.5 6 20.5H18C19.3807 20.5 20.5 19.3807 20.5 18V7Z"
        fill={fill}
      ></path>
    </SVG>
  );
};
