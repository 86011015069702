import withTranslation from 'next-translate/withTranslation';

import { Button } from '@core/component';
import { Translate } from '@core/constant';
import { useContextAuth } from '@core/context';
import { EButtonVariant, EColor, EIcon, EPosition, ESize, WithTranslation } from '@core/type';

import { CloseDrawerFnTyp } from '../../../interface-header';

const SignOutButtonBase = ({ i18n: { t }, closeDrawer }: WithTranslation & CloseDrawerFnTyp) => {
  const { logout } = useContextAuth();

  return (
    <Button
      color={EColor.SECONDARY}
      endIcon={EIcon.ARROW_DOWN}
      iconSize={ESize.SM}
      variant={EButtonVariant.OUTLINED}
      iconOrientation={EPosition.RIGHT}
      isIconStroke
      isIconFilled
      onClick={() => {
        closeDrawer();
        void logout();
      }}
    >
      {t('menu.signOut')}
    </Button>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const SignOutButton = withTranslation(SignOutButtonBase, Translate.layout.HEADER);
