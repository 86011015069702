import { FC } from 'react';
import styled from 'styled-components';

import { Box, ButtonBase, ErrorDot, Icon, Typography } from '@core/component';
import { EActionAttribute, EColor, EIcon } from '@core/type';

import { LabelProps } from './interface-user-menu';

export const Label: FC<LabelProps> = ({ isError, fullName, onOpen }) => (
  <StyledButton onClick={onOpen}>
    {isError ? (
      <ErrorDot />
    ) : (
      <Icon color={EColor.ACCENT} type={EIcon.USER} width={'14px'} height={'14px'} />
    )}
    <Box maxWidth={'130px'} overflow={'hidden'} textOverflow={'ellipsis'} color={EColor.ACCENT}>
      <Typography as={EActionAttribute.SPAN} whiteSpace={'nowrap'}>
        {fullName}
      </Typography>
    </Box>
  </StyledButton>
);

const StyledButton = styled(ButtonBase)`
  all: unset;
  display: flex;
  gap: 8px;
  cursor: pointer;
  align-items: center;
`;
