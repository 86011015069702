import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { BannerEntity } from '@core/type/api';

import { BannerAPI } from '../api/banner';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('BannerService', methodName, dto, apiCall, withContent);
};

const getBanner = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<BannerEntity> => {
  return log('getBanner', { axiosInstance, language }, BannerAPI.getBannerInformation);
};

export class BannerService {
  public static readonly getBanner = getBanner;
}
