import { ELanguageTags } from '@core/type';

export type TrustpilotReviewUrl = {
  [key in ELanguageTags]: string;
};

export const trustpilotReviewUrl: Omit<TrustpilotReviewUrl, 'default'> = {
  [ELanguageTags.EN_US]: 'https://uk.trustpilot.com/review/goldavenue.com',
  [ELanguageTags.DE_DE]: 'https://ch.trustpilot.com/review/goldavenue.com',
  [ELanguageTags.FR_FR]: 'https://fr.trustpilot.com/review/goldavenue.com',
  [ELanguageTags.IT_IT]: 'https://it.trustpilot.com/review/goldavenue.com',
};

export const trustpilotIds = {
  TEMPLATE_ID_LONG: '54ad5defc6454f065c28af8b',
  TEMPLATE_ID_STAR: '53aa8807dec7e10d38f59f32',
  BUSINESSUNIT_ID: '5c6fd4e2966822000197f5f9',
};

export const TRUSTPILOT_WIDGET_URL = 'https://widget.trustpilot.com';
export const TRUSTPILOT_API_URL = 'https://api.trustpilot.com';

export const TRUSTPILOT_TAG = 'General';
export const TRUSTPILOT_STARS = '4,5';
