import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconPayment = ({ fill }: DynamicIconProps) => (
  <SVG width="20" height="20" viewBox="0 0 20 20" title="payment icon" fill="none">
    <circle cx="10.001" cy="10" r="7.2" fill="#fff" />
    <circle cx="10" cy="10" r="9" stroke={fill} stroke-width=".8" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.676 5.14a.54.54 0 0 0-1.08 0v.401A2.504 2.504 0 0 0 7.3 8.036c0 .814.376 1.366.9 1.743.413.297.929.491 1.395.65v2.943a1.424 1.424 0 0 1-1.215-1.408.54.54 0 1 0-1.08 0 2.504 2.504 0 0 0 2.295 2.495v.401a.54.54 0 0 0 1.08 0v-.438a2.504 2.504 0 0 0 2.025-2.458v-.024c0-.81-.379-1.357-.902-1.73-.334-.238-.736-.41-1.123-.55V6.694c.55.197.945.723.945 1.341a.54.54 0 1 0 1.08 0 2.504 2.504 0 0 0-2.025-2.457zm-1.08 1.488A1.424 1.424 0 0 0 8.38 8.036c0 .414.164.66.45.866.206.147.463.266.765.38zm1.08 4.192v2.485c.55-.197.945-.723.945-1.341v-.023c0-.406-.162-.647-.448-.851a2.7 2.7 0 0 0-.497-.27"
      fill={fill}
    />
  </SVG>
);
