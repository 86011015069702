import { FC } from 'react';
import styled, { css, CSSObject } from 'styled-components';

import { EActionAttribute, EColor, WithThemeProps } from '@core/type';
import { getPaletteHandlers } from '@core/util';

import { Box } from '../layout/Box';
import { LineProps } from './interface-line';

export const Line = (props: LineProps) => {
  const { isVertical, ...restStyles } = props;

  return (
    <StyledLine
      as={isVertical ? EActionAttribute.DIV : EActionAttribute.HR}
      isVertical={isVertical}
      {...restStyles}
    />
  );
};

export const StyledLine: FC<LineProps> = styled(Box)((props: LineProps & WithThemeProps) => {
  const {
    theme: {
      palette,
      structure: {
        line: {
          vertical: lineVertical,
          horizontal: lineHorizontal,
          isFullWidth: { base: fullWidthBaseStyles, before: fullWidthBeforeStyles },
        },
      },
    },
    as,
    onClick,
    animation,
    color = { commonType: EColor.GRAY, intensity: EColor.A200 },
    isVertical,
    isFullWidth,
    ...restStyles
  } = props;
  const { getBackgroundColor } = getPaletteHandlers(palette);
  const lineColor = getBackgroundColor(color);

  return css`
    background: ${lineColor};
    ${(isVertical ? lineVertical : lineHorizontal) as CSSObject};
    ${isFullWidth && (fullWidthBaseStyles as CSSObject)};
    ${restStyles as CSSObject};

    &:before {
      ${isFullWidth && (fullWidthBeforeStyles as CSSObject)};
      background: ${lineColor};
    }
  `;
});
