import { FC, LazyExoticComponent, ReactNode, Suspense } from 'react';

import { toastContext, useContextToast } from '@core/context';
import { ToastPosition } from '@core/type';
import {
  ToastHook,
  ToastItem,
  ToastObject,
  ToastPositionProps,
  ToastState,
} from '@core/type/context';

import { storeHook } from '../helpers';
import { useToast } from '../hook';

function ToastChildren<ComponentProps extends ToastItem<ToastObject>>({
  children,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Component,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Wrapper,
  placement,
}: {
  children?: ReactNode;
  placement?: ToastPosition;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Wrapper: FC<any>;
  Component: LazyExoticComponent<FC<ComponentProps>>;
}) {
  const { toasts, clearId } = useContextToast();

  return (
    <>
      {children}
      {!!toasts?.length && (
        <Suspense>
          <Wrapper placement={placement}>
            {toasts.map(({ id, ...toastProps }) => (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              <Component
                {...toastProps}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                id={id}
                placement={placement}
                clearId={clearId}
                key={id as string}
              />
            ))}
          </Wrapper>
        </Suspense>
      )}
    </>
  );
}

export function ProviderToast<
  WrapperProps extends ToastPositionProps,
  ComponentProps extends ToastItem<ToastObject>,
>(props: {
  children?: ReactNode;
  placement?: ToastPosition;
  Wrapper: FC<WrapperProps>;
  Component: LazyExoticComponent<FC<ToastItem<ComponentProps>>>;
}) {
  const hooks = storeHook<
    ToastState<ToastItem<ComponentProps>>,
    ToastHook<ToastItem<ComponentProps>>
  >(
    {
      toasts: [],
    },
    useToast,
  );

  return (
    <toastContext.Provider value={hooks}>
      <ToastChildren {...props} />
    </toastContext.Provider>
  );
}
