import { EZIndexName } from '@core/type';

import { Box } from '../layout/Box';
import { DropdownCloseAreaProps } from './interface-dropdown';

export const DropdownCloseArea = ({
  children: _children,
  isOpen,
  onClose,
  ...restProps
}: DropdownCloseAreaProps) =>
  isOpen && (
    <Box
      position={'fixed'}
      top={0}
      left={0}
      width={'100vw'}
      height={'100vh'}
      {...restProps}
      zIndex={EZIndexName.DROPDOWN}
      onClick={onClose}
    />
  );
