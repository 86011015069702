import { ERouting, SkeletonTypeRoutes } from '@core/type';

import { SkeletonAlerts } from './SkeletonAlerts';
import { SkeletonAutoSavings } from './SkeletonAutoSavings';
import { SkeletonAutoSavingsShow } from './SkeletonAutoSavingsShow';
import { SkeletonBlogArticle } from './SkeletonBlogArticle';
import { SkeletonBlogList } from './SkeletonBlogList';
import { SkeletonCart } from './SkeletonCart';
import { SkeletonFaq } from './SkeletonFaq';
import { SkeletonFaqSearch } from './SkeletonFaqSearch';
import { SkeletonFaqShow } from './SkeletonFaqShow';
import { SkeletonHomepage } from './SkeletonHomepage';
import { SkeletonPayment } from './SkeletonPayment';
import { SkeletonPmg } from './SkeletonPmg';
import { SkeletonProductShow } from './SkeletonProductShow';
import { SkeletonReferrals } from './SkeletonReferrals';
import { SkeletonShop } from './SkeletonShop';
import { SkeletonShopSubcategory } from './SkeletonShopSubcategory';
import { SkeletonStorageFees } from './SkeletonStorageFees';
import { SkeletonTutorialVideo } from './SkeletonTutorialVideo';
import { SkeletonTutorialVideos } from './SkeletonTutorialVideos';
import { SkeletonWishlist } from './SkeletonWishlist';

export const routeSkeleton: SkeletonTypeRoutes = {
  [ERouting.HOME_BUY]: <SkeletonShop />,
  [ERouting.BUY]: <SkeletonShop />,
  [ERouting.SHOP_SUBCATEGORY]: <SkeletonShopSubcategory />,
  [ERouting.PRODUCT_SHOW]: <SkeletonProductShow />,
  [ERouting.HOME]: <SkeletonHomepage />,
  [ERouting.BLOG_ARTICLE]: <SkeletonBlogArticle />,
  [ERouting.BLOG_CATEGORY]: <SkeletonBlogList />,
  [ERouting.BLOG_HOME]: <SkeletonBlogList />,
  [ERouting.BLOG_SEARCH]: <SkeletonBlogList />,
  [ERouting.BLOG_TAG]: <SkeletonBlogList />,
  [ERouting.PRECIOUS_METAL_GUIDE]: <SkeletonPmg />,
  [ERouting.PRECIOUS_METAL_GUIDE_LEVEL1]: <SkeletonPmg />,
  [ERouting.PRECIOUS_METAL_GUIDE_LEVEL2]: <SkeletonPmg />,
  [ERouting.PRECIOUS_METAL_GUIDE_LEVEL3]: <SkeletonPmg />,
  [ERouting.FAQ]: <SkeletonFaq />,
  [ERouting.FAQ_SHOW]: <SkeletonFaqShow />,
  [ERouting.FAQ_SEARCH]: <SkeletonFaqSearch />,
  [ERouting.TUTORIAL_VIDEO]: <SkeletonTutorialVideo />,
  [ERouting.TUTORIAL_VIDEOS]: <SkeletonTutorialVideos />,
  [ERouting.REFERRALS]: <SkeletonReferrals />,
  [ERouting.WISHLIST]: <SkeletonWishlist />,
  [ERouting.ALERT_SETTINGS]: <SkeletonAlerts />,
  [ERouting.PAYMENTS]: <SkeletonPayment />,
  [ERouting.CART]: <SkeletonCart />,
  [ERouting.WALLET_AUTO_SAVINGS]: <SkeletonAutoSavings />,
  [ERouting.WALLET_AUTO_SAVINGS_SHOW]: <SkeletonAutoSavingsShow />,
  [ERouting.STORAGE_FEES]: <SkeletonStorageFees />,
};
