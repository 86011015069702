import { EEditor, WIthInnerHTMLProps } from '@core/type';

import { BoxProps } from '../layout/interface-layout';

export type EditorContentProps = BoxProps & {
  content: string;
  type?: EEditor;
};

export type EditorDefaultContentProps = WIthInnerHTMLProps & {
  isPhone?: boolean;
  isMobile?: boolean;
};

export enum EEditorClassName {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  BTN = 'btn',
  LS_MINUS_03 = 'ls--03',
  LS_03 = 'ls-03',
  LS_05 = 'ls-05',
  LS_1 = 'ls-1',
  LS_2 = 'ls-2',
  FW_300 = 'fw-300',
  FW_400 = 'fw-400',
  FW_500 = 'fw-500',
  SMALL_TEXT = 'small-text',
  SMALLER_TEXT = 'smaller-text',
  HIGHLIGHTED_BLUE_LINE = 'highlighted-blue-line',
  HIGHLIGHTED_BLUE_BG = 'highlighted-blue-bg',
}
