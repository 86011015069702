import { EActionAttribute, EColor } from '@core/type';

import { Box, BoxProps } from '../layout';

export const ErrorDot = ({ ...props }: BoxProps) => (
  <Box
    {...props}
    as={EActionAttribute.SPAN}
    backgroundColor={EColor.DANGER}
    display={'inline-block'}
    width={'10px'}
    height={'10px'}
    borderRadius={'50%'}
  />
);
