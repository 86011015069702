/* eslint-disable @typescript-eslint/no-magic-numbers */
import { useContextMediaQuery } from '@core/context';

import { Box } from '../layout';
import { Line } from '../line';
import { SkeletonTypography } from './SkeletonTypography';
import { SkeletonWrapper } from './SkeletonWrapper';
import { FaqHeader } from './components';

export const SkeletonFaqSearch = () => {
  const { isPhone, isMobile } = useContextMediaQuery();

  return (
    <>
      <FaqHeader />

      <SkeletonWrapper marginTop={'66px'}>
        <SkeletonTypography width={'320px'} marginBottom={'44px'} />

        {[...Array(isMobile ? 4 : 6).keys()].map((index) => (
          <Box key={index}>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              padding={isMobile ? '20px 0' : '40px 0'}
              marginBottom={isPhone ? undefined : '6px'}
              gap={'40px'}
            >
              <Box width={'100%'}>
                <SkeletonTypography
                  lineHeight={isPhone ? '56px' : '28px'}
                  maxWidth={isPhone ? '100%' : '440px'}
                />
              </Box>

              <SkeletonTypography lineHeight={'24px'} width={'24px'} />
            </Box>

            <Line />
          </Box>
        ))}
      </SkeletonWrapper>
    </>
  );
};
