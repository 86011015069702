import Image from 'next/image';

import IconSMSSvg from './svgs/icon-sms.svg';

export const IconSMS = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconSMSSvg}
    alt="SMS icon"
    width={32}
    height={32}
  />
);
