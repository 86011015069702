import withTranslation from 'next-translate/withTranslation';
import NextLink from 'next/link';
import { memo } from 'react';

import { Box, DrawerSub, IconCurrency, Link, Typography } from '@core/component';
import { EDrawerLevel } from '@core/component/interface';
import { Translate } from '@core/constant';
import { useContextCurrency } from '@core/context';
import { useFullUrl } from '@core/hook';
import { EActionAttribute, EColor, ECurrency, EPosition, WithTranslation } from '@core/type';
import { DEFAULT_CURRENCY, getCurrencyLinkHref, getIsoCurrency } from '@core/util';

import { getEnvironmentPublic } from '../../helpers';
import { CurrencyDrawerLineProps } from './interface-header';

const CurrencyDrawerLineContainer = ({
  i18n: { t },
  drawerLevelProps,
  handleDrawerLevelProps,
  shouldShowCurrencyLink,
}: WithTranslation & CurrencyDrawerLineProps) => {
  const { host } = getEnvironmentPublic();
  const { fullUrl } = useFullUrl(host);

  const { currency, changeCurrency } = useContextCurrency();

  const handleCurrency = (currencySelected: ECurrency) => {
    const cur = getIsoCurrency(currencySelected, DEFAULT_CURRENCY);
    changeCurrency(cur);
    handleDrawerLevelProps({ [EDrawerLevel.CURRENCY]: false });
  };

  return (
    <Box as={EActionAttribute.LI} padding="8px 16px 8px 40px">
      <DrawerSub
        drawerLevel={EDrawerLevel.CURRENCY}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={handleDrawerLevelProps}
        position={EPosition.LEFT}
        title={currency.toUpperCase()}
        listTitle={
          <Box display={'flex'} alignItems={'center'}>
            <IconCurrency currency={currency} />
            <Box marginLeft={'8px'}>
              <Typography whiteSpace={'nowrap'}>{currency.toUpperCase()}</Typography>
            </Box>
          </Box>
        }
      >
        {Object.values(ECurrency).map((cur) => {
          const href = shouldShowCurrencyLink ? getCurrencyLinkHref(fullUrl, cur) : null;

          return (
            <Box
              as={EActionAttribute.LI}
              padding="8px 16px 8px 40px"
              marginBottom="16px"
              backgroundColor={cur === currency ? EColor.SECONDARY : undefined}
              onClick={() => handleCurrency(cur)}
              key={cur}
            >
              <Box display={'flex'} alignItems={'center'}>
                {href ? (
                  <NextLink legacyBehavior href={href} passHref>
                    <Link display={'flex'} alignItems={'center'}>
                      <IconCurrency currency={cur} />
                      <Box marginLeft={'8px'}>
                        <Typography color={EColor.PRIMARY} whiteSpace={'nowrap'}>
                          {t(cur)} - {cur.toUpperCase()}
                        </Typography>
                      </Box>
                    </Link>
                  </NextLink>
                ) : (
                  <>
                    <IconCurrency currency={cur} />
                    <Box marginLeft={'8px'}>
                      <Typography color={EColor.PRIMARY} whiteSpace={'nowrap'}>
                        {t(cur)} - {cur.toUpperCase()}
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          );
        })}
      </DrawerSub>
    </Box>
  );
};

export const CurrencyDrawerLine = memo(
  withTranslation(CurrencyDrawerLineContainer, Translate.common.CURRENCY),
);
