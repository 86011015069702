import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { SpotPriceEntity } from '@core/type/api';

import { PriceAPI } from '../api/price';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('PriceService', methodName, dto, apiCall, withContent);
};

const getSpotPrices = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<{ spotPrices: SpotPriceEntity[] }> => {
  return log('spot_rices', { axiosInstance, language }, PriceAPI.getSpotPrices);
};

export class PriceService {
  public static readonly getSpotPrices = getSpotPrices;
}
