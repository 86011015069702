import { createGlobalStyle } from 'styled-components';

import { zIndexes } from '@core/constant';
import { EZIndexName } from '@core/type';

export const CrispStyle = createGlobalStyle`
  body .crisp-client {
    position: relative;
    z-index: ${zIndexes[EZIndexName.MODAL]};

    .cc-52lo .cc-kegp {
      .cc-1apq {
        z-index: 3 !important;
      }

      .cc-1oun {
        bottom: 28px !important;

        &[data-maximized=true],
        &[data-animate-maximize=true],
        &[data-animate-minimize=true] {
          display: block !important;
          animation-name: none !important;
        }
      }
    }
  }
`;
