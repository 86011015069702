export enum EFontWeight {
  LIGHT = 'light',
  REGULAR = 'regular',
  MEDIUM = 'medium',
  BOLD = 'bold',
  HEAVY = 'heavy',
}

export enum EFontFormat {
  WOFF = 'woff',
  WOFF2 = 'woff2',
}
