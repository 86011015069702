import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconCart = ({ fill }: DynamicIconProps) => (
  <SVG width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.129.33A.75.75 0 0 1 1.17.129l1.808 1.224a.75.75 0 0 1 .304.426L6.75 14.683h15.54l2.04-8.288H6.806a.75.75 0 1 1 0-1.5h18.483a.75.75 0 0 1 .728.929l-2.41 9.789a.75.75 0 0 1-.729.57H6.602a1.696 1.696 0 0 0-1.656 1.697c0 .939.76 1.698 1.694 1.698h.336c.455-1.343 1.71-2.309 3.186-2.309 1.477 0 2.732.966 3.186 2.309h3.873c.454-1.343 1.71-2.309 3.186-2.309 1.86 0 3.368 1.531 3.368 3.42 0 1.887-1.508 3.418-3.368 3.418-1.73 0-3.155-1.324-3.346-3.03h-3.553c-.19 1.706-1.616 3.03-3.346 3.03s-3.155-1.325-3.346-3.03H6.64a3.198 3.198 0 0 1-1.363-6.09L1.907 2.439.33 1.371A.75.75 0 0 1 .129.33m20.278 22.277c1.01 0 1.868-.837 1.868-1.919 0-1.081-.858-1.919-1.868-1.919s-1.867.838-1.867 1.92c0 1.08.857 1.918 1.867 1.918m-8.377-1.919c0 1.082-.858 1.92-1.868 1.92s-1.867-.838-1.867-1.92.857-1.919 1.867-1.919 1.868.838 1.868 1.92"
      fill={fill}
    />
  </SVG>
);
