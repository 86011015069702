export enum EPosition {
  TOP_LEFT = 'topLeft',
  TOP = 'top',
  TOP_RIGHT = 'topRight',
  RIGHT = 'right',
  BOTTOM_RIGHT = 'bottomRight',
  BOTTOM = 'bottom',
  BOTTOM_LEFT = 'bottomLeft',
  LEFT = 'left',
  CENTER = 'center',
}

export type PositionType<Position extends Partial<EPosition>> = {
  [key in Position]: Position;
};
