import { Dispatch, SetStateAction } from 'react';

import { Box } from '@core/component';

import { HeaderCart } from './HeaderCart';
import { HeaderSearch } from './HeaderSearch';

export const HeaderCartSearch = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} position="relative">
      <HeaderSearch isOpen={isOpen} setIsOpen={setIsOpen} />
      <HeaderCart />
    </Box>
  );
};
