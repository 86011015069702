import { useContextMediaQuery } from '@core/context';
import { ETypographyVariant } from '@core/type';

import { Box } from '../layout';
import { Line } from '../line';
import { Paper } from '../paper';
import { SkeletonTypography } from './SkeletonTypography';
import { SkeletonWrapper } from './SkeletonWrapper';

export const SkeletonAutoSavingsShow = () => {
  const { isPhone, isMobile } = useContextMediaQuery();

  return (
    <SkeletonWrapper paddingTop={'64px'} overflow={'visible'}>
      <Box
        display={'flex'}
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent={'space-between'}
        marginBottom={isPhone ? '48px' : '40px'}
        gap={'24px'}
      >
        <Box>
          <SkeletonTypography
            variant={ETypographyVariant.H2}
            width={isMobile ? '290px' : '390px'}
            marginBottom={'16px'}
          />

          <SkeletonTypography lineHeight={'25px'} width={'280px'} />
        </Box>

        <Box display={'flex'} gap={'16px'}>
          <SkeletonTypography lineHeight={'48px'} width={'110px'} borderRadius={'8px'} />
          <SkeletonTypography lineHeight={'48px'} width={'110px'} borderRadius={'8px'} />
        </Box>
      </Box>

      {isPhone && (
        <>
          <Line marginBottom={'48px'} />
          <SkeletonTypography lineHeight={'22px'} width={'140px'} marginBottom={'48px'} />
        </>
      )}

      <Paper padding={isPhone ? '0' : isMobile ? '56px 24px' : '40px'} hasElevation={!isMobile}>
        {isMobile ? (
          <>
            <SkeletonTypography lineHeight={'54px'} maxWidth={'360px'} marginBottom={'24px'} />
            <SkeletonTypography lineHeight={'28px'} width={'180px'} marginBottom={'16px'} />
            <SkeletonTypography lineHeight={'28px'} width={'220px'} marginBottom={'16px'} />
            <SkeletonTypography lineHeight={'28px'} width={'160px'} />
          </>
        ) : (
          <>
            <SkeletonTypography lineHeight={'21px'} />
            <Line margin={'12px 0 24px'} />
            <SkeletonTypography lineHeight={'48px'} />
            <Line marginTop={'24px'} />
          </>
        )}
      </Paper>

      {!isMobile && (
        <>
          <SkeletonTypography width={'150px'} margin={'48px 0 24px'} />

          <Paper padding={'40px'} hasElevation>
            <SkeletonTypography marginBottom={'24px'} />
            <SkeletonTypography />
          </Paper>
        </>
      )}
    </SkeletonWrapper>
  );
};
