import Image from 'next/image';

import IconSellSvg from './svgs/icon-sell.svg';

export const IconSell = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconSellSvg}
    alt="Sell icon"
    width={64}
    height={64}
  />
);
