import type { AxiosInstance, AxiosResponse } from 'axios';

import { WidgetStorage } from '@core/storage';
import { ELanguageTags, EStorageType } from '@core/type';
import type {
  PatchPageRatingEntity,
  SpotPriceCaratsRequest,
  SpotPriceCaratsResponse,
  SpotPriceCurrenciesRequest,
  SpotPriceCurrenciesResponse,
  SpotPriceHistoryRequest,
  SpotPriceHistoryResponse,
  SpotPriceWeightsRequest,
  SpotPriceWeightsResponse,
} from '@core/type/api';
import { PageRatingRequest, PageRatingResponse } from '@core/type/api';

import { WidgetAPI } from '../api/widget';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & {
    axiosInstance: AxiosInstance;
  },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('WidgetService', methodName, dto, apiCall, withContent);
};

const getSpotPriceHistory = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: SpotPriceHistoryRequest,
): Promise<SpotPriceHistoryResponse> => {
  return log(
    'getSpotPriceHistory',
    { axiosInstance, language, ...data },
    WidgetAPI.getSpotPriceHistory,
  );
};

const getSpotPriceCarats = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: SpotPriceCaratsRequest,
): Promise<SpotPriceCaratsResponse> =>
  log('getSpotPriceCarats', { axiosInstance, language, ...data }, WidgetAPI.getSpotPriceCarats);

const getSpotPriceCurrencies = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: SpotPriceCurrenciesRequest,
): Promise<SpotPriceCurrenciesResponse> =>
  log(
    'getSpotPriceCurrencies',
    { axiosInstance, language, ...data },
    WidgetAPI.getSpotPriceCurrencies,
  );

const getSpotPriceWeights = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: SpotPriceWeightsRequest,
): Promise<SpotPriceWeightsResponse> =>
  log(
    'getSpotPriceCurrencies',
    { axiosInstance, language, ...data },
    WidgetAPI.getSpotPriceWeights,
  );

const getPageRating = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: PageRatingRequest,
): Promise<PageRatingResponse> =>
  log('getPageRating', { axiosInstance, language, ...data }, WidgetAPI.getPageRating);

const patchPageRating = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: PatchPageRatingEntity,
): Promise<void> => {
  return log('patchPageRating', { axiosInstance, language, ...data }, WidgetAPI.patchPageRating);
};

const setRatedPageState = (pageId: string) => {
  WidgetStorage.setRatedPageState(EStorageType.COOKIE, pageId);
};

const getRatedPageState = (pageId: string) => {
  return WidgetStorage.getRatedPageState(EStorageType.COOKIE, pageId);
};

const setMobileAppPromotionBannerState = () => {
  WidgetStorage.setMobileAppPromotionBannerState(EStorageType.COOKIE);
};

const getMobileAppPromotionBannerState = () => {
  return WidgetStorage.getMobileAppPromotionBannerState(EStorageType.COOKIE);
};

export class WidgetService {
  public static readonly getSpotPriceHistory = getSpotPriceHistory;
  public static readonly getSpotPriceCarats = getSpotPriceCarats;
  public static readonly getSpotPriceCurrencies = getSpotPriceCurrencies;
  public static readonly getSpotPriceWeights = getSpotPriceWeights;
  public static readonly getPageRating = getPageRating;
  public static readonly patchPageRating = patchPageRating;
  public static readonly setRatedPageState = setRatedPageState;
  public static readonly getRatedPageState = getRatedPageState;
  public static readonly setMobileAppPromotionBannerState = setMobileAppPromotionBannerState;
  public static readonly getMobileAppPromotionBannerState = getMobileAppPromotionBannerState;
}
