import Script from 'next/script';

export const GTMNextScript = ({
  gaTrackingId,
  nonce,
  setIsScriptGALoaded,
}: {
  gaTrackingId: string;
  nonce: string;
  setIsScriptGALoaded: (isScriptGALoaded?: boolean) => void;
}): JSX.Element => {
  return gaTrackingId && nonce ? (
    <Script
      id="gtag-init"
      strategy="afterInteractive"
      nonce={nonce}
      onReady={() => setIsScriptGALoaded(true)}
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');
n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gaTrackingId}');`,
      }}
    />
  ) : null;
};
