import { SVG } from '../SVG';
import { DynamicIconProps } from '../interface-icon';

export const IconLinkedIn = ({ fill }: DynamicIconProps) => (
  <SVG height={24} width={25} viewBox="0 0 25 24" title="LinkedIn icon">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.38 16.8h-2.626v-3.797c0-.993-.41-1.671-1.314-1.671-.69 0-1.075.457-1.254.898-.067.159-.056.38-.056.6v3.97h-2.602s.034-6.725 0-7.337h2.602v1.152c.154-.504.984-1.223 2.311-1.223 1.647 0 2.939 1.056 2.939 3.328zM7.898 8.545h-.017c-.837 0-1.381-.56-1.381-1.272C6.5 6.551 7.06 6 7.915 6c.854 0 1.38.547 1.395 1.272 0 .71-.54 1.273-1.412 1.273M6.8 9.463h2.316V16.8H6.8z"
      fill={fill}
    />
  </SVG>
);
