import { useStoreMeta } from '@core/context';
import { Price } from '@core/type';
import { MetaEntity } from '@core/type/api';
import { HookStoreFn, MetaHook, MetaState } from '@core/type/context';

export const useMeta: HookStoreFn<MetaState, MetaHook> = (): MetaHook => {
  const [meta, setMeta] = useStoreMeta<MetaEntity>('meta');
  const [priceProduct, setPriceProduct] = useStoreMeta<Price>('priceProduct');

  return {
    meta,
    setMeta,
    priceProduct,
    setPriceProduct,
  };
};
