import { SVG } from '../SVG';
import { DynamicIconProps } from '../interface-icon';

export const IconEmail = ({ fill }: DynamicIconProps) => (
  <SVG height={12} width={16} viewBox="0 0 16 12" title="Email icon">
    <path
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.62.375H2.37a1.41 1.41 0 0 0-1.278.82.6.6 0 0 1 .215.095l6.687 4.68 6.687-4.68a.6.6 0 0 1 .216-.095 1.41 1.41 0 0 0-1.278-.82m1.405 2.139-6.687 4.68a.6.6 0 0 1-.688 0L.963 2.515v7.705a1.41 1.41 0 0 0 1.406 1.406h11.25a1.41 1.41 0 0 0 1.406-1.406z"
    />
  </SVG>
);
