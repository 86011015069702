import { useContextRouting, useStoreBreadcrumb } from '@core/context';
import { BreadcrumbObject } from '@core/type';
import { BreadcrumbEntity } from '@core/type/api';
import { BreadcrumbHook, BreadcrumbState, HookStoreFn } from '@core/type/context';
import { buildBreadcrumb, getTypeRoute } from '@core/util';

export const useBreadcrumb: HookStoreFn<BreadcrumbState, BreadcrumbHook> = (): BreadcrumbHook => {
  const [breadcrumbs, setBreadcrumbsStore] = useStoreBreadcrumb<BreadcrumbObject[]>('breadcrumbs');
  const { keyRoute } = useContextRouting();

  const setBreadcrumbs = (breadcrumbs?: BreadcrumbEntity[]) => {
    setBreadcrumbsStore(
      buildBreadcrumb({
        keyRoute,
        breadcrumbs: getTypeRoute(keyRoute)?.breadcrumb,
        dynamicsBreadcrumbs: breadcrumbs,
      }),
    );
  };

  return {
    breadcrumbs,
    setBreadcrumbs,
  };
};
