/* eslint-disable @typescript-eslint/restrict-template-expressions */
import NextLink, { LinkProps } from 'next/link';
import { MouseEventHandler, forwardRef } from 'react';
import styled, { CSSObject, css, useTheme } from 'styled-components';
import type { UrlObject } from 'url';

import { zIndexes } from '@core/constant';
import {
  EActionAttribute,
  EButtonVariant,
  EColor,
  EFontWeight,
  ESize,
  StyleRuleDisplay,
  ThemeProps,
  WithThemeProps,
} from '@core/type';
import { getButtonColorProps, getPaletteHandlers } from '@core/util';

import { ButtonBaseProps, ButtonProps, StyledButtonProps } from '../button/interface-button';
import { Icon } from '../icon/Icon';
import { Typography } from '../typography';

type LinkStyledButtonProps = Omit<StyledButtonProps, 'as' | 'onClick'> & {
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

type LinkButtonProps = Omit<ButtonProps, 'as' | 'onClick'> & {
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

type LinkButtonBaseProps = Omit<ButtonBaseProps, 'as' | 'onClick'> & {
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

type WithForwardedAs<Props = unknown> = Props & {
  forwardedAs?: UrlObject | string;
};

export const NextLinkStyledAsButton = forwardRef<
  HTMLAnchorElement,
  WithForwardedAs<LinkButtonProps> & LinkProps
>((props, ref) => {
  const {
    structure: {
      button: {
        icon: { indent, size },
      },
    },
  } = useTheme() as ThemeProps;

  const {
    text,
    isLoading,
    hiddenIcon,
    colorIcon,
    endIcon,
    startIcon,
    iconSize = size,
    zIndex,
    children,
    iconOrientation,
    forwardedAs,
    as,
    ...restProps
  } = props;

  const additionalProps: Record<string, UrlObject | string> = {};
  if (forwardedAs && typeof forwardedAs === 'string') {
    additionalProps.forwardedAs = forwardedAs;
  }

  const hasIcon = !!startIcon || !!endIcon;

  return (
    <StyledButton
      {...restProps}
      zIndex={zIndex}
      hasIcon={hasIcon}
      ref={ref}
      colorIcon={colorIcon}
      {...additionalProps}
    >
      {startIcon && (
        <Icon
          type={startIcon}
          size={iconSize}
          marginRight={indent}
          isLoading={isLoading}
          color={colorIcon}
          hidden={hiddenIcon}
          orientation={iconOrientation}
        />
      )}
      <Typography as={EActionAttribute.SPAN}>{children}</Typography>
      {endIcon && (
        <Icon
          type={endIcon}
          size={iconSize}
          marginLeft={indent}
          isLoading={isLoading}
          color={colorIcon}
          hidden={hiddenIcon}
          orientation={iconOrientation}
        />
      )}
    </StyledButton>
  );
});
NextLinkStyledAsButton.displayName = 'Button';

const FILTER_PROPS = [
  'zIndex',
  'isUnderlined',
  'isUnderlinedHover',
  'isChangingColorHover',
  'hiddenTextOnMobile',
  'isIconFill',
  'isIconStroke',
  'color',
  'colorIcon',
  'animation',
  'hasIcon',
  'textTransform',
  'top',
  'hoverColor',
  'bottom',
  'left',
  'right',
  'letterSpacing',
  'variant',
  'fontSize',
  'fontWeight',
  'lineHeight',
  'enableAnimation',
  'alignItems',
  'textAlign',
  'wordBreak',
  'shallow',
];

const ButtonBase = styled(NextLink).withConfig({
  shouldForwardProp: (prop) => !FILTER_PROPS.includes(prop),
})<WithForwardedAs<LinkButtonBaseProps> & LinkProps>(
  (props: LinkButtonBaseProps & WithThemeProps) => {
    const {
      theme: {
        structure: {
          button: {
            base,
            disabled: { cursor },
          },
        },
      },
      disabled,
      autoFocus,
      text,
      href,
      target,
      type,
      name,
      form,
      role,
      tabindex,
      onClick,
      children,
      zIndex: zIndexProps,
      ...restProps
    } = props;

    const disabledRules = {
      cursor,
      pointerEvents: 'none',
    } as CSSObject;

    const zIndex = zIndexProps && zIndexes[zIndexProps];

    return css`
      ${base as CSSObject};
      ${disabled && disabledRules};
      ${{ ...restProps, zIndex } as CSSObject};
    `;
  },
);

const StyledButton = styled(ButtonBase)<LinkProps & WithForwardedAs<LinkStyledButtonProps>>`
  ${(props: LinkStyledButtonProps & WithThemeProps) => {
    const {
      theme: {
        palette,
        typography: {
          fontWeight: fontWeightStructure,
          button: { size: typographySize },
        },
        structure: {
          button: {
            size: structureSize,
            icon: { indent, size, ...restIconRules },
          },
        },
      },
      disabled,
      hasIcon,
      colorIcon: colorIconProps,
      isFullWidth = false,
      isTransparent = true,
      variant = EButtonVariant.CONTAINED,
      color: colorProps = EColor.PRIMARY,
      size: sizeProps = ESize.MD,
      padding,
      width: widthProps,
      isIconFilled = true,
      isIconStroke = false,
      lineHeight: lineHeightProps,
      fontWeight: fontWeightProps,
      canOverrideTypography,
    } = props;
    const { getColor, getTextColor } = getPaletteHandlers(palette);

    const width = widthProps || isFullWidth ? '100%' : 'auto';
    const display: StyleRuleDisplay = hasIcon ? 'inline-flex' : 'inline-block';

    const { color, hover, ...restPaletteRules } = getButtonColorProps({
      variant,
      color: colorProps,
      isDisabled: disabled,
      isTransparent,
      palette,
    });

    const colorIcon = getTextColor(colorIconProps);

    const {
      fontWeight: fontWeightTypography,
      lineHeight: lineHeightTheme,
      fontSize,
      ...restTypographyRules
    } = typographySize[sizeProps];
    const fontWeight =
      fontWeightStructure[
        fontWeightProps || (sizeProps === ESize.SM && variant === EButtonVariant.TEXT)
          ? EFontWeight.REGULAR
          : fontWeightTypography
      ];
    const lineHeight = lineHeightProps ? lineHeightProps : lineHeightTheme;

    const sizeRules = structureSize[sizeProps];

    let rules = {
      display,
      width,
      color,
      ...sizeRules,
      ...restPaletteRules,
    } as CSSObject;

    if (padding) {
      rules = { ...rules, padding };
    }

    let typographyStyle: string = null;
    if (!canOverrideTypography) {
      typographyStyle = `
        ${Typography.toString()} {
          font-size: ${fontSize};
          font-weight: ${fontWeight};
          line-height: ${lineHeight};
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      `;
    }

    return css`
      ${rules};
      ${typographyStyle};

      text-align: center;
      align-items: center;
      justify-content: center;

      ${Typography.toString()} {
        ${restTypographyRules};
      }

      ${hasIcon &&
      `path {
          fill: ${isIconFilled ? colorIcon ?? color : getColor(EColor.TRANSPARENT)};
          stroke: ${isIconStroke ? colorIcon ?? color : getColor(EColor.TRANSPARENT)};
          ${/* eslint-disable @typescript-eslint/no-base-to-string */ restIconRules as CSSObject};
        }`}

      &:hover {
        ${hover as CSSObject};
      }

      &:hover path {
        fill: ${isIconFilled ? hover?.color : null};
        stroke: ${isIconStroke ? hover?.color : null};
        ${restIconRules as CSSObject};
      }
    `;
  }}
`;
