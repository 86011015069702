var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1.00"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

if (getConfig && getConfig() && getConfig().publicRuntimeConfig) {
  const { publicRuntimeConfig } = getConfig();
  const sentryDsn = publicRuntimeConfig.sentryDsn || publicRuntimeConfig.nextPublicSentryDsn;

  if (publicRuntimeConfig.enableSentry) {
    const { enableSentry, sentryDebug, sentryTracesSampleRate, sentrySampleRate, env } =
      publicRuntimeConfig;
    Sentry.init({
      dsn: sentryDsn,
      beforeSend: (event) => {
        if (event.level === 'error' || event.level === 'fatal' || event.level === 'warning') {
          return event;
        }
        return null;
      },
      tracesSampleRate: sentryTracesSampleRate,
      debug: sentryDebug,
      enabled: enableSentry,
      sampleRate: sentrySampleRate,
      environment: env,
    });
  }
}
