import { EColorName } from '@core/type';

import { SVG } from './SVG';

export const IconSmartphone = () => {
  return (
    <SVG height={32} width={32} viewBox="0 0 32 32" title="Smartphone icon">
      <path
        d="M19.6346 3H10.1154C8.9471 3 8 3.92464 8 5.06524V24.6848C8 25.8254 8.9471 26.75 10.1154 26.75H19.6346C20.8029 26.75 21.75 25.8254 21.75 24.6848V5.06524C21.75 3.92464 20.8029 3 19.6346 3Z"
        fill={EColorName.ALICE_BLUE}
      ></path>
      <path
        d="M21.9423 4.25H11.5577C10.2832 4.25 9.25 5.22331 9.25 6.42394V27.0761C9.25 28.2767 10.2832 29.25 11.5577 29.25H21.9423C23.2168 29.25 24.25 28.2767 24.25 27.0761V6.42394C24.25 5.22331 23.2168 4.25 21.9423 4.25Z"
        stroke={EColorName.OXFORD_BLUE}
        strokeWidth="1.2"
      ></path>
      <path
        d="M21.9423 4.25H11.5577C10.2832 4.25 9.25 5.22331 9.25 6.42394V27.0761C9.25 28.2767 10.2832 29.25 11.5577 29.25H21.9423C23.2168 29.25 24.25 28.2767 24.25 27.0761V6.42394C24.25 5.22331 23.2168 4.25 21.9423 4.25Z"
        stroke={EColorName.OXFORD_BLUE}
        strokeWidth="1.2"
      ></path>
      <path
        d="M19.3592 5.88086H14.7396C14.5145 5.88086 14.332 6.06335 14.332 6.28847C14.332 6.51358 14.5145 6.69608 14.7396 6.69608H19.3592C19.5843 6.69608 19.7668 6.51358 19.7668 6.28847C19.7668 6.06335 19.5843 5.88086 19.3592 5.88086Z"
        fill={EColorName.OXFORD_BLUE}
      ></path>
      <path
        d="M16.9105 26.3737C17.6636 26.3737 18.2741 25.7632 18.2741 25.0101C18.2741 24.257 17.6636 23.6465 16.9105 23.6465C16.1574 23.6465 15.5469 24.257 15.5469 25.0101C15.5469 25.7632 16.1574 26.3737 16.9105 26.3737Z"
        fill="white"
        stroke={EColorName.OXFORD_BLUE}
        strokeWidth="0.5"
      ></path>
    </SVG>
  );
};
