import { ELanguageTags, EStorageType } from '@core/type';

import { EStorageKey } from '../constants';
import { getItem, logStorage, setItem } from '../helpers';

const getIsoLanguageTags = (key: string): ELanguageTags => {
  switch (key) {
    case ELanguageTags.EN_US.toString():
      return ELanguageTags.EN_US;
    case ELanguageTags.FR_FR.toString():
      return ELanguageTags.FR_FR;
    case ELanguageTags.DE_DE.toString():
      return ELanguageTags.DE_DE;
    case ELanguageTags.IT_IT.toString():
      return ELanguageTags.IT_IT;
    default:
      return ELanguageTags.EN_US;
  }
};

const setLanguageStore = (storage: EStorageType, value: ELanguageTags) => {
  logStorage('setLanguageStore');
  setItem(storage, EStorageKey.LANGUAGE, value);
};

const getLanguageStore = (storage: EStorageType): ELanguageTags => {
  return getIsoLanguageTags(getItem(storage, EStorageKey.LANGUAGE));
};

export class LanguageStorage {
  public static readonly set = setLanguageStore;
  public static readonly get = getLanguageStore;
}
