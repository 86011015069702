import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { TrustpilotTotalUsersEntity } from '@core/type/api';

import { TrustpilotAPI } from '../api/trustpilot';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('TrustpilotService', methodName, dto, apiCall, withContent);
};

const getTotalUsers = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<TrustpilotTotalUsersEntity> => {
  return log('getTotalUsers', { axiosInstance, language }, TrustpilotAPI.getTotalUsers);
};

export class TrustpilotService {
  public static readonly getTotalUsers = getTotalUsers;
}
