import type { ReactNode } from 'react';

import { profileContext } from '@core/context';
import { EStorageFeeStatus, UserEntity } from '@core/type/api';
import { ProfileHook, ProfileState } from '@core/type/context';

import { storeHook } from '../helpers';
import { useProfile } from '../hook';

export const ProviderProfile = ({
  children,
  user,
}: {
  children?: ReactNode;
  user?: UserEntity;
}) => {
  const profile: ProfileState = {
    userId: null,
    gaPayBalance: null,
    userGaPayUsed: false,
    referenceNumber: null,
    hasAuthy: false,
    birthday: null,
    storageFeesStatus: EStorageFeeStatus.STANDARD,
    gender: null,
    hasMfaSms: false,
    sessionId: null,
    hasMfaTotp: false,
    hasNewsletterSubscription: false,
    hasWireTransferInProgress: false,
    newsletterSubscriptionIds: null,
    hasLoginAlert: false,
    emergencyPeople: null,
    shippingAddress: null,
    billingAddress: null,
    hasNotCompletedStep: false,
    hasStorageFeesUnpaid: false,
    cartItemsCount: 0,
    customerSurveys: [],
    userType: null,
    crispSessionToken: null,
  };
  const hooks = storeHook<ProfileState, ProfileHook>(
    {
      ...profile,
      ...user,
    },
    useProfile,
  );
  return <profileContext.Provider value={hooks}>{children}</profileContext.Provider>;
};
