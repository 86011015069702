import Image from 'next/image';

import IconBestValueSvg from './svgs/icon-best-value.svg';

export const IconBestValue = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconBestValueSvg}
    alt="Best value icon"
    width={80}
    height={80}
  />
);
