import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconPlus = ({ fill }: DynamicIconProps) => {
  return (
    <SVG height={14} width={14} viewBox="-1 -1 16 16" title="Plus icon">
      <path
        fillRule="evenodd"
        d="M8 6h4a1 1 0 0 1 0 2H8v4a1 1 0 0 1-2 0V8H2a1 1 0 1 1 0-2h4V2a1 1 0 1 1 2 0v4z"
        fill={fill}
      />
    </SVG>
  );
};
