import Image from 'next/image';

import IconSmartphoneTextSvg from './svgs/icon-smartphone-text.svg';

export const IconSmartphoneText = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconSmartphoneTextSvg}
    alt="Smartphone icon"
    width={24}
    height={24}
  />
);
