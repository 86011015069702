export enum EAlternateLevels {
  LEVEL1,
  LEVEL2,
  LEVEL3,
  LEVEL4,
}

export type LevelsLinkProps = {
  level: EAlternateLevels;
  slug: string;
  subSlug?: string;
  subSubSlug?: string;
};
