import { createContext } from 'react';

import type { HookStore, MetaHook, MetaState } from '@core/type/context';

import { useFnContext, useStoreContext } from '../util';

export const metaContext =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createContext<HookStore<any, MetaState, MetaHook>>(null);

export const useContextMeta = () => {
  return useFnContext(metaContext);
};

export function useStoreMeta<Selector>(selector: keyof MetaState) {
  return useStoreContext<Selector, MetaState, MetaHook>(selector, metaContext);
}
