import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconPause = ({ fill }: DynamicIconProps) => {
  return (
    <SVG height={24} width={24} viewBox="0 0 24 24" title="Pause icon">
      <path
        d="M8 5C7.44772 5 7 5.44772 7 6V18C7 18.5523 7.44772 19 8 19C8.55228 19 9 18.5523 9 18V6C9 5.44772 8.55228 5 8 5ZM16 5C15.4477 5 15 5.44772 15 6V18C15 18.5523 15.4477 19 16 19C16.5523 19 17 18.5523 17 18V6C17 5.44772 16.5523 5 16 5Z"
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </SVG>
  );
};
