import { useCallback, useRef, useSyncExternalStore } from 'react';

import { HookStoreFn, StoreDataType } from '@core/type/context';

export function storeHook<State, ReturnFn>(
  initialState: State,
  useHook?: HookStoreFn<State, ReturnFn>,
) {
  const store: StoreDataType<State> = CreateStore();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  function CreateStore(): StoreDataType<State> {
    const ref = useRef<State>(initialState);

    const get = useCallback(() => ref.current, []);

    const subscribers = useRef<Set<() => void>>(new Set<() => void>());

    const set = useCallback((value: State[keyof State], selector: keyof State) => {
      ref.current = { ...ref.current, [selector]: value };
      subscribers.current.forEach((callback) => callback());
    }, []);

    const subscribe = useCallback((callback: () => void) => {
      subscribers.current.add(callback);
      return () => subscribers.current.delete(callback);
    }, []);

    return {
      get,
      set,
      subscribe,
    };
  }

  function useStore<Selector>(selector: keyof State): [Selector, (value: Selector) => void] {
    return [
      useGet<Selector>(selector),
      (value: Selector) => store.set(value as unknown as State[keyof State], selector),
    ];
  }

  function useGet<Selector>(selector: keyof State): Selector {
    return useSyncExternalStore(
      store.subscribe,
      () => store.get()[selector] as unknown as Selector,
      () => (initialState as unknown as State)[selector] as unknown as Selector,
    );
  }

  function useSet<Selector>(selector: keyof State, value: Selector): void {
    return store.set(value as unknown as State[keyof State], selector);
  }

  function useFn(): ReturnFn {
    return useHook(useGet, useSet);
  }

  return {
    useStore,
    useFn,
  };
}
