import { ELanguageTags, TranslatedUrl } from '@core/type';

export const facebookUrl: TranslatedUrl = {
  [ELanguageTags.EN_US]: 'https://www.facebook.com/GoldAvenueSA',
  [ELanguageTags.DE_DE]: 'https://www.facebook.com/GoldavenueDE',
  [ELanguageTags.FR_FR]: 'https://www.facebook.com/goldavenuefr',
  [ELanguageTags.IT_IT]: 'https://www.facebook.com/GoldavenueIT',
};

export const instagramUrl: TranslatedUrl = {
  [ELanguageTags.EN_US]: 'https://www.instagram.com/goldavenue_en',
  [ELanguageTags.DE_DE]: 'https://www.instagram.com/goldavenue_en',
  [ELanguageTags.FR_FR]: 'https://www.instagram.com/goldavenuefr',
  [ELanguageTags.IT_IT]: 'https://www.instagram.com/goldavenue_it',
};

export const linkedinUrl: TranslatedUrl = {
  [ELanguageTags.EN_US]: 'https://www.linkedin.com/company/goldavenue-en',
  [ELanguageTags.DE_DE]: 'https://www.linkedin.com/showcase/goldavenue-de',
  [ELanguageTags.FR_FR]: 'https://www.linkedin.com/showcase/goldavenue-fr',
  [ELanguageTags.IT_IT]: 'https://www.linkedin.com/showcase/goldavenue-it',
};

export const twitterUrl: TranslatedUrl = {
  [ELanguageTags.EN_US]: 'https://twitter.com/GoldavenueEN',
  [ELanguageTags.DE_DE]: 'https://twitter.com/GoldavenueDE',
  [ELanguageTags.FR_FR]: 'https://twitter.com/GoldavenueFR',
  [ELanguageTags.IT_IT]: 'https://twitter.com/GoldavenueIT',
};

export const bookACallUrl: TranslatedUrl = {
  [ELanguageTags.EN_US]: 'http://call-en.goldavenue.com',
  [ELanguageTags.DE_DE]: 'http://call-de.goldavenue.com',
  [ELanguageTags.FR_FR]: 'http://call-fr.goldavenue.com',
  [ELanguageTags.IT_IT]: 'http://call-it.goldavenue.com',
};

export const YOUTUBE_URL = 'https://www.youtube.com/@goldavenue.official';

export const LINKEDIN_CRACCO_URL = 'https://www.linkedin.com/in/nicolas-cracco-58ab0279';
export const LINKEDIN_OMAR_URL = 'https://www.linkedin.com/in/omar-liess-0335134b';
