import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { BlogCategoriesResponse } from '@core/type/api';

import { BlogCategoriesAPI } from '../api/blog-categories';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('BlogCategoriesService', methodName, dto, apiCall, withContent);
};

const getBlogCategories = (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<BlogCategoriesResponse> => {
  return log(
    'getHomepageArticles',
    { axiosInstance, language },
    BlogCategoriesAPI.getBlogCategories,
  );
};

export class BlogCategoriesService {
  public static readonly getBlogCategories = getBlogCategories;
}
