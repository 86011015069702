import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconTagCross = ({ fill }: DynamicIconProps) => (
  <SVG height={16} width={16} viewBox="4 4 16 16" title="Cross icon">
    <path
      fill={fill}
      d="M13.35347825050354,12 l2.022,2.023 a0.5720000000000001,0.5720000000000001 0 1 1 -0.809,0.81 L12.54347825050354,12.808 l-2.023,2.023 a0.5720000000000001,0.5720000000000001 0 1 1 -0.81,-0.809 L11.73547825050354,12 L9.71147825050354,9.977 a0.5720000000000001,0.5720000000000001 0 0 1 0.809,-0.81 L12.54347825050354,11.192 l2.023,-2.023 a0.5720000000000001,0.5720000000000001 0 1 1 0.81,0.809 L13.35147825050354,12 z"
    />
  </SVG>
);
