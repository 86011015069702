import { createContext, useContext } from 'react';

export type GRContextState = {
  getRecaptcha: () => Promise<string>;
};

export const grContext = createContext<GRContextState>(null);

export const useContextGR = () => {
  return useContext(grContext);
};
