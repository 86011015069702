import { memo, useState } from 'react';

import { Box, Icon } from '@core/component';
import { useContextMediaQuery } from '@core/context';
import { EColor, EIcon, ESize } from '@core/type';
import { ShopSubcategoriesResponse } from '@core/type/api';

import { HeaderCartSearch } from './HeaderCartSearch';
import { MetalsHeader } from './HeaderMetals';
import { HeaderSearch } from './HeaderSearch';

const HeaderDefaultContainer = ({
  subcategories,
}: {
  subcategories: ShopSubcategoriesResponse;
}) => {
  const { isMobile } = useContextMediaQuery();
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);

  return (
    <>
      {isMobile ? (
        <Box
          display={'flex'}
          alignItems={'center'}
          width={'100%'}
          maxWidth="450px"
          margin="0 auto"
          padding="24px 0"
          position="relative"
        >
          <MetalsHeader isSearchOpen={isSearchOpen} subcategories={subcategories} />

          <Box
            display="flex"
            position="absolute"
            width={isSearchOpen ? '100%' : '42px'}
            justifyContent="flex-end"
            gap="20px"
            alignItems="center"
            right="0px"
            transition="width 0.9s cubic-bezier(0.79, 0.14, 0.15, 0.86)"
          >
            <Box
              opacity={isSearchOpen ? 1 : 0}
              transition="opacity .9s cubic-bezier(0.79, 0.14, 0.15, 0.86)"
            >
              <Box onClick={() => setIsSearchOpen(false)}>
                <Icon
                  type={EIcon.CROSS}
                  color={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
                  strokeWidth={1.5}
                  size={ESize.SM}
                />
              </Box>
            </Box>
            <HeaderSearch isOpen={isSearchOpen} setIsOpen={setIsSearchOpen} />
          </Box>
        </Box>
      ) : (
        <>
          <MetalsHeader isSearchOpen={isSearchOpen} subcategories={subcategories} />

          <HeaderCartSearch isOpen={isSearchOpen} setIsOpen={setIsSearchOpen} />
        </>
      )}
    </>
  );
};
export const HeaderDefault = memo(HeaderDefaultContainer);
