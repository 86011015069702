import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconAddress = ({ strokeWidth, fill }: DynamicIconProps) => (
  <SVG height={40} width={40} viewBox="0 0 40 40" title="Address icon">
    <path
      d="M26.0668 16.6667C26.0668 17.3788 25.7556 18.5375 25.2149 19.9405C24.6836 21.3195 23.9607 22.8603 23.2015 24.3041C22.442 25.7485 21.6536 27.0818 20.9951 28.0474C20.6644 28.5323 20.3763 28.9098 20.1486 29.1597C20.092 29.2219 20.0425 29.2724 20.0002 29.3125C19.9578 29.2724 19.9083 29.2219 19.8517 29.1597C19.624 28.9098 19.336 28.5323 19.0052 28.0474C18.3468 27.0818 17.5583 25.7485 16.7988 24.3041C16.0396 22.8603 15.3167 21.3195 14.7854 19.9405C14.2448 18.5375 13.9335 17.3788 13.9335 16.6667C13.9335 13.3161 16.6496 10.6 20.0002 10.6C23.3308 10.6 26.0668 13.6445 26.0668 16.6667ZM19.854 29.4306C19.854 29.4303 19.857 29.4285 19.8628 29.4258C19.8568 29.4295 19.8539 29.4309 19.854 29.4306ZM20.1375 29.4258C20.1433 29.4285 20.1463 29.4303 20.1464 29.4306C20.1464 29.4309 20.1435 29.4295 20.1375 29.4258Z"
      stroke={fill}
      strokeWidth={strokeWidth}
    />
    <path
      d="M8.3335 35L8.3335 5H31.6668L31.6668 35H8.3335Z"
      stroke={fill}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="20.0002" cy="16.6667" r="1.66667" fill={fill} />
  </SVG>
);
