// TODO[GARB-4422]: remove ELayout from type when the old header is removed. Move BLOG and LANDING headers to the EHeader enum
export enum ELayout {
  // TODO[GARB-4422]: was renamed to EHeader.DEFAULT value to prevent issues with the new header
  DEFAULT = 'headerDefault',
  BLOG = 'blog',
  // TODO[GARB-4422]: was renamed to EHeader.SHORT to prevent issues with the new header
  SHORT = 'headerShort',
  LANDING = 'landing',
}

export type LayoutType<Layout extends Partial<ELayout>> = {
  [key in Layout]: Layout;
};

export type HeaderLayoutType = keyof LayoutType<
  ELayout.DEFAULT | ELayout.BLOG | ELayout.SHORT | ELayout.LANDING
>;

export enum EFooter {
  DEFAULT = 'default',
  LOGOS = 'logos',
}
