import type { ReactNode } from 'react';

import { selectContext } from '@core/context';
import { SelectHook, SelectState } from '@core/type/context';

import { storeHook } from '../helpers';
import { useSelect } from '../hook';

export const ProviderSelect = ({
  children,
  ...restProps
}: {
  isOpen?: boolean;
  children: ReactNode;
}) => {
  const hooks = storeHook<SelectState, SelectHook>(restProps, useSelect);
  return <selectContext.Provider value={hooks}>{children}</selectContext.Provider>;
};
