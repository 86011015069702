import { useContextRouting } from '@core/context';
import { ELanguageTags, ERouting } from '@core/type';
import { getDefaultLanguageSSR, getFullUrl } from '@core/util';

import { useLanguage } from './use-language';

export const useFullUrl = (host: string) => {
  const { language } = useLanguage();
  const { keyRoute, query, pathTranslations } = useContextRouting();

  const fullUrl =
    keyRoute === ERouting.HOME && [ELanguageTags.EN_US, ELanguageTags.DEFAULT].includes(language)
      ? host
      : getFullUrl(host, getDefaultLanguageSSR(language), keyRoute, pathTranslations, query);

  return { fullUrl };
};
