import { themeContainerMaxWidth } from '@core/constant';
import type { ThemeDevice, ThemeDeviceReturn, ThemeStructure, ThemeTypography } from '@core/type';
import { ESize } from '@core/type';

import { themeDefaultStructure } from './theme-default-structure';
import { themeDefaultTypography } from './theme-default-typography';

const themeTypographyXL: ThemeTypography = {
  ...themeDefaultTypography,
};

const themeStructureXL: ThemeStructure = {
  ...themeDefaultStructure,
  container: {
    ...themeDefaultStructure.container,
    maxWidth: themeContainerMaxWidth[ESize.XL],
  },
};

export const themeXL: ThemeDeviceReturn = (
  baseTheme: ThemeDevice = themeDefaultDevice,
): ThemeDevice => baseTheme;

// From desktop to mobile approach. So this theme is use as default
export const themeDefaultDevice: ThemeDevice = {
  typography: themeTypographyXL,
  structure: themeStructureXL,
};
