import { ESize, IconSizeValues } from '@core/type';

export const iconSize: IconSizeValues = {
  [ESize.XXXS]: '10px',
  [ESize.XXS]: '12px',
  [ESize.XS]: '13px',
  [ESize.SM]: '16px',
  [ESize.MD]: '24px',
  [ESize.LG]: '32px',
  [ESize.XL]: '40px',
  [ESize.XXL]: '54px',
};
