import { useTheme } from 'styled-components';

import { EColor, EIcon, ELanguage, EPosition, ETypographyVariant, ThemeProps } from '@core/type';

import { Icon } from '../icon/Icon';
import { Box } from '../layout/Box';
import { LinkTranslatedStyledAsLink } from '../link-translated';
import { BreadcrumbLinkProps } from './interface-breadcrumb';

export const BreadcrumbLink = ({
  keyRoute,
  title,
  query,
  href,
}: BreadcrumbLinkProps): JSX.Element => {
  const {
    structure: {
      breadcrumb: { padding, yTranslation, sizeIcon },
    },
  } = useTheme() as ThemeProps;

  if (!href && !keyRoute) {
    return <></>;
  }

  const isLinkHasLanguage = Object.values(ELanguage).some((lang) => href?.includes(`/${lang}/`));

  return (
    <>
      {href ? (
        <LinkTranslatedStyledAsLink
          href={href}
          color={EColor.SECONDARY}
          variant={ETypographyVariant.CAPTION2}
          locale={isLinkHasLanguage ? false : undefined}
        >
          {title}
        </LinkTranslatedStyledAsLink>
      ) : (
        keyRoute && (
          <LinkTranslatedStyledAsLink
            keyRoute={keyRoute}
            query={query}
            color={EColor.SECONDARY}
            variant={ETypographyVariant.CAPTION2}
          >
            {title}
          </LinkTranslatedStyledAsLink>
        )
      )}

      <Box padding={padding} display={'inline-block'}>
        <Icon
          type={EIcon.ANGLE}
          color={EColor.SECONDARY}
          orientation={EPosition.RIGHT}
          yTranslation={yTranslation}
          size={sizeIcon}
          marginBottom="-4px"
        />
      </Box>
    </>
  );
};
