import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconCartRepeat = ({ fill }: DynamicIconProps) => (
  <SVG height={24} width={24} viewBox="0 0 24 24" title="Cart repeat icon">
    <g clipPath="url(#clip0_11571_27884)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.602987 0.264028C0.788572 -0.0104986 1.16157 -0.082599 1.43609 0.102987L3.18084 1.28247C3.30035 1.36326 3.38675 1.48441 3.42421 1.62371L6.79411 14.1534H6.91572C6.92407 14.1534 6.93238 14.1536 6.94065 14.1539H20.9854L22.036 9.89232C22.1153 9.57059 22.4405 9.37407 22.7622 9.45339C23.0839 9.53271 23.2805 9.85783 23.2011 10.1796L22.038 14.8975C21.9719 15.1655 21.7315 15.3539 21.4554 15.3539H6.33429C6.32599 15.3539 6.31772 15.3537 6.30949 15.3534H5.7852C4.81375 15.3534 4.02623 16.1409 4.02623 17.1124C4.02623 18.0838 4.81375 18.8713 5.78521 18.8713H6.07032C6.47167 17.5129 7.71386 16.523 9.18407 16.523C10.6543 16.523 11.8966 17.513 12.2979 18.8715H15.957C16.3583 17.513 17.6005 16.523 19.0708 16.523C20.8657 16.523 22.3208 17.9985 22.3208 19.8187C22.3208 21.6388 20.8657 23.1143 19.0708 23.1143C17.3597 23.1143 15.9575 21.7735 15.8302 20.0715H12.4247C12.2973 21.7735 10.8951 23.1143 9.18407 23.1143C7.47296 23.1143 6.07071 21.7734 5.94345 20.0713H5.78521C4.15101 20.0713 2.82623 18.7465 2.82623 17.1124C2.82623 15.556 4.02779 14.2803 5.55386 14.1623L2.32348 2.15136L0.764028 1.09713C0.489501 0.911548 0.417401 0.538554 0.602987 0.264028ZM19.0708 21.9143C20.1873 21.9143 21.1208 20.9919 21.1208 19.8187C21.1208 18.6455 20.1873 17.723 19.0708 17.723C17.9543 17.723 17.0208 18.6455 17.0208 19.8187C17.0208 20.9919 17.9543 21.9143 19.0708 21.9143ZM11.2341 19.8187C11.2341 20.9919 10.3006 21.9143 9.18407 21.9143C8.06758 21.9143 7.13404 20.9919 7.13404 19.8187C7.13404 18.6455 8.06758 17.723 9.18407 17.723C10.3006 17.723 11.2341 18.6455 11.2341 19.8187Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7689 3.07845C14.4376 3.07845 14.1689 3.34708 14.1689 3.67845C14.1689 4.00982 14.4376 4.27845 14.7689 4.27845H17.1765H17.7765V3.67845V1.2709C17.7765 0.939528 17.5079 0.670898 17.1765 0.670898C16.8451 0.670898 16.5765 0.939528 16.5765 1.2709V2.3215C15.6933 1.73944 14.635 1.40035 13.4984 1.40035C10.4056 1.40035 7.89844 3.90755 7.89844 7.00035C7.89844 10.0931 10.4056 12.6003 13.4984 12.6003C16.5912 12.6003 19.0984 10.0931 19.0984 7.00035C19.0984 6.66898 18.8298 6.40035 18.4984 6.40035C18.1671 6.40035 17.8984 6.66898 17.8984 7.00035C17.8984 9.4304 15.9285 11.4003 13.4984 11.4003C11.0684 11.4003 9.09844 9.4304 9.09844 7.00035C9.09844 4.57029 11.0684 2.60035 13.4984 2.60035C14.2178 2.60035 14.8963 2.77264 15.4955 3.07845H14.7689Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_11571_27884">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </SVG>
);
