import { SanityBody, SanityPageResponse } from '@core/type/sanity';

export const formatSanityField = (data: string) => {
  if (typeof data === 'string') {
    return data?.replace(/[\u200B-\u200D\uFEFF]/g, '');
  } else {
    return data;
  }
};

const recursiveFormat = (data: unknown): unknown => {
  if (typeof data === 'string') {
    return formatSanityField(data);
  } else if (Array.isArray(data)) {
    return data.map((el) => recursiveFormat(el));
  } else if (typeof data === 'object' && data !== null) {
    return Object.keys(data).reduce(
      (acc, key) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        acc[key] = recursiveFormat((data as { [key: string]: unknown })[key]);
        return acc;
      },
      {} as { [key: string]: unknown },
    );
  } else {
    return data;
  }
};

export const formatSanityData = <T>(data: T): T => {
  return recursiveFormat(data) as T;
};

export const formatSanityPageData = (data: SanityPageResponse): SanityPageResponse =>
  data.body ? { ...formatSanityData(data) } : { ...formatSanityData(data), body: {} as SanityBody };
