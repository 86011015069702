import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type { ClaimDetailsEntity, ClaimsRequestEntity, ConfirmClaimPayload } from '@core/type/api';

import { Api } from '../constants/api';
import { replaceIdUrl } from '../helpers/api';
import { configResponse, EConfigName } from '../helpers/config';

const getClaim = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<ClaimDetailsEntity>> => {
  return axiosInstance.get(Api.user.CLAIM, configResponse({ [EConfigName.LANGUAGE]: language }));
};

const getClaimHistory = async (
  axiosInstance: AxiosInstance,
  { claimId, language }: LanguageProps & { claimId: number },
): Promise<AxiosResponse<ClaimDetailsEntity>> => {
  return axiosInstance.get(
    replaceIdUrl(Api.user.HISTORY_CLAIM, claimId),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const patchClaim = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & Partial<ClaimsRequestEntity>,
): Promise<AxiosResponse<ClaimDetailsEntity>> => {
  return axiosInstance.patch(
    Api.user.CLAIM,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const postClaim = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & Partial<ClaimsRequestEntity>,
): Promise<AxiosResponse<ClaimDetailsEntity>> => {
  return axiosInstance.post(
    Api.user.CLAIM,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const confirmClaim = async (
  axiosInstance: AxiosInstance,
  { language, ...data }: LanguageProps & ConfirmClaimPayload,
): Promise<AxiosResponse<ClaimDetailsEntity>> => {
  return axiosInstance.put(
    Api.user.CONFIRM_CLAIM,
    data,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const reviewClaim = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<ClaimDetailsEntity>> => {
  return axiosInstance.put(
    Api.user.REVIEW_CLAIM,
    null,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class ClaimAPI {
  public static readonly getClaim = getClaim;
  public static readonly getClaimHistory = getClaimHistory;
  public static readonly postClaim = postClaim;
  public static readonly patchClaim = patchClaim;
  public static readonly confirmClaim = confirmClaim;
  public static readonly reviewClaim = reviewClaim;
}
