import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconPlayCircled = ({ fill }: DynamicIconProps) => {
  return (
    <SVG height={24} width={24} viewBox="0 0 24 24" title="Play circled icon">
      <circle className="--paint-up-stroke" cx="12" cy="12" r="11.5" stroke={fill} />
      <path
        d="M8 7.41168C8 6.71542 8.75149 6.27689 9.35969 6.61824L17.5349 11.2066C18.155 11.5546 18.155 12.4454 17.5349 12.7934L9.3597 17.3818C8.75149 17.7231 8 17.2846 8 16.5883V7.41168Z"
        fill={fill}
      />
    </SVG>
  );
};
