import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type {
  PreciousMetalArticlesBySlugEntity,
  PreciousMetalArticlesEntity,
} from '@core/type/api';

import { Api } from '../constants/api';
import { replaceIdUrl } from '../helpers/api';
import { configResponse, EConfigName } from '../helpers/config';

const getPreciousMetalArticles = (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<PreciousMetalArticlesEntity>> => {
  return axiosInstance.get(
    Api.preciousMetalArticles.PRECIOUS_METAL_ARTICLES,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getPreciousMetalArticlesBySlug = (
  axiosInstance: AxiosInstance,
  { slug, language }: LanguageProps & { slug: string },
): Promise<AxiosResponse<PreciousMetalArticlesBySlugEntity>> => {
  return axiosInstance.get(
    replaceIdUrl(Api.preciousMetalArticles.PRECIOUS_METAL_ARTICLES_BY_SLUG, slug, ':slug'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class PreciousMetalArticlesAPI {
  public static readonly getPreciousMetalArticles = getPreciousMetalArticles;
  public static readonly getPreciousMetalArticlesBySlug = getPreciousMetalArticlesBySlug;
}
