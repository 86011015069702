import { useContextMediaQuery } from '@core/context';

import { Grid } from '../grid';
import { Box } from '../layout';
import { SkeletonTypography } from './SkeletonTypography';
import { SkeletonWrapper } from './SkeletonWrapper';

export const SkeletonBlogArticle = () => {
  const { isPhone, isMobile } = useContextMediaQuery();

  return (
    <Grid isContainer columnSpacing={30}>
      <Grid lg={2} />

      <Grid xs={12} lg={7} xl={8}>
        <SkeletonWrapper padding={'24px 0 80px'}>
          <Box display={'flex'} flexDirection={isPhone ? 'column-reverse' : 'column'}>
            <Box>
              <SkeletonTypography
                lineHeight={isMobile ? '34px' : '42px'}
                width={isPhone ? '80%' : '90%'}
                marginLeft={'auto'}
                marginRight={'auto'}
                marginBottom={isMobile ? '4px' : '8px'}
              />
              <SkeletonTypography
                lineHeight={isMobile ? '34px' : '42px'}
                width={isPhone ? '100%' : '90%'}
                margin={'0 auto 24px'}
              />
              <SkeletonTypography
                lineHeight={'22px'}
                maxWidth={isPhone ? '280px' : '360px'}
                margin={'0 auto 40px'}
              />
            </Box>
            <SkeletonTypography
              lineHeight={'426px'}
              width={isPhone ? '100%' : '95%'}
              margin={'0 auto 40px'}
            />
          </Box>
          <SkeletonTypography lineHeight={'56px'} marginBottom={'12px'} />
          <SkeletonTypography lineHeight={'56px'} marginBottom={'12px'} />
          <SkeletonTypography lineHeight={'56px'} />
        </SkeletonWrapper>
      </Grid>

      <Grid lg={3} xl={2} />
    </Grid>
  );
};
