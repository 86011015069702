import { useContextMediaQuery } from '@core/context';
import { ETypographyVariant } from '@core/type';

import { Grid } from '../grid';
import { Paper } from '../paper';
import { SkeletonTypography } from './SkeletonTypography';
import { SkeletonWrapper } from './SkeletonWrapper';

export const SkeletonAlerts = () => {
  const { isPhone } = useContextMediaQuery();

  return (
    <SkeletonWrapper overflow={'visible'}>
      <SkeletonTypography variant={ETypographyVariant.H2} width={'280px'} marginBottom={'16px'} />
      <SkeletonTypography
        lineHeight={isPhone ? '56px' : '28px'}
        maxWidth={'400px'}
        marginBottom={isPhone ? '62px' : '32px'}
      />
      <SkeletonTypography width={'100px'} marginBottom={'24px'} />

      <Paper padding={isPhone ? undefined : '40px'} hasElevation={!isPhone}>
        <SkeletonTypography
          lineHeight={isPhone ? '132px' : '96px'}
          maxWidth={'700px'}
          marginBottom={'32px'}
        />

        <Grid isContainer columnSpacing={8} rowSpacing={8}>
          <Grid xl={4} lg={6}>
            <SkeletonTypography lineHeight={'48px'} borderRadius={'6px'} />
          </Grid>
          <Grid xl={4} lg={6}>
            <SkeletonTypography lineHeight={'48px'} borderRadius={'6px'} />
          </Grid>
          <Grid xl={4} lg={6}>
            <SkeletonTypography lineHeight={'48px'} borderRadius={'6px'} />
          </Grid>
        </Grid>

        <SkeletonTypography
          lineHeight={isPhone ? '44px' : '24px'}
          maxWidth={'530px'}
          margin={'24px 0 28px'}
        />

        {!isPhone && (
          <>
            <SkeletonTypography
              lineHeight={'48px'}
              width={'100px'}
              borderRadius={'8px'}
              marginBottom={'55px'}
            />

            <SkeletonTypography lineHeight={'36px'} marginBottom={'16px'} />
            <SkeletonTypography lineHeight={'52px'} />
          </>
        )}
      </Paper>
    </SkeletonWrapper>
  );
};
