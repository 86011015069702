import { createContext } from 'react';

import type { HookStore, UtilHook, UtilState } from '@core/type/context';

import { useFnContext, useStoreContext } from '../util';

export const utilContext =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createContext<HookStore<any, UtilState, UtilHook>>(null);

export const useContextUtil = () => {
  return useFnContext(utilContext);
};

export function useStoreUtil<Selector>(selector: keyof UtilState) {
  return useStoreContext<Selector, UtilState, UtilHook>(selector, utilContext);
}
