import useTranslation from 'next-translate/useTranslation';
import withTranslation from 'next-translate/withTranslation';
import { ChangeEventHandler, memo, useEffect, useState } from 'react';

import {
  Autocomplete,
  Box,
  ButtonIcon,
  EditorContent,
  Icon,
  InputBase,
  InputBorder,
  Line,
  LinkTranslatedStyledAsLink,
  Typography,
} from '@core/component';
import { MIN_SEARCH_SYMBOLS, Translate } from '@core/constant';
import { useContextRouting } from '@core/context';
import { useQueryApi } from '@core/hook';
import {
  EColor,
  EIcon,
  ERouting,
  ESize,
  ETypographyVariant,
  EZIndexName,
  WithTranslation,
} from '@core/type';
import { BlogSearchAutocompleteEntity, EQueryKey } from '@core/type/api';
import { replaceToBold } from '@core/util';

export const BlogSearchBarContainer = ({ i18n: { t } }: WithTranslation) => {
  const { t: tAria } = useTranslation(Translate.common.ARIA);
  const [searchTerm, setSearchTerm] = useState<string>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { setRoute } = useContextRouting();

  const isMinSearchValue = searchTerm?.length >= MIN_SEARCH_SYMBOLS;

  const { data, isSuccess } = useQueryApi<BlogSearchAutocompleteEntity, { q: string }>(
    EQueryKey.BLOG_AUTOCOMPLETE_SEARCH,
    { q: searchTerm },
    {
      enabled: isMinSearchValue,
    },
  );

  useEffect(() => {
    if (isMinSearchValue && isSuccess) {
      setIsOpen(true);
    }
  }, [isMinSearchValue, setIsOpen, isSuccess]);

  const changeValue: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    setSearchTerm(value);
  };

  const clearSearchTerm = () => {
    setSearchTerm('');
  };

  const handleClick = () => {
    setRoute(ERouting.BLOG_SEARCH, { q: searchTerm });
    clearSearchTerm();
  };

  return (
    <Box position={'relative'} width={'100%'} maxWidth={'362px'}>
      <Box
        display={'flex'}
        position={'relative'}
        boxSizing={'border-box'}
        backgroundColor={isFocused ? undefined : EColor.SECONDARY}
        borderRadius={'6px'}
        overflow={'hidden'}
        width={'100%'}
        zIndex={isOpen || isFocused ? EZIndexName.TOOLTIP : undefined}
      >
        <InputBorder
          borderColor={
            isFocused ? EColor.SECONDARY : { commonType: EColor.GRAY, intensity: EColor.A50 }
          }
        />
        <Box padding={'4px 12px'} fontSize={'15px'} width={'100%'}>
          <InputBase
            placeholder={t('blog.searchArticle')}
            value={searchTerm}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            onChange={changeValue}
            onKeyUp={(e) => e.key === 'Enter' && handleClick()}
          />
        </Box>
        <LinkTranslatedStyledAsLink
          display="inline-block"
          keyRoute={ERouting.BLOG_SEARCH}
          query={{ q: searchTerm }}
        >
          <ButtonIcon
            iconProps={{
              type: EIcon.SEARCH,
              size: ESize.MD,
              margin: '0 auto',
              color: isFocused
                ? { commonType: EColor.WHITE, intensity: EColor.R900 }
                : EColor.PRIMARY,
            }}
            backgroundColor={
              isFocused ? EColor.PRIMARY : { commonType: EColor.GRAY, intensity: EColor.A50 }
            }
            width={'56px'}
            height="100%"
            onClick={() => setIsOpen(false)}
            aria-label={tAria('blogSearch')}
          />
        </LinkTranslatedStyledAsLink>
      </Box>

      {data && (
        <Autocomplete
          setSearchTerm={setSearchTerm}
          isOpenInput={isOpen}
          searchTerm={searchTerm}
          setIsOpenInput={setIsOpen}
          isCustom
        >
          <Box
            overflowX={'hidden'}
            overflowY={'auto'}
            padding={'14px 0'}
            backgroundColor={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
            textAlign={'left'}
          >
            {data.blogArticles?.length > 0 ? (
              <Box paddingBottom={'24px'}>
                <Box padding={'0 24px'} onClick={clearSearchTerm}>
                  <LinkTranslatedStyledAsLink
                    keyRoute={ERouting.BLOG_SEARCH}
                    query={{ q: searchTerm }}
                    variant={ETypographyVariant.BODY3}
                    color={EColor.ACCENT}
                    endIcon={EIcon.CIRCLE_ARROW}
                  >
                    {t('blog.viewAllResults')}
                  </LinkTranslatedStyledAsLink>
                </Box>

                <Line
                  margin={'24px 0'}
                  backgroundColor={{ commonType: EColor.GRAY, intensity: EColor.A200 }}
                />

                {data.blogArticles.map(({ title, slug, category }) => (
                  <LinkTranslatedStyledAsLink
                    key={slug}
                    keyRoute={ERouting.BLOG_ARTICLE}
                    query={{ category: category.slug, slug }}
                  >
                    <Box
                      padding={'12px 24px'}
                      cursor={'pointer'}
                      hoverStyles={{ backgroundColor: EColor.SECONDARY }}
                      onClick={clearSearchTerm}
                    >
                      <Typography
                        marginBottom={'4px'}
                        overflow={'hidden'}
                        textOverflow={'ellipsis'}
                        whiteSpace={'nowrap'}
                      >
                        <EditorContent content={replaceToBold(searchTerm, title)} />
                      </Typography>
                      <Typography variant={ETypographyVariant.CAPTION1} color={EColor.SECONDARY}>
                        {category?.title}
                      </Typography>
                    </Box>
                  </LinkTranslatedStyledAsLink>
                ))}
              </Box>
            ) : (
              <Box padding={'14px 24px 24px'}>
                <Typography variant={ETypographyVariant.BODY3} color={EColor.SECONDARY}>
                  {t('blog.noResults')}
                </Typography>
              </Box>
            )}

            {data.suggestedTags?.length > 0 && (
              <>
                <Line
                  marginBottom={'24px'}
                  backgroundColor={{ commonType: EColor.GRAY, intensity: EColor.A200 }}
                />

                <Box marginBottom={'16px'} display={'flex'} padding={'0 24px'}>
                  <Icon
                    type={EIcon.TAG}
                    color={EColor.SECONDARY}
                    height={'20px'}
                    width={'20px'}
                    marginRight={'8px'}
                  />

                  <Typography variant={ETypographyVariant.BODY3} color={EColor.SECONDARY}>
                    {t('blog.suggestedTags')}
                  </Typography>
                </Box>
              </>
            )}

            {data.suggestedTags?.map(({ name, slug }) => (
              <LinkTranslatedStyledAsLink key={slug} keyRoute={ERouting.BLOG_TAG} query={{ slug }}>
                <Box
                  padding={'9px 24px 8px'}
                  cursor={'pointer'}
                  hoverStyles={{ backgroundColor: EColor.SECONDARY }}
                >
                  <Typography>{name}</Typography>
                </Box>
              </LinkTranslatedStyledAsLink>
            ))}
          </Box>
        </Autocomplete>
      )}
    </Box>
  );
};

export const BlogSearchBar = memo(withTranslation(BlogSearchBarContainer, Translate.layout.HEADER));
