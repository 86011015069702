import { useEffect, useState } from 'react';

import { Box, DropdownCloseArea, SelectOption } from '@core/component';
import { useScrollDirection } from '@core/hook';
import { EColor } from '@core/type';

import { Dropdown } from './Dropdown';
import { SearchInput } from './SearchInput';
import { useSearchOptions } from './hooks';
import { formatSuggestion } from './utils';

export const Search = ({ isLight }: { isLight?: boolean }) => {
  const { isDown, isUp } = useScrollDirection(0);
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const { suggestions, searchTerm, updateSearchTerm, redirect } = useSearchOptions({ handleClose });

  const handleChange = (inputValue: string) => {
    updateSearchTerm(inputValue);
  };

  useEffect(() => {
    if (isDown || isUp) {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDown, isUp]);

  useEffect(() => {
    if (suggestions.length > 0) {
      setIsOpen(!!searchTerm);
    }
  }, [suggestions, searchTerm]);

  return (
    <Box position={'relative'} display={'flex'} alignItems={'center'} flex={'1'} maxWidth={'400px'}>
      <SearchInput
        isLight={isLight}
        onChange={handleChange}
        value={searchTerm}
        onPressEnter={() => redirect(searchTerm)}
      />
      <DropdownCloseArea onClose={handleClose} isOpen={isOpen} />
      <Dropdown isOpen={isOpen} onClose={handleClose}>
        {suggestions?.map(
          (suggestion) =>
            suggestion && (
              <SelectOption
                value={suggestion}
                key={suggestion}
                onClick={() => redirect(suggestion)}
                label={formatSuggestion(suggestion, searchTerm)}
                padding={'8px 24px'}
                hoverStyles={{
                  backgroundColor: { semanticType: EColor.SECONDARY, variant: EColor.MAIN },
                }}
              ></SelectOption>
            ),
        )}
      </Dropdown>
    </Box>
  );
};
