import { createContext } from 'react';

import type { HookStore, MetalPriceHook, MetalPriceState } from '@core/type/context';

import { useFnContext, useStoreContext } from '../util';

export const metalPriceContext =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createContext<HookStore<any, MetalPriceState, MetalPriceHook>>(null);

export const useContextMetalPrice = () => {
  return useFnContext(metalPriceContext);
};

export function useStoreMetalPrice<Selector>(selector: keyof MetalPriceState) {
  return useStoreContext<Selector, MetalPriceState, MetalPriceHook>(selector, metalPriceContext);
}
