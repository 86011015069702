import { initialize } from 'launchdarkly-js-client-sdk';

import { SanityPageResponse } from '@core/type/sanity';

import { formatSanityData } from './format';

export async function getVariation(key: string, clientIp: string) {
  const client = initialize(process.env.LAUNCHDARKLY_CLIENT_KEY, {
    kind: 'user',
    key: clientIp,
    ip: clientIp,
  });
  await client.waitUntilReady();
  return client.variation(key, false) as boolean;
}

export const applyAbTest = (
  cmsResult: SanityPageResponse,
  shouldUseVariation: boolean,
): SanityPageResponse => {
  const cmsResultFormated = formatSanityData(cmsResult);
  if (shouldUseVariation && cmsResult?.abTest?.variant) {
    return {
      ...cmsResultFormated,
      body: cmsResultFormated.abTest.variant,
    } as SanityPageResponse;
  } else {
    return cmsResultFormated;
  }
};
