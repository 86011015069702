import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type { OrderReq, OrderResponse, UpdateOrderEntity } from '@core/type/api';

import { OrderAPI } from '../api/order';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('CartService', methodName, dto, apiCall, withContent);
};

const getCart = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<OrderResponse> => {
  return log('getCart', { axiosInstance, language }, OrderAPI.getCart);
};

const getHistoryOrder = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  id: string,
): Promise<OrderResponse> => {
  return log('getHistoryOrder', { axiosInstance, language, id }, OrderAPI.getHistoryOrder);
};

const postOrder = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: OrderReq,
): Promise<OrderResponse> => {
  return log('postOrder', { axiosInstance, language, ...data }, OrderAPI.postOrder);
};

const postVirtualCart = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: OrderReq,
): Promise<OrderResponse> => {
  return log('postVirtualCart', { axiosInstance, language, ...data }, OrderAPI.virtualPostCart);
};

const patchOrder = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: OrderReq,
): Promise<OrderResponse> => {
  return log('patchOrder', { axiosInstance, language, ...data }, OrderAPI.patchOrder);
};

const putOrderReview = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<OrderResponse> => {
  return log('putOrderReview', { axiosInstance, language }, OrderAPI.putOrderReview);
};

const putOrderConfirm = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  data: UpdateOrderEntity,
): Promise<{ id: string }> => {
  return log('putOrderConfirm', { axiosInstance, language, ...data }, OrderAPI.putOrderConfirm);
};

const setCurrentCartToAbandonedCart = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  id: string,
) => {
  return log(
    'setCurrentCartToAbandonedCart',
    { axiosInstance, language, id },
    OrderAPI.setCurrentCartToAbandonedCart,
  );
};

export class OrderService {
  public static readonly getCart = getCart;
  public static readonly getHistoryOrder = getHistoryOrder;
  public static readonly postOrder = postOrder;
  public static readonly putOrderReview = putOrderReview;
  public static readonly putOrderConfirm = putOrderConfirm;
  public static readonly patchOrder = patchOrder;
  public static readonly postVirtualCart = postVirtualCart;
  public static readonly setCurrentCartToAbandonedCart = setCurrentCartToAbandonedCart;
}
