import withTranslation from 'next-translate/withTranslation';
import { FC, memo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { Box, LinkTranslatedStyledAsLink, Typography } from '@core/component';
import { Translate, metalToSlug } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { useLanguage } from '@core/hook';
import { EColor, EFontWeight, EMetal, ERouting, ThemeProps, WithTranslation } from '@core/type';
import { ShopSubcategoriesResponse } from '@core/type/api';

import { HeaderMetalCategories } from './HeaderMetalCategories';
import { HeaderMetalsContainerProps, MetalLinkProps } from './interface-header';

const HeaderMetalsContainer = ({ isSearchOpen, subcategories }: HeaderMetalsContainerProps) => {
  const { isMobile } = useContextMediaQuery();

  return (
    <>
      {isMobile ? (
        <Box
          display="flex"
          position="absolute"
          justifyContent="space-around"
          width="90%"
          opacity={isSearchOpen ? 0 : 1}
          transition="0.9s opacity cubic-bezier(0.79, 0.14, 0.15, 0.86)"
        >
          <MetalLinks subcategories={subcategories} />
        </Box>
      ) : (
        <Box
          display={'flex'}
          alignItems={'center'}
          gap={'10px'}
          justifyContent={'space-around'}
          width={'100%'}
        >
          <MetalLinks subcategories={subcategories} />
        </Box>
      )}
    </>
  );
};

const MetalLinksContainer: FC<
  WithTranslation & {
    subcategories: ShopSubcategoriesResponse;
  }
> = ({ subcategories, i18n: { t } }) => {
  const { language } = useLanguage();

  const links = [
    {
      metal: EMetal.GOLD,
      keyRoute: ERouting.BUY,
      query: { metal: metalToSlug[language][EMetal.GOLD] },
      text: t('price.gold'),
    },
    {
      metal: EMetal.SILVER,
      keyRoute: ERouting.BUY,
      query: { metal: metalToSlug[language][EMetal.SILVER] },
      text: t('price.silver'),
    },
    {
      metal: EMetal.PLATINUM,
      keyRoute: ERouting.BUY,
      query: { metal: metalToSlug[language][EMetal.PLATINUM] },
      text: t('price.platinum'),
    },
    {
      metal: EMetal.PALLADIUM,
      keyRoute: ERouting.BUY,
      query: { metal: metalToSlug[language][EMetal.PALLADIUM] },
      text: t('price.palladium'),
    },
  ];

  return (
    <Box display={'flex'}>
      {links.map((props) => (
        <MetalLink key={props.metal} data={subcategories?.[props.metal]} {...props} />
      ))}
    </Box>
  );
};

const MetalLink = ({ keyRoute, text, query, data, metal }: MetalLinkProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const { isDesktop } = useContextMediaQuery();

  const ref = useRef<HTMLDivElement>(null);

  const {
    typography: {
      header: {
        metals: { fontSize, lineHeight },
      },
    },
    structure: {
      header: {
        metals: { padding },
      },
    },
  } = useTheme() as ThemeProps;

  return (
    <Box
      ref={ref}
      position={'relative'}
      borderRadius={'6px 6px 0 0'}
      backgroundColor={isVisible ? { commonType: EColor.BLUE, intensity: EColor.A700 } : undefined}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      <LinkTranslatedStyledAsLink keyRoute={keyRoute} query={query} color={EColor.WHITE}>
        <Box padding={padding}>
          <Typography
            letterSpacing={'.94px'}
            fontWeight={EFontWeight.MEDIUM}
            lineHeight={lineHeight}
            fontSize={fontSize}
          >
            {text.toUpperCase()}
          </Typography>
        </Box>
      </LinkTranslatedStyledAsLink>

      {isDesktop && (
        <HeaderMetalCategories
          isVisible={isVisible}
          data={data}
          metal={metal}
          metalName={text}
          containerRef={ref}
        />
      )}
    </Box>
  );
};

const MetalLinks = memo(withTranslation(MetalLinksContainer, Translate.layout.HEADER));

export const MetalsHeader = memo(HeaderMetalsContainer);
