import { useContextMediaQuery } from '@core/context';
import { ESize } from '@core/type';

import { Box } from '../layout';
import { SkeletonTypography } from './SkeletonTypography';
import { SkeletonWrapper } from './SkeletonWrapper';

export const SkeletonHomepage = () => {
  const { isPhone, isMobile, mediaQuery } = useContextMediaQuery();

  if (isMobile) {
    return (
      <SkeletonWrapper paddingTop={isPhone ? '36px' : '102px'}>
        <SkeletonTypography lineHeight={'30px'} width={'220px'} margin={'0 auto 24px'} />

        <SkeletonTypography lineHeight={'50px'} maxWidth={'435px'} margin={'0 auto 8px'} />
        {isPhone && (
          <SkeletonTypography
            lineHeight={'50px'}
            width={isPhone ? '180px' : '235px'}
            margin={'0 auto 8px'}
          />
        )}
        <SkeletonTypography lineHeight={'50px'} maxWidth={'435px'} margin={'0 auto'} />

        <SkeletonTypography lineHeight={'46px'} width={'210px'} margin={'48px auto 24px'} />
        <SkeletonTypography width={'210px'} margin={'0 auto'} />

        <SkeletonTypography lineHeight={'400px'} maxWidth={'435px'} margin={'50px auto 0'} />
      </SkeletonWrapper>
    );
  }

  const isSmallDesktop: boolean = mediaQuery === ESize.LG;

  return (
    <SkeletonWrapper paddingTop={'118px'}>
      <Box display={'flex'} justifyContent={'space-between'} gap={'40px'}>
        <Box width={isSmallDesktop ? '500px' : '620px'}>
          <SkeletonTypography lineHeight={'36px'} width={'260px'} marginBottom={'24px'} />

          <Box marginBottom={'48px'}>
            <SkeletonTypography lineHeight={'68px'} marginBottom={'8px'} />
            <SkeletonTypography lineHeight={'68px'} />
            {isSmallDesktop && (
              <SkeletonTypography lineHeight={'68px'} width={'75%'} marginTop={'8px'} />
            )}
          </Box>

          <SkeletonTypography lineHeight={'46px'} width={'210px'} marginBottom={'24px'} />
          <SkeletonTypography width={'210px'} />
        </Box>

        <SkeletonTypography lineHeight={'460px'} width={isSmallDesktop ? '380px' : '460px'} />
      </Box>

      <Box marginTop={isSmallDesktop ? '320px' : '246px'} width={'680px'}>
        <SkeletonTypography lineHeight={'36px'} width={'330px'} marginBottom={'24px'} />
        <SkeletonTypography lineHeight={'30px'} marginBottom={'4px'} />
        <SkeletonTypography lineHeight={'30px'} />
      </Box>
    </SkeletonWrapper>
  );
};
