import Image from 'next/image';

import IconArrowBigSvg from './svgs/icon-arrow-big.svg';

export const IconArrowBig = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconArrowBigSvg}
    alt="Arrow big icon"
    height={24}
    width={25}
  />
);
