import type { ReactNode } from 'react';

import { routingContext } from '@core/context';
import { DefaultRoute, ERouting, PathTranslationsType } from '@core/type';
import { RoutingHook, RoutingState } from '@core/type/context';

import { storeHook } from '../helpers';
import { useRouting } from '../hook';

export const ProviderRouter = ({
  children,
  pathTranslationsInitialize,
  defaultRouteInitialize,
}: {
  children?: ReactNode;
  keyRouteInitialize?: ERouting;
  queryInitialize?: NodeJS.Dict<string | string[]>;
  pathTranslationsInitialize?: PathTranslationsType;
  defaultRouteInitialize?: DefaultRoute;
}) => {
  const hookRouting = storeHook<RoutingState, RoutingHook>(
    {
      pathTranslations: pathTranslationsInitialize,
      defaultRoute: defaultRouteInitialize,
      isLoading: false,
    },
    useRouting,
  );

  return <routingContext.Provider value={hookRouting}>{children}</routingContext.Provider>;
};
