import Image from 'next/image';

import IconPhone2Svg from './svgs/icon-phone-2.svg';

export const IconPhone2 = () => (
  <Image
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    src={IconPhone2Svg}
    alt="Icon phone 2"
    width={35}
    height={36}
  />
);
