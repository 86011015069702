import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type { StaticPageEntity } from '@core/type/api';

import { Api } from '../constants/api';
import { replaceIdUrl } from '../helpers/api';
import { configResponse, EConfigName } from '../helpers/config';

const staticPageBySlug = async (
  axiosInstance: AxiosInstance,
  { slug, language }: LanguageProps & { slug: string },
): Promise<AxiosResponse<StaticPageEntity>> =>
  axiosInstance.get(
    replaceIdUrl(Api.staticPage.STATIC_PAGE_BY_SLUG, slug, ':slug'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const staticPageBySlugWithAuth = async (
  axiosInstance: AxiosInstance,
  { slug, language }: LanguageProps & { slug: string },
): Promise<AxiosResponse<StaticPageEntity>> =>
  axiosInstance.get(
    replaceIdUrl(Api.staticPage.STATIC_PAGE_BY_SLUG_WITH_AUTH, slug, ':slug'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

export class StaticPageAPI {
  public static readonly staticPageBySlug = staticPageBySlug;
  public static readonly staticPageBySlugWithAuth = staticPageBySlugWithAuth;
}
