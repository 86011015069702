import { Currency, ECountry, ECurrency } from '@core/type';

export const DEFAULT_CURRENCY = ECurrency.EUR;

export const getCurrency = (currencies: Currency[], key: ECurrency): Currency => {
  return currencies.filter((currency: Currency) => (currency.key as ECurrency) === key)[0];
};

export const getIsoCurrency = (key: string, defaultCurrency: ECurrency): ECurrency => {
  switch (key) {
    case ECurrency.EUR.toString():
      return ECurrency.EUR;
    case ECurrency.CHF.toString():
      return ECurrency.CHF;
    case ECurrency.USD.toString():
      return ECurrency.USD;
    case ECurrency.GBP.toString():
      return ECurrency.GBP;
    default:
      return defaultCurrency;
  }
};

export const getCurrencyFromCountry = (country: ECountry): ECurrency => {
  if (country === ECountry.SWITZERLAND) {
    return ECurrency.CHF;
  }

  if (country === ECountry.UNITED_KINGDOM) {
    return ECurrency.GBP;
  }

  if (country === ECountry.UNITED_STATES) {
    return ECurrency.USD;
  }

  return ECurrency.EUR;
};

export const getIsoCurrencyFromCookie = (value: string): ECurrency => {
  switch (value) {
    case ECurrency.EUR.toString():
      return ECurrency.EUR;
    case ECurrency.CHF.toString():
      return ECurrency.CHF;
    case ECurrency.USD.toString():
      return ECurrency.USD;
    case ECurrency.GBP.toString():
      return ECurrency.GBP;
    case 'euro':
      return ECurrency.EUR;
    case 'swiss_franc':
      return ECurrency.CHF;
    case 'dollar':
      return ECurrency.USD;
    case 'pound_sterling':
      return ECurrency.GBP;
    default:
      return ECurrency.USD;
  }
};
