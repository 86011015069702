import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { Translate } from '@core/constant';
import { useContextAuth, useContextRouting, useContextUser } from '@core/context';
import { useLanguage } from '@core/hook';
import { LanguageStorage } from '@core/storage';
import { EAccessRoute, EColor, ELanguage, ELanguageTags, ERouting, EStorageType } from '@core/type';
import { ToastObject } from '@core/type/context';
import {
  getDefaultKey,
  getIsoLanguage,
  getLanguageFromTags,
  getLanguageTags,
  getTypeRoute,
} from '@core/util';

import { defaultRoute } from '../constants';

export const useNextRouter = ({
  addToast,
}: {
  addToast: (props: Omit<ToastObject, 'id'>) => string;
}) => {
  const { setRoute, isLoading, keyRoute, query, redirect } = useContextRouting();
  const { changeLanguage } = useLanguage();
  const { isLogged, isReady, isRedirectedToSignIn, setIsRedirectedToSignIn } = useContextUser();
  const { disconnect } = useContextAuth();
  const { t } = useTranslation(Translate.common.COMMON);
  const { locale, pathname } = useRouter();

  useEffect(() => {
    if (isRedirectedToSignIn && !isLoading) {
      addToast({
        title: t(`toast.information`),
        description: t('userMustLogged.description'),
        status: EColor.WARNING,
      });
      setRoute(ERouting.SIGN_IN);
      setIsRedirectedToSignIn(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRedirectedToSignIn, isLoading]);

  useEffect(() => {
    const hasToBeConnected: boolean =
      getTypeRoute(getDefaultKey(pathname, defaultRoute))?.accessRoute === EAccessRoute.CONNECTED;
    if (hasToBeConnected && !isLogged && isReady) {
      void disconnect(async () => {
        addToast({
          title: t(`toast.information`),
          description: t('userMustLogged.description'),
          status: EColor.WARNING,
        });
        await redirect(ERouting.SIGN_IN);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, isLogged, pathname]);

  useEffect(() => {
    // Define default language, client side only
    if ((locale as ELanguage) === ELanguage.DEFAULT) {
      // redirect application with language inside local storage
      const language: ELanguageTags = LanguageStorage.get(EStorageType.LOCAL_STORAGE);
      if (language) {
        if (language !== ELanguageTags.EN_US) {
          setRoute(keyRoute, query, getLanguageFromTags(language));
          changeLanguage(language);
        }
        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      } else if (window && window.navigator?.language) {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        const lng = getIsoLanguage((window.navigator?.language || '').substring(0, 2));
        if (lng !== ELanguage.EN) {
          setRoute(keyRoute, query, lng);
          changeLanguage(getLanguageTags(lng));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    LanguageStorage.set(EStorageType.LOCAL_STORAGE, getLanguageTags(locale as ELanguage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);
};
