import { EStorageType } from '@core/type';

import { EStorageKey } from '../constants';
import { getItem, logStorage, setItem } from '../helpers';

const getKeyRouteStore = (storage: EStorageType): string => {
  logStorage('getKeyRouteStore');
  return getItem(storage, EStorageKey.KEY_ROUTE);
};

const setKeyRouteStore = (storage: EStorageType, id: string) => {
  logStorage('setKeyRouteStore');
  setItem(storage, EStorageKey.KEY_ROUTE, id);
};

export class RoutingStorage {
  public static readonly getKeyRouteStore = getKeyRouteStore;
  public static readonly setKeyRouteStore = setKeyRouteStore;
}
