import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type { CategoryEntity, CategoryResponse } from '@core/type/api';

import { Api } from '../constants/api';
import { replaceIdUrl } from '../helpers/api';
import { configResponse, EConfigName } from '../helpers/config';

// TODO[GARB-***]: need to check CategoriesAPI.getCategories (we don't use it)
const getCategories = (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<CategoryResponse>> => {
  return axiosInstance.get(
    Api.categories.CATEGORIES,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getCategory = (
  axiosInstance: AxiosInstance,
  { slug, language }: LanguageProps & { slug: string },
): Promise<AxiosResponse<CategoryEntity>> => {
  return axiosInstance.get(
    replaceIdUrl(Api.categories.CATEGORY, slug, ':slug'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

export class CategoriesAPI {
  public static readonly getCategories = getCategories;
  public static readonly getCategory = getCategory;
}
