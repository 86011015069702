import { ConnectedBanner } from './ConnectedBanner';
import { ConnectedBannerModal } from './ConnectedBannerModal';
import { ConnectedBannerHookProps } from './interface-connected-banner';

export const ConnectedBannerContainer = ({
  bannerData,
  isModalOpen,
  handleBannerClose,
  handleModalClose,
  handleShowMore,
}: Omit<ConnectedBannerHookProps, 'isOpen'>) => (
  <>
    <ConnectedBanner onClose={handleBannerClose} onShowMore={handleShowMore} {...bannerData} />
    <ConnectedBannerModal
      isOpen={isModalOpen}
      onCloseModal={handleModalClose}
      title={bannerData.title}
      subtitle={bannerData.subtitle}
    />
  </>
);
