import { ReactNode } from 'react';

import { Accordion, Box, Typography } from '@core/component';
import { EColor, EFontWeight, EIcon, ESize, ETypographyVariant } from '@core/type';

export const FooterColumnAsAccordion = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <>
      <Accordion
        width={'100%'}
        defaultIcon={EIcon.PLUS}
        activeIcon={EIcon.MINUS}
        iconSize={ESize.SM}
        iconColor={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
        iconActiveColor={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
        titlePadding={'20px 10px'}
        titleContent={
          <Typography
            fontWeight={EFontWeight.MEDIUM}
            color={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
            variant={ETypographyVariant.BODY3}
            textTransform={'uppercase'}
            textAlign={'left'}
          >
            {title}
          </Typography>
        }
      >
        <Box
          display={'flex'}
          alignItems={'flex-start'}
          flexDirection={'column'}
          textAlign={'left'}
          gap={'24px'}
          padding={'4px 0 32px 26px'}
        >
          {children}
        </Box>
      </Accordion>
    </>
  );
};
